import { createPluginFactory } from "@udecode/plate-core";
import { PageBreakComponent } from "./components/pageBreakComponent";
import { withPageBreak } from "./normalizers/withPageBreak";

export const ELEMENT_PAGE_BREAK = "page-break";

export const createPageBreakPlugin = createPluginFactory({
  key: ELEMENT_PAGE_BREAK,
  isElement: true,
  isVoid: true,
  component: PageBreakComponent,
  withOverrides: withPageBreak,
});
