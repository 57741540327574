import { ChapterType } from "../../../types/chapter";

export const DROP_CAP_START_SYMBOLS = ["'", "\"", "“", "‘", " "];

/**
 * Parent block types to which we don't add first sentence formatting
 */
export const parentBlockTypes = [ "align_center", "align_right", "blockquote", "code_block", "indent", "calloutbox" ,"h1", "h2", "h3", "h4", "h5", "h6"];

/**
 * Parent block types to add first sentence formatting
 */
export const fsPatentBlockTypes = ["align_left", "p"];

export class CurrentChapterConfig {
  private showFirstSentenceFormatting: boolean;
  private endnotes: ISlateStore.SlateEndnote[];
  constructor() {
    this.showFirstSentenceFormatting = true;
    this.endnotes=[];
  }

  getFSFormatting(): boolean {
    return this.showFirstSentenceFormatting;
  }
  setFSFormatting(show: boolean): void {
    this.showFirstSentenceFormatting = show;
  }
  getEndnotes(): ISlateStore.SlateEndnote[] {
    return this.endnotes;
  }
  pushEndNote(note: ISlateStore.SlateEndnote): void {
    this.endnotes.push(note);
  }
}

export const getHtmlTagsForMarks = (node: ISlateStore.SlateTextNode): {
  tagQueue: string[],
  tagStack: string[]
} => {
  const tagQueue: string[] = [];
  const tagStack: string[] = [];

  if (node.superscript) {
    tagQueue.push("<sup>");
    tagStack.push("</sup>");
  }

  if (node.subscript) {
    tagQueue.push("<sub>");
    tagStack.push("</sub>");
  }

  if (node.bold) {
    tagQueue.push("<b>");
    tagStack.push("</b>");
  }

  if (node.italic) {
    tagQueue.push("<em>");
    tagStack.push("</em>");
  }

  if (node.underline) {
    tagQueue.push("<u>");
    tagStack.push("</u>");
  }

  if (node.strikethrough) {
    tagQueue.push("<del>");
    tagStack.push("</del>");
  }

  if (node.code) {
    tagQueue.push("<code>");
    tagStack.push("</code>");
  }

  if (node.smallcaps) {
    tagQueue.push("<span class=\"smallcaps\">");
    tagStack.push("</span>");
  }

  if (node.monospace) {
    tagQueue.push("<span class=\"monospace\">");
    tagStack.push("</span>");
  }

  if (node.sansserif) {
    tagQueue.push("<span class=\"sansserif\">");
    tagStack.push("</span>");
  }
  return {
    tagQueue,
    tagStack,
  };
};

const fpFormattableChapterTypes = [
  "chapter",
  "uncategorized",
  "prologue",
  "epilogue",
  "custom",
];

export const shouldRenderFPFormattingForChapter = (chapterType: string): boolean => {
  return fpFormattableChapterTypes.includes(chapterType);
};

/**
 * @function isChapterTitleCardRequired
 * @param {ChapterType} chapterType The chapter type
 * @returns {boolean} Returns whether the chapterType requires a chapter title card
 */
export const isChapterTitleCardRequired = (
  chapterType: ChapterType
): boolean => {
  const chapterTypesWithoutATitleCard: Partial<ChapterType[]> = [
    "custom",
    "copyrights",
    "dedication",
    "epigraph",
  ];
  return !chapterTypesWithoutATitleCard.includes(chapterType);
};