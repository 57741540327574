import React, { useState, useEffect } from "react";
import { Radio, Slider, Form, Row, Col, FormInstance } from "antd";
import { observer } from "mobx-react";

import { ImageSelector } from "../../Shared/Forms/ImageSelector";
import { AtticusSlider } from "../../Shared/Form";


interface OrnamentalBreakProps {
  formFields: IThemeStore.ThemeFields;
  onValuesChange: (fields: Partial<IThemeStore.ThemeFields>) => void
}

const SceneBreak = ({
  formFields,
  onValuesChange,
}: OrnamentalBreakProps): JSX.Element => {

  const handleObUpdate = (imageUrl: string) => {
    onValuesChange({ ob: imageUrl });
  };

  return (
    <div className="theme-section-tab-content">
      <Row>
        <Form.Item name="sceneBreakShowSetting">
          <Radio.Group>
            <Radio value="break-with-image">Scene break with image</Radio>
            <Radio value="break-without-image">Scene break without image</Radio>
            <Radio value="no-break">No visible scene break</Radio>
          </Radio.Group>
        </Form.Item>
      </Row>
      <Row style={{ display: formFields.sceneBreakShowSetting === "break-with-image" ? "block" : "none"}}>
        <Col span={12}>
          <Form.Item name="ob_width" label="Width:">
            <AtticusSlider
              min={1}
              max={100}
            />
          </Form.Item>
        </Col>
        <Col>
          <ImageSelector 
            onUpdate={handleObUpdate} 
            image={formFields.ob} 
            defaultWidth="300px"
            imageSelectorText={<span className="default-scenebreak-text">***</span>}
            folderName="custom-ornamental-breaks"
          />
        </Col>
      </Row>
    </div>
  );
};

export default observer(SceneBreak);