import React from "react";
import { Col, Row } from "antd";
import { NoDataIcon } from "../../../content/icons";

interface NoBooksProps {
  title: string
}

const quotes = [
  {
    title: "You can make anything by writing",
    name: "C.S. Lewis"
  }, 
  {
    title: "A word after a word after a word is power",
    name: "Margaret Atwood"
  },
  {
    title: "If my doctor told me I had only six minutes to live, I wouldn't brood. I'd type a little faster",
    name: "Isaac Asimov"
  },
  {
    title: "Ideas are like rabbits. You get a couple and learn how to handle them, and pretty soon you have a dozen",
    name: "John Steinbeck"
  },
  {
    title: "To produce a mighty book, you must choose a mighty theme",
    name: "Herman Melville"
  }, 
  {
    title: "We are all apprentices in a craft where no one ever becomes a master",
    name: "Ernest Hemingway"
  }
];

const randomNumber = Math.floor(Math.random() * quotes.length);

export const EmptyShelf: React.FC<NoBooksProps> = ({ title }) => {
  return (
    <Row justify="center" align="middle">
      <Col className="inner-xl text-center">
        <Row justify="center" align="middle">
          <Col span={28}>
            <NoDataIcon />
          </Col>
        </Row>
        <Row className="inner-xs" justify="center" align="middle">
          <Col span={24}>
            <h2>{title}</h2>
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col>
            <p className="quote">“{quotes[randomNumber].title}”</p>
            <h4> - {quotes[randomNumber].name}</h4>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
