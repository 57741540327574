import React, { useState } from "react";
import { Menu, Modal, Form, Input } from "antd";
import { observer } from "mobx-react";
import { FormOutlined } from "@ant-design/icons";

import useRootStore from "../../../store/useRootStore";

import { partialThemePropsToTheme } from "../helpers";
import { useOnlineStatus } from "../../../utils/isOffline";

interface RenameThemeProps {
  theme: IThemeStore.Theme;
}

export const RenameTheme = observer(({ theme }: RenameThemeProps) => {
  const { editTheme, activeTheme, setActiveTheme } = useRootStore().themeStore;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [themeName, setThemeName] = useState(theme?.name);
  const [updating, setUpdating] = useState(false);
  const isOnline = useOnlineStatus();

  const updateThemeName = async () => {
    if (theme) {
      try {
        setUpdating(true);
        const updatedTheme = partialThemePropsToTheme(
          { name: themeName },
          theme
        );
        await editTheme(updatedTheme, isOnline);

        // Apply theme name for active theme
        if (theme._id === activeTheme._id) {
          setActiveTheme({
            ...activeTheme,
            name: themeName
          });
        }
        
        setIsModalOpen(false);
        setUpdating(false);
      } catch (error) {
        console.error(error);
        setUpdating(false);
        setIsModalOpen(false);
      }
    }
  };

  return (
    <>
      <Menu.Item
        key="rename-theme"
        onClick={() => setIsModalOpen(true)}
        icon={<FormOutlined />}
      >
        Rename
      </Menu.Item>
      <Modal
        title="Theme Name"
        open={isModalOpen}
        okText="Save"
        okButtonProps={{ disabled: !themeName.trim(), loading: updating }}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => updateThemeName()}
      >
        <Form.Item>
          <Input
            placeholder="Theme Name"
            onChange={(event) => setThemeName(event.target.value)}
            value={themeName}
          />
        </Form.Item>
      </Modal>
    </>
  );
});
