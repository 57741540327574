export const hasCompressedImageSFX = "_ce";
const CompressedImageFolderPath = "/compressed-images/";

const s3FolderPaths = {
  coverImageFolderPath: "/cover-images/",
  customThemeImageFolderPath: "/custom-theme-images/",
  customThemeBGImageFolderPath: "/custom-theme-backgrounds/",
  customOrnamentalBreakImageFolderPath: "/custom-ornamental-breaks/",
  chapterImageFolderPath: "/images/",
  publisherLogoImageFolderPath: "/publisher-logos/",
  genericFileFolderPath: "/files/",
};

export const getCompressedImageUrl = (uncompressedUrl: string): string => {
  for (const folderPath of Object.values(s3FolderPaths)) {
    if (uncompressedUrl.includes(folderPath)) {
      return uncompressedUrl.replace(folderPath, CompressedImageFolderPath);
    }
  }
  return uncompressedUrl;
};
