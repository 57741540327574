import React, { useEffect, FunctionComponent, useCallback, useState  } from "react";
import { Form, Radio, RadioChangeEvent, FormItemProps } from "antd";
import { groupBy } from "lodash";

import useRootStore from "../../../../../store/useRootStore";
import { ImageLink } from "../../../config/typescript";
import { checkHttps } from "../transforms";

import { AtticusTextField, AtticusSelect, AtticusRadioGroup, AtticusRadioButtonProps } from "../../../../Shared/Form";

const formItemProperties: FormItemProps = {
    colon: false,
    labelCol: { span: 24 },
    style: { marginBottom: 15 },
  };

interface ImageLinkComponentProps {
    link: ImageLink | undefined;
    setLink: (link: ImageLink | undefined) => void;
}

interface ChapterSelectItem {
    value: string;
    label: string;
}

export const ImageLinkComponent: FunctionComponent<ImageLinkComponentProps> = ({
    link,
    setLink,
}) => {
    const { book } = useRootStore().bookStore;
    const [error, setError] = useState("");
    const [chapterOptions, setChapterOptions] = useState<ChapterSelectItem[]>([]);

    useEffect(() => {
        const groupedChapters = groupBy(book.chapters, "parentChapterId");
        const chaptersList: ChapterSelectItem[] = [];
    
        for (const chapter of book.frontMatter) {
          if(chapter.type !== "toc") {
            chaptersList.push({ value: chapter._id, label: chapter.title });
          }
        }
    
        for (const group in groupedChapters) {
          let parentChapter = "";
          const chapters = groupedChapters[group];
          for (const chapter of chapters) {
            if (chapter.type === "volume") {
              chaptersList.push({ value: chapter._id, label: chapter.title });
              parentChapter = `${chapter.title} → `;
            } else {
              chaptersList.push({
                value: chapter._id,
                label: `${parentChapter}${chapter.title}`,
              });
            }
          }
        }
    
        setChapterOptions(chaptersList);
      }, [book]);
      
    const handleChangeChapterType = useCallback((e: RadioChangeEvent) => {
        if (e.target.value === "none") {
            setLink(undefined);
            return;
        }

        setLink({ ...link, type: e.target.value });
    }, []);

    const handleChapterSelectionChange = (value: string) => {
        setLink({
            type: "internal-link",
            internalLink: { chapterId: value },
        });
    };

    const handleChapterSelectionFilterOption = (input: string, option: any) => {
        const { children } = option as { children: string };
        return children.toLowerCase().search(input.trim()) !== -1;
    };

    const validateText = (url: string) => {
        if (!checkHttps(url)) {
            setError("Should be a valid link starting with https:// or mailto:");
        } else {
            setError("");
        }
        return url;
    };

    const radioButtons: AtticusRadioButtonProps[] = [
        {
            children : "None",
            value: "none",
            className: "image-link-button image-link-left-align-button"
        },
        {
            children : "Web link",
            value: "web-link",
            className: "image-link-button image-link-center-align-button"
        },
        {
            children : "Internal link",
            value: "internal-link",
            className: "image-link-button image-link-right-align-button"
        },
    ];


    return (
        <Form.Item label={<span className="edit-image-form-label">Link</span>} {...formItemProperties}>
            <AtticusRadioGroup 
                buttons={radioButtons}
                optionType="button"
                buttonStyle="solid"
                value={link ? link.type : "none"}
                onChange={handleChangeChapterType}
                className="antd-full-width-radio-group"
                color="green"
            />
            {link && (
                <div className="image-link-value-container">
                    {link.type === "web-link" && (
                        <Form.Item>
                            <AtticusTextField
                                placeholder="Web Link"
                                value={link.webLink}
                                inputType="secondary"
                                onChange={(e) =>
                                    setLink({ type: link.type, webLink: validateText(e.target.value) })
                                }
                                style={{
                                    borderRadius: 4,
                                }}
                            />
                            <span id="errorText" className='error'>{error}</span>
                        </Form.Item>
                    )}
                    {link.type === "internal-link" && (
                        <Form.Item>
                            <AtticusSelect
                                type="secondary"
                                selectOptions={chapterOptions}
                                showSearch
                                onChange={handleChapterSelectionChange}
                                filterOption={handleChapterSelectionFilterOption}
                                placeholder="Select a target"
                                value={link.internalLink?.chapterId}
                            />
                        </Form.Item>
                    )}
                </div>
            )}
        </Form.Item>
    );
};
