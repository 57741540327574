import React, { FunctionComponent } from "react";
import { SvgIcon } from "@material-ui/core";

interface IconPixelDimensionsProps {
  width: string;
  height: string;
}

export const EditIcon: FunctionComponent = () => (
  <SvgIcon width="224.000000pt" height="212.000000pt" viewBox="0 0 224.000000 212.000000">
    <g transform="translate(0.000000,212.000000) scale(0.100000,-0.100000)"
      fill="#000000" stroke="none">
      <path d="M1289 2017 c-20 -8 -51 -24 -68 -36 -37 -26 -171 -196 -171 -217 0
-7 55 -57 122 -111 183 -146 198 -162 198 -213 0 -55 -42 -100 -93 -100 -37 0
-43 4 -232 155 -100 81 -127 97 -141 87 -11 -8 -57 -67 -422 -538 -289 -375
-291 -378 -298 -440 -6 -47 1 -83 72 -387 25 -105 13 -101 262 -94 178 6 221
10 256 26 45 20 90 67 204 216 37 50 178 232 312 405 134 173 273 353 309 400
36 47 99 128 140 180 41 52 83 115 94 139 38 87 18 203 -50 286 -41 49 -240
204 -293 227 -57 26 -154 33 -201 15z"/>
      <path d="M1344 320 c-79 -32 -86 -155 -10 -195 27 -14 70 -16 368 -13 l336 3
26 24 c51 48 42 135 -18 172 -29 18 -55 19 -356 18 -179 0 -335 -4 -346 -9z"/>
    </g>
  </SvgIcon>
);

export const TuneIcon: React.FC<IconPixelDimensionsProps> = ({ width, height }) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox={"0 0 24 24"}
    style={{ width, height, lineHeight: height }}
  >
    <path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"></path>
  </SvgIcon>
);

export const ChapterSettingsIcon: React.FC = () => {
  return (
    <svg
      id="setting-2"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="setting-2-2"
        data-name="setting-2"
        transform="translate(-300 -190)"
      >
        <path
          id="Vector"
          d="M18.1,6.659c-1.81,0-2.55-1.28-1.65-2.85a1.9,1.9,0,0,0-.7-2.59L14.02.229a1.669,1.669,0,0,0-2.28.6l-.11.19c-.9,1.57-2.38,1.57-3.29,0L8.23.829a1.641,1.641,0,0,0-2.26-.6l-1.73.99a1.908,1.908,0,0,0-.7,2.6c.91,1.56.17,2.84-1.64,2.84A1.906,1.906,0,0,0,0,8.559v1.76a1.906,1.906,0,0,0,1.9,1.9c1.81,0,2.55,1.28,1.64,2.85a1.9,1.9,0,0,0,.7,2.59l1.73.99a1.669,1.669,0,0,0,2.28-.6l.11-.19c.9-1.57,2.38-1.57,3.29,0l.11.19a1.669,1.669,0,0,0,2.28.6l1.73-.99a1.9,1.9,0,0,0,.7-2.59c-.91-1.57-.17-2.85,1.64-2.85a1.906,1.906,0,0,0,1.9-1.9V8.559A1.921,1.921,0,0,0,18.1,6.659ZM10,12.689a3.25,3.25,0,1,1,3.25-3.25A3.256,3.256,0,0,1,10,12.689Z"
          transform="translate(302 192.561)"
          fill="#292d32"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0H24V24H0Z"
          transform="translate(324 214) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  );
};

export const SettingsIcon = () => (
  <SvgIcon viewBox="0 0 32 32" style={{ width: 15 }} className="slate-ToolbarButton">
    <g id="Fill"><path d="M16,12a4,4,0,1,0,4,4A4,4,0,0,0,16,12Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,16,18Z" /><path d="M27.57,18.3l-1.38-.79a9.56,9.56,0,0,0,0-3l1.38-.79a2.86,2.86,0,0,0,1.05-3.91l-.93-1.61a2.88,2.88,0,0,0-3.91-1l-1.38.8a10.07,10.07,0,0,0-2.61-1.52V4.83A2.86,2.86,0,0,0,16.93,2H15.07a2.86,2.86,0,0,0-2.86,2.86V6.41A10.07,10.07,0,0,0,9.6,7.93l-1.38-.8a2.86,2.86,0,0,0-3.91,1L3.38,9.79a2.88,2.88,0,0,0,1,3.91l1.38.79a9.56,9.56,0,0,0,0,3l-1.38.79a2.86,2.86,0,0,0-1,3.91l.93,1.61a2.88,2.88,0,0,0,3.91,1.05l1.38-.8a10.07,10.07,0,0,0,2.61,1.52v1.58A2.86,2.86,0,0,0,15.07,30h1.86a2.86,2.86,0,0,0,2.86-2.86V25.59a10.07,10.07,0,0,0,2.61-1.52l1.38.8a2.86,2.86,0,0,0,3.91-1.05l.93-1.61A2.88,2.88,0,0,0,27.57,18.3Zm-.69,2.91L26,22.82a.86.86,0,0,1-1.17.32l-2.63-1.52-.54.49a8.3,8.3,0,0,1-3.12,1.82l-.7.22v3a.86.86,0,0,1-.86.86H15.07a.86.86,0,0,1-.86-.86v-3l-.7-.22a8.3,8.3,0,0,1-3.12-1.82l-.54-.49L7.22,23.14a.87.87,0,0,1-.65.08.84.84,0,0,1-.52-.4l-.93-1.61A.86.86,0,0,1,5.43,20l2.62-1.51-.16-.72a8.56,8.56,0,0,1-.2-1.8,8,8,0,0,1,.21-1.8l.15-.72L5.43,12a.86.86,0,0,1-.31-1.18l.93-1.61a.86.86,0,0,1,1.17-.32l2.63,1.52.54-.49a8.3,8.3,0,0,1,3.12-1.82l.7-.22v-3A.86.86,0,0,1,15.07,4h1.86a.86.86,0,0,1,.86.86v3l.7.22a8.3,8.3,0,0,1,3.12,1.82l.54.49,2.63-1.52a.87.87,0,0,1,.65-.08.84.84,0,0,1,.52.4l.93,1.61A.86.86,0,0,1,26.57,12L24,13.48l.16.72a8.56,8.56,0,0,1,.2,1.8,8,8,0,0,1-.21,1.8l-.15.72L26.57,20A.86.86,0,0,1,26.88,21.21Z" /></g>
  </SvgIcon>
);