import { makeAutoObservable, toJS } from "mobx";
import { generate as generateRandomString } from "randomstring";
import { debounce, find, uniq } from "lodash";

//IndexedDB
import { db } from "../db/bookDb";

//helpers
import { initBody, copyrightTemplates } from "../utils/initials";
import { AtticusClient } from "../api/atticus.api";

// sync functions
import { SaveOfflineBookToServer, SaveServerBookToDB } from "../utils/sync";
import { UpdateBookInDB } from "../utils/offline.book.helpers";
import { CHAPTER_TITLE_HIGHEST_SCALE } from "../utils/config";

const fallbackThemeId = process.env.REACT_APP_DEFAULT_THEME || "finch";


export class ShelfStore {
  loading = true;
  books: IBookStore.Book[] = [];
  sortBy: IShelfStore.BookSortOptionType = "recently-added";
  searchTerm = "";
  view = "grid";
  newBookModal = false;
  uploadBookModal = false;
  newBoxsetModal = false;

  constructor() {
    makeAutoObservable(this);
  }

  setModal = (key: "newBookModal" | "uploadBookModal" | "newBoxsetModal", show: boolean) => {
    this[key] = show;
  }
  
  // Getters and Setters

  pushBook = (book: IBookStore.Book): void => {
    this.books.push(book);
  }

  setLoading = (loading: boolean): void => {
    this.loading = loading;
  }

  setBooks = (books: IBookStore.Book[]): void => {
    this.books = books;
  }

  setSortBy = (sortBy: IShelfStore.BookSortOptionType) => {
    this.sortBy = sortBy;
  }

  setSearchTerm = (term: string) => {
    this.searchTerm = term;
  }

  setView = (view: "grid" | "list") => {
    this.view = view;
  }

  updateBookInBooks = (book: IBookStore.Book) => {
    const b = find(this.books, {"_id": book._id});
    if(b && b._id){
        const bks = this.books.map(d => d._id === b._id ? b : d);
        this.setBooks(bks);
    }
  }

  // fetch functions
  loadBooks = async (): Promise<void> => {
    this.setLoading(true);
    const booksFromDb = await db.books.toArray();
    this.setBooks(booksFromDb);
    this.setLoading(false);
  }

  // Group and return authors and projects
  getAuthors = (): string[] => {
    const list = new Set();
    this.books.forEach((book) => {
      book.author.forEach((author) => list.add(author));
    });

    return Array.from(list) as string[];
  }

  getProjects = (): string[] => {
    const list = new Set();
    this.books.forEach((book) => {
      list.add(book.project);
    });

    return Array.from(list) as string[];
  }

  getVersionTags = (): string[] => {
    const tags: string[] = [];

    this.books.forEach((book) => {
      if (Array.isArray(book.versionTags)) {
        tags.push(
          ...book.versionTags
        );
      }
    });

    return uniq<string>(tags);
  }

  deleteBook = async (bookId: string): Promise<void> => {
    try {
      await AtticusClient.DeleteBook(bookId);
    } catch (e: any){
      console.log(e);
      throw e;
    }

    const allPromises: Promise<unknown>[] = [];

    allPromises.push(db.chapterBodies.where("bookId").equals(bookId).delete());
    allPromises.push(db.chapterMetas.where("bookId").equals(bookId).delete());
    allPromises.push(db.books.delete(bookId));

    await Promise.all(allPromises);

    const filtered = this.books.filter((book) => book._id !== bookId);
    this.setBooks(filtered);
  }

  duplicateBook = async (bookId: string): Promise<void> => {
    try {
      await AtticusClient.DuplicateBook(bookId);
    } catch (e: any) {
      console.log(e);
      throw e;
    }
    const bookResponse = await AtticusClient.GetBooks();
    for(const bk of bookResponse.books) {
      await SaveServerBookToDB(bk._id);
    }
    await this.loadBooks();
    location.reload();
  }

  newBook = async (params: IShelfStore.BookForm): Promise<string | undefined> => {
    const processedBook: IBookStore.Book = {
      _id: params._id,
      coverImageUrl: "",
      title: params.title,
      author: params.author,
      project: params.project,
      modifiedAt: new Date(),
      createdAt: new Date(),
      chapterIds: [],
      frontMatterIds: [],
      themeId: fallbackThemeId
    };

    const chapterBodies: IChapterStore.ChapterBody[] = [];
    const chapterMetas: IChapterStore.ChapterMeta[] = [];

    const chapterId = params.chapterId;
    const titleChapterId = generateRandomString(16);
    const copyrightsChapterId = generateRandomString(16);
    const tocChapterId = generateRandomString(16);

    // chapter
    const newChapter: IChapterStore.ChapterBody = {
      _id: chapterId,
      bookId: processedBook._id,
      children: initBody,
    };

    const newChapterMeta: IChapterStore.ChapterMeta = {
      _id: chapterId,
      bookId: processedBook._id,
      title: "",
      titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
      type: "chapter",
      image: "",
      startOn: "right",
      subtitle: "",
      index: 0
    };

    chapterMetas.push(newChapterMeta);
    chapterBodies.push(newChapter);

    // title chapter
    const newTitleChapter: IChapterStore.ChapterBody = {
      _id: titleChapterId,
      bookId: processedBook._id,
      children: initBody,
    };

    const newTitleChapterMeta: IChapterStore.ChapterMeta = {
      _id: titleChapterId,
      bookId: processedBook._id,
      title: "Title Page",
      type: "title",
      titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
      subtitle: "",
      index: 0,
      image: "",
      startOn: "right"
    };

    chapterMetas.push(newTitleChapterMeta);
    chapterBodies.push(newTitleChapter);

    // copyrights
    const newCopyrightsChapter: IChapterStore.ChapterBody = {
      _id: copyrightsChapterId,
      bookId: processedBook._id,
      children: copyrightTemplates[0].children,
    };

    const newCopyrightsChapterMeta: IChapterStore.ChapterMeta = {
      _id: copyrightsChapterId,
      bookId: processedBook._id,
      title: "Copyright",
      titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
      type: "copyrights",
      subtitle: "",
      image: "",
      index: 0,
      startOn: "left"
    };

    chapterMetas.push(newCopyrightsChapterMeta);
    chapterBodies.push(newCopyrightsChapter);

    // toc
    const newTocChapter: IChapterStore.ChapterBody = {
      _id: tocChapterId,
      bookId: processedBook._id,
      children: initBody,
    };

    const newTocChapterMeta: IChapterStore.ChapterMeta = {
      _id: tocChapterId,
      bookId: processedBook._id,
      title: "Contents",
      titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
      type: "toc",
      subtitle: "",
      image: "",
      index: 0,
      startOn: "right"
    };

    chapterMetas.push(newTocChapterMeta);
    chapterBodies.push(newTocChapter);

    processedBook.chapterIds.push(chapterId);
    processedBook.frontMatterIds = [ titleChapterId, copyrightsChapterId, tocChapterId ];

    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.chapterBodies.bulkPut(chapterBodies));
    allPromises.push(db.chapterMetas.bulkPut(chapterMetas));

    allPromises.push(db.books.add(toJS(processedBook)));

    try {
      await Promise.all(allPromises);
      await SaveOfflineBookToServer(processedBook._id);
      this.loadBooks();
      return processedBook._id;
    } catch (e: any) {
      console.log(e.message);
    }
  }

  newBoxset = async (params: IShelfStore.BoxsetForm): Promise<string> => {
    try {
      const resp = await AtticusClient.createBoxset({
        title: params.title,
        author: params.author,
        project: params.project,
        bookIds: params.bookIds
      });
      await SaveServerBookToDB(resp.bookId);
      await this.loadBooks();
      return resp.bookId;
    }
    catch (e: any) {
      console.error(e.message);
      throw e;
    }
  }

  onUpload = async (params: IShelfStore.BookFormFile): Promise<string | undefined> => {
    try {
      const resp = await AtticusClient.ImportDocument({
        url: params.fileURL,
        author: params.author,
        title: params.title,
        project: params.project,
      }, params.fileType);
      await SaveServerBookToDB(resp.bookId);
      await this.loadBooks();
      return resp.bookId;
    } catch (e: any) {
      console.log(e.message);
      throw e;
    }
  }

  persistBook = async(bookId: string, changes: Partial<IBookStore.Book>): Promise<void> => {
    try {
      delete changes["__v"];
      delete changes["createdAt"];
      delete changes["lastUpdateAt"];

      const lastSyncAt = await AtticusClient.PatchBook(bookId, changes);
      await UpdateBookInDB(bookId, {
        lastSuccessfulSync: lastSyncAt.timestamp,
        allChangesSynced: true,
        ...toJS(changes),
      });
    } catch (e: any) {
      console.log(e);
    }
  }

  debouncedPersistBook = debounce(this.persistBook, 400);

  saveBook = async (bookId: string, changes: Partial<IBookStore.Book>, localOnly?: boolean) : Promise<void> => {
    const bookIndex = this.books.findIndex((b) => b._id === bookId);
    if (bookIndex > -1) {
      const book = {
        ...this.books[bookIndex],
        ...changes,
      };

      const updatedBooks = [...this.books];
      updatedBooks[bookIndex] = book;

      this.setBooks(updatedBooks);

      if(!localOnly){
        this.debouncedPersistBook(bookId, changes);
      }
    }
  }
}

export default new ShelfStore();
