import { MyRootBlock } from "../../../components/Plate/config/typescript";

import { parseChapter } from ".";

export const parseFormattableEndnote = (
  note: string | MyRootBlock[] | null,
  chapter?: IChapterStore.ChapterMeta,
  themeProps?: IThemeStore.ThemeStyleProps,
  isExport?: boolean
):string =>{
  const initialValue: MyRootBlock[] = [
    {
      type: "p",
      children: [
        {
          text: `${note}`,
        },
      ],
    },
  ];

  const html = chapter && themeProps? parseChapter(
    {
      ...chapter,
      children: typeof note === "string" ? initialValue : note,
    },
    themeProps,
    true,
    undefined,
    undefined,
    isExport

  ) : "";

  return html;

};