import { usePlateEditorState } from "@udecode/plate";
import { Button, Popover } from "antd";
import React, { ReactNode, useState } from "react";
import { MyEditor, MyRootBlock } from "../../../config/typescript";
import { EndnoteIcon } from "../../../partials";
import { updateEndnoteNode } from "../transformers";
import { TEndNote } from "../types";
import EndnotesUpsertModal from "./EndNotesUpsertModal";
import { PlateNodes } from "../../../config/typescript";
import useRootStore from "../../../../../store/useRootStore";
import { parseChapter } from "../../../../../press/exportEpub/parsers";
import { EditOutlined } from "@ant-design/icons";
interface EndNotesComponentProps {
  children: ReactNode;
  element: TEndNote;
}

export const EndNotesComponent: React.FC<
  EndNotesComponentProps
> = ({ children, element }: EndNotesComponentProps) => {
  const { activeTheme: theme } = useRootStore().themeStore;
  const { chapter } = useRootStore().bookStore;
  const editor = usePlateEditorState() as MyEditor;
  const [modalVisible, setModalVisibility] = useState<boolean>(false);

  const updateNote = (note: PlateNodes | null ) => {
    updateEndnoteNode(editor, element, note);
    closeModal();
  };

  const openModal = () => {
    setModalVisibility(true);
  };

  const closeModal = () => {
    setModalVisibility(false);
  };

  const PopOverContent = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{ paddingRight: "10px" }}
          dangerouslySetInnerHTML={{
            __html: parseChapter(
              {
                ...chapter,
                children: typeof element.note === "string" ? initialValue : element.note,
              },
              theme?.properties,
              true,             
            ),
          }}
        ></div>
        <Button type="text" onClick={openModal} icon={<EditOutlined />} size= {"small"}> 
        </Button>
      </div>
    );
  };

  const initialValue: MyRootBlock[]= [
    {
      type: "p",
      children: [
        {
          text: `${element.note}`,
        },
      ],
    },
  ];

  return (
    <div contentEditable={false} style={{ display: "inline" }}>
      {children}

      <Popover placement="bottom" content={PopOverContent}>
        <span className={"slate-endnote"} onMouseDown={() => openModal()}>
          <EndnoteIcon />
        </span>
      </Popover>

      {modalVisible && (
        <EndnotesUpsertModal
          open={modalVisible}
          currentNote={
            typeof element.note === "string"
              ? initialValue
              : (element.note as MyRootBlock[])
          }
          updateNote={updateNote}
          onCancel={closeModal}
        />
      )}
    </div>
  );
};
