import { TReactEditor, TRange, TPath, insertNodes } from "@udecode/plate-core";

export const insertProfileToEditor = (
  editor: TReactEditor,
  location: TRange | TPath | null | undefined,
  profileInfo: {
    url: ISocialProfileStore.ISMAccount[];
    _id?: string;
    profileName?: string;
    printAlign?: ISocialProfileStore.PrintAlign;
  }
): void => {
  const editorLocation = location || editor.selection || undefined;
  insertNodes(
    editor,
    {
      type: "profile",
      url: profileInfo.url,
      _id: profileInfo._id,
      profileName: profileInfo.profileName,
      printAlign: profileInfo.printAlign,
      children: [{ text: "" }],
    },
    {
      at: editorLocation,
    }
  );
};
