import React from "react";
import { Radio, RadioGroupProps, RadioProps } from "antd";

export interface AtticusRadioButtonProps extends RadioProps {
  children: JSX.Element | string
}


export interface AtticusRadioGroupProps extends RadioGroupProps{
  buttons: AtticusRadioButtonProps[]
  color?: "green" | "grey";
}

export const AtticusRadioGroup: React.FC<AtticusRadioGroupProps> = (props) => {
  const {color, buttons, ...radioGroupProps } = props;
  const radioGroupClassName = `at-radio-group ${radioGroupProps.className || ""} ${color || ""}`;
  return (
    <Radio.Group {...radioGroupProps} className={radioGroupClassName}>
      {
        buttons.map((button, index) => {
          const {children, ...radioButtonProps} = button;
          return (<Radio.Button {...radioButtonProps} key={index}>{children}</Radio.Button>);
        })
      }
    </Radio.Group>
  );
};