import React, { useCallback } from "react";
import {
  getEndPoint,
  PlateEditor,
  removeNodes,
  setSelection,
  splitNodes,
  ToolbarButton,
  ToolbarButtonProps,
  usePlateEditorState,
} from "@udecode/plate";
import { CURRENT_COLOR, TooltipIcon } from "../../../partials";
import {
  FEATURE_SPLIT_CHAPTER,
  isRangeSelected,
  getEditorNodesCount,
  getMidPointOfSplit
} from "../";
import { MyEditor } from "../../../config/typescript";
import useRootStore from "../../../../../store/useRootStore";
import { allowFeatureForTextMessages } from "../../text-message/config";
import { allowFeatureForCalloutBoxes } from "../../callout-box/config";
import { SplitChapterIcon } from "../../../../../content/icons";

export const SplitChapterToolbarButton = (_: Omit<ToolbarButtonProps, "icon">) => {
  const editor = usePlateEditorState() as MyEditor;
  const enabled = allowFeatureForTextMessages(editor as PlateEditor) && allowFeatureForCalloutBoxes(editor as PlateEditor);
  const { chapter, book, sceneIndex, getCurrentStoredBook, getChapterBodyById, saveChapterBodyUpdates, addNewChapter, getChapterMatterById } = useRootStore().bookStore;

  const { refreshCache } = useRootStore().pdfCacheStore;

  const refreshPDFCache = useCallback(async () => {
    const { frontMatterIds, chapterIds } =
      getCurrentStoredBook();
    const allChapterIds = [...frontMatterIds, ...chapterIds];
    const chapterData = await getChapterBodyById(allChapterIds);
    const chapterCacheData = chapterData.map(
      ({ _id, type, startOn }) =>
        ({
          chapterId: _id,
          chapterType: type,
          startOn,
        } as IPDFCacheStore.ChapterCacheMetaData)
    );
    refreshCache(book._id, "chapter-add", {
      "chapter-add": { chapters: chapterCacheData },
    });
  }, [book, getCurrentStoredBook, refreshCache]);

  return (
    <div className={sceneIndex !== null ? "toolbar-button-disable" : ""}>
      <TooltipIcon
        title={"Split chapter"}
      >
        <div className={`${enabled ? "" : "plate-disabled"}`}>
          <ToolbarButton
            type={FEATURE_SPLIT_CHAPTER}
            icon={<SplitChapterIcon color={CURRENT_COLOR} />}
            onMouseDown={async () => {
              if (getEditorNodesCount(editor) === 0 || isRangeSelected(editor)) {
                return;
              }

              // If the cursor is between a text for example, the content after the cursor is pushed into a different node.
              splitNodes(editor);

              const midPoint = getMidPointOfSplit(editor);
              const endPoint = getEndPoint(editor, []);

              // Select the stuff that needs to be moved to next chapter
              setSelection(editor, {
                anchor: midPoint.anchor,
                focus: endPoint,
              });

              const editorChildren = editor.children;

              const pushToNextChapter = editorChildren.slice(
                midPoint.index,
                editorChildren.length
              );

              // Remove selected nodes from current chapter
              // as selected nodes will be moved to next chapter
              removeNodes(editor);

              saveChapterBodyUpdates(chapter._id, editor.children);

              await addNewChapter(
                getChapterMatterById(chapter._id),
                chapter.type,
                {
                  title: chapter.title + " (SPLIT)",
                  parentChapterId: chapter.parentChapterId
                },
                pushToNextChapter
              );

              await refreshPDFCache();
            }}
          />
        </div>
      </TooltipIcon>
    </div>
  );
};
