import { getChapterAncestorVolume } from "./chapter";
import {
  PdfSlateEndnote,
  PdfSlateBaseParentBlockNode,
  PdfEndnoteSubheading,
  PdfChapterEndnotes,
} from "../components/Previewer/print/types";
import { getAllEndNotesOfChapter } from "./get-chapter-endnotes";

/**
 * @param chapters All chapters of the book
 */
export function getBookEndnotes(
  chapters: IChapterStore.Chapter[]
): Array<PdfSlateEndnote | PdfEndnoteSubheading> {
  const endNotes: Array<PdfSlateEndnote | PdfEndnoteSubheading> = [];
  const availableVolumeIds = new Set<string>();
  for (const chapter of chapters) {
    const chapterEndnotes = getAllEndNotesOfChapter(
      chapter.children as PdfSlateBaseParentBlockNode[],
      chapter._id
    );

    // if the endnotes are from a new volume, add the volume title
    if (chapterEndnotes.length) {
      const ancestorVolume = getChapterAncestorVolume(chapter._id, chapters);
      if (ancestorVolume && !availableVolumeIds.has(ancestorVolume._id)) {
        availableVolumeIds.add(ancestorVolume._id);
        endNotes.push({
          type: "endnote-subheading",
          text: ancestorVolume.title,
        });
      }
    }

    endNotes.push(...chapterEndnotes);
  }
  return endNotes;
}

export function getBookEndnotesByChapter(
  chapters: IChapterStore.Chapter[]
): PdfChapterEndnotes[] {
const endNotesByChapterArray : Array <PdfChapterEndnotes> = [];
const availableVolumeIds = new Set<string>();
for (const chapter of chapters) {
  const chapterEndnotes = getAllEndNotesOfChapter(
    chapter.children as PdfSlateBaseParentBlockNode[],
    chapter._id
  );

  // if the endnotes are from a new volume, add the volume title
  if (chapterEndnotes.length) {
    const ancestorVolume = getChapterAncestorVolume(chapter._id, chapters);
    if (ancestorVolume && !availableVolumeIds.has(ancestorVolume._id)) {
      availableVolumeIds.add(ancestorVolume._id);
      endNotesByChapterArray.push({
        chapterTitle: ancestorVolume.title,
        chapterEndnotes: [],
        type: "volume"
      });
    }
  }
  const endnotesByChapter : PdfChapterEndnotes = {
    chapterTitle: chapter.title,
    chapterEndnotes: chapterEndnotes,
    type: "chapter"
  };
  if(chapterEndnotes.length > 0){
    endNotesByChapterArray.push(endnotesByChapter);
  }
}
return endNotesByChapterArray;
}
