import React, { useEffect } from "react";
import { Menu, Dropdown, Tooltip } from "antd";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";

import ThemeThumbnail from "./ThemeThumbnail";

import {
  FavouriteTheme,
  DuplicateTheme,
  DeleteTheme,
  RenameTheme,
  EditTheme,
} from "./actions";
import { useOnlineStatus } from "../../utils/isOffline";
import { DEFAULT_THEME_ID } from "../../utils/initials";
import { observer } from "mobx-react";
import { AddCardButton } from "../Shared/Buttons";
import { ThemeIcon } from "../../content/icons";

interface ThemeCardProps {
  refs: React.RefObject<any>,
  active: boolean;
  theme: IThemeStore.Theme;
  editable: boolean;
  handleOnClick: (id: string) => void;
  handleOnEdit: (id: string) => void;
  scrollView: (id: string) => void;
}

const ThemeCard = ({
  refs,
  theme,
  active,
  editable,
  handleOnEdit,
  handleOnClick,
  scrollView
}: ThemeCardProps): JSX.Element => {
  const isOnline = useOnlineStatus();
  const showEditButton =
    !theme.isPredefinedTheme || (theme.isPredefinedTheme && isOnline);
  const showRenameButton = !theme.isPredefinedTheme && isOnline;
  const showDuplicateButton = isOnline;
  const showDeleteButton = !theme.isPredefinedTheme && isOnline;
  const scroll = () => (active ? scrollView(theme._id) : undefined);
  
  useEffect(() => scroll());

  return theme._id !== DEFAULT_THEME_ID ? (
    <div className={`theme-card ${active ? "active" : ""}`} ref={refs}>
      <div
        className="theme-element"
      >
        <ThemeThumbnail 
          theme={theme} 
          onClick={() => handleOnClick(theme._id)}
        />
        <FavouriteTheme
          styleId={theme._id}
          favourite={Boolean(theme.isFavourite)}
        />
      </div>

      <div className="theme-card-bottom">
        <Tooltip placement="bottomLeft" title={theme.name}>
          <div className="theme-card-name-wrapper">{theme.name}</div>
        </Tooltip>
        {/* Disallow other actions when theme is being edited */}
        {!editable && (
          <Dropdown
            trigger={["click"]}
            placement="top"
            overlay={
              <Menu activeKey="">
                {showEditButton && (
                  <EditTheme theme={theme} onEdit={handleOnEdit} />
                )}
                {showRenameButton && <RenameTheme theme={theme} />}
                {showDuplicateButton && !theme.isPredefinedTheme && <DuplicateTheme theme={theme} />}
                {showDeleteButton && <DeleteTheme themeId={theme._id} />}
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        )}
      </div>
    </div>
  ) : (
    <div
      className="theme-card"
    >
      <AddCardButton
        className="theme-element theme-card-container"
        onClick={(e) =>
          isOnline ? handleOnClick(theme._id) : e.preventDefault()
        }
        disabled={!isOnline}
        icon={<ThemeIcon />}
      >
        Create a new theme {!isOnline && " (Offline)"}
      </AddCardButton>
    </div>
  );
};

export default observer(ThemeCard);
