import React from "react";
import { Form, FormItemProps, Input, Radio } from "antd";
import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { isUrl, validateLink } from "../helpers";
import {
  LinkType,
  SelectElementInternalLink,
  TabbedLinkComponentProps,
} from "../../../config/typescript";
import useRootStore from "../../../../../store/useRootStore";
import { groupBy } from "lodash";
import { AtticusSelect, AtticusTextField } from "../../../../Shared/Form";
import { DefaultOptionType } from "antd/lib/select";
import { Button } from "../../../../Shared/Buttons";

const commonFormItemProps = {
  labelAlign: "left",
  className: "form-item-container",
  colon: false,
} as FormItemProps;

interface ChapterMenuItem {
  _id: string;
  title: string;
}

type FilterFunc<OptionType> = (
  inputValue: string,
  option?: OptionType | SelectElementInternalLink
) => boolean;

export const TabbedLinkComponent: FunctionComponent<
  TabbedLinkComponentProps
> = ({
  value = "",
  type = "web-link",
  error,
  isFromEditLink = false,
  setValue,
  setType,
  ...props
}) => {
  const [localUrl, setLocalUrl] = useState(value);
  const [localType, setLocalType] = useState(type);
  const [localError, setLocalError] = useState(error);

  const { book } = useRootStore().bookStore;
  const [chapters, setChapters] = useState<ChapterMenuItem[]>([]);

  useEffect(() => {
    const groupedChapters = groupBy(book.chapters, "parentChapterId");
    const chaptersList: ChapterMenuItem[] = [];

    for (const chapter of book.frontMatter) {
      if (chapter.type !== "toc") {
        chaptersList.push({ _id: chapter._id, title: chapter.title });
      }
    }

    for (const group in groupedChapters) {
      let parentChapter = "";
      const chapters = groupedChapters[group];
      for (const chapter of chapters) {
        if (chapter.type === "volume") {
          chaptersList.push({ _id: chapter._id, title: chapter.title });
          parentChapter = `${chapter.title} → `;
        } else {
          chaptersList.push({
            title: `${parentChapter}${chapter.title}`,
            _id: chapter._id,
          });
        }
      }
    }

    setChapters(chaptersList);
  }, [book]);

  useEffect(() => {
    if (value !== localUrl) {
      setLocalUrl(value);
    }
  }, [value]);

  useEffect(() => {
    if (type !== localType) {
      setLocalType(type);
    }
  }, [type]);

  useEffect(() => {
    if (error !== localError) {
      setLocalError(error);
    }
  }, [error]);

  const switchTabs = (type: LinkType): void => {
    setLocalError("");
    setLocalType(type);
    setType(type);

    if (localUrl === "" && value !== "") {
      setLocalUrl(value);
    } else {
      setLocalUrl("");
    }
  };

  const validateWebUrl = (url: string): void => {
    setLocalError(
      !validateLink(url)
        ? "Should be a valid link starting with http:// or https:// or mailto:"
        : ""
    );
  };

  const validateBooklinkerUrl = (url: string): void => {
    setLocalError(
      !validateLink(url)
        ? "Should be a valid Amazon or Apple Book link starting with http:// or https://"
        : ""
    );
  };

  const onWebLinkChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const urlValue = e.target.value;

    setLocalUrl(urlValue);
    setType("web-link");

    validateWebUrl(urlValue);
    setValue(urlValue);
  };

  const onInternalLinkChange = (value: string) => {
    setLocalUrl(value);
    setType("internal-link");
    setValue(value);
  };

  const onBooklinkerChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const urlValue = e.target.value;

    setLocalUrl(urlValue);
    setType("booklinker");

    validateBooklinkerUrl(urlValue);
    setValue(urlValue);
  };

  const handleInternalLinkFilterOptions = (
    input: string,
    option: SelectElementInternalLink | undefined
  ): boolean => {
    return option?.children?.toLowerCase()?.search(input.trim()) !== -1;
  };

  return (
    <Form.Item {...commonFormItemProps}>
      <Radio.Group
        className='link-btn-group'
        optionType='button'
        buttonStyle='solid'
        size='large'
        value={localType}
        onChange={(e) => switchTabs(e.target.value)}
      >
        <Radio.Button className='link-rd-btn' value='web-link'>
          Web link
        </Radio.Button>
        <Radio.Button className='link-rd-btn' value='internal-link'>
          Internal link
        </Radio.Button>
        <Radio.Button className='link-rd-btn' value='booklinker'>
          Link to book
        </Radio.Button>
      </Radio.Group>

      <div className='image-link-value-container'>
        <Form.Item {...commonFormItemProps}>
          {localType === "web-link" && (
            <Form.Item {...commonFormItemProps}>
              {isFromEditLink ? (
                <>
                  <Input.Group compact className='txt-with-btn'>
                    <AtticusTextField
                      placeholder='Web link'
                      inputType='primary'
                      value={localUrl}
                      onChange={onWebLinkChange}
                    />
                    <Button
                      type='at-ghost'
                      className='visit-link-btn'
                      backgroundColor='green'
                      href={localUrl}
                      target='_blank'
                      rel='noreferrer'
                      disabled={!isUrl(localUrl)}
                    >
                      Visit URL
                    </Button>
                  </Input.Group>
                  <span id='errorText' className='error'>
                    {localError}
                  </span>
                </>
              ) : (
                <>
                  <AtticusTextField
                    placeholder='Web link'
                    inputType='primary'
                    value={localUrl}
                    onChange={onWebLinkChange}
                  />
                  <span id='errorText' className='error'>
                    {localError}
                  </span>
                </>
              )}
            </Form.Item>
          )}
          {localType === "internal-link" && (
            <Form.Item {...commonFormItemProps}>
              <AtticusSelect
                showSearch
                className="selector-internal-link"
                onChange={onInternalLinkChange}
                filterOption={
                  handleInternalLinkFilterOptions as FilterFunc<DefaultOptionType>
                }
                placeholder='Select a target'
                value={localUrl !== "" ? localUrl : undefined}
                selectOptions={chapters.map((chapter) => ({
                  label: chapter.title,
                  value: `#chapter=${chapter._id}`,
                }))}
              />
            </Form.Item>
          )}
          {localType === "booklinker" && (
            <Form.Item {...commonFormItemProps}>
              <p className='bl-info'>
                Create a single, universal book link that works for readers
                around the world to help boost sales&nbsp;
                <a
                  href='https://booklinker.com/faq'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Learn more...
                </a>
              </p>
              <p className='bl-title'>Amazon or Apple Books URL:</p>
              <AtticusTextField
                placeholder='Amazon or Apple Books URL'
                inputType='primary'
                value={localUrl}
                onChange={onBooklinkerChange}
              />
              <span id='errorText' className='error'>
                {localError}
              </span>
            </Form.Item>
          )}
        </Form.Item>
      </div>
    </Form.Item>
  );
};
