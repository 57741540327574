import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import useRootStore from "../../../store/useRootStore";

interface FavouriteThemeProps {
  styleId: string
  favourite: boolean
}

export const FavouriteTheme = observer(({ styleId, favourite }: FavouriteThemeProps) => {
  const { toggleFavourite } = useRootStore().themeStore;
  const [isFav, setIsFav] = useState<boolean>(favourite);

  const handler = () => {
    setIsFav(!isFav);
    toggleFavourite(styleId, !isFav);
  };

  useEffect(() => {
    setIsFav(favourite);
  }, [favourite]);

  return (
    <Button
      className="theme-favourite"
      type="text"
      shape="circle"
      icon={isFav ? <HeartFilled color="gold" /> : <HeartOutlined color="gold" />}
      onClick={handler}
      size="small"
    />
  );
});
