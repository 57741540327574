import React from "react";
import { observer } from "mobx-react";

import useRootStore from "../../store/useRootStore";
import ChapterTemplateBlockItem from "./ChapterTemplateListItem";

const Chapters = observer(() => {
  const {
    book,
    chapterTemplate,
  } = useRootStore().bookStore;
  if (!book) return null;

  return (
    <div>
      <ChapterTemplateBlockItem
        chapter={chapterTemplate}
      />
    </div>
  );
});

export default Chapters;
