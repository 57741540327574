import React, { FunctionComponent } from "react";
import { GalleryImage } from "./GalleryImage";
import { Image, ImageDeleteFrom } from "../../types/image-gallery";

interface GalleryTabPaneProps {
  images: Image[];
  handleSelectImage: (link: string) => void;
  handleDeleteImage: (
    event: any,
    image: Image,
    deleteFrom: ImageDeleteFrom
  ) => void;
}

export const GalleryTabPane: FunctionComponent<GalleryTabPaneProps> = (
  props: GalleryTabPaneProps
) => {
  const { handleSelectImage, handleDeleteImage } = props;
  return (
    <div className="image-gallery-tab-wrapper">
      <div className="image-gallery-tab-inner scroller">
        {props.images.map((image, index) => (
          <GalleryImage
            handleSelectImage={handleSelectImage}
            handleDeleteImage={handleDeleteImage}
            key={index}
            image={image}
          />
        ))}
      </div>
    </div>
  );
};
