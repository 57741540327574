import { PlateEditor, Value } from "@udecode/plate";

export const doesEditorHaveUndos = (editor: PlateEditor<Value>) => {
  if (!editor) return;

  if (editor.history && Array.isArray(editor.history.undos)) {
    if (editor.history.undos.length > 0) {
      return true;
    }
  }

  return false;
};
