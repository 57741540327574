import React, { ReactNode } from "react";
import { Tooltip } from "antd";

interface CardContentProps {
  title: string;
  children: ReactNode;
}

export const CardContent: React.FC<CardContentProps> = ({ title, children }) => (
  <div className='book-card-content'>
    <Tooltip title={title} placement='top' color='#171D21'>
      <h4 className='book-title'>{title}</h4>
    </Tooltip>
    {children}
  </div>
);
