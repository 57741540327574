import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import {
  Select,
  Form,
  Row,
  Col,
} from "antd";
import { groupBy } from "lodash";
import useRootStore from "../../../store/useRootStore";


const { Option } = Select;


interface ChapterMenuItem {
  _id: string;
  title: string;
}


const EbookSettings = () => {
  const [chaptersList, setChaptersList] = useState<ChapterMenuItem[]>([]);
  const { book } = useRootStore().bookStore;

  useEffect(() => {
    const groupedChapters = groupBy(book.chapters, "parentChapterId");
    const chaptersList: ChapterMenuItem[] = [];

    for (const chapter of book.frontMatter) {
      let chapId = (chapter.title.trim() === "") ? chapter._id : chapter.title.trim().replace(/[^\w\s]/gi, "").replace(/ /g, "-");

      if (chapter.type === "toc") {
        chapId = "toc";
      }
      chaptersList.push({ _id: chapId, title: chapter.title });
    }

    for (const group in groupedChapters) {
      let parentChapter = "";
      const chapters = groupedChapters[group];
      for (const chapter of chapters) {
        if (chapter.type === "volume") {
          chaptersList.push({ _id: chapter._id, title: chapter.title });
          parentChapter = `${chapter.title} → `;
        } else {
          chaptersList.push({
            title: `${parentChapter}${chapter.title}`,
            _id: chapter._id
          });
        }
      }
    }

    setChaptersList(chaptersList);
  }, [book]);

  const handleChapterSelectionFilterOption = (input: string, option: any) => {
    const { children } = option as { children: string };
    return children.toLowerCase().search(input.trim()) !== -1;
  };

  return (
    <>
      <Row gutter={32}>
        <Col span={14}>
          <Form.Item
            name="startPage"
            
            label="Start Page"
          >
            <Select
              showSearch
              filterOption={handleChapterSelectionFilterOption}
              placeholder="Select a target"
            >
              {chaptersList.map((chapter) => {
                return (
                  <Option key={chapter._id} value={chapter._id}>
                    {chapter.title}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default observer(EbookSettings);
