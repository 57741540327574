import React from "react";
import {
  Radio,
  Checkbox,
  Form,
  Row,
  Col,
  Select,
} from "antd";
import {
  FontVariant,
  getFontFamilyName,
} from "@surge-global-engineering/css-generator";
import { addDefaultFontToDropCap } from "../helpers";
import { observer } from "mobx-react";

const { Option } = Select;

const ParagraphSetting = () => {
  const bodyFonts = addDefaultFontToDropCap(true);

  return (
      <div className="theme-section-tab-content">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
            className="at-form-item" 
            name="decorations" 
            label="First sentence"
          >
                <Checkbox.Group>
                  <Checkbox value="dropcap">Drop caps</Checkbox>
                  <Checkbox value="smallcaps">
                    Lead in small caps
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                  name="dropCapfont"
                  label="Different font for Drop Caps"
                >
                  <Select placeholder="Select">
                    {bodyFonts.map((font, i) => (font.availableForDropCap && ( 
                      <Option
                        className={getFontFamilyName(font._id, FontVariant.regular)}
                        value={font._id}
                        key={i}
                      >
                        {font._id}
                      </Option>
                    )))}
                  </Select>
                </Form.Item>
            </Col>
          </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item
            className="at-form-item"
            name="beginFirstSentence"
            label="When to use the first sentence formatting"
          >
            <Radio.Group buttonStyle="solid">
              <Radio value="chapter">Beginning of chapter</Radio>
              <Radio value="break">
                Every paragraph following a break
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item 
            className="at-form-item"
            name="firstline" 
            label="Subsequent paragraphs"
          >
            <Radio.Group>
              <Radio value="indented">Indented</Radio>
              <Radio value="spaced">Spaced</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
     </div>
  );
};

export default observer(ParagraphSetting);
