import React, { useState, useMemo } from "react";
import useRootStore from "../../../../../store/useRootStore";
import { Row, Col } from "antd";
import {
  HeadingToolbar,
  Plate,
  PlateProvider,
  createTEditor,
  withPlate,
} from "@udecode/plate";
import { endNotesPlugins } from "../../../endnotesConfigure";
import { MyEditor, PlateNodes, MyRootBlock } from "../../../config/typescript";
import { EndNotesToolbar } from "../../../partials";
import { CONFIG } from "../../../config/config";
import { Modal } from "../../../../Shared/Modal";
import { Button } from "../../../../Shared/Buttons";
interface EndNotesUpsertProps {
  isInsert?: boolean;
  currentNote?: MyRootBlock[];
  open: boolean;
  addNote?: (note: PlateNodes | null) => void;
  onCancel: () => void;
  updateNote?: (note: PlateNodes | null) => void;
}

/**
 * Modal used to add or edit endnotes
 * @param {string} props - props passed to the component
 * @param {string} [currentNote] - current note to edit
 * @param {boolean} [isInsert] - if the note is being inserted or edited
 * @param {boolean} [open] - if the modal is visible, default is false
 * @param {function} [addNote] - function to add a note, only used when isInsert is true
 * @param {function} onCancel - function to close the modal
 * @param {function} [updateNote] - function to update a note, only used when isInsert is false
 */
const EndnotesUpsertModal: React.FC<EndNotesUpsertProps> = ({
  currentNote,
  isInsert,
  open,
  addNote,
  onCancel,
  updateNote,
}) => {
  const editor = useMemo(
    () => withPlate(createTEditor() as MyEditor, { plugins: endNotesPlugins }),
    []
  );
  const { activeTheme: theme } = useRootStore().themeStore;
  const [editorValue, setValue] = useState<PlateNodes | null>(null);

  const onClick = () => {
    if (isInsert) {
      if (addNote === undefined || !editorValue) {
        console.error("addNote is undefined");       
        return;
      }
      addNote(editorValue);
    } else {
      if (updateNote === undefined) {
        console.error("updateNote is undefined");
        return;
      }
      updateNote(editorValue);
    }
  };

  const { children } = editor;

  const isEmpty = editorValue == null || children.length === 1 &&
      children[0].children.length === 1 &&
      children[0].children[0].text === "";

  const onClose = () => {
    //setNote("");
    onCancel();
  };

  return (
    <Modal
      title={<b>{isInsert ? "Insert" : "Update"} {
        theme?.properties?.notesMode === "FOOTNOTE" ? "footnote" : "endnote"
      }</b>}
      open={open}
      onCancel={onClose}
      leftBtn={{
        type: "at-secondary",
        backgroundColor: "green",
        onClick: onClose,
        children: "Cancel",
      }}
      rightBtn={{
        type: "at-primary",
        backgroundColor: "green",
        onClick: onClick,
        disabled: isEmpty,
        children: isInsert ? "Insert" : "Update",
      }}
    >
      <div className="endnotes-editor-container">
        <PlateProvider
          editor={editor}
          id={"endNote"}
          plugins={endNotesPlugins}
          initialValue={currentNote }
          onChange={(editorValue) => {
            setValue(editorValue);
          }}
        >
          <Row className="editor-header">
            <Col>
              <HeadingToolbar className="plate-toolbar-overrides">
                <EndNotesToolbar />
              </HeadingToolbar>
            </Col>
            <Col className="editor-settings-toolbar-container"></Col>
          </Row>
          <div className="editor-col">
            <div className="editor-area-end-note" >
              <Plate
                id={"endNote"}
                editableProps={{
                  ...CONFIG.editableProps,
                }}
              >
              </Plate>
            </div>
          </div>        
        </PlateProvider>
      </div>
    </Modal>
  );
};

export default EndnotesUpsertModal;
