export const checkHttps = (content: any) => {
  if (
    content.toLowerCase().startsWith("https://") ||
    content.toLowerCase().startsWith("http://")
  ) {
    const regexp =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?!&//=]*)/gi;
    const urls = content.match(regexp);
    if (urls) {
      return true;
    } else {
      return false;
    }
  } else if (content.toLowerCase().startsWith("mailto:")) {
    return true;
  } else {
    return false;
  }
};
