import { TextMessageNode } from "../../../components/Plate/plugins/text-message/types";
import { parseTextNode } from "./textNode";

export const parseTextMessages = (
  textMessagesNode: ISlateStore.SlateTextMessages
): string => {
  const { style, children } = textMessagesNode;
  let parsedNode = "<div class='text-messages-container'>";

  children.forEach((message: TextMessageNode) => {
    let bubbleClass = "message-bubble";
    let wrapperClass = "";
    if (message.messageType === "contact") {
      bubbleClass += " contact-bubble";
      if (message.nextMessageType === "sent") {
        bubbleClass += " sent";
        wrapperClass += "sent";
      }
    } else if (message.messageType === "sent") {
      bubbleClass += " sent";
      wrapperClass += "sent";

      if (style === "ios-ios") {
        bubbleClass += " ios-sent-bubble";
      } else if (style === "ios-android") {
        bubbleClass += " ios-android-sent-bubble";
      } else if (style === "android") {
        bubbleClass += " android-sent-bubble";
      }
    } else if (message.messageType === "received") {
      if (style === "ios-ios") {
        bubbleClass += " ios-received-bubble";
      } else if (style === "ios-android") {
        bubbleClass += " ios-android-received-bubble";
      } else if (style === "android") {
        bubbleClass += " android-received-bubble";
      }
    }

    parsedNode += `<div class='${wrapperClass}'>`;
    parsedNode += `<p class='${bubbleClass}'>`;

    for (const child of message.children) {
      parsedNode += parseTextNode(child);
    }

    parsedNode += "</p>";
    parsedNode += "</div>";
  });

  parsedNode += "</div>";
  return parsedNode;
};
