import React, { useState } from "react";
import { Row } from "antd";
import { TextMessagesModalProps } from "../types";
import { Modal } from "../../../../Shared/Modal";
import { Button } from "../../../../Shared/Buttons";
import {
  AtticusRadioButtonProps,
  AtticusRadioGroup,
} from "../../../../Shared/Form";

export const TextMessagesModal = ({
  element,
  onUpdate,
  onClose,
}: TextMessagesModalProps) => {
  const [styleSelection, setStyleSelection] = useState(element.style);

  const onSubmit = () => {
    onUpdate(styleSelection);
    onClose();
  };

  const radioButtons: AtticusRadioButtonProps[] = [
    {
      children: "iOS - iOS",
      value: "ios-ios",
    },
    {
      children: "iOS - Android",
      value: "ios-android",
    },
    {
      children: "Android",
      value: "android",
    },
  ];

  return (
    <Modal
      open={true}
      onCancel={() => {
        onClose();
      }}
      cancelText="Cancel"
      title={<b>Select Text Message Style</b>}
    >
      <Row>
        <AtticusRadioGroup
          buttons={radioButtons}
          optionType="default"
          className="custom-radio-secondary"
          value={styleSelection}
          onChange={(e) => setStyleSelection(e.target.value)}
        />
      </Row>
      <div className="spacer"></div>
      <Row>
        <Button
          type="at-primary"
          backgroundColor="green"
          onMouseDown={onSubmit}
          fullWidth
        >
          Update text message
        </Button>
      </Row>
    </Modal>
  );
};
