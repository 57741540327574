import { PlateEditor, Value } from "@udecode/plate";

const DISABLED_TYPES = ["ul", "ol"];

export const allowFeatureForList = (editor: PlateEditor<Value>): boolean => {
  const focusPoint = editor.selection?.focus;

  let enabled = true;

  if (typeof focusPoint !== "undefined") {
    enabled = !DISABLED_TYPES.includes(
      editor.children[focusPoint.path[0]].type
    );
  }

  return enabled;
};
