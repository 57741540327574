import { createPluginFactory } from "@udecode/plate-core";
import { TextMessageComponent } from "./components/textMessageComponent";
import { TextMessagesGroupComponent } from "./components/textMessagesGroupComponent";
import { withTextMessagesGroup } from "./normalizers/withTextMessagesGroup";
import { withTextMessage } from "./normalizers/withTextMessage";

export const ELEMENT_TEXT_MESSAGES_GROUP = "text_messages";
export const ELEMENT_TEXT_MESSAGE = "text_message";

export const createTextMessagePlugin = createPluginFactory({
  key: "ELEMENT_TEXT_MESSAGE",
  plugins: [
    {
      key: ELEMENT_TEXT_MESSAGE,
      isElement: true,
      type: ELEMENT_TEXT_MESSAGE,
      component: TextMessageComponent,
      withOverrides: withTextMessage,
    },
    {
      key: ELEMENT_TEXT_MESSAGES_GROUP,
      isElement: true,
      type: ELEMENT_TEXT_MESSAGES_GROUP,
      component: TextMessagesGroupComponent,
      withOverrides: withTextMessagesGroup,
    },
  ],
});
