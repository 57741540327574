import React from "react";

interface AnchorPoints {
    top: number;
    left: number;
}

function getIsElementInside (currentCoordinates: DOMRect, clientX: number, clientY: number) {
    const {top, left, bottom, right} = currentCoordinates;

    return clientX >= left && clientX <= right && clientY >= top && clientY <= bottom;
}

const useContextMenu = (itemRef: React.RefObject<HTMLAnchorElement> , isContextMenuEnabled = true ): [boolean, AnchorPoints?] => {
    const [displayContextMenu, setDisplayContextMenu] = React.useState(false);
	const [anchorPoints, setAnchorPoints] = React.useState<AnchorPoints>();

	const handleContextMenuEvent = (event: MouseEvent) => {
		if(!event.target || !itemRef.current){
			return;
        } 

        const isElementInside = getIsElementInside(itemRef.current.getBoundingClientRect(), event.clientX, event.clientY);

        if (isElementInside) {
          const viewportHeight = window.innerHeight;
          const currentAnchorPoints = {
            top: Math.min(event.pageY, viewportHeight - 130),
            left: event.pageX,
          };
          event.preventDefault();
          setAnchorPoints(currentAnchorPoints);
          setDisplayContextMenu(true);
        }  
	};

    const handleOutOfFocusEvents = (event: MouseEvent) => {
        if(itemRef.current && event.target){
            const isElementInside = getIsElementInside(itemRef.current.getBoundingClientRect(), event.clientX, event.clientY);

            if(!isElementInside){
                setDisplayContextMenu(false);
            }  
        }
    };

	React.useEffect(() => {
        if(!isContextMenuEnabled) return;

		itemRef.current?.addEventListener("contextmenu", handleContextMenuEvent);

		return () => {
			itemRef.current?.removeEventListener("contextmenu", handleContextMenuEvent, false);
			
		};
	}, [handleContextMenuEvent, itemRef]);

    React.useEffect(() => {
        if(!isContextMenuEnabled) return;
        
        document.addEventListener("click", () => setDisplayContextMenu(false));
        document.addEventListener("contextmenu", handleOutOfFocusEvents);

        return () => {
            document.removeEventListener("click", () => setDisplayContextMenu(false), false);
            document.removeEventListener("contextmenu", () => handleContextMenuEvent, false);
        };
    }, [setDisplayContextMenu, handleContextMenuEvent, itemRef]);
    return [displayContextMenu, anchorPoints];
};

export default useContextMenu;