import React from "react";

interface BookActionItemProps {
  icon: React.ReactNode;
  label: string;
  isDisabled?: boolean;
  onClick: () => void;
}

export const BookActionItem: React.FC<BookActionItemProps> = ({icon, label, onClick, isDisabled}) => {
  return (
    <div
      className={`book-action-item${isDisabled ? " disabled" : ""}`}
      onClick={!isDisabled ? onClick : undefined}
    >
      <span className='book-action-icon'>{icon}</span>
      <p className='book-action-label'>{label}</p>
    </div>
  );
};
