import { makeAutoObservable } from "mobx";

import { AtticusClient } from "../api/atticus.api";
import { db } from "../db/bookDb";

export class FontStore {
  userFavouriteFonts: IFontStore.FontItem[] = [];
  googleFonts: IFontStore.FontItem[] = [];
  showFontGallery = false;
  totalGoogleFontsCount = 0;
  search = "";
  category: string[] = [];
  page = 0;
  count = 60;
  loading = false;
  googleFontCount = 0;
  favoriteFontCount = 0;
  lastQueryWasSearchOrCategory = false;

  constructor() {
    makeAutoObservable(this);
  }
  
  get googleFavoritedFonts() {
    const { search, category, googleFavFnts } = this;
    if (!search && !category.length) {
      this.setFavoriteFontCount(googleFavFnts.length);
      return googleFavFnts;
    }
    const lowercaseSearch = search.toLowerCase();

    const filterResult = googleFavFnts.filter(font => {
      const lowercaseId = font.family.toLowerCase();
      const matchesSearch = !search || lowercaseId.includes(lowercaseSearch);
      const matchesCategory = !category.length || category.some(cat => font.category === cat);
      return matchesSearch && matchesCategory;
    });

    this.setFavoriteFontCount(filterResult.length);
    return filterResult;
  }

  setUserFavouriteFonts = (fonts: IFontStore.FontItem[]): void => {
    const googleFonts = fonts.filter(font => font.source === "google");
    const atticusFonts = fonts.filter(font => font.source === "atticus");
    const sortedGoogleFonts = googleFonts.sort((a, b) => a.family.localeCompare(b.family));
    const sortedAtticusFonts = atticusFonts.sort((a, b) => a.family.localeCompare(b.family));
    const sortedFonts = [...sortedGoogleFonts, ...sortedAtticusFonts];
    this.userFavouriteFonts = sortedFonts;
  };

  setGoogleFonts = (fonts: IFontStore.FontItem[], query: Partial<IFontStore.FontQuery>): void => {
    const currentQueryIsSearchOrCategory = query.category || query.search;
    if (currentQueryIsSearchOrCategory) {
      if (!this.lastQueryWasSearchOrCategory) {
          // Initial search/filter query, replace googleFonts
          this.googleFonts = [];
          this.googleFonts = fonts;
          this.lastQueryWasSearchOrCategory = true;
      } else {
          // Right after search/filter query, add fonts to existing list
          this.googleFonts = fonts;
      }
    } else {
      this.lastQueryWasSearchOrCategory = false;
      if (this.page === 0) {
          this.googleFonts = fonts;
          this.lastQueryWasSearchOrCategory = false;
      } else {
          // Add fonts to existing list for rest of the pages
          this.googleFonts = [...this.googleFonts, ...fonts];
      }
  }
  };

  toggleLoading = (loading: boolean) => {
    this.loading = loading;
  };

  setFontQuery = (query: Partial<IFontStore.FontQuery>) => {
    Object.keys(query).forEach((key) => {
      this[key] = query[key];
    });
  };

  setPage = (page: number) => {
    this.page = page;
  };

  setFavoriteFontCount = (favCount: number) => {
    this.favoriteFontCount = favCount;
  };

  getFavoriteFontCount = () => {
    return this.favoriteFontCount;
  };

  setGoogleFontCount = (totalCount: number) => {
    this.googleFontCount = totalCount;
  };

  getGoogleFontCount = () => {
    return this.googleFontCount;
  };

  get googleFavFnts () {
    return this.userFavouriteFonts.filter(font => font.source === "google");
  }

  loadUserFavouriteFonts = async (): Promise<void> => {
    try {
      const fonts = await this.getFavoritedFonts();
      this.setUserFavouriteFonts(fonts);
      const dbPromises: Promise<unknown>[] = [];

      fonts.forEach(font => {
        dbPromises.push(db.userFavouriteFonts.put(font));
      });

      await Promise.all(dbPromises);
    } catch (error) {
      console.error("Error fetching atticus fonts:", error);
    }
  }

  loadGoogleFonts = async (query: Partial<IFontStore.FontQuery>) => {
    try {
      const {allFonts, totalCount } = await this.getGoogleFonts();
      this.setGoogleFonts(allFonts, query);
      this.setGoogleFontCount(totalCount);

      return {
        totalCount
      };
    } catch (error) {
      console.error("Error fetching google fonts:", error);
    }
    return {
      totalCount: 0
    };
  }

  doFontSearch = async (query: Partial<IFontStore.FontQuery>) => {
    this.setFontQuery(query);

    return await this.loadGoogleFonts(query);
  }

  toggleFontGallery = () => {
    this.showFontGallery = !this.showFontGallery;
  }

  addToFavorites = async (font: IFontStore.FontItem) => {
    try {
      // Can only favorite not more than 60 fonts
      if(this.googleFavFnts.length < 61){
        const response = await this.invokeAddToFavorite(font._id);
        const withNewFavoritedFonts = [
          font,
          ...this.userFavouriteFonts
        ];
        this.setUserFavouriteFonts(withNewFavoritedFonts);
        this.loadUserFavouriteFonts();
      }
    } catch (error) {
      console.error("Error Adding Font to Favorites", error);
    }
  }

  removeFromFavorites = async (fontId: string) => {
    try {
      const response = await this.invokeRemoveFromFavorites(fontId);
      this.setUserFavouriteFonts(
        this.userFavouriteFonts.filter(font => font._id !== fontId)
      );

    } catch (error) {
      console.error("Error Removing Font to Favorites", error);
    }
  }

  getFavoritedFonts = async () => await AtticusClient.getFavoritedFonts();

  getGoogleFonts = async () => await AtticusClient.getGoogleFonts({
    page: this.page,
    count: this.count,
    category: this.category,
    search: this.search
  });

  invokeAddToFavorite = async (fontId: string) => await AtticusClient.AddFontToFavorite(fontId);

  invokeRemoveFromFavorites = async (fontId: string) => await AtticusClient.RemoveFontFromFavorite(fontId);
}

export default new FontStore();