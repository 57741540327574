export function getBookSubheads(
    chapters: IChapterStore.Chapter[]
  ): ISlateStore.SlateSubheading[] {
    const subheads: ISlateStore.SlateSubheading[] = [];
    for (const chapter of chapters) {
      const chapterSubheads = getSubheadsOfChapter(
        chapter.children as ISlateStore.SlateBaseParentBlockNode[],
        chapter._id
      );
      subheads.push(...chapterSubheads);
    }
    return subheads;
  }
  
  export function getSubheadsOfChapter(
    children: ISlateStore.SlateBaseParentBlockNode[],
    chapterId: string
  ): ISlateStore.SlateSubheading[] {
    const subheads: ISlateStore.SlateSubheading[] = [];
    for (const child of children) {
      const { type } = child;
      if (type === "h2") {
        subheads.push({
          ...(child as ISlateStore.SlateSubheading),
          chapterId: chapterId
        });
      }
      const _child = child as ISlateStore.SlateBaseParentBlockNode;
      if (!_child.children) continue;
      subheads.push(
        ...getSubheadsOfChapter(
          _child.children as ISlateStore.SlateBaseParentBlockNode[],
          chapterId
        )
      );
    }
    return subheads;
  }
  