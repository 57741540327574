import React from "react";
import { Input, InputProps } from "antd";

interface AtticusInputProps extends InputProps {
  inputType?: "primary" | "secondary";
}

export const AtticusTextField: React.FC<AtticusInputProps> = (props) => {
  return (
    <Input {
      ...{
        ...props,
        className: `at-form-input ${props.inputType}`
      }}
    />
  );
};
