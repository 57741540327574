import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Divider, Form, Row, Select } from "antd";
import { Colorpicker, ColorPickerValue } from "antd-colorpicker";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { observer } from "mobx-react";
import { CalloutModalStore } from "../../../../../store/CalloutModal";

import {
  backgroundPresetColors,
  borderPresetColors,
  selectItems,
} from "./constant";
import {
  AtticusCheckBox,
  AtticusSlider,
} from "../../../../Shared/Form";

interface CalloutBoxPropertiesProps {
  calloutStore: CalloutModalStore;
}

const { Option } = Select;

export const CalloutBoxProperties: FunctionComponent<CalloutBoxPropertiesProps> =
  observer(({ calloutStore }) => {
    const [backgroundColor, setBackgroundColor] = useState<ColorPickerValue>();

    const [borderColor, setBorderColor] = useState<ColorPickerValue>({});

    const handleOnChangeBackgroundColor = (color: ColorPickerValue) => {
      setBackgroundColor(color);
      calloutStore.setFillColor(color.hex);
      calloutStore.setFillOpacity(color.rgb.a);
    };

    const handleOnChangeBorderColor = (color: ColorPickerValue) => {
      setBorderColor(color);
      calloutStore.setBorderColor(color.hex);
      calloutStore.setBorderOpacity(color.rgb.a);
    };

    const handleFillChange = (event: CheckboxChangeEvent) => {
      calloutStore.setFill(event.target.checked);
    };

    const handleBorderChange = (event: CheckboxChangeEvent) => {
      calloutStore.setBorder(event.target.checked);
    };

    const onChangeWidth = (value: number | null) => {
      if (value) {
        calloutStore.setBorderWidth(value);
      }
    };

    const onChangeRadius = (value: number | null) => {
      if (value !== null) {
        calloutStore.setBorderRadius(value);
      } else {
        calloutStore.setBorderRadius(0);
      }
    };

    // get border style icon
    const getBorderStyleIcon = (borderStyle) => {
      switch (borderStyle) {
        case "solid":
          return selectItems[0].icon;
        case "dotted":
          return selectItems[1].icon;
        case "dashed":
          return selectItems[2].icon;
        case "double":
          return selectItems[3].icon;
        default:
          return "Select";
      }
    };

    const borderMenuStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    // convert hex to rgba
    const convertHexToRGBA = (hex: string, alpha: number) => {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return { r, g, b, a: alpha };
    };

    // set initial values
    useEffect(() => {
      setBackgroundColor(
        convertHexToRGBA(
          calloutStore.background.fillColor,
          calloutStore.background.fillOpacity
        )
      );
      setBorderColor(
        convertHexToRGBA(
          calloutStore.border.borderColor,
          calloutStore.border.borderOpacity
        )
      );
    }, [
      calloutStore.background.fillColor,
      calloutStore.background.fillOpacity,
      calloutStore.border.borderColor,
      calloutStore.border.borderOpacity,
    ]);

    return (
      <Form.Item className='cl-properties'>
        {/* callout background row */}
        <Row align='stretch' gutter={20}>
          <Col className='callout-property-cl'>
            <AtticusCheckBox
              type='secondary'
              checked={calloutStore.background.fill}
              onChange={handleFillChange}
            />
            <div className='cl-label'>Fill</div>
          </Col>
          <Col className='callout-property-cl'>
            <Colorpicker
              popup
              presetColors={backgroundPresetColors}
              blockStyles={{
                width: "32px",
                height: "20px",
                borderRadius: "2px",
              }}
              value={backgroundColor}
              onChange={handleOnChangeBackgroundColor}
            />
            <div className='cl-label'>Fill color</div>
          </Col>
        </Row>

        <Divider />

        {/* Border style row */}
        <Row align='stretch' gutter={20}>
          <Col className='callout-property-cl'>
            <AtticusCheckBox
              type='secondary'
              checked={calloutStore.border.border}
              onChange={handleBorderChange}
            />
            <div className='cl-label'>Border</div>
          </Col>
          <Col className='callout-property-cl'>
            <Colorpicker
              popup
              presetColors={borderPresetColors}
              blockStyles={{
                width: "32px",
                height: "20px",
                borderRadius: "2px",
              }}
              value={borderColor}
              onChange={handleOnChangeBorderColor}
            />
            <div className='cl-label'>Border color</div>
          </Col>
          <Col className='callout-property-cl'>
            <div className='cl-label-style'>Border style</div>
            <div className='style-selector'>
              <Select
                placeholder={
                  <div className='select-option'>
                    {calloutStore.border.borderStyle
                      ? getBorderStyleIcon(calloutStore.border.borderStyle)
                      : "Select"}
                  </div>
                }
                size='large'
                value={calloutStore.border.borderStyle}
                onChange={(value) => calloutStore.setBorderStyle(value)}
                className='select-dropdown'
              >
                {selectItems.map((item) => (
                  <Option
                    value={item.value}
                    key={item.key}
                    style={borderMenuStyle}
                  >
                    <div className='select-option'>{item.icon}</div>
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>

        {/* Border weight row */}
        <div className='border-settings'>
          <Row align='middle' className='1border-weight-row'>
            <Col span={7} className='slider-label'>
              Border thickness
            </Col>
            <Col span={15}>
              <AtticusSlider
                color='green'
                min={1}
                max={10}
                onChange={onChangeWidth}
                value={
                  typeof calloutStore.border.borderWidth === "number"
                    ? calloutStore.border.borderWidth
                    : 0
                }
              />
            </Col>
            <Col>
              <div className='slider-value'>
                {calloutStore.border.borderWidth}
              </div>
            </Col>
          </Row>

          {/* Border radius row */}
          <Row align='middle' className='border-radius-row'>
            <Col span={7} className='slider-label'>
              Corner roundness
            </Col>
            <Col span={15}>
              <AtticusSlider
                color='green'
                min={0}
                max={20}
                onChange={onChangeRadius}
                value={
                  typeof calloutStore.border.borderRadius === "number"
                    ? calloutStore.border.borderRadius
                    : 0
                }
              />
            </Col>
            <Col>
              <div className='slider-value'>
                {calloutStore.border.borderRadius}
              </div>
            </Col>
          </Row>
        </div>
      </Form.Item>
    );
  });
