import React from "react";

import "./style.scss";

export const SubheadingComponent = (props: any) => {
  const { id, children } = props;
  return (
    <h2 className="plate-custom-subheading-block" id={id}>
      {children}
    </h2>
  );
};
