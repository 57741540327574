import { handleElementNode, handleTextNode } from "./nodeHandleHelpers";
import { wrapSlateListItems } from "./wrapSlateListItems";

export const preprocessHtml = (html: string): string => {
    const div = document.createElement("div");
    div.innerHTML = html;
    Array.from(div.childNodes).forEach((node) => processHtmlNode(node, div));
    wrapSlateListItems(div);
    return div.innerHTML;
};

const processHtmlNode = (node: ChildNode, parent: HTMLElement): void => {
    if (node.nodeType === Node.TEXT_NODE) {
        handleTextNode(node, parent);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
        handleElementNode(node as HTMLElement);
    }
};