import React, { useState, useCallback, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  notification,
  RadioChangeEvent,
  message,
  Radio,
} from "antd";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { observer } from "mobx-react";
import { cloneDeep } from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import { AtticusTextField, AtticusSelect as Select } from "../../../../Shared/Form";

import useRootStore from "../../../../../store/useRootStore";
import { SocialMediaAccount } from "./socialMediaAccount";
import { Button } from "../../../../Shared/Buttons";


const socialMediaPlatforms = [
  {
    id: "0",
    name: "Amazon",
    username: "https://www.amazon.com/",
    iconSize: "",
  },
  {
    id: "1",
    name: "BookBub",
    username: "https://www.bookbub.com/",
    iconSize: "",
  },
  {
    id: "2",
    name: "Facebook",
    username: "https://www.facebook.com/",
    iconSize: "",
  },
  {
    id: "3",
    name: "Goodreads",
    username: "https://www.goodreads.com/",
    iconSize: "",
  },
  {
    id: "4",
    name: "Instagram",
    username: "https://www.instagram.com/",
    iconSize: "",
  },
  {
    id: "5",
    name: "LinkedIn",
    username: "https://www.linkedin.com/",
    iconSize: "",
  },
  {
    id: "6",
    name: "Patreon",
    username: "https://www.patreon.com/",
    iconSize: "",
  },
  {
    id: "7",
    name: "Pinterest",
    username: "https://www.pinterest.com/",
    iconSize: "",
  },
  {
    id: "8",
    name: "Snapchat",
    username: "https://www.snapchat.com/",
    iconSize: "",
  },
  {
    id: "9",
    name: "TikTok",
    username: "https://www.tiktok.com/",
    iconSize: "",
  },
  {
    id: "10",
    name: "Twitter",
    username: "https://www.twitter.com/",
    iconSize: "",
  },
  {
    id: "11",
    name: "YouTube",
    username: "https://www.youtube.com/",
    iconSize: "",
  },
];

const selectFilterSMOptions = (input, option) => {
  if (option?.children) {
    return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
  return false;
};

const selectSortSMOptions = (optionA, optionB) => {
  if (optionA.children && optionB.children) {
    return optionA.children
      .toLowerCase()
      .localeCompare(optionB.children.toLowerCase());
  }
};

export const AddNewProfile = observer(() => {
  const {
    selectedSocialProfileId,
    userSMProfiles,
    editSMProfileInDB,
    addNewSMProfileToDB,
    modalState,
    resetModal
  } = useRootStore().socialProfileStore;
  const [selectedSMProfile, setSelectedSMProfile] =
    useState<ISocialProfileStore.ISMProfile | null>(null);
  const [profileName, setProfileName] = useState<string>("");
  const [selectedPlatforms, setSelectedPlatforms] = useState<
    ISocialProfileStore.ISMAccount[]
  >([]);
  const [iconSize, setIconSize] =
    useState<ISocialProfileStore.IconSize>("small");
  const [printAlign, setPrintAlign] =
    useState<ISocialProfileStore.PrintAlign>("left");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedSocialProfileId) {
      const selectedProfile = userSMProfiles.find((profile) => {
        return profile._id === selectedSocialProfileId;
      });
      if (selectedProfile) {
        setSelectedSMProfile(selectedProfile);
      }
    }
  }, [selectedSocialProfileId]);

  useEffect(() => {
    if (selectedSMProfile) {
      // component state values
      setProfileName(selectedSMProfile.profileName);
      setSelectedPlatforms(selectedSMProfile.smAccounts);
      setIconSize(selectedSMProfile.smAccounts[0].iconSize);
      setPrintAlign((prev) => selectedSMProfile.printAlign || prev);
      //form values
      form.setFieldsValue({ form_profileName: selectedSMProfile.profileName });
      form.setFieldsValue({
        iconSize: selectedSMProfile.smAccounts[0].iconSize,
      });
      form.setFieldsValue({
        printAlign: selectedSMProfile.printAlign || printAlign,
      });
    }
  }, [selectedSMProfile]);

  const handleChangeChapterType = useCallback((e: RadioChangeEvent) => {
    setIconSize(e.target.value);
  }, []);

  const handleChangePrintAlignment = (e: RadioChangeEvent) => {
    setPrintAlign(e.target.value);
  };

  const pushSelectedMedia = (platformName: string) => {
    const selectedPlatform = socialMediaPlatforms.find((plaform) => {
      return plaform.name === platformName;
    });

    if (selectedPlatform) {
      setSelectedPlatforms((s) => {
        return [...s, selectedPlatform];
      });
    }
  };

  const removeItem = (id) => {
    const result = selectedPlatforms.filter((media) => media.id !== id);
    setSelectedPlatforms(result);
  };

  const getOptions = () => {
    const selectedMediaIds = selectedPlatforms.map((media) => media.id);
    return socialMediaPlatforms.filter(
      (platform) => !selectedMediaIds.includes(platform.id)
    );
  };

  const reorderSMAccounts = (
    startIndex: number,
    endIndex: number
  ): ISocialProfileStore.ISMAccount[] => {
    const profiles = cloneDeep(selectedPlatforms);
    const [dragged] = profiles.splice(startIndex, 1);
    profiles.splice(endIndex, 0, dragged);
    return profiles;
  };

  const dndOndragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }
    const reorderedAccounts = reorderSMAccounts(
      result.source.index,
      result.destination.index
    );
    setSelectedPlatforms(reorderedAccounts);
  };

  const saveProfile = async (values) => {
    if (selectedPlatforms.length === 0) {
      const notify = notification["error"]({
        message: "You must add at least one account to save the profile",
      });
      return notify;
    }

    if (profileName === "") {
      const notify = notification["error"]({
        message: "Please enter profile name to save the profile",
      });
      return notify;
    }

    try {
      setLoading(true);
      const formItems = { ...values };
      const selectedPlatformsClone = [...selectedPlatforms];

      // update username and iconSize for each of the social media platforms
      for (const itemKey in formItems) {
        selectedPlatformsClone.forEach((media, index) => {
          if (media.id == itemKey) {
            selectedPlatformsClone[index].username = formItems[itemKey];
            selectedPlatformsClone[index].iconSize = iconSize;
          }
        });
      }

      // save edited or new profile to DB
      if (modalState === "editProfile") {
        // update existing profile
        if (selectedSMProfile) {
          const editedProfile: ISocialProfileStore.ISMProfile = {
            _id: selectedSMProfile?._id,
            profileName,
            smAccounts: selectedPlatformsClone,
            printAlign,
          };
          await editSMProfileInDB(editedProfile);
        }
      } else if (modalState === "addNewProfile") {
        const newProfile: ISocialProfileStore.ICreateSMProfile = {
          profileName,
          smAccounts: selectedPlatformsClone,
          printAlign,
        };
        await addNewSMProfileToDB(newProfile);
      }
    } catch (error) {
      message.error("Failed to update profile");
    }
  };

  return (
    <div className="create-prof-div">
      <Form
        form={form}
        onFinish={(val) => saveProfile(val)}
        initialValues={{ form_profileName: profileName }}
        layout="vertical"
      >
        <Form.Item className="prof-select" name="formSelect">
          <Select
            showSearch
            placeholder="Search to select"
            optionFilterProp="children"
            filterOption={selectFilterSMOptions}
            filterSort={selectSortSMOptions}
            onSelect={(platformName) => {
              if (typeof platformName === "string") {
                pushSelectedMedia(platformName);
              }
            }}
            selectOptions={getOptions().map((platform) => ({
              label: platform.name,
              value: platform.name
            }))} 
          />
        </Form.Item>

        <DragDropContext onDragEnd={dndOndragEnd}>
          <Droppable droppableId="smAccount">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="draggable-container scroller"
              >
                {selectedPlatforms !== undefined &&
                  selectedPlatforms.map((account, i) => (
                    <SocialMediaAccount
                      account={account}
                      removeItem={removeItem}
                      key={account.id}
                      index={i}
                    />
                  ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Row>
          <Col flex={"auto"}>
            <Form.Item
              label="Icon size"
              name="iconSize"
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                defaultValue="small"
                onChange={handleChangeChapterType}
                className="antd-full-width-radio-group"
              >
                <Radio.Button value="small">Small</Radio.Button>
                <Radio.Button value="medium">Medium</Radio.Button>
                <Radio.Button value="large">Large</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Print alignment"
          name="printAlign"
        >
          <Radio.Group
            defaultValue="left"
            onChange={handleChangePrintAlignment}
            className="custom-radio-secondary"
          >
            <Radio value="left">Left</Radio>
            <Radio value="center">Center</Radio>
          </Radio.Group>
        </Form.Item>

        <Row>
          <Col flex={"auto"}>
            <Form.Item name="form_profileName" label="Profile name">
              <AtticusTextField
                placeholder="Profile name"
                onChange={(e) => setProfileName(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="form_profileName">
              <Button type="at-secondary" backgroundColor="green" onClick={()=>resetModal(true)} fullWidth>
                Go back
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button type="at-primary" backgroundColor="green" loading={loading} htmlType="submit" fullWidth>
                Save this profile
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
});
