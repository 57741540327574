import { AtticusClient } from "../api/atticus.api";
import { makeAutoObservable } from "mobx";
import { IChapterTemplateBase } from "../types/chapter";

import { SaveTemplateToDB, syncChapterTemplateChangesWithLocalChapters } from "../utils/sync";

import { db } from "../db/bookDb";
import { GetAllChapterTemplates, SaveChapterTemplateToDB, UpdateChapterMeta } from "../utils/offline.book.helpers";
import { bookStore } from ".";
import { cloneDeep } from "lodash";

export class ChapterStore {
  id = null;
  saving = false;
  loading = true;
  books: IBookStore.Book[] = [];
  chapterTemplates: IChapterTemplateBase[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  getChapter = async () => {
    return {
      ...this,
    };
  };

  setLoading = (loading: boolean): void => {
    this.loading = loading;
  };

  setSaving = (saving: boolean): void => {
    this.saving = saving;
  };

  storeChapterTemplate = async (
    chapterId: string,
    templateName: string,
    section: string
  ): Promise<string> => {
    const templateOutput = await AtticusClient.CreateChapterTemplate(
      chapterId,
      templateName,
      section
    );
    const chapterTemplates = await AtticusClient.GetChapterTemplates();

    const currentTemplate = chapterTemplates.find(
      (template) => template._id === templateOutput.templateId
    );

    if (currentTemplate) {
      await SaveChapterTemplateToDB(currentTemplate);
    }
    this.loadTemplates();

    return templateOutput.templateId;
  };

  storeChapterFromTemplate = async (
    templateId: string,
    bookId: string
  ): Promise<boolean> => {
    await AtticusClient.CreateChapterFromTemplate(templateId, bookId);
    return true;
  };

  setChapterTemps = (templates: IChapterTemplateBase[]): void => {
    this.chapterTemplates = templates;
  };

  // fetch functions
  loadTemplates = async (): Promise<void> => {
    this.setLoading(true);
    const allChapterTemplatesServer = await AtticusClient.GetChapterTemplates();
    const allChapterTemplates = await GetAllChapterTemplates();

    const mergedChapterTemplates: IChapterTemplateBase[] = [
      ...(allChapterTemplates || []),
    ];

    allChapterTemplatesServer.forEach(async (serverTemplate) => {
      const localTemplate = mergedChapterTemplates.find(
        (mergedTemplate) => mergedTemplate._id === serverTemplate._id
      );

      if (!localTemplate) {
        mergedChapterTemplates.push(serverTemplate);
        await SaveChapterTemplateToDB(serverTemplate);
      }
    });

    this.setChapterTemps(mergedChapterTemplates || []);
    this.setLoading(false);
  };

  syncChapterTemplate = async (
    shouldUpdateAllBooks: boolean,
    shouldUpdateTemplate: boolean,
    bookId: string,
    templateId?: string,
    chapterId?: string,
  ): Promise<void> => {
    try {
      this.setLoading(true);
      await AtticusClient.SyncChapterTemplate(
        shouldUpdateAllBooks,
        shouldUpdateTemplate,
        bookId,
        templateId,
        chapterId,
      );
      await SaveTemplateToDB();
      if(!templateId) throw new Error("Missing template Id");
      await syncChapterTemplateChangesWithLocalChapters(templateId, bookId, shouldUpdateAllBooks);
    } catch (e: any) {
      console.log(e);
    }
    this.setLoading(false);
    await this.loadTemplates();
  };

  deleteChapterTemplate = async (templateId: string): Promise<void> => {
    try {
      this.setLoading(true);
      const deleteChapTemp = await AtticusClient.DeleteChapterTemplate(
        templateId
      );

      if (deleteChapTemp) {
        await db.chapterTemplates.where("_id").equals(templateId).delete();
        const chapterMetas = await db.chapterMetas.toArray();

        const allPromises: Promise<void>[] = [];

        chapterMetas.forEach((meta) => {
          if (meta.templateId === templateId) {
            allPromises.push(
              UpdateChapterMeta(meta._id, { templateId: undefined })
            );

            if (bookStore.chapter._id === meta._id) {
              const chapterClone = cloneDeep(meta);
              delete chapterClone.templateId;

              bookStore.setChapter(chapterClone);
            }
          }
        });

        await Promise.all(allPromises);
      }
    } catch (e: any) {
      console.log(e);
    }
    this.setLoading(false);
    await this.loadTemplates();
  };
}
export default new ChapterStore();
