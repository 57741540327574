export interface Fonts {
  _id: string,
  css: string,
  name: string,
  before?: number,
  after?: number
} 

export const trimSizes = [
  {
    category: "Popular trim sizes",
    sizes: [
      {
        width: 5,
        height: 8,
        kdp: true,
        ingramSpark:true
      },
      {
        width: 5.25,
        height: 8,
        kdp: true,
        ingramSpark:true
      },
      {
        width: 5.5,
        height: 8.5,
        kdp: true,
        ingramSpark:true
      },
      {
        width: 6,
        height: 9,
        kdp: true,
        ingramSpark:true
      }
    ]
  },
  {
    category: "Additional trim sizes",
    sizes: [
      {
        width: 5.06,
        height: 7.81,
        kdp: true,
        ingramSpark:true
      },
      {
        width: 5.5,
        height: 8.25,
        kdp: false,
        ingramSpark:true
      },
      {
        width: 6.14,
        height: 9.21,
        kdp: false,
        ingramSpark:false
      },
    ]
  },
  {
    category: "International sizes",
    sizes: [
      {
        width: 4.72,
        height: 7.48,
        kdp: false,
        ingramSpark:true
      },
      {
        width: 4.92,
        height: 7.48,
        kdp: false,
        ingramSpark: false
      },
      {
        width: 5.83,
        height: 8.27,
        kdp: false,
        ingramSpark:true
      },
      {
        width: 5.31,
        height: 8.46,
        kdp: false,
        ingramSpark: false
      },
    ]
  },
  {
    category: "Mass market paperbacks",
    sizes: [
      {
        width: 4.12,
        height: 6.75,
        kdp: false,
        ingramSpark: false
      },
      {
        width: 4.25,
        height: 7,
        kdp: false,
        ingramSpark: true
      },
      {
        width: 4.37,
        height: 7,
        kdp: false,
        ingramSpark: true
      },
    ]
  },
  {
    category: "Children's book trim sizes",
    sizes: [
      {
        width: 8.5,
        height: 8.5,
        kdp: true,
        ingramSpark: true
      },
      {
        width: 8,
        height: 10,
        kdp: true,
        ingramSpark: true
      },
      {
        width: 8.5,
        height: 11,
        kdp: true,
        ingramSpark: true
      },
    ]
  },
];