import React, { FunctionComponent, ReactNode } from "react";

import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
//import { Tooltip } from '@material-ui/core';

interface TooltipIconProps{
  title: string;
  children: ReactNode;
  placement?: TooltipPlacement;
  zIndex?: number
}

export const TooltipIcon: FunctionComponent<TooltipIconProps> = ({ title, children, placement, zIndex}: TooltipIconProps) => {
	return(
		<Tooltip 
			placement={placement ?? "bottom"}
			title={title}
			zIndex={zIndex}
		>
			<div>
				{children}
			</div>
		</Tooltip>
	);
};