export const isNullOrUndefined = (value: string | null | undefined): boolean => {
  return value === null || value === undefined;
};

export const isEmpty = (value: string | null | undefined): boolean => {
  return value === "" || isNullOrUndefined(value);
};

export const ellipsizeTitle = (value: string, maxLength?: number): string => {
  const MAX_TEXT_LENGTH = maxLength || 25;

  if (value.length <= MAX_TEXT_LENGTH) return value;

  return `${value.split("").splice(0, MAX_TEXT_LENGTH).join("").trim()}...`;
};
