import React, { useEffect, useState } from "react";
import { Checkbox, Col, Form, Row, Spin } from "antd";
import { observer } from "mobx-react";
import useRootStore from "../../../../../store/useRootStore";
import { AllPropertiesRequired } from "../../../../../utils/helper";
import {
  formatNumberWithThousandSeparator,
  habitWritingDays,
  createWritingHabitConfiguration,
  alteredWritingHabitConfiguration,
} from "../../helpers";
import { ConfigurationFormFields } from "../../types";
import { BackgroundColor, Button, ButtonType } from "../../../../Shared/Buttons";
import { AtticusNumberField } from "../../../../Shared/Form/InputNumber";
import { ExitToView } from "../Header/Tools";

interface ConfigurationFormProps {
  isEditView: boolean;
}

const ConfigurationForm: React.FC<ConfigurationFormProps> = observer(
  ({ isEditView }) => {
    const [form] = Form.useForm<ConfigurationFormFields>();

    const { book, getFullBookWordCount } = useRootStore().bookStore;
    const {
      createNewServerWritingHabitData,
      showOfflineModal,
      isOnline,
      writingHabitState,
      applyBookChangeRelatedChanges,
      switchToViewState,
      stateLock,
    } = useRootStore().writingHabitStore;

    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
      if (!isEditView || !writingHabitState) return;

      form.setFieldValue("wordsPerDay", writingHabitState.goalCount);
      form.setFieldValue("writeDays", writingHabitState.writeDays);
    }, [isEditView, writingHabitState]);

    const onConfigurationCreate = async (
      formValues: ConfigurationFormFields
    ) => {
      if (!isOnline) {
        return showOfflineModal(
          "You must be connected to the internet to create a habit."
        );
      }
      if (!formValues.wordsPerDay) return;

      setSubmitting(true);

      const totalInitialWordCount = await getFullBookWordCount();

      const initialHabitDataConfiguration = createWritingHabitConfiguration(
        formValues as AllPropertiesRequired<ConfigurationFormFields>,
        book._id,
        totalInitialWordCount
      );

      await createNewServerWritingHabitData(initialHabitDataConfiguration);

      setSubmitting(false);
    };

    const onConfigurationUpdate = async (
      formValues: ConfigurationFormFields
    ) => {
      if (!isOnline) {
        return showOfflineModal(
          "You must be connected to the internet to modify a habit."
        );
      }

      if (!formValues.wordsPerDay || !writingHabitState) return;

      const totalInitialWordCount = await getFullBookWordCount();

      let habit = writingHabitState;

      if (book._id !== writingHabitState._id) {
        habit = applyBookChangeRelatedChanges(totalInitialWordCount, book._id);
      }

      const updatedHabitDataConfiguration = alteredWritingHabitConfiguration(
        formValues as AllPropertiesRequired<ConfigurationFormFields>,
        habit
      );

      switchToViewState(updatedHabitDataConfiguration, true);

      setSubmitting(false);
    };

    return (
      <Spin spinning={stateLock && isEditView}>
        {
          isEditView? 
          <Row justify="space-between" align="middle">
            <Col span={22}>
            <h4> Edit Writing Habit</h4>
            </Col>
            <Col span={2}>
              <ExitToView/>
            </Col>
          </Row> : null
        }
        <Form
          form={form}
          className="writing-habit-configuration-form goal-setting-form"
          layout={"vertical"}
          onFinish={isEditView ? onConfigurationUpdate : onConfigurationCreate}
          requiredMark={"optional"}
        >
          {isEditView && (
            <Row>
              <Col flex="auto">
                <span className="warn-edit">
                  You&apos;ll lose your current streak if you edit the Habit.
                </span>
              </Col>
            </Row>
          )}
          <Row>
            <Col flex={"auto"}>
            <Row>
                <h5 className="setting-form-label">Word count per day</h5>
            </Row>
              <Form.Item
                name="wordsPerDay"
                rules={[
                  { required: true, message: "Please enter the word count!" },
                ]}
                requiredMark={"optional"}
              >
                <AtticusNumberField type="secondary"/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col flex="auto">
            <Row>
                <h5 className="setting-form-label">Writing days</h5>
            </Row>
              <Form.Item
                name="writeDays"
                className="goal-setting-check-btns"
                rules={[
                  {
                    required: true,
                    message: "Please select the writing days!",
                  },
                ]}
              >
                <Checkbox.Group options={habitWritingDays} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col flex="auto">
              <Button
                block
                type={ButtonType.PRIMARY}
                backgroundColor={BackgroundColor.GREEN}
                htmlType="submit"
                disabled={submitting}
              >
                {submitting ? "Saving..." : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  }
);

export { ConfigurationForm };
