import { Remote, releaseProxy, wrap } from "comlink";
import { EpubGeneratorWorker } from "./epub-generator.worker";

export let exportEpubWorker : Worker | null;
export let exportEpubWorkerApi: Remote<EpubGeneratorWorker>;

const initiateWorker = (): void => {
  exportEpubWorker = new Worker("./epub-generator.worker", {
    type: "module",
  });
  exportEpubWorkerApi = wrap<EpubGeneratorWorker>(exportEpubWorker);
};

export const register = (): void => {
  initiateWorker();
};

export const detachWorker = (): void => {
  exportEpubWorkerApi[releaseProxy]();
  exportEpubWorker?.terminate();
};
