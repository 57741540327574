import React, { FunctionComponent } from "react";
import { SvgIcon } from "@material-ui/core";
import { BorderOutlined, CheckCircleTwoTone, ScanOutlined, SyncOutlined, WarningTwoTone, MessageOutlined, UserOutlined } from "@ant-design/icons";

interface IconProps {
    size?: number;
    active?: boolean;
}

export const BoldIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon viewBox="0 0 150 150" >
		<path id="Path_101" data-name="Path 101" className="cls-1" d="M91.33,141.1A34.42,34.42,0,0,0,129,110.22a34.8,34.8,0,0,0,.12-5,31.67,31.67,0,0,0-24.9-32.32,29.43,29.43,0,0,0,22.38-30.32A33.85,33.85,0,0,0,92.71,8.73a34.59,34.59,0,0,0-3.52.19H20.89V141.15ZM83.15,61.42H48.52V33.64H83.15A13.75,13.75,0,0,1,98.27,45.88a12.93,12.93,0,0,1,.08,1.65,13.52,13.52,0,0,1-15.2,13.89Zm1.21,54.92H48.52V86.19h35.8a14.94,14.94,0,0,1,16.46,13.25,15.66,15.66,0,0,1,.08,1.81A14.55,14.55,0,0,1,87,116.45a14.16,14.16,0,0,1-2.68-.13Z" />
	</SvgIcon>
);

export const ItalicIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon viewBox="0 0 150 150" >
		<path id="Path_102" data-name="Path 102" className="cls-1" d="M68.29,141.27,99.1,8.73H81.71L50.9,141.27Z" />
	</SvgIcon>
);

export const UnderlineIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon viewBox="0 0 150 150" >
		<path id="Path_103" data-name="Path 103" className="cls-1" d="M75,115.81c31.44,0,46.86-18,46.86-44.75V5.73H102.56V70.58A25.63,25.63,0,0,1,75,98.92,25.62,25.62,0,0,1,47.44,70.58V5.73H28.18V71.06C28.18,97.77,43.71,115.81,75,115.81ZM16.63,134.13H133.37v10.14H16.63Z" />
	</SvgIcon>
);

/* strikethroughs and baseline alignment */

export const StrikethroughIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon viewBox="0 0 150 150" >
		<path id="Path_104" data-name="Path 104" className="cls-1" d="M63,143.3V104.91H15.92V92.11H63V27.81H21.66V6.7H128.34V27.81H87v64.3h47.1v12.8H87V143.3Z" />
	</SvgIcon>
);

export const SuperscriptIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon scale={0.1} viewBox="0 -21 469.33144 469" >
		<path d="m453.332031 192.527344h-96c-8.832031 0-16-7.167969-16-16v-40.191406c0-30.867188 25.085938-55.976563 55.894531-55.976563h16.210938c13.164062 0 23.894531-10.773437 23.894531-24 0-13.441406-10.730469-24.195313-23.894531-24.195313h-56.105469c-8.832031 0-16-7.167968-16-16 0-8.832031 7.167969-16 16-16h56.105469c30.804688 0 55.894531 25.109376 55.894531 55.980469 0 31.082031-25.089843 56.214844-55.894531 56.214844h-16.210938c-13.164062 0-23.894531 10.75-23.894531 23.976563v24.191406h80c8.832031 0 16 7.167968 16 16 0 8.832031-7.167969 16-16 16zm0 0" />
		<path d="m261.351562 427.707031c-4.734374 0-9.40625-2.089843-12.5625-6.058593l-245.335937-310.015626c-5.480469-6.957031-4.308594-17.003906 2.605469-22.484374 6.929687-5.527344 17.023437-4.289063 22.484375 2.601562l245.332031 310.015625c5.484375 6.933594 4.308594 17.003906-2.601562 22.484375-2.945313 2.328125-6.464844 3.457031-9.921876 3.457031zm0 0" />
		<path d="m15.976562 427.707031c-3.457031 0-6.976562-1.128906-9.917968-3.457031-6.914063-5.480469-8.085938-15.550781-2.605469-22.484375l245.335937-310.015625c5.460938-6.871094 15.550782-8.105469 22.484376-2.601562 6.910156 5.480468 8.085937 15.550781 2.601562 22.484374l-245.332031 310.015626c-3.15625 3.96875-7.832031 6.058593-12.566407 6.058593zm0 0" />
	</SvgIcon>
);

export const SubscriptIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon scale={0.1} viewBox="0 -21 469.32982 469" >
		<path d="m453.328125 427.714844h-96c-8.832031 0-16-7.167969-16-16v-40.214844c0-30.871094 25.066406-55.980469 55.894531-55.980469h16.214844c13.183594 0 23.890625-10.75 23.890625-23.976562 0-13.441407-10.707031-24.191407-23.890625-24.191407h-56.109375c-8.832031 0-16-7.167968-16-16 0-8.832031 7.167969-16 16-16h56.109375c30.824219 0 55.890625 25.109376 55.890625 55.976563 0 31.082031-25.066406 56.191406-55.890625 56.191406h-16.214844c-13.183594 0-23.894531 10.753907-23.894531 23.980469v24.214844h80c8.832031 0 16 7.167968 16 16 0 8.832031-7.167969 16-16 16zm0 0" />
		<path d="m261.328125 342.1875c-4.714844 0-9.386719-2.089844-12.542969-6.058594l-245.332031-310.015625c-5.484375-6.933593-4.308594-17.003906 2.625-22.484375 6.910156-5.503906 16.980469-4.308594 22.460937 2.601563l245.335938 310.015625c5.480469 6.933594 4.308594 17.003906-2.625 22.484375-2.921875 2.347656-6.421875 3.457031-9.921875 3.457031zm0 0" />
		<path d="m15.996094 342.1875c-3.476563 0-6.976563-1.128906-9.917969-3.457031-6.933594-5.480469-8.109375-15.550781-2.625-22.484375l245.332031-310.015625c5.484375-6.910157 15.53125-8.105469 22.464844-2.601563 6.933594 5.480469 8.105469 15.550782 2.625 22.484375l-245.335938 310.015625c-3.15625 3.988282-7.828124 6.058594-12.542968 6.058594zm0 0" />
	</SvgIcon>
);

// Block toolbar icon
export const SubheadingIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon viewBox="0 0 150 150" >
		<g id="Group_39" data-name="Group 39">
			<path className="cls-1" d="M90.67,41.76H17A12.87,12.87,0,0,1,17,16H90.67a12.87,12.87,0,1,1,0,25.74Z" />
			<path className="cls-1" d="M139.48,104H12.15a6.44,6.44,0,0,1,0-12.87H139.48a6.44,6.44,0,0,1,0,12.87Z" />
			<path className="cls-1" d="M139.48,73.93H12.15a6.44,6.44,0,0,1,0-12.87H139.48a6.44,6.44,0,0,1,0,12.87Z" />
			<path className="cls-1" d="M139.48,134H12.15a6.44,6.44,0,0,1,0-12.87H139.48a6.44,6.44,0,0,1,0,12.87Z" />
		</g>
	</SvgIcon>
);

export const UnorderedListIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon viewBox="0 0 150 150" >
		<g id="Group_42" data-name="Group 42">
			<path className="cls-1" d="M136.16,41.72H53a8.32,8.32,0,0,1,0-16.64h83.2a8.32,8.32,0,0,1,0,16.64Z" />
			<path className="cls-1" d="M136.16,83.32H53a8.32,8.32,0,0,1,0-16.64h83.2a8.32,8.32,0,0,1,0,16.64Z" />
			<path className="cls-1" d="M136.16,124.92H53a8.32,8.32,0,1,1,0-16.64h83.2a8.32,8.32,0,0,1,0,16.64Z" />
			<path className="cls-1" d="M18,45.87c-.4,0-.82,0-1.22-.06a11.74,11.74,0,0,1-1.21-.18,12.64,12.64,0,0,1-2.34-.71,12.32,12.32,0,0,1-2.15-1.15c-.34-.23-.67-.47-1-.73a12.41,12.41,0,0,1-1.72-1.73c-.26-.32-.51-.65-.74-1a12.66,12.66,0,0,1-1.85-4.49,12.18,12.18,0,0,1-.19-1.21q-.06-.61-.06-1.23c0-.4,0-.82.06-1.22A12.18,12.18,0,0,1,5.77,31a12.64,12.64,0,0,1,.71-2.34,13,13,0,0,1,1.14-2.15,12.14,12.14,0,0,1,.74-1,10.05,10.05,0,0,1,.82-.91,11.79,11.79,0,0,1,.9-.83c.33-.25.66-.5,1-.72a12.32,12.32,0,0,1,2.15-1.15,12.64,12.64,0,0,1,2.34-.71A11.74,11.74,0,0,1,16.78,21a11.15,11.15,0,0,1,2.45,0,12.16,12.16,0,0,1,1.21.18A12.44,12.44,0,0,1,24.93,23c.34.22.67.47,1,.72a11.94,11.94,0,0,1,.91.83,12.52,12.52,0,0,1,1.55,1.9,13,13,0,0,1,1.15,2.15A13.45,13.45,0,0,1,30.24,31a12.16,12.16,0,0,1,.18,1.21c0,.4.06.82.06,1.22s0,.82-.06,1.23a12.16,12.16,0,0,1-.18,1.21,13.72,13.72,0,0,1-.71,2.34,13,13,0,0,1-1.15,2.15,11.45,11.45,0,0,1-.73,1,11.61,11.61,0,0,1-.82.91,11.81,11.81,0,0,1-.91.82c-.32.26-.65.5-1,.73a12.44,12.44,0,0,1-4.49,1.86,12.16,12.16,0,0,1-1.21.18C18.83,45.85,18.41,45.87,18,45.87Z" />
			<path className="cls-1" d="M18,87.48a10.1,10.1,0,0,1-1.22-.07,11.74,11.74,0,0,1-1.21-.18,12.64,12.64,0,0,1-2.34-.71,12.32,12.32,0,0,1-2.15-1.15c-.34-.22-.67-.47-1-.72a13.26,13.26,0,0,1-1.72-1.74c-.26-.32-.51-.65-.74-1a13,13,0,0,1-1.14-2.15,12.64,12.64,0,0,1-.71-2.34,12.18,12.18,0,0,1-.19-1.21c0-.4-.06-.82-.06-1.22s0-.82.06-1.23a12.18,12.18,0,0,1,.19-1.21,12.66,12.66,0,0,1,1.85-4.49c.23-.34.48-.67.74-1s.53-.62.82-.91a11.67,11.67,0,0,1,.9-.82c.33-.26.66-.51,1-.73a12.32,12.32,0,0,1,2.15-1.15,12.64,12.64,0,0,1,2.34-.71,11.74,11.74,0,0,1,1.21-.18,11.15,11.15,0,0,1,2.45,0,12.16,12.16,0,0,1,1.21.18,12.44,12.44,0,0,1,4.49,1.86,10.1,10.1,0,0,1,1,.73,11.81,11.81,0,0,1,.91.82c.29.29.57.6.83.91s.5.65.72,1a13,13,0,0,1,1.15,2.15,13.72,13.72,0,0,1,.71,2.34,12.16,12.16,0,0,1,.18,1.21q.06.62.06,1.23c0,.4,0,.82-.06,1.22a12.16,12.16,0,0,1-.18,1.21,13.45,13.45,0,0,1-.71,2.34,13,13,0,0,1-1.15,2.15c-.22.34-.47.67-.72,1a13.41,13.41,0,0,1-1.74,1.74c-.32.25-.65.5-1,.72a12.44,12.44,0,0,1-4.49,1.86,12.16,12.16,0,0,1-1.21.18A10,10,0,0,1,18,87.48Z" />
			<path className="cls-1" d="M18,129.08c-.4,0-.82,0-1.22-.06a11.74,11.74,0,0,1-1.21-.19,12.64,12.64,0,0,1-2.34-.71A12.32,12.32,0,0,1,11.08,127c-.34-.22-.67-.47-1-.72s-.62-.54-.9-.83a11.81,11.81,0,0,1-.82-.91c-.26-.32-.51-.65-.74-1a13,13,0,0,1-1.14-2.15A12.78,12.78,0,0,1,5.77,119c-.08-.4-.15-.81-.19-1.22s0-.81,0-1.22,0-.82,0-1.23.11-.81.19-1.21a12.66,12.66,0,0,1,1.85-4.49c.23-.34.48-.67.74-1s.53-.62.82-.91a11.67,11.67,0,0,1,.9-.82c.33-.26.66-.5,1-.73a12.32,12.32,0,0,1,2.15-1.15,12.64,12.64,0,0,1,2.34-.71,11.74,11.74,0,0,1,1.21-.18,12.54,12.54,0,0,1,2.45,0,12.16,12.16,0,0,1,1.21.18,12.44,12.44,0,0,1,4.49,1.86c.34.23.67.47,1,.73a11.81,11.81,0,0,1,.91.82,11.61,11.61,0,0,1,.82.91,11.45,11.45,0,0,1,.73,1,13,13,0,0,1,1.15,2.15,13.72,13.72,0,0,1,.71,2.34c.08.4.14.81.18,1.21s.07.82.07,1.23,0,.82-.07,1.22-.1.82-.18,1.22a13.61,13.61,0,0,1-.71,2.33,13,13,0,0,1-1.15,2.15,11.45,11.45,0,0,1-.73,1,10.05,10.05,0,0,1-.82.91,11.94,11.94,0,0,1-.91.83c-.32.25-.65.5-1,.72a12.44,12.44,0,0,1-4.49,1.86,12.18,12.18,0,0,1-1.21.19C18.83,129.06,18.41,129.08,18,129.08Z" />
		</g>
	</SvgIcon>
);

export const OrderedListIcon: FunctionComponent<IconProps> = ({ size = 18 }: IconProps) => (
	<SvgIcon viewBox="0 0 24 24" width={size} height={size} >
		<g id="Group_42" data-name="Group 42">
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M2 17h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1 3h1.8L2 13.1v.9h3v-1H3.2L5 10.9V10H2v1zm5-6v2h14V5H7zm0 14h14v-2H7v2zm0-6h14v-2H7v2z" />
		</g>
	</SvgIcon>
);

export const OrnamentalBreakIcon: FunctionComponent<IconProps> = ({ size = 15 }: IconProps) => (
	<SvgIcon viewBox="0 0 150 150" >
		<g id="Group_38" data-name="Group 38">
			<path className="cls-1" d="M77.18,79.84a9.68,9.68,0,0,1-9.68-9.68V12.09a9.68,9.68,0,0,1,19.36,0V70.16A9.68,9.68,0,0,1,77.18,79.84Z" />
			<path className="cls-1" d="M75,81.82a9.62,9.62,0,0,1-4.83-1.29L13.21,47.64a9.68,9.68,0,1,1,9.68-16.76l57,32.88A9.68,9.68,0,0,1,75,81.82Z" />
			<path className="cls-1" d="M18.06,114.71a9.68,9.68,0,0,1-4.85-18.06l57-32.89a9.68,9.68,0,0,1,9.68,16.77L22.89,113.41A9.66,9.66,0,0,1,18.06,114.71Z" />
			<path className="cls-1" d="M77.18,147.58a9.67,9.67,0,0,1-9.68-9.67V70.16a9.68,9.68,0,0,1,19.36,0v67.75A9.68,9.68,0,0,1,77.18,147.58Z" />
			<path className="cls-1" d="M131.94,114.71a9.66,9.66,0,0,1-4.83-1.3L70.16,80.53a9.68,9.68,0,0,1,9.68-16.77l56.95,32.89a9.68,9.68,0,0,1-4.85,18.06Z" />
			<path className="cls-1" d="M75,81.82a9.68,9.68,0,0,1-4.85-18.06l57-32.88a9.68,9.68,0,0,1,9.68,16.76L79.84,80.53A9.62,9.62,0,0,1,75,81.82Z" />
		</g>
	</SvgIcon>
);

export const PageBreakIcon: FunctionComponent<IconProps> = ({ size = 15 }: IconProps) => (
	<SvgIcon style={{width:size, height: size, verticalAlign: "middle", overflow: "hidden"}} viewBox="0 0 1024 1024">
    <g id="Group_38" data-name="Group 38">
      <path d="M896 460.8h-768C85.6576 460.8 51.2 426.3424 51.2 384v-307.2a25.6 25.6 0 0 1 51.2 0v307.2a25.6 25.6 0 0 0 25.6 25.6h768a25.6 25.6 0 0 0 25.6-25.6v-307.2a25.6 25.6 0 0 1 51.2 0v307.2c0 42.3424-34.4576 76.8-76.8 76.8z"/>
      <path d="M76.8 563.2h-51.2a25.6 25.6 0 0 1 0-51.2h51.2a25.6 25.6 0 0 1 0 51.2z" />
      <path d="M230.4 563.2h-51.2a25.6 25.6 0 0 1 0-51.2h51.2a25.6 25.6 0 0 1 0 51.2z" />
      <path d="M384 563.2h-51.2a25.6 25.6 0 0 1 0-51.2h51.2a25.6 25.6 0 0 1 0 51.2z" />
      <path d="M537.6 563.2h-51.2a25.6 25.6 0 0 1 0-51.2h51.2a25.6 25.6 0 0 1 0 51.2z" />
      <path d="M691.2 563.2h-51.2a25.6 25.6 0 0 1 0-51.2h51.2a25.6 25.6 0 0 1 0 51.2z"/>
      <path d="M844.8 563.2h-51.2a25.6 25.6 0 0 1 0-51.2h51.2a25.6 25.6 0 0 1 0 51.2z"/>
      <path d="M998.4 563.2h-51.2a25.6 25.6 0 0 1 0-51.2h51.2a25.6 25.6 0 0 1 0 51.2z"/>
      <path d="M947.2 1024a25.6 25.6 0 0 1-25.6-25.6v-307.2a25.6 25.6 0 0 0-25.6-25.6h-768a25.6 25.6 0 0 0-25.6 25.6v307.2a25.6 25.6 0 0 1-51.2 0v-307.2c0-42.3424 34.4576-76.8 76.8-76.8h768c42.3424 0 76.8 34.4576 76.8 76.8v307.2a25.6 25.6 0 0 1-25.6 25.6z" />
    </g>
	</SvgIcon>
);

export const ImageIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon viewBox="0 0 150 150" >
		<g id="Group_40" data-name="Group 40">
			<path className="cls-1" d="M130.55,132.9H19.05a15,15,0,0,1-15-15V32.11a15,15,0,0,1,15-15h111.5a15,15,0,0,1,15,15v85.78A15,15,0,0,1,130.55,132.9ZM19.05,30a2.14,2.14,0,0,0-2.15,2.14v85.78A2.14,2.14,0,0,0,19.05,120h111.5a2.14,2.14,0,0,0,2.14-2.14V32.11A2.14,2.14,0,0,0,130.55,30Z" />
			<path className="cls-1" d="M15,113.69l-9.1-9.1L39.54,71a6.42,6.42,0,0,1,9.1,0L73.75,96.08,107.8,62a6.44,6.44,0,0,1,9.1,0l27.18,27.18-9.1,9.1L112.35,75.67l-34,34.05a6.44,6.44,0,0,1-9.1,0L44.09,84.61Z" />
			<circle id="Ellipse_8" data-name="Ellipse 8" className="cls-1" cx="66.22" cy="53.56" r="12.87" />
		</g>
	</SvgIcon>
);

export const LeftAlignIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon viewBox="0 0 150 150" >
		<g id="Group_91" data-name="Group 91">
			<path className="cls-1" d="M134.76,26.73H15.24a8.05,8.05,0,0,1,0-16.1H134.76a8.05,8.05,0,1,1,0,16.1Z" />
			<path className="cls-1" d="M134.76,101.82H15.24a8,8,0,1,1,0-16.09H134.76a8,8,0,1,1,0,16.09Z" />
			<path className="cls-1" d="M93.65,64.27H15.24a8,8,0,1,1,0-16.09H93.65a8,8,0,1,1,0,16.09Z" />
			<path className="cls-1" d="M93.65,139.37H15.24a8.05,8.05,0,1,1,0-16.1H93.65a8.05,8.05,0,0,1,0,16.1Z" />
		</g>
	</SvgIcon>
);

export const CenterAlignIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon viewBox="0 0 150 150" >
		<g id="Group_41" data-name="Group 41">
			<path className="cls-1" d="M111.55,35.58H36.27a6.58,6.58,0,0,1,0-13.15h75.28a6.58,6.58,0,0,1,0,13.15Z" />
			<path className="cls-1" d="M128.4,96.9H21.22a6.57,6.57,0,0,1,0-13.14H128.4a6.57,6.57,0,0,1,0,13.14Z" />
			<path className="cls-1" d="M140,66.24H10A6.57,6.57,0,0,1,10,53.1H140a6.57,6.57,0,1,1,0,13.14Z" />
			<path className="cls-1" d="M111.55,127.57H36.69a6.58,6.58,0,0,1,0-13.15h74.86a6.58,6.58,0,0,1,0,13.15Z" />
		</g>
	</SvgIcon>
);

export const RightAlignIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon viewBox="0 0 150 150" >
		<g id="Group_92" data-name="Group 92">
			<path className="cls-1" d="M133.86,26.73H14.34a8.05,8.05,0,0,1,0-16.1H133.86a8.05,8.05,0,0,1,0,16.1Z" />
			<path className="cls-1" d="M133.86,101.82H14.34a8,8,0,1,1,0-16.09H133.86a8,8,0,1,1,0,16.09Z" />
			<path className="cls-1" d="M135.66,64.27H57.25a8,8,0,1,1,0-16.09h78.41a8,8,0,1,1,0,16.09Z" />
			<path className="cls-1" d="M135.66,139.37H57.25a8.05,8.05,0,1,1,0-16.1h78.41a8.05,8.05,0,1,1,0,16.1Z" />
		</g>
	</SvgIcon>
);

export const BlockquoteIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon viewBox="0 0 150 150" >
		<g id="Group_46" data-name="Group 46">
			<path className="cls-1" d="M135.66,57.36H99.08a8.26,8.26,0,0,1,0-16.51h36.58a8.26,8.26,0,0,1,0,16.51Z" />
			<path className="cls-1" d="M135.66,98.62H16.71a8.25,8.25,0,1,1,0-16.5h119a8.25,8.25,0,1,1,0,16.5Z" />
			<path className="cls-1" d="M135.66,139.88H16.7a8.25,8.25,0,1,1,0-16.5h119a8.25,8.25,0,0,1,0,16.5Z" />
			<path id="Path_96" data-name="Path 96" className="cls-1" d="M22.7,33.93A16.62,16.62,0,0,1,39.32,50.55h0A16.62,16.62,0,0,1,22.7,67.17h0A16.63,16.63,0,0,1,6.08,50.55h0c0-23.48,20.18-40.74,23.8-40.43S18.51,33.93,22.7,33.93Z" />
			<path id="Path_97" data-name="Path 97" className="cls-1" d="M62.57,33.93A16.62,16.62,0,0,1,79.19,50.55h0A16.62,16.62,0,0,1,62.57,67.17h0A16.62,16.62,0,0,1,46,50.55h0c0-23.48,20.18-40.74,23.8-40.43S58.38,33.93,62.57,33.93Z" />
		</g>
	</SvgIcon>
);

export const VerseIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon viewBox="0 0 150 150" >
		<g id="Group_80" data-name="Group 80">
			<path className="cls-1" d="M135,29H15A10.3,10.3,0,1,1,15,8.39H135A10.3,10.3,0,1,1,135,29Z" />
			<path className="cls-1" d="M135,64.91H15a10.3,10.3,0,1,1,0-20.59H135a10.3,10.3,0,1,1,0,20.59Z" />
			<path className="cls-1" d="M135,100.83H15a10.3,10.3,0,1,1,0-20.59H135a10.3,10.3,0,1,1,0,20.59Z" />
			<path className="cls-1" d="M135,141.61H75a7.72,7.72,0,1,1,0-15.44h60a7.72,7.72,0,1,1,0,15.44Z" />
		</g>
	</SvgIcon>
);

export const CalloutBoxIcon: FunctionComponent<IconProps> = () => (
  <svg
    version='1.0'
    xmlns='http://www.w3.org/2000/svg'
    width='512pt'
    height='512pt'
    viewBox='0 0 512 512'
    preserveAspectRatio='xMidYMid meet'
  >
    <g
      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='125'
    >
      <path
        d='M396 5104 c-181 -44 -337 -202 -381 -385 -13 -56 -15 -325 -15 -2159
0 -2310 -4 -2158 63 -2284 41 -78 135 -172 213 -213 126 -67 -26 -63 2284 -63
2310 0 2158 -4 2284 63 78 41 172 135 213 213 67 126 63 -26 63 2284 0 1834
-2 2103 -15 2159 -44 186 -200 342 -386 386 -94 22 -4231 21 -4323 -1z m4315
-230 c67 -31 124 -87 156 -152 l28 -57 0 -2105 0 -2105 -21 -46 c-31 -67 -87
-124 -152 -156 l-57 -28 -2105 0 -2105 0 -57 28 c-65 32 -121 89 -152 156
l-21 46 0 2105 0 2105 21 46 c42 91 127 161 215 178 24 5 980 8 2124 7 l2080
-1 46 -21z'
      />
      <path
        d='M3155 3811 c-28 -16 -236 -232 -243 -250 -3 -9 595 -591 608 -591 12
0 219 217 240 253 25 40 25 93 3 133 -18 32 -424 425 -472 456 -34 23 -98 23
-136 -1z'
      />
      <path
        d='M2256 2888 l-475 -493 302 -303 c166 -166 305 -301 308 -300 12 6
954 990 954 998 0 9 -600 590 -609 590 -3 0 -219 -222 -480 -492z'
      />
      <path
        d='M1547 2154 c-3 -5 -46 -166 -96 -359 -91 -351 -121 -489 -108 -501 5
-5 767 236 804 255 8 4 -102 122 -291 310 -167 167 -306 299 -309 295z'
      />
    </g>
  </svg>

);

export const HangingIndentIcon: FunctionComponent<IconProps> = () => (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="2 2 20 20">
  <rect x="0" y="0" width="29" height="29" fill="rgba(255, 255, 255, 0)" />
  <path fill="currentColor" d="M10 15h10v2H10zm-6 4h16v2H4zm6-8h10v2H10zm0-4h10v2H10zM4 3h16v2H4zm0 5v8l4-4z"/>
</svg>
);

export const LinkIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon viewBox="0 0 150 150" >
		<g id="Group_37" data-name="Group 37">
			<g id="Group_36" data-name="Group 36">
				<path id="Path_77" data-name="Path 77" className="cls-1" d="M63.46,110.46l-15.9,15.9A16.9,16.9,0,1,1,23.3,102.82l.36-.36L55.53,70.58a16.92,16.92,0,0,1,23.9,0A5.64,5.64,0,0,0,88,63.2a7.66,7.66,0,0,0-.57-.57,28.17,28.17,0,0,0-39.84,0L15.64,94.52A28.18,28.18,0,0,0,55.5,134.36h0l15.89-15.9A5.63,5.63,0,1,0,64,110a4.94,4.94,0,0,0-.56.56Z" />
				<path className="cls-1" d="M35.57,147a32.59,32.59,0,0,1-23-55.63l31.9-31.89a32.63,32.63,0,0,1,46-.1,10.55,10.55,0,0,1,.88.89A10.05,10.05,0,0,1,76.22,73.62a12.5,12.5,0,0,0-17.57.09L26.46,105.89a12.5,12.5,0,0,0,.28,17.66,12.46,12.46,0,0,0,17.65-.27l16-15.94h0c.25-.26.5-.51.77-.74a10.06,10.06,0,0,1,14.18,1,10.07,10.07,0,0,1-.88,14.06l-15.8,15.8a32.37,32.37,0,0,1-23,9.55ZM67.46,58.81a23.7,23.7,0,0,0-16.8,7L18.77,97.64a23.76,23.76,0,0,0,16.8,40.56h0a23.57,23.57,0,0,0,16.8-7L68.5,115.13a1.22,1.22,0,0,0,.12-1.72,1.18,1.18,0,0,0-.83-.42,1.2,1.2,0,0,0-.89.29l-.11.12-.9-.51.7.69-15.9,15.9A21.32,21.32,0,0,1,20.13,99.74l.45-.45L52.41,67.46a21.34,21.34,0,0,1,30.14,0l.22.23a1.22,1.22,0,1,0,1.84-1.6A.57.57,0,0,0,84.5,66l-.24-.22A23.7,23.7,0,0,0,67.46,58.81Z" />
				<path id="Path_78" data-name="Path 78" className="cls-1" d="M134.36,15.62a28.19,28.19,0,0,0-39.85,0L75.42,34.75a5.64,5.64,0,0,0,8,8l19.14-19.11a16.9,16.9,0,0,1,23.9,23.9L91.35,82.56a16.9,16.9,0,0,1-23.9,0,5.64,5.64,0,1,0-8.52,7.38,7.66,7.66,0,0,0,.57.57,28.17,28.17,0,0,0,39.84,0L134.4,55.44A28.17,28.17,0,0,0,134.36,15.62Z" />
				<path className="cls-1" d="M79.42,103.16a32.53,32.53,0,0,1-23-9.43,10.66,10.66,0,0,1-.88-.9A10.05,10.05,0,0,1,70.66,79.52a12.51,12.51,0,0,0,17.57-.08l35.06-35.07a12.49,12.49,0,0,0-8.82-21.14h-.19a12.37,12.37,0,0,0-8.69,3.53L86.26,46a10.1,10.1,0,0,1-13.17,0,10,10,0,0,1-1-14.17l.21-.23L91.38,12.5a32.64,32.64,0,0,1,46.1,0h0a32.63,32.63,0,0,1,.05,46.07L102.46,93.63A32.5,32.5,0,0,1,79.42,103.16ZM63.19,85a1.2,1.2,0,0,0-.8.3,1.22,1.22,0,0,0-.12,1.72l.13.13.22.2a23.77,23.77,0,0,0,33.6,0l35.06-35.06a23.79,23.79,0,0,0,0-33.58h0a23.8,23.8,0,0,0-33.61,0l-18.93,19a1.22,1.22,0,0,0,1.7,1.7l19-19a21.32,21.32,0,0,1,30.19,30.11L94.47,85.68a21.35,21.35,0,0,1-30.15,0l-.21-.23a1.16,1.16,0,0,0-.83-.41Z" />
			</g>
		</g>
	</SvgIcon>
);

export const SearchIcon: FunctionComponent<IconProps> = () => (
	<SvgIcon viewBox="0 0 150 150" >
		<g id="Group_111" data-name="Group 111">
			<path className="cls-1" d="M59.89,114.82A54.93,54.93,0,1,1,98.75,98.75,54.78,54.78,0,0,1,59.89,114.82Zm0-88.22a33.29,33.29,0,1,0,23.55,9.74A33.16,33.16,0,0,0,59.89,26.6Z" />
			<path className="cls-1" d="M134.21,145a10.79,10.79,0,0,1-7.65-3.17L83.45,98.75A10.83,10.83,0,0,1,98.76,83.44l43.11,43.12A10.83,10.83,0,0,1,134.21,145Z" />
		</g>
	</SvgIcon>
);

export const RedoIcon: FunctionComponent<IconProps> = (props) => (
  <SvgIcon color={!props.active ? "disabled" : "inherit"} width="15" height="14" viewBox="0 0 15 14">
    <path className="a" d="M10.992,5.9v-2c0-.581.483-.774.773-.484l4.863,4.84a.431.431,0,0,1,0,.645l-4.863,4.84a.449.449,0,0,1-.773-.323V11.261a.343.343,0,0,0-.258-.29,8.486,8.486,0,0,0-8.373,6.1c-.1.29-.483.29-.515,0C1.137,11.874,5.678,7.034,10.766,6.195A.31.31,0,0,0,10.992,5.9Z" transform="translate(-1.773 -3.287)"/>
  </SvgIcon>
);

export const UndoIcon: FunctionComponent<IconProps> = (props) => (
  <SvgIcon color={!props.active ? "disabled" : "inherit"} width="15" height="14" viewBox="0 0 15 14">
    <path className="a" d="M7.431,5.912V3.906c0-.582-.483-.777-.773-.485L1.795,8.274a.433.433,0,0,0,0,.647l4.863,4.854a.449.449,0,0,0,.773-.324V11.283a.343.343,0,0,1,.258-.291,8.488,8.488,0,0,1,8.373,6.116.268.268,0,0,0,.515-.032c.708-5.21-3.832-10.063-8.921-10.9A.25.25,0,0,1,7.431,5.912Z" transform="translate(-1.65 -3.287)"/>
  </SvgIcon>
);

export const FireIcon: FunctionComponent<IconProps> = (props) => (
<svg id="fire-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  <path id="Vector" d="M0,0H18V18H0Z" fill="none" opacity="0"/>
  <path id="Fire" d="M12.251,1.927A7.788,7.788,0,0,0,9.208-.007a.319.319,0,0,0-.374.451A5.8,5.8,0,0,1,9.369,4.57a.1.1,0,0,1-.1.077c-.058,0-.071,0-.071-.045A6.646,6.646,0,0,0,6.816,1.656.322.322,0,0,0,6.326,2c.38,1.489-.367,2.385-1.289,3.52A6.949,6.949,0,0,0,3,10.127a5.925,5.925,0,0,0,5.639,5.986A3.684,3.684,0,0,1,7,13.483C7,10.754,8.618,9.113,9.717,9.15s-.541,1.874.913,3.443-.372,3.129-.913,3.52a9.9,9.9,0,0,0,3.372-1.39,5.97,5.97,0,0,0,2.16-4.616C15.249,5.8,13.618,3.281,12.251,1.927Z" transform="translate(-0.593 1.019)" fill="#ff8b16"/>
</svg>
);

export const SnowFlakeIcon: FunctionComponent<IconProps> = (props) => (
  <svg id="snowflake-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  <path id="Vector" d="M0,0H18V18H0Z" fill="none" opacity="0"/>
  <path id="Vector-2" data-name="Vector" d="M.563,1.125H1.5V4.688a.563.563,0,0,0,1.125,0V1.125h.938A.567.567,0,0,0,4.125.563.567.567,0,0,0,3.563,0h-3A.567.567,0,0,0,0,.563.567.567,0,0,0,.563,1.125Z" transform="translate(6.937 1.688)" fill="#00accd"/>
  <path id="Vector-3" data-name="Vector" d="M3.563,4.125H2.625V.563A.563.563,0,0,0,1.5.563V4.125H.563a.563.563,0,0,0,0,1.125h3a.563.563,0,1,0,0-1.125Z" transform="translate(6.937 11.062)" fill="#00accd"/>
  <path id="Vector-4" data-name="Vector" d="M.28,3.648a.557.557,0,0,0,.765-.21l.472-.81,3.09,1.785a.515.515,0,0,0,.277.075A.551.551,0,0,0,5.373,4.2a.563.563,0,0,0-.2-.765L2.08,1.652l.465-.81a.56.56,0,0,0-.2-.765.553.553,0,0,0-.765.2l-1.5,2.6A.562.562,0,0,0,.28,3.648Z" transform="translate(1.842 3.762)" fill="#00accd"/>
  <path id="Vector-5" data-name="Vector" d="M5.168.841a.553.553,0,0,0-.765.2l-.472.81L.841.076a.56.56,0,0,0-.562.968L3.361,2.828l-.465.81a.56.56,0,0,0,.2.765.557.557,0,0,0,.765-.21l1.5-2.6A.54.54,0,0,0,5.168.841Z" transform="translate(10.709 9.749)" fill="#00accd"/>
  <path id="Vector-6" data-name="Vector" d="M3.37,1.645.28,3.43a.557.557,0,0,0-.2.765.567.567,0,0,0,.487.285.515.515,0,0,0,.277-.075L3.925,2.62l.472.81a.567.567,0,0,0,.488.285.515.515,0,0,0,.277-.075.557.557,0,0,0,.2-.765L3.865.28A.56.56,0,0,0,2.9.843Z" transform="translate(10.708 3.77)" fill="#00accd"/>
  <path id="Vector-7" data-name="Vector" d="M2.08,2.83,5.163,1.045A.56.56,0,0,0,4.6.077L1.518,1.863l-.472-.81a.56.56,0,0,0-.968.563l1.5,2.6a.567.567,0,0,0,.488.285.515.515,0,0,0,.278-.075.557.557,0,0,0,.2-.765Z" transform="translate(1.842 9.755)" fill="#00accd"/>
  <path id="Vector-8" data-name="Vector" d="M5.25,2.625a2.624,2.624,0,0,1-4.9,1.313A2.624,2.624,0,1,1,4.9,1.313,2.6,2.6,0,0,1,5.25,2.625Z" transform="translate(6.375 6.375)" fill="#00accd"/>
</svg>
  );

export const SettingsIcon: FunctionComponent<IconProps> = (props) => (
  <SvgIcon width="15" height="14" viewBox="0 0 15 14">
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 16 16">
      <g id="setting-2-icon" transform="translate(-300 -190)" opacity="0.6">
        <g id="setting-2" transform="translate(300 190)">
          <path id="Vector" d="M12.067,4.439c-1.207,0-1.7-.853-1.1-1.9A1.264,1.264,0,0,0,10.5.813L9.347.153a1.113,1.113,0,0,0-1.52.4L7.753.679a1.159,1.159,0,0,1-2.193,0L5.487.553A1.094,1.094,0,0,0,3.98.153L2.827.813A1.272,1.272,0,0,0,2.36,2.546c.607,1.04.113,1.893-1.093,1.893A1.27,1.27,0,0,0,0,5.706V6.879A1.27,1.27,0,0,0,1.267,8.146c1.207,0,1.7.853,1.093,1.9a1.264,1.264,0,0,0,.467,1.727l1.153.66a1.113,1.113,0,0,0,1.52-.4l.073-.127a1.159,1.159,0,0,1,2.193,0l.073.127a1.113,1.113,0,0,0,1.52.4l1.153-.66a1.266,1.266,0,0,0,.467-1.727c-.607-1.047-.113-1.9,1.093-1.9A1.27,1.27,0,0,0,13.34,6.879V5.706A1.28,1.28,0,0,0,12.067,4.439Zm-5.4,4.02A2.167,2.167,0,1,1,8.833,6.293,2.171,2.171,0,0,1,6.667,8.459Z" transform="translate(1.333 1.707)" fill="#171d21"/>
          <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"/>
        </g>
      </g>
    </svg>
  </SvgIcon>
);

export const DeleteIcon: FunctionComponent<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
    <g id="delete-icon"  opacity="0.6">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
    </g>
  </svg>
);

export const ExitIcon: FunctionComponent<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
    <g id="exit-icon"  opacity="0.6">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </g>
  </svg>
);

export const CreateNewIcon: FunctionComponent<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 25 25">
    <g id="exit-icon"  opacity="0.6">
      <path d="M0 0h24v24H0z" fill="none"/><path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
    </g>
  </svg>
);


export const HelpIcon: FunctionComponent<IconProps> = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="help-icon" transform="translate(-161 -136)">
    <circle id="Ellipse_178" data-name="Ellipse 178" cx="10" cy="10" r="10" transform="translate(161 136)" fill="#3568ba" opacity="0.2"/>
    <path id="Path_1045" data-name="Path 1045" d="M2.03-4.088a1.788,1.788,0,0,0,.462,1.232L4.018-3.3a.846.846,0,0,1-.252-.588c0-.518.434-.826.924-1.19A2.585,2.585,0,0,0,6.1-7.266C6.1-8.568,5-9.478,3.192-9.478A4,4,0,0,0,.126-8.12L1.26-6.846a2.238,2.238,0,0,1,1.722-.868c.77,0,1.092.364,1.092.8s-.378.686-.812.994A2.235,2.235,0,0,0,2.03-4.088Zm0,3.108A1.147,1.147,0,0,0,3.164.154,1.147,1.147,0,0,0,4.3-.98,1.147,1.147,0,0,0,3.164-2.114,1.147,1.147,0,0,0,2.03-.98Z" transform="translate(168 151)" fill="#3568ba"/>
  </g>
</svg>
);

export const FindReplaceSettingsIcon: FunctionComponent<IconProps> = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 20 20">
  <g id="find-icon" transform="translate(-428 -188)">
    <g id="search-normal" transform="translate(428 188)">
      <path id="Vector" d="M13.524,6.762A6.762,6.762,0,1,1,6.762,0,6.762,6.762,0,0,1,13.524,6.762Z" transform="translate(1.667 1.667)" fill="none" stroke="#171d21" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
      <path id="Vector-2" data-name="Vector" d="M4.864,4.864,0,0" transform="translate(13.47 13.47)" fill="none" stroke="#171d21" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
      <path id="Vector-3" data-name="Vector" d="M0,0H20V20H0Z" fill="none" opacity="0"/>
    </g>
  </g>
</svg>
);

export const HistorySettingsIcon: FunctionComponent<IconProps> = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 20 20">
  <g id="history-icon" transform="translate(0 20) rotate(-90)">
    <g id="rotate-left">
      <path id="Vector" d="M4.817.358A8.349,8.349,0,0,1,7.225,0,7.219,7.219,0,1,1,1.217,3.208" transform="translate(2.775 3.319)" fill="none" stroke="#171d21" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
      <path id="Vector-2" data-name="Vector" d="M0,2.767,2.408,0" transform="translate(6.558 1.111)" fill="none" stroke="#171d21" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
      <path id="Vector-3" data-name="Vector" d="M0,0,2.808,2.05" transform="translate(6.558 3.878)" fill="none" stroke="#171d21" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
      <path id="Vector-4" data-name="Vector" d="M0,0H20V20H0Z" transform="translate(20 20) rotate(180)" fill="none" opacity="0"/>
      <path id="Vector-5" data-name="Vector" d="M2.975,5.592.715,4.244A1.615,1.615,0,0,1,0,2.989V0" transform="translate(13.274 10.424) rotate(90)" fill="none" stroke="#171d21" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
    </g>
  </g>
</svg>

  );

export const GoalsIcon: FunctionComponent<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 20 20">
  <g id="goals-icon" transform="translate(-172 -188)">
    <g id="clock" transform="translate(172 188)">
      <path id="Vector" d="M0,0H20V20H0Z" transform="translate(20 20) rotate(180)" fill="currentColor" opacity="0"/>
      <g id="Group_778" data-name="Group 778" transform="translate(2.16 1.905)">
        <path id="Path_1" data-name="Path 1" d="M23.6,30.246a4.83,4.83,0,1,0,9.172-2.1l-1.038,1.037a3.479,3.479,0,1,1-2.24-2.239L30.534,25.9A4.818,4.818,0,0,0,23.6,30.246Z" transform="translate(-20.751 -22.197)" fill="#171d21"/>
        <path id="Path_2" data-name="Path 2" d="M22.156,15.908a6.33,6.33,0,1,1-3.928-3.881l.034-.034,1.012-1.014A7.69,7.69,0,1,0,23.2,14.865L22.17,15.894Z" transform="translate(-8.514 -9.964)" fill="#171d21"/>
        <path id="Path_3" data-name="Path 3" d="M47.006,10.32l-.69-1.936-2.664,2.669.45,1.263-2.373,2.373a1.918,1.918,0,1,0,.96.96l2.4-2.4,1.194.423,2.664-2.669Z" transform="translate(-33.264 -8.384)" fill="#171d21"/>
      </g>
    </g>
  </g>
</svg>
);

export const NotesIcon: FunctionComponent<IconProps> = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 20 20">
  <g id="notes-icon" transform="translate(-352 2299)">
    <path id="Vector" d="M0,0H20V20H0Z" transform="translate(352 -2299)" fill="#171d21" opacity="0"/>
    <path id="notebook-pen" d="M15.046,4.98l1.2.513a.313.313,0,0,1,.165.411l-.291.664-1.767-.759.291-.664a.313.313,0,0,1,.411-.165ZM1.293,1.148H2.486V.4a.4.4,0,0,1,.8,0v.75H5.031V.4a.4.4,0,0,1,.8,0v.75H7.574V.4a.4.4,0,0,1,.8,0v.75h1.748V.4a.4.4,0,0,1,.8,0v.75h1.35a1.3,1.3,0,0,1,1.293,1.293V4.157a1.787,1.787,0,0,0-.256.422l-.291.664a1.457,1.457,0,0,0-.045.169,1.637,1.637,0,0,0-.084.168l-.087.2V2.441a.524.524,0,0,0-.521-.521H10.924v.75a.4.4,0,0,1-.8,0V1.92H8.381v.75a.4.4,0,1,1-.8,0V1.92H5.826v.75a.4.4,0,1,1-.8,0V1.92H3.283v.75a.4.4,0,0,1-.8,0V1.92H1.293a.521.521,0,0,0-.521.521v13.4a.524.524,0,0,0,.521.523H8.617l.082.771H1.293A1.3,1.3,0,0,1,0,15.843V2.443A1.3,1.3,0,0,1,1.293,1.148ZM2.963,13.742a.386.386,0,0,1,0-.772H9.718l-.331.772Zm0-2.546a.386.386,0,0,1,0-.772H10.6a.367.367,0,0,1,.185.051l-.309.728Zm0-2.546a.386.386,0,1,1,0-.772H10.6a.386.386,0,1,1,0,.772Zm0-2.546a.386.386,0,1,1,0-.772H10.6a.386.386,0,1,1,0,.772ZM11.8,16.692l-.9.728c-.706.571-.635.737-.728-.128l-.146-1.36h0l4.185-9.788,1.774.76Zm-1.47-.628,1.165.5-.6.479c-.465.376-.418.484-.478-.083l-.1-.894Z" transform="translate(354.105 -2297.948)" fill="#171d21"/>
  </g>
</svg>
);

export const ToolbarSettingsIcon: FunctionComponent<IconProps> = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 20 20">
  <g id="settings-icon" transform="translate(-300 -190)">
    <g id="setting-2" transform="translate(300 190)">
      <path id="Vector" d="M15.083,5.549c-1.508,0-2.125-1.067-1.375-2.375a1.581,1.581,0,0,0-.583-2.158L11.683.191a1.391,1.391,0,0,0-1.9.5L9.692.849c-.75,1.308-1.983,1.308-2.742,0L6.858.691a1.368,1.368,0,0,0-1.883-.5l-1.442.825A1.59,1.59,0,0,0,2.95,3.182c.758,1.3.142,2.367-1.367,2.367A1.588,1.588,0,0,0,0,7.132V8.6a1.588,1.588,0,0,0,1.583,1.583c1.508,0,2.125,1.067,1.367,2.375a1.581,1.581,0,0,0,.583,2.158l1.442.825a1.391,1.391,0,0,0,1.9-.5l.092-.158c.75-1.308,1.983-1.308,2.742,0l.092.158a1.391,1.391,0,0,0,1.9.5l1.442-.825a1.583,1.583,0,0,0,.583-2.158c-.758-1.308-.142-2.375,1.367-2.375A1.588,1.588,0,0,0,16.675,8.6V7.132A1.6,1.6,0,0,0,15.083,5.549Zm-6.75,5.025a2.708,2.708,0,1,1,2.708-2.708A2.714,2.714,0,0,1,8.333,10.574Z" transform="translate(1.667 2.134)" fill="#171d21"/>
      <path id="Vector-2" data-name="Vector" d="M0,0H20V20H0Z" transform="translate(20 20) rotate(180)" fill="none" opacity="0"/>
    </g>
  </g>
</svg>
);

export const ToggleSettingsIcon: FunctionComponent<IconProps> = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="55" height="55" viewBox="0 0 55 55">
  <defs>
    <filter id="Ellipse_179" x="0" y="0" width="55" height="55" filterUnits="userSpaceOnUse">
      {/* <feOffset dy="5" input="SourceAlpha"/> */}
      <feGaussianBlur stdDeviation="5" result="blur"/>
      {/* <feFlood flood-color="#171d21" flood-opacity="0.059"/> */}
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="arrow-icon" transform="translate(-1116 -77)">
    <g transform="matrix(1, 0, 0, 1, 1116, 77)" filter="url(#Ellipse_179)">
      <circle id="Ellipse_179-2" data-name="Ellipse 179" cx="12.5" cy="12.5" r="12.5" transform="translate(15 10)" fill="#3568ba"/>
    </g>
    <path id="Path_1035" data-name="Path 1035" d="M0,0,4.5,4.5,9,0" transform="translate(1141.5 104) rotate(-90)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  </g>
</svg>
);

export const ReverseToggleSettingsIcon: FunctionComponent<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 44">
    <defs>
      <filter id="Ellipse_179" x="0" y="0" width="55" height="55" filterUnits="userSpaceOnUse">
        {/* <feOffset dy="5" input="SourceAlpha"/> */}
        <feGaussianBlur stdDeviation="5" result="blur"/>
        {/* <feFlood flood-color="#171d21" flood-opacity="0.059"/> */}
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
    </defs>
    <g id="arrow-icon" transform="translate(-1116 -77)">
      <g transform="matrix(1, 0, 0, 1, 1116, 77)" filter="url(#Ellipse_179)">
        <circle id="Ellipse_179-2" data-name="Ellipse 179" cx="12.5" cy="12.5" r="12.5" transform="translate(15 10)" fill="#3568ba"/>
      </g>
      <path id="Path_1035" data-name="Path 1035" d="M0,0,4.5,4.5,9,0" transform="translate(1143.5 95) rotate(-270)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
    </g>
  </svg>
  );

export const SprintTimerIcon: FunctionComponent<IconProps> = (props) => (
<svg id="timer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <path id="Vector" d="M14.583,7.292A7.292,7.292,0,1,1,7.292,0,7.294,7.294,0,0,1,14.583,7.292Z" transform="translate(2.708 3.75)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  <path id="Vector-2" data-name="Vector" d="M0,0V4.167" transform="translate(10 6.667)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  <path id="Vector-3" data-name="Vector" d="M0,0H5" transform="translate(7.5 1.667)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  <path id="Vector-4" data-name="Vector" d="M0,0H20V20H0Z" fill="none" opacity="0"/>
</svg>
);

export const SprintPauseTimerIcon: FunctionComponent<IconProps> = (props) => (
<svg id="timer-pause" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <path id="Vector" d="M7.292,14.583a7.292,7.292,0,1,1,7.292-7.292" transform="translate(2.708 3.75)" fill="none" stroke="#00be95" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  <path id="Vector-2" data-name="Vector" d="M0,0V4.167" transform="translate(10 6.667)" fill="none" stroke="#00be95" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  <path id="Vector-3" data-name="Vector" d="M0,0H5" transform="translate(7.5 1.667)" fill="none" stroke="#00be95" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  <path id="Vector-4" data-name="Vector" d="M0,0V3.333" transform="translate(15.833 14.167)" fill="none" stroke="#00be95" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  <path id="Vector-5" data-name="Vector" d="M0,0V3.333" transform="translate(13.333 14.167)" fill="none" stroke="#00be95" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  <path id="Vector-6" data-name="Vector" d="M0,0H20V20H0Z" fill="none" opacity="0"/>
</svg>
);

export const SprintPlayTimerIcon: FunctionComponent<IconProps> = (props) => (
<svg id="timer-start" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <path id="Vector" d="M0,0V4.167" transform="translate(10 6.667)" fill="none" stroke="#3568ba" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  <path id="Vector-2" data-name="Vector" d="M7.292,14.583a7.292,7.292,0,1,1,7.292-7.292" transform="translate(2.708 3.75)" fill="none" stroke="#3568ba" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  <path id="Vector-3" data-name="Vector" d="M0,0H5" transform="translate(7.5 1.667)" fill="none" stroke="#3568ba" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  <path id="Vector-4" data-name="Vector" d="M0,2.306V1.339C0,.148.85-.344,1.883.256l.833.483.833.483a1.151,1.151,0,0,1,0,2.175l-.833.483-.833.483C.85,4.964,0,4.473,0,3.281Z" transform="translate(12.417 13.111)" fill="none" stroke="#3568ba" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  <path id="Vector-5" data-name="Vector" d="M0,0H20V20H0Z" fill="none" opacity="0"/>
</svg>
);

export const ImageUploadIcon: FunctionComponent<IconProps> = ({ size = 15 }: IconProps) => (
	<SvgIcon viewBox="0 0 150 150" style={{ width: size }} className="slate-ToolbarButton">
		<g id="Group_40" data-name="Group 40">
			<path className="cls-1" d="M130.55,132.9H19.05a15,15,0,0,1-15-15V32.11a15,15,0,0,1,15-15h111.5a15,15,0,0,1,15,15v85.78A15,15,0,0,1,130.55,132.9ZM19.05,30a2.14,2.14,0,0,0-2.15,2.14v85.78A2.14,2.14,0,0,0,19.05,120h111.5a2.14,2.14,0,0,0,2.14-2.14V32.11A2.14,2.14,0,0,0,130.55,30Z" />
			<path className="cls-1" d="M15,113.69l-9.1-9.1L39.54,71a6.42,6.42,0,0,1,9.1,0L73.75,96.08,107.8,62a6.44,6.44,0,0,1,9.1,0l27.18,27.18-9.1,9.1L112.35,75.67l-34,34.05a6.44,6.44,0,0,1-9.1,0L44.09,84.61Z" />
			<circle id="Ellipse_8" data-name="Ellipse 8" className="cls-1" cx="66.22" cy="53.56" r="12.87" />
		</g>
	</SvgIcon>
);

export const ImageSettingsIcon: FunctionComponent<IconProps> = ({ size = 15 }: IconProps) => (
	<SvgIcon viewBox="0 0 32 32" style={{ width: size }} className="slate-ToolbarButton">
		<g id="Fill"><path d="M16,12a4,4,0,1,0,4,4A4,4,0,0,0,16,12Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,16,18Z"/><path d="M27.57,18.3l-1.38-.79a9.56,9.56,0,0,0,0-3l1.38-.79a2.86,2.86,0,0,0,1.05-3.91l-.93-1.61a2.88,2.88,0,0,0-3.91-1l-1.38.8a10.07,10.07,0,0,0-2.61-1.52V4.83A2.86,2.86,0,0,0,16.93,2H15.07a2.86,2.86,0,0,0-2.86,2.86V6.41A10.07,10.07,0,0,0,9.6,7.93l-1.38-.8a2.86,2.86,0,0,0-3.91,1L3.38,9.79a2.88,2.88,0,0,0,1,3.91l1.38.79a9.56,9.56,0,0,0,0,3l-1.38.79a2.86,2.86,0,0,0-1,3.91l.93,1.61a2.88,2.88,0,0,0,3.91,1.05l1.38-.8a10.07,10.07,0,0,0,2.61,1.52v1.58A2.86,2.86,0,0,0,15.07,30h1.86a2.86,2.86,0,0,0,2.86-2.86V25.59a10.07,10.07,0,0,0,2.61-1.52l1.38.8a2.86,2.86,0,0,0,3.91-1.05l.93-1.61A2.88,2.88,0,0,0,27.57,18.3Zm-.69,2.91L26,22.82a.86.86,0,0,1-1.17.32l-2.63-1.52-.54.49a8.3,8.3,0,0,1-3.12,1.82l-.7.22v3a.86.86,0,0,1-.86.86H15.07a.86.86,0,0,1-.86-.86v-3l-.7-.22a8.3,8.3,0,0,1-3.12-1.82l-.54-.49L7.22,23.14a.87.87,0,0,1-.65.08.84.84,0,0,1-.52-.4l-.93-1.61A.86.86,0,0,1,5.43,20l2.62-1.51-.16-.72a8.56,8.56,0,0,1-.2-1.8,8,8,0,0,1,.21-1.8l.15-.72L5.43,12a.86.86,0,0,1-.31-1.18l.93-1.61a.86.86,0,0,1,1.17-.32l2.63,1.52.54-.49a8.3,8.3,0,0,1,3.12-1.82l.7-.22v-3A.86.86,0,0,1,15.07,4h1.86a.86.86,0,0,1,.86.86v3l.7.22a8.3,8.3,0,0,1,3.12,1.82l.54.49,2.63-1.52a.87.87,0,0,1,.65-.08.84.84,0,0,1,.52.4l.93,1.61A.86.86,0,0,1,26.57,12L24,13.48l.16.72a8.56,8.56,0,0,1,.2,1.8,8,8,0,0,1-.21,1.8l-.15.72L26.57,20A.86.86,0,0,1,26.88,21.21Z"/></g>
	</SvgIcon>
);

export const RemoveIcon: FunctionComponent<IconProps> = ({ size = 15 }: IconProps) => (
	<SvgIcon viewBox="0 0 32 32" style={{ width: size }} className="slate-ToolbarButton">
		<g><rect height="12" width="2" x="15" y="12"/><rect height="12" width="2" x="19" y="12"/><rect height="12" width="2" x="11" y="12"/><path d="M20,6V5a3,3,0,0,0-3-3H15a3,3,0,0,0-3,3V6H4V8H6V27a3,3,0,0,0,3,3H23a3,3,0,0,0,3-3V8h2V6ZM14,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H14ZM24,27a1,1,0,0,1-1,1H9a1,1,0,0,1-1-1V8H24Z"/></g>
	</SvgIcon>
);

export const ProfileIcon: FunctionComponent<IconProps> = ({ size = 25 }: IconProps) => (
	<SvgIcon viewBox="0 0 22 22" style={{ width: size }} className="slate-ToolbarButton">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" />
	</SvgIcon>
);

export const EndnoteIcon: FunctionComponent<IconProps> = (_) => (
  <SvgIcon
    viewBox="0 0 150 150"
  >
    <g id="_1">
      <g className="st0">
        <path
          className="st1"
          d="M71.2,109.8V34.6L55.3,51.2l-9.9-10.3l28-28.3h14.9v97.2H71.2z"
        />
      </g>
    </g>
    <path
      className="st1"
      d="M114.7,146.3H35.3c-5,0-9.1-4.1-9.1-9.1s4.1-9.1,9.1-9.1h79.5c5,0,9.1,4.1,9.1,9.1S119.8,146.3,114.7,146.3z"
    />
  </SvgIcon>
);

export const SplitChapterIcon: FunctionComponent<IconProps> = (props) => (
  <ScanOutlined {...props} />
);

export const SmartQuotesIcon: FunctionComponent<IconProps> = (_) => (
  <svg
    id="smart-quotes-icon"
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    viewBox="0 0 48 48"
    width="20"
  >
    <path d="M12 34h6l4-8v-12h-12v12h6zm16 0h6l4-8v-12h-12v12h6z" />
    <path d="M0 0h48v48h-48z" fill="none" />
  </svg>
);

export const SmartQuoteDetectionWaiting: FunctionComponent<IconProps> = (_) => (
  <SyncOutlined spin />
);

export const SmartQuoteDetectionOK: FunctionComponent<IconProps> = (_) => (
  <CheckCircleTwoTone twoToneColor="#52c41a" />
);

export const SmartQuoteDetectionError: FunctionComponent<IconProps> = (_) => (
  <WarningTwoTone twoToneColor="#ff0000" />
);

export const TextMessageIcon: FunctionComponent<IconProps> = (_) => (
  <MessageOutlined />
);

export const TextMessageRecievedIcon: FunctionComponent<IconProps> = (props) => {

  if (props.active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="15"
        viewBox="0 -960 960 960"
        width="15"
        fill="rgb(53, 104, 186)"
      >
        <path d="M80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Z" />
      </svg>
    );
  }

  return (
<svg
    xmlns="http://www.w3.org/2000/svg"
    height="15"
    viewBox="0 -960 960 960"
    width="15"
  >
    <path d="M80-80v-740q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240L80-80Zm134-220h606v-520H140v600l74-80Zm-74 0v-520 520Z" />
  </svg>
  );
  
};

export const TextMessageSentIcon: FunctionComponent<IconProps> = (props) => {

  if(props.active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="15"
        viewBox="0 -960 960 960"
        width="15"
        fill="rgb(53, 104, 186)"
      >
        <path d="M160-240q-33 0-56.5-23.5T80-320v-480q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v720L720-240H160Z" />
      </svg>
    );
  }

  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    height="15"
    viewBox="0 -960 960 960"
    width="15"
  >
    <path d="M880-80 720-240H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v740ZM140-300v-520 520Zm606 0 74 80v-600H140v520h606Z" />
  </svg>
  );
 
};

export const TextMessageContactIcon: FunctionComponent<IconProps> = (props) => {

  if(props.active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="15"
        viewBox="0 -960 960 960"
        width="15"
        fill="rgb(53, 104, 186)"
      >
        <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Z" />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="15"
      viewBox="0 -960 960 960"
      width="15"
    >
      <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" />
    </svg>
  );
};
