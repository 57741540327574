import React, { useState } from "react";
import { PlateEditor, ToolbarButton, ToolbarButtonProps, usePlateEditorState } from "@udecode/plate";
import { CURRENT_COLOR, TooltipIcon } from "../../../partials";
import { ELEMENT_END_NOTE } from "../types";
import useRootStore from "../../../../../store/useRootStore";
import { MyEditor } from "../../../config/typescript";
import { Range } from "slate";
import EndnotesUpsertModal from "./EndNotesUpsertModal";
import { insertEndnoteNode } from "../transformers";
import { PlateNodes} from "../../../config/typescript";
import { allowFeatureForTextMessages } from "../../text-message/config";
import { EndNoteIcon } from "../../../../../content/icons";

export const EndNotesToolbarButton = (props: Omit<ToolbarButtonProps, "icon">) => {
  const { isDropdown } = props;
  const { activeTheme: theme } = useRootStore().themeStore;
  const editor = usePlateEditorState() as MyEditor;
  const enabled = allowFeatureForTextMessages(editor as PlateEditor);
  const [modalVisible, setModalVisibility] = useState<boolean>(false);
  const [selection, setSelection] = useState<Range | null>(null);

  const openModal = () => {
    setModalVisibility(true);
  };

  const closeModal = () => {
    setModalVisibility(false);
  };

  const addEndNote = (note: PlateNodes | null) => {
    const inserted = insertEndnoteNode(editor, selection, note);

    if (inserted) {
      closeModal();
    }
  };

  return (
    <>
      <TooltipIcon
        title={
          theme?.properties?.notesMode === "FOOTNOTE" ? "Footnote" : "Endnote"
        }
        placement={isDropdown ? "right" : "bottom"}
      >
        <div className={`${enabled ? "" : "plate-disabled"}`}>
          <ToolbarButton
            type={ELEMENT_END_NOTE}
            icon={<EndNoteIcon color={CURRENT_COLOR} />}
            onMouseDown={() => {
              if (editor.selection) {
                setSelection(editor.selection as Range);
                openModal();
              }
            }}
          />
        </div>
      </TooltipIcon>
      {modalVisible && (
        <EndnotesUpsertModal
          isInsert={true}
          open={modalVisible}
          addNote={addEndNote}
          onCancel={closeModal}
        />
      )}
    </>
  );
};
