import React, { FunctionComponent } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";

//Provider 
import Provider from "./Provider";

/* PARTIALS */
//Layout
import LayoutWithAuth from "./containers/layouts/WithAuth";

/* PAGES */

import {

	/* Non - Authenticated Pages */
	SignIn,
	SignUp,
	ResetPassword,
	ForgotPassword,

	/* Authenticated Pages */
	Overview,
	Welcome,
	Profile,
	MyBooks,
	ChangePassword,
	Maintenance,
	SignOut,
	BookDebugger,
	Backup,
} from "./pages";

//Components
import { ProfileTab } from "./containers/layouts/Profile";

// Editor
import Editor from "./components/Editor/EditorView";
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
import RendererCacheInitiator from "./components/Shared/RendererCacheInitiator/RendererCacheInitiator";
import LayoutWithoutAuth from "./containers/layouts/WithoutAuth";

const Routes: FunctionComponent = () => {

	// set the REACT_APP_MAINTENANCE_MODE=true to enable the Maintenance Mode
	const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE === "true";

	if (MAINTENANCE_MODE) {
		return <Maintenance />;
	}

	return (
		<Provider>
			<>
				<Router>
					<Switch>
						<Route path="/auth/sign-out" component={SignOut} />
						<Route path="/auth/*">
							<LayoutWithoutAuth>
								<Switch>
									<Route path="/auth/sign-in" component={SignIn} />
									<Route path="/auth/create-account" component={SignUp} />
									<Route path="/auth/forgot-password" component={ForgotPassword} />
									<Route path="/auth/reset-password" component={ResetPassword} />
								</Switch>
							</LayoutWithoutAuth>
						</Route>
						<Route>
							<LayoutWithAuth>
								<Switch>
									<Route path="/books/:bookId/:mode?" exact>
										<RendererCacheInitiator />
										<Editor />
									</Route>
									<Route path="/chapter-template/:bookId/:mode?" exact>
										<RendererCacheInitiator />
										<Editor />
									</Route>
									<Route path="/" exact>
										<Overview />
									</Route>
									<Route path="/my-books" exact>
										<MyBooks />
									</Route>
									<Route path="/welcome" exact>
										<Welcome />
									</Route>
									<Route path="/debug" exact>
										<BookDebugger />
									</Route>
									<Route path="/backup" exact component={Backup} />
									<Route path="/account/*" exact>
										<ProfileTab>
											{props => props.profile.email ? (
												<>
													<Route path="/account/change-password" component={ChangePassword} exact />
													<Route path="/account/profile" render={routeProps => <Profile {...props} {...routeProps} />} />
												</>
											) : null}
										</ProfileTab>
									</Route>
								</Switch>
							</LayoutWithAuth>
						</Route>
					</Switch>
				</Router>
				<ServiceWorkerWrapper></ServiceWorkerWrapper>

			</>
		</Provider>
	);
};

export default Routes;