import { WritingHabitSection } from "../types";

const THOUSAND_SEPARATOR = /\B(?=(\d{3})+(?!\d))/g;

export const formatNumberWithThousandSeparator = (value: string): string => {
  return value.replace(THOUSAND_SEPARATOR, ",");
};

export const habitWritingDays = [
  { label: "S", value: 0 },
  { label: "M", value: 1 },
  { label: "T", value: 2 },
  { label: "W", value: 3 },
  { label: "T", value: 4 },
  { label: "F", value: 5 },
  { label: "S", value: 6 },
];

export const isSectionWithoutGoal = (section: WritingHabitSection): boolean => {
  return ["loading", "create"].includes(section);
};
