import React from "react";
import { Link } from "react-router-dom";

interface TextAndIconButtonProps {
  link: string;
  text: string;
  icon?: React.ReactNode;
  isExternalLink?: boolean
}

export const TextAndIconLink: React.FC<TextAndIconButtonProps> = ({
  link,
  text,
  icon,
  isExternalLink = false,
}) => {
  return (
    <Link
      className="text-and-icon-link"
      to={isExternalLink ? { pathname: link } : link}
      target={isExternalLink ?"_blank" : ""}
    >
      {text}
      <div className="text-and-icon-container">{icon}</div>
    </Link>
  );
};