import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useOnlineStatus } from "../../../../../utils/isOffline";
import useRootStore from "../../../../../store/useRootStore";

const OnlineChecker: React.FC = observer(() => {
  const isOnline = useOnlineStatus();

  const { setIsOnline } = useRootStore().writingHabitStore;

  useEffect(() => {
    setIsOnline(isOnline);
  }, [isOnline]);

  return <></>;
});

export { OnlineChecker };
