import React, { FunctionComponent } from "react";
import { Formik, FormikErrors } from "formik";

import { Modal } from "../Shared/Modal";

interface ImportIntoVolumeModalProps {
  onUpload: (params: IShelfStore.ChapterImportForm) => Promise<unknown>;
  onClose: () => void;
  bookId: string;
}

const ImportIntoVolumeModal: FunctionComponent<ImportIntoVolumeModalProps> = ({
  onUpload,
  onClose,
  bookId,
}: ImportIntoVolumeModalProps) => {
  return (
    <>
      <Formik
        initialValues={
          {
            bookId,
            fileURL: "",
            fileType: "docx",
            insertIntoVolume: false,
          } as IShelfStore.ChapterImportForm
        }
        validate={() => {
          const errors: FormikErrors<IShelfStore.ChapterImportForm> = {};
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await onUpload(values);
          setSubmitting(false);
        }}
      >
        {({ setFieldValue, values, submitForm, isSubmitting }) => (
          <Modal
            open={true}
            centered={true}
            title={<b>Import chapters into a new volume</b>}
            onCancel={() => onClose()}
            destroyOnClose={true}
            leftBtn={{
              type: "at-secondary",
              backgroundColor: "green",
              onMouseDown: () => submitForm(),
              loading: !values.insertIntoVolume && isSubmitting,
              disabled: values.insertIntoVolume && isSubmitting,
              children: "No",
            }}
            rightBtn={{
              type: "at-primary",
              backgroundColor: "green",
              onMouseDown: () => {
                setFieldValue("insertIntoVolume", true);
                submitForm();
              },
              loading: values.insertIntoVolume && isSubmitting,
              disabled: !values.insertIntoVolume && isSubmitting,
              children: "Yes",
            }}
          >
            <p className="delete-modal-bottom-padding">
              Do you want to import chapters into a new Volume?
            </p>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default ImportIntoVolumeModal;
