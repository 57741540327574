import React from "react";
import { Menu} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
interface EditThemeProps {
  theme: IThemeStore.Theme,
  onEdit: (id: string) => void
}
export const EditTheme = observer(({ theme, onEdit }: EditThemeProps) => {
  return (
    <Menu.Item
      key="edit-theme"
      onClick={() => onEdit(theme._id)}
      icon={<EditOutlined />}
    >
      {theme.isPredefinedTheme ? "Edit as new theme" : "Edit"}
    </Menu.Item>
  );
});