import { SpecialTemplate } from "./";

export const Epigraph: SpecialTemplate = {
  title: "Epigraph",
  children: [
    {
      type: "blockquote",
      children: [
        {
          text: "Epigraphs are sometimes included at the beginning of books. They’re typically a quote, poem, or verse that acts as an intriguing segue or set up for the content to come and are typically formatted as a block quote.",
        },
      ],
    },
    {
      type: "p",
      children: [
        {
          text: "",
        },
      ],
    },
  ],
  configuration: {
    showChapterImage: false,
    showPageNumber: false,
    showChapterHeading: false,
    showHeaderFooter: true,
    showInTableOfContents: true,
    showFirstSentenceFormatting: false,
  },
};
