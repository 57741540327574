import {
  CreateWritingHabit,
  WritingHabitDateRecord,
  WritingHabitSection,
} from "../components/Goals/WritingHabit/types";
import { db } from "../db/bookDb";
import { AtticusClient } from "../api/atticus.api";
import moment from "moment";
import { makeAutoObservable, toJS } from "mobx";
import { Modal } from "antd";
import { isSectionWithoutGoal } from "../components/Goals/WritingHabit/helpers";
import { cloneDeep } from "lodash";

export class WritingHabitStore {
  section: WritingHabitSection = "loading";
  isOnline = true;
  collapsed = false;

  stateLock = false;

  writingHabitState: IHabitStore.Habit | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  initializePlugin = async (): Promise<void> => {
    if (this.stateLock || this.section === "edit") return;

    this.setStateLock(true);

    if (!this.writingHabitState) {
      await this.loadWritingHabitDataToState();
    }

    await this.syncUserWritingHabitData();

    this.setStateLock(false);
  };

  setWritingHabitState = (state: IHabitStore.Habit | null): void => {
    this.writingHabitState = state;
  };

  setCollapsed = (collapsed: boolean): void => {
    this.collapsed = collapsed;
  };

  setStateLock = (isStateLocked: boolean): void => {
    this.stateLock = isStateLocked;
  };

  syncUserWritingHabitData = async (): Promise<void> => {
    const remoteState = this.isOnline ? await AtticusClient.GetHabit() : null;

    // If the writing habit object does not exist in the server
    // AND does not exist in the IndexedDB, the user has no writing habit
    // configured at the moment. So switch to the create state
    if (!remoteState && !this.writingHabitState) {
      this.switchToCreateState();
      this.setStateLock(false);
      return;
    }

    // If the server contains the data and the state doesn't,
    // then load the configuration.
    if (remoteState && !this.writingHabitState) {
      this.switchToViewState({
        ...remoteState,
        lastSuccessfulSync: remoteState.lastUpdateAt,
      });

      return;
    }

    if (!remoteState && this.writingHabitState) {
      if (!this.isOnline) {
        this.switchToViewState(
          this.writingHabitState,
          isSectionWithoutGoal(this.section)
        );

        return;
      }

      const serverHabit = await AtticusClient.CreateHabit(
        this.writingHabitState
      );

      this.switchToViewState(
        {
          ...this.writingHabitState,
          lastUpdateAt: serverHabit.lastUpdateAt,
          lastSuccessfulSync: serverHabit.lastUpdateAt,
        },
        isSectionWithoutGoal(this.section)
      );

      return;
    }

    if (remoteState && this.writingHabitState) {
      const localSyncLast = this.writingHabitState.lastUpdateAt;

      if (
        !moment(remoteState.lastUpdateAt).isSame(
          moment(localSyncLast),
          "second"
        )
      ) {
        const isRemoteNewer = moment(localSyncLast).isBefore(
          moment(remoteState.lastUpdateAt),
          "second"
        );

        const prioritizedState = isRemoteNewer
          ? remoteState
          : this.writingHabitState;

        const lastUpdate = new Date();

        const newState = {
          ...prioritizedState,
          lastUpdateAt: lastUpdate,
          dateRecords: (
            [
              ...this.writingHabitState.dateRecords,
              ...remoteState.dateRecords,
            ] as WritingHabitDateRecord[]
          )
            .reduce(
              (
                uniqueRecords: WritingHabitDateRecord[],
                currentValue: WritingHabitDateRecord
              ) => {
                if (
                  !uniqueRecords.some(
                    (record) => record.date === currentValue.date
                  )
                ) {
                  uniqueRecords.push(currentValue);
                }

                return uniqueRecords;
              },
              []
            )
            .sort(
              ({ date: dateX }, { date: dateY }) =>
                new Date(dateY).getTime() - new Date(dateX).getTime()
            ),
        };

        const remoteUpdate = await AtticusClient.UpdateHabit(newState);

        this.switchToViewState(
          {
            ...remoteUpdate,
            lastUpdateAt: lastUpdate,
            lastSuccessfulSync: lastUpdate,
          },
          isSectionWithoutGoal(this.section)
        );

        return;
      } else {
        const lastUpdate = new Date();

        await AtticusClient.UpdateHabit({
          ...this.writingHabitState,
          lastUpdateAt: lastUpdate,
        });

        this.switchToViewState(
          {
            ...this.writingHabitState,
            lastUpdateAt: lastUpdate,
            lastSuccessfulSync: lastUpdate,
          },
          isSectionWithoutGoal(this.section)
        );

        return;
      }
    }
  };

  switchToCreateState = (): void => {
    this.section = "create";
  };

  switchToViewState = (
    state: IHabitStore.Habit,
    switchIfEditing = true
  ): void => {
    this.setWritingHabitState(state);

    if (switchIfEditing) {
      this.section = "view";
    }
  };

  switchToViewStateWithoutUpdates = (): void => {
    this.section = "view";
  };

  switchToEditState = (): void => {
    if (this.writingHabitState) {
      this.section = "edit";
    }
  };

  loadWritingHabitDataToState = async (): Promise<void> => {
    const records = await db.writingHabits.toArray();
    const record = records.length === 0 ? undefined : records[0];

    if (record) {
      this.setWritingHabitState(record);
    }
  };

  addWritingHabitDataToIndexedDB = async (
    state: IHabitStore.Habit
  ): Promise<void> => {
    await db.writingHabits.add(
      {
        ...state,
        lastSuccessfulSync: state.lastUpdateAt,
      },
      state.userId
    );
  };

  updateWritingHabitDataToIndexedDB = async (
    state: IHabitStore.Habit
  ): Promise<IHabitStore.Habit> => {
    await db.writingHabits.put(cloneDeep(state));

    return state;
  };

  createNewServerWritingHabitData = async (
    state: CreateWritingHabit
  ): Promise<void> => {
    if (this.stateLock) {
      return this.showOfflineModal(
        "The data is currently being synced with the server. Please try creating a new writing goal in a few moments."
      );
    }

    this.setStateLock(true);
    const habitData = await AtticusClient.CreateHabit(state);

    await this.addWritingHabitDataToIndexedDB(habitData);

    this.switchToViewState(habitData);
    this.setStateLock(false);
  };

  showOfflineModal = (message: string): void => {
    Modal.warning({
      title: message,
      closable: true,
      okButtonProps: {
        style: {
          display: "none",
        },
      },
      width: 550,
    });
  };

  setIsOnline = (isOnline: boolean): void => {
    this.isOnline = isOnline;
  };

  deleteHabit = async (): Promise<void> => {
    const key = this.writingHabitState?.userId || null;

    if (!key) return;

    if (!this.isOnline) {
      return this.showOfflineModal(
        "You must be connected to the internet to delete a habit."
      );
    }

    if (this.stateLock) {
      return this.showOfflineModal(
        "The data is currently being synced with the server. Please try deleting again in a few seconds."
      );
    }

    this.setStateLock(true);

    await Promise.all([
      db.writingHabits.where("userId").equals(key).delete(),
      AtticusClient.DeleteHabit(),
    ]);

    this.setWritingHabitState(null);

    this.setStateLock(false);

    this.switchToCreateState();
  };

  applyBookChangeRelatedChanges = (
    wordCount: number,
    bookId: string
  ): IHabitStore.Habit => {
    if (!this.writingHabitState) throw new Error("Something went wrong");

    const accumulatedPreviousBookWordCount =
      this.writingHabitState.preBookWordCount +
      this.writingHabitState.writtenCount;

    const newState = {
      ...this.writingHabitState,
      preWordCount: wordCount,
      preBookWordCount: accumulatedPreviousBookWordCount,
      writtenCount: 0,
      snowFireStreak:
        accumulatedPreviousBookWordCount >= this.writingHabitState.goalCount
          ? "fire"
          : "snow",
      currentBookId: bookId,
    } as IHabitStore.Habit;

    this.setWritingHabitState(newState);

    return newState;
  };
}

export default new WritingHabitStore();
