import React, { FunctionComponent, useMemo } from "react";
import { Formik, FormikErrors } from "formik";
import { Button, Modal } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import NewBoxsetBookSelection from "./NewBoxsetBookSelection";
import NewBoxsetDetails from "./NewBoxsetDetails";
import useRootStore from "../../store/useRootStore";
import { toJS } from "mobx";
import Fuse from "fuse.js";

interface NewBoxsetModalProps {
  onNewBoxset: (params: IShelfStore.BoxsetForm) => Promise<unknown>;
  onClose: () => void;
}

const NewBoxsetModal: FunctionComponent<NewBoxsetModalProps> = ({ onNewBoxset, onClose }: NewBoxsetModalProps) => {
  const [isBookSelectionDone, setBookSelectionDone] = React.useState(false);

  const onSubmitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);
    await onNewBoxset(values);
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        initialValues={{ bookIds: [], title: "", author: [], project: "" } as IShelfStore.BoxsetForm}
        validate={({ bookIds, title }) => {
          const errors: FormikErrors<IShelfStore.BoxsetForm> = {};
          if (bookIds.length < 2) {
            errors.bookIds = "There must at least be 2 books to create a box set";
          }

          if (!title) {
            errors.title = "A book must have a title";
          }

          console.log("ERRORS", errors);
          return errors;
        }}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, dirty, handleChange, submitForm, setFieldValue, isSubmitting }) => (
          <Modal
            maskClosable={false}
            title={<h2 className="section-heading">{!isBookSelectionDone ? "Select Books" : "Enter Details"}</h2>}
            open={true}
            bodyStyle={{ overflowY: "hidden", maxHeight: "calc(100vh - 400px)", height: "100%" }}
            style={{
              borderRadius: 10,
            }}
            onCancel={() => onClose()}
            footer={
              <Button
                type="primary"
                block
                size="large"
                loading={isSubmitting}
                disabled={!dirty || !!errors.bookIds || (isBookSelectionDone && !!errors.title)}
                onMouseDown={
                  !isBookSelectionDone
                    ? () => {
                        setBookSelectionDone(true);
                      }
                    : submitForm
                }
              >
                {!isBookSelectionDone ? "Next" : "Create boxset"} <ArrowRightOutlined />
              </Button>
            }
          >
            {!isBookSelectionDone ? (
              <NewBoxsetBookSelection onBookSelectionChange={(bookIds) => setFieldValue("bookIds", bookIds)} />
            ) : (
              <NewBoxsetDetails
                values={{ title: values.title, author: values.author, project: values.project }}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            )}
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default NewBoxsetModal;
