import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Radio, Row, Col, Tooltip } from "antd";

import miniLogo from "../../content/images/miniLogo.png";
import wordLogo from "../../content/images/wordLogo.png";


const StaticHeader = observer(() => {
  return (
    <Row className="header auth" gutter={8} justify="center" align="middle">
        <Col>
          <img height={32} src={miniLogo} />        
        </Col>
        <Col>
          <img height={20} src={wordLogo} />        
        </Col>
    </Row >
  );
});

export default StaticHeader;
