import React from "react";
import { FullScreen } from "../Shared/Layouts/FullScreen";
import { TextSettingIcon, SearchAndFindIcon, NotesIcon, CollaborationIcon, GoalIcon, SmartQuoteIcon, VersionHistoryIcon, PreviewerIcon } from "../../content/icons";
import { Button, ButtonType } from "../Shared/Buttons/Button";
import { TooltipIcon } from "../Plate/partials";
import { Divider } from "antd";
import useRootStore from "../../store/useRootStore";
import { observer } from "mobx-react";

interface Icon {
    icon: React.ReactElement<{ key: string, color?: string }>,
    id: IAppStore.SettingView,
    title: string
}

const icons: Icon[] = [
    {icon: <TextSettingIcon key="textSetting" />, id: "setting", title: "Editor settings"},
    {icon: <SearchAndFindIcon key="searchAndFind" />, id: "search", title: "Find and replace"},
    // {icon: <NotesIcon key="notes" />, id: "notes", title: "Notes"},
    // {icon: <CollaborationIcon key="collaboration" />, id: "collaboration", title: "Collaboration"},
    {icon: <GoalIcon key="goal" />, id: "goals", title: "Goals"},
    {icon: <SmartQuoteIcon key="smartQuote" />, id: "smart-quotes", title: "Smart quotes"},
    // {icon: <VersionHistoryIcon key="versionHistory" />, id: "history", title: "Version history"},
];

const _RHSider: React.FC = () => {
    const { setSettingView, editor_setting, sidebarPreviewerOnlyMode } = useRootStore().appStore;
    
    const setTab = (viewId: IAppStore.SettingView) => {
      setSettingView({
        show: true,
        view: viewId,
        isPreviewerOpen: viewId === "previewer" ? !editor_setting.isPreviewerOpen : false
      });
    };

    const isPreviewer = editor_setting.show && editor_setting.view === "previewer";
    
    return (
      <FullScreen className="atticus-rhs">
        {icons.map((item, index) => {
          const isActive =
            editor_setting.show && editor_setting.view === item.id;
          return (
            <div
              key={index}
              className={`right-menu-item ${isActive ? "active" : ""}`}
            >
              <div key={index} className="rhs-item">
                <TooltipIcon title={item.title} placement="left">
                  <Button
                    type={ButtonType.GHOST}
                    icon={React.cloneElement(item.icon, {
                      color: isActive ? "#00BE95" : "#171d21",
                    })}
                    onClick={() => {
                      setTab(item.id);
                    }}
                    disabled={sidebarPreviewerOnlyMode}
                    style={{opacity: sidebarPreviewerOnlyMode ? "50%" : "100%"}}
                  />
                </TooltipIcon>
              </div>
              <Divider className="rhs-divider" />
            </div>
          );
        })}
        <div className="item-bottom">
          <Divider className="rhs-divider" />
          <div className={`right-menu-item ${isPreviewer ? "active" : ""}`}>
            <div className="rhs-item">
                <TooltipIcon title="Previewer" placement="left">
                <Button
                    type={ButtonType.GHOST}
                    icon={React.cloneElement(<PreviewerIcon />, {
                        color: isPreviewer ? "#00BE95" : "#171d21",
                    })}
                    onClick={() => {
                    setTab("previewer");
                    }}
                />
                </TooltipIcon>
            </div>
          </div>
        </div>
      </FullScreen>
    );
};

export const RHSider = observer(_RHSider);