import { convertHexToRGBA } from "../../../utils/hexToRgba";

export const parseCalloutNode = (
  calloutNode: ISlateStore.SlateCalloutBox
): string => {
  let parsedCallout = "";

  const { background, border } = calloutNode as ISlateStore.SlateCalloutBox;
  const rgbBackgroundColor = convertHexToRGBA(
    background.fillColor,
    background.fillOpacity
  );
  const rgbBorderColor = convertHexToRGBA(
    border.borderColor,
    border.borderOpacity
  );

  const getCalloutBoxStyle = () => {
    if (background.fill && border.border) {
      return `background-color: ${rgbBackgroundColor}; border: ${border.borderWidth}px ${border.borderStyle} ${rgbBorderColor}; border-radius: ${border.borderRadius}px;`;
    } else if (background.fill && !border.border) {
      return `background-color: ${rgbBackgroundColor};`;
    } else if (!background.fill && border.border) {
      return `border: ${border.borderWidth}px ${border.borderStyle} ${rgbBorderColor}; border-radius: ${border.borderRadius}px;`;
    } else {
      return "";
    }
  };

  parsedCallout += `<div class="calloutbox" style="${getCalloutBoxStyle()}">`;

  return parsedCallout;
};
