import React, { useState } from "react";
import "tippy.js/animations/scale.css";
import "tippy.js/dist/tippy.css";
import {
    MARK_BOLD,
    MARK_ITALIC,
    MARK_STRIKETHROUGH,
    MARK_SUBSCRIPT,
    MARK_SUPERSCRIPT,
    MARK_UNDERLINE,
    MarkToolbarButton,
} from "@udecode/plate";
import {
    LinkToolbarButton,
} from "../plugins";
import { Divider, Row, Col, Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { TooltipIcon } from "./TooltipIcon";
import { ArrowDownIcon, ArrowUpIcon, BoldIcon, ItalicIcon, LinkIcon, StrikethroughIcon, TickSquare, UnderlineIcon } from "../../../content/icons";
import { CURRENT_COLOR } from "./Toolbars";

const basic_marks = [
  {
    type: MARK_BOLD,
    icon: <BoldIcon color={CURRENT_COLOR} />,
    toolTipText: "Bold", 
  },
  {
    type: MARK_ITALIC,
    icon: <ItalicIcon color={CURRENT_COLOR} />,
    toolTipText: "Italic",
  },
  {
    type: MARK_UNDERLINE,
    icon: <UnderlineIcon color={CURRENT_COLOR} />,
    toolTipText: "Underline",
  },
];

export const EndNotesListToolbar = () => {
    return (
      <>
        <TooltipIcon title="Link">
          <LinkToolbarButton id={"endNote"} icon={<LinkIcon color={CURRENT_COLOR} />} />
        </TooltipIcon>
      </>
    );
};

export const EndNotesBasicMarkToolbar = () => {
    return (
        <>
            {basic_marks.map(basicMarkItem => (
                <TooltipIcon key={`plugin_${basicMarkItem.type}`} title={basicMarkItem.toolTipText}>
                    <MarkToolbarButton
                        reversed
                        id={"endNote"}
                        type={basicMarkItem.type}
                        icon={basicMarkItem.icon}
                    />
                </TooltipIcon>
            ))}
        </>
    );
};


export const EndNotesFontVariationToolbar = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const handleDropdownVisibleChange = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
  
    return (
      <Dropdown
        overlay={
          <Menu className='at-toolbar-dropdown'>
            <Menu.Item key='monospace' onClick={handleDropdownVisibleChange}>
              <MarkToolbarButton
                id={"endNote"}
                reversed
                type='monospace'
                clear={["smallcaps", "sansserif"]}
                icon={
                  <Row align='middle' gutter={15}>
                    <Col className='at-toolbar-menu-col'>
                      <span className='at-tick-square'>
                        <TickSquare />
                      </span>
                    </Col>
                    <Col>
                      <div className='tlb-monospace'>Monospace</div>
                    </Col>
                  </Row>
                }
              ></MarkToolbarButton>
            </Menu.Item>
            <Menu.Item key='smallcaps' onClick={handleDropdownVisibleChange}>
              <MarkToolbarButton
                id={"endNote"}
                reversed
                type='smallcaps'
                clear={["monospace", "sansserif"]}
                icon={
                  <Row align='middle' gutter={15}>
                    <Col className='at-toolbar-menu-col'>
                      <span className='at-tick-square'>
                        <TickSquare />
                      </span>
                    </Col>
                    <Col>
                      <div className='tlb-smallcaps'>Smallcaps</div>
                    </Col>
                  </Row>
                }
              ></MarkToolbarButton>
            </Menu.Item>
            <Menu.Item key='sansserif' onClick={handleDropdownVisibleChange}>
              <MarkToolbarButton
                id={"endNote"}
                reversed
                type='sansserif'
                clear={["smallcaps", "monospace"]}
                icon={
                  <Row align='middle' gutter={15}>
                    <Col className='at-toolbar-menu-col'>
                      <span className='at-tick-square'>
                        <TickSquare />
                      </span>
                    </Col>
                    <Col>
                      <div className='tlb-sansserif'>Sans Serif</div>
                    </Col>
                  </Row>
                }
              ></MarkToolbarButton>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key='subscript' onClick={handleDropdownVisibleChange}>
              <MarkToolbarButton
                id={"endNote"}
                reversed
                type={MARK_SUBSCRIPT}
                clear={[MARK_SUPERSCRIPT]}
                icon={
                  <Row align='middle' gutter={15}>
                    <Col className='at-toolbar-menu-col'>
                      <span className='at-tick-square'>
                        <TickSquare />
                      </span>
                    </Col>
                    <Col>
                      <div>
                        Subscript <sub>2</sub>
                      </div>
                    </Col>
                  </Row>
                }
              ></MarkToolbarButton>
            </Menu.Item>
            <Menu.Item key='superscript' onClick={handleDropdownVisibleChange}>
              <MarkToolbarButton
                id={"endNote"}
                reversed
                type={MARK_SUPERSCRIPT}
                clear={[MARK_SUBSCRIPT]}
                icon={
                  <Row align='middle' gutter={15}>
                    <Col className='at-toolbar-menu-col'>
                      <span className='at-tick-square'>
                        <TickSquare />
                      </span>
                    </Col>
                    <Col>
                      <div>
                        Superscript <sup>2</sup>
                      </div>
                    </Col>
                  </Row>
                }
              ></MarkToolbarButton>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key='strikethrough' onClick={handleDropdownVisibleChange}>
              <MarkToolbarButton
                id={"endNote"}
                reversed
                type={MARK_STRIKETHROUGH}
                icon={
                  <Row align='middle' gutter={15}>
                    <Col className='at-toolbar-menu-col'>
                      <span className='at-tick-square'>
                        <TickSquare />
                      </span>
                    </Col>
                    <Col>
                      <div className='tlb-strikethrough'>Strikethrough</div>
                    </Col>
                  </Row>
                }
              ></MarkToolbarButton>
            </Menu.Item>
          </Menu>
        }
        onOpenChange={handleDropdownVisibleChange}
      >
        <span className='at-dropdown-toolbar'>
          <StrikethroughIcon color={CURRENT_COLOR} />
  
          {isDropdownOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </span>
      </Dropdown>
    );
  };

export const EndNotesToolbar = () => {
  return (
    <>
      <Row>
        <Col className="plate-toolbar-wrapper">
          <div className="end-notes-toolbar">
            <EndNotesBasicMarkToolbar />
            <Divider type="vertical" className="divider" />
            <EndNotesFontVariationToolbar />
            <Divider type="vertical" className="divider" />
            <EndNotesListToolbar/>
          </div>
        </Col>
      </Row>
    </>
  );
};