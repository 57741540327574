import { createPluginFactory } from "@udecode/plate-core";

import { AlignComponent } from "./components/alignComponent";
import { withAlign } from "./normalizers/withAlign";

export const ELEMENT_ALIGN_CENTER = "align_center";
export const ELEMENT_ALIGN_RIGHT = "align_right";

export const createCenterAlignPlugin = createPluginFactory({
  key: "UNIQUE_ALIGN_KEY_CENTER",
  isElement: true,
  component: AlignComponent,
  type: "align_center",
  withOverrides: withAlign,
});

export const createRightAlignPlugin = createPluginFactory({
  key: "UNIQUE_ALIGN_KEY_RIGHT",
  isElement: true,
  component: AlignComponent,
  type: "align_right",
  withOverrides: withAlign,
});
