import { Tooltip } from "antd";
import { Moment } from "moment";
import React from "react";
import "./styles.scss";
import { buildDateGrid } from "./utils";

interface CalendarHeatmapProps {
  /**
   * Any date within the month the calendar should display.
   *
   * @type {Moment}
   */
  date: Moment;

  /**
   * Do each individual calendar cells need to display the date within them?
   *
   * @type {boolean}
   */
  showDate: boolean;

  /**
   * For each individual calendar cell, you may provide an override CSS class.
   *
   * @param {Moment} day - Date object of the day about to be rendered, which must be used to provide an override class.
   *
   * @returns {(string | null)} - A string value will be added to the display, a null value returned will not add the class.
   */
  additionalCellClass?(day: Moment): string | null;

  /**
   * For each individual calendar cell, you may provide a tooltip text.
   *
   * @param {Moment} day - Date object of the day about to be rendered, which must be used to provide the tooltip text.
   *
   * @returns {(string | null)} - A string value will be added to the tooltip, a null value will disable the tooltip for that cell.
   */
  tooltipForCell?(day: Moment): string | null;
}

/**
 * Day of the week labels, from Sunday to Saturday.
 *
 * @type { string[] }
 */
const HEADER_LABELS = ["S", "M", "T", "W", "T", "F", "S"];

const CalendarHeatmap: React.FC<CalendarHeatmapProps> = (
  props: CalendarHeatmapProps
): React.ReactElement => {
  const grid = buildDateGrid(props.date);

  const applyTooltipIfApplicable = (
    element: React.ReactNode,
    day: Moment | null
  ) => {
    if (day === null) return element;

    const tooltipText = props.tooltipForCell && props.tooltipForCell(day);

    return typeof tooltipText === "string" ? (
      <Tooltip placement="top" title={tooltipText} key={`tooltip-${day.unix()}`}>
        {element}
      </Tooltip>
    ) : (
      element
    );
  };

  return (
    <div className="calendar-heatmap-container">
      <div className="calendar-row calendar-header">
        {HEADER_LABELS.map((label, index) => (
          <span
            className="calendar-header-item calendar-row-item"
            key={`calendar-header-${index}`}
          >
            {label}
          </span>
        ))}
      </div>
      {grid.days.map((week, weekIndex) => (
        <div className="calendar-row" key={`calendar-row-week-${weekIndex}`}>
          {week.map((day, dayIndex) =>
            applyTooltipIfApplicable(
              <span
                className={`calendar-row-item ${
                  day === null ? "null-item" : ""
                } ${
                  day !== null &&
                  props.additionalCellClass &&
                  props.additionalCellClass(day)
                }`}
                key={`calendar-cell-${weekIndex}-${dayIndex}`}
              >
                {day !== null && props.showDate && day.date()}
              </span>,
              day
            )
          )}
        </div>
      ))}
    </div>
  );
};

export default CalendarHeatmap;
