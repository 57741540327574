import React, { FunctionComponent, useState } from "react";
import { observer } from "mobx-react";

// 3rd party Components
import { Formik } from "formik";
import { Form, Input, message, FormItemProps } from "antd";
import { useHistory, Redirect, Link } from "react-router-dom";

// Data fetching
import { AtticusClient } from "../../api/atticus.api";

// Store
import useRootStore from "../../store/useRootStore";

import { AtticusFullLogo } from "../../content/icons";
import { AtticusTextField } from "../../components/Shared/Form/Input";
import { BackgroundColor, Button, ButtonType } from "../../components/Shared/Buttons/Button";

const styles = {
  miniLogo: {
    height: 100,
    width: 100,
  },
};

// types
// interface FormErrors {
//   email?: string;
//   password?: string;
// }

export const ForgotPassword: FunctionComponent = observer(() => {
  const { token } = useRootStore().authStore;

  const history = useHistory();
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const commonFormItemProps = {
    labelAlign: "left",
    colon: false,
  } as FormItemProps;

  // const HandleButton = () => console.log("submitted");

  if (token) return <Redirect to="/" />;

  const onFinish = async (values) => {
    try {
      setIsSubmitting(true);
      await AtticusClient.ForgotPassword(values.email);
      message.success(`Email sent to ${values.email}`);
      history.replace("/");
    } catch (e: any) {
      setIsSubmitting(false);
      if (e.response && e.response.status === 406) {
        const validationErrors = e.response.data.errors;
        Object.keys(validationErrors).forEach((k) => {
          form.setFields([{ name: k, errors: [validationErrors[k].message] }]);
        });
        message.error("Please fix the highlighted fields", 4);
      } else {
        message.error("This email address is not connected to an Atticus account");
      }
    } finally {
      form.setFieldsValue({});
      setIsSubmitting(false);
    }
  };

  return (
    <Form
      form={form}
      initialValues={{ email: "" }}
      onFinish={onFinish}
      layout="vertical"
      validateTrigger={["onBlur", "onInput"]}
    >
      <div className="auth-card">
        <div className="text-center inner-s">
          <h1 className="inner-xs">Forgot your password?</h1>
          <p>Don’t worry! Resetting your password is easy.<br /> Just type In your email</p>
        </div>
        <div className="inner-s">
          <Form.Item
            className="at-form-item"
            label="Email"
            name="email"
            rules={
              [
                {
                  type: "email",
                  required: true,
                  message: "A valid email is required"
                }
              ]
            }
          >
            <AtticusTextField
              placeholder="yourname@gmail.com"
            />
          </Form.Item>
        </div>

        <div className="inner-xs">
          <Button
            block
            type={ButtonType.PRIMARY}
            backgroundColor={BackgroundColor.BLUE}
            htmlType="submit"
            loading={isSubmitting}
          >
            Send reset instructions
          </Button>
        </div>
        <div className="inner-xs text-center">
          <p>Remember your password?{" "}
            <Link to="/auth/sign-in" className="default-link">
              <b>{"Sign in"}</b>
            </Link>
          </p>
        </div>
      </div>
    </Form>
  );
});