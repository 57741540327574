import {
  createPluginFactory,
  onKeyDownToggleMark,
} from "@udecode/plate";

export const MARK_SANSSERIF = "sansserif";

export const createSansSerifPlugin = createPluginFactory({
  key: MARK_SANSSERIF,
  isLeaf: true,
  handlers: {
    onKeyDown: onKeyDownToggleMark,
  },
  inject:{
    props:{
      transformStyle:() => ({fontFamily: "PT Sans, sans-serif"})
    }
  }
});
