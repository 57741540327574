import React from "react";
import { Divider, DividerProps } from "antd";

export const AtticusDivider: React.FC<DividerProps> = (props) => {
  return (
    <Divider {...props}>
      {props.children}
    </Divider>
  );
};
