export const parseOrnamentalBreak = (config: IThemeStore.ThemeStyleProps): string => {
  return config.sceneBreakShowSetting === "break-with-image" ?
    `<span class="ornamental-break">
      ${config.ornamentalBreakImage
        ? `<img src="${config.ornamentalBreakImage}" >`
        : "***"
    }</span>` : `
    <span class="ornamental-break"></span>
  `;
};
