import React from "react";
import {
  getPreventDefaultHandler,
  someNode,
  useEventPlateId,
  usePlateEditorState,
} from "@udecode/plate-core";
import { ToolbarButton, ToolbarButtonProps } from "@udecode/plate-ui-toolbar";
import { ELEMENT_CALLOUTBOX } from "../createCalloutBoxPlugin";
import { toggleCalloutBoxNodes } from "../transforms/toggleCalloutBoxNodes";
import { allowFeatureForList } from "../../../config/lists";
import { CalloutModalStore } from "../../../../../store/CalloutModal";
import { allowFeatureForTextMessages } from "../../text-message/config";

export const CalloutBoxToolbarButton = ({
  id,
  ...props
}: ToolbarButtonProps) => {
  const callOutStore = new CalloutModalStore();
  const editor = usePlateEditorState(useEventPlateId(id));

  const enabled = allowFeatureForList(editor) && allowFeatureForTextMessages(editor);

  return (
    <div className={`${enabled ? "" : "plate-disabled"}`}>
      <ToolbarButton
        active={
          !!editor?.selection &&
          someNode(editor, { match: { type: ELEMENT_CALLOUTBOX } })
        }
        onMouseDown={
          editor
            ? getPreventDefaultHandler(
                toggleCalloutBoxNodes,
                editor,
                callOutStore.background,
                callOutStore.border,
              )
            : undefined
        }
        {...props}
      />
    </div>
  );
};
