import { makeAutoObservable } from "mobx";
import { TPath, TReactEditor } from "@udecode/plate";
import { AtticusClient } from "../api/atticus.api";
import { insertProfileToEditor, removeProfileFromEditor } from "../components/Plate/plugins/social-media-link";

export class SocialProfileStore {

	showProfileModal = false;
	selectedSocialProfileId: string | null = null;
	userSMProfiles: ISocialProfileStore.ISMProfile[] = [];
	modalType = "";
	modalState: ISocialProfileStore.ModalState = "default";
	smAccountsToInsert: ISocialProfileStore.ISMAccount[] = [];
	smProfileToInsert: ISocialProfileStore.ISMProfile | null = null;
	smProfilePathToReplace?: TPath; 

	constructor() {
		makeAutoObservable(this);
	}

	setShowProfileModal = (visible: boolean): void => {
		this.showProfileModal = visible;
	}

	resetAndHideProfileModal = (): void => {
		this.setShowProfileModal(false);
		this.resetModal();
	}

	setModalState = (state: "default" | "editProfile" | "addNewProfile"): void => {
		this.modalState = state;
	}

	resetSelectedProfile = (): void => {
		this.selectedSocialProfileId = null;
	}

	resetModal = (leaveElementPath?: boolean): void => {
		this.modalState = "default";
		this.resetSelectedProfile();
		this.resetSMProfileToInsert();
		if(!leaveElementPath){
			this.resetSelectSMProfilePath();
		}
	}

	showEditProfileModalFromEditor = (profileId: string): void => {
		this.setSelectedSocialProfileId(profileId);
		this.setModalState("editProfile");
		this.setShowProfileModal(true);
	}

	setSelectedSocialProfileId = (id: string): void => {
		this.selectedSocialProfileId = id;
	}

	setSMProfilePathToReplace = (path?: TPath): void => {
		this.smProfilePathToReplace = path;
	}

	resetSelectSMProfilePath = (): void => {
		this.setSMProfilePathToReplace(undefined);
	}

	setUserSMProfiles = (profiles: ISocialProfileStore.ISMProfile[]): void => {
		this.userSMProfiles = profiles;
	}

	setSMAccountsToInsert = (accounts: ISocialProfileStore.ISMAccount[]): void => {
		this.smAccountsToInsert = accounts;
	}

	setSMProfileToInsert = (profile: ISocialProfileStore.ISMProfile): void => {
		this.smAccountsToInsert = profile.smAccounts;
		this.smProfileToInsert = profile;
	}

	resetSMProfileToInsert = (): void => {
		this.smAccountsToInsert = [];
		this.smProfileToInsert = null;
	}

	addNewSMProfileToDB = async (profileParams: ISocialProfileStore.ICreateSMProfile): Promise<void> => {
		const createProfileResponse = await AtticusClient.CreateProfile(profileParams);
		await this.getSMProfilesFromDB();
		this.resetSelectedProfile();
		if(createProfileResponse.data){
			this.setSMProfileToInsert(createProfileResponse.data);
		}
		this.modalState = "default";
	}

	getSMProfilesFromDB = async (): Promise<void> => {
		const profiles = await AtticusClient.GetProfiles();
		this.setUserSMProfiles(profiles.data);
	}

	editSMProfileInDB = async(profile: ISocialProfileStore.ISMProfile): Promise<void> => {
		await AtticusClient.UpdateProfile(profile);
		await this.getSMProfilesFromDB();
		this.resetSelectedProfile();
		this.setSMProfileToInsert(profile);
		this.modalState = "default";
	}

	deleteSMProfileFromDB = async(profileId: string): Promise<void> => {
		await AtticusClient.DeleteProfile(profileId);
		await this.getSMProfilesFromDB();
	}

	insertAccountsIntoEditor = (editor: TReactEditor): void => {
		if (this.smAccountsToInsert.length) {
			if(this.smProfilePathToReplace){
				removeProfileFromEditor(this.smProfilePathToReplace, editor);
			}
			insertProfileToEditor(editor, this.smProfilePathToReplace, {
				url: this.smAccountsToInsert,
				_id: this.smProfileToInsert?._id,
				profileName: this.smProfileToInsert?.profileName,
				printAlign: this.smProfileToInsert?.printAlign,
			});
			this.resetAndHideProfileModal();
			this.resetSelectSMProfilePath();
		}
	}

}

export default new SocialProfileStore();