import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  FormInstance,
  Checkbox,
  Form,
  Row,
  Col,
  Button,
  Slider,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Divider,
} from "antd";

import { HANGING_INDENT_IN_MAX, HANGING_INDENT_IN_MIN, HANGING_INDENT_IN_STEP, HANGING_INDENT_MM_MAX, HANGING_INDENT_MM_MIN, HANGING_INDENT_MM_STEP, MARGIN_IN_MAX, MARGIN_IN_MIN, MARGIN_MM_MAX, MARGIN_MM_MIN, convertHangingIndentValue, convertMarginValue } from "../helpers";
interface AdvancedSettingsProps {
  form: FormInstance<IThemeStore.ThemeFields>;
  onValuesChange: (fields: Partial<IThemeStore.ThemeFields>) => void;
}

const linespacingmarks = {
  1: {
    label: "Single",
  },
  1.25: {
    label: "1.25",
  },
  1.5: {
    label: "1.5",
  },
  1.75: {
    label: "1.75",
  },
  2: {
    label: "Double",
  },
};

const fontsizemarks = {
  9: {
    label: "9pt",
  },
  10: {
    label: "10pt",
  },
  11: {
    label: "11pt",
  },
  12: {
    label: "12pt",
  },
  13: {
    label: "13pt",
  },
  14: {
    label: "14pt",
  },
  15: {
    label: "15pt",
  },
  16: {
    label: "16pt",
  },
  17: {
    label: "17pt",
  },
  18: {
    label: "18pt",
  },
};

const AdvancedSettings = ({
  form,
  onValuesChange,
}: AdvancedSettingsProps): JSX.Element => {
  const resetMargins = () => {
    if(form.getFieldValue("margin_unit") === "in") {
      form.setFieldsValue({ margin_in: 0.875, margin_out: 0.5 });
      onValuesChange({ margin_in: 0.875, margin_out: 0.5 });
    } else {
      form.setFieldsValue({ margin_in: 22.3, margin_out: 12.7 });
      onValuesChange({ margin_in: 22.3, margin_out: 12.7 });
    }
  };

  const resetHangingIndents = () => {
    if(form.getFieldValue("margin_unit") === "in") {
      form.setFieldsValue({ hanging_indent: HANGING_INDENT_IN_MIN});
      onValuesChange({ hanging_indent: HANGING_INDENT_IN_MIN });
    } else {
      form.setFieldsValue({ hanging_indent: HANGING_INDENT_MM_MIN});
      onValuesChange({ hanging_indent: HANGING_INDENT_MM_MIN });
    }
  };

  const [showInMM, setShowInMM] = useState<boolean>(form.getFieldValue("margin_unit") === "mm");

  const handleUnitChange = (e: RadioChangeEvent) => {
    const marginUnit = e.target.value;
    const convertedMarginInValue = convertMarginValue({
      to: marginUnit,
      value: form.getFieldValue("margin_in"),
    });

    const convertedMarginOutValue = convertMarginValue({
      to: marginUnit,
      value: form.getFieldValue("margin_out"),
    });

    const convertedHangingIndentValue = convertHangingIndentValue({
      to: marginUnit,
      value: form.getFieldValue("hanging_indent"),
    });

    setShowInMM(marginUnit === "mm");
    form.setFieldsValue({
      margin_unit: marginUnit,
      margin_in: convertedMarginInValue,
      margin_out: convertedMarginOutValue,
      hanging_indent: convertedHangingIndentValue,
    });
    onValuesChange({
      margin_unit: marginUnit,
      margin_in: convertedMarginInValue,
      margin_out: convertedMarginOutValue,
      hanging_indent: convertedHangingIndentValue,
    });
  };
  
  
  return (
    <>
      <div id="advance-settings" className="fullWidth">
        <div className="thm-section fullWidth">
          <>
                <Form.Item name="margin_unit">
                  <Radio.Group buttonStyle="solid" onChange={handleUnitChange}>
                    <Radio value="in">inches</Radio>
                    <Radio value="mm">mm</Radio>
                  </Radio.Group>
              </Form.Item>
          </>
          <Row gutter={16}>
            <Col span={12}>
              <div className="margins-form-wrapper">
                <h4 className="margins-heading">Margins</h4>
                <Button size="small" onClick={resetMargins} type="link">
                  Reset
                </Button>
              </div>
              <Row gutter={16}>
                <Col>
                <div className="margin-input">
                  <span>Inside</span>
                  <Form.Item name="margin_in" className="margin-form-item">
                    <InputNumber
                      step={showInMM ? MARGIN_MM_MIN : MARGIN_IN_MIN}
                      max={showInMM ? MARGIN_MM_MAX : MARGIN_IN_MAX}
                      min={showInMM? MARGIN_MM_MIN : MARGIN_IN_MIN}
                      formatter={(value) => `${value} ${showInMM ? "mm" : "in"}`}
                    />
                  </Form.Item>
                </div>
                </Col>
                <Col>
                  <div className="margin-input">
                    <span>Outside</span>
                    <Form.Item name="margin_out" className="margin-form-item">
                      <InputNumber
                        step={showInMM ? MARGIN_MM_MIN : MARGIN_IN_MIN}
                        max={showInMM ? MARGIN_MM_MAX : MARGIN_IN_MAX}
                        min={showInMM? MARGIN_MM_MIN : MARGIN_IN_MIN}
                        formatter={(value) => `${value} ${showInMM ? "mm" : "in"}`}
                      />
                  </Form.Item>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <div className="margins-form-wrapper">
                <h4 className="margins-heading">Indents</h4>
                <Button size="small" onClick={resetHangingIndents} type="link">
                  Reset
                </Button>
              </div>
              <Form.Item name="hanging_indent">
                <InputNumber
                  step={showInMM ? HANGING_INDENT_MM_STEP : HANGING_INDENT_IN_STEP}
                  max={showInMM ? HANGING_INDENT_MM_MAX : HANGING_INDENT_IN_MAX}
                  min={showInMM? HANGING_INDENT_MM_MIN : HANGING_INDENT_IN_MIN}
                  formatter={(value) => `${value} ${showInMM ? "mm" : "in"}`}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider className="margins-divider" />
        </div>
        <div className="thm-section fullWidth">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="paraAlignment" label="Alignment">
                <Checkbox.Group>
                  <Checkbox value="justify">Justified</Checkbox>
                  <Checkbox value="hyphens">Hyphens</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>

      <div className="thm-section fullWidth">
        <Row>
          <Col span={24}>
            <Form.Item name="fontsize" label="Font Size">
              <Slider min={9} max={18} marks={fontsizemarks} />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div className="thm-section fullWidth">
        <Row>
          <Col span={24}>
            <Form.Item name="linespacing" label="Line Spacing">
              <Slider min={1} max={2} step={0.05} marks={linespacingmarks} />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div className="thm-section fullWidth">
        <Row>
          <Form.Item name="dynamicPageBreaks" label="Keep Options">
            <Checkbox.Group>
              <Checkbox value="breakOrnamentalBreaks">
                Ornamental Breaks
              </Checkbox>
              <Checkbox value="breakSubheadings">Subheadings</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Row>
      </div>

      <div className="thm-section fullWidth">
        <Row gutter={32}>
          <Col span={32}>
            <Form.Item
              name="layoutPriority"
              label="Layout Priority"
            >
              <Radio.Group>
                <Radio value="WIDOWS_AND_ORPHANS">Widows and Orphans</Radio>
                <Radio value="BALANCED_PAGE_SPREAD">Balanced Page Spread</Radio>
                <Radio value="BEST_OF_BOTH">Best of Both</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default observer(AdvancedSettings);
