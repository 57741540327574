import {
  someNode,
  wrapNodes,
  TEditor,
  TElement,
  Value,
  unwrapNodes,
  getNodeEntries,
} from "@udecode/plate-core";
import { ELEMENT_CALLOUTBOX } from "../../callout-box";
import { ELEMENT_TEXT_MESSAGE, ELEMENT_TEXT_MESSAGES_GROUP } from "../../text-message";
import { ELEMENT_END_NOTE } from "../../end-notes/types";
import { ELEMENT_LINK } from "@udecode/plate";

/**
 * @constant DISABLED_CONTAINERS
 * @description Adding an element in this will disable alignment wrapping the entire element and
 * instead wrap elements within that particular container.
 */
const DISABLED_CONTAINERS = [
  ELEMENT_CALLOUTBOX,
  ELEMENT_TEXT_MESSAGES_GROUP,
  ELEMENT_TEXT_MESSAGE,
  ELEMENT_END_NOTE,
  ELEMENT_LINK
];

/**
 *
 * @param editor Plate Editor
 * @param detachTypes Conflicting Node types to unwrap if already wrapped with
 * @param targetType Node type to wrap with
 *
 *
 * Unwraps nodes with conflicting node types
 * Re-Wrap Nodes if a target type is given and if the nodes are not already wrapped with the given target
 * type, to avoids nesting with the same type of wrap
 */
export const reWrapNodes = <V extends Value>(
  editor: TEditor<V>,
  detachTypes: string[],
  targetType?: string,
): void => {
  detachTypes.map((type) => {
    unwrapNodes(editor, { match: { type } });
  });

  /**
   * Checks the following condition:
   * - Editor Selection is defined.
   * - Target Type is defined.
   * - The selection is not the target alignment type.
   */
  if (
    editor.selection &&
    targetType &&
    !someNode(editor, { match: { type: targetType } })
  ) {
    // 1. Fetch node entries within selection
    const nodeEntries = getNodeEntries(editor, {
      at: editor.selection,
    });

    // 2. Convert the entries into an array
    const nodes = Array.from(nodeEntries);

    // 3. Iterate through the array converted entries. Every element in the array is a sub-array
    /**
     * 3. Iterate through the array converted entries.
     * - Each array element within the converted array is a sub-array with 2 elements.
     * - The first element is the node itself.
     * - The second element is the path within the editor (for the node).
     * - We check if the node type is not undefined (to ignore containers, as we target elements).
     * - If it is an element for sure, then we wrap it with the preferred alignment direction.
     */
    for (const [node, path] of nodes) {
      if (typeof node.type !== "undefined" && node.type !== null) {
        if (!DISABLED_CONTAINERS.includes(node.type as string)) {
          wrapNodes<TElement>(
            editor,
            {
              type: targetType,
              children: [],
            },
            {
              at: path,
            }
          );
        }
      }
    }
  }
};
