import React, { FunctionComponent, useState, useEffect } from "react";
import { Progress, Card } from "antd";

//IndexedDB
import { db } from "../../db/bookDb";
import { AtticusClient } from "../../api/atticus.api";

import { GetBookFromDB } from "../../utils/offline.book.helpers";
import { Book } from "../../types/book";

interface BackupProps{
    download?: boolean,
    onComplete?: () => void;
}

const Backup: FunctionComponent<BackupProps> = ({ onComplete, download }: BackupProps) => {
  const [ numBooks, setNumBooks ] = useState<number>();
  const [ completeSync, setCompleteSync ] = useState<number>(0);

  const downloadFile = async (fileName, snapshotStr) => {
    const blob = new Blob([snapshotStr],{type: "application/json" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const doBackup = async () => {
    const offlineBooks = await db.books.toArray();
    const completeBooks: Book[] = [];

    setNumBooks(offlineBooks.length);

    let cur = 0;
    let errors = 0;

    // Save snapshots
    for (const book of offlineBooks) {
      const fullBook = await GetBookFromDB(book._id, { chapterMeta: true, chapterBodies: true });

      if (fullBook) {
        completeBooks.push(fullBook);

        try {
          await AtticusClient.SaveSnapshot(book._id, JSON.stringify(fullBook));
        } catch (e: any) {
          console.log(e.message);
          errors += 1;
        }
        cur += 1;
        setCompleteSync(cur);
      }
    }

    //if snapshots fails then download the file as JSON
    if (errors > 0 || download) {
        await downloadFile(
            `atticus-backup-${new Date().toISOString()}`.replace(/ /ig, "-"), 
            JSON.stringify(completeBooks)
        );
    }

    //Finally, Logout
    if (onComplete) {
      onComplete();
    }
  };

  useEffect(() => {
    doBackup();
  }, []);

  return (
    <div>
      <div className="container" style={{ padding: "2rem 2rem", display: "flex", justifyContent: "center" }}>
        <Card style={{
          display: "flex", 
          justifyContent: "center", 
          flexDirection: "column",
          width: 500,
        }}>
          <h3>Backing Up Data</h3>
          {numBooks ? (
            <>
              <h4>Syncing {completeSync}/{numBooks} Books</h4>
              <Progress
                strokeColor={{
                  "0%": "#108ee9",
                  "100%": "#87d068",
                }}
                percent={Math.round((completeSync/numBooks) * 100)}
              />
            </>
          ) : null}
        </Card>
      </div>
    </div>
  );
};
export default Backup;