export enum ThemeFieldFontStyle {
  regular = "regular",
  bold = "bold",
  italic = "italic",
  boldItalic = "boldItalic",
  underlined = "underlined",
  smallcaps = "small-caps",
  weight_100 = "100",
  weight_200 = "200",
  weight_300 = "300",
  weight_500 = "500",
  weight_600 = "600",
  weight_700 = "700",
  weight_800 = "800",
  weight_900 = "900",
  italic_100 = "100italic",
  italic_200 = "200italic",
  italic_300 = "300italic",
  italic_500 = "500italic",
  italic_600 = "600italic",
  italic_700 = "700italic",
  italic_800 = "800italic",
  italic_900 = "900italic",
}

export const ThemeFieldFontStyleMap = new Map<ThemeFieldFontStyle, string>([
  [ThemeFieldFontStyle.regular, "Regular"],
  [ThemeFieldFontStyle.bold, "Bold"],
  [ThemeFieldFontStyle.italic, "Italics"],
  [ThemeFieldFontStyle.boldItalic, "Bold & Italics"],
  [ThemeFieldFontStyle.underlined, "Underlined"],
  [ThemeFieldFontStyle.smallcaps, "Small Caps"],
  [ThemeFieldFontStyle.weight_100, "100"],
  [ThemeFieldFontStyle.weight_200, "200"],
  [ThemeFieldFontStyle.weight_300, "300"],
  [ThemeFieldFontStyle.weight_500, "500"],
  [ThemeFieldFontStyle.weight_600, "600"],
  [ThemeFieldFontStyle.weight_700, "700"],
  [ThemeFieldFontStyle.weight_800, "800"],
  [ThemeFieldFontStyle.weight_900, "900"],
  [ThemeFieldFontStyle.italic_100, "100italic"],
  [ThemeFieldFontStyle.italic_200, "200italic"],
  [ThemeFieldFontStyle.italic_300, "300italic"],
  [ThemeFieldFontStyle.italic_500, "500italic"],
  [ThemeFieldFontStyle.italic_600, "600italic"],
  [ThemeFieldFontStyle.italic_700, "700italic"],
  [ThemeFieldFontStyle.italic_800, "800italic"],
  [ThemeFieldFontStyle.italic_900, "900italic"],
]);
