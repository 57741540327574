import {
  PlateEditor,
  removeNodes,
  liftNodes,
  TNodeEntry,
  unwrapNodes,
  getNodeEntries,
  insertNodes,
  setSelection,
} from "@udecode/plate";
import {
  Element,
  Node,
  Text,
  BaseElement,
  BaseEditor,
  Descendant,
  Editor,
} from "slate";

import { NodeText, NodeType } from "../../types";

// Element type definitions
export const blockElements = [
  "p",
  "h2",
  "blockquote",
  "code_block",
  "align_center",
  "align_right",
  "ornamental-break",
  "image",
  "ul",
  "ol",
  "li",
  "break-chapter",
];
export const normalizeElements = [
  "align_center",
  "align_right",
  "h2",
  "blockquote",
  "code_block",
];
export const voidElements = [
  "ornamental-break",
  "image",
  "break-chapter",
  "endnote",
  "indent",
  "text_messages",
];
export const aligmentElements = ["align_center", "align_right"];
export const inlineElements = ["endnote", "link"];
export const supportedLiChildrenElements = ["ul", "ol", "p"];
export const listElements = ["ul", "ol"];
export const invalidAlignmentElements = ["align_left"];

export const withAlign = <T extends PlateEditor>(editor: T): PlateEditor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]: TNodeEntry) => {
    if (!Element.isElement(node)) return;

    if (
      invalidAlignmentElements.indexOf(
        (node as (BaseElement | BaseEditor) & NodeType).type
      ) > -1
    ) {
      unwrapNodes(editor, { at: path });
      return;
    }

    if (
      aligmentElements.indexOf(
        (node as (BaseElement | BaseEditor) & NodeType).type
      ) > -1
    ) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (
          (Element.isElement(child) &&
            inlineElements.indexOf((child as BaseElement & NodeType).type) ===
              -1 &&
            voidElements.indexOf((child as BaseElement & NodeType).type) >
              -1) ||
          listElements.indexOf((child as Element & NodeType).type) > -1
        ) {
          liftNodes(editor, { at: childPath });
          return;
        }
      }

      /**
       * Start Normalizing Alignment Nodes, where, when an Alignment Ancestor Node has more than one child,
       * we split the children into two separate children with the same type of Ancestor Node.
       *
       * i.e. A block of "align_center" with two children of type "p" would result into two "align_center" blocks with
       * one of each of the two children previously contained within a common alignment block ancestor.
       */

      /* Step 1: Fetch all nodes */
      const nodeEntries = getNodeEntries(editor);

      const nodes = Array.from(nodeEntries);

      /* Step 2: Iterate through each node */
      for (const [node, path] of nodes) {
        /**
         * Step 3: Check if the node is an element, and if so,
         * if the type matches the alignment types and has more than one child,
         * we continue to Step 4.
         */
        if (
          Element.isElement(node) &&
          aligmentElements.includes(node.type as string) &&
          node.children.length > 1
        ) {
          /**
           * Step 4: Identify alignment type and note the different path parameters.
           */
          const type = node.type as string;

          const parentPath = path.slice(0, path.length - 1);
          const nodeIndex = path[path.length - 1];

          /**
           * Step 5: Duplicate each child as a sibling to the "align" parent node within the same
           * type of "align" parent.
           */
          node.children.forEach((child, index) => {
            const newPath = parentPath.concat(nodeIndex + index + 1);
            insertNodes(editor, { type, children: [child] }, { at: newPath });
          });

          /**
           * Step 6: Remove the initial parent node with multiple children.
           */
          removeNodes(editor, { at: path });

          /**
           * Step 7: Fetch the last elements path
           * (i.e. if the Node was split into two, get the second child's new path)
           */
          const lastElementPath = Editor.after(
            editor as BaseEditor,
            parentPath.concat(nodeIndex)
          );

          /**
           * Step 8: If such a path was found, move the cursor to the start of that element so that
           * the cursor doesn't randomly go else-where.
           */
          if (lastElementPath) {
            setSelection(editor, {
              anchor: lastElementPath,
              focus: lastElementPath,
            });
          }
        }
      }
    }

    /** End Alignment Normalizer */

    if (
      blockElements.indexOf(
        (node as (BaseElement | BaseEditor) & NodeType).type
      ) > -1
    ) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (
          Element.isElement(child) &&
          (child as BaseElement & NodeType).type === "a"
        ) {
          let allEmpty = true;
          let invalidNodes = false;

          for (const [linkChild] of Node.children(editor, childPath)) {
            if (!Text.isText(linkChild)) {
              invalidNodes = true;
              allEmpty = false;
            }

            if (
              (linkChild as Descendant & NodeText).text &&
              ((linkChild as Descendant & NodeText).text as string).length > 0
            ) {
              allEmpty = false;
            }
          }

          if (allEmpty) {
            removeNodes(editor, {
              at: childPath,
            });
            return;
          }

          if (invalidNodes) {
            console.log("INVALID NODE");
            console.log(child);
            liftNodes(editor, {
              at: childPath,
              match: (n) => !Text.isText(n),
            });
            return;
          }
        }
      }
    }

    normalizeNode([node, path]);
  };

  return editor;
};
