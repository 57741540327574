import { Image } from "../types/image-gallery";

const ORNAMENTAL_BREAKS_S3_BUCKET = "https://atticus-content.s3.amazonaws.com/ornamental-breaks/";
const COLOR_VARIATION = "Black";
const FOLDER_NAME = "atticus-images";

export const getAtticusImages = (limit: number, userId: string): Image[] => {
	const images: Image[] = [];
	for (let i = 1; i <= limit; i += 1) {
		images.push({
      _imgId: `Atticus-Image-${i}.png`,
      link: `${ORNAMENTAL_BREAKS_S3_BUCKET}${COLOR_VARIATION}-${i < 10 ? `0${i}` : `${i}`}.png`,
      folderName: FOLDER_NAME,
      userId,
      date: new Date("2021-04-22")
    });
	}
	return images;
};