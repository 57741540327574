import React from "react";
import { Row, Col } from "antd";

import { Container } from "../components/Shared/Layouts/Container";
import { BooksTabContainer } from "../containers/shelf";
import { BookFilter } from "../components/Books";
import { ScrollContainer } from "../components/Shared/Layouts";

export const MyBooks = (): JSX.Element => {
  return (
    <ScrollContainer className="padded">
      <Container>
        <Row className="inner-s" justify="space-between" align="middle">
          <Col>
            <h1>My books</h1>
          </Col>
          <Col>
            <BookFilter />
          </Col>
        </Row>
        <Col>
          <BooksTabContainer />
        </Col>
      </Container>
    </ScrollContainer>
  );
};
