import {
  findNode,
  findNodePath,
  insertNodes,
  liftNodes,
  PlateEditor,
  removeNodes,
  setNodes,
  TNodeEntry,
  unwrapNodes,
} from "@udecode/plate";
import { Element, Node, BaseElement, BaseEditor } from "slate";

import { NodeType } from "../../types";
import {
  ELEMENT_TEXT_MESSAGE,
  ELEMENT_TEXT_MESSAGES_GROUP,
} from "../createTextMessagePlugin";
import { TextMessageNode } from "../types";

export const withTextMessagesGroup = <T extends PlateEditor>(
  editor: T
): PlateEditor => {
  const { normalizeNode, insertData } = editor;

  editor.insertData = (data: DataTransfer) => {
    const at = editor.selection;
    if (!at) return;

    const pastingNode = findNode(editor, {
      at,
      match: { type: ELEMENT_TEXT_MESSAGE },
    });
    if (pastingNode && pastingNode[0].type === ELEMENT_TEXT_MESSAGE) {
      const pastingPath = findNodePath(editor, pastingNode[0]);
      const text = data.getData("text/plain");

      insertNodes(editor, {
        ...pastingNode[0],
        children: [...(pastingNode[0].children as any), { text }],
      } as any);
      removeNodes(editor, { at: pastingPath });
      return;
    }
    insertData(data);
  };

  editor.normalizeNode = ([node, path]: TNodeEntry) => {
    if (!Element.isElement(node)) return;

    if (
      (node as (BaseElement | BaseEditor) & NodeType).type ===
      ELEMENT_TEXT_MESSAGES_GROUP
    ) {
      let allEmpty = true;
      for (const [child, childPath] of Node.children(editor, path)) {
        if ((child as TextMessageNode).inserting) {
          delete child["inserting"];
          setNodes(
            editor,
            {
              ...child,
            },
            { at: childPath }
          );
        }
        if ((child as BaseElement & NodeType).type !== ELEMENT_TEXT_MESSAGE) {
          liftNodes(editor, {
            at: childPath,
          });
          return;
        }
        if (
          Element.isElement(child) &&
          (child as BaseElement & NodeType).type === ELEMENT_TEXT_MESSAGE
        ) {
          allEmpty = false;
        }
      }
      if (allEmpty) {
        removeNodes(editor, {
          at: path,
        });
        return;
      }
    }

    normalizeNode([node, path]);
  };

  return editor;
};
