import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Form } from "antd";

import FileUpload from "../Forms/FileUpload";
import ImageGallery from "../../ImageGallery";
import { AtticusDivider } from "../Divider";
import { UploadIcon } from "../../../content/icons";
import { Modal } from "../Modal";
import { ButtonType, ButtonSize, Button, DeleteImageButton } from "../Buttons";

interface ImageUploadComponentProps {
  handler: (url: string) => void;
  show: boolean;
}

const ImageUploadComponent = ({ handler, show }: ImageUploadComponentProps) => {
  const [uploading, setUploading] = useState(false);
  const [imageURL, setImageURL] = useState<string>("");

  const clearForm = () => {
    setImageURL("");
  };

  const clearModal = () => {
    clearForm();
    handler("");
  };

  const onSubmit = async () => {
    if (imageURL) {
      handler(imageURL);
    }
  };

  const getIGURL = (link) => {
    setImageURL(link);
  };

  return (
    <Modal
      open={show}
      title="Add an image"
      onCancel={() => clearModal()}
      centered={true}
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
      wrapClassName="image-toolbar-modal-wrap"
    >
      <div className="image-toolbar-body-wrapper">
        <Form className="image-toolbar-ant-form">
          <>
            <ImageGallery
              handleImageUrl={getIGURL}
              ImageGalleryButtonText="Select from image gallery"
              renderButtonProps={{
                type: "at-primary",
                backgroundColor: "green",
                size: ButtonSize.MEDIUM,
                block: true
              }}
            />
            <AtticusDivider>
              <p className="image-toolbar-divider-text">Or</p>
            </AtticusDivider>
            <h4 className="image-toolbar-upload-header">Upload a new image</h4>
            {imageURL ? (
              <>
                <div
                  className="image-toolbar-image-previewer contain"
                  style={{ backgroundImage: `url(${imageURL})` }}
                >
                  <div className="image-toolbar-image-previewer-container">
                    <DeleteImageButton onClick={() => clearForm()}/>
                  </div>
                </div>
                <Button
                  type={ButtonType.PRIMARY}
                  size={ButtonSize.MEDIUM}
                  backgroundColor="green"
                  onMouseDown={() => onSubmit()}
                  fullWidth
                >
                  Set Image
                </Button>
              </>
            ) : (
              <>
                <Form.Item
                  name="upload"
                  className="image-toolbar-upload-form-item"
                >
                  <FileUpload
                    fileType="image/png, image/jpg, image/jpeg"
                    onFileUploadStart={() => setUploading(true)}
                    onFileUpload={(fileUrl) => {
                      if (fileUrl) setImageURL(fileUrl);
                    }}
                    onFileUploadEnd={() => setUploading(false)}
                    folder="images"
                    styleOverrides={{
                      backgroundColor: "#E7EDF8",
                      borderRadius: 4,
                      borderWidth: 2,
                      borderStyle: "dashed",
                      borderColor: "#ADC1E3",
                    }}
                  >
                    <div className="image-toolbar-dnd-container">
                      {uploading ? (
                        <span className="image-toolbar-dnd-text-highlight">
                          <LoadingOutlined /> Uploading
                        </span>
                      ) : (
                        <>
                          <span>
                            <UploadIcon color="#3363B9" />
                          </span>
                          <p>
                            <span className="image-toolbar-dnd-text-highlight">
                              Click to upload image
                            </span>{" "}
                            or drag and drop
                          </p>
                        </>
                      )}
                    </div>
                  </FileUpload>
                </Form.Item>
                <div className="image-toolbar-upload-footer">
                  <p className="image-toolbar-upload-footer-text">
                    Supported formats: PNG, JPG
                  </p>
                  <p className="image-toolbar-upload-footer-text">
                    Maximum size: 10mb
                  </p>
                </div>
              </>
            )}
          </>
        </Form>
      </div>
    </Modal>
  );
};

export default ImageUploadComponent;
