import { IncludeInType } from "../../../types/chapter";
import { isValidChapter } from "../../../utils/toc";

export const getAdjacentChapterIds = (
  chapter: IChapterStore.ChapterMeta,
  book: IBookStore.ExpandedBook
): {
  prev: string | undefined;
  next: string | undefined;
} => {
  if (!chapter) return { prev: undefined, next: undefined };
  const frontMatterChapterIds = book.frontMatter.filter(({_id}) => isValidChapter(_id, book)).map(({_id}) => _id);
  // bodychapterIds without volumes
  const bodyChapterIds = book.chapters
    .filter(
      (chap) =>
        chap.type !== "volume" &&
        chap.type !== "part" &&
        isValidChapter(chap._id, book)
    )
    .map(({ _id }) => _id);

  let prev: string | undefined;
  let next: string | undefined;

  // check if the current chapter is in frontmatter
  const fmIndex = frontMatterChapterIds.indexOf(chapter._id);

  if (fmIndex > -1) {
    // previous chapter
    if (fmIndex > 0) prev = frontMatterChapterIds[fmIndex - 1];

    // next chapter
    if (fmIndex < frontMatterChapterIds.length - 1)
      next = frontMatterChapterIds[fmIndex + 1];
    else if (bodyChapterIds.length > 0) next = bodyChapterIds[0];
  }

  // check if the current chapter is in the body
  const cIndex = bodyChapterIds.indexOf(chapter._id);

  if (cIndex > -1) {
    // previous chapter
    if (cIndex > 0) prev = bodyChapterIds[cIndex - 1];
    else if (frontMatterChapterIds.length > 0)
      prev = frontMatterChapterIds[frontMatterChapterIds.length - 1];

    // next chapter
    if (cIndex < bodyChapterIds.length - 1) next = bodyChapterIds[cIndex + 1];
  }

  return {
    prev,
    next,
  };
};

export const isFrontMatterChapter = (
  chapterType: IChapterStore.ChapterType
): boolean => {
  return [
    "title",
    "blurbs",
    "dedication",
    "epigraph",
    "foreword",
    "introduction",
    "preface",
    "prologue",
  ].includes(chapterType);
};

export const isBackMatterChapter = (
  chapterType: IChapterStore.ChapterType
): boolean => {
  return [
    "epilogue",
    "afterword",
    "acknowledgments",
    "about-author",
    "also-by",
  ].includes(chapterType);
};

export const shouldApplyDropCaps = (
  chapter: IChapterStore.ChapterMeta
): boolean => {
  return [
    "chapter",
    "uncategorized",
    "prologue",
    "epilogue",
    "custom",
  ].includes(chapter.type);
};

export const isIncludedInEpub = (includeIn: IncludeInType | undefined): boolean => {
  return typeof includeIn === "undefined" || ["all", "ebook"].includes(includeIn);
};

export type DeviceName =
  | "paperwhite"
  | "fire"
  | "oasis"
  | "forma"
  | "clara"
  | "libra"
  | "nia"
  | "glowlight"
  | "glowlight_plus"
  | "iphone"
  | "ipad"
  | "galaxy_s21"
  | "galaxy_tab_7"
  | "print";

export const getPreviewerConfigByDevice = (
  deviceName: DeviceName
): IAppStore.PreviewerConfigs => {
  switch (deviceName) {
    case "paperwhite":
      return {
        l1ClassName: deviceName,
        l2ClassName: "inside",
        width: 440,
      };
    case "fire":
      return {
        l1ClassName: deviceName,
        l2ClassName: "inside",
        l3ClassName: "print-wrapper",
        width: 440,
      };
    case "oasis":
      return {
        l1ClassName: deviceName,
        l2ClassName: "inside",
        width: 440,
      };
    case "forma":
      return {
        l1ClassName: deviceName,
        l2ClassName: "inside",
        width: 440,
      };
    case "clara":
      return {
        l1ClassName: deviceName,
        l2ClassName: "inside",
        width: 440,
      };
    case "libra":
      return {
        l1ClassName: deviceName,
        l2ClassName: "inside",
        width: 440,
      };
    case "nia":
      return {
        l1ClassName: deviceName,
        l2ClassName: "inside",
        width: 440,
      };
    case "glowlight":
      return {
        l1ClassName: deviceName,
        l2ClassName: "inside",
        width: 440,
      };
    case "glowlight_plus":
      return {
        l1ClassName: deviceName,
        l2ClassName: "inside",
        width: 440,
      };
    case "iphone":
      return {
        l1ClassName: deviceName,
        l2ClassName: "inside",
        l3ClassName: "inter",
        width: 380,
      };
    case "ipad":
      return {
        l1ClassName: deviceName,
        l2ClassName: "inside",
        width: 440,
      };
    case "galaxy_s21":
      return {
        l1ClassName: deviceName,
        l2ClassName: "inside",
        width: 360,
      };
    case "galaxy_tab_7":
      return {
        l1ClassName: deviceName,
        l2ClassName: "inside",
        l3ClassName: "print-wrapper",
        width: 440,
      };
    case "print":
      return {};
  }
};
