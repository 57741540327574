import React, { useState } from "react";
import { Row, Col, Tooltip } from "antd";
import { Button, ButtonType } from "../Shared/Buttons/Button";
import { InfoIcon } from "../../content/icons";
import { BookGoal } from "./BookGoal/BookGoal";
import WritingHabitContainer from "./WritingHabit/WritingHabitContainer";

const Goals: React.FC = () => {
  const [activeTab, setActiveTab] = useState("bookGoal");

  const tabItems = [
    {
      key: "bookGoal",
      label: "Book goal",
      tooltipText:
        "This will help you set goals for the current book or writing project and help you meet your target due date.",
    },
    {
      key: "writingHabit",
      label: "Writing habit",
      tooltipText: "This will help you set a goal of how many words per day you'd like to be writing and let you know if you're on a habit streak.",
    },
  ];

  return (
    <div>
      <div>
        <Row className="settings-tabs" align="middle" justify="space-around">
          {tabItems.map((item, index) => (
            <Col
              span={12}
              key={index}
              className={`settings-tab-item ${
                item.key === activeTab ? "active" : ""
              }`}
            >
              <Button
                className="setting-tab-btn"
                type={ButtonType.GHOST}
                onClick={() => setActiveTab(item.key)}
              >
                {item.label}{" "}
                <Tooltip
                  placement="bottom"
                  title={<span>{item.tooltipText}</span>}
                >
                  <div className="info-icon-wrapper">
                    <InfoIcon />
                  </div>
                </Tooltip>
              </Button>
            </Col>
          ))}
        </Row>

        <Row>
          <div className="setting-area-form">
            {activeTab === "bookGoal" ? (
              <BookGoal />
            ) : (
              <WritingHabitContainer />
            )}
          </div>
        </Row>
      </div>
    </div>
  );
};

export default Goals;
