import { parseTextNode } from ".";

export const parseLinkNode = (linkNode: ISlateStore.SlateLink, isExport?: boolean): string => {
  let parsedLink = "";
  if (linkNode.url?.startsWith("#chapter")) {
    const chapterId = linkNode.url.split("=")[1];
    const linkUrl = isExport? `${chapterId}.xhtml` : linkNode.url;
    parsedLink += `<a href="${linkUrl}">`;
  } else {
    parsedLink += `<a target='_blank' href="${linkNode.url}">`;
  }
  for (const linkChild of linkNode.children) {
    parsedLink += parseTextNode(linkChild);
  }
  parsedLink += "</a>";

  return parsedLink;
};
