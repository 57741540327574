import {
  TReactEditor,
  removeNodes,
  TPath,
} from "@udecode/plate-core";

export const removeProfileFromEditor = (
  path: TPath | undefined,
  editor: TReactEditor
): void => {
  removeNodes(editor, { at: path });
};
