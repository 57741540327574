import React from "react";
import { Input, InputProps } from "antd";

interface AtticusPasswordFieldProps extends Omit<InputProps, "type"> {
  type?: "primary" | "secondary";
}

export const AtticusPasswordField: React.FC<AtticusPasswordFieldProps> = (props) => {
  return (
    <Input.Password
      {
        ...{
          ...props,
          type: "password",
          className: `at-form-password ${props.type}`
        }
      }
    />
  );
};
