import React, { useEffect, useState } from "react";
import { Form, Divider, Row, Col, notification } from "antd";
import { CalloutBoxProperties } from "./CalloutBoxProperties";
import { observer } from "mobx-react";
import { CalloutModalStore } from "../../../../../store/CalloutModal";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "./styles.scss";
import ListAllCalloutPresets from "./ListAllCalloutPresets";
import useRootStore from "../../../../../store/useRootStore";
import PreviewTile from "./PreviewTile";
import { PRESET_TYPE } from "./constant";
import { Button } from "../../../../Shared/Buttons";
import { Modal } from "../../../../Shared/Modal";
import { AtticusTextField } from "../../../../Shared/Form";
export interface CalloutBoxModalProps {
  calloutStore: CalloutModalStore;
  onClose: () => void;
  onSubmit: () => void;
}

export const CalloutBoxModal: React.FC<CalloutBoxModalProps> = observer(
  ({ calloutStore, onClose, onSubmit }) => {
    const { createPreset } = useRootStore().presetStore;
    const [presetName, setPresetName] = useState("");
    const [isPresetNameEmpty, setIsPresetNameEmpty] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const handleSavePreset = async () => {
      if (presetName === "") {
        setIsPresetNameEmpty(true);
        return;
      }

      const newPreset: IPresetStore.IPresetBase = {
        type: PRESET_TYPE,
        properties: {
          presetName: presetName,
          background: calloutStore.background,
          border: calloutStore.border,
        },
      };

      try {
        setIsSaving(true);
        const result = await createPreset(newPreset);
        if (result) {
          notification.success({
            message: "Preset Saved Successfully",
            description: result,
          });
        } else {
          notification.error({
            message: "Something went wrong when saving preset",
            description: result,
          });
        }
        setPresetName("");
      } catch (error) {
        notification.error({
          message: "Error Saving Preset",
        });
      } finally {
        setIsSaving(false);
      }
    };

    useEffect(() => {
      if (presetName !== "") {
        setIsPresetNameEmpty(false);
      }
    }, [presetName]);

    return (
      <Modal
        className='calloutbox-modal'
        open={true}
        onCancel={() => {
          onClose();
        }}
        cancelText='Cancel'
        title='Callout box configuration'
      >
        <Form>
          <ListAllCalloutPresets calloutStore={calloutStore} />
          <Divider />
          <CalloutBoxProperties calloutStore={calloutStore} />
          <Divider />
          <PreviewTile calloutStore={calloutStore} />
          <Row>
            <Col span={14}>
              <Form.Item>
                <div className="preset-text-div">
                <AtticusTextField
                  value={presetName}
                  className="preset-txt-field"
                  status={isPresetNameEmpty ? "error" : ""}
                  placeholder={
                    isPresetNameEmpty
                      ? "Please enter preset name"
                      : "Enter preset name"
                  }
                  onChange={(e) => setPresetName(e.target.value)}
                />
                </div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <div className='btn-div'>
                {isSaving ? (
                  <Button
                    type='at-secondary'
                    backgroundColor='green'
                    icon={<LoadingOutlined />}
                  />
                ) : (
                  <Button
                    key='save-preset'
                    type='at-secondary'
                    backgroundColor='green'
                    icon={<PlusOutlined />}
                    onClick={handleSavePreset}
                    ghost={true}
                  >
                    Save as preset
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <div>
            <Button
              type='at-primary'
              className="apply-btn"
              backgroundColor='green'
              onMouseDown={onSubmit}
              block
            >
              Apply properties
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
);
