import { SpecialTemplate } from "./";

export const Dedication: SpecialTemplate = {
  title: "Dedication",
  children: [
    {
      type: "align_center",
      children: [
        {
          type: "p",
          children: [
            {
              text: "Not all books include dedications, but many authors choose to share a special thank you to someone(s) who have made an impact on the writing of the book. This may be on a personal or professional level and can range from heartfelt to comedic and anything in between.",
            },
          ],
        },
        {
          type: "p",
          children: [
            {
              text: "Dedication text is traditionally center aligned.",
            },
          ],
        },
      ],
    },
    {
      type: "p",
      children: [
        {
          text: "",
        },
      ],
    },
  ],
  configuration: {
    showChapterImage: false,
    showPageNumber: false,
    showChapterHeading: false,
    showHeaderFooter: true,
    showInTableOfContents: true,
    showFirstSentenceFormatting: false,
  },
};
