import React from "react";
import { Row, Typography, Tag, Col, Tooltip } from "antd";

interface FontItem {
  _id: string;
  family: string;
  category?: string;
  source: string;
  variants?: string[];
  subsets?: string[];
  files?: any;
  menuFontFile?: string;
  userId?: string;
  version?: string;
  createdAt: Date;
  lastUpdateAt: Date;
}

const { Text } = Typography;

interface FontItemProps {
  font: IFontStore.FontItem
  actionItem: React.ReactNode
}

export const FontItem = ({ font, actionItem }: FontItemProps) => {
  return (
    <div className="font-card-outer">
      <Row align="middle" justify="space-between" className="font-card">
        <Col flex={"auto"} className="font-card-content">
          {font.category && (
            <Text className="font-card-category" type="secondary">{font.category}</Text>
          )}
          <Tooltip title={font.family} placement="topLeft">
            <h3
              className={
                "font-card-title " + (font.source === "google" && font.menuFontFile ? `${font._id}Menu` : `${font._id}Regular`)
              }
            >
              {font.family}
            </h3>
          </Tooltip>
          <Row className="font-card-variant">
            <Tooltip title={font.variants ? font.variants.join(", ") : "Font variants unavailable"} placement="topLeft">
              {font.variants && font.variants.map(variant => (
                <Tag className="font-variant-tag" style={{ border: "none" }} key={variant}>{variant}</Tag>
              ))}
            </Tooltip>
          </Row>
        </Col>
        <Col className="font-card-action-item" flex={"40px"}>
          {actionItem}
        </Col>
      </Row>
    </div>
  );
};