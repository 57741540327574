import React from "react";
import { Row, Checkbox } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { AtticusSelect } from "../../Shared/Form/Select";

interface FontFilterProps {
  updateCategories: (categories: string[]) => void;
}

const categoryOptions = [
  { label: "Sans-Serif", value: "sans-serif" },
  { label: "Serif", value: "serif" },
  { label: "Handwriting", value: "handwriting" },
  { label: "Display", value: "display" },
  { label: "Monospace", value: "monospace" },
];

export const FontFilter = ({ updateCategories }: FontFilterProps) => {
  const handleChange = (value: CheckboxValueType[]) => updateCategories(value as string[]);
  const content = (
    <>
      <Row className="font-filter-heading">
        <Checkbox.Group options={categoryOptions} defaultValue={[""]} onChange={handleChange} />
      </Row>
    </>
  );

  return (
    <div className="select-container">
      <AtticusSelect
        dropdownRender={() => {
          return <>{content}</>;
        }}
        placeholder="Filter by"
      />
    </div>
  );
};