import { PlateEditor, Value } from "@udecode/plate";
import { ELEMENT_CALLOUTBOX} from "./createCalloutBoxPlugin";

const DISABLED_TYPES = [ELEMENT_CALLOUTBOX];

export const allowFeatureForCalloutBoxes = (editor: PlateEditor<Value>): boolean => {
  const focusPoint = editor.selection?.focus;

  let enabled = true;

  if (typeof focusPoint !== "undefined") {
    enabled = !DISABLED_TYPES.includes(
      editor.children[focusPoint.path[0]].type
    );
  }

  return enabled;
};