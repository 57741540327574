import { SpecialTemplate } from "./";

export const Epilogue: SpecialTemplate = {
  title: "Epilogue",
  children: [
    {
      type: "p",
      children: [
        {
          text: "An epilogue is very similar to a prologue, but it occurs at the end of your story, though usually separate from the main plot. It might offer a glimpse of the future to share a sense of closure with your readers, or entice them to read the next in a series or collection.",
        },
      ],
    },
    {
      type: "p",
      children: [
        {
          text: "Similar to the prologue, the epilogue should be placed in the main body content of your book and is therefore not technically back matter.",
        },
      ],
    },
  ],
  configuration: {
    showChapterImage: true,
    showPageNumber: true,
    showChapterHeading: true,
    showHeaderFooter: true,
    showInTableOfContents: true,
    showFirstSentenceFormatting: true,
  },
};
