import React, { FunctionComponent } from "react";

const A = () => {
    return (

        <div className="pregenerated__theme--box--wrapper">
        <div className="placement__theme--box theme-layout">
            <div className="top__part--section theme__style--common" style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#EBEBEB",
                paddingLeft: 0,
                paddingTop: 0,
                paddingBottom: 0
            }}>
                <div style={{
                    height: "40%",
                    width: "100%",
                    backgroundColor: "#EBEBEB",
                }}></div>
                <div style={{
                    height: "60%",
                    width: "100%",
                    backgroundColor: "#FFFFFF",

                }}>
                    <h6>Chapter #1</h6>
                    <h4>Chapter Title</h4>
                    <span>Subtitle</span>
                </div>
            </div>
        </div>
        <div className="pregenerated__theme--box--hwrapper">
            <h4>Above Chapter #</h4>
        </div>
    </div>

    );
};

const B = () => {

    return (
        <div className="pregenerated__theme--box--wrapper">
                <div className="placement__theme--box theme-layout">
                    <h6 style={{ textAlign: "center" }}>Chapter #1</h6>
                    <div className="top__part--section theme__style--common" style={{
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#EBEBEB",
                        paddingLeft: 0,
                        paddingTop: 0,
                        paddingBottom: 0
                    }}>
                        <div style={{
                            height: "40%",
                            width: "100%",
                            backgroundColor: "#EBEBEB",
                        }}></div>
                        <div style={{
                            height: "60%",
                            width: "100%",
                            backgroundColor: "#FFFFFF",

                        }}>
                            <h4>Chapter Title</h4>
                            <span>Subtitle</span>
                        </div>
                    </div>
                </div>
                <div className="pregenerated__theme--box--hwrapper">
                    <h4>Above Chapter Title</h4>
                </div>
            </div>
    );
};

const C = () => {

    return (

        <div className="pregenerated__theme--box--wrapper">
                <div className="placement__theme--box theme-layout">
                    <h6 style={{ textAlign: "center" }}>Chapter #1</h6>
                    <h4 style={{ textAlign: "center" }}>Chapter Title</h4>
                    <div className="top__part--section theme__style--common" style={{
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#EBEBEB",
                        paddingLeft: 0,
                        paddingTop: 0,
                        paddingBottom: 0
                    }}>
                        <div style={{
                            height: "40%",
                            width: "100%",
                            backgroundColor: "#EBEBEB",
                        }}></div>
                        <div style={{
                            height: "60%",
                            width: "100%",
                            backgroundColor: "#FFFFFF",

                        }}>
                            <span>Subtitle</span>
                        </div>
                    </div>
                </div>
                <div className="pregenerated__theme--box--hwrapper">
                    <h4>Below Chapter Title</h4>
                </div>
            </div>

    );
};

const D = () => {
    return (

        <div className="pregenerated__theme--box--wrapper">
                <div className="placement__theme--box theme-layout">
                    <div className="top__part--section theme__style--common" style={{
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#EBEBEB",
                        paddingLeft: 0,
                        paddingTop: 0,
                        paddingBottom: 0
                    }}>
                        <div style={{
                            height: "60%",
                            width: "100%",
                            backgroundColor: "#FFFFFF",

                        }}>
                            <h6>Chapter #1</h6>
                            <h4>Chapter Title</h4>
                            <span>Subtitle</span>
                        </div>
                        <div style={{
                            height: "40%",
                            width: "100%",
                            backgroundColor: "#EBEBEB",
                        }}></div>
                    </div>
                </div>
                <div className="pregenerated__theme--box--hwrapper">
                    <h4>Below Subtitle</h4>
                </div>
            </div>

    );
};


const E = () => {
    return (
        <div className="pregenerated__theme--box--wrapper">
                <div className="placement__theme--box theme-layout">
                    <div className="top__part--section theme__style--common" style={{
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 0,
                        backgroundColor: "#EBEBEB",
                        paddingLeft: 0,
                        paddingTop: 0,
                        paddingBottom: 0
                    }}>
                        <div style={{
                            height: "60%",
                            width: "100%",
                        }}>
                            <h6>Chapter #1</h6>
                            <h4>Chapter Title</h4>
                            <span>Subtitle</span>
                        </div>
                    </div>
                </div>
                <div className="pregenerated__theme--box--hwrapper">
                    <h4>Background Image</h4>
                </div>
            </div>

    );
};

export default {
    A,
    B,
    C,
    D,
    E
};