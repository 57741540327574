import React from "react";
import { WordCounter, HabitStreaks } from "./";

const HabitView: React.FC = () => {
  return (
    <>
      <WordCounter />
      <HabitStreaks />
    </>
  );
};

export { HabitView };
