const getSocialMediaIcons = (smName: string) => {

    //small - 40px
    //medium - 40px
    //large - 50px
    switch(smName) {
        case "Facebook-Small" :
            return { id: 1, style: "styles.imageSmall", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/facebook.png" };
        case "Facebook-Medium" :
            return { id: 2, style: "styles.imageMedium", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/facebook.png" };
        case "Facebook-Large" :
            return { id: 3, style: "styles.imageLarge", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/facebook_lg.png" };
        case "Amazon-Small" :
            return { id: 4, style: "styles.imageSmall", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/amazon.png" };
        case "Amazon-Medium" :
            return { id: 5, style: "styles.imageMedium", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/amazon.png" };
        case "Amazon-Large" :
            return { id: 6, style: "styles.imageLarge", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/amazon_lg.png" };
        case "Instagram-Small" :
            return { id: 7, style: "styles.imageSmall", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/instagram.png" };
        case "Instagram-Medium" :
            return { id: 8, style: "styles.imageMedium", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/instagram.png" };
        case "Instagram-Large" :
            return { id: 9, style: "styles.imageLarge", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/instagram_lg.png" };
        case "Twitter-Small" :
            return { id: 10, style: "styles.imageSmall", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/twitter.png" };
        case "Twitter-Medium" :
            return { id: 11, style: "styles.imageMedium",smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/twitter.png" };
        case "Twitter-Large" :
            return { id: 12, style: "styles.imageLarge", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/twitter_lg.png" };
        case "LinkedIn-Small" :
            return { id: 13, style: "styles.imageSmall", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/linkedin.png" };
        case "LinkedIn-Medium" :
            return { id: 14, style: "styles.imageMedium", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/linkedin.png" };
        case "LinkedIn-Large" :
            return { id: 15, style: "styles.imageLarge", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/linkedin_lg.png" };
        case "Goodreads-Small" :
            return { id: 16, style: "styles.imageSmall", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/goodreads.png" };
        case "Goodreads-Medium" :
            return { id: 17, style: "styles.imageMedium", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/goodreads.png" };
        case "Goodreads-Large" :
            return { id: 18, style: "styles.imageLarge", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/goodreads_lg.png" };
        case "BookBub-Small" :
            return { id: 19, style: "styles.imageSmall", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/bookbub.png" };
        case "BookBub-Medium" :
            return { id: 20, style: "styles.imageMedium", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/bookbub.png" };
        case "BookBub-Large" :
            return { id: 21, style: "styles.imageLarge", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/bookbub_lg.png" };
        case "Patreon-Small" :
            return { id: 22,  style: "styles.imageSmall",smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/patreon.png" };
        case "Patreon-Medium" :
            return { id: 23, style: "styles.imageMedium", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/patreon.png" };
        case "Patreon-Large" :
            return { id: 24, style: "styles.imageLarge", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/patreon_lg.png" };
        case "Pinterest-Small" :
            return { id: 25, style: "styles.imageSmall",smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/pinterest.png" };
        case "Pinterest-Medium" :
            return { id: 26, style: "styles.imageMedium", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/pinterest.png" };
        case "Pinterest-Large" :
            return { id: 27, style: "styles.imageLarge", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/pinterest_lg.png" };
        case "Snapchat-Small" :
            return { id: 28, style: "styles.imageSmall", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/snapchat.png" };
        case "Snapchat-Medium" :
            return { id: 29,style: "styles.imageMedium",  smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/snapchat.png" };
        case "Snapchat-Large" :
            return { id: 30, style: "styles.imageLarge", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/snapchat_lg.png" };
        case "YouTube-Small" :
            return { id: 31, style: "styles.imageSmall", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/youtube.png" };
        case "YouTube-Medium" :
            return { id: 32, style: "styles.imageMedium", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/youtube.png" };
        case "YouTube-Large" :
            return { id: 33, style: "styles.imageLarge", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/youtube_lg.png" };
        case "TikTok-Small" :
            return { id: 34, style: "styles.imageSmall", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/tiktok.png" };
        case "TikTok-Medium" :
            return { id: 35, style: "styles.imageMedium", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/tiktok.png" };
        case "TikTok-Large" :
            return { id: 36, style: "styles.imageLarge", smLink: "https://atticus-content.s3.amazonaws.com/social-media-icons/tiktok_lg.png" };
        // default:
        //         return "";
    }
};

export { getSocialMediaIcons };