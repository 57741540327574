import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  FormInstance,
  Checkbox,
  Form,
  Row,
  Col,
  InputNumber,
  Radio,
  RadioChangeEvent,
} from "antd";

import { HANGING_INDENT_IN_MAX, HANGING_INDENT_IN_MIN, HANGING_INDENT_IN_STEP, HANGING_INDENT_MM_MAX, HANGING_INDENT_MM_MIN, HANGING_INDENT_MM_STEP, MARGIN_IN_MAX, MARGIN_IN_MIN, MARGIN_MM_MAX, MARGIN_MM_MIN, convertHangingIndentValue, convertMarginValue } from "../helpers";
import { Button } from "../../Shared/Buttons";

interface AdvancedSettingsProps {
  form: FormInstance<IThemeStore.ThemeFields>;
  onValuesChange: (fields: Partial<IThemeStore.ThemeFields>) => void;
}

const PrintLayoutSettings = ({
  form,
  onValuesChange,
}: AdvancedSettingsProps): JSX.Element => {

  const resetMargins = () => {
    if (form.getFieldValue("margin_unit") === "in") {
      onValuesChange({ margin_in: 0.875, margin_out: 0.5 });
    } else {
      onValuesChange({ margin_in: 22.3, margin_out: 12.7 });
    }
  };

  const resetHangingIndents = () => {
    if (form.getFieldValue("margin_unit") === "in") {
      onValuesChange({ hanging_indent: HANGING_INDENT_IN_MIN });
    } else {
      onValuesChange({ hanging_indent: HANGING_INDENT_MM_MIN });
    }
  };

  const [showInMM, setShowInMM] = useState<boolean>(form.getFieldValue("margin_unit") === "mm");

  const handleUnitChange = (e: RadioChangeEvent) => {
    const marginUnit = e.target.value;
    const convertedMarginInValue = convertMarginValue({
      to: marginUnit,
      value: form.getFieldValue("margin_in"),
    });

    const convertedMarginOutValue = convertMarginValue({
      to: marginUnit,
      value: form.getFieldValue("margin_out"),
    });

    const convertedHangingIndentValue = convertHangingIndentValue({
      to: marginUnit,
      value: form.getFieldValue("hanging_indent"),
    });

    setShowInMM(marginUnit === "mm");
    onValuesChange({
      margin_unit: marginUnit,
      margin_in: convertedMarginInValue,
      margin_out: convertedMarginOutValue,
      hanging_indent: convertedHangingIndentValue,
    });
  };


  return (
    <>
      <div className="theme-section-tab-content">
        <Row>
          <Col span={24}>
            <Form.Item name="margin_unit">
              <Radio.Group buttonStyle="solid" onChange={handleUnitChange}>
                <Radio value="in">inches</Radio>
                <Radio value="mm">mm</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="margins-form-wrapper">
              <h4 className="margins-heading">Margins</h4>
            </div>
            <Row gutter={32}>
              <Col>
                <div className="margin-input">
                  <span>Inside</span>
                  <Form.Item name="margin_in" className="margin-form-item">
                    <InputNumber
                      step={showInMM ? MARGIN_MM_MIN : MARGIN_IN_MIN}
                      max={showInMM ? MARGIN_MM_MAX : MARGIN_IN_MAX}
                      min={showInMM ? MARGIN_MM_MIN : MARGIN_IN_MIN}
                      formatter={(value) => `${value} ${showInMM ? "mm" : "in"}`}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col>
                <div className="margin-input">
                  <span>Outside</span>
                  <Form.Item name="margin_out" className="margin-form-item">
                    <InputNumber
                      step={showInMM ? MARGIN_MM_MIN : MARGIN_IN_MIN}
                      max={showInMM ? MARGIN_MM_MAX : MARGIN_IN_MAX}
                      min={showInMM ? MARGIN_MM_MIN : MARGIN_IN_MIN}
                      formatter={(value) => `${value} ${showInMM ? "mm" : "in"}`}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col>
                <Button size="small" onClick={resetMargins} type="at-ghost">
                  Reset margins
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <div className="margins-form-wrapper">
              <h4 className="margins-heading">Indents</h4>
            </div>
            <Row>
              <Col>
                <Form.Item name="hanging_indent">
                  <InputNumber
                    step={showInMM ? HANGING_INDENT_MM_STEP : HANGING_INDENT_IN_STEP}
                    max={showInMM ? HANGING_INDENT_MM_MAX : HANGING_INDENT_IN_MAX}
                    min={showInMM ? HANGING_INDENT_MM_MIN : HANGING_INDENT_IN_MIN}
                    formatter={(value) => `${value} ${showInMM ? "mm" : "in"}`}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button size="small" onClick={resetHangingIndents} type="at-ghost">
                  Reset
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item name="paraAlignment" label="Alignment">
              <Checkbox.Group>
                <Checkbox value="justify">Justified</Checkbox>
                <Checkbox value="hyphens">Hyphens</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="dynamicPageBreaks" label="Keep options">
              <Checkbox.Group>
                <Checkbox value="breakOrnamentalBreaks">
                  Ornamental breaks
                </Checkbox>
                <Checkbox value="breakSubheadings">Subheadings</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="layoutPriority"
              label="Layout priority"
            >
              <Radio.Group>
                <Radio value="WIDOWS_AND_ORPHANS">Widows and orphans</Radio>
                <Radio value="BALANCED_PAGE_SPREAD">Balanced page spread</Radio>
                <Radio value="BEST_OF_BOTH">Best of both</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row >
      </div>
    </>
  );
};

export default observer(PrintLayoutSettings);
