import { SpecialTemplate } from "./";

export const Afterword: SpecialTemplate = {
  title: "Afterword",
  children: [
    {
      type: "p",
      children: [
        {
          text: "The afterword is an opportunity for the author to offer any final explanations about the book, and is most common in non-fiction. Some books include this as part of the body content whereas others will place it in the back matter.",
        },
      ],
    },
  ],
  configuration: {
    showChapterImage: false,
    showPageNumber: true,
    showChapterHeading: true,
    showHeaderFooter: true,
    showInTableOfContents: true,
    showFirstSentenceFormatting: false,
  },
};
