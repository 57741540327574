import React from "react";
import moment from "moment";


interface CardContentProps {
  date: Date | string;
  children: any;
}

export const CardFooter: React.FC<CardContentProps> = ({ date, children }) => (
  <div className='book-card-action'>
    <p className='book-date'>
      Edited{" "}
      {date ? moment(date).format("MMM D, YYYY") : "N/A"}
    </p>
    {children}
  </div>
);
