import { getPluginType, PlateEditor, TText, Value, ELEMENT_LINK} from "@udecode/plate";
import { TLinkElement, LinkType } from "../../../config/typescript";

export interface CreateLinkNodeOptions {
  url: string;
  text?: string;
  children?: TText[];
  linkType?: LinkType;
}

export const createLinkNode = <V extends Value>(
  editor: PlateEditor<V>,
  { url, text = "", children, linkType }: CreateLinkNodeOptions
): TLinkElement => {
  const type = getPluginType(editor, ELEMENT_LINK);

  return {
    type,
    url,
    linkType,
    children: children ?? [{ text }],
  };
};
