import React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { Checkbox, Form, FormItemProps, RadioChangeEvent } from "antd";
import {
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
} from "@ant-design/icons";
import { ImageFlow } from "../../../config/typescript";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  AtticusRadioButtonProps,
  AtticusRadioGroup,
  AtticusCheckBox
} from "../../../../Shared/Form";

interface ImageFlowComponentProps {
  flowValue?: ImageFlow;
  wrapValue?: boolean;
  disableWrap?: boolean;
  separatePageValue?: boolean;
  flowChange?: (flow?: ImageFlow, wrap?: boolean) => void;
  separatePageChange: (separateValue?: boolean | undefined) => void;
}

const formItemProperties: FormItemProps = {
  colon: false,
  labelCol: { span: 24 },
};

export const ImageFlowComponent: FunctionComponent<ImageFlowComponentProps> = ({
  flowValue,
  wrapValue,
  disableWrap,
  separatePageValue,
  flowChange,
  separatePageChange,
}) => {
  const [flow, setFlow] = useState<ImageFlow>();
  const [wrap, setWrap] = useState<boolean>();

  const handleFlowChange = (event: RadioChangeEvent) => {
    const milddleSelected = (event.target.value as ImageFlow) === "middle";
    if (milddleSelected) setWrap(false);
    setFlow(event.target.value);
    if (flowChange)
      flowChange(event.target.value, milddleSelected ? false : wrap);
  };

  const handleSeparatePageChange = (event: CheckboxChangeEvent) => {
    if (event.target.checked !== undefined)
      separatePageChange(event.target.checked);
  };

  const handleWrapCheckboxChange = (event: CheckboxChangeEvent) => {
    setWrap(event.target.checked);
    if (flowChange) flowChange(flow, event.target.checked);
  };

  useEffect(() => {
    if (flowValue) setFlow(flowValue);
    if (wrapValue) setWrap(disableWrap ? false : wrapValue);
  }, [flowValue, wrapValue, disableWrap]);

  const radioButtons: AtticusRadioButtonProps[] = [
    {
      children: <AlignLeftOutlined />,
      value: "left",
      className: "image-flow-button image-flow-left-align-button",
    },
    {
      children: <AlignCenterOutlined />,
      value: "middle",
      className: "image-flow-button image-flow-center-align-button",
    },
    {
      children: <AlignRightOutlined />,
      value: "right",
      className: "image-flow-button image-flow-right-align-button",
    },
  ];

  return (
    <Form.Item
      label={<span className="edit-image-form-label">Alignment</span>}
      {...formItemProperties}
    >
      <div className="flow-control-container">
        <div className="flow-radio-button-container">
          <AtticusRadioGroup
            buttons={radioButtons}
            value={flow}
            onChange={handleFlowChange}
            optionType="button"
            buttonStyle="solid"
            color="grey"
          />
        </div>
        <div className="wrap-check-box-container">
          <AtticusCheckBox
            label="Wrap Text"
            checked={wrap}
            onChange={handleWrapCheckboxChange}
            disabled={flow === "middle" || disableWrap}
            type="secondary"
          />
          <AtticusCheckBox
            label="Separate Page"
            checked={separatePageValue}
            onChange={handleSeparatePageChange}
            type="secondary"
          />
        </div>
      </div>
    </Form.Item>
  );
};
