import { toWords } from "number-to-words";
import { addPrefix } from "@surge-global-engineering/css-generator";

const getHeaderTitleHtml = (title: string, classPrefix?: string) => {
  return `<div class="${addPrefix(
    "chapter-title",
    classPrefix
  )}"><h2>${title}</h2></div>`;
};

const getHeaderSubtitleHtml = (subTitle: string, classPrefix?: string) => {
  return `<div class="${addPrefix(
    "chapter-subtitle",
    classPrefix
  )}"><h3>${subTitle}</h3></div>`;
};

const getHeaderNumberHtml = (
  type: IThemeStore.ChapterNumbering,
  number?: number,
  classPrefix?: string
) => {
  let html = "";
  if (number) {
    switch (type) {
      case "number":
        html = `<span>${number}</span>`;
        break;
      case "word":
        html = `<span>${toWords(number)}</span>`;
        break;
      case "chapter+number":
        html = `<span>Chapter ${number}</span>`;
        break;
      case "chapter+word":
        html = `<span>Chapter ${toWords(number)}</span>`;
    }
  }
  return `<div class="${addPrefix(
    "chapter-number",
    classPrefix
  )}">${html}</div>`;
};

const getImageElementHtml = (imageUrl: string, classPrefix?: string) => {
  return `<div class="${addPrefix(
    "chp_img",
    classPrefix
  )} ${addPrefix(
    "chp_clr_all",
    classPrefix
  )}"><img src="${imageUrl}" /></div>`;
};

const getBackgroundImageHtml = (imageUrl: string, classPrefix?: string) => {
  return `<div class="${addPrefix(
    "chp_bg",
    classPrefix
  )} ${addPrefix(
    "chp_clr_all",
    classPrefix
  )}" style="background-image: url(${imageUrl}); background-size: cover; background-repeat: no-repeat;"></div>`;
};

export const parseChapterHeader = (
  props: IChapterStore.ChapterHeaderProps,
  isExport?: boolean,
): string => {
  const { styleProps, headerElements, classPrefix } = props;
  const { titleCard, chapterNumbering, image: headerImage } = styleProps;

  // this array will hold the elements in the order they should be displayed
  const titleCardItems : string[] = [];

  let html = "";
  const backgroundImageStyling =
  headerImage.placement === "background-image" && isExport && headerElements.image
    ? ` chp_bg chp_clr_all" style="background-image: url(${headerElements.image}); background-size: cover; background-repeat: no-repeat; opacity: 1 !important`
    :"";
  html += `<div class="${addPrefix(props.isInverted ? "chapter-title-card inverted" : "chapter-title-card",classPrefix)} ${backgroundImageStyling}">`;

  if (headerImage?.placement === "above-chapter-no" && headerElements.image)
    titleCardItems.push(getImageElementHtml(headerElements.image, classPrefix));

  if (titleCard.chapterNumber && headerElements.number)
    titleCardItems.push(getHeaderNumberHtml(chapterNumbering, headerElements.number, classPrefix));
  
  if (headerImage?.placement === "above-chapter-title" && headerElements.image)
    titleCardItems.push(getImageElementHtml(headerElements.image, classPrefix));

  if (titleCard.title && headerElements.title)
    titleCardItems.push(getHeaderTitleHtml(headerElements.title, classPrefix));
  
  if (headerImage?.placement === "below-chapter-title" && headerElements.image)
    titleCardItems.push(getImageElementHtml(headerElements.image, classPrefix));

  if (titleCard.subtitle && headerElements.subtitle)
    titleCardItems.push(getHeaderSubtitleHtml(headerElements.subtitle, classPrefix));

  if (headerImage?.placement === "below-subtitle" && headerElements.image)
    titleCardItems.push(getImageElementHtml(headerElements.image, classPrefix));

  if (titleCard.image && headerElements.image && headerImage?.placement === "background-image" && !isExport) {
    titleCardItems.push(getBackgroundImageHtml(headerElements.image, classPrefix));
  }

  titleCardItems.forEach((item) => {
    html += item;
  });

  return (html += "</div>");
};
