interface WidthConfig {
  referenceFontSizes: [number, number];
  referenceWidths: [number, number];
}

const DEFAULT_WIDTH_CONFIG: WidthConfig = {
  referenceFontSizes: [10, 18],
  referenceWidths: [30, 40],
};

const widthConfigs: Record<string, WidthConfig> = {
  ["Benne"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [25, 40],
  },
  ["Cardo"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [30, 55],
  },
  ["CrimsonPro"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [30, 45],
  },
  ["EBGaramond"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [30, 40],
  },
  ["OpenSansLT"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [30, 45],
  },
  ["LibreBaskerville"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [30, 48],
  },
  ["LibreCaslon"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [30, 52],
  },
  ["Lora"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [30, 45],
  },
  ["OpenSans"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [30, 50],
  },
  ["OpenDyslexic"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [30, 50],
  },
  ["PTSans"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [30, 45],
  },
  ["Rosario"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [25, 40],
  },
  ["SourceSansPro"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [25, 40],
  },
  ["Spectral"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [25, 40],
  },
  ["TheanoDidot"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [25, 45],
  },
  ["YoungSerif"]: {
    referenceFontSizes: [10, 18],
    referenceWidths: [30, 50],
  },
};

/**
 * Computes the page number container width that should be allocated in the header using a linear
 * interpolation of a set of tuned configurations
 */
export const computeHeaderPageNumberContainerWidth = (
  fontSize: number,
  fontFamily?: string
): number => {
  const { referenceFontSizes: x, referenceWidths: y } =
    widthConfigs[fontFamily || ""] || DEFAULT_WIDTH_CONFIG;

  if (!widthConfigs[fontFamily || ""]) {
    console.warn(
      `Header tuning configurations are missing for ${fontFamily}. Please configure in chapter-header-tuting-configs`
    );
  }

  const gradient = (y[1] - y[0]) / (x[1] - x[0]);
  const intercept = y[0] - gradient * x[0];

  return gradient * fontSize + intercept;
};
