import React from "react";
import { ModalFuncProps } from "antd";

export const epubErrorModalContent: ModalFuncProps = {
  icon: null,
  title: <h2 className="section-heading">Export Failed</h2>,
  content: (
    <div>
      <p style={{ textAlign: "justify" }}>
        Sorry about that! Your book wasn&apos;t able to be exported due to an
        error in the document. Use the link below to learn more about why this
        might happen and what you can do to resolve the error.
      </p>
      <a
        target="_blank"
        href="https://www.atticus.io/troubleshooting-export-errors/"
        rel="noreferrer"
      >
        https://www.atticus.io/troubleshooting-export-errors/
      </a>
    </div>
  ),
  centered: true,
  okText: "Ok",
  okButtonProps: {
    type: "primary",
    danger: true,
    style: {
      flex: 1,
    },
  },
  cancelText: false,
  cancelButtonProps: {
    className: "btn-a",
    style: {
      display: "none",
    },
  },
};
