import React, { FunctionComponent, useState, useEffect } from "react";
import { Progress } from "antd";

import AtticusAnimation_v2 from "../../../content/images/AtticusAnimation_v2.gif";

interface AtticusLoaderProps {
    loading: boolean;
}
  
export const AtticusLoader: FunctionComponent<AtticusLoaderProps> = ({ loading }: AtticusLoaderProps) => {
    let timer: any = null;
	const [countOfProgess, setCountOfProgess] = useState(0);
	const [ localLoading, setLocalLoading ] = useState(loading);
  
	useEffect(() => {
		timer = setInterval(() => {
        if (loading && countOfProgess > 100) setLocalLoading(true);
			setCountOfProgess(oldProgress => Math.min(oldProgress + 1, 102));
		}, 25);
		return () => clearInterval(timer);
	}, []);

	if (countOfProgess > 100) clearInterval(timer);

    return (
        countOfProgess < 100 && localLoading ?
		<div className="loading-animation-container">
			<img className="loading-animation" src={AtticusAnimation_v2} />
			<div style={{ width: 170 }}>
				<Progress status="active" strokeLinecap="square" percent={countOfProgess} showInfo={false} />
			</div>
			<p className="loading-animation-text">{`${countOfProgess}%`}</p>
			<p className="loading-animation-text">{"Fetch...Atticus...Fetch"}</p>
		</div> :
		(countOfProgess == 100) ?
		<>
			<div className="loading-animation-container">
				<img className="loading-animation" src={AtticusAnimation_v2} />
				<div style={{ width: 170 }}>
					<Progress status="active" strokeLinecap="square" percent={countOfProgess} showInfo={false} />
				</div>
				<p className="loading-animation-text">{`${countOfProgess}%`}</p>
				<p className="loading-animation-text">{"Good Boy!"}</p>
			</div>
		</> : null
    );
};