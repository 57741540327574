import { SpecialTemplate } from "./";

export const AlsoBy: SpecialTemplate = {
  title: "Also by",
  children: [
    {
      type: "align_center",
      children: [
        {
          type: "p",
          children: [
            {
              text: "Some books include the Also By information on the same page as the author bio, but many have a separate page dedicated to sharing more works by the same author or publishing house that the reader might be interested. This page can be a very powerful marketing tool. \n\nThe text on this page is typically center aligned.",
            },
          ],
        },
      ],
    },
    {
      type: "p",
      children: [
        {
          text: "",
        },
      ],
    },
  ],
  configuration: {
    showChapterImage: false,
    showPageNumber: true,
    showChapterHeading: true,
    showHeaderFooter: true,
    showInTableOfContents: true,
    showFirstSentenceFormatting: false,
  },
};
