import React from "react";

interface ContainerProps {
  maxWidth?: number
}

export const Container: React.FC<ContainerProps> = ({ children, maxWidth }) => {
  return (
    <div
      className="at-container"
      style={
        maxWidth ? {
          maxWidth: `${maxWidth}px`
        } : undefined}
      >
      {children}
    </div>
  );
};