import React, { useState } from "react";
import {
  Radio,
  Checkbox,
  Form,
  Row,
  Col,
  Tooltip,
  Select,
  Badge,
  FormInstance,
  RadioChangeEvent,
} from "antd";


import { trimSizes } from "../../../utils/print-configs";
import { observer } from "mobx-react-lite";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { fonts, FontVariant, getFontFamilyName } from "@surge-global-engineering/css-generator";
import { AtticusSelect } from "../../Shared/Form";

const ingramSparkImgURL = "/images/ingram-spark-logo@3x.png";
const kdpImgURL = "/images/kdp-logo@3x.png";

interface TrimSizeSettingsProps {
  form: FormInstance<IThemeStore.ThemeFields>;
  formFields: IThemeStore.ThemeFields;
  onValuesChange: (fields: Partial<IThemeStore.ThemeFields>) => void;
}

const { Option } = Select;

const paperStyles = {
  ingramSpark: {
    height: 25,
    width: 100,
  },
  kdp: {
    height: 35,
    width: 100,
  },
};

const TrimSizeSettings = ({ form, onValuesChange }: TrimSizeSettingsProps) => {
  const [showInMM, setShowInMM] = useState<boolean>(form.getFieldValue("trim_unit") === "mm");

  const SupportKdp = () => {
    return (
      <div  className="trim-sizes-kdp-icon"></div>
    );
  };

  const SupportIngram = () => {
    return (
      <div className="trim-sizes-ingram-icon"></div>
    );
  };

  const handleTrimUnit = (e: RadioChangeEvent) => {
    const trimUnit = e.target.value;
    setShowInMM(e.target.value === "mm");
    onValuesChange({
      trim_unit: trimUnit,
    });
  };

  

  return (
    <div className="theme-section-tab-content">
      <br></br>
      <Row gutter={24}>
        <Col span={8}>
          <h4>Paper trim size</h4>
          <Form.Item name="trim_unit">
            <Radio.Group onChange={handleTrimUnit}>
              <Radio value="in">inches</Radio>
              <Radio value="mm">mm</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      {/* <h4>Paper Trim Size (in)</h4> */}
      {trimSizes.map((cat, i) => (
        <Row key={i}>
          <Form.Item
            name="trimsize"
            label={cat.category}
            className="a-radio-btns at-form-item"
          >
            <Radio.Group buttonStyle="solid" className="trim-sizes-btn-group">
              {cat.sizes.map((size, j) => (
                <Radio.Button
                  className="trim-sizes-rd-btn"
                  key={j}
                  value={
                    cat.category === "Large print options"
                      ? `${size.width * 2.54}*${size.height * 2.54}`
                      : `${size.width * 2.54}x${size.height * 2.54}`
                  }
                >
                    <Row
                      className="trim-size-content-wrapper"
                      align="middle"
                    >
                      {showInMM
                        ? `${Math.round(size.width * 25.4)} x ${Math.round(
                            size.height * 25.4
                          )}`
                        : `${size.width} x ${size.height}`}
                      {size.kdp === true ? <SupportKdp /> : null}
                      {size.ingramSpark === true ? <SupportIngram /> : null}
                    </Row>
                  
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </Row>
      ))}

      <Row align="middle" gutter={20}>
        <Col>
          <div className="trim-sp-lbl">
            <SupportKdp />
            Supported by KDP
          </div>
        </Col>
        <Col>
          <div className="trim-sp-lbl">
            <SupportIngram /> 
            <div>Supported by INGRAM SPARK</div>
          </div>
        </Col>
        <Col>
          <div className="auth-logo__container">
            <img style={paperStyles.kdp} src={kdpImgURL} />
          </div>
        </Col>
        <Col>
          <div className="auth-logo__container">
            <img style={paperStyles.ingramSpark} src={ingramSparkImgURL} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default observer(TrimSizeSettings);
