import React, { FunctionComponent, useMemo, useState, useEffect } from "react";
import { Form, Row, Col, List, Badge } from "antd";
import Fuse from "fuse.js";
import { toJS } from "mobx";
import { debounce } from "lodash";

import useRootStore from "../../../store/useRootStore";
import { AtticusSearchField } from "../../Shared/Form";

interface NewBoxSetBookSelectionProps {
  selectedBookIds: Record<string, number>;
  onBookSelectionChange: (selectedBookIds: Array<string>) => void;
  resetField?: boolean;
}

export const BoxsetBookSelection: FunctionComponent<NewBoxSetBookSelectionProps> = ({ onBookSelectionChange, selectedBookIds, resetField }) => {
  const { books } = useRootStore().shelfStore;
  // <key, value> = <bookId, order>

  const [filteredBooks, setFilteredBooks] = useState(books);

  const fuse = useMemo(() => new Fuse(toJS(books), { keys: ["title"], useExtendedSearch: true, includeScore: true, threshold:0.0}), [books]);

  useEffect(() => {
    if (resetField) {
      onBookSelectionChange([]);
    }
  }, [resetField]);

  const filterBooks = debounce((searchTerm: string) => {
    if (!searchTerm.trim()) {
      setFilteredBooks(books);
      return;
    }

    const results = fuse.search(searchTerm);
    setFilteredBooks(results.map((result) => result.item));
  }, 200);

  const handleBookClick = (bookId: string) => {
    // sort the books
    const prioritizedBookIds = Object.keys(selectedBookIds)
      .map((bookId) => ({ bookId, priority: selectedBookIds[bookId] }))
      .sort((a, b) => (a < b ? -1 : 1))
      .map(({ bookId }) => bookId);
    const nextSelectedBookIds: typeof selectedBookIds = {};

    let removed = false;
    let index = 1;
    for (const currentBookId of prioritizedBookIds) {
      // remove the book if it was previously selected
      if (currentBookId === bookId) {
        removed = true;
        continue;
      }

      nextSelectedBookIds[currentBookId] = index;
      index += 1;
    }

    // insert the book if it was not previously selected
    if (!removed) {
      nextSelectedBookIds[bookId] = index;
      prioritizedBookIds.push(bookId);
    } else {
      prioritizedBookIds.splice(prioritizedBookIds.indexOf(bookId), 1);
    }

    // notify the parent & update the internal state
    onBookSelectionChange(prioritizedBookIds);
  };

  return (
    <Form layout="vertical">
      <div className="boxset-book-selection inner-s">
        <Form.Item className="ant-input-extended" style={{marginBottom:0}}>
          <AtticusSearchField  placeholder="Search" onChange={(event) => filterBooks(event.target.value)} />
        </Form.Item>
        <div>
          <List className="boxset-book-list scroller" itemLayout="vertical">
            {filteredBooks.map(({ _id, title }) => {
              const selected = !!selectedBookIds[_id];
              const selectionOrder = selectedBookIds[_id];

              return (
                <List.Item key={_id} onClick={() => handleBookClick(_id)} style={{ ...(selected && { color: "#3568BA" }) }}>
                  <Row justify="space-between" className="boxset-book-item">
                    <Col>{title}</Col>
                    <Col>{selected && <Badge count={selectionOrder} />}</Col>
                  </Row>
                </List.Item>
              );
            })}
          </List>
        </div>
      </div>
    </Form>
  );
};