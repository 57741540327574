import { insertNodes } from "@udecode/plate";
import { Range } from "slate";
import { TEndNote, ELEMENT_END_NOTE } from "../types";
import { MyEditor } from "./../../../config/typescript";
import { PlateNodes } from "./../../../config/typescript";

export const insertEndnoteNode = (editor: MyEditor, selection: Range | null, note: PlateNodes | null): boolean => {
  if (Range.isRange(selection)) {
    console.log("note",note);
    insertNodes<TEndNote>(
      editor,
      {
        type: ELEMENT_END_NOTE,
        note,
        children: [{ text: "" }],
      },
      {
        at: Range.isExpanded(selection) ? selection.focus : selection,
      }
    );

    editor.selection = null;

    return true;
  }

  return false;
};
