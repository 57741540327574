import { createPluginFactory } from "@udecode/plate-core";
import { SubheadingComponent } from "./components/subheadingComponent";

export const ELEMENT_SUBHEADING = "h2";

export const createSubheadingPlugin = createPluginFactory({
  key: ELEMENT_SUBHEADING,
  isElement: true,
  component: SubheadingComponent,
  deserializeHtml: {
    rules: [
      { validNodeName: ["H2"] },
    ]
  }
});
