import { isEmpty, uniq } from "lodash";
import { ChapterErrorsObject, ChapterQuotesResultType, ChapterQuoteStyleRecord } from "./types";
import { GetBookFromDB } from "../../utils/offline.book.helpers";
import { Book } from "../../types/book";
import { Chapter } from "../../types/chapter";

const regularQuotes = /["']/;
const smartQuotes = /[“”″‘’′]/;

export const getAllTableRowsForErrors = async (
  bookId: string,
  results: ChapterQuoteStyleRecord | null
): Promise<ChapterErrorsObject[]> => {
  if (results === null) return [];

  const book = await GetBookFromDB(bookId, {
    chapterMeta: true,
    chapterBodies: true,
  });

  if (book === null || book === undefined) return [];

  const data: ChapterErrorsObject[] = [];

  const allChapters = allBookChapters(book);

  allChapters.forEach((chapter) => {
    data.push({
      _id: chapter._id,
      title: chapter.title,
      quotesType: results[chapter._id] ?? "N/A",
    });
  });

  return data;
};

export const allBookChapters = (book: Book): Chapter[] => {
  return [
    ...(book.frontMatter || []),
    ...(book.chapters || []),
  ];
};

export const analyzeQuoteConsistencyInChapter = (
  content: any,
  results: ChapterQuotesResultType[] = []
): ChapterQuotesResultType => {
  for (let i = 0; i < content.length; i++) {
    const { text = null, children = [] } = content[i];

    if (children.length > 0) {
      results.push(analyzeQuoteConsistencyInChapter(children, results));
    } else {
      if (isEmpty(text)) {
        continue;
      }

      const coercedText = text as string;

      const smartQuotesOnly = smartQuotes.test(coercedText);
      const regularQuotesOnly = regularQuotes.test(coercedText);

      if (smartQuotesOnly && regularQuotesOnly) {
        results.push("Mix");
        continue;
      }

      if (smartQuotesOnly === false && regularQuotesOnly === false) {
        results.push("N/A");
        continue;
      }

      results.push(smartQuotesOnly ? "Curly" : "Straight");
    }
  }

  const uniqueResults = uniq<ChapterQuotesResultType>(
    results.filter((result) => result !== "N/A")
  );

  if (uniqueResults.length === 0) return "N/A";

  if (uniqueResults.length === 1) return uniqueResults[0];

  return "Mix";
};

export const analyzeQuoteConsistencyInBook = async (
  bookId: string
): Promise<ChapterQuoteStyleRecord | null> => {
  const book = await GetBookFromDB(bookId, {
    chapterMeta: true,
    chapterBodies: true,
  });

  if (book === null || book === undefined) return null;

  const chapterConsistency: ChapterQuoteStyleRecord = {};

  const chapters = allBookChapters(book);

  chapters.forEach((chapter: Chapter) => {
    chapterConsistency[chapter._id] = analyzeQuoteConsistencyInChapter(
      chapter.children ?? []
    );
  });

  return chapterConsistency;
};

export const validateAnalyzedResults = (
  chapters: ChapterQuoteStyleRecord | null
): boolean | null => {
  if (chapters === null) return null;

  const chapterQuoteTypes = uniq(Object.values(chapters)).filter(
    (type) => type !== "N/A"
  );

  if (chapterQuoteTypes.length === 0) return true;

  if (chapterQuoteTypes.length === 1) {
    if (chapterQuoteTypes[0] !== "Mix") return true;
  }

  return false;
};


export const getInconsistencyCount = (
  chapters: ChapterQuoteStyleRecord | null
): number => {
  if (chapters === null) return 0;

  const numberOfMixChapters = Object.values(chapters).filter(
    (type) => type === "Mix"
  ).length;

  return numberOfMixChapters;
};
