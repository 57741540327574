import {
  getNode,
  getParentNode,
  match,
  PlateEditor,
  removeNodes,
  setNodes,
  TElement,
  TNodeEntry,
  unwrapNodes,
  wrapNodes,
} from "@udecode/plate";
import { Element, Node, BaseElement, BaseEditor, Path, Text } from "slate";

import { NodeType } from "../../types";
import { ELEMENT_TEXT_MESSAGE, ELEMENT_TEXT_MESSAGES_GROUP } from "../createTextMessagePlugin";
import { TextMessageNode } from "../types";
import { at } from "lodash";

export const withTextMessage = <T extends PlateEditor>(
  editor: T
): PlateEditor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]: TNodeEntry) => {
    if (!Element.isElement(node)) return;

    if (
      (node as (BaseElement | BaseEditor) & NodeType).type ===
        ELEMENT_TEXT_MESSAGE) {
        const parentNode = getParentNode(editor, path);
        if(parentNode && !match(parentNode[0], [], { type:ELEMENT_TEXT_MESSAGES_GROUP})) {
          if(!node.inserting) {
            removeNodes(editor, {
              at: path 
            });
          }
        }

        for (const [child, childPath] of Node.children(editor, path)) {
          if(!Text.isText(child)) {
            unwrapNodes(editor, {at: childPath});
          }
        }
    }

    if (
      (node as (BaseElement | BaseEditor) & NodeType).type ===
        ELEMENT_TEXT_MESSAGE &&
      (node as TextMessageNode).messageType === "contact"
    ) {
      const nextPath = Path.next(path);

      if (nextPath) {
        const nextNode = getNode<TElement>(editor, nextPath);

        if (nextNode?.type === ELEMENT_TEXT_MESSAGE) {
          setNodes(
            editor,
            { nextMessageType: (nextNode as TextMessageNode).messageType },
            { at: path }
          );
        }
      }
    }

    if (
      (node as (BaseElement | BaseEditor) & NodeType).type ===
      ELEMENT_TEXT_MESSAGE
    ) {
      if (!Path.hasPrevious(path)) return;
      const previousPath = Path.previous(path);
      if (previousPath) {
        const previousNode = getNode<TElement>(editor, previousPath);

        if (
          previousNode?.type === ELEMENT_TEXT_MESSAGE &&
          (previousNode as TextMessageNode).messageType === "contact"
        ) {
          setNodes(
            editor,
            { nextMessageType: (node as TextMessageNode).messageType },
            { at: previousPath }
          );
        }
      }
    }

    normalizeNode([node, path]);
  };

  return editor;
};
