import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Menu, Dropdown, Row, Col } from "antd";
import { Modal as AtticusModal } from "../Shared/Modal";

// store
import useRootStore from "../../store/useRootStore";

// types
import {
  ChapterMeta,
  ChapterType,
  IChapterTemplateBase,
} from "../../types/chapter";

// Defaults
import { sectionTypes, getLabel } from "./helpers";
import ImportChaptersModal from "../Books/ImportChaptersModal";
import ImportIntoVolumeModal from "../Books/ImportChaptersIntoVolumeCheckModal";
import { MyRootBlock } from "../Plate/config/typescript";
import { copyrightTemplates } from "../../utils/initials";
import { SectionType } from "../../types/sidebar";
import { SpecialTemplates } from "./SpecialTemplates";
import { Button } from "../Shared/Buttons";
import { PlusIcon, MoreIcon, DeleteIcon } from "../../content/icons";
import { ellipsizeTitle } from "../../utils/strings";

const { SubMenu } = Menu;

const ActionBar: FunctionComponent = observer(() => {
  const {
    addNewChapter,
    deleteChapter,
    addNewChapterFromTemplate,
    chapter,
    book,
    getCurrentStoredBook,
    getChapterBodyById,
    debouncedSaveChapterMetaUpdates,
    importChapters,
  } = useRootStore().bookStore;
  const { refreshCache } = useRootStore().pdfCacheStore;
  const { resetSelectedChapters } = useRootStore().sideMenuStore;
  const { activeTheme: theme } = useRootStore().themeStore;
  const { chapterTemplates, deleteChapterTemplate, loadTemplates } =
    useRootStore().chapterStore;

  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showImportChaptersModal, setShowImportChaptersModal] =
    React.useState(false);
  const [showImportChaptersCheckModal, setShowImportChaptersCheckModal] =
    React.useState(false);
  const [importChapterUrl, setImportChapterUrl] = React.useState("");
  const containVolumes =
    book.chapters.filter(({ type }) => type === "volume").length > 0;

  React.useEffect(() => {
    loadTemplates();
  }, []);
  
  const [selectedMasterPage, setSelectedMasterPage] = useState<IChapterTemplateBase>();

  const deleteTemplate = async (templateId) => {
    try {
      setLoading(true);
      await deleteChapterTemplate(templateId);
      loadTemplates();
    } catch (e: any) {
      console.log(e);
    }
    setLoading(false);
  };

  const refreshPDFCache = useCallback(
    async (type: ChapterType) => {
      const { frontMatterIds, chapterIds } = getCurrentStoredBook();
      const allChapterIds = [...frontMatterIds, ...chapterIds];
      const chapterData = await getChapterBodyById(allChapterIds);
      const chapterCacheData = chapterData.map(
        ({ _id, type, startOn }) =>
          ({
            chapterId: _id,
            chapterType: type,
            startOn,
          } as IPDFCacheStore.ChapterCacheMetaData)
      );
      if (type === "image") {
        refreshCache(book._id, "full-page-image-chapter-add", {
          "full-page-image-chapter-add": { chapters: chapterCacheData },
        });
      } else {
        refreshCache(book._id, "chapter-add", {
          "chapter-add": { chapters: chapterCacheData },
        });
      }
    },
    [book, getCurrentStoredBook, getChapterBodyById, refreshCache]
  );

  const handleAddChapterFromTemplate = useCallback(
    async (
      chapterTemplate: IChapterTemplateBase,
      type: IChapterStore.ChapterType,
      section?: string
    ) => {
      resetSelectedChapters();
      await addNewChapterFromTemplate(chapterTemplate, type, section);
      await refreshPDFCache(type);
    },
    [addNewChapterFromTemplate, refreshPDFCache]
  );

  /**
   * This code segment is to handle books with endnotes chapters without `includeIn` specification
   * Here we check the endnotes chapter against the theme config and remove the chapter if
   * no longer needed or update `includeIn` property as necessary
   *
   * This segment can be removed after migrating from the ghost endnotes chapters
   *
   * See more at: https://surgeglobal.atlassian.net/browse/AT-533
   */
  useEffect(() => {
    const checkExistingEndnotesChapter = async () => {
      const { frontMatter, chapters } = getCurrentStoredBook();
      const endnotesChapter = [...frontMatter, ...chapters].find(
        (chapter) => chapter.type === "endnotes"
      );

      const themeStyleProps = theme?.properties;

      if (endnotesChapter && !endnotesChapter.includeIn && theme) {
        let includeIn: "all" | "ebook" | "print" | "none" = "none";
        if (
          themeStyleProps?.ePubNotesMode === "END_OF_BOOK" &&
          themeStyleProps?.notesMode === "END_OF_BOOK"
        ) {
          includeIn = "all";
        } else if (themeStyleProps?.ePubNotesMode === "END_OF_BOOK") {
          includeIn = "ebook";
        } else if (themeStyleProps?.notesMode === "END_OF_BOOK") {
          includeIn = "print";
        }

        if (includeIn === "none") {
          await deleteChapter(endnotesChapter._id);

          // Update PDF cache
          const allChapterIds = [...book.frontMatterIds, ...book.chapterIds];
          const chapterData = await getChapterBodyById(allChapterIds);
          const chapterCacheData = chapterData.map(
            ({ _id, type, startOn, includeIn }) =>
              ({
                chapterId: _id,
                chapterType: type,
                startOn,
                includeIn,
              } as IPDFCacheStore.ChapterCacheMetaData)
          );
          refreshCache(book._id, "chapter-delete", {
            "chapter-delete": {
              chapterId: endnotesChapter._id,
              chapters: chapterCacheData,
            },
          });
        } else {
          await debouncedSaveChapterMetaUpdates({
            ...endnotesChapter,
            includeIn,
          });
          refreshCache(book._id, "chapter-properties-change", {
            "chapter-properties-change": {
              chapter: {
                chapterId: endnotesChapter._id,
                chapterType: endnotesChapter.type,
                startOn: endnotesChapter.startOn,
                includeIn: includeIn, // updated includeIn
              },
            },
          });
        }
      }
    };

    checkExistingEndnotesChapter();
  }, [theme]);

  const handleMenuClick = useCallback(
    async (
      section: SectionType,
      type: ChapterType,
      body?: MyRootBlock[],
      meta?: Partial<ChapterMeta>
    ) => {
      resetSelectedChapters();
      await addNewChapter(section, type, meta, body);
      await refreshPDFCache(type);
    },
    [book.chapterIds]
  );

  const handleImportChapters = async (
    fileURL: string,
    insertIntoVolume: boolean
  ) => {
    try {
      await importChapters(fileURL, insertIntoVolume);
      location.reload();
    } finally {
      setShowImportChaptersModal(false);
      setShowImportChaptersCheckModal(false);
    }
  };

  const showAddMasterPageOption =
    chapterTemplates &&
    Array.isArray(chapterTemplates) &&
    chapterTemplates.length > 0;

  const MasterPageMenu = () => (
    <div>
      <SubMenu
      key='ChapterTemplates'
      title='Master Pages'
      popupClassName="master-pages-menu-popup"
      className='action-bar-masterpage-menu'
    >
      {chapterTemplates.map((template, i) => (
        <div
          key={`chapter-template-${template._id}`}
          className='action-bar-masterpage-menu-item'
        >
          <div
            key={`template-${i}`}
            className='action-bar-masterpage-title-cover'
            onClick={() =>
              handleAddChapterFromTemplate(
                template,
                template.type,
                template.section
              )
            }
          >
            <p className='action-bar-masterpage-title'>
              {ellipsizeTitle(template.title)}
            </p>
          </div>
          <div
            key={`template-${template._id}`}
            className='action-bar-masterpage-menu-icon-cover'
            onClick={() => {
              setOpen(true);
              setSelectedMasterPage(template);
            }}
          >
            <span className='action-bar-masterpage-menu-icon'>
              <DeleteIcon />
            </span>
          </div>
        </div>
      ))}
    </SubMenu>
    <AtticusModal
        open={open}
        centered={true}
        title={<b>Delete master page</b>}
        onCancel={() => {
          setOpen(false);
        }}
        destroyOnClose={true}
        leftBtn={{
          type: "at-secondary",
          className: "link-modal-btn",
          backgroundColor: "green",
          onMouseDown: () => setOpen(false),
          children: "No",
        }}
        rightBtn={{
          type: "at-primary",
          danger:true,
          onMouseDown: () => {
            return new Promise((resolve, reject) => {
              deleteTemplate(selectedMasterPage?._id);
              setTimeout(loading ? resolve : reject, 5000);
              setOpen(false);
            }).catch(() => console.log("Delete"));
          },
          children: "Delete",
        }}
      >
        {" "}
        <p className="delete-modal-bottom-padding">
          Are you sure you want to delete the "{<b>{selectedMasterPage?.title}</b>}"?
        </p>
      </AtticusModal>
    </div>
  );

  return (
    <>
      <Row className="tube" gutter={8} justify="center">
        <Col flex={1}>
          <div className="action-bar-container">
            <Button
              type="at-primary"
              size="medium"
              onClick={() => handleMenuClick("body", "chapter")}
              fullWidth={true}
              icon={<PlusIcon />}
            >
              Add new chapter
            </Button>
            <Dropdown
              trigger={["click"]}
              overlay={
                <Menu>
                  <SubMenu
                    key="CopyrightsSubMenu"
                    title="Copyright templates"
                    level={1}
                  >
                    {copyrightTemplates.map((template, index) => (
                      <Menu.Item
                        key={`copyright-template-${index}`}
                        onClick={() => {
                          handleMenuClick(
                            "frontMatter",
                            "copyrights",
                            template.children
                          );
                        }}
                      >
                        {template.title}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                  <SubMenu
                    key="SpecialTemplatesSubmenu"
                    title="Preset layouts"
                    level={1}
                  >
                    {SpecialTemplates.map((template, index) => (
                      <Menu.Item
                        key={`special-template-${index}`}
                        onClick={() => {
                          handleMenuClick(
                            "body",
                            "chapter",
                            template.children,
                            {
                              title: template.title,
                              configuration: template.configuration,
                              numbered: false,
                            }
                          );
                        }}
                      >
                        {template.title}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                  {sectionTypes.frontMatter.map((elem, i) => (
                    <Menu.Item
                      key={`frontMatter-${i}`}
                      onClick={() => {
                        const parentChapter = book.chapters.find(
                          (chap) => chap._id == chapter.parentChapterId
                        );
                        // check if a parent chapter is a part or current chapter is a part set the sections as "body" (parts do not have frontMatter)
                        const section =
                          parentChapter?.type === "part" ||
                          chapter.type === "part"
                            ? "body"
                            : "frontMatter";
                        handleMenuClick(section, elem as ChapterType);
                      }}
                    >
                      {getLabel(elem)}
                    </Menu.Item>
                  ))}
                  {sectionTypes.body.map((elem, i) => (
                    <Menu.Item
                      key={`body-${i}`}
                      onClick={() => {
                        handleMenuClick("body", elem as ChapterType);
                      }}
                    >
                      {getLabel(elem)}
                    </Menu.Item>
                  ))}
                  {showAddMasterPageOption && MasterPageMenu()}
                  <Menu.Item
                    key={"import-chapters"}
                    onClick={() => setShowImportChaptersModal(true)}
                  >
                    Import chapters
                  </Menu.Item>
                </Menu>
              }
            >
              <div className="action-bar-more-btn-container">
                <Button
                  className="action-bar-more-btn"
                  type="at-primary"
                  size="medium"
                  icon={<MoreIcon color="#ffffff" />}
                />
              </div>
            </Dropdown>
          </div>
        </Col>
      </Row>

      {showImportChaptersModal ? (
        <ImportChaptersModal
          onUpload={async (params: IShelfStore.ChapterImportForm) => {
            setImportChapterUrl(params.fileURL);
            setShowImportChaptersModal(false);
            setShowImportChaptersCheckModal(true);
          }}
          onClose={() => {
            setShowImportChaptersModal(false);
          }}
          bookId={book._id}
        />
      ) : null}
      {showImportChaptersCheckModal ? (
        <ImportIntoVolumeModal
          onUpload={async (params: IShelfStore.ChapterImportForm) =>
            handleImportChapters(importChapterUrl, params.insertIntoVolume)
          }
          onClose={() => setShowImportChaptersCheckModal(false)}
          bookId={book._id}
        />
      ) : null}
    </>
  );
});

export default ActionBar;
