import { SpecialTemplate } from "./";

export const Acknowledgements: SpecialTemplate = {
  title: "Acknowledgements",
  children: [
    {
      type: "p",
      children: [
        {
          text: "The acknowledgements page is a place to say thank you to anyone or anything who has helped make the book possible. Being a part of the back matter, authors will often use acknowledgements to recognize a number of sources, or offer more detail about why the source is being recognized.",
        },
      ],
    },
  ],
  configuration: {
    showChapterImage: false,
    showPageNumber: true,
    showChapterHeading: true,
    showHeaderFooter: true,
    showInTableOfContents: true,
    showFirstSentenceFormatting: false,
  },
};
