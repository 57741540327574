import React, { useState } from "react";
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import useRootStore from "../../store/useRootStore";

const { TextArea } = Input;

const JSONPreviewer = () => {
  const [show, setshow] = useState(false);
  const [editable, setEditable] = useState(true);
  const { body } = useRootStore().bookStore;

  return (
    <>
      <Button className="btn" type="primary" onClick={() => setshow(!show)}>
        JSON
      </Button>
      <Modal
        title={
          <Row align="middle">
            <h3>JSON Previewer</h3>
            <Button type="link" onClick={() => setEditable(!editable)}>{editable ? "Edit" : "View"} JSON</Button>
          </Row>
        }
        onCancel={() => setshow(false)}
        width={720}
        open={show}
      >
        <>
          <Row>
            <TextArea
              disabled={editable}
              autoSize
              value={JSON.stringify(body, null, 4)}
            ></TextArea>
          </Row>
        </>
      </Modal>
    </>
  );
};

export default JSONPreviewer;