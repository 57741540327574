import React, { useRef, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";

import useRootStore from "../../../store/useRootStore";


export const Root = observer((props: HTMLDivElement) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { topContainerPosition, setContainerTopPosition } = useRootStore().appStore;

    useEffect(() => {
        if (containerRef !== null && containerRef.current !== null) {
            const topPosition = containerRef.current.getBoundingClientRect().top;
            setContainerTopPosition(topPosition);
        }
    }, [containerRef.current]);

    return (
        <>:</>
        // Built for later developments

        // <div
        //     {...{
        //         ...props,
        //         ref: containerRef,
        //         style: {
        //             height: `calc(100vh - ${topContainerPosition}px)`,
        //         }
        //     }}
        // >
        //     <div>
        //         {props.children}
        //     </div>
        // </div>
    );
});