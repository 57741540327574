import React, { FunctionComponent, useMemo, useState } from "react";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";

import {
  Menu,
  Dropdown,
  Button,
  Divider,
  message,
  Tooltip,
  Badge,
} from "antd";
import { cloneDeep, find } from "lodash";
import { Modal as AtticusModal } from "../../../Shared/Modal";

//store
import useRootStore from "../../../../store/useRootStore";

import { useOnlineStatus } from "../../../../utils/isOffline";

import { MenuBook } from "@material-ui/icons";
import { MobileOutlined } from "@ant-design/icons";
import {
  ChapterSidebarIcon,
  ToCSidebarIcon,
  TitleSidebarIcon,
  CopyrightSidebarIcon,
  MoreIcon,
  CloseIconWithoutBackground,
  ArrowUp,
  ArrowDown,
  AlertIcon,
  BlueTickIcon,
  VolumeSidebarIcon,
  PartSidebarIcon,
} from "../../../../content/icons";

// Defaults
import { sectionTypes, getLabel, isMergable } from "../../helpers";

// types
import { ChapterMeta, IncludeInType } from "../../../../types/chapter";
import ContextMenu from "../../ContextMenu";
import useContextMenu from "../../../../utils/hooks/useContextMenu";
import { useSideBarActions } from "../../../../utils/hooks/useSideBarActions";
import { SectionType, SideBarChapterItem } from "../../../../types/sidebar";
import { getBookMetaSummaryForCache } from "../../../../utils/sidebar";
import { ellipsizeTitle } from "../../../../utils/strings";
import { observer } from "mobx-react";
import { isNumberedChapter } from "../../../../utils/chapter-numbering";

const { SubMenu } = Menu;

interface ChapterBlockItemProps {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  item: SideBarChapterItem;
}

const nonDraggableChapterTypes = ["endnotes", "toc"];
const nonDeleteableChapterTypes = ["endnotes", "toc"];
const nonConvertableChapterTypes = [
  "toc",
  "title",
  "image",
  "endnotes",
  "part",
  "volume",
  "copyrights",
  "chapter",
];

const ChapterItem: FunctionComponent<ChapterBlockItemProps> = ({
  item,
  snapshot,
  provided,
}) => {
  const {
    chapter,
    section,
    hasChildren,
    level,
    beginOn,
    canMerge,
    number,
    createPart,
    createVolume,
  } = item;
  const chapterId = chapter._id;

  const {
    captureClickEvent,
    selectedChaptersMap,
    expandedChapters,
    handleCollapseChange,
    unSelectChapter,
  } = useRootStore().sideMenuStore;

  const isOnline = useOnlineStatus();
  const { chapterTemplates, storeChapterTemplate, syncChapterTemplate } =
    useRootStore().chapterStore;
  const {
    book,
    setBook,
    extras,
    chapter: currentEditorChapter,
    sceneIndex,
    saveChapterMetaUpdates,
    getCurrentStoredBook,
    getChapterBodyById,
    getErrorChapter,
    mergeChapter,
    deleteChapterGroup,
    getAndSetCurChapter,
  } = useRootStore().bookStore;
  const { refreshCache } = useRootStore().pdfCacheStore;
  const { saveBook } = useRootStore().shelfStore;
  const [error, setError] = React.useState(false);
  const { updateChapterMeta, handleDeleteChapter } = useSideBarActions();
  const extra = find(extras, { id: chapter._id });
  const [deleteChapterModal, setDeleteChapterModal] = useState(false);
  const [deleteGroupChapterModal, setGroupChapterModal] = useState(false);

  const isMultipleChaptersSelected =
    Object.keys(selectedChaptersMap).length > 1;

  const draggable =
    nonDraggableChapterTypes.indexOf(chapter.type) === -1 &&
    !isMultipleChaptersSelected;
  const deletable = nonDeleteableChapterTypes.indexOf(chapter.type) === -1;
  const convertable = nonConvertableChapterTypes.indexOf(chapter.type) === -1;
  const isActive =
    (selectedChaptersMap[chapterId] ||
      currentEditorChapter._id === chapterId) &&
    sceneIndex == null;
  const isIncludeInAvailable = useMemo(() => {
    if (chapter.type === "part") {
      return false;
    }
    return !isNumberedChapter(chapter, book);
  }, [chapter, book]);

  const setStartPage = async () => {
    const updatedBook = {
      ...book,
      startPage: "toc",
    };
    setBook(updatedBook);
    saveBook(book._id, { startPage: "toc" });
    refreshCache(book._id, "book-properties-change");
    return;
  };

  const onDelete = async (
    deleteChapterId: string,
    deleteChapterTitle: string
  ) => {
    if (deleteChapterId) {
      if (
        book.startPage === deleteChapterId ||
        book.startPage ===
          deleteChapterTitle
            .trim()
            .replace(/[^\w\s]/gi, "")
            .replace(/ /g, "-")
      ) {
        await setStartPage();
        
      }
      handleDeleteChapter(deleteChapterId);
      setDeleteChapterModal(false);
    }
  };

  const onDeleteConfirmed = async (preserveChildren : boolean, chapter :ChapterMeta) => {
    const book = await deleteChapterGroup(
      chapter._id,
      preserveChildren
    );
    if (book) {
      const { chapterCacheData } = getBookMetaSummaryForCache(
        book.frontMatter,
        book.chapters
      );
      refreshCache(book._id, "chapter-delete", {
        "chapter-delete": { chapterId, chapters: chapterCacheData },
      });
    }
  };

  const frontMatterCovertOptions = sectionTypes.frontMatter.filter((type) =>
    isMergable(type)
  );
  const bodyCovertOptions = sectionTypes.body.filter((type) =>
    isMergable(type)
  );

  const switchToInteractedChapter = () => {
    unSelectChapter(currentEditorChapter._id);
    getAndSetCurChapter(chapter._id, sceneIndex);
  };

  const onConvertToChapter = async () => {
    await updateChapterMeta({
      ...chapter,
      type: "chapter",
    });

    switchToInteractedChapter();
  };

  const toggleNumbered = async () => {
    const isNumbered =
      chapter.numbered || chapter.numbered === undefined ? false : true;

    await updateChapterMeta({
      ...chapter,
      numbered: isNumbered,
      includeIn: isNumbered ? "all" : chapter.includeIn,
    });

    switchToInteractedChapter();

    // If the chapter is changed to numbered and and had a include in other than "all" previously
    // it has changed the include in to "all" so we need to invalidate the cache and rerender.
    if (isNumbered && chapter.includeIn !== "all") {
      const { _id, type, startOn } = chapter;
      refreshCache(chapter.bookId, "chapter-properties-change", {
        "chapter-properties-change": {
          chapter: {
            chapterId: _id,
            chapterType: type,
            startOn: startOn,
            includeIn: "all",
          },
        },
      });
    }
  };

  const handleSaveAsMasterPage = async () => {
    switchToInteractedChapter();
    const templateId: string = await storeChapterTemplate(
      chapter._id,
      chapter.title,
      section
    );
    await saveChapterMetaUpdates({
      ...chapter,
      templateId: templateId,
    });
    await syncChapterTemplate(true, false, book._id, templateId, chapter._id);
    message.success("Master page created successfully", 4);
  };

  const updateIncludeIn = async (includeIn: IncludeInType) => {
    const updatedChapterMeta: Partial<ChapterMeta> = cloneDeep({
      ...chapter,
      includeIn,
    });
    await updateChapterMeta(updatedChapterMeta);
    switchToInteractedChapter();

    const { _id, type, startOn } = chapter;
    refreshCache(chapter.bookId, "chapter-properties-change", {
      "chapter-properties-change": {
        chapter: {
          chapterId: _id,
          chapterType: type,
          startOn: startOn,
          includeIn,
        },
      },
    });
  };

  const updateStartOn = async (startOn: "right" | "left" | "any") => {
    await updateChapterMeta({
      ...chapter,
      startOn,
    });
    switchToInteractedChapter();
  };

  const getChapterCacheData = async () => {
    const { frontMatterIds, chapterIds } = getCurrentStoredBook();
    const allChapterIds = [...frontMatterIds, ...chapterIds];
    const chapterData = await getChapterBodyById(allChapterIds);
    const chapterCacheData = chapterData.map(
      ({ _id, type, startOn }) =>
        ({
          chapterId: _id,
          chapterType: type,
          startOn,
        } as IPDFCacheStore.ChapterCacheMetaData)
    );
    return chapterCacheData;
  };

  const handleMergeChaptersClick = async (
    section: SectionType,
    chapterId: string
  ) => {
    if (canMerge) {
      await mergeChapter(section, chapterId);
      const chapterCacheData = await getChapterCacheData();
      refreshCache(chapter.bookId, "chapter-merge", {
        "chapter-merge": { chapters: chapterCacheData },
      });
    }
  };

  const loadFailChapters = async () => {
    const failChaps = await getErrorChapter();
    if (failChaps) {
      const checkBook = failChaps.map((a) => {
        if (a._chapterId === chapter._id) {
          setError(true);
        }
      });
    }
  };

  const ChapterVisibilityIndicator = () => {
    if (chapter.includeIn == "print") {
      return <MenuBook className="action-icon" />;
    }
    if (chapter.includeIn === "ebook") {
      return <MobileOutlined className="action-icon" />;
    }
    return null;
  };

  const handleCollapse = (
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    collapseIcon?: boolean
  ) => {
    if (collapseIcon) {
      e?.stopPropagation();
      handleCollapseChange(chapter._id);
    } else {
      if (chapter.type === "part" || chapter.type === "volume")
        handleCollapseChange(chapter._id, false);
    }
  };

  const listItemRef = React.createRef<HTMLAnchorElement>();
  const isContextMenuEnabled = !!selectedChaptersMap[chapter._id];
  const [displayContextMenu, anchorPoints] = useContextMenu(
    listItemRef,
    isContextMenuEnabled
  );

  const isMasterPage = useMemo(() => {
    return chapterTemplates.some(
      (template) => template.motherChapterId === chapter._id || template._id === chapter.templateId
    );
  }, [chapter._id, chapterTemplates]);

  const showChapterNumber = section === "body" && number;
  const isFrontMatter = section === "frontMatter";

  const getChaptertitle = (): string => {
    if (chapter.title) return chapter.title;
    if (showChapterNumber) return "";
    if (chapter.type === "chapter") return "Untitled";
    else return getLabel(chapter.type);
  };

  const isSectionExpanded = useMemo(() => {
    return expandedChapters.has(chapter._id);
  }, [expandedChapters, chapter]);

  const ChapterBlockIcon = (): JSX.Element => {
    const color = isActive ? "#3568BA" : "#171D21";
    if (error)
      return (
        <Tooltip title="Copy/Paste error" color="#3568BA" arrowPointAtCenter>
          <span className="chapter-block-icon">
            <AlertIcon />
          </span>
        </Tooltip>
      );
    if (chapter.type === "title")
      return (
        <span className="chapter-block-icon">
          <TitleSidebarIcon color={color} />
        </span>
      );
    if (chapter.type === "toc")
      return (
        <span className="chapter-block-icon">
          <ToCSidebarIcon color={color} />
        </span>
      );
    if (chapter.type === "copyrights")
      return (
        <span className="chapter-block-icon">
          <CopyrightSidebarIcon color={color} />
        </span>
      );
    if (chapter.type === "volume")
      return (
        <span className="chapter-block-icon">
          <VolumeSidebarIcon color={color} />
        </span>
      );
    if (chapter.type === "part")
      return (
        <span className="chapter-block-icon">
          <PartSidebarIcon color={color} />
        </span>
      );
    return (
      <span className="chapter-block-icon">
        <ChapterSidebarIcon color={color} />
      </span>
    );
  };

  const getBlockItemIndentation = (): string => {
    const baseIndentation = isFrontMatter ? 0.8 : 1.4;
    return `${level + baseIndentation}rem`;
  };

  const isIncludeInMenuItemSelected = (
    item: "All" | "E-Book Only" | "Print Only" | "None"
  ): boolean => {
    if (item === "All")
      return chapter.includeIn === undefined || chapter.includeIn === "all";
    if (item === "E-Book Only") return chapter.includeIn === "ebook";
    if (item === "Print Only") return chapter.includeIn === "print";
    else return chapter.includeIn === "none";
  };

  const isBeginOnMenuItemSelected = (
    item: "Either Side" | "Right-side" | "Left-side"
  ): boolean => {
    if (item === "Either Side")
      return chapter.startOn === undefined || chapter.startOn === "any";
    if (item === "Right-side") return chapter.startOn === "right";
    else return chapter.startOn === "left";
  };

  const showNumberedChapterMenuItem =
    chapter.type === "chapter" ||
    chapter.type === "uncategorized" ||
    chapter.type === "custom" ||
    chapter.type === undefined;

  const isNumberedChapterSelected =
    chapter.numbered === undefined || chapter.numbered === true;

  const showSaveAsMasterpageMenuItem =
    !["endnotes", "part", "volume", "title", "image", "toc"].includes(
      chapter.type
    ) && !isMasterPage;

  const showBaseChapterOptions = !["volume", "part"].includes(chapter.type);

  React.useEffect(() => {
    loadFailChapters();
  }, [chapter]);

  return (
    <>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={provided.draggableProps.style}
      >
        <div
          className={`chapter-block-item ${
            snapshot.isDragging ? " hover" : ""
          } ${isActive ? " active" : ""} ${
            chapter.includeIn === "none" &&
            chapter.type !== "volume" &&
            chapter.type !== "part"
              ? " chapter-hidden"
              : ""
          }`}
          style={{ marginLeft: getBlockItemIndentation() }}
        >
          <div
            className="chapter-block-item-container"
            onClick={() => handleCollapse()}
          >
            <a
              onClick={(e) =>
                captureClickEvent(
                  e,
                  chapter._id,
                  undefined,
                  createPart,
                  createVolume
                )
              }
              ref={listItemRef}
            >
              <div className="chapter-block-item-info-container">
                {extra && (
                  <Badge
                    count={extra.extra}
                    className="chapter-block-item-chapter-extra"
                  />
                )}
                <div className="chapter-block-item-icon-container">
                  {ChapterBlockIcon()}
                </div>
                <div className="chapter-block-item-chapter-name-cover">
                  <p
                    className={`chapter-block-item-chapter-name ${
                      isActive ? " active" : ""
                    }
                    ${isFrontMatter ? " frontmatter" : ""}`}
                  >
                    {showChapterNumber && <span>{number}. </span>}
                    {ellipsizeTitle(getChaptertitle())}
                  </p>
                </div>
              </div>
            </a>
            <ContextMenu
              canDisplay={displayContextMenu}
              top={anchorPoints ? anchorPoints.top + 25 : 0}
              left={anchorPoints?.left}
            />
            &nbsp;
            <ChapterVisibilityIndicator />
          </div>
          <div className="action-group">
            {hasChildren && (
              <div onClick={(e) => handleCollapse(e, true)}>
                <Button
                  type="text"
                  shape="round"
                  size="middle"
                  className="action-btn"
                >
                  {isSectionExpanded ? (
                    <span className="chapter-block-icon">
                      <ArrowUp />
                    </span>
                  ) : (
                    <span className="chapter-block-icon">
                      <ArrowDown />
                    </span>
                  )}
                </Button>
              </div>
            )}
            {showBaseChapterOptions && (
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    {canMerge && (
                      <Menu.Item
                        onClick={() =>
                          handleMergeChaptersClick(section, chapter._id)
                        }
                      >
                        Merge with next chapter
                      </Menu.Item>
                    )}
                    {convertable && (
                      <Menu.Item onClick={onConvertToChapter}>
                        Convert To chapter
                      </Menu.Item>
                    )}
                    {isIncludeInAvailable && (
                      <SubMenu title="Include in">
                        <Menu.Item
                          style={{
                            color: isIncludeInMenuItemSelected("All")
                              ? "#3568BA"
                              : "#171D21",
                          }}
                          icon={
                            isIncludeInMenuItemSelected("All") && (
                              <span className="chapter-block-icon">
                                <BlueTickIcon />
                              </span>
                            )
                          }
                          onClick={() => {
                            updateIncludeIn("all");
                          }}
                        >
                          All
                        </Menu.Item>
                        <Divider className="nospace" />
                        <Menu.Item
                          style={{
                            color: isIncludeInMenuItemSelected("E-Book Only")
                              ? "#3568BA"
                              : "#171D21",
                          }}
                          icon={
                            isIncludeInMenuItemSelected("E-Book Only") && (
                              <span className="chapter-block-icon">
                                <BlueTickIcon />
                              </span>
                            )
                          }
                          onClick={() => {
                            updateIncludeIn("ebook");
                          }}
                        >
                          E-Book Only
                        </Menu.Item>
                        <Menu.Item
                          style={{
                            color: isIncludeInMenuItemSelected("Print Only")
                              ? "#3568BA"
                              : "#171D21",
                          }}
                          icon={
                            isIncludeInMenuItemSelected("Print Only") && (
                              <span className="chapter-block-icon">
                                <BlueTickIcon />
                              </span>
                            )
                          }
                          onClick={() => {
                            updateIncludeIn("print");
                          }}
                        >
                          Print Only
                        </Menu.Item>
                        <Divider className="nospace" />
                        <Menu.Item
                          style={{
                            color: isIncludeInMenuItemSelected("None")
                              ? "#3568BA"
                              : "#171D21",
                          }}
                          icon={
                            isIncludeInMenuItemSelected("None") && (
                              <span className="chapter-block-icon">
                                <BlueTickIcon />
                              </span>
                            )
                          }
                          onClick={() => {
                            updateIncludeIn("none");
                          }}
                        >
                          None
                        </Menu.Item>
                      </SubMenu>
                    )}
                    {beginOn && (
                      <SubMenu title="Begin On">
                        <Menu.Item
                          style={{
                            color: isBeginOnMenuItemSelected("Either Side")
                              ? "#3568BA"
                              : "#171D21",
                          }}
                          icon={
                            isBeginOnMenuItemSelected("Either Side") && (
                              <span className="chapter-block-icon">
                                <BlueTickIcon />
                              </span>
                            )
                          }
                          onClick={() => {
                            updateStartOn("any");
                          }}
                        >
                          Either side
                        </Menu.Item>
                        <Menu.Item
                          style={{
                            color: isBeginOnMenuItemSelected("Right-side")
                              ? "#3568BA"
                              : "#171D21",
                          }}
                          icon={
                            isBeginOnMenuItemSelected("Right-side") && (
                              <span className="chapter-block-icon">
                                <BlueTickIcon />
                              </span>
                            )
                          }
                          onClick={() => {
                            updateStartOn("right");
                          }}
                        >
                          Right side
                        </Menu.Item>
                        <Menu.Item
                          style={{
                            color: isBeginOnMenuItemSelected("Left-side")
                              ? "#3568BA"
                              : "#171D21",
                          }}
                          icon={
                            isBeginOnMenuItemSelected("Left-side") && (
                              <span className="chapter-block-icon">
                                <BlueTickIcon />
                              </span>
                            )
                          }
                          onClick={() => {
                            updateStartOn("left");
                          }}
                        >
                          Left side
                        </Menu.Item>
                      </SubMenu>
                    )}
                    {showNumberedChapterMenuItem && (
                      <Menu.Item
                        style={{
                          color: isNumberedChapterSelected
                            ? "#3568BA"
                            : "#171D21",
                        }}
                        icon={
                          isNumberedChapterSelected && (
                            <span className="chapter-block-icon">
                              <BlueTickIcon />
                            </span>
                          )
                        }
                        onClick={() => {
                          toggleNumbered();
                        }}
                      >
                        Numbered chapter
                      </Menu.Item>
                    )}
                    {showSaveAsMasterpageMenuItem && (
                      <Menu.Item onClick={() => handleSaveAsMasterPage()}>
                        Save as a master page
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button
                  type="text"
                  shape="round"
                  size="middle"
                  className="action-btn"
                >
                  <span className="chapter-block-icon">
                    <MoreIcon />
                  </span>
                </Button>
              </Dropdown>
            )}
            {deletable && isOnline && (
              <>
                <Button
                  type="text"
                  shape="round"
                  size="middle"
                  className="action-btn"
                  onMouseDown={() => {
                    if (["volume", "part"].includes(chapter.type)) {
                      setGroupChapterModal(true);
                      return;
                    }
                    setDeleteChapterModal(true);
                  }}
                >
                  <span className="chapter-block-icon">
                    <CloseIconWithoutBackground />
                  </span>
                </Button>
                <AtticusModal
                  open={deleteChapterModal}
                  title={<b>Delete chapter</b>}
                  centered={true}
                  onCancel={() => {
                    setDeleteChapterModal(false);
                  }}
                  destroyOnClose={true}
                  leftBtn={{
                    type: "at-secondary",
                    className: "link-modal-btn",
                    backgroundColor: "green",
                    onMouseDown: () => setDeleteChapterModal(false),
                    children: "No",
                  }}
                  rightBtn={{
                    type: "at-primary",
                    danger: true,
                    onMouseDown: () => onDelete(chapter._id, chapter.title),
                    children: "Delete",
                  }}
                >
                  {" "}
                  <p className="delete-modal-bottom-padding">
                    Are you sure you want to delete "{<b>{chapter.title}</b>}"?
                  </p>
                </AtticusModal>

                <AtticusModal
                  open={deleteGroupChapterModal}
                  title={<b>Delete {chapter.type == "part" ? "Part" : "Volume"}</b>}
                  centered={true}
                  onCancel={() => {
                    setGroupChapterModal(false);
                  }}
                  destroyOnClose={true}
                  leftBtn={{
                    type: "at-secondary",
                    className: "link-modal-btn",
                    backgroundColor: "green",
                    onMouseDown: () => onDeleteConfirmed(true, chapter),
                    children: "Keep Children",
                  }}
                  rightBtn={{
                    type: "at-primary",
                    danger: true,
                    onMouseDown: () => onDeleteConfirmed(false, chapter),
                    children: "Delete Children",
                  }}
                >
                  {" "}
                  <p className="delete-modal-bottom-padding">
                    How do you want to delete?
                  </p>
                </AtticusModal>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ChapterItem);
