export const deviceSpec = {
    devices: [
        {
            _deviceName: "paperwhite",
            image: "https://atticus-content.s3.amazonaws.com/devices/Kindle+Paperwhite.png",
            fonts: [
                "Bookerly",
                "Baskerville",
                "Georgia",
                "Helvetica",
                "Palatino",
                "Open Dyslexic"
            ]
        },
        {
            _deviceName: "fire",
            image: "https://atticus-content.s3.amazonaws.com/devices/Kindle+Fire.png",
            fonts: [
                "Bookerly",
                "Baskerville",
                "Caecilia",
                "Palatino",
                "Georgia",
                "Helvetica",
                "Open Dyslexic"
            ]
        },
        {
            _deviceName: "oasis",
            image: "https://atticus-content.s3.amazonaws.com/devices/Kindle+Oasis.png",
            fonts: [
                "Bookerly",
                "Caecilia",
                "Droid Serif",
                "Lucida",
                "Georgia",
                "Helvetica",
                "Open Dyslexic"
            ]
        },
        {
            _deviceName: "forma",
            image: "https://atticus-content.s3.amazonaws.com/devices/Kobo+Forma.png",
            fonts: [
                "Avenir Next",
                "Georgia",
                "Gill Sans",
                "Droid Sans Serif",
                "Droid Serif",
                "Open Dyslexic"
            ]
        },
        {
            _deviceName: "clara",
            image: "https://atticus-content.s3.amazonaws.com/devices/Kobo+Clara.png",
            fonts: [
                "Avenir Next",
                "Georgia",
                "Gill Sans",
                "Droid Sans Serif",
                "Droid Serif",
                "Open Dyslexic"
            ]
        },
        {
            _deviceName: "libra",
            image: "https://atticus-content.s3.amazonaws.com/devices/Kobo+Libra+H20.png",
            fonts: [
                "Avenir Next",
                "Georgia",
                "Gill Sans",
                "Droid Sans Serif",
                "Droid Serif",
                "Open Dyslexic"
            ]
        },
        {
            _deviceName: "nia",
            image: "https://atticus-content.s3.amazonaws.com/devices/Kobo+Nia.png",
            fonts: [
                "Avenir Next",
                "Georgia",
                "Gill Sans",
                "Droid Sans Serif",
                "Droid Serif",
                "Open Dyslexic"
            ]
        },
        {
            _deviceName: "glowlight",
            image: "https://atticus-content.s3.amazonaws.com/devices/Nook+Glowlight.png",
            fonts: [
                "Baskerville",
                "Helvetica Neue",
                "Trebuchet MS",
                "Gill Sans",
                "Open Dyslexic"
            ]
        },
        {
            _deviceName: "glowlight_plus",
            image: "https://atticus-content.s3.amazonaws.com/devices/Nook+Glowlight+Plus.png",
            fonts: [
                "Helvetica Neue",
                "Trebuchet MS",
                "Gill Sans",
                "Open Dyslexic"
            ]
        },
        {
            _deviceName: "iphone",
            image: "https://atticus-content.s3.amazonaws.com/devices/iPhone+12+Pro.png",
            fonts: [
                "Athelas",
                "Charter",
                "Palatino",
                "Georgia",
                "San Francisco",
                "Seravek",
                "Iowan"
            ]
        },
        {
            _deviceName: "ipad",
            image: "https://atticus-content.s3.amazonaws.com/devices/iPad+Pro.png",
            fonts: [
                "Palatino",
                "Georgia",
                "San Franciso",
                "Athleas",
                "Iowan",
                "New York",
                "Seravek",
                "Charter"
            ]
        },
        {
            _deviceName: "galaxy_s21",
            image: "https://atticus-content.s3.amazonaws.com/devices/Galaxy+S21.png",
            fonts: [
                "Droid Sans"
            ]
        },
        {
            _deviceName: "galaxy_tab_7",
            image: "https://atticus-content.s3.amazonaws.com/devices/Galaxy+Tab+S7.png",
            fonts: [
                "Droid Sans"
            ]
        },
        {
            _deviceName: "print",
            image: "",
            fonts: [
                "inherit"
            ]
        },
        
        
    ]
};