import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Breadcrumb } from "antd";

import useRootStore from "../../store/useRootStore";

import ThemeList from "./ThemeList";
import {
  ChapterHeading,
  ParagraphSetting,
  SceneBreakSettings,
  NotesSettings,
  HeadingSettings,
  PageLayoutSettings,
  TypographySettings,
  PrintLayoutSettings,
  TrimSizeSettings
} from "./views";
import { AtticusVerticalTab } from "../Shared/Tabs";

import {
  formFieldsToStyleProps,
  partialThemePropsToTheme,
  getFullFormFields,
  hasCommonElements,
  FontNameFieldsWithStyle,
  ThemeFormFieldsToDebounce,
  themeFormFieldsCheck,
} from "./helpers";
import { DEFAULT_THEME_ID } from "../../utils/initials";
import { debounce, find, cloneDeep } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useOnlineStatus } from "../../utils/isOffline";
import { getNewFontStyle } from "../../utils/font";
import { SeparateIcon } from "../../content/icons";
import { Modal } from "../Shared/Modal";
import { Button } from "../Shared/Buttons";
import { ScrollContainer } from "../Shared/Layouts";
import { AtticusTextField } from "../Shared/Form";

import FontGalleryModal from "../Fonts/NewFontGallery";

const ThemeSection = (): JSX.Element => {
  const [form] = Form.useForm<IThemeStore.ThemeFields>();
  const {
    isThemeBuilderActive,
    activeTheme,
    allThemes,
    duplicateTheme,
    themeFormFields,
    isCurrentThemeDirty,
    setActiveTheme,
    setThemeFormFields,
    saveNewCustomTheme,
    editTheme,
    switchTheme,
    setThemeToEdit,
    discardEditThemeForm,
    setIsCurrentThemeDirty,
    confirmExitEdit,
  } = useRootStore().themeStore;
  const { book, setBook, updateEndnotesChapter } = useRootStore().bookStore;
  const { refreshCache } = useRootStore().pdfCacheStore;
  const isOnline = useOnlineStatus();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);

  const sanitizedThemeFormFields = toJS(themeFormFields);

  const updateInitialFormValues = () => form.setFieldsValue(sanitizedThemeFormFields);

  useEffect(() => {
    updateInitialFormValues();
  }, [themeFormFields]);
  
  const shouldRenderSaveButton =
    isThemeBuilderActive &&
    !activeTheme.isPredefinedTheme &&
    activeTheme._id !== DEFAULT_THEME_ID;

  const shouldRenderSaveAsButton = isThemeBuilderActive;

  const shouldRenderDiscardButton = isThemeBuilderActive;

  // This is to update the MobX Store with the activeTheme id as switching
  // between the writing and formatting mode does not read from the IndexDB.
  useEffect(() => {
    if (book.themeId !== activeTheme._id && activeTheme._id !== "default") {
      setBook({
        ...book,
        themeId: activeTheme._id,
      });
    }
  }, [activeTheme]);

  /**
   * Switches the active theme for the book if clicked on theme thumbnails
   * Opens theme configurator form and populates the form with defaults if
   * 'Create a new theme' is clicked
   * @param themeId Theme card Id
   */
  const handleThemeCardOnClick = async (themeId: string): Promise<void> => {
    const theme = find(allThemes, { _id: themeId });

    if (themeId === activeTheme._id) return;

    if (theme) {
      const oldTheme = activeTheme;
      if (themeId !== DEFAULT_THEME_ID) {
        await switchTheme(book._id, theme);
        await setBook({ ...book, themeId: theme._id });
      } else {
        setThemeToEdit(theme);
      }

      debounceTriggerThemeSideEffects(oldTheme, theme);
    }
  };

  /**
   * Sets the theme card theme as the active theme, and populates the form
   * with its values
   * This function is prop drilled deep in to child components to in order to
   * trigger the theme update side effects
   * @param themeId Theme card Id
   */
  const handleThemeCardOnEdit = (themeId: string) => {
    let theme = find(allThemes, { _id: themeId });
    if (theme) {
      // AT-948 -> Name should be empty when editing predefined themes
      theme = theme.isPredefinedTheme
        ? {
          ...theme,
          name: "",
        }
        : theme;

      const oldTheme = activeTheme;
      setThemeToEdit(theme);

      debounceTriggerThemeSideEffects(oldTheme, theme);
    }
  };

  /**
   * Updates app level 'activeTheme' when form fields are changed to render
   * a live preview of theme being edited on the previewer
   * @param themeFields Values of the theme configurator form
   */
  const updateActiveTheme = (themeFields: IThemeStore.ThemeFields) => {
    setIsCurrentThemeDirty(true);
    setThemeFormFields(themeFields);

    const themeStyleProps = formFieldsToStyleProps(themeFields);
    const oldThemeProps = cloneDeep(activeTheme);
    /**
     * ensures unconfigurable properties (via the form UI) in custom / predefined
     * themes are considered when updating the theme.
     * antd form onValuesChange does not always return properties that are not bound to
     * a form element.
     */

    const tempUpdatedTheme = partialThemePropsToTheme(
      {
        properties: themeStyleProps,
        name: themeFields.name,
      },
      oldThemeProps
    );

    setActiveTheme(tempUpdatedTheme);

    debounceTriggerThemeSideEffects(oldThemeProps, tempUpdatedTheme);
  };

  const debouncedUpdateActiveTheme = debounce((allThemeFields) => {
    updateActiveTheme(allThemeFields);
  }, 300);

  /**
   * Updates chapter header elemet font styles as necessary when header element fonts change
   * @param updatedFields Form fields updated during the change
   * @param allFormFields All form fields
   * @returns All form fields with updated header font styles
   */
  const updateHeaderFontStyleWithFont = (
    updatedFields: Partial<IThemeStore.ThemeFields>,
    allFormFields: IThemeStore.ThemeFields
  ) => {
    const formFields = allFormFields;
    if (updatedFields.chpNumFont) {
      const newFontStyle = getNewFontStyle(
        allFormFields.chpNumFont,
        allFormFields.chpNumStyle
      );
      formFields.chpNumStyle = newFontStyle;
      form.setFieldsValue({ chpNumStyle: newFontStyle });
    }
    if (updatedFields.chpTitleFont) {
      const newFontStyle = getNewFontStyle(
        allFormFields.chpTitleFont,
        allFormFields.chpTitleStyle
      );
      formFields.chpTitleStyle = newFontStyle;
      form.setFieldsValue({ chpTitleStyle: newFontStyle });
    }
    if (updatedFields.chpSubtitleFont) {
      const newFontStyle = getNewFontStyle(
        allFormFields.chpSubtitleFont,
        allFormFields.chpSubtitleStyle
      );
      formFields.chpSubtitleStyle = newFontStyle;
      form.setFieldsValue({ chpSubtitleStyle: newFontStyle });
    }
    return formFields;
  };

  /**
   * Handles Form onValueChange which triggers when form fields are changes from the UI
   * @param updatedFields Updated form fields and values
   * @param allFormFields All form fields and values
   */
  const handleChange = (
    updatedFields: Partial<IThemeStore.ThemeFields>,
    allFormFields?: IThemeStore.ThemeFields
  ) => {

    // Get form fields programmatically when no allFormFields value is not received.
    const formFields = allFormFields ? allFormFields : form.getFieldsValue();

    let shouldDebounceUpdate = false;
    let allFields = formFields;

    //do a defaults with state fields if fields are missing keys
    if (themeFormFieldsCheck(allFields).length > 0)
      allFields = getFullFormFields(
        getFullFormFields(updatedFields, formFields),
        sanitizedThemeFormFields
      );

    const updatedFieldsList = Object.keys(updatedFields);
    /** debounce the theme update when theme name field is edited */
    if (hasCommonElements(updatedFieldsList, ThemeFormFieldsToDebounce))
      shouldDebounceUpdate = true;
    if (hasCommonElements(updatedFieldsList, FontNameFieldsWithStyle)) {
      allFields = updateHeaderFontStyleWithFont(updatedFields, formFields);
    }

    if (shouldDebounceUpdate) {
      debouncedUpdateActiveTheme(allFields);
    } else {
      updateActiveTheme(allFields);
    }
  };

  const handleThemeFormDiscard = () => {
    confirmExitEdit(
      () => {
        debounceTriggerThemeSideEffects(activeTheme, duplicateTheme);
        discardEditThemeForm();
      },
      () => undefined,
      {
        title: "Do you want to discard your changes?",
        cancel: "Discard",
        ok: "Continue working",
      }
    );
  };

  /**
   * Side effects of theme updates:
   * Update endnotes chapter if necessary
   * Refresh pdf cache
   */
  const debounceTriggerThemeSideEffects = debounce(
    async (oldTheme: IThemeStore.Theme, newTheme: IThemeStore.Theme) => {
      await updateEndnotesChapter(newTheme, oldTheme, refreshCache);
      refreshCache(book._id, "theme-change");
    },
    2000
  );

  const saveAsNewTheme = async () => {
    setSaving(true);
    await saveNewCustomTheme(activeTheme, book._id);
    setSaving(false);
    setIsModalOpen(false);
  };

  const editExistingTheme = async () => {
    setEditing(true);
    await editTheme(activeTheme, isOnline);
    setEditing(false);
  };

  const openModal = () => {
    form.setFieldValue("name", "");
    setIsModalOpen(true);
  };

  let themeName = form.getFieldValue("name");

  if (themeName) {
    themeName = themeName.trim();
  }

  return (
    <div className="theme-container">
      <Row className="inner-xs" align="middle" wrap={false}>
        <Col
          className="theme-container-top"
          flex={1}

        >
          <h3 className="current-theme-title truncate">
            {activeTheme._id === DEFAULT_THEME_ID ? (
              "Creating a new theme"
            ) : (
              <>
                {isThemeBuilderActive && isCurrentThemeDirty && "*"}Current Theme -{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {activeTheme.name}
                </span>
              </>
            )}
          </h3>
        </Col>
        {/* {!isThemeBuilderActive && (
          <Col span={12} xxl={9} className="justify-flex-end">
            <Row gutter={8}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => handleThemeCardOnEdit(activeTheme._id)}
                  className="btn"
                >
                  Edit Theme
                </Button>
              </Col>
            </Row>
          </Col>
        )} */}
        {isThemeBuilderActive && (
          <Col flex="420px" className="justify-flex-end">
            <Row gutter={8}>
              <Col>
                {shouldRenderDiscardButton && (
                  <Button
                    disabled={editing}
                    loading={saving}
                    type="at-secondary"
                    onClick={() => handleThemeFormDiscard()}
                    className="btn"
                  >
                    Discard
                  </Button>
                )}
              </Col>
              <Col>
                {shouldRenderSaveAsButton && isOnline && (
                  <Button
                    disabled={editing}
                    loading={saving}
                    type={
                      activeTheme._id === DEFAULT_THEME_ID
                        ? "at-primary"
                        : "at-secondary"
                    }
                    onClick={() => openModal()}
                  >
                    Save as new theme
                  </Button>
                )}
              </Col>
              <Col>
                {shouldRenderSaveButton && (
                  <Button
                    disabled={saving}
                    loading={editing}
                    type="at-primary"
                    color="blue"
                    onClick={editExistingTheme}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <div className="theme-section">
        <Row className="theme-section-header">
          <Col>
            <Breadcrumb className="at-breadcrumbs" separator={<SeparateIcon />}>
              <Breadcrumb.Item onClick={() => handleThemeFormDiscard()}>Themes</Breadcrumb.Item>
              {isThemeBuilderActive ? shouldRenderSaveButton
                ? <Breadcrumb.Item>Edit {activeTheme.name}</Breadcrumb.Item>
                : <Breadcrumb.Item>Create new theme</Breadcrumb.Item>
                : null
              }
            </Breadcrumb>
          </Col>
        </Row>
        {!isThemeBuilderActive ? (
          <ThemeList
            themeCardOnEdit={handleThemeCardOnEdit}
            themeCardOnClick={handleThemeCardOnClick}
          />
        ) : (
          <Form
            form={form}
            layout="vertical"
            className="theme-form"
            onValuesChange={handleChange}
            initialValues={sanitizedThemeFormFields}
          >
            <ScrollContainer>
              <AtticusVerticalTab
                variant="primary-blue"
                items={[
                  {
                    key: "1",
                    label: "Chapter heading",
                    children: <ChapterHeading
                      formFields={sanitizedThemeFormFields}
                      onValuesChange={handleChange}
                    />
                  },
                  {
                    key: "2",
                    label: "Paragraph",
                    children: <ParagraphSetting />
                  },
                  {
                    key: "3",
                    label: "Subheading",
                    children: <HeadingSettings />
                  },
                  {
                    key: "4",
                    label: "Scene break",
                    children: <SceneBreakSettings
                      formFields={sanitizedThemeFormFields}
                      onValuesChange={handleChange}
                    />
                  },
                  {
                    key: "5",
                    label: "Notes",
                    children: <NotesSettings
                      formFields={sanitizedThemeFormFields}
                    />
                  },
                  {
                    key: "6",
                    label: "Print layout",
                    children: <PrintLayoutSettings
                      form={form}
                      onValuesChange={handleChange}
                    />
                  },
                  {
                    key: "7",
                    label: "Typography",
                    children: <TypographySettings
                      formFields={sanitizedThemeFormFields}
                      onValuesChange={handleChange}
                    />
                  },
                  {
                    key: "8",
                    label: "Header/Footer",
                    children: <PageLayoutSettings />
                  },
                  {
                    key: "9",
                    label: "Trim sizes",
                    children: <TrimSizeSettings
                      form={form}
                      formFields={sanitizedThemeFormFields}
                      onValuesChange={handleChange}
                    />
                  },
                ]}
              />
            </ScrollContainer>
            <Modal
              title="Theme name"
              open={isModalOpen}
              okText="Save"
              rightBtn={{
                backgroundColor: "green",
                type: "at-primary",
                loading: saving,
                disabled: !themeName,
                onClick: saveAsNewTheme,
                children: saving ? "Saving" : "Save"
              }}
              leftBtn={{
                backgroundColor: "green",
                type: "at-secondary",
                disabled: saving,
                onClick: () => setIsModalOpen(false),
                children: "Cancel"
              }}
            >
              <Form.Item className="at-form-item" name="name">
                <AtticusTextField placeholder="Theme name" />
              </Form.Item>
            </Modal>
            <FontGalleryModal />
          </Form>
        )}
      </div>
    </div>
  );
};

export default observer(ThemeSection);
