import React from "react";
import {
  getPreventDefaultHandler,
  someNode,
  useEventPlateId,
  usePlateEditorState,
} from "@udecode/plate-core";
import { ToolbarButton, ToolbarButtonProps } from "@udecode/plate-ui-toolbar";

import { reWrapNodes } from "../transforms/reWrapNodes";
import { AlignmentTypes } from "../..";
import { allowFeatureForList } from "../../../config/lists";
import { allowFeatureForTextMessages } from "../../text-message/config";

export interface AlignToolbarButtonProps extends ToolbarButtonProps {
  active?: boolean,
  targetType?: AlignmentTypes;
}

export const AlignToolbarButton = ({
  id,
  targetType,
  active,
  ...props
}: AlignToolbarButtonProps) => {
  const editor = usePlateEditorState(useEventPlateId(id));
  const detachTypes = Object.values(AlignmentTypes);

  const enabled = allowFeatureForList(editor) && allowFeatureForTextMessages(editor);

  return (
    <div className={`${enabled ? "" : "plate-disabled"}`}>
      <ToolbarButton
        active={
          active === undefined
            ? !!editor?.selection &&
              someNode(editor, { match: { type: targetType } })
            : !!active
        }
        onMouseDown={
          editor
            ? enabled
              ? getPreventDefaultHandler(
                  reWrapNodes,
                  editor,
                  detachTypes,
                  targetType
                )
              : undefined
            : undefined
        }
        {...props}
      />
    </div>
  );
};
