import React, { useRef, useState, useEffect } from "react";

/*

  Use this for pages that aren't scrollable and that has a fixed height on top and a scrollable component on the bottom.

*/

interface ScrollContainerProps  {
	className?: string;
}

export const ScrollContainer: React.FC<ScrollContainerProps> = ({ children, className }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [topPosition, setTopPosition] = useState(80);

	useEffect(() => {
		if (containerRef !== null && containerRef.current !== null) {
			const topPosition = containerRef.current.getBoundingClientRect().top;
			setTopPosition(topPosition);
		}
	}, [containerRef.current]);
  
	return (
		<div
			ref={containerRef}
			className={`scroll-container scroller ${className || ""}`}
			style={{ 
				height: `calc(100vh - ${topPosition}px)`,
			}}>
			{children}
		</div>
	);
};