import React, { useState } from "react";
import { Row, Col, Table } from "antd";

import { Button, ButtonType } from "../Shared/Buttons/Button";
import { ArrowDownIcon, ArrowUpIcon, DangerIcon } from "../../content/icons";

import { ChapterErrorsObject, ChapterQuoteStyleRecord } from "./types";
import { getInconsistencyCount } from "./helper";

interface InconsistenciesProps {
  chapters: ChapterErrorsObject[];
  analyzedResults: ChapterQuoteStyleRecord | null;
}
const Inconsistencies: React.FC<InconsistenciesProps> = ({
  chapters,
  analyzedResults,
}) => {
  const columns = [
    {
      title: "Chapter Title",
      dataIndex: "title",
      key: "title",
      width: "200px",
    },
    {
      title: "Type",
      dataIndex: "quotesType",
      key: "quotesType",
      width: "70px",
      render: (_, { quotesType }) => (
          <p className={`quote-${quotesType.toLowerCase()}`}>{quotesType}</p>
      ),
    },
  ];

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  //pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = chapters.slice(startIndex, endIndex);
  const totalPages = Math.ceil(chapters.length / itemsPerPage);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      <Row className="smart-quote-inconsistency-wrapper">
        <Col span={22}>
          <Row align="middle" gutter={8}>
            <Col span={2} className="inconsistencies-warning-icon-wrap">
              <DangerIcon />
            </Col>
            <Col span={12}>
              <h5 className="inconsistency-heading"> Inconsistencies</h5>
            </Col>
            <Col span={3}>
              <div className="inconsistency-count">
                {getInconsistencyCount(analyzedResults)}
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={2}>
          <Row>
            <Button
              className="quotes-pagination-btn"
              type={ButtonType.GHOST}
              icon={
                <ArrowUpIcon
                  color={currentPage === 1 ? "#d1d2d3" : "#00BE95"}
                />
              }
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            />
          </Row>
          <Row>
            <Button
              className="quotes-pagination-btn"
              type={ButtonType.GHOST}
              icon={
                <ArrowDownIcon
                  color={currentPage === totalPages ? "#d1d2d3" : "#00BE95"}
                />
              }
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Row>
        </Col>
      </Row>
        <Row className="inconsistency-table-wrapper scroller">
          <Col span={24}>
            <div>
              <Table
                className="quote-table"
                dataSource={currentItems}
                bordered
                columns={columns}
                size="small"
                pagination={false}
              />
            </div>
          </Col>
        </Row>
    </div>
  );
};

export default Inconsistencies;
