import { Button, notification } from "antd";
import React, { FC, useEffect } from "react";
import * as serviceWorker from "./serviceWorkerRegistration";

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);

  };

  useEffect(() => {
    regist();
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  };

  const regist = async () => {
    const registration = await navigator.serviceWorker.getRegistration(
      `${self.location.origin}`
    );
    if (registration) {
      /*registration.addEventListener("updatefound", () => {
        console.log("Service Worker update detected!!!!", registration);    
          notification.info({
            message: "A New Update is Available!",
            description: (
              <Button style={{ textAlign: "center" }} type="primary">
                Update
              </Button>
            ),
            onClick: () => {
            reloadPage();
            },
            duration: 0,
          });
      });*/
    }
  };

  return <React.Fragment></React.Fragment>;
};

export default ServiceWorkerWrapper;
