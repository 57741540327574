export default class Queue{
    private data: any[] = [];

    enqueue(value: any[]){
        this.data = this.data.concat(value);
        return this.data;
    }

    dequeue(){
        return this.data.splice(0,1)[0];
    }

    dequeueMultiple(size: number){
        return this.data.splice(0,size);
    }

    isEmpty(){
        return this.data.length === 0;
    }

    getSize(){
        return this.data.length;
    }

    getQueue(){
        return this.data;
    }
}