export const wrapSlateListItems = (parent: HTMLElement) => {
    const slateListItems = Array.from(parent.querySelectorAll("li:not(ul li):not(ol li)"))
        .filter((li) => (li as HTMLElement).dataset.slateNode === "element");
    if (slateListItems.length > 0) {
        //check if any list item has a fragment with list(ol or ul) structure
        const hasListStructure = slateListItems.some((li) => {
            const fragment = (li as HTMLElement).dataset.slateFragment;
            if (fragment) {
                try {
                    const decodedFragment = JSON.parse(decodeURIComponent(atob(fragment)));
                    return decodedFragment.some((item: any) => item.type === "ul" || item.type === "ol");
                } catch (error) {
                    console.error("Error decoding fragment:", error);
                    return false;
                }
            }
            return false;
        });

        if (!hasListStructure) {
            let listWrapper: HTMLElement = document.createElement("ul");
            const childNodes = Array.from(parent.childNodes);
            const elementsToRemove = new Set(slateListItems);
            childNodes.forEach((node) => {
                if (elementsToRemove.has(node as Element)) {
                    listWrapper.appendChild(node);
                } else if (listWrapper && listWrapper.childNodes.length > 0) {
                    parent.insertBefore(listWrapper, node);
                    listWrapper = document.createElement("ul");
                }
            });

            if (listWrapper && listWrapper.childNodes.length > 0) {
                parent.appendChild(listWrapper);
            }
        }
    }
};