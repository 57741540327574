import { SpecialTemplate } from "./";

export const Preface: SpecialTemplate = {
  title: "Preface",
  children: [
    {
      type: "p",
      children: [
        {
          text: "A preface is most commonly found in non-fiction books. It’s an opportunity for the author to share a personal story, build credibility as a subject matter expert, or otherwise begin the relationship with each reader.",
        },
      ],
    },
    {
      type: "p",
      children: [
        {
          text: "A preface can also be used to explain why the book was written, or what the author’s connection to the subject matter might be.",
        },
      ],
    },
  ],
  configuration: {
    showChapterImage: false,
    showPageNumber: true,
    showChapterHeading: true,
    showHeaderFooter: true,
    showInTableOfContents: true,
    showFirstSentenceFormatting: false,
  },
};
