import React from "react";

import { BookCardView } from "../Shared/Cards/BookCard";

export const BookListGrid = ({
  items,
  handleButtonClick,
}: {
  items: (IShelfStore.ShelfItem | IShelfStore.ShlefButtonItem)[];
  handleButtonClick?: (buttonType: IShelfStore.ShelfButtonItemType) => void;
}): JSX.Element => {
  return (
    <div className="book-list">
      {items.length > 0 &&
        items.map((item, i) => (
          <BookCardView key={`${item.type}-${i}`} item={item} handleButtonClick={handleButtonClick}/>
        ))}
    </div>
  );
};
