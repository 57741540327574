import React from "react";
import { Dropdown, Menu, Tooltip } from "antd";
import { ToolbarSettingsIcon } from "../../../../../Plate/partials";
import { observer } from "mobx-react";
import useRootStore from "../../../../../../store/useRootStore";
import { Button, ButtonType } from "../../../../../Shared/Buttons";
import { MoreSettingsIcon } from "../../../../../../content/icons";

const HabitTools: React.FC = observer(() => {
  const { isOnline, switchToEditState, deleteHabit } =
    useRootStore().writingHabitStore;

  const menu = (
    <Menu className="goals-setting-menu">
      <Menu.Item key="1" className="goals-menu-item">
        <p onClick={() => switchToEditState()}>Edit Habit</p>
      </Menu.Item>
      <Menu.Item key="2" className="goals-menu-item">
        <p onClick={() => deleteHabit()}>Delete Habit</p>
      </Menu.Item>
    </Menu>
  );

  const OnlineOption = () => {
    return (
      <Dropdown overlay={menu} placement="bottomRight">
        <Button
                type={ButtonType.GHOST}
                className="edit-goal-icon-btn"
                icon={<MoreSettingsIcon />}
              ></Button>
      </Dropdown>
    );
  };

  const OfflineOption = () => {
    return (
      <Tooltip
        placement="right"
        title={
          <span>
            You must be connected to the internet to view habit settings.
          </span>
        }
      >
        <button
          className="edit-goal-icon-btn"
          style={{ cursor: "not-allowed" }}
        >
          <ToolbarSettingsIcon />
        </button>
      </Tooltip>
    );
  };

  return isOnline ? <OnlineOption /> : <OfflineOption />;
});

export { HabitTools };
