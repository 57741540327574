import React, { useCallback, useEffect } from "react";
import { debounce } from "lodash";

import useRootStore from "../../../store/useRootStore";
import { AtticusSearchField } from "../../Shared/Form/Search";

interface FontSearchProps {
  onSearch: (term: string) => any
}

export const FontSearch = ({ onSearch }: FontSearchProps) => {
  const { showFontGallery } = useRootStore().fontStore;
  useEffect(() => {
    onSearch("");
  }, [!showFontGallery]);

  const debouncedSearch = useCallback(
    debounce(nextValue => onSearch(nextValue), 1000),
    [], // will be created only once initially
  );

  const handleSearch = event => {
    event.persist();
    const { value: nextValue } = event.target;
    // Even though handleSearch is created on each render and executed
    // it references the same debouncedSearch that was created initially
    debouncedSearch(nextValue);
  };

  return (
    <>
      <AtticusSearchField
        width={"320px"}
        placeholder="Search any font"
        size="large"
        allowClear
        onChange={(e) => handleSearch(e)}
      />
    </>
  );
};
