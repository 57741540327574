import {
  UsedFont,
  themePropsToCss,
} from "@surge-global-engineering/css-generator";
import { Font, Options } from "@surge-global-engineering/epub-gen-browser";

import { exportEpubWorkerApi } from "../workers/epub-generator-worker-api";
import { PdfChapterEndnotes, PdfEndnoteSubheading, PdfSlateEndnote } from "../components/Previewer/print/types";

import { tocXhtml } from "./templates/toc.xhtml";
import { tocNcx } from "./templates/toc.ncx";
import { contentOpf } from "./templates/content.opf";
import { getStartPageId } from "./exportEpub/helpers";
import { Compiler } from "./compile";

export const exportEpubBook = async (
  book : IBookStore.Book,
  theme : IThemeStore.Theme,
  endnotes : (PdfSlateEndnote | PdfEndnoteSubheading)[] | PdfChapterEndnotes[],
  fontFiles: Font[],
  usedFonts: UsedFont[],
): Promise<Buffer> => {
  const { epubContent, showTOC, tocContent, tocTitle } = await Compiler(
    book,
    theme,
    endnotes,
  );
  const allChapters = (book.chapters ?? []).concat(book.frontMatter ?? []);
  const options: Options = {
    title: book.title,
    author: book.author.join(", "),
    publisher: book.publisherName ? book.publisherName : "",
    cover: book.coverImageUrl,
    contentOPF: contentOpf,
    tocNCX: tocNcx,
    tocXHTML: tocXhtml,
    tocTitle,
    isTOC: showTOC,
    startPage:
      book.startPage &&
      getStartPageId(allChapters, book.startPage),
    fonts: fontFiles,
    version: 3,
    toc: tocContent,
    css: themePropsToCss(theme, usedFonts),
    themeId: theme._id,
    prependChapterTitles: false,
  };

  return exportEpubWorkerApi.exportEpub(options, epubContent);
};
