import { Button, ButtonProps } from "antd";
import React from "react";

export const AddCardButton: React.FC<ButtonProps> = (props) => {
  return (
    <>
      <Button
        {...{
          ...props,
          type: "primary",
          className: `at-add-card-btn at-btn-card ${props.className || ""}`
        }}
      >
        {props.children}
      </Button>
    </>
  );
};
