import React from "react";
import useRootStore from "../../../../../store/useRootStore";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { HabitStreakItem } from "./HabitStreakItem";

const HabitStreaks: React.FC = observer(() => {
  const { writingHabitState } = useRootStore().writingHabitStore;

  return (
    writingHabitState && (
      <Row className="writing-habit-streaks-container">
        <Col flex="auto">
          <HabitStreakItem
            dayCount={writingHabitState.currentStreak}
            label={"Current streak"}
          />
        </Col>
        <Col flex="auto" offset={1}>
          <HabitStreakItem
            dayCount={writingHabitState.longestStreak}
            label={"Longest streak"}
          />
        </Col>
      </Row>
    )
  );
});

export { HabitStreaks };
