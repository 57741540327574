import { createPluginFactory } from "@udecode/plate-core";

export const FEATURE_SPLIT_CHAPTER = "split-chapter";

/**
 * Enables support for Split Chapter.
 */
export const createSplitChapterPlugin = createPluginFactory({
  key: FEATURE_SPLIT_CHAPTER,
  isVoid: true,
});
