import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Row, Tooltip, List, notification } from "antd";
import { debounce } from "lodash";

import { FontItem } from "./partial";
import { FavFilledIcon, FavOutlinedIcon } from "../../content/icons";
import { Button } from "../Shared/Buttons";

import useRootStore from "../../store/useRootStore";

const GoogleListing = observer(() => {
  const [handleScrollCalled, setHandleScrollCalled] = useState(false);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const pageSize = 60;
  const { googleFonts, googleFavoritedFonts, loading, toggleLoading, doFontSearch, removeFromFavorites, addToFavorites, setPage, page, googleFontCount, googleFavFnts } = useRootStore().fontStore;
  
  const doFontUpdate = (query: Partial<IFontStore.FontQuery>) => {
    toggleLoading(true);
    doFontSearch(query).then(({totalCount}) => {
      toggleLoading(false);
    });
  };

  const handleRemoveFavorites = async (font: IFontStore.FontItem) => {
    try {
      toggleLoading(true);
      await removeFromFavorites(font._id);
      toggleLoading(false);
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const handleAddtoFavorites = async (font: IFontStore.FontItem) => {
    try {
      const gFavFnts = googleFavFnts.length;
      if(gFavFnts < 61) {
        toggleLoading(true);
        await addToFavorites(font);
        toggleLoading(false);
      } else {
        notification.error({
          message: "Favorite fonts limit exceeded",
          description: "Cannot add more than 60 fonts to favorites.",
        });
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };

  useEffect(() => {
    doFontUpdate({});
  }, []);

  useEffect(() => {
    if(handleScrollCalled) {
      doFontUpdate({ page: page, count: pageSize });
      setHandleScrollCalled(false);
    }
  }, [page, handleScrollCalled]);

  useEffect(() => {
    if (page === 0 && scrollerRef.current) {
      scrollerRef.current.scrollTop = 0;
    }
  }, [page, googleFonts]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>, roundedFontCount: number) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    const bottomThreshold = 1;
    const bottom = scrollTop + clientHeight + bottomThreshold >= scrollHeight;
    const pageCount = roundedFontCount - 1;

    if (bottom && page !== roundedFontCount && page < pageCount) {
      setPage(page + 1);
      setHandleScrollCalled(true);
    }
  };

  const debouncedHandleScroll = debounce((e: React.UIEvent<HTMLDivElement>) => {
    const roundedFontCount = Math.ceil(googleFontCount / 60);
    handleScroll({
      ...e,
      currentTarget: scrollerRef.current as HTMLDivElement
    }, roundedFontCount);
  }, 200);

  return (
    <>
      <div className="font-gallery-tab-wrapper" >
        <div ref={scrollerRef} className="font-gallery-tab-inner scroller" onScroll={debouncedHandleScroll}>
          <Row>
            <div className="font-tabs">
              <List
                grid={{ column: 3 }}
                dataSource={googleFonts}
                loading={loading}
                itemLayout="horizontal"
                locale={{
                  emptyText: "No Fonts to show yet"
                }}
                className="font-listing-wrapper"
                renderItem={(item) => (
                  <FontItem
                    key={item._id}
                    font={item}
                    actionItem={
                      googleFavoritedFonts.map(f => f._id).includes(item._id) ? (
                        <Tooltip title="Remove from favorites">
                          <Button
                            type="at-ghost"
                            className="font-fav-btn"
                            icon={<FavFilledIcon />}
                            onClick={() => handleRemoveFavorites(item)}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Add to favorites">
                          <Button
                            type="at-ghost"
                            className="font-fav-btn"
                            icon={<FavOutlinedIcon />}
                            onClick={() => handleAddtoFavorites(item)}
                          />
                        </Tooltip>
                      )
                    }
                  />
                )}
              />
            </div>
          </Row>
        </div>
      </div>
    </>
  );
});

export default GoogleListing;