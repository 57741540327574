import React from "react";
import { useState, FunctionComponent } from "react";

import { checkHttps } from "../transforms";

import {  Form, FormItemProps } from "antd";
import { Modal } from "../../../../Shared/Modal";
import { AtticusTextField } from "../../../../Shared/Form";
import { Button, ButtonSize, ButtonType } from "../../../../Shared/Buttons";
import { ImageLinkComponent } from "./imageLink";
import { ImageSizeComponent } from "./ImageSize";
import { ImageFlowComponent } from "./ImageFlow";

import {
  ImageNode,
  ImageSize,
  ImageLink,
  ImageFlow,
} from "../../../config/typescript";

interface EditImageDialogProps {
  element: ImageNode;
  onClose: () => void;
  onUpdate: (update: ImageNode) => void;
}

const formItemProperties: FormItemProps = {
  colon: false,
  labelCol: { span: 24 }
};

export const EditImage: FunctionComponent<EditImageDialogProps> = ({
  element,
  onClose,
  onUpdate,
}: EditImageDialogProps) => {
  const [caption, setCaption] = useState<string>(element.caption);
  const [flow, setFlow] = useState(element.flow);
  const [wrap, setWrap] = useState(element.wrap);
  const [size, setSize] = useState(element.size);
  const [separatePage, setSeparatePage] = useState<boolean>(
    !!element.separatePage
  );
  const [imageLink, setImageLink] = useState<ImageLink | undefined>(
    element.link
  );

  const disableWrap =
    (typeof size === "number" && size > 50) || size === "large";

  const onSubmit = async () => {
    if (imageLink && imageLink.webLink && !checkHttps(imageLink.webLink)) {
      (document.getElementById("errorText") as HTMLSpanElement).textContent =
        "Should be a valid link starting with https:// or mailto:";
    } else {
      onUpdate({
        ...element,
        size,
        flow,
        caption,
        link: imageLink,
        wrap: disableWrap ? false : wrap,
        separatePage,
      });
      onClose();
    }
  };

  const handleImageFlowChange = (
    flowValue?: ImageFlow,
    wrapValue?: boolean
  ) => {
    if (flowValue !== undefined) setFlow(flowValue);
    if (wrapValue !== undefined) setWrap(disableWrap ? false : wrapValue);
  };

  const handleImageSizeChange = (value: ImageSize) => {
    setSize(value);
    setWrap(disableWrap ? false : wrap);
  };

  const handleSeparatePageChange = (
    separateValue: boolean | undefined | null
  ) => {
    if (separateValue !== null && separateValue !== undefined)
      setSeparatePage(separateValue);
  };

  return (
    <>
      <Modal
        open={true}
        title="Edit image"
        onCancel={() => onClose()}
        centered={true}
        bodyStyle={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        wrapClassName="image-toolbar-modal-wrap"
      >
        <div className="image-toolbar-body-wrapper">
          <Form className="image-toolbar-ant-form">
              <>
                <Form.Item
                  label={<span className="edit-image-form-label">Caption</span>}
                  className="at-form-item"
                  {...formItemProperties}
                >
                  <AtticusTextField
                    value={caption}
                    onChange={(e) => {
                      setCaption(e.target.value);
                    }}
                    placeholder="Image caption"
                    inputType="secondary"
                  />
                </Form.Item>
                <ImageFlowComponent
                  flowValue={flow}
                  wrapValue={wrap}
                  disableWrap={disableWrap}
                  flowChange={handleImageFlowChange}
                  separatePageValue={separatePage}
                  separatePageChange={handleSeparatePageChange}
                />
                <ImageSizeComponent
                  size={size}
                  onSizeChange={handleImageSizeChange}
                />
                <ImageLinkComponent link={imageLink} setLink={setImageLink} />
                <Button
                  type={ButtonType.PRIMARY}
                  size={ButtonSize.MEDIUM}
                  backgroundColor="green"
                  onMouseDown={() => onSubmit()}
                  fullWidth
                >
                  Update Image
                </Button>
              </>
          </Form>
        </div>
      </Modal>
    </>
  );
};
