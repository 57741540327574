import { Remote, wrap, proxy } from "comlink";
import { useEffect, useState } from "react";
import { CallbackFnType, TaskExecutorApi, TaskType } from "./types";

// This is the interface that is exposed to be used in the UI thread
// and has a different signature from the Task used by the web worker
export interface Task<T = unknown, M = unknown> {
  taskId: string | null;
  type: TaskType;
  status: "pending" | Exclude<CallbackFnType, "dropped">;
  data: T | null;
  error: Error | null;
  createdAt: Date;
  metadata?: M;
}

let taskExecutorWorkerInstance: Worker | null = null;
let taskExecutorWorker: Remote<TaskExecutorApi> | null = null;
let taskQueue: ReturnType<typeof createTaskQueueStore>;


const createTaskQueueStore = () => {
  let tasks: Task[] = []; //
  const listeners = new Set<() => void>();
  const queuedDrops = new Set<Task>();

  const getTasks = () => [...tasks];

  const notifyAll = () => {
    listeners.forEach((l) => l());
  };

  const subscribe = (listener) => {
    listeners.add(listener);
    return () => {
      listeners.delete(listener);
    };
  };

  const queueTask = async (
    type: TaskType,
    parameters: unknown,
    metadata?: unknown
  ) => {
    // add a task with pending state
    const task: Task = {
      taskId: null,
      type,
      status: "pending",
      data: null,
      error: null,
      createdAt: new Date(),
      metadata,
    };

    // add the pending task
    tasks.push(task);
    notifyAll();

    const taskId = await getWorker().queueTask(
      type,
      parameters,
      proxy(
        (
          type: CallbackFnType,
          _taskId: string,
          message?: string,
          data?: unknown
        ) => {
          task.taskId = _taskId || null;
          switch (type) {
            case "queued":
            case "executing":
            case "status":
            case "completed":
              task.status = type;
              task.data = data;
              break;
            case "dropped":
              // Drop the item from the array
              tasks = tasks.filter((t) => t !== task);
              break;
            case "error":
              task.data = null;
              task.error = new Error(message);
              break;
          }
          notifyAll();
        }
      )
    );

    task.taskId = taskId;
    notifyAll();
  };

  const dropTask = (task: Task) => {
    // if the drop has been already queued, prevent further attempts to drop
    if (queuedDrops.has(task)) {
      return Promise.resolve();
    }
    queuedDrops.add(task);

    return new Promise<void>((resolve) => {
      // helper function to reduce repetition
      const wrapTheJob = () => {
        tasks = tasks.filter((t) => t !== task);
        queuedDrops.delete(task);
        notifyAll();
        resolve();
      };

      if (task.taskId !== null) {
        // There is a task id, so we can ask the web worker to drop the task
        getWorker()
          .dropTask(task.taskId)
          .catch((error) => console.error(error))
          .finally(() => wrapTheJob());
      } else {
        if (task.status === "pending") {
          // The task does not have a taskId yet because it's still waiting to be queued
          // Therefore we wait until the task has been queued, and then drop it
          const unsubscribe = subscribe(async () => {
            if (task.status === "pending") return;
            // Task has moved up from the pending state. Therefore we can process the deletion
            try {
              if (task.taskId !== null) {
                await getWorker().dropTask(task.taskId);
              }
            } catch (error) {
              // prevent further propagation of the error
              console.error(error);
            } finally {
              unsubscribe();
              wrapTheJob();
            }
          });
        } else {
          // no need to wait, just drop the task and notify all
          wrapTheJob();
        }
      }
    });
  };

  const dropAll = async () => {
    const dropCalls = tasks.map((task) => dropTask(task));
    await Promise.all(dropCalls);
  };

  const forceReset = () => {
    resetWorker();
    tasks = [];
    notifyAll();
  };

  return {
    getTasks,
    subscribe,
    queueTask,
    dropTask,
    dropAll,
    forceReset,
  };
};

const initiateWorker = (): void => {
  taskExecutorWorkerInstance = new Worker("./task-executor.worker", {
    type: "module",
  });
  taskExecutorWorker = wrap<TaskExecutorApi>(taskExecutorWorkerInstance);
};

const terminateWorker = (): void => {
  if (taskExecutorWorkerInstance) {
    taskExecutorWorkerInstance.terminate();
  }
  taskExecutorWorkerInstance = null;
  taskExecutorWorker = null;
};

const resetWorker = (): void => {
  terminateWorker();
  initiateWorker();
};

const getWorker = () => {
  if (taskExecutorWorker === null) {
    throw new Error("Task Executor Worker has not been registered! Please ");
  }
  return taskExecutorWorker;
};

export const register = (): void => {
  initiateWorker();
  taskQueue = createTaskQueueStore();
};

export const useTaskQueueAPI = <T, M>() => {
  const [state, setState] = useState(
    () => taskQueue.getTasks() as Task<T, M>[]
  );

  useEffect(() => {
    const callback = () => setState(taskQueue.getTasks() as Task<T, M>[]);
    const unsubscribe = taskQueue.subscribe(callback);
    return unsubscribe;
  }, []);

  return {
    currentTasks: state,
    queueTask: taskQueue.queueTask,
    dropTask: taskQueue.dropTask,
    dropAllTasks: taskQueue.dropAll,
    forceReset: taskQueue.forceReset,
  };
};
