import React from "react";
import { Button as ButtonAntd, ButtonProps } from "antd";

export enum ButtonType {
  PRIMARY = "at-primary",
  SECONDARY = "at-secondary",
  GHOST = "at-ghost",
}

export enum BackgroundColor {
  GREEN = "green",
  BLUE = "blue",
}

export enum IconPosition {
  LEFT = "left",
  RIGHT = "right",
}

export enum ButtonSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  XLARGE = "xlarge",
  ICON_ONLY = "icon-only"
}

export interface AtticusButtonProps extends Omit<ButtonProps, "type" | "size"> {
  type: "at-primary" | "at-secondary" | "at-ghost";
  backgroundColor?: "green" | "blue" | "white";
  size?: "small" | "medium" | "large" | "xlarge" | "icon-only";
  fullWidth?: boolean;
}

export const Button: React.FC<AtticusButtonProps> = (props) => {
  const getButtonClassName = () => {
    const defaultClassName = `atticus-button ${props.type} ${
      props.size || ButtonSize.MEDIUM
    } ${props.fullWidth ? "full-with" : ""} ${props.className || ""}`;

    if (props.disabled) {
      return `${defaultClassName} btn-disabled`;
    }

    if (!props.danger && !props.disabled) {
      return `${defaultClassName} ${
        props.backgroundColor || BackgroundColor.BLUE
      }`;
    }

    return defaultClassName;
  };

  return (
    <ButtonAntd
      {...{
        ...props,
        size: "middle",
        className: getButtonClassName(),
        type: props.type === ButtonType.GHOST ? "link" : undefined,
      }}
    >
      {props.children && (
        <div className="atticus-button-text">{props.children}</div>
      )}
    </ButtonAntd>
  );
};
