import React from "react";
import { observer } from "mobx-react";
import { HeadingSetting } from "./partial";

const HeadingSettings = (): JSX.Element => {
  return (
    <div className="theme-section-tab-content">
      <HeadingSetting heading={"Heading 2"} headingType={"h2"} />
      <HeadingSetting heading={"Heading 3"} headingType={"h3"} />
      <HeadingSetting heading={"Heading 4"} headingType={"h4"} />
      <HeadingSetting heading={"Heading 5"} headingType={"h5"} />
      <HeadingSetting heading={"Heading 6"} headingType={"h6"} />
    </div>
  );
};

export default observer(HeadingSettings);
