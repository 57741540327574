import React, { useState } from "react";
import { Row, Col } from "antd";
import { BlockquoteModalProps, BlockquoteNode } from "../types";
import { Modal } from "../../../../Shared/Modal";
import { AtticusTextField } from "../../../../Shared/Form";
import { Button } from "../../../../Shared/Buttons";

export const BlockquoteModal = ({
  element,
  onUpdate,
  onClose,
}: BlockquoteModalProps) => {
  const [value, setValue] = useState(element.quotee);

  const onSubmit = () => {
    onUpdate({
      element: element,
      quotee: value,
    });
    onClose();
  };

  return (
    <Modal
      visible={true}
      onCancel={() => {
        onClose();
      }}
      cancelText='Cancel'
      title='Add attribution'
    >
      <div className='blockquote-modal-content'>
        <Row align='middle' gutter={[16, 16]}>
          <Col>
            <label htmlFor='quotee'>Author</label>
          </Col>
          <Col flex={1}>
            <AtticusTextField
              width={100}
              name='quotee'
              value={value}
              placeholder='Enter author'
              onChange={(e) => setValue(e.target.value)}
            />
          </Col>
        </Row>
        <Row className='blockqute-btn-div'>
          <Button
            type='at-primary'
            className='apply-bquotee-btn'
            backgroundColor='green'
            onMouseDown={onSubmit}
            block
          >
            Update blockquote
          </Button>
        </Row>
      </div>
    </Modal>
  );
};
