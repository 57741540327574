import React, { FunctionComponent, useEffect, useState } from "react";
import { Formik, FormikErrors } from "formik";
import { Button, Form, Input, FormItemProps } from "antd";
import { v4 as uuidv4 } from "uuid";
import { generate as generateRandomString } from "randomstring";

import SelectAuthor from "./select-author";
import SelectProject from "./select-project";
import { Modal } from "../../Shared/Modal";
import { AtticusTextField } from "../../Shared/Form";

const commonFormItemProps = {
	labelAlign: "left",
	colon: false,
} as FormItemProps;

interface NewBookModalProps {
  showModal: boolean,
  onNewBook: (params: IShelfStore.BookForm) => Promise<unknown>;
  onClose: () => void;
}

export const NewBookModal: FunctionComponent<NewBookModalProps> = ({ showModal, onNewBook, onClose }: NewBookModalProps) => {
	const [shouldResetSelectAuthor, setShouldResetSelectAuthor] = useState(false);
	useEffect(() => {
		if(showModal) {
			setShouldResetSelectAuthor(false);
		}
	}, [showModal]);

	const onCancel = (resetForm: () => void, onClose: () => void) => {
		setShouldResetSelectAuthor(true);
		resetForm();
		onClose();
	};

	return (
		<>
			<Formik
				initialValues={{ _id: uuidv4(), chapterId: generateRandomString(16), title: "", author: [], project: "" } as IShelfStore.BookForm}
				validate={() => {
					const errors: FormikErrors<IShelfStore.BookForm> = {};
					return errors;
				}}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					await onNewBook(values);
					onClose();
					setSubmitting(false);
				}}
			>
				{({
					values,
					handleChange,
					submitForm,
					setFieldValue,
					isSubmitting,
					resetForm
				}) => (
					<Modal
						maskClosable={false}
						title={"Let's start a new journey"}
						open={showModal}
						style={{
							borderRadius: 10,
						}}
						onCancel={() => onCancel(resetForm, onClose)}
            rightBtn={{
              type: "at-primary",
              size: "medium",
              backgroundColor: "green",
              children: "Go get em Atticus!",
              loading: isSubmitting,
              onMouseDown: submitForm 
            }}
					>
						<Form layout="vertical">
							<div>
								<Form.Item label="Title" {...commonFormItemProps} className="ant-input-extended">
									<AtticusTextField placeholder="Title" name="title" onChange={handleChange} value={values.title} size="large" />
								</Form.Item>
								<Form.Item name="author" label="Author" {...commonFormItemProps} className="ant-input-extended ant-select-extended">
									<SelectAuthor placeholderText={"Author"} onSelect={v => setFieldValue("author", v)} value={values.author} resetField={shouldResetSelectAuthor}/>
								</Form.Item>
								<Form.Item name="project" label="Project" {...commonFormItemProps} className="ant-input-extended ant-select-extended">
									<SelectProject placeholderText={"Project Name"} onSelect={v => setFieldValue("project", v)} value={values.project} resetField={shouldResetSelectAuthor} />
								</Form.Item>
							</div>
						</Form>
					</Modal>
				)}
			</Formik>
		</>
	);
};