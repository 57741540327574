import React, { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import { generate } from "randomstring";
import { getExtension } from "mime";
import { v4 } from "uuid";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";

import { Upload, notification, message } from "antd";
const { Dragger } = Upload;

import { AtticusClient } from "../../../api/atticus.api";
import { useOnlineStatus } from "../../../utils/isOffline";
import { getUploadPathByExtension, compressFile, compressedImageFolder, hasCompressedImageSFX } from "../../../utils/file";
import { RcFile } from "antd/lib/upload";

interface onSuccessType {
  (event: { percent: number }): void
}

interface CastedFile  { name: string, type: string }

interface FileUploadProps {
  fileType: string;
  folder?: string;
  children?: any;
  componentWidth?: number;
  background?: string;
  shape?: "square" | "rect" | "none";
  onFileUpload: (url: string | null, type?: string) => void;
  onFileUploadStart?: () => void;
  onFileUploadEnd?: () => void;
  isImageDeleted?:boolean;
  styleOverrides?: CSSProperties;
  resetKey?: string;
  isProfileImage?:boolean;
  updateLoadingState?: (newLoadingState:boolean) => void;
}

const FileUpload: FunctionComponent<FileUploadProps> = ({ shape, onFileUpload, onFileUploadStart, onFileUploadEnd, fileType, folder, children, background, componentWidth,isImageDeleted, styleOverrides, resetKey, isProfileImage, updateLoadingState }: FileUploadProps) => {
  const isOnline = useOnlineStatus();
  let errorMessage = "Error uploading selected file";


  const addImgToGallery = async (id, link, igFolder) => {
    const profileResponse = await AtticusClient.GetProfile();
    const IDBImgG = {
      userId: profileResponse._id,
      _imgId: id,
      link: link,
      folderName: igFolder,
      date: new Date()
    };
    await AtticusClient.AddImgToGallery(IDBImgG);
  };

/**
 * @function getExtensionLocal
 * @param {CastedFile} file name and type from the uploaded file
 * @returns {string | null} returns "rtf" if the extenstionFromFilename is rtf (AT-1309) or returns the extenstion from mime=>getExtension()
 */
  const getExtensionLocal = (file:CastedFile): string | null => {
    const extenstionFromFilename = file.name.split(".").pop();
    if (extenstionFromFilename === "rtf") {
      return "rtf";
    } 

    return getExtension(file.type);
  };

  const customRequest = async (options: RcCustomRequestOptions) => {
    const { onSuccess, onError } = options;
    if(!options.file) {
      notification.error({message: "Error fetching file"});
      return;
    }
    if(!isOnline){
      notification.warning({message: "Can not upload content without an active internet connection"});
      return;
    }
    try{
      const file: CastedFile = options.file as CastedFile;
     
      if(onFileUploadStart) onFileUploadStart();
  
      const fileExtension = getExtensionLocal(file);
      /** Remove white spaces and generate a unique fixed length file name */
      const sanitizedFileName = file.name.replace(/[\W_]+/g, "_").slice(0,6) + generate(12);
      const fileNameWithExtension = `${sanitizedFileName}.${fileExtension}`;
      /** Folder path in s3 for the file to be uploaded */
      const s3FolderName = folder || getUploadPathByExtension(fileExtension);
  
      let fileUploadPayload = {
        fileBinary: options.file as Blob,
        path: `${s3FolderName}/`,
        fileName: fileNameWithExtension,
        contentType: file.type,
        fileId: v4()
      };

      if(fileType.includes("image")){
        //check if the image type is a allowed image type
        const allowedImageTypes = ["image/png", "image/jpeg", "image/jpg"];
        if(!allowedImageTypes.includes(file.type)){
          errorMessage = "This file type is not allowed for image uploads.";
          throw new Error("Unsupported Image Type Upload");
        }
      }
  
      if(file.type.includes("image")){
        /** Generate a compressed counterpart of the image to be used in exports */
        const compressedImage = await compressFile(options.file as Blob, 0.6);
        /** 
         * Add _ce suffix to the file name to identify images with compressed counter parts
         * Uncompressed images with _ce indicated that it has a compressed counterpart in /compressed-images/ directory
         */
        const compressedImageFileName = sanitizedFileName.split(".")[0] + `${hasCompressedImageSFX}.${fileExtension}`;
        const compressedPayload = {
          ...fileUploadPayload,
          fileBinary: compressedImage,
          path: `${compressedImageFolder}/`,
          fileName: compressedImageFileName,
        };
        await AtticusClient.UploadFile([compressedPayload]);
        /** Append _ce to original image file name to indicate that it has a compressed counterpart */
        fileUploadPayload = {...fileUploadPayload, fileName: compressedImageFileName};
      }
  
      const s3UrlMap = await AtticusClient.UploadFile([fileUploadPayload]);
      const hostedUrl = s3UrlMap.get(fileUploadPayload.fileId);
  
      if(file.type.includes("image")){
        await addImgToGallery(fileUploadPayload.fileName, hostedUrl, s3FolderName);
      }
      if(onSuccess) (onSuccess as onSuccessType)({ percent: 100});
      onFileUpload(hostedUrl!, fileExtension!);
      if(onFileUploadEnd) onFileUploadEnd();
    }catch(error){
      notification.error({message: errorMessage});
      if(onError) onError(error as Error);
      if(onFileUploadEnd) onFileUploadEnd();
  }
};

const beforeUpload = (file:RcFile) => {
  const allowedImageTypes = ["image/png", "image/jpeg", "image/jpg"];
  const maxSize = 10 * 1024 * 1024;

  if (file.size > maxSize && allowedImageTypes.includes(file.type)) {
    message.error("File size must be within 10MB");
    return false;
  }
  return true; 
};

  const uploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: fileType,
    onChange: (info: { fileList: Array<unknown> }) => {
      isProfileImage && updateLoadingState?.(true);
      if (info.fileList.length === 0) onFileUpload(null);
    },

    showUploadList:isImageDeleted || isProfileImage ? false:{showRemoveIcon:false}
  };

  return shape === "none" ? (
    <Upload
      {...uploadProps}
      customRequest={customRequest}
      showUploadList={false}
      key={resetKey}
    >
      {children}
    </Upload>
  ) : (
    <Dragger
      {...uploadProps}
      customRequest={customRequest}
      beforeUpload={beforeUpload}
      //fileList
      style={{
        border:0,
        borderRadius: 10,
        padding: shape === "square" ? "0.8rem" : "0.6rem 1rem",
        height: shape === "square" ? 60 : undefined,
        backgroundColor: background,
        width: `${componentWidth} px`,
        ...styleOverrides
      }}
      key={resetKey}
      >
      {children}
    </Dragger>
  );
};

export default FileUpload;