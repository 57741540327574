import React from "react";
import { Slider, SliderSingleProps } from "antd";

interface AtticusSliderProps extends SliderSingleProps{
  color?: "green"
}

export const AtticusSlider: React.FC<AtticusSliderProps> = (props) => {

  return (
    <Slider
      {...{
        ...props,
        className: `at-slider ${props.color ? "at-slider-green" : ""} ${props.className || ""}`
      }}
    />
  );
};
