import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect } from "react";
import useRootStore from "../../../../../store/useRootStore";
import { WritingHabitDateRecord } from "../../types";

const WritingHabitWordCounter: React.FC = observer(() => {
  const { body, book, getFullBookWordCount } = useRootStore().bookStore;
  const {
    writingHabitState,
    applyBookChangeRelatedChanges,
    setWritingHabitState,
    stateLock,
    setStateLock,
    section,
  } = useRootStore().writingHabitStore;

  useEffect(() => {
    const wordCountSyncLoop = setInterval(async () => {
      if (!writingHabitState || section === "edit") return;

      let habit = writingHabitState;

      const wordCount = await getFullBookWordCount();
      const bookId = book._id;

      if (bookId !== writingHabitState.currentBookId) {
        habit = applyBookChangeRelatedChanges(wordCount, bookId);
      }

      const currentWordCount = wordCount - habit.preWordCount;

      if (stateLock) {
        return;
      }

      setStateLock(true);

      const yesterday = moment().startOf("day").subtract(1, "day");
      let latestRecord: WritingHabitDateRecord | undefined;

      habit.dateRecords.forEach((record, i) => {
        if (typeof latestRecord === "undefined") {
          latestRecord = record;
        }

        const recordDate = moment(record.date).startOf("day");
        const latestRecordDate = moment(latestRecord.date).startOf("day");

        if (recordDate.isAfter(latestRecordDate, "day")) {
          latestRecord = record;
        }
      });

      let alreadyExists = false;

      const newHabitRecord: WritingHabitDateRecord[] = [];

      if (typeof latestRecord !== "undefined") {
        const latestRecordDate = moment(latestRecord.date).startOf("day");

        if (yesterday.isSameOrBefore(latestRecordDate, "day")) {
          alreadyExists = true;
        }
      }

      const createdAt = moment(habit.createdAt);
      const now = moment();

      if (!alreadyExists && !now.isSame(createdAt, "day")) {
        newHabitRecord.push({
          date: yesterday.clone().format("YYYY-MM-DD"),
          writtenCount: habit.writtenCount + habit.preBookWordCount,
          goal: habit.goalCount,
          isCommittedDate: habit.writeDays.includes(yesterday.day()),
        });

        habit.preWordCount = habit.preWordCount + habit.writtenCount;
        habit.writtenCount = 0;
        habit.preBookWordCount = 0;
      }

      let currentStreak = 0;

      // Get all records and sort by descending order of date (latest first)
      const sortedByDates = [...habit.dateRecords, ...newHabitRecord].sort(
        ({ date: dateX }, { date: dateY }) =>
          new Date(dateY).getTime() - new Date(dateX).getTime()
      );

      for (let index = 0; index < sortedByDates.length; index++) {
        const record = sortedByDates[index];

        if (record.writtenCount >= record.goal) {
          currentStreak++;
          continue;
        }

        if (!record.isCommittedDate) {
          continue;
        }

        break;
      }

      setWritingHabitState({
        ...habit,
        dateRecords: sortedByDates.reverse(),
        writtenCount: currentWordCount,
        currentStreak,
        longestStreak:
          habit.longestStreak > currentStreak
            ? habit.longestStreak
            : currentStreak,
        snowFireStreak:
          currentWordCount + habit.preBookWordCount >= habit.goalCount
            ? "fire"
            : "snow",
      });
      setStateLock(false);
    }, 2000);

    return () => clearInterval(wordCountSyncLoop);
  }, [body, stateLock]);

  return null;
});

export { WritingHabitWordCounter };
