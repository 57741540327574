import { SpecialTemplate } from "./";

export const Foreword: SpecialTemplate = {
  title: "Foreword",
  children: [
    {
      type: "p",
      children: [
        {
          text: "A foreword is a section of a book written by someone other than the author, often to provide context on the book itself or to share unique insight. It is much more common in non-fiction books, and frequently written by a subject matter authority.",
        },
      ],
    },
    {
      type: "p",
      children: [
        {
          text: "Forewords are also common if the book is being published post-mortem or if it is an improved or expanded upon edition.",
        },
      ],
    },
    {
      type: "p",
      children: [
        {
          text: "If your book includes both a foreword and a preface, the foreword should come first.",
        },
      ],
    },
  ],
  configuration: {
    showChapterImage: false,
    showPageNumber: true,
    showChapterHeading: true,
    showHeaderFooter: true,
    showInTableOfContents: true,
    showFirstSentenceFormatting: false,
  },
};
