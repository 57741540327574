import React, { FunctionComponent, useState, useEffect } from "react";
import { Form, Checkbox, Popover } from "antd";
import {Button} from "../Shared/Buttons";

// Icons
import useRootStore from "../../store/useRootStore";

import { db } from "../../db/bookDb";

import SprintOnly from "./SprintOnly";
import SprintWithBreak from "./SprintBreak";

import "./sprint.scss";
import { CloseIconWithoutBackground, TimerIcon } from "../../content/icons";
import { AtticusNumberField } from "../Shared/Form/InputNumber";

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 24 },
};

const SprintTimer: FunctionComponent = () => {
    const { getCurrentBookId } = useRootStore().bookStore;
    const [form] = Form.useForm();

    const [sprintValues, setSprintValues] = React.useState<ISprintTimerStore.SprintTimer | null>();
    const [show, toggleShow] = useState(false);
    const [showBreak, setShowBreak] = useState(false);
    const [cancel, setSprintCancel] = useState(false);


    const bkkId = getCurrentBookId();
    const statWritingTime = sprintValues?.statTime ? sprintValues?.statTime/60000 : null;
    const statBreakingTime = sprintValues?.statBreakTime ? sprintValues?.statBreakTime/60000 : null;

    const setToggleViews = () => {
        toggleShow(true);
    };


    const getSprintData = async () => {
        const spVal = await db.sprintTimer.where("_bookId").equals(bkkId).first();
        if (spVal) {
            setSprintValues(spVal);
        }
    };

    const onCancel = async () => {
        toggleShow(false);
    };

    const handleCancel = async () => {
        setSprintCancel(true);
    };

    const sprintTimeSubmit = async (vals) => {

        if(showBreak) {

            const tData = {
                _bookId: bkkId,
                writingTime: (vals.writingTime * 60000),
                statTime: (vals.writingTime * 60000),
                breakTime: !vals.breakTime ? 0 : (vals.breakTime * 60000),
                statBreakTime: !vals.breakTime ? 0 : (vals.breakTime * 60000),
                status: "active"
            };

            const timeData = await db.sprintTimer.put(tData);

        } else {

            const tData = {
                _bookId: bkkId,
                writingTime: (vals.writingTime * 60000),
                statTime: (vals.writingTime * 60000),
                // breakTime: !vals.breakTime ? 0 : (vals.breakTime * 60000),
                statBreakTime: !vals.breakTime ? 0 : (vals.breakTime * 60000),
                status: "active"
            };

            const timeData = await db.sprintTimer.put(tData);
        }


        getSprintData();
        toggleShow(false);
        setSprintCancel(false);
        form.resetFields();
    };
    useEffect(() => {
        getSprintData();
    }, []);

    useEffect(() => {
        getSprintData();
        if(statBreakingTime) {
            setShowBreak(true);
        }
    }, [show]);

    useEffect(() => {
       form.setFieldsValue({
         writingTime: statWritingTime,
         breakTime: statBreakingTime
        });
    }, [show]);


    return (
      <>
        <Popover
          open={show}
          trigger={["click"]}
          className="timer-popover"
          overlayClassName="timer-popover-content"
          title={
            <div className="popover-title">
              <span>Sprint timer</span>
              <div onClick={() => toggleShow(false)}>
                <CloseIconWithoutBackground />
              </div>
            </div>
          }
          content={
            <Form
              {...layout}
              form={form}
              onFinish={sprintTimeSubmit}
              layout="vertical"
              initialValues={{
                writingTime: statWritingTime,
                breakTime: statBreakingTime,
              }}
              requiredMark={false}
            >
              <div>
                <>
                  <>
                    <Form.Item
                      className="input-label"
                      name="writingTime"
                      label="Writing time"
                      labelCol={{ span: 24 }}
                      validateTrigger="onChange"
                      rules={[
                        {
                          required: true,
                          message: "Please input a sprint time!",
                        },
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.reject();
                            }
                            if (value < 0) {
                              return Promise.reject("Invalid sprint time!");
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <AtticusNumberField
                        type="primary"
                        style={{
                          width: "100%",
                        }}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        placeholder="minutes"
                        maxLength={3}
                      />
                    </Form.Item>
                  </>
                </>
                <>
                  <Checkbox
                    checked={showBreak ? showBreak : false}
                    onChange={(c) => setShowBreak(c.target.checked)}
                  >
                    Set break
                  </Checkbox>
                </>
                <div className="spacer"></div>
                <div style={{ display: showBreak ? "block" : "none" }}>
                  <div>
                    <Form.Item
                      className="input-label"
                      name="breakTime"
                      label="Break time"
                      labelCol={{ span: 24 }}
                      validateTrigger="onChange"
                      rules={[
                        {
                          required: showBreak ? true : false,
                          message: "Please input a break time!",
                        },
                        () => ({
                          validator(_, value) {
                            if (value < 0) {
                              return Promise.reject("Invalid sprint time!");
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <AtticusNumberField
                        type="primary"
                        style={{
                          width: "100%",
                        }}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        placeholder="minutes"
                        maxLength={3}
                      />
                    </Form.Item>
                  </div>
                </div>
                <>
                  <>
                    <Button
                      block
                      type="at-primary"
                      htmlType="submit"
                      className="start-button"
                    >
                      Start
                    </Button>
                  </>
                </>
              </div>
            </Form>
          }
        >
          <div>
            {(!sprintValues?.writingTime && !sprintValues?.breakTime) ||
            cancel ? (
              <>
                <Button
                  type="at-ghost"
                  icon={<TimerIcon />}
                  className="timer-button"
                  onClick={() => toggleShow(!show)}
                >
                  Timer
                </Button>
              </>
            ) : null}
            {sprintValues?.writingTime &&
            !sprintValues?.breakTime &&
            !cancel ? (
              <SprintOnly
                sp={sprintValues}
                toggleStatus={show}
                toggle={setToggleViews}
                cancel={handleCancel}
              />
            ) : null}
            {sprintValues?.breakTime && !cancel ? (
              <SprintWithBreak
                sp={sprintValues}
                toggle={setToggleViews}
                cancel={handleCancel}
              />
            ) : null}
          </div>
        </Popover>
      </>
    );
};



export default React.memo(SprintTimer);
