import React, { useCallback, useEffect, useState } from "react";
import { Select } from "antd";
import { observer } from "mobx-react";

import useRootStore from "../../../store/useRootStore";
import { AtticusSelect } from "../../Shared/Form";

const { Option } = Select;

interface SelectProjectProps {
    onSelect: (value: string) => void
    value: string;
    placeholderText: string;
	resetField?: boolean;
}

const SelectProject = observer(({ onSelect, value, placeholderText, resetField }: SelectProjectProps) => {
	const { getProjects } = useRootStore().shelfStore;
	const [ selectedValue, setSelectedValue ] = useState<string | null>(value);
	const [ projectFreeText, setProjectFreeText ] = useState("");

	const projects = getProjects();

	useEffect(() => {
		if (resetField) {
			setSelectedValue(null);
		}
	}, [resetField]);

	const handleSelect = useCallback((val: string) => {
		setSelectedValue(val);
		onSelect(val);
	}, [onSelect]);

	return (
		<AtticusSelect 
			showSearch
			onSearch={val => setProjectFreeText(val)}
			onChange={val => handleSelect(val)}
			placeholder={placeholderText}
			value={selectedValue}
		>{projectFreeText && projects.indexOf(projectFreeText) === -1 ? (<Option key={"new"} value={projectFreeText}>{projectFreeText}</Option>) : null}
		<Option key="empty" value="" title="" style={{ color: "grey"}}>Select project</Option>
			{projects.map((a, i) => (
				<Option key={i} value={a}>{a}</Option>
			))}
		</AtticusSelect>
			
	);
});

export default SelectProject;