// TODO: Refactor this file (There are some repeating configs)
// - Seperate chapter title card `CustomThemeHeader` configs and book and chapter title cards `Style` configs
// - Seperate default `CustomThemeHeader` configs and `CustomThemeHeader` configs that are changing according to the chapter type

import { HeaderFontStyle, PrintExtraProps } from "@surge-global-engineering/css-generator";
import { PdfChapterType, PdfTheme, PdfThemeProperties } from "../types";


type ChildrenVisibility = (data: Pick<PdfThemeProperties, "titleCard">) => Pick<PdfThemeProperties, "titleCard">
type CustomThemeHeaderForChapterTypes = (chapterType: PdfChapterType) =>
  Partial<Pick<PdfThemeProperties, "chapterTitle" | "chapterSubtitle" | "chapterNo">>
type CustomThemeHeaderForChapterTypesMap = {
  [key in PdfChapterType]?: Partial<Pick<PdfThemeProperties, "chapterTitle" | "chapterSubtitle" | "chapterNo">>;
};

type ExtraPropItem = {
  [item in
  | keyof Pick<
  PdfThemeProperties,
    "chapterNo" | "chapterTitle" | "chapterSubtitle"
  >
  | "bookTitle"
  | "bookSubTitle"
  | "bookAuthor"
  | "publisherDetails"
  | "titleImage"
  | "chapterTitleCardContainer"]?: Partial<PrintExtraProps>;
};

interface PrebuiltThemeProperties {
  [name: string]: Partial<PdfThemeProperties> & {
    extra?: ExtraPropItem,
    childrenVisibility?: ChildrenVisibility,
    getCustomThemeHeaderForChapterTypes?: CustomThemeHeaderForChapterTypes
  };
}

const themeProperties: PrebuiltThemeProperties = {
  minax: {
    chapterTitle: {
      font: "Megrim",
      style: [HeaderFontStyle.regular],
      align: "left",
      size: 33,
      width: 100
    },
    chapterNo: {
      font: "Quantico",
      style: [HeaderFontStyle.regular],
      align: "left",
      size: 20,
      width: 100,
    },
    chapterSubtitle: {
      font: "Quantico",
      style: [HeaderFontStyle.regular],
      align: "left",
      size: 20,
      width: 100,
    },
    extra: {
      chapterNo: { paddingBottom: 0, paddingTop: 0, lineHeight: 1 },
      chapterTitle: { paddingBottom: 15, paddingTop: 20, lineHeight: 1.1, fontWeight: 900 },
      chapterSubtitle: { paddingBottom: 0, paddingTop: 0, lineHeight: 1 },
      chapterTitleCardContainer: { paddingTop: 42, paddingBottom: 53 },

      bookTitle: {
        fontFamily: "Megrim",
        fontSize: "43pt",
        fontWeight: 900
      },
      bookAuthor: {
        fontSize: "20pt",
        paddingTop: 3,
        paddingBottom: 1.6,
        fontFamily: "Quantico",
      },
      bookSubTitle: {
        fontSize: "17pt",
        paddingTop: 0.6,
        paddingBottom: 0.6,
        fontFamily: "Quantico",
      },
      publisherDetails: {
        fontFamily: "Quantico"
      }
    },
    childrenVisibility: ({ titleCard: { chapterNumber, subtitle, title } }) => {
      return { titleCard: { chapterNumber, image: true, subtitle, title } };
    },
    getCustomThemeHeaderForChapterTypes: (chapterType) => {
      const customThemeHeaderForChapterTypesMap: CustomThemeHeaderForChapterTypesMap = {
        ["acknowledgments"]: {
          chapterTitle: {
            font: "Megrim",
            style: [HeaderFontStyle.regular],
            align: "left",
            size: 30,
            width: 100
          }
        }
      };
      return customThemeHeaderForChapterTypesMap[chapterType] || {};
    }
  },
  delta: {
    chapterNo: {
      font: "Oswald",
      style: [HeaderFontStyle.regular],
      align: "right",
      size: 28,
      width: 100,
    },
    chapterTitle: {
      font: "BlackOpsOne",
      style: [HeaderFontStyle.regular],
      align: "right",
      size: 33,
      width: 100,
    },
    chapterSubtitle: {
      font: "Oswald",
      style: [HeaderFontStyle.regular],
      align: "right",
      size: 27,
      width: 100,
    },
    extra: {
      chapterNo: { paddingBottom: 0, paddingTop: 0, lineHeight: 1 },
      chapterTitle: { fontWeight: 900, paddingBottom: 5, paddingTop: 18, lineHeight: 1.22 },
      chapterSubtitle: { paddingBottom: 40, paddingTop: 0, lineHeight: 1 },
      chapterTitleCardContainer: { paddingTop: 0, paddingBottom: 43 },

      bookTitle: {
        fontFamily: "BlackOpsOne",
        fontSize: "43pt"
      },
      bookAuthor: {
        fontSize: "23pt",
        paddingTop: 0.6,
        paddingBottom: 0.6,
        fontFamily: "Oswald",
      },
      bookSubTitle: {
        fontSize: "19pt",
        paddingTop: 0.6,
        paddingBottom: 0.6,
        fontFamily: "Oswald",
      },
      publisherDetails: {
        fontFamily: "Oswald"
      },
    },
    getCustomThemeHeaderForChapterTypes: (chapterType) => {
      const customThemeHeaderForChapterTypesMap: CustomThemeHeaderForChapterTypesMap = {
        ["acknowledgments"]: {
          chapterTitle: {
            font: "BlackOpsOne",
            style: [HeaderFontStyle.regular],
            align: "right",
            size: 25.5,
            width: 100,
          }
        }
      };
      return customThemeHeaderForChapterTypesMap[chapterType] || {};
    }
  },
  delphini: {
    chapterNo: {
      font: "Alegreya",
      style: [HeaderFontStyle.regular],
      align: "center",
      size: 19,
      width: 100,
    },
    chapterTitle: {
      font: "EuphoriaScript",
      style: [HeaderFontStyle.regular],
      align: "center",
      size: 36,
      width: 100,
    },
    chapterSubtitle: {
      font: "Alegreya",
      style: [HeaderFontStyle.regular],
      align: "center",
      size: 19,
      width: 100,
    },
    extra: {
      chapterNo: { fontWeight: 400, paddingBottom: 0, paddingTop: 0, lineHeight: 1, fontVariant: "small-caps" },
      chapterTitle: { fontWeight: 900, paddingBottom: 22, paddingTop: 22, lineHeight: 1 },
      chapterSubtitle: { fontWeight: 400, paddingBottom: 0, paddingTop: 0, lineHeight: 1, fontVariant: "small-caps" },
      chapterTitleCardContainer: { paddingTop: 31, paddingBottom: 94 },

      bookTitle: {
        fontFamily: "EuphoriaScript",
        fontSize: "55pt",
        lineHeight: 0.9,
        fontWeight: 900
      },
      bookAuthor: {
        fontSize: "20pt",
        paddingTop: 3.3,
        paddingBottom: 1,
        fontFamily: "AlegreyaSansSC",
        fontWeight: 400
      },
      bookSubTitle: {
        fontSize: "15pt",
        paddingTop: 1,
        paddingBottom: 0.4,
        fontFamily: "AlegreyaSansSC",
        fontWeight: 300
      },
      publisherDetails: {
        fontFamily: "AlegreyaSansSC",
        fontWeight: 300
      }
    },
    childrenVisibility: ({ titleCard: { chapterNumber, subtitle, title } }) => {
      return { titleCard: { chapterNumber, image: true, subtitle, title } };
    }
  },
  minerva: {
    chapterNo: {
      font: "Cinzel",
      style: [HeaderFontStyle.regular],
      align: "left",
      size: 20,
      width: 100,
    },
    chapterTitle: {
      font: "CinzelDecorative",
      style: [HeaderFontStyle.regular],
      align: "left",
      size: 33,
      width: 100,
    },
    chapterSubtitle: {
      font: "Cinzel",
      style: [HeaderFontStyle.regular],
      align: "left",
      size: 20,
      width: 100,
    },
    extra: {
      chapterNo: { paddingBottom: 0, paddingTop: 0, lineHeight: 1, fontVariant: "small-caps" },
      chapterTitle: { fontWeight: 900, paddingBottom: 8, paddingTop: 16, lineHeight: 1.245 },
      chapterSubtitle: { paddingBottom: 0, paddingTop: 1, lineHeight: 1, fontVariant: "small-caps" },
      chapterTitleCardContainer: { paddingTop: 34, paddingBottom: 60 },
      bookTitle: {
        fontSize: "36pt",
        fontFamily: "CinzelDecorative",
        fontWeight: 900,
        lineHeight: 1
      },
      bookAuthor: {
        fontSize: "17pt",
        paddingTop: 3,
        paddingBottom: 1,
        fontFamily: "Cinzel",
        fontWeight: 400
      },
      bookSubTitle: {
        fontSize: "15pt",
        paddingTop: 0.8,
        paddingBottom: 0.4,
        fontFamily: "Cinzel",
        fontWeight: 400
      },
      publisherDetails: {
        fontFamily: "Cinzel",
        fontWeight: 400
      },
    },
    getCustomThemeHeaderForChapterTypes: (chapterType) => {
      const customThemeHeaderForChapterTypesMap: CustomThemeHeaderForChapterTypesMap = {
        ["acknowledgments"]: {
          chapterTitle: {
            font: "CinzelDecorative",
            align: "left",
            style: [HeaderFontStyle.regular],
            size: 22,
            width: 100,
          }
        }
      };
      return customThemeHeaderForChapterTypesMap[chapterType] || {};
    }
  },
  clairmont: {
    chapterNo: {
      font: "AlegreyaSansSC",
      style: [HeaderFontStyle.regular],
      align: "center",
      size: 22,
      width: 100,
    },
    chapterTitle: {
      font: "AlegreyaSansSC",
      style: [HeaderFontStyle.regular],
      align: "center",
      size: 26,
      width: 100,
    },
    chapterSubtitle: {
      font: "AlegreyaSansSC",
      style: [HeaderFontStyle.regular],
      align: "center",
      size: 22,
      width: 100,
    },
    extra: {
      chapterNo: { fontWeight: 600, lineHeight: 1, paddingTop: 0, paddingBottom: 23, fontVariant: "small-caps" },
      chapterTitle: { borderBottom: "1.5px solid #999999", borderTop: "1.5px solid #999999", paddingTop: 15, paddingBottom: 17, fontWeight: 900, lineHeight: 1, fontVariant: "small-caps" },
      chapterSubtitle: { paddingTop: 20, fontWeight: 600, lineHeight: 1, paddingBottom: 0, fontVariant: "small-caps" },
      chapterTitleCardContainer: { paddingTop: 10, paddingBottom: 78 },
      bookTitle: {
        borderColor: "#999999",
        borderBottom: "1.5px solid #999999",
        borderTop: "1.5px solid #999999",

        paddingTop: 1.2,
        paddingBottom: 0.8,

        fontSize: "39pt",
        fontFamily: "AlegreyaSansSC",
        fontWeight: 600
      },
      bookAuthor: {
        fontSize: "21pt",
        paddingTop: 1,
        paddingBottom: 1,

        fontFamily: "AlegreyaSansSC",
        fontWeight: 900
      },
      bookSubTitle: {
        fontSize: "19pt",
        fontFamily: "AlegreyaSansSC",
        fontWeight: 600,
        paddingTop: 3,
        paddingBottom: 1,
      },
      publisherDetails: {
        fontFamily: "AlegreyaSansSC",
        fontWeight: 400
      }
    }
  },
  finch: {
    chapterNo: {
      font: "Marcellus",
      style: [HeaderFontStyle.regular],
      align: "left",
      size: 28,
      width: 100,
    },
    chapterTitle: {
      font: "Marcellus",
      style: [HeaderFontStyle.regular],
      align: "left",
      size: 33,
      width: 100,
    },
    chapterSubtitle: {
      font: "Marcellus",
      style: [HeaderFontStyle.regular],
      align: "left",
      size: 24,
      width: 100,
    },
    extra: {
      chapterNo: { color: "#666666", paddingBottom: 15, paddingTop: 0, lineHeight: 1.3, borderBottom: "1.5px solid #000" },
      chapterTitle: { fontWeight: 900, paddingTop: 13, paddingBottom: 0, lineHeight: 1.2 },
      chapterSubtitle: { paddingTop: 15, paddingBottom: 0, lineHeight: 1, color: "#666666" },
      chapterTitleCardContainer: { paddingTop: 10, paddingBottom: 48 },
      bookTitle: {
        borderBottom: "2pt solid #000",

        paddingTop: 0.6,
        paddingBottom: 0.6,

        fontSize: "33pt",//44px
        fontFamily: "Marcellus",
        fontWeight: 900,
      },
      bookAuthor: {
        fontSize: "17pt",//22px

        paddingTop: 1,
        paddingBottom: 1,

        fontFamily: "Marcellus",
      },
      bookSubTitle: {
        fontSize: "15pt",//20px
        color: "#666",

        paddingTop: 2.5,
        paddingBottom: 2,

        fontFamily: "Marcellus",
      },
      publisherDetails: {
        fontFamily: "Marcellus"
      }
    },
    getCustomThemeHeaderForChapterTypes: (chapterType) => {
      const customThemeHeaderForChapterTypesMap: CustomThemeHeaderForChapterTypesMap = {
        ["acknowledgments"]: {
          chapterTitle: {
            font: "Marcellus",
            align: "left",
            style: [HeaderFontStyle.regular],
            size: 31,
            width: 100,
          }
        }
      };
      return customThemeHeaderForChapterTypesMap[chapterType] || {};
    }
  },
  seraphina: {
    chapterNo: {
      font: "Spectral",
      style: [HeaderFontStyle.regular],
      align: "center",
      size: 24,
      width: 100,
    },
    chapterTitle: {
      font: "Allura",
      style: [HeaderFontStyle.regular],
      align: "center",
      size: 39,
      width: 100,
    },
    chapterSubtitle: {
      font: "Spectral",
      style: [HeaderFontStyle.regular],
      align: "center",
      size: 24,
      width: 100,
    },
    image: {
      placement: "below-chapter-title",
      url: "https://atticus-content.s3.amazonaws.com/files/heart__0mwdYSpJggCF_ce.png",
      width: 9,
      printExtent: "margins",
      alignment: "center",
      // TODO: Revise this 
      colored: "all",
      opacity: 100,
      headerTextColor: "default"
    },
    extra: {
      chapterNo: { paddingBottom: 0, paddingTop: 0, lineHeight: 1, fontWeight: 900 },
      chapterTitle: { fontWeight: 900, paddingBottom: 11, paddingTop: 19, lineHeight: 1.22 },
      chapterSubtitle: { paddingBottom: 0, paddingTop: 3, lineHeight: 1, fontWeight: 900 },
      chapterTitleCardContainer: { paddingTop: 3, paddingBottom: 51 },

      bookTitle: {
        fontSize: "53pt",
        fontFamily: "Allura",
        fontWeight: 400
      },
      bookAuthor: {
        fontSize: "27pt",
        paddingBottom: 0.6,
        paddingTop: 3.5,
        fontFamily: "Spectral",
        fontWeight: 400
      },
      bookSubTitle: {
        fontSize: "17pt",
        paddingBottom: 0.6,
        paddingTop: -1,
        fontFamily: "Spectral",
        fontWeight: 900
      },
      publisherDetails: {
        fontFamily: "Spectral",
        fontWeight: 900
      }
    },
    childrenVisibility: ({ titleCard: { chapterNumber, subtitle, title } }) => {
      return { titleCard: { chapterNumber, image: true, subtitle, title } };
    }
  },
  titus: {
    chapterNo: {
      font: "VollkornSC",
      style: [HeaderFontStyle.regular],
      align: "center",
      size: 18.75, // 43[slides] 18.75[press]
      width: 100,
    },
    chapterTitle: {
      font: "VollkornSC",
      style: [HeaderFontStyle.regular],
      align: "center",
      size: 15.75, // 33[slides] 15.75[press]
      width: 100,
    },
    chapterSubtitle: {
      font: "VollkornSC",
      style: [HeaderFontStyle.regular],
      align: "center",
      size: 12.75, // 17[slides] 12.75[press]
      width: 100,
    },
    image: {
      placement: "above-chapter-title",
      url: "https://atticus-content.s3.amazonaws.com/icons/chapter-number-break.png",
      width: 7,
      printExtent: "margins",
      alignment: "center",
      opacity: 100,
      headerTextColor: "default",
      // TODO: Revise this
      colored: "all"
    },
    extra: {
      chapterNo: { paddingTop: 0, paddingBottom: 6.5, lineHeight: 1, fontWeight: 900/*, fontVariant: "small-caps"*/ },
      chapterTitle: { paddingTop: 14, paddingBottom: 10, lineHeight: 1.2, fontWeight: 900, fontVariant: "small-caps" },
      chapterSubtitle: { paddingTop: 0, paddingBottom: 0, lineHeight: 1, fontVariant: "small-caps" },
      chapterTitleCardContainer: { paddingTop: 48, paddingBottom: 33 },

      // [slides]
      // chapterNo: { paddingTop: 0, paddingBottom: 7.5, lineHeight: 1, fontWeight: 900/*, fontVariant: "small-caps"*/ },
      // chapterTitle: { paddingTop: 7, paddingBottom: 14, lineHeight: 1.2, fontWeight: 900, fontVariant: "small-caps" },
      // chapterSubtitle: { paddingTop: 0, paddingBottom: 0, lineHeight: 1, fontVariant: "small-caps" },
      // chapterTitleCardContainer: { paddingTop: 0, paddingBottom: 70 },

      bookTitle: {
        paddingTop: 0.6,
        paddingBottom: 1,
        fontSize: "41pt",
        fontFamily: "VollkornSC",
        fontWeight: 900
      },
      titleImage: {
        imageSrc: "https://atticus-content.s3.amazonaws.com/icons/chapter-number-break.png",
        width: 17
      },
      bookAuthor: {
        fontSize: "20pt",
        paddingTop: 3.5,
        paddingBottom: 0.6,
        fontFamily: "VollkornSC",
        fontWeight: 400
      },
      bookSubTitle: {
        fontSize: "14pt",
        paddingTop: 2,
        paddingBottom: 0.4,
        fontFamily: "VollkornSC",
        fontWeight: 400
      },
      publisherDetails: {
        fontFamily: "VollkornSC",
        fontWeight: 400
      }
    },
    childrenVisibility: ({ titleCard: { chapterNumber, subtitle, title } }) => {
      if (!chapterNumber) {
        return { titleCard: { chapterNumber, image: false, subtitle, title } };
      }
      return { titleCard: { chapterNumber, image: true, subtitle, title } };
    }
  },
  penelope: {
    chapterTitle: {
      font: "EmilysCandy",
      style: [HeaderFontStyle.regular],
      align: "right",
      size: 30,
      width: 100
    },
    chapterNo: {
      font: "Raleway",
      style: [HeaderFontStyle.regular],
      align: "right",
      size: 20,
      width: 100,
    },
    chapterSubtitle: {
      font: "Raleway",
      style: [HeaderFontStyle.regular],
      align: "left",
      size: 20,
      width: 100,
    },
    extra: {
      chapterNo: { paddingBottom: 22, paddingTop: 0, lineHeight: 1, fontWeight: 400 },
      chapterTitle: { borderBottom: 2, borderColor: "#000000", borderStyle: "dashed", paddingBottom: 29, paddingTop: 9, lineHeight: 1, fontWeight: 900 },
      chapterSubtitle: { paddingBottom: 23, paddingTop: 22, lineHeight: 1, fontWeight: 400 },
      chapterTitleCardContainer: { paddingTop: 20, paddingBottom: 45 },

      bookTitle: {
        paddingTop: 0.6,
        paddingBottom: 1,
        fontSize: "41pt",
        fontFamily: "EmilysCandy",
        fontWeight: 400,
        borderBottom: "2pt dotted #000000"
      },
      bookAuthor: {
        fontSize: "20pt",
        paddingTop: 2,
        paddingBottom: 1.6,
        fontFamily: "Raleway",
        fontWeight: 900
      },
      bookSubTitle: {
        fontSize: "17pt",
        paddingTop: 1.5,
        paddingBottom: 1,
        fontFamily: "Raleway",
        fontWeight: 300
      },
      publisherDetails: {
        fontFamily: "Raleway",
        fontWeight: 300
      }
    }
  },
  watts: {
    chapterNo: {
      font: "Anton",
      align: "left",
      style: [HeaderFontStyle.regular],
      size: 22,
      width: 100
    },
    chapterTitle: {
      font: "PressStart2P",
      align: "left",
      style: [HeaderFontStyle.regular],
      size: 20,
      width: 100
    },
    chapterSubtitle: {
      font: "Anton",
      align: "left",
      style: [HeaderFontStyle.regular],
      size: 22,
      width: 100
    },
    extra: {
      chapterNo: { paddingBottom: 0, paddingTop: 0, lineHeight: 1 },
      chapterTitle: { paddingBottom: 0, paddingTop: 32, lineHeight: 1.21 },
      chapterSubtitle: { paddingBottom: 0, paddingTop: 21, lineHeight: 1 },
      chapterTitleCardContainer: { paddingTop: 0, paddingBottom: 53 },

      bookTitle: {
        paddingTop: 0.6,
        paddingBottom: 0.6,
        fontSize: "27pt",
        fontFamily: "PressStart2P",
        fontWeight: 400,
      },
      bookAuthor: {
        fontSize: "20pt",
        paddingTop: 3.5,
        paddingBottom: 1.6,
        fontFamily: "Anton",
        fontWeight: 400
      },
      bookSubTitle: {
        fontSize: "17pt",
        paddingTop: 1,
        paddingBottom: 1,
        fontFamily: "Anton",
        fontWeight: 400
      },
      publisherDetails: {
        fontFamily: "Anton",
        fontWeight: 400
      }
    },
    getCustomThemeHeaderForChapterTypes: (chapterType) => {
      const customThemeHeaderForChapterTypesMap: CustomThemeHeaderForChapterTypesMap = {
        ["acknowledgments"]: {
          chapterTitle: {
            font: "PressStart2P",
            align: "left",
            style: [HeaderFontStyle.regular],
            size: 17,
            width: 100
          }
        }
      };
      return customThemeHeaderForChapterTypesMap[chapterType] || {};
    }
  },
  aether: {
    chapterNo: {
      font: "Aldrich",
      align: "right",
      style: [HeaderFontStyle.regular],
      size: 28,
      width: 100
    },
    chapterTitle: {
      font: "Audiowide",
      align: "right",
      style: [HeaderFontStyle.regular],
      size: 33,
      width: 100
    },
    chapterSubtitle: {
      font: "Aldrich",
      align: "right",
      style: [HeaderFontStyle.regular],
      size: 20,
      width: 100
    },
    extra: {
      chapterNo: { paddingBottom: 0, paddingTop: 0, lineHeight: 1 },
      chapterTitle: { paddingBottom: 18, paddingTop: 14, lineHeight: 1.22 },
      chapterSubtitle: { paddingTop: 14, paddingBottom: 0, lineHeight: 1, textTransform: "uppercase", fontWeight: 900 },
      chapterTitleCardContainer: { paddingTop: 2, paddingBottom: 55 },

      bookTitle: {
        paddingTop: 0.6,
        paddingBottom: 0.6,
        fontSize: "41pt",
        fontFamily: "Audiowide",
        fontWeight: 400,
      },
      bookAuthor: {
        fontSize: "19pt",
        paddingTop: 3.5,
        paddingBottom: 1.6,
        fontFamily: "Aldrich",
        fontWeight: 400
      },
      bookSubTitle: {
        fontSize: "15pt",
        paddingTop: 1.5,
        paddingBottom: 1,
        fontFamily: "Aldrich",
        fontWeight: 900
      },
      publisherDetails: {
        fontFamily: "Aldrich",
        fontWeight: 400
      }
    },
    getCustomThemeHeaderForChapterTypes: (chapterType) => {
      const customThemeHeaderForChapterTypesMap: CustomThemeHeaderForChapterTypesMap = {
        ["acknowledgments"]: {
          chapterTitle: {
            font: "Audiowide",
            align: "right",
            style: [HeaderFontStyle.regular],
            size: 25,
            width: 100
          }
        }
      };
      return customThemeHeaderForChapterTypesMap[chapterType] || {};
    }
  },
  hughes: {
    chapterNo: {
      font: "Cantarell",
      align: "center",
      size: 18,
      style: [HeaderFontStyle.regular],
      width: 100
    },
    chapterTitle: {
      font: "RockSalt",
      align: "center",
      size: 20,
      style: [HeaderFontStyle.regular],
      width: 100
    },
    chapterSubtitle: {
      font: "Cantarell",
      align: "center",
      style: [HeaderFontStyle.regular],
      size: 18,
      width: 100
    },
    extra: {
      chapterNo: { paddingBottom: 0, paddingTop: 0, lineHeight: 1 },
      chapterTitle: { paddingBottom: 30, paddingTop: 19, lineHeight: 1.5, fontWeight: 900 },
      chapterSubtitle: { paddingBottom: 0, paddingTop: 0, lineHeight: 1 },
      chapterTitleCardContainer: { paddingTop: 65, paddingBottom: 55 },

      bookTitle: {
        paddingTop: 0.6,
        paddingBottom: 0,
        fontSize: "34pt",
        fontFamily: "RockSalt",
        fontWeight: 900,
      },
      bookAuthor: {
        fontSize: "19pt",
        paddingTop: 3.5,
        paddingBottom: 1.6,
        fontFamily: "Cantarell",
        fontWeight: 900
      },
      bookSubTitle: {
        fontSize: "15pt",
        paddingTop: -1,
        paddingBottom: 1,
        fontFamily: "Cantarell",
        fontWeight: 400
      },
      publisherDetails: {
        fontFamily: "Cantarell",
        fontWeight: 400
      }
    }
  },
  intratech: {
    chapterNo: {
      font: "Poppins",
      align: "left",
      style: [HeaderFontStyle.regular],
      size: 20,
      width: 100
    },
    chapterTitle: {
      font: "RobotoCondensed",
      align: "left",
      style: [HeaderFontStyle.regular],
      size: 34,
      width: 100
    },
    chapterSubtitle: {
      font: "Poppins",
      align: "left",
      style: [HeaderFontStyle.regular],
      size: 20,
      width: 100
    },
    extra: {
      chapterNo: { paddingBottom: 0, paddingTop: 0, lineHeight: 1.1 },
      chapterTitle: { paddingBottom: 11, paddingTop: 17, lineHeight: 1.19, fontWeight: 900, },
      chapterSubtitle: { paddingBottom: 0, paddingTop: 0, lineHeight: 1 },
      chapterTitleCardContainer: { paddingTop: 31, paddingBottom: 60 },

      bookTitle: {
        paddingTop: 0.6,
        paddingBottom: 0.6,
        fontSize: "44pt",
        fontFamily: "RobotoCondensed",
        fontWeight: 900,
      },
      bookAuthor: {
        fontSize: "19pt",
        paddingTop: 1,
        paddingBottom: 1,
        fontFamily: "Poppins",
        fontWeight: 400
      },
      bookSubTitle: {
        fontSize: "15pt",
        paddingTop: 1,
        paddingBottom: 0,
        fontFamily: "Poppins",
        fontWeight: 400
      },
      publisherDetails: {
        fontFamily: "Poppins",
        fontWeight: 400
      }
    }
  },
  atreides: {
    chapterNo: {
      font: "Roboto",
      align: "center",
      style: [HeaderFontStyle.regular],
      size: 20,
      width: 100
    },
    chapterTitle: {
      font: "Crushed",
      align: "center",
      style: [HeaderFontStyle.regular],
      size: 33,
      width: 100
    },
    chapterSubtitle: {
      font: "Roboto",
      align: "center",
      style: [HeaderFontStyle.regular],
      size: 20,
      width: 100
    },
    extra: {
      chapterNo: { paddingBottom: 0, paddingTop: 0, lineHeight: 1 },
      chapterTitle: { paddingBottom: 16, paddingTop: 18, lineHeight: 1, fontWeight: 900 },
      chapterSubtitle: { paddingBottom: 0, paddingTop: 0, lineHeight: 1 },
      chapterTitleCardContainer: { paddingTop: 74, paddingBottom: 60 },

      bookTitle: {
        paddingTop: 0.4,
        paddingBottom: 0.4,
        fontSize: "44pt",
        fontFamily: "Crushed",
        fontWeight: 400,
      },
      bookAuthor: {
        fontSize: "26pt",
        paddingTop: 3.8,
        paddingBottom: 0.6,
        fontFamily: "Roboto",
        fontWeight: 400
      },
      bookSubTitle: {
        fontSize: "15pt",
        paddingTop: 0.6,
        paddingBottom: 1,
        fontFamily: "Roboto",
        fontWeight: 400
      },
      publisherDetails: {
        fontFamily: "Roboto",
        fontWeight: 400
      }
    }
  },
  scarlett: {
    chapterNo: {
      font: "Spartan",
      align: "center",
      style: [HeaderFontStyle.regular],
      size: 20,
      width: 100
    },
    chapterTitle: {
      font: "Selima",
      align: "center",
      style: [HeaderFontStyle.regular],
      size: 42,
      width: 100
    },
    chapterSubtitle: {
      font: "Spartan",
      align: "center",
      style: [HeaderFontStyle.regular],
      size: 20,
      width: 100
    },
    extra: {
      chapterNo: { paddingBottom: 0, paddingTop: 0, lineHeight: 1, fontWeight: 400 },
      chapterTitle: { paddingBottom: 37, paddingTop: 29, lineHeight: 1, fontWeight: 900 },
      chapterSubtitle: { paddingBottom: 0, paddingTop: 0, lineHeight: 1, fontWeight: 400 },
      chapterTitleCardContainer: { paddingTop: 35, paddingBottom: 60 },

      bookTitle: {
        paddingTop: 0.4,
        paddingBottom: 0.4,
        fontSize: "41pt",
        fontFamily: "Selima",
        fontWeight: 400,
      },
      bookAuthor: {
        fontSize: "17pt",
        paddingTop: 3.5,
        paddingBottom: 0.6,
        fontFamily: "Spartan",
        fontWeight: 400
      },
      bookSubTitle: {
        fontSize: "15pt",
        paddingTop: 0.6,
        paddingBottom: 1,
        fontFamily: "Spartan",
        fontWeight: 400
      },
      publisherDetails: {
        fontFamily: "Spartan",
        fontWeight: 400
      }
    },
    getCustomThemeHeaderForChapterTypes: (chapterType) => {
      const customThemeHeaderForChapterTypesMap: CustomThemeHeaderForChapterTypesMap = {
        ["acknowledgments"]: {
          chapterTitle: {
            font: "Selima",
            align: "center",
            style: [HeaderFontStyle.regular],
            size: 41.5,
            width: 100
          }
        }
      };
      return customThemeHeaderForChapterTypesMap[chapterType] || {};
    }
  },
  "bonkers-books": {
    chapterNo: {
      font: "Delius",
      align: "center",
      style: [HeaderFontStyle.regular],
      size: 20,
      width: 100
    },
    chapterTitle: {
      font: "LifeSavers",
      align: "center",
      style: [HeaderFontStyle.regular],
      size: 34,
      width: 100
    },
    chapterSubtitle: {
      font: "Delius",
      align: "center",
      style: [HeaderFontStyle.regular],
      size: 20,
      width: 100
    },
    extra: {
      chapterNo: { paddingBottom: 0, paddingTop: 0, lineHeight: 1 },
      chapterTitle: { paddingBottom: 12, paddingTop: 17, lineHeight: 1.19, fontWeight: 900 },
      chapterSubtitle: { paddingBottom: 0, paddingTop: 0, lineHeight: 1 },
      chapterTitleCardContainer: { paddingTop: 33, paddingBottom: 50 },

      bookTitle: {
        paddingTop: 0.6,
        paddingBottom: 0.6,
        fontSize: "44pt",
        fontFamily: "LifeSavers",
        fontWeight: 900,
      },
      bookAuthor: {
        fontSize: "20pt",
        paddingTop: 3,
        paddingBottom: 1,
        fontFamily: "Delius",
        fontWeight: 400
      },
      bookSubTitle: {
        fontSize: "15pt",
        paddingTop: 0,
        paddingBottom: 1,
        fontFamily: "Delius",
        fontWeight: 400
      },
      publisherDetails: {
        fontFamily: "Delius",
        fontWeight: 400
      }
    },
    getCustomThemeHeaderForChapterTypes: (chapterType) => {
      const customThemeHeaderForChapterTypesMap: CustomThemeHeaderForChapterTypesMap = {
        ["acknowledgments"]: {
          chapterTitle: {
            font: "LifeSavers",
            align: "center",
            style: [HeaderFontStyle.regular],
            size: 31,
            width: 100
          }
        }
      };
      return customThemeHeaderForChapterTypesMap[chapterType] || {};
    }
  },
  elinor: {
    chapterNo: {
      font: "Cinzel",
      align: "center",
      style: [HeaderFontStyle.regular],
      size: 20,
      width: 100
    },
    chapterTitle: {
      font: "GreatVibes",
      align: "center",
      style: [HeaderFontStyle.regular],
      size: 33,
      width: 100
    },
    chapterSubtitle: {
      font: "Cinzel",
      align: "center",
      style: [HeaderFontStyle.regular],
      size: 20,
      width: 100
    },
    extra: {
      chapterNo: { paddingBottom: 0, paddingTop: 0, lineHeight: 1 },
      chapterTitle: { paddingBottom: 20, paddingTop: 20, lineHeight: 1, fontWeight: 900 },
      chapterSubtitle: { paddingBottom: 0, paddingTop: 0, lineHeight: 1 },
      chapterTitleCardContainer: { paddingTop: 66, paddingBottom: 60 },

      bookTitle: {
        paddingTop: 0.4,
        paddingBottom: 0,
        fontSize: "48pt",
        fontFamily: "GreatVibes",
        fontWeight: 900,
      },
      bookAuthor: {
        fontSize: "17pt",
        paddingTop: 3.5,
        paddingBottom: 0.6,
        fontFamily: "Cinzel",
        fontWeight: 400
      },
      bookSubTitle: {
        fontSize: "15pt",
        paddingTop: 0.4,
        paddingBottom: 1.5,
        fontFamily: "Cinzel",
        fontWeight: 400
      },
      publisherDetails: {
        fontFamily: "Cinzel",
        fontWeight: 400
      }
    }
  }
};

export { themeProperties };
