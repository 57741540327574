import { HeaderImage, ThemeStyleProps } from "@surge-global-engineering/css-generator";

/**
 * Handles the issue of having two image opacity scales 0-1 and 0-100.
 * Current opacity scale is from 0-100, this normalizes all scales to 0-1.
 * Known Issue: If opacity is set to 1 from current 0-100 scale, this would consider it as 100% opacity.
 * @param opacity image opacity
 * @returns normalized image opacity in scale of 0-1
 */
const normalizeImageOpacity = (opacity?: number): number => {
  if (opacity !== undefined && opacity > 1) {
    return opacity / 100;
  }
  return opacity || 1;
};

/**
 * Changes image opacity and returns the url
 * @param imgUrl s3 url of the original image
 * @param opacity image opacity in a scale of 0-1
 * @returns data url for opacity changed image
 */
export const applyOpacity = async (
  imgUrl: string,
  opacity: number
): Promise<string> => {
  const image = new Image();

  return new Promise((resolve, reject) => {
    image.setAttribute("crossorigin", "anonymous");
    const canvas = document.createElement("canvas");
    image.onload = async () => {
      // Create a new canvas
      canvas.width = image.width;
      canvas.height = image.height;
      // Get the canvas 2D context
      const context = canvas.getContext("2d");
      if (context) {
        // Draw the image on the canvas
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        // Perform image manipulation
        context.fillStyle = "#ffffff";
        context.fillRect(0, 0, canvas.width, canvas.height);

        context.globalAlpha = opacity;
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        const imageUrl = canvas.toDataURL("image/png");
        resolve(imageUrl);
      }
      // Handle image loading errors
      image.onerror = (error) => {
        reject(error);
      };
    };
    image.src = imgUrl;
  });
};

/**
 * Get Chapter header image to render based on theme and chapter properties. Returns undefined if header image shouldn't be rendered
 * @param themeProps Theme properties
 * @param chapter Chapter properties
 * @returns Header image to render based on theme and chapter properties. Returns undefined if header image shouldn't be rendered
 */
export const getChapterHeaderImageToRender = async (
  themeProps: ThemeStyleProps,
  chapter: IChapterStore.Chapter
): Promise<HeaderImage | undefined> => {
  const { showChapterImage = true } = chapter?.configuration || {};
  const shouldRenderHeaderImage =
    showChapterImage && themeProps.titleCard.image;

  if (!shouldRenderHeaderImage) {
    return undefined;
  }
  const { image } = themeProps;

  // if individual chapter image is opted, use chapter level header image instead of theme image
  let imageUrl = themeProps.individualChapterImage ? chapter.image: image.url;

  if (!imageUrl) {
    return undefined;
  }

  // normalize image opacity value
  const opacity = normalizeImageOpacity(image.opacity);
  // change image opacity and update image url if necessary
  const shouldChangeImageOpacity =
    opacity !== 1 && image.placement === "background-image";

  if (shouldChangeImageOpacity) {
    imageUrl = await applyOpacity(imageUrl, opacity);
  }

  return {
    ...image,
    url: imageUrl,
    opacity,
  };
};
