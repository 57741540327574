import React from "react";

const A = () => {
	return (
		<div className="hf-layout">
			<div className="left">
				<div className="hf-header">
					<div>2</div>
					<div>Author</div>
					<div></div>
				</div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer"></div>
			</div>
			<div className="right">
				<div className="hf-header">
					<div></div>
					<div>Title</div>
					<div>3</div>
				</div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer"></div>
			</div>
		</div>
	);
};

const B = () => {
	return (
		<div className="hf-layout">
			<div className="left">
				<div className="hf-header">
					<div>2 Author</div>
					<div></div>
				</div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer"></div>
			</div>
			<div className="right">
				<div className="hf-header">
					<div></div>
					<div>Title 3</div>
				</div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer"></div>
			</div>
		</div>
	);
};

const C = () => {
	return (
		<div className="hf-layout">
			<div className="left">
				<div className="hf-header">
					<div>Author</div>
					<div></div>
				</div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer">2</div>
			</div>
			<div className="right">
				<div className="hf-header">
					<div></div>
					<div>Title</div>
				</div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer">3</div>
			</div>
		</div>
	);
};

const D = () => {
	return (
		<div className="hf-layout">
			<div className="left">
				<div className="hf-header">
					<div></div>
					<div>Author</div>
					<div></div>
				</div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer">2</div>
			</div>
			<div className="right">
				<div className="hf-header">
					<div></div>
					<div>Title</div>
					<div></div>
				</div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer">3</div>
			</div>
		</div>
	);
};

const E = () => {
	return (
		<div className="hf-layout">
			<div className="left">
				<div className="hf-header">
					<div>2</div>
					<div>Title</div>
					<div></div>
				</div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer"></div>
			</div>
			<div className="right">
				<div className="hf-header">
					<div></div>
					<div>Chapter</div>
					<div>3</div>
				</div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer"></div>
			</div>
		</div>
	);
};

const F = () => {
	return (
		<div className="hf-layout">
			<div className="left">
				<div className="hf-header">
					<div>2 Title</div>
					<div></div>
				</div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer"></div>
			</div>
			<div className="right">
				<div className="hf-header">
					<div></div>
					<div>Chapter 3</div>
				</div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer"></div>
			</div>
		</div>
	);
};

const G = () => {
	return (
		<div className="hf-layout">
			<div className="left">
				<div className="hf-header"></div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer">2</div>
			</div>
			<div className="right">
				<div className="hf-header"></div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer">3</div>
			</div>
		</div>
	);
};

const H = () => {
	return (
		<div className="hf-layout">
			<div className="left">
				<div className="hf-header">
				</div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer">
					<div style={{ textAlign: "left"}}>2</div>
				</div>
			</div>
			<div className="right">
				<div className="hf-header"></div>
				<div className="body">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="hf-footer">
					<div style={{ textAlign: "right"}}>3</div>
				</div>
			</div>
		</div>
	);
};

export default {
	A,
	B,
	C,
	D,
	E,
	F,
	G,
	H
};
