import {
  createPluginFactory,
} from "@udecode/plate-core";
import { HangingIndentComponent } from "./components/hangingIndentComponent";

export const ELEMENT_HANGING_INDENT = "indent";

export const createHangingIndentPlugin = createPluginFactory({
  key: ELEMENT_HANGING_INDENT,
  isElement: true,
  component: HangingIndentComponent,
  deserializeHtml: {
    rules: [
      { validNodeName: ["INDENT"] },
    ]
  },
  options: {
    hotkey: ["mod+opt+7", "mod+shift+7"],
  },
});
