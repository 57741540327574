import {
  ThemeStyleProps,
  UsedFont,
  capitalizeFirstLetter,
  headerStyleToFontVariant,
} from "@surge-global-engineering/css-generator";
import { Font } from "@surge-global-engineering/epub-gen-browser";
import { Table } from "dexie";
import { isEqual, uniqWith } from "lodash";
import { FontItem } from "../../../types/font";

type GetFontsForEpubReturnType = {
  fontFiles: Font[];
  usedFonts: UsedFont[];
};

export const getFontsForEpub = async (
  styleProps: ThemeStyleProps,
  userFavouriteFonts: Table<FontItem, string>
): Promise<GetFontsForEpubReturnType> => {
  const fontFiles: Font[] = [];
  const usedFonts: UsedFont[] = [];

  const headings = ["h2", "h3", "h4", "h5", "h6"];
  const themeFontIdsAndStyles = [
    {
      id: styleProps.chapterNo.font,
      style: styleProps.chapterNo.style,
    },
    {
      id: styleProps.chapterTitle.font,
      style: styleProps.chapterTitle.style,
    },
    {
      id: styleProps.chapterSubtitle.font,
      style: styleProps.chapterSubtitle.style,
    },
  ];

  // include every subheading fonts used except "Default" font
  for (const heading of headings) {
    const { font } = styleProps.headings[heading];
    if (font !== "Default") {
      themeFontIdsAndStyles.push({
        id: font,
        style: [],
      });
    }
  }

  const dropCapFont = styleProps.firstParagraph.dropcapFont;
  if (dropCapFont !== "Default") {
    themeFontIdsAndStyles.push({
      id: dropCapFont,
      style: [],
    });
  }

  for (const themeFont of themeFontIdsAndStyles) {
    const fontFromDB = await userFavouriteFonts.get(themeFont.id);
    if (fontFromDB) {
      const fontVariant = headerStyleToFontVariant(themeFont.style);
      const fontFileSrc = fontFromDB.files.find(
        ({ variant }) => variant === fontVariant
      );
      const filename = `${fontFromDB._id}-${capitalizeFirstLetter(
        fontVariant
      )}.${fontFileSrc.src.split(".").pop()}`;

      usedFonts.push({
        id: themeFont.id,
        variant: fontVariant,
        remoteSrc: fontFileSrc.src,
        localSrc: filename,
      });
      fontFiles.push({ filename, url: fontFileSrc.src });
    }
  }

  return {
    fontFiles: uniqWith(fontFiles, isEqual),
    usedFonts: uniqWith(usedFonts, isEqual),
  };
};
