import {
  PlateEditor,
  Value,
  isElement,
  liftNodes,
} from "@udecode/plate-core";

export const withPageBreak = <
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>
>(
  editor: E
) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    const isRoot = path.length === 1;

    if (isElement(node) && node.type === "page-break" && !isRoot) {
      liftNodes(editor, {
        at: path
      });

      return;
    }

    normalizeNode(entry);
  };

  return editor;
};
