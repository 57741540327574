import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Row, Col, Tooltip, notification } from "antd";
import Countdown from "react-countdown";

// Icons
import { SprintPauseTimerIcon, SprintPlayTimerIcon } from "../Plate/partials/Icon";

import { db } from "../../db/bookDb";

import "./sprint.scss";

import useRootStore from "../../store/useRootStore";
import { Button } from "../Shared/Buttons/Button";
import { NotificationCloseIcon } from "../../content/icons";

interface SprintOnlyProps {
    sp?: ISprintTimerStore.SprintTimer | null,
    // show: boolean;
    toggleStatus?: boolean;
    toggle?: (visible: boolean) => void,
    cancel?: (visible: boolean) => void
}

const SprintOnly = observer((props: SprintOnlyProps) => {
    const { getCurrentBookId } = useRootStore().bookStore;
    const bkkId = getCurrentBookId();

    const [timerPopConfirm, setTimePopConfirm] = useState(false);
    const [completedSP, setCompleted] = useState(false);

    const writingMinutes = props.sp?.writingTime ? props.sp?.writingTime : 0;
    const sprintWritingStatus = props.sp ? props.sp.status : null;
    const staticWM = props.sp?.statTime;

    const setToggleViews = (destroyNotification?:boolean) => {
        props.toggle ? props.toggle(true) : null;
        if(destroyNotification) {
            notification.destroy();
        }
        // setCompleted(false);
    };

    const handleCancel = () => {
        setTimePopConfirm(false);
        props.cancel ? props.cancel(true) : null;
        notification.destroy();
    };

    React.useEffect(() => {
        setCompleted(false);
    }, [writingMinutes]);


    // Renderer callback with condition
    const renderer = ({ formatted: { hours, minutes, seconds }, completed, api, total }) => {

        if (api.isPaused()) {
            const tData = {
                _bookId: bkkId,
                writingTime: total,
                breakTime: 0,
                statTime: staticWM,
                status: "paused"
            };

            const timeData = db.sprintTimer.put(tData);

            return (
                <div className="sprint-break">
                    <Tooltip color="#3568BA" placement="bottom" title={<span>Play Timer</span>}>
                        <div className="before-sprint" onClick={api.start}>
                            <SprintPlayTimerIcon />
                            {writingMinutes > 3600000 ? (<span style={{ color: "#3568BA", paddingLeft: 2 }} >{hours}{minutes}:{seconds}</span>) : (<span style={{ color: "#3568BA", paddingLeft: 2 }}>{minutes}:{seconds}</span>)}
                            {/* onClick={() => setToggleViews()} */}
                        </div>
                    </Tooltip>
                </div>
            );
        }
        if (completed) {

            // Render a completed state
            setTimePopConfirm(true);
            setCompleted(true);

            const tData = {
                _bookId: bkkId,
                writingTime: total, //hours,minutes,seconds,
                breakTime: 0,
                statTime: staticWM,
                status: "completed"
            };

            const timeData = db.sprintTimer.put(tData);

            return null;

        } else {

            // Render a countdown
            if (completedSP == false) {
                setTimePopConfirm(false);

                const tData = {
                    _bookId: bkkId,
                    writingTime: total,
                    statTime: staticWM,
                    breakTime: 0,
                    status: "active"
                };

                const timeData = db.sprintTimer.update(bkkId, tData);

            }

            return (
                <>
                    {/* <div className="after-sprint">
                        <Tooltip color="#3568BA" placement="bottom" title={<span>Pause Timer</span>}>
                            <div className="before-sprint" onClick={api.pause}>
                                <SprintPauseTimerIcon />
                                {completedSP == true ? (
                                    writingMinutes > 3600000 ? (
                                        <span style={{ color: "#00BE95", paddingLeft: 2 }} onClick={() => setToggleViews()}>{hours}:{minutes}:{seconds}</span>) :
                                        (<span style={{ color: "#00BE95", paddingLeft: 2 }} onClick={() => setToggleViews()}>{minutes}:{seconds}</span>)
                                ) : (
                                    writingMinutes > 3600000 ? (
                                        <span style={{ color: "#00BE95", paddingLeft: 2 }}>{hours}:{minutes}:{seconds}</span>) :
                                        (<span style={{ color: "#00BE95", paddingLeft: 2 }}>{minutes}:{seconds}</span>)
                                )}
                            </div>
                        </Tooltip>
                    </div> */}

                    <div className="after-sprint">
                        {completedSP == true ? (
                            <div className="before-sprint">
                                <SprintPauseTimerIcon />
                                {writingMinutes > 3600000 ? (
                                    <span style={{ color: "#00BE95", paddingLeft: 2 }} onClick={() => setToggleViews()}>{hours}:{minutes}:{seconds}</span>) :
                                    (<span style={{ color: "#00BE95", paddingLeft: 2 }} onClick={() => setToggleViews()}>{minutes}:{seconds}</span>)}
                            </div>
                        ) : (
                            <Tooltip color="#3568BA" placement="bottom" title={<span>Pause Timer</span>}>
                                <div className="before-sprint" onClick={api.pause}>

                                    <SprintPauseTimerIcon />
                                    {writingMinutes > 3600000 ? (
                                        <span style={{ color: "#00BE95", paddingLeft: 2 }}>{hours}:{minutes}:{seconds}</span>) :
                                        (<span style={{ color: "#00BE95", paddingLeft: 2 }}>{minutes}:{seconds}</span>)
                                    }
                                </div>
                            </Tooltip>
                        )}
                    </div>

                </>
            );
        }
    };

    // trigger notification after completing the sprint
    useEffect(() => {
      if(timerPopConfirm) {
        notification.open({
          message: null,
          duration: null,
          placement: "bottomRight",
          icon: null,
          className: "timer-notification",
          closeIcon: <></>,
          description: (
            <div>
              <Row>
                <Col flex="auto" className="info">
                  <div className="title">Your sprint is complete!</div>
                  <div className="description">
                    Want to start another sprint?
                  </div>
                </Col>
                <Col className="action">
                  <Button
                    type="at-ghost"
                    className="start-button"
                    onClick={() => setToggleViews(true)}
                  >
                    Start
                  </Button>
                </Col>
                <Col className="destroy" onClick={() => handleCancel()}>
                  <NotificationCloseIcon />
                </Col>
              </Row>
            </div>
          ),
        });

      }
    }, [timerPopConfirm]);
    
    // destroy the notification if the popover is opened
    useEffect(() => {
      if(props.toggleStatus) {
        notification.destroy();
      }
    }, [props.toggleStatus]);
    

    return (
        <div className="after-sprint">
            <div>
                {sprintWritingStatus === "active" ? (<Countdown key={staticWM} date={Date.now() + (writingMinutes)} renderer={renderer} />) : null}    {/* //ref={setRef} */}
                {sprintWritingStatus === "paused" ? (<Countdown key={staticWM} date={Date.now() + (writingMinutes)} renderer={renderer} />) : null}
            </div>
        </div>
    );
});

export default React.memo(SprintOnly);