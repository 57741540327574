import { TReactEditor, insertNodes } from "@udecode/plate-core";
import { ELEMENT_PAGE_BREAK } from "../createPageBreakPlugin";

export const insertPageBreak = (
  editor: TReactEditor,
): void => {
  insertNodes(
    editor,
    {
      type: ELEMENT_PAGE_BREAK,
      children: [{ text: "" }],
    },
    {
      at: editor.selection || undefined,
    }
  );
};