import React, { FunctionComponent } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button, Menu } from "antd";

import { DeleteIcon, MenuIcon } from "../../content/icons";
import { AtticusDropdown } from "../Shared/Dropdowns";
import { Image, ImageDeleteFrom } from "../../types/image-gallery";

const SystemImageFolders: string [] = ["atticus-images"];

interface ImageProps {
  image: Image;
  handleSelectImage: (link: string) => void;
  handleDeleteImage: (
    event: any,
    image: Image,
    deleteFrom: ImageDeleteFrom
  ) => void;
}

interface ImageDropDownProps {
  image: Image;
  handleDeleteImage: (
    event: any,
    image: Image,
    deleteFrom: ImageDeleteFrom
  ) => void;
}

export const ImageDropDownMenu: FunctionComponent<ImageDropDownProps> = (
  props: ImageDropDownProps
) => {
  const { image, handleDeleteImage } = props;
  return (
    <AtticusDropdown
      trigger={["click"]}
      placement="topRight"
      overlay={
        <Menu
          items={[
            {
              key: "image-delete-gallery",
              label: "Delete from gallery",
              title: "Delete title",
              icon: <DeleteIcon size={16} />,
              className: "delete-from-gallery-btn", 
              onClick: (e) => handleDeleteImage(e, image, "gallery"),
            },
            // {
            //   key: "image-delete-s3",
            //   label: "Delete from gallery and books",
            //   danger: true,
            //   icon: <DeleteOutlined />,
            //   onClick: (e) => handleDeleteImage(e, image, "s3"),
            // },
          ]}
        />
      }
    >
      <Button
        className="image-card-option-button"
        type="link"
        size="small"
        icon={<MenuIcon />}
      />
    </AtticusDropdown>
  );
};

export const GalleryImage: FunctionComponent<ImageProps> = (
  props: ImageProps
) => {
  const { image, handleDeleteImage } = props;
  const selectImage = (imageLink: string) => {
    props.handleSelectImage(imageLink);
  };

  const canDeleteImage = !SystemImageFolders.includes(image.folderName);
  const shouldContainImage = image.folderName === "atticus-images";

  return (
    <div
      className="image-gallery-wrapper"
      onClick={() => selectImage(image.link)}
    >
      <div className="image-gallery-image-wrapper">
        <LazyLoadImage src={image.link} className={`image-gallery-image ${shouldContainImage ? "contain" : ""}`} />
      </div>
      <div className="image-gallery-info-wrapper">
        <div className="image-gallery-image-name-wrapper">
          <p className="image-gallery-image-name">{image._imgId}</p>
        </div>
        {canDeleteImage && <ImageDropDownMenu image={image} handleDeleteImage={handleDeleteImage} />}
      </div>
    </div>
  );
};
