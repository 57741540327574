import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import EditorLayout from "../../containers/layouts/Editor";

import { EditChapter } from ".";
import Sider from "../Chapters/Sider";
import { EditBook } from "../Books";
import Previewer from "../Previewer/Previewer";
import ThemeWrapper from "../Theme/ThemeWrapper";
import Loading from "../Shared/Loading";
import AboutAuthorEditor from "./AboutAuthor";

import { getHashMap, getEditorType } from "../../utils/helper";

import useRootStore from "../../store/useRootStore";

import AtticusAnimation_v2 from "../../content/images/AtticusAnimation_v2.gif";
import { GetAllChapterTemplates } from "../../utils/offline.book.helpers";
import { mapChaptersToItems } from "../../utils/sidebar";
import { getAncestralChapters } from "../../utils/chapter";
import { SideBarChapterItem } from "../../types/sidebar";
import {RHSider} from "../Chapters/RHSBar";

const EditorView: React.FC = () => {
  const {
    chapterLoading,
    bookEdit,
    sceneIndex,
    setBookEdit,
    getAndSetCurBook,
    getAndSetCurChapter,
    getAndSetChapterTemplate,
    chapterTemplate: chapTemplate,
    initSceneCache,
    sceneCacheMap,
    getCurrentStoredBook,
  } = useRootStore().bookStore;
  const {
    setMainChapterTemplate,
    setChapterTemplateView,
    themeview,
    setThemeView,
    writing,
    setWriting,
    editor_setting,
  } = useRootStore().appStore;
  const { init, resetSelectedChapters, handleCollapseChange } =
    useRootStore().sideMenuStore;
  const { loadTemplates } = useRootStore().chapterStore;

  const [mounted, setMounted] = useState(false);
  const { location } = useHistory();

  const { urlFragments, type } = getEditorType(location.pathname);

  const chapterTemplateView = type === "chapter-template";
  const bookView = type === "books";

  const bookId = chapterTemplateView
    ? null
    : (useParams() as { bookId: string }).bookId;

  const hashObject = getHashMap(location.hash);

  const chapterId = bookView ? (hashObject["chapter"] as string) : null;
  const chapterTemplateId = chapterTemplateView
    ? (urlFragments[1] as string)
    : null;
  const frontMatterType =
    type === "frontmatter" ? (hashObject["frontmatter"] as string) : null;
  
  const isChapterTemplate = type === "chapter-template";

  const mountBook = async () => {
    try {
      if (!bookId) throw "Book ID not found";
      else {
        //Get Book from Db
        const b = await getAndSetCurBook(bookId);

        if (!b) throw "Book not found";
        else {
          //get and set theme after book is set
          //const t = await getAndSetTheme(b.themeId);

          initSceneCache();
          //get book with chapter meta
          const book = getCurrentStoredBook();
          const chapterMeta = [...book.frontMatter, ...book.chapters];
          let initialChapterId: string | undefined;

          if (
            chapterId &&
            (b.chapterIds.includes(chapterId) ||
              b.frontMatterIds.includes(chapterId))
          ) {
            initialChapterId = chapterId;
          } else if (b.chapters && b.chapters.length > 0) {
            //if first chapter is volume, then go to its' next chapter
            if (b.chapters[0].type === "volume") {
              initialChapterId = book.chapters[1]._id;
            } else {
              initialChapterId = book.chapters[0]._id;
            }
          } else if (b.frontMatterIds && b.frontMatterIds.length > 0) {
            initialChapterId = book.frontMatter[0]._id;
          }
          const initialSideBarChapterItem = mapChaptersToItems(
            book,
            sceneCacheMap
          ).find(
            (item) =>
              item.type === "chapter" && item.chapter._id === initialChapterId
          ) as SideBarChapterItem;
          if (initialChapterId && initialSideBarChapterItem) {
            // expand all chapters up to the initial chapter
            for (const ancestralChapter of getAncestralChapters(
              initialChapterId,
              chapterMeta
            )) {
              handleCollapseChange(ancestralChapter._id, false);
            }
            init(
              chapterMeta,
              getAndSetCurChapter,
              initialSideBarChapterItem,
              book._id
            );
            await getAndSetCurChapter(initialSideBarChapterItem.chapter._id);
          }
        }
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const mountChapterTemplates = async () => {
    if (chapterTemplateId) {
      setBookEdit(false);
      setChapterTemplateView(true);
      setThemeView(false);
      setWriting(true);
      await getAndSetChapterTemplate(chapterTemplateId);
    } else {
      setBookEdit(false);
      setChapterTemplateView(true);
      setThemeView(false);
      setWriting(true);
      await getAndSetChapterTemplate(chapTemplate._id);
    }
  };

  const renderAllTemplates = async () => {
    const getTempsFromDb = await GetAllChapterTemplates();

    const filtered = getTempsFromDb?.filter((a) => a._id === chapterTemplateId);

    if (filtered) {
      for (const temp of filtered) {
        setMainChapterTemplate(temp);
      }
    }
  };

  useEffect(() => {
    if (bookId != null) {
      mountBook().then(() => setMounted(true));
    }
  }, [bookId]);

  useEffect(() => {
    if (chapterId && !chapterTemplateId) {
      if (sceneIndex !== null) {
        resetSelectedChapters();
      }
      getAndSetCurChapter(chapterId, sceneIndex);
    }
  }, [chapterId]);

  useEffect(() => {
    if (chapterTemplateView) {
      mountChapterTemplates().then(() => setMounted(true));
      renderAllTemplates();
    } else {
      setChapterTemplateView(false);
    }
  }, [chapterTemplateView]);

  useEffect(() => {
    loadTemplates();
  }, []);

  const LoadingView = () => (
    <div className="book-load-wrapper">
      <Loading />
    </div>
  );

  const PageLayout = ():JSX.Element => {
    if (!mounted) {
      return (
        <div className="loading-animation-container">
          <img className="loading-animation" src={AtticusAnimation_v2} />
        </div>
      );
    }
    if (frontMatterType) {
      return (
        <EditorLayout.Wrapper>
          <EditorLayout.Left>
            <Sider />
          </EditorLayout.Left>
          <EditorLayout.Center>
            <AboutAuthorEditor />
          </EditorLayout.Center>
        </EditorLayout.Wrapper>
      );
    }
    if (themeview) {
      return (
        <EditorLayout.Wrapper>
          <EditorLayout.Center>
            <ThemeWrapper />
          </EditorLayout.Center>
          <EditorLayout.Right>
            <Previewer />
          </EditorLayout.Right>
        </EditorLayout.Wrapper>
      );
    }
    if (bookEdit) {
      return <EditBook />;
    }
    return (
      <EditorLayout.Wrapper>
        <EditorLayout.Left>
          <Sider />
        </EditorLayout.Left>
        <EditorLayout.Center>
          {chapterLoading ? (
            <LoadingView />
          ) : (
            <EditChapter
              isChapterTemplate={chapterTemplateView}
              chapterTemplateId={chapterTemplateId}
            />
          )}
        </EditorLayout.Center>
        { !isChapterTemplate && (!writing ? (
          <EditorLayout.Right>
            <Previewer />
          </EditorLayout.Right>
        ) : (
          <>
            <EditorLayout.RHSBar>
              <RHSider />
            </EditorLayout.RHSBar>
          </>
        ))}
      </EditorLayout.Wrapper>
    );
  };

  return PageLayout();
};

export default observer(EditorView);
