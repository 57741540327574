import React from "react";
import { observer } from "mobx-react";
import { Row, Col } from "antd";

import useRootStore from "../../store/useRootStore";

import { AtticusSelect, AtticusSearchField } from "../Shared/Form";
import { useHistory, useLocation } from "react-router-dom";

const sortOptions = [
  {
    label: "Recently added",
    value: "recently-added"
  },
  {
    label: "Date modified",
    value: "date-modified"
  },
  {
    label: "Title, A-Z",
    value: "alphabetically-asc"
  },
  {
    label: "Project, A-Z",
    value: "project-asc"
  },
  {
    label: "Author, A-Z",
    value: "author-asc"
  },
  {
    label: "Version, A-Z",
    value: "version-asc"
  }
];

export const BookFilter = observer(() => {
  const { sortBy, searchTerm, setSortBy, setSearchTerm } = useRootStore().shelfStore;
  const { push } = useHistory();
  const { search }  = useLocation();

  const handleSortChange = (value: IShelfStore.BookSortOptionType) => {
    const params = new URLSearchParams(search);
    params.set("sort", value);
    const queryString = params.toString();

    setSortBy(value);
    push({
      search: queryString,
    });
  };

  const handleSearchChange = (e) => {
    const params = new URLSearchParams(search);

    if(e.target.value)
      params.set("search", e.target.value);
    else
      params.delete("search");
    
    const queryString = params.toString();

    setSearchTerm(e.target.value);
    push({
      search: queryString,
    });
  };

  return (
    <Row gutter={16}>
      <Col>
        <AtticusSearchField
          value={searchTerm}
          width={"320px"}
          className="book-filter-search"
          placeholder="Search by title, author, version or project "
          onChange={handleSearchChange}
        />
      </Col>
      <Col>
        <AtticusSelect
          value={sortBy}
          selectOptions={sortOptions}
          onChange={handleSortChange}
          className="book-sort-select"
        />
      </Col>
    </Row>
  );
});
