import React from "react";
import { useEventPlateId, usePlateEditorState } from "@udecode/plate-core";
import { Button, Popconfirm } from "antd";
import { removeOrnamentalBreak } from "../transforms/removeOrnamentalBreak";

import useRootStore from "../../../../../store/useRootStore";
import { DeleteIcon } from "../../../../../content/icons";

export const OrnamentalBreakComponent = ({ children, element, id }) => {
    const editor = usePlateEditorState(useEventPlateId(id));

    const { setExpectOrnamentalBreakChange, setSceneLabelsInOrnamentalBreakAtDeletion } = useRootStore().sideMenuStore;

    return (
        <div className="slate-ornamental-break" >
            <div></div>
            <div contentEditable={false}
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <span>{"***"}</span>
            </div>
            {children}
            <div>
                <Popconfirm
                    title="Are you sure want to delete this Ornamental Break?"
                    onConfirm={() => {
                        //setting the editor onChange event ornamental break control flag 
                        setExpectOrnamentalBreakChange(true);

                        setSceneLabelsInOrnamentalBreakAtDeletion(element.sceneLabels);
                        removeOrnamentalBreak(element, editor);
                    }}
                    okButtonProps={{
                        danger: true
                    }}
                    okText="Delete"
                    cancelText="Cancel"
                >
                    <Button>
                        <DeleteIcon />
                    </Button>
                </Popconfirm>
            </div>
        </div>
    );
};
