import React from "react";
import { Select, SelectProps } from "antd";

interface AtticusSelectProps extends Omit<SelectProps, "type"> {
  type?: "primary" | "secondary";
  selectOptions?: {
    label: any,
    value: string,
    classNames?: string
  }[];
  children?: React.ReactNode[]
}

const { Option } = Select;

export const AtticusSelect: React.FC<AtticusSelectProps> = (props) => {
  const { selectOptions, children, type } = props;

  const renderChildren = () => {
    if(selectOptions) {
      return (
        selectOptions.map((option) => (
          <Option className={option.classNames || ""} key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))
      );
    }

    if(children) {
      return children;
    }

    return <></>;
  };
 
  return (

    <Select
      {
        ...{
          ...props,
          popupClassName: `at-form-select-dropdown ${props.popupClassName}`,
          className: `at-form-select ${props.className || ""} ${type || ""}`
        }
      }
    >
      {renderChildren()}
    </Select>
  );
};
