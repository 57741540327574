import { findNodePath, setNodes } from "@udecode/plate";
import { MyEditor } from "../../../config/typescript";
import { TEndNote } from "../types";
import { PlateNodes } from "../../../config/typescript";

export const updateEndnoteNode = (editor: MyEditor, element: TEndNote, note: PlateNodes | null): void => {
  const path = findNodePath(editor, element);

  setNodes<TEndNote>(
    editor,
    {
      ...element,
      note,
    },
    { at: path }
  );
};
