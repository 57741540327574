import {
  createPluginFactory,
} from "@udecode/plate";
import { EndNotesComponent } from "./components";
import { ELEMENT_END_NOTE } from "./types";

/**
 * Enables support for endnotes.
 */
export const createEndNotesPlugin = createPluginFactory({
  key: ELEMENT_END_NOTE,
  isElement: true,
  isVoid: true,
  isInline: true,
  component: EndNotesComponent,
});
