import { createPluginFactory } from "@udecode/plate-core";
import { BlockquoteComponent } from "./components/blockquoteComponent";

export const ELEMENT_BLOCKQUOTE = "blockquote";

export const createBlockquotePlugin = createPluginFactory({
  key: ELEMENT_BLOCKQUOTE,
  isElement: true,
  type: "blockquote",
  component: BlockquoteComponent,
  deserializeHtml: {
    rules: [
      { validNodeName: ["BLOCKQUOTE"] },
    ]
  },
  options: {
    hotkey: ["mod+opt+9", "mod+shift+9"],
  },
});
