export const MergableChapters = [
	"uncategorized", "chapter", "custom", "about-author", "epigraph", "foreword",
	"introduction", "preface", "prologue", "epilogue",
	"afterword", "acknowledgments", "also-by", "dedication", "blurbs"
];

export const ChapterTypeLabels = {
	title: "Title Page",
	copyrights: "Copyright",
	toc: "Table of contents",
	chapter: "Chapter",
	image: "Full page image",
};

export const sectionTypes = {
	frontMatter: ["title"],
	body: ["chapter", "image"],
};

export const nonDraggableChapterTypes = ["endnotes", "toc"];

export const nonDeleteableChapterTypes = ["endnotes", "toc"];

export const nonConvertableChapterTypes = ["toc", "title", "image", "endnotes", "part", "volume", "copyrights", "chapter"];

export const getLabel = (type: string): string => {
	return ChapterTypeLabels[type] ? ChapterTypeLabels[type] : "";
};

export const isMergable = (type?: string): boolean => {
	if (type === undefined) return true;
	return MergableChapters.indexOf(type) > -1;
};


export function getDragStyle({ provided, style, isDragging }) {
	// If you don't want any spacing between your items
	// then you could just return this.
	// I do a little bit of magic to have some nice visual space
	// between the row items
	const combined = {
		...style,
		...provided.draggableProps.style
	};

	const marginBottom = 8;
	const withSpacing = {
		...combined,
		height: isDragging ? combined.height : combined.height - marginBottom,
		marginBottom
	};
	return withSpacing;
}


export const dragHandler = (itemType: string, chapterType: string): boolean => {
	if (itemType === "scene") {
		return false;
	}

	if(itemType === "chapter"){
		if(nonDraggableChapterTypes.includes(chapterType)){
			return true;
		}
	}

	if(itemType === "body-seperator"){
		return true;
	}

	return false;
};