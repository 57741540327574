import React from "react";
import {
  getPluginType,
  useEventPlateId,
  usePlateEditorState,
} from "@udecode/plate-core";
import { BlockToolbarButton, ToolbarButtonProps } from "@udecode/plate-ui-toolbar";
import { allowFeatureForList } from "../../../config/lists";
import { ELEMENT_BLOCKQUOTE } from "../createBlockquotePlugin";
import { allowFeatureForTextMessages } from "../../text-message/config";

export const BlockquoteToolbarButton = ({ id, icon }: ToolbarButtonProps) => {
  const editor = usePlateEditorState(useEventPlateId(id));

  const enabled = allowFeatureForList(editor) && allowFeatureForTextMessages(editor);

  return (
    <div className={`${enabled ? "" : "plate-disabled"}`}>
      <BlockToolbarButton
        type={getPluginType(editor, ELEMENT_BLOCKQUOTE)}
        icon={icon}
      />
    </div>
  );
};
