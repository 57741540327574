import { createPluginFactory } from "@udecode/plate-core";
import { CalloutBoxComponent } from "./components/CalloutBoxComponent";
import { withCalloutBox } from "./normalizers/withCalloutBox";

export const ELEMENT_CALLOUTBOX = "calloutbox";

export const createCalloutBoxPlugin = createPluginFactory({
	key: ELEMENT_CALLOUTBOX,
	isElement: true,
	component: CalloutBoxComponent,
	withOverrides: withCalloutBox
});