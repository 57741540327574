import React from "react";
import { observer } from "mobx-react";
import { Formik } from "formik";
import { Col, Row, Form, Input, message, FormItemProps } from "antd";

import { AtticusClient } from "../api/atticus.api";
import { Button } from "../components/Shared/Buttons";

interface FormErrors {
  password?: string;
  newPassword?: string;
  confirmPassword?: string;
}

const commonFormItemProps = {
  labelAlign: "left",
  colon: false,
} as FormItemProps;

export const ChangePassword = observer(() => {
  return (
    <Formik
      isInitialValid={false}
      initialValues={{
        password: "",
        newPassword: "",
        confirmPassword: "",
      }}
      validate={(values) => {
        const errors: FormErrors = {};

        const requiredFields = ["password", "newPassword", "confirmPassword"];

        requiredFields.forEach((field) => {
          if (!values[field]) {
            errors[field] = "Required";
          }
        });

        if (values.newPassword && values.newPassword.length < 6) {
          errors.newPassword =
            "Your password should have at least 6 characters";
        }

        if (
          values.newPassword !== values.confirmPassword &&
          values.confirmPassword
        ) {
          errors.confirmPassword = "Oops... Your passwords don't match";
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSubmitting(true);
          const passwords = {
            oldPassword: values.password,
            newPassword: values.newPassword,
          };

          await AtticusClient.UpdatePassword(passwords);
          message.success("Your password has been updated successfully!", 3);
        } catch (e: any) {
          setSubmitting(false);
          message.error("Your current password is incorrect", 3);
        }
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
      }) => (
        <>
          <Form
            onSubmitCapture={handleSubmit}
            colon={false}
            layout={"vertical"}
          >
            <div className='my-accoutn-card'>
              <h3 className='title'>Change password</h3>
              <Row className='top-password-row'>
                <Col span={24} xl={10} xxl={8}>
                  <Form.Item
                    label='Current password'
                    {...commonFormItemProps}
                    help={errors.password}
                    validateStatus={errors.password ? "error" : ""}
                  >
                    <Input
                      className='password-imput-field'
                      placeholder='Current password'
                      name='password'
                      type='password'
                      onChange={handleChange}
                      value={values.password}
                      size='large'
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='password-row'>
                <Col span={24} xl={10} xxl={8}>
                  <div className='form-field' style={{ paddingBottom: 0 }}>
                    <Form.Item
                      label='New password'
                      {...commonFormItemProps}
                      help={errors.newPassword}
                      validateStatus={errors.newPassword ? "error" : ""}
                    >
                      <Input
                        className='password-imput-field'
                        placeholder='New password'
                        name='newPassword'
                        type='password'
                        onChange={handleChange}
                        value={values.newPassword}
                        size='large'
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row className='password-row'>
                <Col span={24} xl={10} xxl={8}>
                  <div className='form-field'>
                      <Form.Item
                        label='Re-enter new password'
                        {...commonFormItemProps}
                        help={errors.confirmPassword}
                        validateStatus={errors.confirmPassword ? "error" : ""}
                      >
                        <Input
                          className='password-imput-field'
                          placeholder='Confirm password'
                          name='confirmPassword'
                          type='password'
                          onChange={handleChange}
                          value={values.confirmPassword}
                          size='large'
                        />
                      </Form.Item>
                  </div>
                </Col>
              </Row>
              <div className='form-field'>
                <Button
                  className='update-button'
                  type='at-primary'
                  htmlType='submit'
                  disabled={isSubmitting || !isValid ? true : false}
                  loading={isSubmitting}
                >
                  {isSubmitting ? "" : "Update"}
                </Button>
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
});
