import React from "react";
import type { DropdownProps } from "antd";
import { Dropdown} from "antd";

interface AtticusCheckBoxProps extends Omit<DropdownProps, "type"> {
  type?: "primary" | "secondary";
  label?: string;
  onClick?: () => void;
}

export const AtticusDropdown: React.FC<AtticusCheckBoxProps> = (props) => {
  const { overlay, ...rest } = props;

  return (
    <Dropdown overlay={overlay} {...rest}>
      <a onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}>
        {props.children}
      </a>
    </Dropdown>
  );
};
