import React, { FunctionComponent, useState } from "react";
import { Row, Col, Button, message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

// types
import { Book } from "../types/book";

import { AtticusClient } from "../api/atticus.api";

export const BookDebugger: FunctionComponent = () => {
  const [ code, setCode ] = useState("");
  const [ validationErrors, setValidationErrors ] = useState<string | null>();

  const validateErrors = async () => {
    try {
      const bookTmp = JSON.parse(code);
      delete bookTmp["_id"];
      await AtticusClient.ValidateBook(bookTmp as Book);
      message.success("Validation passed");
      setValidationErrors(null);
    } catch (e: any) {
      if (e.response) setValidationErrors(JSON.stringify(e.response.data));
    }
  };

  return (
    <div className="container">
      <Row gutter={18} style={{ padding: "2rem 2rem "}}>
        <Col span={12}>
          <h2 style={{ marginBottom: "1rem" }}>Debug Book</h2>
          <Upload 
            multiple={false}
            type="select"
            accept="application/json"
            showUploadList={false}
            beforeUpload={file => {
              const reader = new FileReader();
      
              reader.onload = e => {
                if (e && e.target) setCode(e.target.result as string);
              };
              reader.readAsText(file);
      
              // Prevent upload
              return false;
            }}
          ><Button type="default"><UploadOutlined /> Upload Snapshot</Button></Upload>
          <div style={{ height: 500, overflow: "scroll", backgroundColor: "#FFFFFF", marginTop: "1rem", marginBottom: "1rem" }}>
            <Editor
              value={code}
              onValueChange={code => setCode(code)}
              highlight={code => highlight(code, languages.javascript)}
              padding={10}
            />
          </div>
          <Button type="primary" block size="large" onMouseDown={() => validateErrors()}>Validate Snapshot</Button>
        </Col>
        <Col span={12}>
          <h2 style={{ marginBottom: "1rem" }}>Validation Errors</h2>
          {validationErrors ? (
            <code>{validationErrors}</code>
            ) : null}
        </Col>
        </Row>
    </div>
  );
};