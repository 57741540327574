import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import useRootStore from "../../../../store/useRootStore";
import { FavouriteIconFill, FavouriteIconOutline } from "../../../../content/icons";
import { Button } from "antd";

interface FavouriteThemeActionButtonProps {
  styleId: string
  favourite: boolean
}

export default observer(({ styleId, favourite }: FavouriteThemeActionButtonProps) => {
  const { toggleFavourite } = useRootStore().themeStore;
  const [isFav, setIsFav] = useState<boolean>(favourite);

  const handler = () => {
    setIsFav(!isFav);
    toggleFavourite(styleId, !isFav);
  };

  useEffect(() => {
    setIsFav(favourite);
  }, [favourite]);

  return (
    <Button
      className="btn-theme-favourite"
      type="primary"
      shape="circle"
      size="small"
      icon={isFav ? <FavouriteIconFill /> : <FavouriteIconOutline />}
      onClick={handler}
    />
  );
});
