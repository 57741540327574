import React, { useState, FunctionComponent } from "react";
import { Button as Antbutton, Row, Col } from "antd";
import ImageGallery from "../../ImageGallery";

// helpers
import FileUpload from "../Forms/FileUpload";
import BookBrush from "../Forms/BookBrush";
import { Button } from "../Buttons";
import { DeleteIcon, GalleryAddIcon, GalleryEditIcon, GalleryIcon } from "../../../content/icons";

interface ImageSelectorProps {
  image: string;
  folderName: string;
  defaultWidth?: string;
  onUpdate: (url: string) => void;
  isImageDeleted?: boolean,
  imageSelectorText?: React.ReactElement;
}

export const ImageSelector: FunctionComponent<ImageSelectorProps> = (props: ImageSelectorProps) => {
  const { onUpdate, image, folderName, defaultWidth = "auto" } = props;
  const [uploading, setUploading] = useState<boolean>(false);

  const deleteImage = () => onUpdate("");

  return (
    <div className="at-image-selector">
      <Row gutter={16}>
        <Col>
          {uploading ? (
            <Button
              type="at-ghost"
              loading={true}
              disabled={true}
              size="small"
            >
              Uploading
            </Button>
          ) : (
            <FileUpload
              fileType="image/png, image/jpg, image/jpeg"
              shape='none'
              folder={folderName}
              onFileUpload={(fileUrl) => {
                onUpdate(fileUrl || "");
              }}
              onFileUploadStart={() => setUploading(true)}
              onFileUploadEnd={() => setUploading(false)}
              isImageDeleted={props.isImageDeleted}
            >
              <Button
                type="at-ghost"
                size="small"
                icon={<GalleryAddIcon color="#3568BA" />}
              >
                Upload image
              </Button>
            </FileUpload>
          )}
        </Col>
        <Col>
          <ImageGallery
            handleImageUrl={(url) => onUpdate(url)}
            renderButtonProps={{
              type: "at-ghost",
              size: "small",
              icon: <GalleryIcon color="#3568BA" />
            }}
          />
        </Col>
        <Col>
          <BookBrush
            type="at-ghost"
            size="small"
            icon={<GalleryEditIcon color="#3568BA" />}
          >
            Create image with book brush
          </BookBrush>
        </Col>
      </Row>
      {image ? (
        <div className="inner-s">
          <Row 
            className="at-image-selector-image" 
            style={{ 
              backgroundImage: `url(${image})`,
              maxWidth:  defaultWidth
            }} 
          >
            <Antbutton 
              onClick={deleteImage}
              className="at-image-selector-delete-btn" 
              icon={<DeleteIcon />} 
            />
          </Row>
        </div>
      ) : (
        <div className="at-image-selector-previewer">
          <p>{props.imageSelectorText || "No image selected" }</p>
        </div>
      )}
    </div>
  );
};