import { SpecialTemplate } from "./";

export const AboutTheAuthor: SpecialTemplate = {
  title: "About the author",
  children: [
    {
      type: "p",
      children: [
        {
          text: "This page is for authors to share their bio with their readers. How it is written will depend on the genre you write as well as your desired relationship with your audience.",
        },
      ],
    },
    {
      type: "p",
      children: [
        {
          text: "The Author bio page is usually written in third person and shares information about the author such as where they are from, what their hobbies and sources of inspiration are, how and why they became an author, or why they write the type of content they write.",
        },
      ],
    },
  ],
  configuration: {
    showChapterImage: false,
    showPageNumber: true,
    showChapterHeading: true,
    showHeaderFooter: true,
    showInTableOfContents: true,
    showFirstSentenceFormatting: false,
  },
};
