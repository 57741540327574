import React from "react";
import {
  useEventPlateId,
  usePlateEditorState,
  someNode
} from "@udecode/plate-core";
import { ToolbarButtonProps } from "@udecode/plate-ui-toolbar";
import { allowFeatureForList } from "../../../config/lists";
import { ELEMENT_TEXT_MESSAGE } from "../createTextMessagePlugin";
import { ToolbarButton, getPreventDefaultHandler } from "@udecode/plate";
import { toggleTextMessage } from "../transforms/toggleTextMessage";
import { allowFeatureForCalloutBoxes } from "../../callout-box/config";

export const TextMessageToolbarButton = ({
  id,
  ...props
}: ToolbarButtonProps) => {
  const editor = usePlateEditorState(useEventPlateId(id));

  const enabled = allowFeatureForList(editor) && allowFeatureForCalloutBoxes(editor);

  return (
    <div className={`${enabled ? "" : "plate-disabled"}`}>
      <ToolbarButton
        active={
          !!editor?.selection &&
          someNode(editor, { match: { type: ELEMENT_TEXT_MESSAGE } })
        }
        onMouseDown={
          editor
            ? getPreventDefaultHandler(toggleTextMessage, editor)
            : undefined
        }
        {...props}
      />
    </div>
  );
};
