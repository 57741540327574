import React from "react";

export const selectItems = [
  {
    key: 1,
    value: "solid",
    icon: (
      <div
        style={{
          display: "flex",
          border: "2px solid black",
          width: "85px",
        }}
      ></div>
    ),
  },
  {
    key: 2,
    value: "dotted",
    icon: (
      <div
        style={{
          border: "2px dotted black",
          width: "85px",
        }}
      ></div>
    ),
  },
  {
    key: 3,
    value: "dashed",
    icon: (
      <div
        style={{
          border: "2px dashed black",
          width: "85px",
        }}
      ></div>
    ),
  },
];

export const backgroundPresetColors = [
  "#EEEEEE",
  "#DDDDDD",
  "#CCE6CC",
  "#FFFDD0",
  "#D1E0FD",
  "#FFE8D3",
  "#FFDCDC",
  "#EFE2FF",
];

export const borderPresetColors = [
  "#000000",
  "#545454",
  "#006B00",
  "#F6EC17",
  "#345699",
  "#EB7A13",
  "#C21B1B",
  "#7954A6",
];

export const DEFAULT_PRESET_ID = "Select a preset";
export const PRESET_TYPE = "calloutbox";
