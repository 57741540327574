import { ColorPickerValue } from "antd-colorpicker";
import { makeAutoObservable } from "mobx";
import { CalloutBackground, CalloutBorder } from "../types/slate";

interface CalloutModalValues {
  background: CalloutBackground;
  border: CalloutBorder;
}
export class CalloutModalStore {
  background = {
    fill: false,
    fillColor: "#EEEEEE",
    fillOpacity: 1,
  };

  border = {
    border: true,
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: 0,
    borderOpacity: 1,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setValues(values: CalloutModalValues): void {
    this.background = { ...this.background, ...values.background };
    this.border = { ...this.border, ...values.border };
  }

  setFill = (fill: boolean): void => {
    this.background.fill = fill;
  };
  setFillColor = (color: ColorPickerValue): void => {
    this.background.fillColor = color;
  };
  setFillOpacity = (opacity: number): void => {
    this.background.fillOpacity = opacity;
  };
  setBorder = (border: boolean): void => {
    this.border.border = border;
  };
  setBorderColor = (color: ColorPickerValue): void => {
    this.border.borderColor = color;
  };
  setBorderStyle = (style: string): void => {
    this.border.borderStyle = style;
  };
  setBorderWidth = (width: number): void => {
    this.border.borderWidth = width;
  };
  setBorderRadius = (radius: number): void => {
    this.border.borderRadius = radius;
  };
  setBorderOpacity = (opacity: number): void => {
    this.border.borderOpacity = opacity;
  };
}

export default new CalloutModalStore();
