import React, { FunctionComponent } from "react";
import { observer } from "mobx-react";
import { Row, Col, Button } from "antd";

import useRootStore from "../../store/useRootStore";
import { BackIcon } from "../../content/icons";

interface BookDetailsNavProps {
  children: JSX.Element;
  handleEditBookDetailsOnClick?: () => void;
  isChapterTemplate?: boolean;
}

export const BookDetailsNav: FunctionComponent<BookDetailsNavProps> = observer(
  ({ children, handleEditBookDetailsOnClick, isChapterTemplate }) => {
    const { book, bookEdit, chapterTemplate } = useRootStore().bookStore;

    return (
      <>
        <Row gutter={16} className="book-details-nav" >
          <Col>{children}</Col>
          <Col span={12} className="book-details-nav-title-col">
            <h1 className="book-details-nav-title" >{!isChapterTemplate ? book.title : chapterTemplate.title}</h1>
          </Col>
          {!isChapterTemplate && (
            <Col >
              <Button
                className="book-details-nav-btn"
                onClick={handleEditBookDetailsOnClick}
                icon={bookEdit ? <BackIcon /> : null}
              >
                {bookEdit ? "Back" : "Edit book details"}
              </Button>
            </Col>
          )}
        </Row>
      </>
    );
  }
);
