import axios from "axios";

import { Book } from "../types/book";
import { Chapter, IChapterTemplateBase } from "../types/chapter";
import {
  CreateWritingHabit,
  WritingHabit,
} from "./../components/Goals/WritingHabit/types";

// stores
import authStore from "../store/Auth";
import { IPresetBase } from "../types/presets";

const baseURL = () => {
  const apiUrl = process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : "http://localhost:4000";
  return apiUrl;
};

const endpoints = {
  theme: {
    get: "/book-themes",
    post: "/book-themes",
    put: (id: string) => "/book-themes/" + id,
    delete: (id: string) => "/book-themes/" + id,
  },
  theme_favs: {
    add: "/book-theme-favourites",
    delete: (id: string) => "/book-theme-favourites/" + id,
  },
  book: {
    switch_theme: (bookId: string) => `/books/${bookId}/theme`,
  },
  writing_habit: {
    get: "/writing-habits",
    post: "/writing-habits",
    put: "/writing-habits",
    delete: "/writing-habits",
  },
  fonts: {
    get: "/fonts",
    favourite: {
      post: "/fonts/favourite",
      get: "/fonts/user-favourites",
      delete: (fontid: string) => `/fonts/favourite/${fontid}`,
    }
  }
};

const getAuthInstance = () => {
  const token = authStore.token;
  const instance = axios.create({
    headers: { Authorization: `Bearer ${token}` },
    baseURL: baseURL(),
  });
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        if (
          !(
            window.location.pathname === "/auth/sign-out" ||
            window.location.pathname === "/auth/sign-in"
          )
        ) {
          window.location.href = "/auth/sign-out?expired=true";
        }
      }
      return Promise.reject(error);
    }
  );
  return instance;
};

const getInstance = () => {
  return axios.create({
    baseURL: baseURL(),
  });
};
interface UpdateResponse {
  timestamp: Date;
}

interface InviteDetails {
  firstName: string;
  lastName: string;
}

interface SignUpRequest {
  inviteId: string;
  inviteCode: string;
  firstName: string;
  lastName: string;
  password: string;
}

interface ImportBookRequest {
  title: string;
  author: string[];
  project: string;
  url: string;
}

interface ImportChapterRequest {
  url: string;
  bookId: string;
  insertIntoVolume: boolean;
}
interface CreateChapterTemplateReturnResponse {
  timestamp: Date;
  templateId: string;
}

interface ImportBookResponse {
  bookId: string;
}

interface ImportChapterResponse {
  bookId: string;
}

export interface ProfileImage {
  file: any;
  profilePictureURL: string;
}

export interface ExportResponse {
  _id: string;
  bookId: string;
  processed: boolean;
  url: string;
  type: "pdf" | "epub";
  createdAt: Date;
  lastUpdateAt: Date;
  __v: number;
}

export interface Password {
  oldPassword: string;
  newPassword: string;
}

export interface FileUpload {
  fileBinary: Blob;
  fileId: string;
  path?: string;
  fileName?: string;
  contentType?: string;
}

interface CreateBoxsetRequest {
  title: string;
  author: string[];
  project: string;
  bookIds: string[];
}

interface CreateBoxsetResponse {
  bookId: string;
}

interface CreateBooklinkerSuccessRes {
  success: true,
  linkResult: string,
}
interface CreateBooklinkerErrorRes {
  success: false,
  responseStatus: {
    errorCode: string,
    message: string
  },
}
export type CreateBooklinkerResponse = CreateBooklinkerSuccessRes | CreateBooklinkerErrorRes;

export class AtticusClient {
  // Auth functions
  public static async FetchInvite(
    inviteId: string,
    inviteCode: string
  ): Promise<InviteDetails> {
    const authResp = await getInstance().get(`/auth/invite/${inviteId}`, {
      params: {
        inviteCode,
      },
    });
    return authResp.data;
  }

  public static async SignUp(
    values: SignUpRequest
  ): Promise<{ token: string;[key: string]: any }> {
    const authResp = await getInstance().post("/auth/signup", values);
    return authResp.data;
  }

  public static async SignIn(
    email: string,
    password: string
  ): Promise<{ token: string;[key: string]: any }> {
    const authResp = await getInstance().post("/auth/signin", {
      email,
      password,
    });
    return authResp.data;
  }

  public static async ForgotPassword(
    email: string
  ): Promise<{ token: string;[key: string]: any }> {
    const authResp = await getInstance().post("/auth/forgotPassword", {
      email,
    });
    return authResp.data;
  }

  public static async ResetPassword(
    userId: string,
    code: string,
    newPassword: string
  ): Promise<unknown> {
    const authResp = await getInstance().post("/auth/resetPassword", {
      userId,
      code,
      newPassword,
    });
    return authResp.data;
  }

  // Authenticated Functions
  public static async GetBooks(): Promise<{
    books: Book[];
    deletedBookIds: string[];
  }> {
    const booksResp = await getAuthInstance().get("/books");
    return booksResp.data;
  }

  public static async ValidateBook(book: Book): Promise<UpdateResponse> {
    const putBookResp = await getAuthInstance().post("/books/validate", book);
    return putBookResp.data;
  }

  public static async GetBook(bookId: string): Promise<Book> {
    const bookResp = await getAuthInstance().get(`/books/${bookId}`);
    return bookResp.data;
  }

  public static async PutBook(book: Book): Promise<UpdateResponse> {
    const putBookResp = await getAuthInstance().put(`/books/${book._id}`, book);
    return putBookResp.data;
  }

  public static async PatchBook(
    bookId: string,
    changes: Partial<Book>
  ): Promise<UpdateResponse> {
    const patchBookResp = await getAuthInstance().patch(
      `/books/${bookId}`,
      changes
    );
    return patchBookResp.data;
  }

  public static async DuplicateBook(bookId: string) {
    const postBookResp = await getAuthInstance().post(`/books/${bookId}/clone`);
    return postBookResp.data;
  }

  public static async DeleteBook(bookId: string): Promise<UpdateResponse> {
    const deleteBookResp = await getAuthInstance().delete(`/books/${bookId}`);
    return deleteBookResp.data;
  }

  // Chapter Updates
  public static async PutChapter(chapter: Chapter): Promise<UpdateResponse> {
    const putChapterResp = await getAuthInstance().put(
      `/books/${chapter.bookId}/chapters/${chapter._id}`,
      chapter
    );
    return putChapterResp.data;
  }

  public static async PatchChapter(
    bookId: string,
    chapterId: string,
    changes: Partial<Chapter>
  ): Promise<UpdateResponse> {
    const patchBookResp = await getAuthInstance().patch(
      `/books/${bookId}/chapters/${chapterId}`,
      changes
    );
    return patchBookResp.data;
  }

  public static async DeleteChapter(
    bookId: string,
    chapterId: string
  ): Promise<UpdateResponse> {
    const deleteBookResp = await getAuthInstance().delete(
      `/books/${bookId}/chapters/${chapterId}`
    );
    return deleteBookResp.data;
  }

  public static async UpdateThemeInBook(
    bookId: string,
    themeId: string
  ): Promise<any> {
    const response = await getAuthInstance().post(
      endpoints.book.switch_theme(bookId),
      { themeId }
    );
    return response.data;
  }

  public static async GetThemes(): Promise<IThemeStore.ThemeResponse[]> {
    const response = await getAuthInstance().get(endpoints.theme.get);
    return response.data;
  }

  public static async SaveNewTheme(
    theme: Omit<IThemeStore.ThemePayload, "_id">
  ): Promise<IThemeStore.ThemeResponse> {
    const response = await getAuthInstance().post(endpoints.theme.post, theme);
    return response.data;
  }

  public static async SaveTheme(
    theme: IThemeStore.Theme
  ): Promise<IThemeStore.ThemeResponse> {
    const response = await getAuthInstance().put(
      endpoints.theme.put(theme._id),
      theme
    );
    return response.data;
  }

  public static async DeleteTheme(themeId: string): Promise<any> {
    const response = await getAuthInstance().delete(
      endpoints.theme.delete(themeId)
    );
    return response.data;
  }

  public static async AddThemeToFavourites(themeId: string): Promise<any> {
    const response = await getAuthInstance().post(endpoints.theme_favs.add, {
      themeId,
    });
    return response.data;
  }

  public static async RemoveThemeFromFavourites(
    themeId: string
  ): Promise<UpdateResponse> {
    const deleteBookResp = await getAuthInstance().delete(
      endpoints.theme_favs.delete(themeId)
    );
    return deleteBookResp.data;
  }

  public static async ImportDocument(
    params: ImportBookRequest,
    fileType?: string
  ): Promise<ImportBookResponse> {
    const importResp = await getAuthInstance().post(
      `/books/import/${fileType ? fileType : "docx" || "mobi"}`,
      params
    );
    return importResp.data;
  }

  public static async ImportChapters(
    params: ImportChapterRequest
  ): Promise<ImportChapterResponse> {
    const response = await getAuthInstance().post(
      "/books/import-chapters",
      params
    );
    return response.data;
  }
  public static async ExportBook(
    bookId: string,
    type: "pdf" | "epub" | "docx"
  ): Promise<ExportResponse> {
    const exportResp = await getAuthInstance().post(
      `/books/${bookId}/export/${type}`
    );
    return exportResp.data;
  }

  public static async SaveSnapshot(
    bookId: string,
    json: string
  ): Promise<boolean> {
    const snapshotResp = await getInstance().post("/snapshots", {
      bookId,
      json,
    });
    return snapshotResp.data;
  }

  public static async SaveConflictChapter(
    chapter: Chapter,
    type: "OFFLINE" | "CONFLICT"
  ): Promise<boolean> {
    const conflictChapterResp = await getInstance().post("/conflicts", {
      chapter,
      type,
    });
    return conflictChapterResp.data;
  }

  public static async createBoxset(
    params: CreateBoxsetRequest
  ): Promise<CreateBoxsetResponse> {
    const response = await getAuthInstance().post("/books/boxset", params);
    return response.data;
  }

  // Profile related functions
  public static async GetProfile(): Promise<IAuthStore.ProfileProps> {
    const profile = await getAuthInstance().get("/me");
    return profile.data;
  }

  public static async UpdatePassword(
    changes: Partial<Password>
  ): Promise<ExportResponse> {
    const profile = await getAuthInstance().post("/me/updatePassword", changes);
    return profile.data;
  }

  public static async PatchProfile(
    changes: Partial<IAuthStore.ProfileFields>
  ): Promise<UpdateResponse> {
    const patchProfileResp = await getAuthInstance().patch("/me", changes);
    return patchProfileResp.data;
  }

  public static async SetBookGoal(goal: any): Promise<any> {
    const bookGoal = await getAuthInstance().post("/goals/setGoal", goal);
    return bookGoal.data;
  }

  public static async FetchLatestGoal(
    bookId: string,
    writtenWordCount: number,
    sDate: any
  ): Promise<any> {
    const bookGoal = await getAuthInstance().post("/goals/fetchGoal", {
      bookId,
      writtenWordCount,
      sDate,
    });
    return bookGoal.data;
  }

  public static async EditGoal(goal: any): Promise<any> {
    const bookGoal = await getAuthInstance().put("/goals/editGoal", goal);
    return bookGoal.data;
  }

  public static async GetProjectGoal(bookId: string): Promise<any> {
    const bookGoal = await getAuthInstance().get(`/goals/getGoal/${bookId}`);
    return bookGoal.data;
  }

  public static async DeleteGoal(bookId: string, goalId: string): Promise<any> {
    const bookGoal = await getAuthInstance().put("/goals/deleteGoal", {
      bookId,
      goalId,
    });
    return bookGoal.data;
  }

  public static async CompleteGoal(
    bookId: string,
    goalId: string,
    totalWrittenWordCount: number
  ): Promise<any> {
    const bookGoal = await getAuthInstance().put("/goals/completeGoal", {
      bookId,
      goalId,
      totalWrittenWordCount,
    });
    return bookGoal.data;
  }

  public static async CreateHabit(
    habit: CreateWritingHabit
  ): Promise<WritingHabit> {
    const writingHabit = await getAuthInstance().post(
      endpoints.writing_habit.post,
      habit
    );
    return writingHabit.data;
  }

  public static async UpdateHabit(habit: WritingHabit): Promise<WritingHabit> {
    const writingHabit = await getAuthInstance().put(
      endpoints.writing_habit.put,
      habit
    );
    return writingHabit.data;
  }

  public static async GetHabit(): Promise<WritingHabit | null> {
    const writingHabit = await getAuthInstance().get<WritingHabit | null>(
      endpoints.writing_habit.get
    );
    return writingHabit.data;
  }

  public static async DeleteHabit(): Promise<void> {
    await getAuthInstance().delete(endpoints.writing_habit.delete);
  }

  public static async CreateChapterTemplate(
    chapterId: string,
    templateName: string,
    section: string
  ): Promise<CreateChapterTemplateReturnResponse> {
    const postChapResp = await getAuthInstance().post(
      `/chapter-templates/${chapterId}`,
      {
        templateName,
        section,
      }
    );
    return postChapResp.data;
  }

  public static async CreateChapterFromTemplate(
    templateId: string,
    bookId: string
  ): Promise<UpdateResponse> {
    const postChapResp = await getAuthInstance().post(
      `/chapter-templates/clone/${templateId}/${bookId}`
    );
    return postChapResp.data;
  }

  public static async GetChapterTemplates(): Promise<IChapterTemplateBase[]> {
    const templateResp = await getAuthInstance().get(
      "/chapter-templates/filtered"
    );
    return templateResp.data;
  }
  public static async SyncChapterTemplate(
    updateAllBooks: boolean,
    updateTemplate: boolean,
    bookId?: string,
    templateId?: string,
    chapterId?: string,
  ): Promise<any> {
    const templateResp = await getAuthInstance().put(
      `/chapter-templates/${templateId}/sync`,
      {
        updateAllBooks,
        updateTemplate,
        bookId,
        chapterId
      }
    );
    return templateResp.data;
  }

  public static async DeleteChapterTemplate(templateId: string): Promise<any> {
    const templateResp = await getAuthInstance().delete(
      `/chapter-templates/${templateId}`
    );
    return templateResp.data;
  }

  public static async UpdateChapterTemplate(
    templateId: string,
    update: IChapterTemplateBase
  ): Promise<any> {
    const templateResp = await getAuthInstance().put(
      `/chapter-templates/${templateId}`,
      update
    );
    return templateResp.data;
  }

  public static async PatchChapterTemplate(
    templateId: string,
    changes: Partial<IChapterTemplateBase>
  ): Promise<UpdateResponse> {
    const patchBookResp = await getAuthInstance().patch(
      `/chapter-templates/${templateId}`,
      changes
    );
    return patchBookResp.data;
  }

  public static async DeleteImgInGallery(
    imgData: any,
    type: string
  ): Promise<any> {
    const img = await getAuthInstance().post("/imgGallery/deleteImg", {
      imgData,
      type,
    });
    return img.data;
  }

  public static async AddImgToGallery(imgData: any): Promise<any> {
    const img = await getAuthInstance().post("/imgGallery/addImage", {
      imgData,
    });
    return img.data;
  }

  public static async GetGallery(userId: any): Promise<any> {
    const img = await getAuthInstance().get(`/imgGallery/getGallery/${userId}`);
    return img.data;
  }

  public static async GetProfiles(): Promise<any> {
    const profileData = await getAuthInstance().get("/profiles/getProfiles");
    return profileData;
  }

  public static async CreateProfile(
    profile: ISocialProfileStore.ICreateSMProfile
  ): Promise<any> {
    const profileData = await getAuthInstance().post(
      "/profiles/createProfile",
      { profile }
    );
    return profileData;
  }

  public static async UpdateProfile(
    profile: Partial<ISocialProfileStore.ISMProfile>
  ): Promise<any> {
    const profileData = await getAuthInstance().put("/profiles/updateProfile", {
      profile,
    });
    return profileData;
  }

  public static async DeleteProfile(profileId: string): Promise<any> {
    const profileData = await getAuthInstance().delete(
      `/profiles/deleteProfile/${profileId}`
    );
    return profileData;
  }

  // Presets
  public static async GetPresets( type: string ): Promise<IPresetBase[]> {
    try {
      const presets = await getAuthInstance().post("/presets/getPresetsByType", 
      { type });
      return presets.data;
    }catch(err){
      console.log(err);
      return [];
    }
  }

  public static async CreatePreset( preset: IPresetStore.ICreatePresets ): Promise<IPresetBase | null> {
    try {
      const response = await getAuthInstance().post("/presets/createPreset", 
      { preset });
      return response.data;
    }catch(err){
      console.log(err);
      return null;
    }
  }

  public static async DeletePreset( presetId: string ): Promise<IPresetBase | null> {
    try {
      const response = await getAuthInstance().delete(`/presets/deletePreset/${presetId}`);
      return response.data;
    }catch(err){
      console.log(err);
      return null;
    }
  }

  /**
   * Accepts an array of file objects to upload and returns a Map of file identifier and s3 object url
   * @param files An array of file objects
   * @returns {Map<string, string>} A map of file identifier and s3 object url
   */
  public static async UploadFile(files: FileUpload[]): Promise<Map<string, string>> {
    const uploadPayload = new FormData();
    const meta = new Map();
    for (const file of files) {
      uploadPayload.append(file.fileId, file.fileBinary);
      const { fileBinary, fileId, ...fileMeta } = file;
      meta.set(file.fileId, fileMeta);
    }
    uploadPayload.append("meta", JSON.stringify(Array.from(meta.entries())));
    const uploadResponse = await getAuthInstance().post("/files", uploadPayload, { headers: { "Content-Type": "multipart/form-data" } });
    return new Map(uploadResponse.data);
  }

  // Booklinker
  public static async CreateBooklinkerLink(bookLink: string): Promise<CreateBooklinkerResponse> {
    const booklinkerLink = await getAuthInstance().post("/booklinker", { bookLink });
    return booklinkerLink.data;
  }

  public static async getGoogleFonts({ search, category, count, page }: {
    search: string,
    category: string[],
    page: number,
    count: number
  }):
    Promise<{
      favoritedIds: string[],
      allFonts: IFontStore.FontItem[]
      totalCount: number
    }> {
    const bookResp = await getAuthInstance().get(endpoints.fonts.get, {
      params: {
        search,
        category,
        page,
        count,
        source: "google"
      }
    });
    return bookResp.data;
  }

  public static async AddFontToFavorite(fontId: string): Promise<any> {
    const putBookResp = await getAuthInstance().post(endpoints.fonts.favourite.post, {
      fontId
    });
    return putBookResp.data;
  }

  public static async RemoveFontFromFavorite(fontId: string): Promise<any> {
    const putBookResp = await getAuthInstance().delete(endpoints.fonts.favourite.delete(fontId));
    return putBookResp.data;
  }

  public static async getFavoritedFonts(): Promise<IFontStore.FontItem[]> {
    const bookResp = await getAuthInstance().get(endpoints.fonts.favourite.get);
    console.log("book resp: ", bookResp.data);
    
    return bookResp.data;
  }

  public static async GetUserFavouriteFonts(): Promise<IFontStore.FontItem[][]> {
    const response = await getAuthInstance().get("fonts/user-favourites");
    return response.data;
  }
}

