import React, { useEffect, useState } from "react";
import {
    createStyles,
    Theme,
    makeStyles,
} from "@material-ui/core/styles";
import { InputBase, Grow } from "@material-ui/core";
import { observer } from "mobx-react";

import useRootStore from "../../../store/useRootStore";

import { getChapterById } from "../../../utils/initials";
import { Row, Col, Button, Popconfirm, Modal, Radio, Space, message, RadioChangeEvent } from "antd";
import { DeleteOutlined, } from "@ant-design/icons";
import { AddImageIcon } from "../../../content/icons";

import JSONPreviewer from "../../Shared/JSONPreviewer";
import IndividualChapterImageUpload from "../../Shared/Uploads/IndividualChapterImage";
import { ChapterConfiguration } from "./ChapterConfiguration";
import { ChapterConfiguratorVariant } from "../../../types/chapter";

const isDev = process.env.NODE_ENV === "development";

enum TemplateScope {
    AllBooks = "AllBooks",
    CurrentBook = "CurrentBook"
}

interface TitlebarProps {
    onEnter: any,
    customTitle: boolean,
    placeholder: string,
    autofocus: boolean,
    sceneIndex: number | null,
    sceneTitle: string | null,
    handleSceneTitleOnChange: any,
    chapterConfiguratorVariant?: ChapterConfiguratorVariant,
    isTitle?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btnGroup: {
            marginRight: `-${theme.spacing(0.5)}px`,
            marginLeft: `-${theme.spacing(0.5)}px`,
            padding: 0,
            marginTop: theme.spacing(0.5),

            "& > *": {
                textTransform: "capitalize"
            },
        },
        title: {
            width: "100%",
            fontSize: 21,
            fontWeight: 600,

            "& input": {
                paddingBottom: 0,
            }
        },
        subtitle: {
            width: "100%",
            fontSize: 15,
            fontWeight: 500,

            "& input": {
                height: 32,
                padding: 0,
            }
        },
    }),
);

export const Titlebar = observer((props: TitlebarProps) => {
    const { onEnter, autofocus, placeholder, customTitle, sceneIndex, sceneTitle, handleSceneTitleOnChange, chapterConfiguratorVariant, isTitle=false } = props;
    const { chapter, debouncedSaveChapterMetaUpdates, saveChapterMetaUpdates, chapterTemplate, debouncedSyncChapterTemplateChangesToServer, book } = useRootStore().bookStore;
    const { chapterTemplateView } = useRootStore().appStore;
    const { syncChapterTemplate } = useRootStore().chapterStore;
    const { refreshCache } = useRootStore().pdfCacheStore;
    const [show, toggleShow] = useState(false);
    const classes = useStyles();
    const [title, setTitle] = useState(chapterTemplateView ? chapterTemplate.title : chapter.title);
    const [subtitle, setSubtitle] = useState(
        chapterTemplateView ? chapterTemplate.subtitle : chapter.subtitle
    );
    const [indChaptImg, setIndChaptImg] = useState(chapter.image);
    const [imgDlg, setImgDlg] = useState(false);

    const [templateScope, setTemplateScope] = useState<TemplateScope>(TemplateScope.AllBooks);

    const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);

    const showTemplateModal = () => {
        setIsTemplateModalVisible(true);
    };

    const handleTemplateOk = async() => {
        if(!templateScope) return;
        setIsTemplateModalVisible(false);
        const shouldUpdateAllBooks = templateScope === TemplateScope.AllBooks;
        const templateId = chapterTemplateView ? chapterTemplate._id : chapter.templateId;
        const chapterId = chapterTemplateView ? chapterTemplate.motherChapterId : chapter._id;
        await syncChapterTemplate(shouldUpdateAllBooks,!chapterTemplateView, book._id, templateId, chapterId);
        message.success("Changes applied successfully", 4);
    };

    const handleTemplateCancel = () => {
        setIsTemplateModalVisible(false);
    };

    function onTemplateScopeChange(event: RadioChangeEvent) {
        setTemplateScope(event.target.value as TemplateScope);
    }

    function onTitleChange(v) {
        setTitle(v);

        if (chapterTemplateView) {
            debouncedSyncChapterTemplateChangesToServer(chapterTemplate._id, {
                title: v
            });
        } else {
            debouncedSaveChapterMetaUpdates({
                ...chapter,
                title: v,
            });
            refreshCache(chapter.bookId, "chapter-title-change", {
                "chapter-title-change": { chapterId: chapter._id },
            });
        }
    }

    function onSubtitleChange(v) {
        setSubtitle(v);

        if (chapterTemplateView) {
            debouncedSyncChapterTemplateChangesToServer(chapterTemplate._id, {
                subtitle: v,
            });
        } else {
            debouncedSaveChapterMetaUpdates({
                ...chapter,
                subtitle: v,
            });
            refreshCache(chapter.bookId, "chapter-subtitle-change", {
                "chapter-subtitle-change": { chapterId: chapter._id },
            });
        }
    }

    function onIndividualChapterImageUpload(v) {
        setImgDlg(false);
        setIndChaptImg(v);
        saveChapterMetaUpdates({
            ...chapter,
            image: v
        });
        refreshCache(chapter.bookId, "chapter-contents-change", { "chapter-contents-change": { chapterId: chapter._id } });
    }

    useEffect(() => {
        if (chapterTemplateView) {
            setSubtitle(chapterTemplate.subtitle);
        } else {
            setSubtitle(chapter.subtitle);
        }
    }, [chapter.subtitle, chapterTemplate.subtitle, chapterTemplateView]);

    useEffect(() => {
        if (chapterTemplateView) {
            setTitle(chapterTemplate.title);
        } else {
            setTitle(chapter.title);
        }
    }, [chapter.title, chapterTemplate.subtitle, chapterTemplateView]);

    useEffect(() => {
        setIndChaptImg(chapter.image);
    }, [chapter.image]);

    return (
        <Row className="att-editor-bar" >
            <Row gutter={8} align="middle">
                    {!chapterTemplateView && chapter.type !== "chapter" ? (
                        <Col >
                            <h4 className="chapter-type-toolbar">
                                {customTitle ?  chapter.type.charAt(0).toUpperCase() + chapter.type.slice(1) : chapter.type === "copyrights" ? "Copyright": chapter.type ==="image" ? "":sceneIndex !== null ? "Scene" : getChapterById(chapter.type).name}
                            </h4>
                        </Col>
                    ) : null}
                </Row>
                <Row className={`title-bar-wrapper ${!isTitle ? "title-bar-padding" : ""}`} align="middle" >
                    {!isTitle && sceneIndex === null && chapter.type !== "image" && chapter.type !== "copyrights" &&(
                     !chapterTemplateView && indChaptImg ? (
                            <Col>
                                <div className="title-bar-image-previewer" style={{ backgroundImage: `url(${indChaptImg})`}}>

                                    <div className="title-bar-image-previewer-container">
                                    <Popconfirm
                                            title="Are you sure you want to delete this image?"
                                            onConfirm={() => onIndividualChapterImageUpload("")}
                                            okButtonProps={{
                                                danger: true,
                                            }}
                                            okText="Delete"
                                            cancelText="No"
                                        >
                                                <Button
                                                size="middle"
                                                danger
                                                type="primary"
                                                shape="circle"
                                                icon={<DeleteOutlined/>}
                                                />
                                    </Popconfirm>
                                    <IndividualChapterImageUpload
                                        show={imgDlg}
                                        handler={onIndividualChapterImageUpload}
                                     />
                                    </div>
                                </div>  
                            </Col>
                        ) : 
                            <Col>
                                <a onClick={() => setImgDlg(!imgDlg)}>
                                    <div className="title-bar-image-container" >
                                        <AddImageIcon /> 
                                    </div>
                                </a>
                            </Col>
                )
                }

            <Col flex={1}>
                <div className={`tite-bar-title-input ${isTitle ? "tite-bar-title-input-chapter-settings" : ""}`}>
                    <InputBase
                        type="text"
                        value={sceneIndex !== null ? sceneTitle : title}
                        placeholder={placeholder}
                        autoFocus={autofocus}
                        className={classes.title}
                        inputProps={{ "aria-label": "naked" }}
                        onChange={sceneIndex !== null ? (e) => handleSceneTitleOnChange(e.target.value) : (e) => onTitleChange(e.target.value)}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") {
                                onEnter();
                            }
                        }}
                    /> 
                    {sceneIndex === null && isTitle ? 
                        <ChapterConfiguration variant={chapterConfiguratorVariant} /> : null }       
                </div>
                <div >
                        {sceneIndex === null && !isTitle? chapter.type !== "copyrights" && (
                            <InputBase
                                type="text"
                                value={subtitle}
                                placeholder={"Add subtitle"}
                                onBlur={() => {
                                    toggleShow(false);
                                }}
                                autoFocus={show}
                                className={classes.subtitle}
                                inputProps={{ "aria-label": "naked" }}
                                onChange={(e) => onSubtitleChange(e.target.value)}
                                onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        onEnter();
                                    }
                                }}
                            />
                    ) : null}
                </div>  
                <div className={classes.btnGroup}>
                    <Row className="editor-title-bar-secondary-configs ">
                        <Col>
                            {!indChaptImg && !chapterTemplateView && sceneIndex === null && chapter.type !== "image" && chapter.type !== "copyrights" ? (
                                <>
                                    <IndividualChapterImageUpload
                                        show={imgDlg}
                                        handler={onIndividualChapterImageUpload}
                                    />
                                </>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </Col>
            <div className="title-bar-icon-holder">
                <Col>
                    <div >
                        <Row >
                        {sceneIndex === null && !isTitle? 
                            <ChapterConfiguration variant={chapterConfiguratorVariant} /> : null }
                        </Row>
                    </div>
                </Col>
                <Col>
                    <Row gutter={10}>
                        {isDev && !isTitle ? (
                            <Col>
                                <JSONPreviewer />
                            </Col>
                        ) : null}
                    </Row>
                </Col>
            </div>
            </Row> 
        </Row>
    );
});

