import React from "react";
import { useState } from "react";
import {
  ToolbarButton,
  usePlateEditorState,
  ToolbarButtonProps,
  insertNodes,
  setSelection,
} from "@udecode/plate";
import { Range } from "slate";

// components
import { Form, FormItemProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import {
  ImageSize,
  ImageLink,
  ImageFlow,
} from "../../../config/typescript";
import FileUpload from "../../../../Shared/Forms/FileUpload";
import ImageGallery from "../../../../ImageGallery";

import { checkHttps } from "../transforms";

import { ImageLinkComponent } from "./imageLink";
import { ImageSizeComponent } from "./ImageSize";
import { ImageFlowComponent } from "./ImageFlow";

import { allowFeatureForTextMessages } from "../../text-message/config";

import { Modal } from "../../../../Shared/Modal";
import {
  ButtonSize,
  ButtonType,
  Button,
  DeleteImageButton
} from "../../../../Shared/Buttons";
import { AtticusDivider } from "../../../../Shared/Divider";
import { AtticusTextField } from "../../../../Shared/Form/Input";
import { ImageIcon, UploadIcon } from "../../../../../content/icons";

import { CURRENT_COLOR } from "../../../partials";

const formItemProperties: FormItemProps = {
  colon: false,
  labelCol: { span: 24 },
  style: { marginBottom: 15 },
};

export const ImageUploadToolbarButton = ({
  id,
}: Omit<ToolbarButtonProps, "icon">) => {
  const editor = usePlateEditorState();

  const enabled = allowFeatureForTextMessages(editor);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [editorSelection, setEditorSection] = useState<Range | null>(null);
  const [caption, setCaption] = useState("");
  const [uploading, setUploading] = useState(false);
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [size, setSize] = useState<ImageSize>("medium");
  const [wrap, setWrap] = useState(false);
  const [flow, setFlow] = useState<ImageFlow>("left");
  const [separatePage, setSeparatePage] = useState<boolean>(false);
  const [imageLink, setImageLink] = useState<ImageLink | undefined>(undefined);

  const clearForm = () => {
    setCaption("");
    setImageURL(null);
    setEditorSection(null);
    setDialogOpen(false);
    setSeparatePage(false);
  };

  const onSubmit = async () => {
    if (imageLink && imageLink.webLink && !checkHttps(imageLink.webLink)) {
      (document.getElementById("errorText") as HTMLSpanElement).textContent =
        "Should be a valid link starting with https:// or mailto:";
    } else {
      if (imageURL) {
        insertNodes(
          editor,
          [
            {
              type: "image",
              url: imageURL,
              caption,
              size,
              flow,
              link: imageLink,
              children: [{ text: "" }],
              wrap,
              separatePage,
            },
            {
              type: "p",
              children: [{ text: "" }],
            },
          ],
          {
            at: editorSelection ? editorSelection : undefined,
          }
        );

        if (editorSelection) setSelection(editor, editorSelection);
        clearForm();
      }
    }
  };

  const disableWrap =
    (typeof size === "number" && size > 50) || size === "large";

  const handleImageFlowChange = (
    flowValue?: ImageFlow,
    wrapValue?: boolean
  ) => {
    if (flowValue !== undefined) setFlow(flowValue);
    if (wrapValue !== undefined) setWrap(disableWrap ? false : wrapValue);
  };

  const handleImageSizeChange = (value: ImageSize) => {
    setSize(value);
    setWrap(disableWrap ? false : wrap);
  };

  const handleSeparatePageChange = (
    separateValue: boolean | undefined | null
  ) => {
    if (separateValue !== null && separateValue !== undefined)
      setSeparatePage(separateValue);
  };

  const getIGURL = (link: string) => {
    setImageURL(link);
  };

  const removeSelectedImage = () => {
    setImageURL(null);
  };

  return (
    <>
      <Modal
        open={dialogOpen}
        title="Add an image"
        onCancel={() => setDialogOpen(false)}
        centered={true}
        bodyStyle={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        wrapClassName="image-toolbar-modal-wrap"
      >
        <div className="image-toolbar-body-wrapper">
          <Form className="image-toolbar-ant-form">
            {!imageURL ? (
              // Render this section if no image is selected
              <>
                <ImageGallery
                  handleImageUrl={getIGURL}
                  ImageGalleryButtonText="Select from image gallery"
                  renderButtonProps={{
                    type: "at-primary",
                    backgroundColor: "green",
                    size: ButtonSize.MEDIUM,
                    block: true
                  }}
                />
                <AtticusDivider>
                  <p className="image-toolbar-divider-text">Or</p>
                </AtticusDivider>
                <h4 className="image-toolbar-upload-header">
                  Upload a new image
                </h4>
                <Form.Item name="upload" className="image-toolbar-upload-form-item">
                  <FileUpload
                    fileType="image/png, image/jpg, image/jpeg"
                    onFileUploadStart={() => setUploading(true)}
                    onFileUpload={(fileUrl) => {
                      setImageURL(fileUrl);
                    }}
                    onFileUploadEnd={() => setUploading(false)}
                    folder="images"
                    styleOverrides={{
                      backgroundColor: "#E7EDF8",
                      borderRadius: 4,
                      borderWidth: 2,
                      borderStyle: "dashed",
                      borderColor: "#ADC1E3",
                    }}
                  >
                    <div className="image-toolbar-dnd-container">
                      {uploading ? (
                        <span className="image-toolbar-dnd-text-highlight">
                          <LoadingOutlined /> Uploading
                        </span>
                      ) : (
                        <>
                          <span>
                            <UploadIcon color="#3363B9" />
                          </span>
                          <p>
                            <span className="image-toolbar-dnd-text-highlight">
                              Click to upload image
                            </span>{" "}
                            or drag and drop
                          </p>
                        </>
                      )}
                    </div>
                  </FileUpload>
                </Form.Item>
                <div className="image-toolbar-upload-footer">
                  <p className="image-toolbar-upload-footer-text">
                    Supported formats: PNG, JPG
                  </p>
                  <p className="image-toolbar-upload-footer-text">
                    Maximum size: 10mb
                  </p>
                </div>
              </>
            ) : (
              // Render this section when an image is selected
              <>
                <div
                  className="image-toolbar-image-previewer"
                  style={{ backgroundImage: `url(${imageURL})` }}
                >
                  <div className="image-toolbar-image-previewer-container">
                    <DeleteImageButton onClick={() => removeSelectedImage()}/>
                  </div>
                </div>
                <Form.Item
                  label={<span className="edit-image-form-label">Caption</span>}
                  {...formItemProperties}
                >
                  <AtticusTextField
                    value={caption}
                    onChange={(e) => {
                      setCaption(e.target.value);
                    }}
                    placeholder="Image caption"
                    style={{
                      borderRadius: 4,
                    }}
                    inputType="secondary"
                  />
                </Form.Item>
                <ImageFlowComponent
                  flowValue={flow}
                  wrapValue={wrap}
                  disableWrap={disableWrap}
                  flowChange={handleImageFlowChange}
                  separatePageValue={separatePage}
                  separatePageChange={handleSeparatePageChange}
                />
                <ImageSizeComponent
                  size={size}
                  onSizeChange={handleImageSizeChange}
                />
                <ImageLinkComponent link={imageLink} setLink={setImageLink} />
                <Button
                  type={ButtonType.PRIMARY}
                  size={ButtonSize.MEDIUM}
                  backgroundColor="green"
                  onMouseDown={() => onSubmit()}
                  fullWidth
                >
                  Upload image
                </Button>
              </>
            )}
          </Form>
        </div>
      </Modal>
      <div className={`${enabled ? "" : "plate-disabled"}`}>
        <ToolbarButton
          type={"image"}
          icon={<ImageIcon color={CURRENT_COLOR} />}
          onMouseDown={(e) => {
            e.preventDefault();
            setDialogOpen(true);
          }}
        />
      </div>
    </>
  );
};
