import React, { FunctionComponent } from "react";
import { Form, Input, FormItemProps } from "antd";

import SelectAuthor from "./forms/select-author";
import SelectProject from "./forms/select-project";

const commonFormItemProps = {
	labelAlign: "left",
	colon: false,
} as FormItemProps;

interface NewBoxsetDetailsProps {
	values: {title: string, author: string[], project: string };
	handleChange: (obj) => void;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const layout = {
	labelCol: { span: 5 },
	wrapperCol: { span: 24 },
};	

const NewBoxsetDetails: FunctionComponent<NewBoxsetDetailsProps> = ({values, handleChange, setFieldValue }: NewBoxsetDetailsProps) => {

	return (	
		<Form {...layout} layout="vertical">
			<div style={{height: "calc(100vh - 400px)" }}>
				<Form {...layout} layout="vertical">
					<div>
						<Form.Item label="Title" {...commonFormItemProps} className="ant-input-extended">
							<Input placeholder="Title" name="title" onChange={handleChange} value={values.title} size="large" />
						</Form.Item>
						<Form.Item name="author" label="Author" {...commonFormItemProps} className="ant-input-extended ant-select-extended">
							<SelectAuthor placeholderText={"Author"} onSelect={v => setFieldValue("author", v)} value={values.author} />
						</Form.Item>
						<Form.Item name="project" label="Project" {...commonFormItemProps} className="ant-input-extended ant-select-extended">
							<SelectProject placeholderText={"Project Name"} onSelect={v => setFieldValue("project", v)} value={values.project} />
						</Form.Item>
					</div>
				</Form>
			</div>
		</Form>
	
	);
};

export default NewBoxsetDetails;