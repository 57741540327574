import { AllPropertiesRequired } from "../../../../utils/helper";
import { ConfigurationFormFields, CreateWritingHabit } from "../types";

export const createWritingHabitConfiguration = (
  values: AllPropertiesRequired<ConfigurationFormFields>,
  currentBookId: string,
  currentBookWordCount: number
): CreateWritingHabit => {
  return {
    writeDays: values.writeDays,
    countDeleted: false,
    dateRecords: [],
    preWordCount: currentBookWordCount,
    goalCount: values.wordsPerDay,
    writtenCount: 0,
    currentStreak: 0,
    longestStreak: 0,
    snowFireStreak: "snow",
    deletedWordCount: 0,
    currentBookId: currentBookId,
    preBookWordCount: 0,
    createdAt: new Date(),
  };
};

export const alteredWritingHabitConfiguration = (
  values: AllPropertiesRequired<ConfigurationFormFields>,
  writingHabit: IHabitStore.Habit,
): IHabitStore.Habit => {
  return {
    ...writingHabit,
    writeDays: values.writeDays,
    countDeleted: false,
    goalCount: values.wordsPerDay,
    currentStreak: 0,
    snowFireStreak:
      (writingHabit.writtenCount + writingHabit.preBookWordCount) >= values.wordsPerDay ? "fire" : "snow",
  };
};
