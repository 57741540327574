import { createPluginFactory } from "@udecode/plate-core";
import { SocialMediaLinkComponent } from "./components/socialMediaLinkComponent";

export const ELEMENT_SM_LINK = "profile";

export const createSocialMediaLinkPlugin = createPluginFactory({
  key: ELEMENT_SM_LINK,
  isElement: true,
  component: SocialMediaLinkComponent,
});
