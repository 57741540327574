import React from "react";
import { useEventPlateId, usePlateEditorState } from "@udecode/plate-core";
import { observer } from "mobx-react";
import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import useRootStore from "../../../../../store/useRootStore";

import { AddNewProfile } from "./addNewProfile";
import { ListAllProfiles } from "./listAllProfiles";

import "./styles.scss";
import { Modal } from "../../../../Shared/Modal";
import { Button } from "../../../../Shared/Buttons";

export const SocialMediaLinkModal = observer(({ id }) => {
  const editor = usePlateEditorState(useEventPlateId(id));

  const {
    showProfileModal,
    resetAndHideProfileModal,
    modalState,
    setModalState,
    resetModal,
    insertAccountsIntoEditor,
    smProfilePathToReplace,
    smAccountsToInsert,
  } = useRootStore().socialProfileStore;

  const handleCancel = () => {
    resetAndHideProfileModal();
  };

  const handleSubmit = () => {
    insertAccountsIntoEditor(editor);
  };

  return (
    <Modal
      visible={showProfileModal}
      title={<b>{smProfilePathToReplace ? "Change profile" : "Your profiles"}</b>}
      onCancel={handleCancel}
      leftBtn={ modalState === "default" ? {
        type: "at-secondary",
        backgroundColor: "green",
        onMouseDown: handleCancel,
        children: "Cancel"
      } : undefined}
      rightBtn={ modalState === "default" ? {
        type: "at-primary",
        backgroundColor: "green",
        onClick: handleSubmit,
        disabled: smAccountsToInsert.length === 0,
        children: smProfilePathToReplace ? "Change" : "Add"
      }: undefined}
    >
      {modalState === "default" && (
        <>
          {!smProfilePathToReplace && (
            <div className="create-prof-btn-div">
              <Button
                type="at-primary"
                backgroundColor="green"
                size="large"
                icon={<PlusOutlined />}
                onClick={() => setModalState("addNewProfile")}
              >
                Create new profile
              </Button>
            </div>
          )}
          <ListAllProfiles />
        </>
      )}
      {(modalState === "addNewProfile" || modalState === "editProfile") && (
        <>
          <AddNewProfile />
        </>
      )}
    </Modal>
  );
});
