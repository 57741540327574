import { createPluginFactory } from "@udecode/plate";
import { withImage } from "./normalizers/withImage";
import { ImageComponent } from "./components/Image";

export const ELEMENT_IMAGE = "image";

/**
 * Enables support for images.
 */

export const createImagePlugin = createPluginFactory({
  key: ELEMENT_IMAGE,
  type: ELEMENT_IMAGE,
  isElement: true,
  component: ImageComponent,
  withOverrides: withImage,
});
