import React, { useState, useEffect, useRef, useCallback } from "react";
import { Select } from "antd";
import { observer } from "mobx-react";

import useRootStore from "../../../store/useRootStore";
import { AtticusSelect } from "../../Shared/Form";

const { Option } = Select;

interface SelectAuthorProps {
    onSelect: (val: string[]) => void;
    value: string[];
    placeholderText: string;
	resetField?: boolean;
}

const SelectAuthor = observer(({ value, onSelect, placeholderText, resetField }: SelectAuthorProps) => {
	const { getAuthors } = useRootStore().shelfStore;
	const [selectedValue, setSelectedValue] = useState(value);
	const authors = getAuthors();
	
	useEffect(() => {
		if (resetField) {
			setSelectedValue([]);
		}
	}, [resetField]);

	const handleSelect = useCallback((val: string[]) => {
		setSelectedValue(val);
		onSelect(val);
	}, [onSelect]);

	return (
		<AtticusSelect
			selectOptions={authors.map((author, i) => ({
				label: author,
				value: author
			}))}
            mode="tags"
			onChange={val => handleSelect(val)}
			value={selectedValue}
			placeholder={placeholderText}
		/>
	);
});

export default SelectAuthor;