import React from "react";
import {
    createPlateUI,
    createAutoformatPlugin,
    createBoldPlugin,
    createExitBreakPlugin,
    createHeadingPlugin,
    createHighlightPlugin,
    createItalicPlugin,
    createNodeIdPlugin,
    createResetNodePlugin,
    createSelectOnBackspacePlugin,
    createSoftBreakPlugin,
    createStrikethroughPlugin,
    createSubscriptPlugin,
    createSuperscriptPlugin,
    createTrailingBlockPlugin,
    createUnderlinePlugin,
    createDeserializeMdPlugin,
    createDeserializeCsvPlugin,
    createNormalizeTypesPlugin,
    createDeserializeDocxPlugin,
    AutoformatPlugin,
    ELEMENT_LINK,
    ELEMENT_BLOCKQUOTE
} from "@udecode/plate";
import { createJuicePlugin } from "@udecode/plate-juice";
import { CONFIG } from "./config/config";
import {
    createMyPlugins,
    MyEditor,
    MyPlatePlugin,
    PlateNodes,
} from "./config/typescript";
import {
    createSansSerifPlugin,
    createMonospacePlugin,
    createSmallCapsPlugin,
    createRedoPlugin,
    createLinkPlugin,
    createRightAlignPlugin,
    createCenterAlignPlugin,
    createParagraphPlugin,
    createListPlugin,
    ELEMENT_SUBHEADING,
    SubheadingComponent,
    BlockquoteComponent,
    Link,
} from "./plugins";

/*
    Add your custom component here to override existing component if they share the same `key`
*/
const components = createPlateUI({
    [ELEMENT_LINK]: Link,
    [ELEMENT_SUBHEADING]: SubheadingComponent,
    [ELEMENT_BLOCKQUOTE]: BlockquoteComponent
});

export const endNotesPlugins = createMyPlugins(
    [

        //custom
        createSansSerifPlugin(),
        createMonospacePlugin(),
        createSmallCapsPlugin(),
        

        //custom with normalizers
        createParagraphPlugin(),
        createCenterAlignPlugin(),
        createRightAlignPlugin(),
        createListPlugin(),

        //default
        createHeadingPlugin(),
        createLinkPlugin(),
        createBoldPlugin(),
        createItalicPlugin(),
        createHighlightPlugin(),
        createUnderlinePlugin(),
        createStrikethroughPlugin(),
        createSubscriptPlugin(),
        createSuperscriptPlugin(),
        createNodeIdPlugin(),
        createAutoformatPlugin<
            AutoformatPlugin<PlateNodes, MyEditor>,
            PlateNodes,
            MyEditor
        >(CONFIG.autoformat),
        createResetNodePlugin(CONFIG.resetBlockType),
        createSoftBreakPlugin(CONFIG.softBreak),
        createExitBreakPlugin(CONFIG.exitBreak),
        createNormalizeTypesPlugin(CONFIG.forceLayout),
        createTrailingBlockPlugin(CONFIG.trailingBlock),
        createSelectOnBackspacePlugin(CONFIG.selectOnBackspace),

        createDeserializeMdPlugin(),
        createDeserializeCsvPlugin(),
        createDeserializeDocxPlugin(),
        createJuicePlugin() as MyPlatePlugin,
    ],
    {
        components,
    }
);