import React from "react";
import { Row, Col, Form, Checkbox, FormInstance } from "antd";
import {
  BackgroundColor,
  Button,
  ButtonType,
} from "../../Shared/Buttons/Button";
import moment from "moment";
import { AtticusNumberField } from "../../Shared/Form/InputNumber";
import { AtticusDatePicker } from "../../Shared/Form/DatePicker";
import { writingDays } from "./helper";

interface InitialValues {
  targetWordCount?: number;
  dueDate: moment.Moment;
  writingDays?: number[];
}

interface BookGoalFormProps {
  form: FormInstance;
  loading: boolean;
  handleGoalsSubmit: (fieldsValue) => void;
  containerRef: React.MutableRefObject<any>;
  initialValues?: InitialValues;
}

const BookGoalForm: React.FC<BookGoalFormProps> = ({
  form,
  handleGoalsSubmit,
  loading,
  containerRef,
  initialValues,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <Form
        id="collapsible"
        className="book-goal-form"
        form={form}
        layout="vertical"
        onFinish={handleGoalsSubmit}
        requiredMark={"optional"}
        initialValues={initialValues}
      >
        <Row>
          <h5 className="setting-form-label">
            Total word count for this project
          </h5>
        </Row>
        <Row justify="space-around" align="middle">
          <Col span={24}>
            <Form.Item
              name="targetWordCount"
              validateTrigger="onChange"
              rules={[
                {
                  required: true,
                  message: "Please enter the total word count!",
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject();
                    }
                    if (value <= parseInt(containerRef.current?.innerText)) {
                      return Promise.reject(
                        "Total word count should be higher than current total!"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <AtticusNumberField type="secondary" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <h5 className="setting-form-label">Due date</h5>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="dueDate"
              validateTrigger="onChange"
              rules={[
                {
                  required: true,
                  message: "Please select the due date!",
                },
                () => ({
                  validator(_, value) {
                    const now = new Date();
                    now.setHours(0, 0, 0, 0);

                    if (!value) {
                      return Promise.reject();
                    }
                    if (value < now) {
                      return Promise.reject("Due date cannot be in the past!");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <AtticusDatePicker type="secondary" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <h5 className="setting-form-label">Writing days</h5>
        </Row>
        <Row>
          <Form.Item
            name="writingDays"
            className="goal-setting-check-btns"
            rules={[
              {
                required: true,
                message: "Please select the writing days!",
              },
            ]}
          >
            <Checkbox.Group options={writingDays} />
          </Form.Item>
        </Row>
        <Row>
          <Col span={24}>
            {loading ? (
              <Button
                block
                type={ButtonType.PRIMARY}
                backgroundColor={BackgroundColor.GREEN}
              >
                Saving...
              </Button>
            ) : (
              <Button
                block
                type={ButtonType.PRIMARY}
                backgroundColor={BackgroundColor.GREEN}
                htmlType="submit"
              >
                Save
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BookGoalForm;