export const writingDays = [
  { label: "S", value: 0, dayName: "sunday" },
  { label: "M", value: 1, dayName: "monday" },
  { label: "T", value: 2, dayName: "tuesday" },
  { label: "W", value: 3, dayName: "wednesday" },
  { label: "T", value: 4, dayName: "thursday" },
  { label: "F", value: 5, dayName: "friday" },
  { label: "S", value: 6, dayName: "saturday" },
];

export const isoWithTimeZone = (date) => {
  const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num) {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? "0" : "") + norm;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
};

export const calculateGoal = (
  targetWordCount: number,
  startDate: string,
  dueDate: string,
  writeDays: number[],
  writtenWordCount: number
) => {
  const countCertainDays = (days: number[], d1: string, d2: string) => {
    const dateObj1: any = new Date(d1);
    const dateObj2: any = new Date(d2);

    const nDays = 1 + Math.round((dateObj2 - dateObj1) / (24 * 3600 * 1000));
    const sum = (a: any, b: any) => {
      return a + Math.floor((nDays + ((dateObj1.getDay() + 6 - b) % 7)) / 7);
    };
    return days.reduce(sum, 0);
  };

  const goalStartDate = startDate;
  const goalDueDate = dueDate;
  const daysToSearch = writeDays;

  const totalDays = countCertainDays(daysToSearch, goalStartDate, goalDueDate);
  const wordsPerDay = (targetWordCount - writtenWordCount) / totalDays;

  const calculation = {
    totalDays: totalDays,
    daysLeft: totalDays,
    wordsPerDay: wordsPerDay,
  };

  return calculation;
};

export const percentage = (partialValue, totalValue) => {
  return (100 * partialValue) / totalValue;
};
