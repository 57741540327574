import { observer } from "mobx-react";
import React from "react";
import useRootStore from "../../../../../../store/useRootStore";
import { Button, ButtonType } from "../../../../../Shared/Buttons";
import { CloseOutlinedIcon } from "../../../../../../content/icons";

const ExitToView: React.FC = observer(() => {
  const { switchToViewStateWithoutUpdates } = useRootStore().writingHabitStore;

  return (
    <Button
      className="close-btn"
      type={ButtonType.GHOST}
      icon={<CloseOutlinedIcon/>}
      onClick={() => switchToViewStateWithoutUpdates()}
    >
    </Button>
  );
});

export { ExitToView };
