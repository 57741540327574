export const shouldTransformToParagraph = (element: HTMLElement): boolean => {
    return (
        element.classList.contains("editor-callout-box") ||
        Boolean(element.closest(".editor-callout-box")) ||
        (element.dataset.slateNode === "element" &&
            element.hasAttribute("style") &&
            Boolean(element.querySelector(".slate-italic, .slate-bold, [data-slate-string='true']")))
    );
};

export const transformDescendants = (element: HTMLElement): void => {
    element.querySelectorAll("h1, hr, p, li:not(ul li, ol li)").forEach((child) => {
        const htmlChild = child as HTMLElement;
        if (htmlChild.matches("h1, hr, p") || (htmlChild.matches("li:not(ul li, ol li)") && htmlChild.dataset.slateNode !== "element")) {
            transformToParagraph(htmlChild);
        }
    });
};

export const transformAncestors = (element: HTMLElement): void => {
    let currentElement: HTMLElement | null = element;
    while (currentElement && currentElement !== document.documentElement) {
        const htmlCurrentElement = currentElement as HTMLElement;
        if (htmlCurrentElement.matches("h1, hr, p") || (htmlCurrentElement.matches("li:not(ul li, ol li)") && htmlCurrentElement.dataset.slateNode !== "element")) {
            transformToParagraph(htmlCurrentElement);
        }
        currentElement = currentElement.parentElement;
    }
};

export const transformToParagraph = (element: HTMLElement) => {
    // Exclude <p> elements with data-slate-node="element" for calloutboxes, textmessageboxes, etc.
    if (element.nodeName === "P" && element.dataset.slateNode === "element") {
        return;
    }
    const p = document.createElement("p");
    p.innerHTML = element.innerHTML;
    element.parentNode?.replaceChild(p, element);
};

export const simplifyTable = (table: HTMLElement): void => {
    const rows = Array.from(table.querySelectorAll("tr"));
    const content = rows.map(row => {
        const cells = Array.from(row.querySelectorAll("td, th"));
        return cells.map(cell => cell.textContent).join(" ");
    }).join("\n");
    const div = document.createElement("div");
    div.textContent = content;
    table.parentNode?.replaceChild(div, table);
};