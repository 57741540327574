import { createPluginFactory } from "@udecode/plate-core";

import { withCombinedDeserialization } from "./components/withCombinedDeserialization";
import { MyPlatePlugin } from "../../config/typescript";


export const CombinedDeserializationPlugin = (): MyPlatePlugin => {
  const createCombinedDeserializationPlugin = createPluginFactory({
      key: "combinedDeserialization",
      withOverrides: withCombinedDeserialization,
  });

  return createCombinedDeserializationPlugin();
};
