import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Row, List } from "antd";

import ThemeCard from "./ThemeCard";
import { ScrollContainer } from "../Shared/Layouts";

import useRootStore from "../../store/useRootStore";

function itemRender(current, type, originalElement) {
  return ["prev", "next"].includes(type) ? null : originalElement;
}

interface ThemeListProps {
  themeCardOnClick: (themeId: string) => Promise<void>;
  themeCardOnEdit: (themeId: string) => void;
}

const ThemeList = ({ themeCardOnClick, themeCardOnEdit }: ThemeListProps) => {
  const { allThemes, activeTheme, isThemeBuilderActive, synced } = useRootStore().themeStore;
  const { book } = useRootStore().bookStore;
  const { refreshCache } = useRootStore().pdfCacheStore;

  // refresh pdf previewer after syncing the themes
  useEffect(() => {
    refreshCache(book._id, "theme-change");
  }, [synced]);

  // Create refs for all theme cards
  const refs = allThemes.reduce((song, theme) => {
    song[theme._id] = React.createRef();
    return song;
  }, {});

  // Functions invoke everytime an active theme set
  const handleActiveScroll = id => {
    if(refs[id].current){
      refs[id].current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    } 
    return;
  };


  return (
    <ScrollContainer>
      <div className="theme-listing">
        <List
          loading={!synced}
          itemLayout="vertical"
          className="theme-cards"
          size="large"
          locale={{
            emptyText: (
              <Row justify="center" className="custom-themes-empty inner">
                <div>
                  <img width="200" src="/images/dog.gif" />
                </div>
                <h2>What will you create ?</h2>
                <br />
              </Row>
            ),
          }}
          dataSource={allThemes}
          renderItem={(theme) => (
            <ThemeCard
              key={theme._id}
              active={activeTheme._id === theme._id}
              editable={isThemeBuilderActive}
              theme={theme}
              scrollView={handleActiveScroll}
              handleOnClick={themeCardOnClick}
              handleOnEdit={themeCardOnEdit}
              refs={refs[theme._id]}
            />
          )}
        />
      </div>
    </ScrollContainer>
  );
};

export default observer(ThemeList);
