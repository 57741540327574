import { observer } from "mobx-react";
import React, { useEffect } from "react";
import useRootStore from "../../../../../store/useRootStore";

const Synchronization: React.FC = observer(() => {
   const {
     section,
     initializePlugin,
     isOnline,
     stateLock,
     writingHabitState,
     updateWritingHabitDataToIndexedDB,
   } = useRootStore().writingHabitStore;

   useEffect(() => {
     if (section === "loading") {
       initializePlugin();
     }

     const syncLoop = setInterval(() => {
       if (!stateLock && section !== "edit") {
         initializePlugin();
       }
     }, 30000);

     return () => clearInterval(syncLoop);
   }, [isOnline]);

   useEffect(() => {
     if (writingHabitState && section !== "edit") {
       updateWritingHabitDataToIndexedDB(writingHabitState);
     }
   }, [writingHabitState]);

  return null;
});

export { Synchronization };
