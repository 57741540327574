import React from "react";

interface HabitStreakItemProps {
  dayCount: number;
  label: string;
}

const HabitStreakItem: React.FC<HabitStreakItemProps> = ({
  dayCount,
  label,
}) => {
  return (
    <div className={"writing-habit-streak-item"}>
      <div className="writing-habit-streak-item-title">
        <div className="writing-habit-streak-day-count">{dayCount}</div> day{dayCount !== 1 && "s"}
      </div>
      <div className="writing-habit-streak-item-label">{label}</div>
    </div>
  );
};

export { HabitStreakItem };
