import { PdfSlateBaseParentBlockNode, PdfSlateEndnote } from "../components/Previewer/print/types";

export const getAllEndNotesOfChapter = (children: (PdfSlateBaseParentBlockNode | PdfSlateEndnote)[], chapterId?: string) => {
    const endNotes: (PdfSlateEndnote)[] = [];
    for (const child of children) {
        const { type, id } = child;
        if (type === "endnote") {
            if (!id) {
                /**
                 * In some scenarios, endnote nodes do not contain valid node.id (check AT-192)
                 * Therefore, when an endnote with missing id is detected, a random number is assigned.
                 * This id is required inside the pdf generator logic.
                 */
                endNotes.push({...child, id: Math.trunc(Math.random() * 10000), chapterId: chapterId} as PdfSlateEndnote );
            } else {
                endNotes.push({ ...child, chapterId: chapterId} as PdfSlateEndnote);
            }
        }
        const _child = child as PdfSlateBaseParentBlockNode;
        if (!_child.children) continue;
        endNotes.push(...getAllEndNotesOfChapter(_child.children as PdfSlateBaseParentBlockNode[], chapterId));
    }
    return endNotes;
};