import { HeadingSize } from "./types";


const widthMarks = {
  20: {
    label: "20%"
  },
  40: {
    label: "40%"
  },
  60: {
    label: "60%"
  },
  80: {
    label: "80%"
  },
  100: {
    label: "100%"
  },
};

const sizeMarks = {
  10: {
    label: "10",
  },
  20: {
    label: "20",
  },
  30: {
    label: "30",
  },
  40: {
    label: "40",
  },
  50: {
    label: "50",
  },
  60: {
    label: "60",
  },
  70: {
    label: "70",
  },
  80: {
    label: "80",
  },
  90: {
    label: "90",
  },
  100: {
    label: "100",
  },
};

export const chapterNoSize: HeadingSize = {
  min: 9,
  max: 42,
  labels: {
    9: {
      label: "9",
    },
    20: {
      label: "20",
    },
    31: {
      label: "31",
    },
    42: {
      label: "42",
    }
  },
};

export const chapterTitleSize: HeadingSize = {
  min: 15,
  max: 54,
  labels: {
    15: {
      label: "15",
    },
    28: {
      label: "28",
    },
    41: {
      label: "41",
    },
    54: {
      label: "54",
    },
  },
};

export const chapterSubtitleSize: HeadingSize = {
  min: 12,
  max: 48,
  labels: {
    12: {
      label: "12",
    },
    24: {
      label: "24",
    },
    36: {
      label: "36",
    },
    48: {
      label: "48",
    },
  },
};

const opacityMarks = {
  0: {
    label: "0%"
  },
  20: {
    label: "20%"
  },
  40: {
    label: "40%"
  },
  60: {
    label: "60%"
  },
  80: {
    label: "80%"
  },
  100: {
    label: "100%"
  },
};

export const headingSize = {
  min: 0.75,
  max: 2,
  labels: {
    0.75: {
      label: "0.75x",
      style: { fontSize: "11px" },
    },
    1.00: {
      label: "1.00x",
      style: { fontSize: "11px" },
    },
    1.25: {
      label: "1.25x",
      style: { fontSize: "11px" },
    },
    1.50: {
      label: "1.50x",
      style: { fontSize: "11px" },
    },
    1.75: {
      label: "1.75x",
      style: { fontSize: "11px" },
    },
    2.00: {
      label: "2.00x",
      style: { fontSize: "11px" },
    },
  },
};


const alignItems = [
  {
    label: "Left",
    value: "left",
  },
  {
    label: "Center",
    value: "center",
  },
  {
    label: "Right",
    value: "right",
  }
];

export {
  widthMarks,
  sizeMarks,
  opacityMarks,
  alignItems
};
