import React from "react";
import { observer } from "mobx-react";

import { Button } from "../Shared/Buttons";
import { BetaLibraryIcon } from "../../content/icons";

import useRootStore from "../../store/useRootStore";

const FontGalleryButton: React.FC = observer(() => {
  const { toggleFontGallery } = useRootStore().fontStore;
  return (
    <div className="font-gallery-btn-wrapper">
      <Button type="at-primary" className="btn-alpha" onClick={toggleFontGallery} icon={<BetaLibraryIcon />}>Library</Button>
    </div>
  );
});



export default FontGalleryButton;