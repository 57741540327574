import {
  someNode,
  Value,
  TEditor,
  TElement,
  setElements,
} from "@udecode/plate-core";

import { ELEMENT_BLOCKQUOTE, ELEMENT_DEFAULT } from "@udecode/plate";
import { ELEMENT_SUBHEADING, ELEMENT_HANGING_INDENT, ELEMENT_VERSE } from "../../";

export const toggleHangingIndent = <V extends Value>(editor: TEditor<V>) => {
  const validNodeTypes = [
    ELEMENT_BLOCKQUOTE,
    ELEMENT_DEFAULT,
    ELEMENT_SUBHEADING,
    ELEMENT_VERSE
  ];

  const canConvertToHangingIndent= (node: TElement): boolean => {
    return validNodeTypes.includes(node.type);
  };
  const isHangingIndent= (node: TElement): boolean => {
    return node.type === ELEMENT_HANGING_INDENT;
  };

  /**
   * if selected node's type is valid, convet the node to a hanging indent node
   * if selected node is already a hanging indent, toggle it back to default
   * */
  if (someNode(editor, { match: canConvertToHangingIndent })) {
    setElements(editor, {
      type: ELEMENT_HANGING_INDENT,
    });
  } else if (someNode(editor, { match: isHangingIndent })) {
    setElements(editor, {
      type: ELEMENT_DEFAULT,
    });
  }
};
