import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { PlateEditor, Value } from "@udecode/plate";

import useRootStore from "../../store/useRootStore";
import { Button, ButtonType } from "../Shared/Buttons/Button";

import ApplyQuotes from "./ApplyQuotes";
import Inconsistencies from "./Inconsistencies";
import { ChapterErrorsObject, ChapterQuoteStyleRecord } from "./types";
import { analyzeQuoteConsistencyInBook, getAllTableRowsForErrors } from "./helper";

interface SmartQuotesProps {
  editor: PlateEditor<Value>;
}
const tabItems = [
  {
    key: "apply",
    label: "Apply",
  },
  {
    key: "inconsistencies",
    label: "Inconsistencies",
  },
];

const SmartQuotes: React.FC<SmartQuotesProps> = ({ editor }) => {
  const [activeTab, setActiveTab] = useState("apply");
  const [analyzedResults, setAnalyzedResults] =
    useState<ChapterQuoteStyleRecord | null>(null);
  const [chapters, setChapters] = useState<ChapterErrorsObject[]>([]);
  const { book } = useRootStore().bookStore;

  useEffect(() => {
    const fetchData = async () => {
      const results = await analyzeQuoteConsistencyInBook(book._id);
      setAnalyzedResults(results);
      setChapters(await getAllTableRowsForErrors(book._id, results));
    };

    const interval = setInterval(async () => {
      await fetchData();
    }, 5000);

    return () => clearInterval(interval);
  }, [book]);

  return (
    <div className="smart-quote-area-wrapper" style={{ height: "100%" }}>
      <div>
        <Row className="settings-tabs" align="middle" justify="space-around">
          {tabItems.map((item, index) => (
            <Col
              span={12}
              key={index}
              className={`settings-tab-item ${
                item.key === activeTab ? "active" : ""
              }`}
            >
              <Button
                className="setting-tab-btn"
                type={ButtonType.GHOST}
                onClick={() => setActiveTab(item.key)}
              >
                {item.label}
              </Button>
            </Col>
          ))}
        </Row>
      </div>
      <div>
        {activeTab === "apply" ? (
          <ApplyQuotes editor={editor} />
        ) : (
          <Inconsistencies
            chapters={chapters}
            analyzedResults={analyzedResults}
          />
        )}
      </div>
    </div>
  );
};

export default SmartQuotes;
