import React from "react";
import {
  Form,
  Select,
  Radio,
  Row,
  Col,
  Button,
  Checkbox,
  Slider,
  Switch,
  Popconfirm,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { FormInstance } from "antd/es/form/Form";

//helpers
import { widthMarks, opacityMarks } from "../helpers/configs";

//components
import PlacementTheme from "../../../Shared/PlacementTheme";
import { ImageSelector } from "../../../Shared/Forms/ImageSelector";

interface ImageSettingsProps {
  active: boolean;
  image: string;
  showImage: boolean;
  showImageConfigs: boolean,
  showBackgroundImageConfigs: boolean,
  onUpdate: (url: string) => void;
}

const { Option } = Select;


/**
 * Elements are hidden using css instead of conditionally rendering to avoid
 * unexpected behavors of antd form caused by removing form related dom elements
 */

const ImageSettings = ({
  active,
  image,
  showImage,
  showImageConfigs,
  showBackgroundImageConfigs,
  onUpdate
}: ImageSettingsProps): JSX.Element => {

  return (
    <div className="theme-title-section">
      <Row>
        <Form.Item className="at-form-item theme-chapter-header-checkbox" name="imgVisible" valuePropName="checked">
          <Checkbox>Chapter image</Checkbox>
        </Form.Item>
      </Row>
      <div style={{ display: active ? "block" : "none" }}>
        <Row gutter={16}>
          <Col>
            Use individual chapter images {" "}
          </Col>
          <Form.Item
            noStyle
            name="individualChapterImage"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Row>
        <div className="inner-xs" />
        <div>
          <div style={{ display: showImage ? "block" : "none" }}>
            <ImageSelector
              onUpdate={onUpdate}
              image={image}
              folderName="custom-theme-images"
            />
          </div>
          <div style={{ display: showImageConfigs ? "block" : "none" }}>
            <Row className="theme-layout-btns scroller">
              <Form.Item
                name="imgPlacement"
                label="Placement"
                className="at-form-item"
              >
                <Radio.Group>
                  <Radio value="above-chapter-no">
                    <PlacementTheme.A />
                  </Radio>
                  <Radio value="above-chapter-title">
                    <PlacementTheme.B />
                  </Radio>
                  <Radio value="below-chapter-title">
                    <PlacementTheme.C />
                  </Radio>
                  <Radio value="below-subtitle">
                    <PlacementTheme.D />
                  </Radio>
                  <Radio value="background-image">
                    <PlacementTheme.E />
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Row>
            <div
              id="background-image"
              style={{ display: showBackgroundImageConfigs ? "block" : "none" }}
            >
              <h4 className="inner-s">Background image options</h4>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="imgOpacity"
                    label="Image opacity"
                    className="at-form-item"
                    tooltip="Set background image opacity."
                  >
                    <Slider marks={opacityMarks} min={0} max={100} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="textLight"
                    label="Text color"
                    className="at-form-item"
                    tooltip="Set text color"
                  >
                    <Radio.Group defaultValue="default">
                      <Radio value="default">Default</Radio>
                      <Radio value="light">Light</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="bgPrintExtent"
                    label="Print extent"
                    className="at-form-item"
                    tooltip="Full bleed only supports on print version (PDF)."
                  >
                    <Radio.Group defaultValue="margins">
                      <Radio value="margins">Margins</Radio>
                      <Radio value="full-bleed">Full bleed</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div
              id="image-options"
              style={{ display: showBackgroundImageConfigs ? "none" : "block" }}
            >
              <h4 className="inner-s">Image element options</h4>
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item
                    name="imgWidth"
                    label="Width percentage"
                    className="at-form-item"
                  >
                    <Slider marks={widthMarks} min={20} max={100} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="imgAlignment"
                    label="Alignment"
                    className="at-form-item"
                  >
                    <Select placeholder="Select">
                      <Option value="left" key={1}>
                        Left
                      </Option>
                      <Option value="center" key={3}>
                        Center
                      </Option>
                      <Option value="right" key={2}>
                        Right
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="inner-s"></div>
      </div>
    </div>
  );
};

export default observer(ImageSettings);
