import ejs from "ejs";
export const tocXhtml = 
`<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:epub="http://www.idpf.org/2007/ops" xml:lang="<%- lang %>" lang="<%- lang %>">
<% 
  function renderElement(content, element) { 

    var currentBlock = content.find((item) => item.blockId === element.itemId || item.blockId === element.pointToChapterId ); 
    if(element.title) { %>
      <li class="toc-entry">
      <% if(currentBlock) { %><a href="<%= currentBlock.filename %>"><% } %>
      <% if(element.chapterNumber) { %>
        <span class="chapter-num"><%- (element.chapterNumber) %>.</span>
        <% } %>
        <span><%- (element.title) %></span>
        <% if(currentBlock) { %></a><% } %>
        <% if(element.subtitle || (element.subheads && element.subheads.length) || (element.children && element.children.length)) { %>
          <ol class="toc-block">
            <% } %>
            <% if(element.subtitle) { %>
            <li class="reset-padding">
              <a class="toc-subtitle-inner">
                <% if(element.chapterNumber) { %>
                  <span class="chapter-num-hidden"><%- (element.chapterNumber) %>.</span>
                <% } %>
                <span> <%= element.subtitle %></span>
              </a>
            </li>
            <% } %>
            <% if(element.subheads && element.subheads.length) { %>
        
            <% element.subheads.forEach(function(subheadItem, index){ %>
            <li class="toc-subheading">
              <a href="<%= currentBlock.filename %>#subhead-<%- index+1 %>">
                <span> <%= subheadItem.text %></span>
              </a>
            </li>
            <% }) %>
        
            <% } %>
            <% if(element.children && element.children.length) { %>
        
            <% element.children.forEach(function(child){ %>
              <%- renderElement(content, child) %>
            <% }) %>
        
            <% } %>
            <% if(element.subtitle || (element.subheads && element.subheads.length) || (element.children && element.children.length)) { %>
          </ol>
          <% } %>
    
      </li>
    <%}  else { %>
    <li class="toc-entry">
      <a href="<%= currentBlock.filename %>">
        <span>
          <%= element.chapterNumber %>. Chapter <%= element.chapterNumber %>
        </span>
      </a>
    </li>
    <% }
  }
%>

<head>
  <title><%= title %></title>
  <meta charset="UTF-8" />
  <link rel="stylesheet" type="text/css" href="style.css" />
</head>

<body>
  <div class="<%= themeId %>" >
    <div class="epub-toc-title-card">
      <h2><%= tocTitle %></h2>
    </div>
    <nav id="toc" epub:type="toc">
      <ol class="toc-block">
        <% toc.forEach(element => { %>
        <% if (!element.parentItemId) { %>
          <%- renderElement(content, element) %>
        <% } %>
        <% }) %>
      </ol>
    </nav>
  </div>
</body>

</html>
`;