import React from "react";
import {
  ToolbarButton,
  ToolbarButtonProps,
  useEventPlateId,
  usePlateEditorState,
} from "@udecode/plate";
import { doesEditorHaveRedos } from "../utils";
import {  } from "../../../config/typescript";
import { BaseOperation } from "slate";
import { RedoIcon } from "../../../../../content/icons";
import { CURRENT_COLOR } from "../../../partials";

export const RedoToolbarButton = (
  props: Omit<ToolbarButtonProps, "icon"> & { onRedo: (change: BaseOperation[]) => void }
) => {    
    const editor = usePlateEditorState();

    const hasRedos = editor ? doesEditorHaveRedos(editor) : false;

    return (
      <ToolbarButton
        active={hasRedos}
        icon={<RedoIcon active={hasRedos} color={CURRENT_COLOR} />}
        onMouseDown={async (event) => {
          if (!editor) return;

          event.preventDefault();
          event.stopPropagation();

          if (hasRedos) {
            const change = editor.history.redos[0];
            editor.redo();
            props.onRedo(change);
          }
        }}
        {...props}
      />
    );
  };
