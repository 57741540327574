import { Spin } from "antd";
import { observer } from "mobx-react";
import React from "react";
import useRootStore from "../../../store/useRootStore";
import { BodyContainer } from "./components";

const WritingHabitContainer: React.FC = () => {
  const { section, collapsed } = useRootStore().writingHabitStore;

  return (
    <>
      <Spin spinning={section === "loading"}>
        {!collapsed && <BodyContainer />}
      </Spin>
    </>
  );
};

export default observer(WritingHabitContainer);
