import React, { FunctionComponent } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { SideBarPlaceholderItem } from "../../../../types/sidebar";

export interface ListPlaceholderItemProps {
  provided: DraggableProvided;
  isVisible: boolean;
  item: SideBarPlaceholderItem;
}

const ListPlaceholderItem: FunctionComponent<ListPlaceholderItemProps> = ({
  provided,
  isVisible,
  item,
}) => {
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...provided.draggableProps.style,
        display: "flex",
        alignItems: "center",
        justifyContent: "stretch",
      }}
    >
      <div
        className={`chapter-block-item placeholder ${
          isVisible ? "visible" : ""
        }`}
        style={{ marginLeft: item.level > 0 ? `${item.level + 1.8}rem` : "" }}
      ></div>
    </div>
  );
};

export default ListPlaceholderItem;
