import React, { useState } from "react";
import { Button } from "../Shared/Buttons";

import useRootStore from "../../store/useRootStore";
import { InfoIcon } from "../../content/icons";
import { Tooltip, Col, Row } from "antd";

export default function SnapshotContainer() {
  const { exportSpanshot, book } = useRootStore().bookStore;
  const [exporting, setExporting] = useState<boolean>(false);

  const bookId = book._id;

  const downloadFile = async (fileName, snapshotStr) => {
    const blob = new Blob([snapshotStr], { type: "application/json" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const createSnapshot = async () => {
    setExporting(true);
    const book = await exportSpanshot(bookId);

    if (book) {
      const fileName = `${book.title}-${new Date().toISOString()}`.replace(/ /ig, "-");
      downloadFile(fileName, JSON.stringify(book));
    }
    setExporting(false);
  };

  const snapshotTooltipText = "Downloading a snapshot allows you to quickly save a copy of your book to your device and can be used as a backup. This file can also be used to help our support team debug any issues you might be having. You cannot currently import this file directly into Atticus but we can use it to reinstate that version of your book for you.";

  return (
    <div className="snapshot-container-cover">
      <Row align="bottom" gutter={4}>
        <Col>
          <h3 className="snapshot-container-title">Snapshots</h3>
        </Col>
        <Col flex={1}>
          <Tooltip title={snapshotTooltipText} placement="rightTop">
            <>
              <InfoIcon color="#A2A5A6" />
            </>
          </Tooltip>
        </Col>
      </Row>
      <div className="inner-xs" />
      <div className="snapshot-container-item-wrapper">
        <Button
          onClick={createSnapshot}
          loading={exporting}
          type="at-primary"
          backgroundColor="green"
          size="small"
          className="snapshot-container-button"
        >
          Download snapshot
        </Button>
      </div>
    </div>
  );
}
