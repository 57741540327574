import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Row, Avatar, Form, FormItemProps } from "antd";

import FileUpload from "../components/Shared/Forms/FileUpload";
import { CameraIcon } from "../content/icons";
import { AtticusTextField } from "../components/Shared/Form";
import { Button } from "../components/Shared/Buttons";
import { getInitials } from "../containers/header/profile";
import { LoadingOutlined } from "@ant-design/icons";

const commonFormItemProps = {
  labelAlign: "left",
  colon: false,
} as FormItemProps;

export const Profile: FunctionComponent<IAuthStore.MyProfileProps> = ({
  profile,
  OnSubmitHandler,
}: IAuthStore.MyProfileProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm<IAuthStore.ProfileFields>(); 
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    profile ? form.setFieldsValue(profile) : "";
  }, [form, profile]);

  const handleOnSubmit = (values: IAuthStore.ProfileFields) => {
    setIsSubmitting(true);
    form
      .validateFields()
      .then(() => {
        OnSubmitHandler(values).then(() => {
          setIsSubmitting(false);
        });
      })

      .catch((errorInfo) => {
        setIsSubmitting(false);
        console.log("Form validation failed:", errorInfo);
      });
  };

  //handle loading state while uploading a image
  const updateProPictureLoadingState = (newLoadingState : boolean) : void => {
    setLoading(newLoadingState);
  };

  const OnProfilePictureUploadHanlder = async (fileUrl?: string | null): Promise<void> => {
      if(fileUrl) {
        setLoading(false);
        setImageUrl(fileUrl);
        profile ? profile.profilePictureURL = fileUrl : "";
      }
  };

  return (
    <Form
      form={form}
      colon={false}
      layout={"vertical"}
      onFinish={handleOnSubmit}
      initialValues={profile}
    >
      <div className='my-accoutn-card'>
        <h3 className='title'>My account</h3>
        <Row align='middle'>
          <Col>
            <div className='form-field'>
              <Form.Item {...commonFormItemProps}>
                <FileUpload
                  fileType='image/*'
                  componentWidth={120}
                  background={"transparent"}
                  onFileUpload={OnProfilePictureUploadHanlder}
                  updateLoadingState={updateProPictureLoadingState}
                  isProfileImage={true}
                >
                  <div>
                    <div className={"pr-avatar"}>
                      {!loading ?<Avatar
                        className='profile-image'
                        size='large'
                        src={imageUrl ? imageUrl : profile?.profilePictureURL}
                      >
                        <div className="profile-text-p">
                          {getInitials(profile?.firstName, profile?.lastName)}
                        </div>
                      </Avatar> : <div className="profile-image-loader"><LoadingOutlined/></div>}
                      <div className='upload-icon'>
                        <CameraIcon />
                      </div>
                    </div>
                  </div>
                </FileUpload>
              </Form.Item>
            </div>
          </Col>
          <Col>
            <h2 className='profile-name'>
              {profile ? profile.firstName + " " + profile.lastName : "N/A"}
            </h2>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12} xxl={6}>
            <div className='form-field'>
              <Form.Item
                {...commonFormItemProps}
                label='First name'
                name='firstName'
                rules={[{ message: "First Name cannot be empty" }]}
              >
                <AtticusTextField placeholder='First name' />
              </Form.Item>
            </div>
          </Col>
          <Col span={12} xxl={6}>
            <div className='form-field'>
              <Form.Item
                label='Last name'
                name='lastName'
                rules={[{ message: "Last Name cannot be empty" }]}
                {...commonFormItemProps}
                className='ant-input-extended'
              >
                <AtticusTextField placeholder='Last name' />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={24} xxl={12}>
            <div className='form-field'>
              <Form.Item
                label='Email address'
                {...commonFormItemProps}
                className='ant-input-extended'
                name='email'
              >
                <AtticusTextField placeholder='Email address' disabled />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={24} xxl={12}>
            <div className='form-field'>
              <Form.Item
                label='Website'
                {...commonFormItemProps}
                className='ant-input-extended'
                name='website'
              >
                <AtticusTextField placeholder='Website' />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <div>
          <Button
            className='update-button'
            type='at-primary'
            htmlType='submit'
            disabled={isSubmitting ? true : false}
            loading={isSubmitting}
          >
            {isSubmitting ? "" : "Update"}
          </Button>
        </div>
      </div>
    </Form>
  );
};
