import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form, Row, Col, FormItemProps, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import useRootStore from "../../../store/useRootStore";

import ImageGallery from "../../ImageGallery";
import { ButtonSize, DeleteImageButton } from "../Buttons";
import { AtticusTextField } from "../Form";
import FileUpload from "./FileUpload";
import BookBrush from "./BookBrush";
import { UploadIcon } from "../../../content/icons";


const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const fullWidthFormItemProps: FormItemProps = {
  colon: false,
  labelCol: { span: 24 },
  style: { marginBottom: 15 },
};

const EditPublisherDetails = observer(() => {
  const { book } = useRootStore().bookStore;
  const { saveBook } =
    useRootStore().shelfStore;
  const { setBook } = useRootStore().bookStore;
  const { refreshCache } = useRootStore().pdfCacheStore;

  const [publisherLogoUrl, setPublisherLogoUrl] = useState(
    book.publisherLogoURL
  );
  const [uploading, setUploading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (book && form) {
      form.setFieldsValue(book);
    }
  }, [book, form]);

  const onFinish = (values: any) => {
    try {
      delete values["CustomTocTitle"];
      const vals: Partial<IBookStore.Book> = {
        ...values,
        publisherLogoURL: publisherLogoUrl,
        custom_toc_title: form.getFieldValue("custom_toc_title"),
      };
      setBook({ ...book, ...vals });
      saveBook(book._id, vals);
      refreshCache(book._id, "book-properties-change");
    } catch (error) {
      console.error(error);
    }
  };

  const updatePublisherLogoUrl = (url: string) => {
    setPublisherLogoUrl(url);
    form.submit();
  };

  const handleSelectedImageUrl = (link) => {
    updatePublisherLogoUrl(link);
  };

  return (
    <Form
      name="validate_other"
      form={form}
      onValuesChange={(_, vals) => onFinish(vals)}
      onFinish={(vals) => onFinish(vals)}
      initialValues={book}
      className="edit-book-ant-form"
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={14} xl={16}>
          <Form.Item
            name="publisherName"
            label={<span className="edit-book-form-label">Publisher name</span>}
            {...fullWidthFormItemProps}
          >
            <AtticusTextField
              placeholder="Publisher name"
              className="edit-book-input-field"
            />
          </Form.Item>
          <Form.Item
            name="publisherLink"
            label={<span className="edit-book-form-label">Publisher link</span>}
            {...fullWidthFormItemProps}
          >
            <AtticusTextField
              placeholder="Publisher link"
              className="edit-book-input-field"
            />
          </Form.Item>
          <Row>
            <Col xs={11}>
              <Form.Item
                name="printISBN"
                label={<span className="edit-book-form-label">Print ISBN</span>}
                {...fullWidthFormItemProps}
              >
                <AtticusTextField
                  placeholder="Print ISBN"
                  className="edit-book-input-field"
                />
              </Form.Item>
            </Col>
            <Col xs={2}></Col>
            <Col xs={11}>
              <Form.Item
                name="ebookISBN"
                label={
                  <span className="edit-book-form-label">E-book ISBN</span>
                }
                {...fullWidthFormItemProps}
              >
                <AtticusTextField
                  placeholder="E-book ISBN"
                  className="edit-book-input-field"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={8}>
          <div className="edit-publisher-image-section-wrapper">
            <Form.Item
              name="publisherLogoURL"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              label={
                <span className="edit-book-form-label">Publisher logo</span>
              }
              {...fullWidthFormItemProps}
            >
              {publisherLogoUrl ? (
                <>
                  <div className="edit-publisher-image-dnd-container">
                    <div
                      className="edit-publisher-image-cover"
                      style={{ backgroundImage: `url(${publisherLogoUrl})` }}
                    >
                      <div className="edit-publisher-image-overlay">
                        <DeleteImageButton onClick={() => updatePublisherLogoUrl("")} />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="edit-publisher-image-dnd-container">
                    <FileUpload
                      folder="publisher-logos"
                      fileType="image/png, image/jpg, image/jpeg"
                      onFileUpload={(url) => updatePublisherLogoUrl(url || "")}
                      onFileUploadStart={() => setUploading(true)}
                      onFileUploadEnd={() => setUploading(false)}
                      styleOverrides={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: 4,
                        borderWidth: 2,
                        borderStyle: "dashed",
                        borderColor: "#ADC1E3",
                      }}
                    >
                      <div>
                        {uploading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined className="edit-publisher-spinner" />
                            }
                          />
                        ) : (
                          <>
                            <span>
                              <UploadIcon color="#3363B9" />
                            </span>
                            <p>
                              <span className="edit-publisher-image-dnd-text-highlight">
                                Click to upload image
                              </span>{" "}
                              or drag and drop
                            </p>
                          </>
                        )}
                      </div>
                    </FileUpload>
                  </div>
                  <div className="edit-publisher-image-button-container">
                    <ImageGallery
                      handleImageUrl={handleSelectedImageUrl}
                      renderButtonProps={{
                        type: "at-primary",
                        backgroundColor: "green",
                        block: true
                      }}
                    />
                    <BookBrush 
                      type="at-primary"
                      className="book-brush-button"
                      fullWidth
                    >
                        Create with book brush
                    </BookBrush>
                  </div>
                </>
              )}
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
});

export default EditPublisherDetails;
