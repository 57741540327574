import React from "react";
import { Form, Select, Row, Col, Slider } from "antd";
import { observer } from "mobx-react";

import { sizeMarks } from "../helpers/configs";
import { fonts, FontVariant, getFontFamilyName } from "@surge-global-engineering/css-generator";
import { AtticusSelect, AtticusSlider } from "../../../Shared/Form";

interface TitleSettingProps {
  title: string
  prefix: string,
}

const { Option } = Select;

const LayoutSettings = ({ prefix, title } : TitleSettingProps) => (
  <div className="section">
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item 
          className="at-form-item"
          name={`${prefix}Font`} 
          label={`${title} font`}
        >
          <AtticusSelect 
            placeholder="Select"
            selectOptions={fonts
              .filter(font => font.availableForPrint) //filter out "print" specific fonts only
              .map(font => ({
                classNames: getFontFamilyName(font.id, FontVariant.regular),
                value: font.id,
                label: <>
                  {font.name}{" "}
                  {font.additionalInfo ? (
                  <span className="font-additional-info">
                  ({font.additionalInfo})
                  </span>
                ) : null}
                </>,
              }))}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item 
          className="at-form-item"
          name={`${prefix}Size`} 
          label={`${title} size`}
        >
          <AtticusSlider min={1} max={5} />
        </Form.Item>
      </Col>
    </Row>
  </div>
);

export default observer(LayoutSettings);