import { SpecialTemplate } from "./";

export const Prologue: SpecialTemplate = {
  title: "Prologue",
  children: [
    {
      type: "p",
      children: [
        {
          text: "This section of your novel is considered outside the main plot story, but will always be closely related to it.",
        },
      ],
    },
    {
      type: "p",
      children: [
        {
          text: "You might use a prologue to introduce a character or setting, or provide relevant background information for the main event to come.",
        },
      ],
    },
    {
      type: "p",
      children: [
        {
          text: "Similar to an introduction, a prologue is typically placed in the main body content of a book, before the first chapter, and is therefore not technically front matter.",
        },
      ],
    },
  ],
  configuration: {
    showChapterImage: true,
    showPageNumber: true,
    showChapterHeading: true,
    showHeaderFooter: true,
    showInTableOfContents: true,
    showFirstSentenceFormatting: true,
  },
};
