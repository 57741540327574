import React from "react";
import { Button, ButtonSize, ButtonType } from "./Button";
import { DeleteIcon } from "../../../content/icons";

interface DeleteImageButtonProps {
  onClick: React.MouseEventHandler<HTMLElement>;
}
export const DeleteImageButton: React.FC<DeleteImageButtonProps> = (props) => {
  return (
  <Button 
    type={ButtonType.SECONDARY}
    size={ButtonSize.ICON_ONLY}
    backgroundColor="white"
    className="delete-image-button"
    {...props}
  >
    <DeleteIcon color="#454a4d" />
  </Button>
  );
};
