import { SpecialTemplate } from "./";

export const Blurbs: SpecialTemplate = {
  title: "Blurbs",
  children: [
    {
      type: "p",
      children: [
        {
          text: "A blurb is typically a 1-2 line endorsement provided by a celebrity or well-known authority. You can also use this specially formatted page to share an editorial review.",
        },
      ],
    },
    {
      type: "p",
      children: [
        {
          text: "When publishing through KDP, the first few pages of your book can be seen using the “Look Inside” feature on Amazon, and blurbs are a great way to capture interest and show social proof for your book.",
        },
      ],
    },
  ],
  configuration: {
    showChapterImage: false,
    showPageNumber: false,
    showChapterHeading: true,
    showHeaderFooter: true,
    showInTableOfContents: true,
    showFirstSentenceFormatting: false,
  },
};
