import { ThemeStyleProps, addPrefix } from "@surge-global-engineering/css-generator";

import {
  PdfChapterEndnotes,
  PdfEndnoteSubheading,
  PdfSlateEndnote,
} from "../../../components/Previewer/print/types";

import { parseFormattableEndnote } from ".";

export const parseEndnotes = (
  endnotes: (
    | ISlateStore.SlateEndnote
    | PdfSlateEndnote
    | PdfEndnoteSubheading
  )[],
  separator = true,
  chapter? : IChapterStore.ChapterMeta,
  theme? : IThemeStore.ThemeStyleProps,
  isExport? : boolean
): string => {
  let html = "<div class=\"endnotes\" epub:type=\"endnotes\">";
  html += separator ? "<div class=\"endnotes-separator\"></div>" : "";
  const filteredEndnotes = (endnotes || []).filter(
    (note) => note.type === "endnote"
  );
  for (let i = 0; i < endnotes.length; i += 1) {
    const note = endnotes[i];
    if (note.type === "endnote-subheading") {
      html += `<aside><p class="first"><b>${note.text}</b></p></aside>`;
      continue;
    }
    const chapterId = note.chapterId;
    const endnoteUrl = isExport
    ? `${chapterId}.xhtml#${chapterId}-endnote-${note.id}`
    : `#endnote-${note.id}`;
    const noteId = isExport? `${chapterId}-endnote-${note.id}-text`: `endnote-${note.id}-text`;
    
    html += `<aside id="${noteId}" class="endnote-text" role="note" epub:type="endnote">`;
    html += `<p class="first" ><span class="endnote-text-number" ><a class="endnote-backlink" epub:type="noteref"  href="${endnoteUrl}">${
      filteredEndnotes.findIndex((candidateNote) => candidateNote === note) + 1
    }.</a></span>
      ${parseFormattableEndnote(note.note, chapter, theme, isExport)}
    </p>`;
    html += "</aside>";
  }
  html += "</div>";
  return html;
};


export const parseEndnotesByChapter = (
  endnotes: (PdfChapterEndnotes)[],
  separator = true,
  chapter: IChapterStore.ChapterMeta,
  theme: IThemeStore.ThemeStyleProps,
  isExport?: boolean,
): string => {
    let html = "<div class=\"endnotes\" epub:type=\"endnotes\">";
    html += separator ? "<div class=\"endnotes-separator\"></div>" : "";
    
    for (let i = 0; i < endnotes.length; i += 1) {

      endnotes[i].type === "chapter" 
      ?     
      html += `<div  class="endnote-title" > ${endnotes[i].chapterTitle}</div>`
      :
      html += `<div  class="endnote-title" > <b>${endnotes[i].chapterTitle}</b> </div>`;

      for (let j = 0; j < endnotes[i].chapterEndnotes.length; j +=1){
        const note = endnotes[i].chapterEndnotes[j];
        const endnoteUrl = isExport? `${note.chapterId}.xhtml#${note.chapterId}-endnote-${note.id}`: `#endnote-${note.id}`;
        const endnoteId = isExport? `${note.chapterId}-endnote-${note.id}-text`: `endnote-${note.id}-text`;
        html += `<aside id="${endnoteId}" class="endnote-text" role="note" epub:type="endnote">`;
        html += `<p class="endnote-item" ><span class="endnote-text-number" ><a class="endnote-backlink" epub:type="noteref"  href="${endnoteUrl}">${
          j + 1
        }.</a></span>
          ${parseFormattableEndnote(note.note, chapter, theme, isExport)}
        </p>`;
        html += "</aside>";
      }
      
    }
    html += "</div>";
    return html;
};

export const parseEndNoteChapter = (
  chapter:IChapterStore.ChapterMeta, 
  endnotes: (PdfSlateEndnote | PdfEndnoteSubheading |PdfChapterEndnotes )[], 
  theme: ThemeStyleProps,
  isExport?: boolean): string => {
    
  let html = `<div class="${chapter.type}">`;

  theme.ePubEndnotesChapterSettings.showTitle ?
    html += `<div class="${addPrefix(
      "chapter-title",
      !isExport? "previewer": ""
    )}"><h2>${chapter.title}</h2></div>` : undefined;

  html += "<div class=\"chapter-body\">";
  html += "<div class=\"wrapper\">"; 
  html += theme.ePubEndnotesChapterSettings.categorizeByTitle ? 
  parseEndnotesByChapter(endnotes as PdfChapterEndnotes[], false, chapter, theme, isExport) : 
  parseEndnotes(endnotes as (PdfSlateEndnote | PdfEndnoteSubheading)[], false, chapter, theme, isExport);
  html += "</div>";
  html += "</div>";
  html += "</div>";
  return html;
};

export const parseEPubNotIncluded = (): string => {
  const html =
    "<div style={{ marginTop: \"20rem\" }}> This content is not included in ePub </div>";
  return html;
};

export const parseEndnoteAnchorPoint = (
  currentEndnote: ISlateStore.SlateEndnote,
  allEndnotes: ISlateStore.SlateEndnote[],
  chapterId?: string,
  endnoteChapter?: IChapterStore.Chapter,
  endnotesOffset?: number,
  themeConfig?: ThemeStyleProps
): string => {

  const shouldRenderEndnotes = themeConfig?.ePubNotesMode !== "END_OF_BOOK";

    const link = shouldRenderEndnotes
      ? `#${chapterId}-endnote-${currentEndnote.id}-text`
      : `${endnoteChapter?._id}.xhtml#${chapterId}-endnote-${currentEndnote.id}-text`;
    const offset: number =
      themeConfig?.ePubNotesMode === "END_OF_BOOK" && endnotesOffset
        ? endnotesOffset
        : 0;

    return `<a href=${link} class="endnote-link" id="${chapterId}-endnote-${
      currentEndnote.id
    }" role="doc-noteref" epub:type="noteref"><sup>${
      offset + allEndnotes.length
    }</sup></a>`;

};
