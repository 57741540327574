import React, { useState, useEffect, useMemo } from "react";
import { Select, Row, Col, Radio, Divider } from "antd";
import { findIndex } from "lodash";

const { Option, OptGroup } = Select;
import useRootStore from "../../store/useRootStore";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { AtticusSelect, AtticusSlider } from "../Shared/Form";

// Moves the menu below the select input
const menuProps = {
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left",

	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left",
	},
	getContentAnchorEl: null
};

const fnts = [
	{
		device: "iphone",
		fonts: [
			"AthelasRegular",
			"CharterRegular",
			"PalatinoRegular",
			"Georgia",
			"SanFranciscoRegular",
			"SeravekRegular",
			"IowanRegular"
		]
	},
	{
		device: "ipad",
		fonts: [
			"PalatinoRegular",
			"Georgia",
			"SanFranciscoRegular",
			"AthelasRegular",
			"IowanRegular",
			"NewYorkRegular",
			"SeravekRegular",
			"CharterRegular"
		]
	},
	{
		device: "fire",
		fonts: [
			"BookerlyRegular",
			"BaskervilleRegular",
			"CaeciliaRegular",
			"PalatinoRegular",
			"Georgia",
			"Helvetica",
			"OpenDyslexicRegular"
		]
	},
	{
		device: "paperwhite",
		fonts: [
			"BookerlyRegular",
			"BaskervilleRegular",
			"Georgia",
			"Helvetica",
			"PalatinoRegular",
			"OpenDyslexicRegular"
		]
	},
	{
		device: "oasis",
		fonts: [
			"BookerlyRegular",
			"CaeciliaRegular",
			"DroidSerifRegular",
			"LucidaRegular",
			"Georgia",
			"Helvetica",
			"OpenDyslexicRegular"
		]
	},
	{
		device: "libra",
		fonts: [
			"AvenirNextRegular",
			"Georgia",
			"GillSansRegular",
			"DroidSerifRegular",
			"OpenDyslexicRegular"
		]
	},
	{
		device: "clara",
		fonts: [
			"AvenirNextRegular",
			"Georgia",
			"GillSansRegular",
			"DroidSerifRegular",
			"OpenDyslexicRegular"
		]
	},
	{
		device: "forma",
		fonts: [
			"AvenirNextRegular",
			"Georgia",
			"GillSansRegular",
			"DroidSerifRegular",
			"OpenDyslexicRegular"
		]
	},
	{
		device: "nia",
		fonts: [
			"AvenirNextRegular",
			"Georgia",
			"GillSansRegular",
			"DroidSerifRegular",
			"OpenDyslexicRegular"
		]
	},
	{
		device: "glowlight",
		fonts: [
			"BaskervilleRegular",
			"HelveticaNeueRegular",
			"Trebuchet MS",
			"GillSansRegular",
			"OpenDyslexicRegular"
		]
	},
	{
		device: "glowlight_plus",
		fonts: [
			"HelveticaNeueRegular",
			"Trebuchet MS",
			"GillSansRegular",
			"OpenDyslexicRegular"
		]
	},
	{
		device: "galaxy_s21",
		fonts: [
			"DroidSansRegular"
		]
	},
	{
		device: "galaxy_tab_7",
		fonts: [
			"DroidSansRegular"
		]
	},
	{
		device: "print",
		fonts: [
			"inherit"
		]
	},
];
export const fontSizes = {
  min: 8,
	default: 12,
  max: 14,
  labels: {
    8: {
      label: "",
    },
    9: {
      label: "",
    },
    11: {
      label: "",
    },
    12: {
      label: "",
    },
    13: {
      label: "",
    },
    14: {
      label: "",
    },
  },
};

function Toolbar(props) {
	const { ondeviceselect, device, previewFontSize, previewFont } = props;
	const { getDevicePreview } = useRootStore().themeStore;

	const fntindex = findIndex(fnts, ["device", device]);
	const [font, setFont] = useState(fnts[fntindex].fonts[0]);

	useEffect(() => {
		getDevicePreview(device).then((dv) => {
			setFont(dv ? dv.fonts[0] : "");
			previewFont(dv ? dv.fonts[0] : "");
		});

	}, [device]);

	const handleDeviceSelect = (device: string) => {
		ondeviceselect(device);
	};

	const fontOptions = useMemo(() => {
        if (fntindex === -1) return [];
        return fnts[fntindex].fonts.map((v) => {
            const displayName = v.replace(/Regular/, "").trim().split(/(?=[A-Z])/).join(" ");
            return (
                <Option key={v} value={v} style={{ fontFamily: v }}>
                    {displayName}
                </Option>
            );
        });
    }, [fntindex, fnts]);

	return fntindex !== -1 ? (
		<Row gutter={16} className="previewer-toolbar">
			<Col>
				<AtticusSelect defaultValue="ipad" placeholder="Select device" value={device} className="previewer-select" onChange={handleDeviceSelect}>
					<Option value="print">Print</Option>
					<OptGroup label="Apple">
						<Option value="iphone">iPhone</Option>
						<Option value="ipad">iPad</Option>
					</OptGroup>
					<OptGroup label="Android">
						<Option value="galaxy_tab_7">Galaxy Tab 7</Option>
						<Option value="galaxy_s21">Galaxy S21</Option>
					</OptGroup>
					<OptGroup label="Kindle">
						<Option value="fire">Fire</Option>
						<Option value="paperwhite">Paperwhite</Option>
						<Option value="oasis">Oasis</Option>
					</OptGroup>
					<OptGroup label="Nook">
						<Option value="glowlight">Glowlight 3</Option>
						<Option value="glowlight_plus">Glowlight Plus</Option>
					</OptGroup>
					<OptGroup label="Kobo">
						<Option value="forma">Forma</Option>
						<Option value="libra">Libra H20</Option>
						<Option value="clara">Clara</Option>
						<Option value="nia">Nia</Option>
					</OptGroup>
				</AtticusSelect>
			</Col>
			{device !== "print" ? (
				<Col>
					<AtticusSelect
						value={font}
						onChange={(v) => {
							setFont(v);
							previewFont(v);
						}}
						className="previewer-select"
						dropdownRender={el => (
							<>
								<div className="font-size-slider">
									<span className="small-a">A</span>
									<AtticusSlider className="slider" defaultValue={fontSizes.default} onChange={(e) => previewFontSize(e)} marks={fontSizes.labels} min={fontSizes.min} max={fontSizes.max} />
									<span className="big-a">A</span>	
								</div>
								<div>
									{el}
								</div>
							</>
						)}
					>
						{fontOptions}
					</AtticusSelect>
				</Col>
			) : null}
		</Row>
	) : null;
}

export default Toolbar;
