import {
  findNodePath,
  TElement,
  TPath,
  TReactEditor,
} from "@udecode/plate-core";

export const getElementPath = (
  element: TElement,
  editor: TReactEditor
): TPath | undefined => {
  return findNodePath(editor, element);
};
