import React from "react";
import { FireIcon, SnowFlakeIcon } from "../../Plate/partials";
import { Col, Row, Progress } from "antd";
import { percentage } from "./helper";

interface GoalDailyProgressProps {
  streak?: string;
  total: number;
  wordsPerDay?: number;
}

export const GoalDailyProgress: React.FC<GoalDailyProgressProps> = ({
  streak,
  total,
  wordsPerDay,
}) => {
  return (
    <div className="" style={{ width: "100%", paddingBottom: "10px" }}>
      <Row gutter={8} justify="space-around" align="middle">
        <Col span={8} className="gutter-row">
          <Progress
            className="goals-progress"
            format={(percent) => (
              <div className="goals-progress-percentage">{percent}%</div>
            )}
            width={85}
            type="dashboard"
            strokeColor={{ "0%": "#66D8BF", "100%": "#00BE95" }}
            gapDegree={5}
            percent={Math.round(percentage(total, wordsPerDay))}
            strokeWidth={13}
          />
        </Col>
        <Col span={16} className="gutter-row">
          <Row justify="start" align="middle">
            <div className="total-words-negative">{total < 0 ? total : ""}</div>
            <div className="total-words">{total >= 0 && total} /</div>

            <div className="words-per-day">
              {wordsPerDay && Math.round(wordsPerDay)}
            </div>

            {streak === "snow" ? <SnowFlakeIcon /> : <FireIcon />}
          </Row>
          <Row>
            <Col className="goals-word-count-text">
              Words needed to stay on track
            </Col>
          </Row>
        </Col>
      </Row>
      {total < 0 ? (
        <Row>
          <div className="deleted-word-msg">
            You have deleted words and are behind the total word count goal that
            was calculated before you started writing today.
          </div>
        </Row>
      ) : null}
    </div>
  );
};
