import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Progress,
  Typography,
  Menu,
  Dropdown,
  Modal,
} from "antd";
import moment from "moment";

import { AtticusClient } from "../../../api/atticus.api";
import { observer } from "mobx-react";
import useRootStore from "../../../store/useRootStore";
import { getWordsCount } from "../../../utils/helper";
import { db } from "../../../db/bookDb";
import { useOnlineStatus } from "../../../utils/isOffline";
import { Button, ButtonType } from "../../Shared/Buttons";
import BookGoalForm from "./BookGoalForm";
import { GoalDailyProgress } from "./GoalDailyProgress";
import { AddIcon, CloseOutlinedIcon, MoreSettingsIcon } from "../../../content/icons";
import { calculateGoal, isoWithTimeZone, percentage } from "./helper";

const { Text } = Typography;

export const BookGoal: FunctionComponent = () => {
  const [goalData, setGoalData] = useState<IGoalStore.Goal | null>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [currentGoal, setCurrentGoal] = useState(false);
  const [editGoal, setEditGoal] = useState(false);
  const { bookId } = useParams() as { bookId: string };
  const containerRef = useRef<any>();
  const [showAnim, setShowAnim] = useState(false);
  const isOnline = useOnlineStatus();
  const [total, setTotal] = useState(0);
  const { getBookBodies, body, chapter } = useRootStore().bookStore;

  const getGoalData = async () => {
    const goalData = await db.bookGoals.where("bookId").equals(bookId).first();
    if (goalData) {
      setLoading(false);
      setCurrentGoal(true);
      setGoalData(goalData);
    }
  };

  const updateTodayWordCount = async () => {
    if (goalData && goalData.status === "active") {
      goalData.currentDateWrittenWordCount = total;
      const updatedTWC = await db.bookGoals.put(goalData);
    }
  };

  const updateStreak = async (streak) => {
    const goalData = await db.bookGoals.where("bookId").equals(bookId).first();
    if (goalData && goalData.status === "active") {
      goalData.streak = streak;
      const updatedTWC = await db.bookGoals.put(goalData);
    }
  };

  const completeGoalDeleteIDB = async (total) => {
    if (goalData && goalData.status === "active") {
      goalData.currentDateWrittenWordCount = total;
      const completedGoalCWCID = await db.bookGoals.put(goalData);
      const totalWrittenWordCount =
        goalData.preGoalWordCount + goalData.currentDateWrittenWordCount;
      const completedGoal = await AtticusClient.CompleteGoal(
        bookId,
        goalData._id,
        totalWrittenWordCount
      );
      goalData.status = "completed";
      const completedGoalID = await db.bookGoals.put(goalData);

      setShowAnim(true);
      setTimeout(() => {
        setShowAnim(false);
      }, 5000);
    }
  };

  const createNewGoal = async () => {
    if (goalData) {
      setCurrentGoal(false);
      const deleteGoalIDB = await db.bookGoals
        .where("bookId")
        .equals(bookId)
        .delete();
      resetForm();
    }
  };

  const dailyWCCheckup = async () => {
    const goalData = await db.bookGoals.where("bookId").equals(bookId).first();
    const todayDate = new Date();
    const today = isoWithTimeZone(todayDate).slice(0, 10);

    if (goalData && goalData.status === "completed") {
      return getGoalData();
    }

    if (goalData && goalData.status === "active") {
      const currentDateIDB = goalData.currentDate === today;
      const lastUpdatedWordsPerDay = goalData.lastUpdatedWordsPerDay === today;

      if (!currentDateIDB || !lastUpdatedWordsPerDay) {
        if (!isOnline) {
          const modal = Modal.warning({
            title:
              "You must be connected to the internet at least once a day to update the goal.",
            closable: true,
            okButtonProps: {
              style: {
                display: "none",
              },
            },
            width: 550,
          });

          return modal;
        }

        if (goalData.currentDateWrittenWordCount >= goalData.wordsPerDay) {
          updateStreak("fire");
        } else {
          updateStreak("snow");
        }

        const writtenWC =
          goalData.preGoalWordCount + goalData.currentDateWrittenWordCount;
        const sDate = new Date().toDateString().slice(0, 10);
        const updatedGoal = await AtticusClient.FetchLatestGoal(
          bookId,
          writtenWC,
          sDate
        );

        goalData.currentDate = today;
        goalData.lastUpdatedWordsPerDay = today;
        goalData.preGoalWordCount =
          goalData.preGoalWordCount + goalData.currentDateWrittenWordCount;
        goalData.currentDateWrittenWordCount = 0;
        const calculatedGoalResult = calculateGoal(
          goalData.targetWordCount,
          goalData.currentDate,
          goalData.dueDate,
          goalData.writeDays,
          updatedGoal.writtenWordCount
        );

        if (updatedGoal) {
          goalData.wordsPerDay = calculatedGoalResult.wordsPerDay;
          const updatedTWC = await db.bookGoals.put(goalData);
          getGoalData();
        }
      } else {
        getGoalData();
      }
    } else {
      //if the goal is not saved in IDB
      const getProjectGoal = await AtticusClient.GetProjectGoal(bookId);
      if (getProjectGoal) {
        const startDate = new Date();

        const IDBGoal = {
          _id: getProjectGoal._id,
          bookId: getProjectGoal.bookId,
          preGoalWordCount: getProjectGoal.writtenWordCount,
          wordsPerDay: getProjectGoal.wordsPerDay,
          lastUpdatedWordsPerDay: isoWithTimeZone(startDate).slice(0, 10),
          currentDateWrittenWordCount: 0,
          currentDate: isoWithTimeZone(startDate).slice(0, 10),
          targetWordCount: getProjectGoal.targetWordCount,
          streak: "snow",
          status: getProjectGoal.status,
          dueDate: getProjectGoal.dueDate,
          writeDays: getProjectGoal.writeDays,
        };

        const saveGoalIDB = await db.bookGoals.add(IDBGoal);
      }

      getGoalData();
    }
  };

  const setTotalWordCount = async () => {
    let list: Array<string> = [];
    let count = 0;

    const book = await getBookBodies();
    list = book.ids;
    count = book.words;

    const goalData = await db.bookGoals.where("bookId").equals(bookId).first();

    let lastCount = 0;
    if (goalData) {
      lastCount = goalData.preGoalWordCount;
    }

    const words = list.includes(chapter._id) ? getWordsCount(body) : 0;
    setTotal(words + count - lastCount);
  };

  useEffect(() => {
    dailyWCCheckup();
    setTotalWordCount();
  }, []);

  const BookWCCompleted = observer(() => {
    const { getBookBodies, body, chapter } = useRootStore().bookStore;
    const [count, setCount] = useState(0);
    const [list, setList] = useState<Array<string>>([]);

    useEffect(() => {
      getBookBodies().then((d) => {
        setCount(d.words);
        setList(d.ids);
      });
    }, []);

    useEffect(() => {
      getBookBodies().then((d) => {
        setCount(d.words);
      });
    }, [chapter._id]);

    const words = list.includes(chapter._id) ? getWordsCount(body) : 0;

    return (
      <div>
        <p style={{ display: "none" }} ref={containerRef}>
          {count + words}
        </p>

        {goalData && goalData.status === "active" && (
          <>
            <Row>
              <Col span={24}>
                <p>
                  <span className="goal-percentage">
                    {Math.round(
                      percentage(count + words, goalData?.targetWordCount)
                    )}
                    %
                  </span>&nbsp; <span>completed</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Progress
                showInfo={false}
                percent={Math.round(
                  percentage(count + words, goalData?.targetWordCount)
                )}
                strokeWidth={10}
                strokeColor={{ from: "#66D8BF", to: "#00BE95" }}
              />
            </Row>
            <Row>
              <Col span={24}>
                <Text type="secondary" className="goal-words">
                  {(count + words).toLocaleString()} of{" "}
                  {goalData?.targetWordCount.toLocaleString()} words
                </Text>
              </Col>
            </Row>
          </>
        )}
        {goalData && goalData.status === "completed" && (
          <>
            <Row></Row>
            <Row>
              <Col span={24}>
                <p>
                  <span className="goal-percentage">100%</span> &nbsp; <span>completed</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Text type="secondary" className="goal-words">
                  {(
                    goalData.preGoalWordCount +
                    goalData.currentDateWrittenWordCount
                  ).toLocaleString()}{" "}
                  of {goalData?.targetWordCount.toLocaleString()} words
                </Text>
              </Col>
            </Row>
            <Row>
              <Progress
                showInfo={false}
                percent={100}
                strokeWidth={10}
                strokeColor={{ from: "#66D8BF", to: "#00BE95" }}
              />
            </Row>
          </>
        )}
      </div>
    );
  });

  useEffect(() => {
    const initializeInter = setInterval(() => {
      const getIDBData = async () => {
        const data = await db.bookGoals.where("bookId").equals(bookId).first();

        if (
          data &&
          data.preGoalWordCount + data.currentDateWrittenWordCount >=
            data.targetWordCount
        ) {
          await completeGoalDeleteIDB(data.currentDateWrittenWordCount);
        }
      };

      getIDBData();
    }, 1000);

    return () => clearInterval(initializeInter);
  });

  const DailyWordCount = observer(() => {
    setTotalWordCount();
    useEffect(() => {
      updateTodayWordCount();

      if (goalData) {
        if (total >= goalData.wordsPerDay) {
          updateStreak("fire");
        }
      }
    }, [total]);

    return (
      <>
        <Row>
          <Col span={23}>
            <GoalDailyProgress
              total={total}
              streak={goalData?.streak}
              wordsPerDay={goalData?.wordsPerDay}
            />
          </Col>
          <Col span={1}>
            <Dropdown overlay={menu} placement="bottomRight">
              <Button
                type={ButtonType.GHOST}
                className="edit-goal-icon-btn"
                icon={<MoreSettingsIcon />}
              ></Button>
            </Dropdown>
          </Col>
        </Row>
      </>
    );
  });

  const handleGoalsSubmit = async (fieldsValue) => {
    if (!navigator.onLine) {
      const modal = Modal.warning({
        title: "You must be connected to the internet to create a goal.",
        closable: true,
        okButtonProps: {
          style: {
            display: "none",
          },
        },
        width: 550,
      });

      return modal;
    }

    if (editGoal) {
      deleteGoal();
    }
    const values = {
      ...fieldsValue,
      dueDateISO: isoWithTimeZone(fieldsValue.dueDate._d).slice(0, 10),
    };

    try {
      const startDate = new Date();

      const goalDataObj = {
        bookId: bookId,
        targetWordCount: values.targetWordCount,
        startDate: isoWithTimeZone(startDate).slice(0, 10),
        dueDate: values.dueDateISO,
        writeDays: values.writingDays, //values.writingDays
        writtenWordCount: parseInt(containerRef.current?.innerText),
      };

      setLoading(true);

      if (!editGoal) {
        const savedGoal = await AtticusClient.SetBookGoal(goalDataObj);
        const calculatedGoalResult = calculateGoal(
          goalDataObj.targetWordCount,
          goalDataObj.startDate,
          goalDataObj.dueDate,
          goalDataObj.writeDays,
          savedGoal.writtenWordCount
        );

        if (savedGoal) {
          const IDBGoal = {
            _id: savedGoal._id,
            bookId: savedGoal.bookId,
            preGoalWordCount: savedGoal.writtenWordCount,
            wordsPerDay: calculatedGoalResult.wordsPerDay,
            lastUpdatedWordsPerDay: isoWithTimeZone(startDate).slice(0, 10),
            currentDateWrittenWordCount: 0,
            currentDate: isoWithTimeZone(startDate).slice(0, 10),
            targetWordCount: savedGoal.targetWordCount,
            streak: "snow",
            status: "active",
            dueDate: savedGoal.dueDate,
            writeDays: savedGoal.writeDays,
          };

          const saveGoalIDB = await db.bookGoals.add(IDBGoal);
          getGoalData();
        }
      }

      if (editGoal) {
        const editGoalDataObj = {
          bookId: bookId,
          targetWordCount: values.targetWordCount,
          startDate: isoWithTimeZone(startDate).slice(0, 10),
          dueDate: values.dueDateISO,
          writeDays: values.writingDays,
          writtenWordCount: goalData?.preGoalWordCount,
        };

        const editedGoal = await AtticusClient.EditGoal(editGoalDataObj);
        const calculatedGoalResult = calculateGoal(
          editGoalDataObj.targetWordCount,
          editGoalDataObj.startDate,
          editGoalDataObj.dueDate,
          editGoalDataObj.writeDays,
          editedGoal.writtenWordCount
        );

        if (editedGoal && goalData) {
          const IDBGoal = {
            _id: editedGoal._id,
            bookId: editedGoal.bookId,
            preGoalWordCount: editedGoal.writtenWordCount,
            wordsPerDay: calculatedGoalResult.wordsPerDay,
            lastUpdatedWordsPerDay: isoWithTimeZone(startDate).slice(0, 10),
            currentDateWrittenWordCount: goalData.currentDateWrittenWordCount,
            currentDate: isoWithTimeZone(startDate).slice(0, 10),
            targetWordCount: editedGoal.targetWordCount,
            streak: "snow",
            status: "active",
            dueDate: editGoalDataObj.dueDate,
            writeDays: editedGoal.writeDays,
          };

          const saveGoalIDB = await db.bookGoals.add(IDBGoal);
          setEditGoal(false);
          getGoalData();
        }
      }
    } catch (e: any) {
      console.log(e);
    }

    setLoading(true);
  };

  const getEditGoal = () => {
    resetForm();
    setEditGoal(true);
  };

  const deleteGoal = async (forceResetForm = false) => {
    if (!navigator.onLine) {
      const modal = Modal.warning({
        title: "You must be connected to the internet to delete a goal.",
        closable: true,
        okButtonProps: {
          style: {
            display: "none",
          },
        },
        width: 550,
      });

      return modal;
    }

    if (goalData) {
      const deletedGoal = await AtticusClient.DeleteGoal(bookId, goalData._id);

      if (deletedGoal) {
        const deleteGoalIDB = await db.bookGoals
          .where("bookId")
          .equals(bookId)
          .delete();
        setCurrentGoal(false);

        if (forceResetForm) {
          resetForm();
        }
      }
    }
  };

  const resetForm = () => {
    form.resetFields();
  };

  const menu = (
    <Menu className="goals-setting-menu">
      <Menu.Item key="1" className="goals-menu-item">
        <p onClick={() => getEditGoal()}>Edit goal</p>
      </Menu.Item>
      <Menu.Item key="2" className="goals-menu-item">
        <p onClick={() => deleteGoal(true)}>Delete goal</p>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div style={{ display: "none" }}>
        <BookWCCompleted />
      </div>
      <div style={{ display: "none" }}>
        <DailyWordCount />
      </div>

      {!currentGoal && !editGoal && (
        <BookGoalForm
          form={form}
          handleGoalsSubmit={handleGoalsSubmit}
          containerRef={containerRef}
          loading={loading}
        ></BookGoalForm>
      )}

      {currentGoal && !editGoal && goalData && !loading && (
        <>
          <DailyWordCount />
          {goalData.status === "completed" ? (
            <div>
              <Row className="goal-complete-message">
                You have reached your goal!
              </Row>
            </div>
          ) : (
            <></>
          )}

          <Row justify="start">
            <Col span={22}>
              <h6 className="goals-target-title">Book target words</h6>
            </Col>
            <Col span={2}>
              {goalData.status === "completed" ? (
                <Button
                  className="new-goal-btn"
                  type={ButtonType.GHOST}
                  icon={<AddIcon />}
                  onClick={() => createNewGoal()}
                ></Button>
              ) : null}
            </Col>
          </Row>
          <BookWCCompleted />
        </>
      )}

      {editGoal && (
        <div>
          <Row justify="space-between" align="middle">
            <Col>
              <h4>Edit book goal</h4>
            </Col>
            <Col>
              <Button
                className="close-btn"
                type={ButtonType.GHOST}
                onClick={() => setEditGoal(false)}
                icon={<CloseOutlinedIcon />}
              ></Button>
            </Col>
          </Row>
          <BookGoalForm
            form={form}
            containerRef={containerRef}
            loading={loading}
            handleGoalsSubmit={handleGoalsSubmit}
            initialValues={{
              targetWordCount: goalData?.targetWordCount,
              dueDate: moment(goalData?.dueDate),
              writingDays: goalData?.writeDays,
            }}
          ></BookGoalForm>
        </div>
      )}
    </>
  );
};
