import React, { useEffect, useState } from "react";
import { CalloutModalStore } from "../../../../../store/CalloutModal";
import { observer } from "mobx-react";
import styled from "styled-components";
import { convertHexToRGBA } from "../../../../../utils/hexToRgba";
import { ColorPickerValue } from "antd-colorpicker";

interface PreviewTileProps {
  calloutStore: CalloutModalStore;
}

const PreviewTileElement = styled.div`
  background-color: ${(props) => props.fillColor};
  border: ${(props) =>
    props.border
      ? `${props.borderWidth}px ${props.borderStyle} ${props.borderColor}`
      : "none"};
  border-radius: ${(props) => `${props.borderRadius}px`};
`;

const PreviewTile: React.FC<PreviewTileProps> = observer(({ calloutStore }) => {
  const [backgroundColor, setBackgroundColor] = useState<ColorPickerValue>();
  const [borderColor, setBorderColor] = useState<ColorPickerValue>({});

  useEffect(() => {
    setBackgroundColor(
      convertHexToRGBA(
        calloutStore.background.fillColor,
        calloutStore.background.fillOpacity
      )
    );
    setBorderColor(
      convertHexToRGBA(
        calloutStore.border.borderColor,
        calloutStore.border.borderOpacity
      )
    );
  }, [
    calloutStore.background.fillColor,
    calloutStore.background.fillOpacity,
    calloutStore.border.borderColor,
    calloutStore.border.borderOpacity,
  ]);

  return (
    <div className="preview-div">
      <div className="preview-title">Preview</div>
      <div className='preview-tile-container'>
        <PreviewTileElement
          className='preview-tile'
          fillColor={
            calloutStore.background.fill ? backgroundColor : "transparent"
          }
          border={calloutStore.border.border}
          borderWidth={calloutStore.border.borderWidth}
          borderStyle={calloutStore.border.borderStyle}
          borderColor={borderColor}
          borderRadius={calloutStore.border.borderRadius}
        ></PreviewTileElement>
      </div>
    </div>
  );
});

export default PreviewTile;
