import React, { FC } from "react";
import { Tabs, TabsProps, TabPaneProps } from "antd";

import useRootStore from "../../../store/useRootStore";

interface TabItemProps extends Omit<TabPaneProps, "tab" | "icon"> {
  key: string;
  label: React.ReactNode;
}

export interface TabProps extends TabItemProps {
  count?: React.ReactNode;
}



interface AtticusTabsProps extends Omit<TabsProps, "type" | "items"> {
  variant: "primary-blue" | "primary-mint";
  items: TabProps[];
  hideCountBadge? : boolean;
}

const TabBadge = ({ children }) => {
  return (
    <span className="at-tabs-badge">
      {children}
    </span>
  );
};

export const AtticusTab: FC<AtticusTabsProps> = (props) => {
  const tabProps : AtticusTabsProps = {
    ...props,
    className: `at-tabs at-${props.variant} ${props.className || ""}`,
    items: props.items.map((tab) => ({
      ...tab,
      label: (
        <>
          <label>{tab.label}</label>
          {!props.hideCountBadge && <TabBadge>{tab.count}</TabBadge>}
        </>
      )
    }))
  };

  return (
    <Tabs {...tabProps} />
  );
};

