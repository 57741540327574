import { makeAutoObservable } from "mobx";
import { AtticusClient } from "../api/atticus.api";

export class PresetStore {
  userPresets: IPresetStore.IPresetBase[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setPresets = (presets: IPresetStore.IPresetBase[]): void => {
    this.userPresets = presets;
  };

  getPresetsFromDB = async (type: string): Promise<void> => {
    try {
      const presets = await AtticusClient.GetPresets(type);
      this.setPresets(presets);
    } catch (error) {
      console.error("Error fetching presets: ", error);
    }
  };

  createPreset = async (preset: IPresetStore.IPresetBase): Promise<boolean> => {
    try {
      await AtticusClient.CreatePreset(preset);
      await this.getPresetsFromDB(preset.type);
      return true;
    } catch (error) {
      console.error("Error creating preset: ", error);
      return false;
    }
  };

  deletePreset = async (presetId: string): Promise<boolean> => {
    try {
      await AtticusClient.DeletePreset(presetId);
      return true;
    } catch (error) {
      console.error("Error deleting preset: ", error);
      return false;
    }
  };
}

export default new PresetStore();
