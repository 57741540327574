import { getChapterAncestorVolume } from "../../../utils/chapter";
import { generateToc } from "../../../utils/toc";

const chapterNumber = (
  element: IChapterStore.TOCSummaryItem,
  hidden = false
) => {
  if (!element.chapterNumber) return "";
  const className = `chapter-num${hidden ? "-hidden" : ""}`;
  return `<span class="${className}">${element.chapterNumber}.</span>`;
};

const chapterSubtitle = (element: IChapterStore.TOCSummaryItem) => {
  if (!element.subtitle) return "";
  return `
    <li class="reset-padding">
      <a class="toc-subtitle-inner">
        ${chapterNumber(element, true)}
        <span>${element.subtitle}</span> 
      </a>
    </li>
  `;
};

const chapterSubheads = (element: IChapterStore.TOCSummaryItem) => {
  if (!element.subheads) return "";
  return element.subheads
    .map(
      (subheadItem, index) => `
        <li class="toc-subheading">
          <a href="${element.itemId}.xhtml#subhead-${index + 1}">
            <span>${(subheadItem as ISlateStore.SlateTextNode).text} </span>
          </a>
        </li>
      `
    )
    .join("\n");
};

const renderBlock = (element: IChapterStore.TOCSummaryItem): string => {
  if (!element.title) {
    return `
      <li class="toc-entry">
        <a href="${element.itemId}.xhtml"> 
          ${chapterNumber(element)} Chapter ${element.chapterNumber}
        </a>
      </li>      
    `;
  }

  return `
    <li class="toc-entry">
      <a href="${element.pointToChapterId || element.itemId}.xhtml"> 
        ${chapterNumber(element)} ${element.title}
      </a>
      ${chapterBlock([
        chapterSubtitle(element),
        chapterSubheads(element),
        chapterChildren(element),
      ])}
    </li>
  `;
};

const chapterChildren = (element: IChapterStore.TOCSummaryItem) => {
  if (!element.children) return "";
  return element.children.map((child) => renderBlock(child)).join("\n");
};

const chapterBlock = (content: string[]) => {
  const filteredContent = content.filter((output) => !!output);
  if (!filteredContent.length) return "";
  return `
    <ol class="toc-block">
      ${content.join("\n")}
    </ol>
  `;
};

export const parseVolumeToc = (
  book: IBookStore.ExpandedBook,
  chapter: IChapterStore.Chapter
): string => {
  const tocTitle = chapter.title || "Contents";
  const chapters = book.chapters?.concat(book.frontMatter);
  const tocItems = generateToc(
    book as IBookStore.ExpandedBook,
    chapters as IChapterStore.Chapter[],
    "ebook",
    chapter
  );
  const parentVolume = getChapterAncestorVolume(chapter._id, chapters || []);

  return `
    <div class="epub-toc-title-card">
      <h2>${tocTitle}</h2>
    </div>
    <nav>
      <ol class="toc-block">
        ${tocItems
          .filter((item) => item.parentItemId === parentVolume?._id)
          .map((item) => renderBlock(item))
          .join("\n")}
      </ol>
    </nav>
  `;
};
