import React, { ReactNode } from "react";


interface CardImageProps {
  img: string;
  icon: ReactNode;
}

export const CardImage: React.FC<CardImageProps> = ({ img, icon }) => (
  <div className="book-card-img-wrapper" style={{backgroundImage: img ? `url(${img})` : "none"}}>
    {!img && <span className="book-card-icon">{icon}</span>}
  </div>
);
