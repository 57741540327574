import React from "react";
import { Form, Row, Col, Checkbox } from "antd";
import { observer } from "mobx-react";
import { FontVariant } from "@surge-global-engineering/css-generator";

import { widthMarks, alignItems } from "../helpers/configs";
import { ThemeFieldFontStyle, ThemeFieldFontStyleMap } from "../../../../types/themeForm";
import { HeadingSize } from "../helpers/types";
import { AtticusSelect, AtticusSlider } from "../../../Shared/Form";
import { getFontFamilyName } from "../../../../utils/font";

import FontGalleryButton from "../../../Fonts/FontGalleryButton";

interface TitleSettingProps {
  title: string;
  prefix: string;
  fonts: IFontStore.FontItem[];
  availableFontStyles: ThemeFieldFontStyle[];
  headingSize: HeadingSize;
  active: boolean
}

const TitleSettings: React.FC<TitleSettingProps> = ({ active, title, prefix, fonts, availableFontStyles, headingSize, children }) => {
  return (
    <div className="theme-title-section">
      <Row>
        <Form.Item className="at-form-item theme-chapter-header-checkbox" name={`${prefix}Visible`} valuePropName="checked">
          <Checkbox>{title}</Checkbox>
        </Form.Item>
      </Row>
      <Row gutter={32} style={{ display: active ? "flex" : "none" }}>
        <Col span={8}>
          <Form.Item className="at-form-item" name={`${prefix}Font`} label="Font">
            <AtticusSelect
              placeholder={`Select ${title}`}
              selectOptions={fonts
                .filter(font => !font.availableForDropCap)
                .map(font => ({
                  classNames: getFontFamilyName(font._id, FontVariant.regular),
                  value: font._id,
                  label: font.family,
                }))
              }
              dropdownRender={(menu) => (
                <>
                    {menu}
                    <FontGalleryButton />
                </>
              )}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item className="at-form-item" name={`${prefix}Align`} label="Align">
            <AtticusSelect
              placeholder="Select"
              selectOptions={alignItems}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item className="at-form-item" name={`${prefix}Style`} label="Style">
            <AtticusSelect
              placeholder="Select"
              selectOptions={availableFontStyles.map(fontStyle => ({
                value: fontStyle,
                label: ThemeFieldFontStyleMap.get(fontStyle) || ""
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className="at-form-item" name={`${prefix}Size`} label="Size">
            <AtticusSlider marks={headingSize.labels} min={headingSize.min} max={headingSize.max} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className="at-form-item" name={`${prefix}Width`} label="Width percentage">
            <AtticusSlider className="at-slider-contained" marks={widthMarks} min={20} max={100} />
          </Form.Item>
        </Col>
        {children}
      </Row>
    </div>
  );
};

export default observer(TitleSettings);
