import { useEffect, useState, useRef } from "react";

import useInterval from "use-interval";

const useWebsocket = (url: string) => {
  const PingInterval = 5000;
  const MaxReconnectInterval = 10000;
  const ReconnectInterval = useRef(0);
  const SocketUrl = useRef("");
  const _ismounted = useRef(false);

  const [socket, setSocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    SocketUrl.current = url;
    if (socket) {
      socket.close();
    } else {
      connect();
    }
  }, [url]);

  useEffect(() => {
    _ismounted.current = true;
    return () => {
      _ismounted.current = false;
      if (socket) socket.close();
    };
  }, []);

  useInterval(() => {
    if (socket?.readyState === WebSocket.OPEN) {
      socket?.send(JSON.stringify({
        action: "ping", 
      }));
    }
  }, PingInterval);

  const connect = () => {
    const ws = new WebSocket(SocketUrl.current);
    let timeoutFunction;

    ws.onopen = () => {
      console.log("WebSocket Connected");
      setSocket(ws);
      ReconnectInterval.current = 0;
      clearInterval(timeoutFunction);
    };

    ws.onclose = () => {
      if (_ismounted.current) {
        ReconnectInterval.current += 1000;
        console.log(`WebSocket disconnected, attempting to reconnect in ${Math.min(MaxReconnectInterval / 1000, ReconnectInterval.current / 1000)} seconds`);
        timeoutFunction = setTimeout(checkSocket, Math.min(MaxReconnectInterval, ReconnectInterval.current));
      } else {
        console.log("WebSocket connection closed");
      }
    };

    ws.onerror = () => {
      console.log(`WebSocket failed, status: ${ws.readyState}`);
    };
  };

  const checkSocket = () => {
    if (!socket || socket.readyState === WebSocket.CLOSED) connect();
  };

  return socket;
};

export default useWebsocket;