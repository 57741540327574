import { find } from "lodash";

/**
 * Get the chapter id for the startPage config
 * @param chapters
 * @param startPage
 * @returns chapter id if it"s found or undefined which will set the chapter id to default
 */
export const getStartPageId = (
  chapters: IChapterStore.Chapter[],
  startPage: string
): string | undefined => {
  let chapter = find(chapters, ["_id", startPage]);
  if (chapter) {
    return chapter._id;
  }

  /* to support the old books which has a frontmatter chapter title set as the startPage */
  chapter = find(chapters, ["title", startPage.replace(/-/g, " ")]);

  return chapter?._id;
};

/**
 * 
 * @param previousType previous block type
 * @param currentType current block type
 * @returns boolean value of if the paragraph is after a subheading or not
 */

export const isParaAfterSubheading = (
  previousType: ISlateStore.SlateBaseBlockTypes,
  currentType: ISlateStore.SlateBaseBlockTypes
) : boolean => {
  if (previousType === "h2" || previousType === "h3" || previousType === "h4" || previousType === "h5" || previousType === "h6") {
    if (currentType === "p") {
      return true;
    }
  }
  return false;
};
