import Compressor from "compressorjs";

const imageExtensions = [
  "apng",
  "avif",
  "gif",
  "jpg",
  "jpeg",
  "jgif",
  "pjpeg",
  "pjp",
  "png",
  "svg",
  "webp",
];

const s3FolderNameToExtension = {
  "images" : imageExtensions,
  "docxs": ["docx"],
  "epubs": ["epub"],
  "import-json": ["json"],
};

const defaultFolder = "files";
export const compressedImageFolder = "compressed-images";
export const hasCompressedImageSFX = "_ce";

export const getUploadPathByExtension = (extension: string | null): string => {
  if(!extension) return defaultFolder;
  
  for(const [folderName, extensions] of Object.entries(s3FolderNameToExtension)){
    if(extensions.includes(extension)) return folderName;
  }

  return defaultFolder;
};


export const compressFile = (inputFile: File | Blob, outputQuality: number): Promise<File|Blob> => {
  return new Promise((resolve, reject) => {
    new Compressor(inputFile, {
      quality: outputQuality,
      success: (compressedFile) => resolve(compressedFile),
      error: (error) => reject(error)
    });
  });
};