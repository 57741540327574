import React, { useState } from "react";
import { Result, Button, Card} from "antd";


import { db } from "../../db/bookDb";
import { AtticusClient } from "../../api/atticus.api";

import { GetBookFromDB } from "../../utils/offline.book.helpers";
import { Book } from "../../types/book";

interface BackupProps{
    onComplete: () => void;
}

const OfflineBackup = ({onComplete}: BackupProps) => {
    const [numBooks, setNumBooks ] = useState<number>(0);
    const [curIndex, setCurIndex] = useState<number>(0);

    const downloadFile = async (fileName, snapshotStr) => {
        const blob = new Blob([snapshotStr],{type: "application/json" });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

  
    const downloadBackup = async () => {
        const offlineBooks = await db.books.toArray();
        const completeBooks: Book[] = [];
        setNumBooks(offlineBooks.length);
    
        let cur = 0;
        const errors = 0;
    
        for (const book of offlineBooks) {
          const fullBook = await GetBookFromDB(book._id, { chapterMeta: true, chapterBodies: true });
    
          if (fullBook) {
            delete fullBook["frontMatter"];
            completeBooks.push(fullBook);
    
            // try {
            //   await AtticusClient.SaveSnapshot(book._id, JSON.stringify(fullBook));
            // } catch (e: any) {
            //   console.log(e.message);
            //   errors += 1;
            // }
            cur += 1;
            setCurIndex(cur);
          }
        }
    
        const fileName = `atticus-backup-${new Date().toISOString()}`.replace(/ /ig, "-");
        await downloadFile(fileName, JSON.stringify(completeBooks));
    
        setNumBooks(0);
        onComplete();
    };
    
    
    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }} 
        >
            <Card style={{
                maxWidth: 800,
                width: "100%",
                margin: "auto"
            }}>
                <Result
                    status="info"
                    title="Your session has expired."
                    subTitle="Click the button below to backup your work and log out. Then just log in again to start a new session and get back to writing."
                    extra={numBooks < 1 ?  <Button size="large" type="primary" key="d_logout" onClick={downloadBackup}>Download and Logout</Button> : <Button size="large" type="primary" key="d_logout" disabled>{`${curIndex} of ${numBooks} books ready to Download`}</Button>}
                />
            </Card>
        </div>
    );
};

export default OfflineBackup;