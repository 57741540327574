import React from "react";
import { DatePicker, DatePickerProps} from "antd";
import { CalenderIcon } from "../../../content/icons";

interface AtticusDatePickerProps extends Omit<DatePickerProps, "type"> {
  type?: "primary" | "secondary";
}

export const AtticusDatePicker: React.FC<AtticusDatePickerProps> = (props) => {
  return (
    <DatePicker {
      ...{
        ...props,
        className: `at-form-input ${props.type}`,
        popupClassName: "at-date-picker",
        suffixIcon: <CalenderIcon/>
      }}
    />
  );
};
