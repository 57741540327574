import {
  someNode,
  Value,
  TEditor,
  TElement,
  setElements,
} from "@udecode/plate-core";

import { ELEMENT_DEFAULT, ELEMENT_PARAGRAPH, unsetNodes, wrapNodes } from "@udecode/plate";
import { ELEMENT_TEXT_MESSAGE, ELEMENT_TEXT_MESSAGES_GROUP } from "../../";

export const toggleTextMessage = <V extends Value>(editor: TEditor<V>) => {
  const validNodeTypes = [
    ELEMENT_PARAGRAPH,
    ELEMENT_DEFAULT,
  ];

  const canConvertToTextMessage= (node: TElement): boolean => {
    return validNodeTypes.includes(node.type);
  };
  const isTextMessage= (node: TElement): boolean => {
    return node.type === ELEMENT_TEXT_MESSAGES_GROUP;
  };

  //toggle or convert based on the selected node
  if (someNode(editor, { match: canConvertToTextMessage })) {
    setElements(editor, {
      type: ELEMENT_TEXT_MESSAGE,
      messageType: "received",
      inserting: true,
    });
    wrapNodes<TElement>(editor, {
      type: ELEMENT_TEXT_MESSAGES_GROUP,
      style: "ios-ios",
      children: [],
    });
  } else if (someNode(editor, { match: isTextMessage })) {
    unsetNodes(editor, ["messageType", "nextMessageType"]);
    setElements(editor, {
      type: ELEMENT_DEFAULT,
    });
  }
};
