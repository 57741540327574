import React, { FunctionComponent, ReactNode, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Redirect, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
 
import { Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import useRootStore from "../../store/useRootStore";

import { useOnlineStatus } from "../../utils/isOffline";

import { getAppLevelFontFaceCss } from "../../utils/font";

// sync
import { syncData } from "../../utils/sync";

import Header, { HeaderProps } from "../header";
import PDFExporter from "../../components/Shared/PDFExporter/PDFExporter";


const WithAuthComponent: React.FC = ({ children }) => {
    const { loadUserProfile } = useRootStore().authStore;
    const { setSyncing, syncing } = useRootStore().bookStore;
    const { loadBooks } = useRootStore().shelfStore;
    const { loadThemes, saveDevicePreview, getAllDevices } = useRootStore().themeStore;
    const { getSMProfilesFromDB } = useRootStore().socialProfileStore;
    const { loadTemplates } = useRootStore().chapterStore;
    const { loadUserFavouriteFonts, userFavouriteFonts, googleFonts } = useRootStore().fontStore;
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const isOnline = useOnlineStatus();
    const { pathname } = useLocation();

    const [visible, setVisible] = useState(false);
    const [fontFaceCss, setFontFaceCss] = useState("");

    const sync = async () => {
        try {
            setSyncing(true);
            if (isOnline) {
                await syncData();
            }
            getSMProfilesFromDB(); // load user's social media profiles in the app state
            loadUserProfile();
            loadBooks();
            loadThemes(); // saves all the themes at the start
            loadTemplates();
            saveDevicePreview(); // saved the book previews at the start
            getAllDevices();
            await loadUserFavouriteFonts();
            setFontsLoaded(true);
        } catch (error) {
            console.error("Error during sync:", error);
            setFontsLoaded(false);
        } finally {
            setSyncing(false);
            setVisible(false);
        }
    };

    const mode: HeaderProps["mode"] = useMemo(() => {
        if (pathname.indexOf("chapter-template") === 1) {
            return "chapter-template";
        } else if (pathname.indexOf("books") === 1) {
            return "editor";
        } else {
            return "home";
        }
    }, [pathname]);

    useEffect(() => {
        if (isOnline) {
            sync();
        }
    }, [isOnline]);

    useEffect(() => {
        if (fontsLoaded) {
            setFontFaceCss(
                getAppLevelFontFaceCss([
                    ...userFavouriteFonts,
                    ...googleFonts
                ])
            );
        }
    }, [userFavouriteFonts, googleFonts, fontsLoaded]);

    return (
        <div>
            <style>{fontFaceCss}</style>
            <Header mode={mode} />
            <div className="main">
                {children}
            </div>
            <PDFExporter />
            <Modal
                open={visible}
                // onCancel={() => toggle(false)}
                footer={null}
                width={300}
                title={<h2 className="section-heading">Syncing updates</h2>}
                bodyStyle={{
                    backgroundColor: "white"
                }}
                closeIcon={null}
                closable={false}
            >
                <div style={{
                    maxWidth: 300,
                    width: 300,
                    margin: "auto",
                    alignItems: "center"
                }}>
                    <div className="sync-pop-up">
                        <LoadingOutlined style={{ fontSize: 60 }} />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const LayoutWithAuth : React.FC = (props) => {
    const { token } = useRootStore().authStore;

    useEffect(() => {
      const handleInvalidToken = e => {
        // check if auth token does not have value
        if (e.key === "atticus-auth-token" && !e.newValue) {
          // reload page to redirect
          window.location.reload();
        }
      };
      // apply listener for storage changes
      window.addEventListener("storage", handleInvalidToken);
      return () => {
        // remove local storage change listener
        window.removeEventListener("storage", handleInvalidToken);
      };
    }, []);

    return token ? <WithAuthComponent {...props} /> : <Redirect to="/auth/sign-out"/>;
  };
  

const LayoutWithAuthHOC = observer(LayoutWithAuth);

export default LayoutWithAuthHOC;
