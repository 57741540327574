import React, { useState } from "react";
import { Layout, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";

const {  Content, Sider } = Layout;

interface EditorLayoutProps {
  children: React.ReactNode
}

const Right = ({ children }: EditorLayoutProps) => (
  <Sider
    className="_right"
    theme="light"
    breakpoint="lg"
    collapsedWidth="0"
    width={500}
  >
    <div className="previewer">
      {children}
    </div>
  </Sider>
);

  const Left = ({ children }: EditorLayoutProps) => {
    const [collapsed, setCollapsed ] = useState(false);
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };

    const onBreakpoint = (broken) =>{
      if(broken){
        if(!collapsed){
          setCollapsed(true);
        }
      }else{
        if(collapsed){
          setCollapsed(false);
        }
      }
    };

    return (
      <>
        <Sider
          collapsible
          collapsed={collapsed}
          className="_left"
          breakpoint="xl"
          collapsedWidth="0"
          theme="light"
          width={280}
          trigger={null}
          onBreakpoint={(broken) => onBreakpoint(broken)}
        >
        {children}
        </Sider>
        {collapsed?
          <>
            <Drawer 
              closable={false}  
              bodyStyle={{ color: "black"}}
              width={"300px"}
              maskClosable={true} 
              placement="left" 
              onClose={onClose} open={open}>
                {children}
            </Drawer>
            <div className="left-drawer-menu">
              <MenuOutlined onClick={showDrawer}/>
            </div>
          </>
        : null} 
    </>
  );
};

const Wrapper = ({ children }: EditorLayoutProps) => (
  <Layout className="atticus-editor-layout">
    {children}
  </Layout>
);

const Center = ({ children }: EditorLayoutProps) => (
  <Layout className="_center">
    <Content>
      {children}
    </Content>
  </Layout>
);

const RHSBar = ({ children }: EditorLayoutProps) => (
  <Sider
    collapsible={false}
    className='_right'
    breakpoint='xl'
    collapsedWidth={48}
    theme='light'
    width={48}
    trigger={null}
  >
    {children}
  </Sider>
);

export default {
  Center,
  Wrapper,
  Left,
  Right,
  RHSBar,
};
