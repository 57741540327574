import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import useRootStore from "../../../../../store/useRootStore";

import { LoadingOutlined } from "@ant-design/icons";
import { CalloutModalStore } from "../../../../../store/CalloutModal";
import { Col, Popconfirm, Row } from "antd";
import { DEFAULT_PRESET_ID, PRESET_TYPE } from "./constant";
import { Button } from "../../../../Shared/Buttons";
import { AtticusSelect } from "../../../../Shared/Form";
import { DeleteIcon, DeleteIconActive } from "../../../../../content/icons";

interface ListAllCalloutPresetsProps {
  calloutStore: CalloutModalStore;
}

const ListAllCalloutPresets: React.FC<ListAllCalloutPresetsProps> = observer(
  ({ calloutStore }) => {
    const { userPresets, deletePreset, getPresetsFromDB } =
      useRootStore().presetStore;
    const [isDisabled, setIsDisabled] = useState(true);
    const [presetId, setPresetId] = useState<string>(DEFAULT_PRESET_ID);
    const [deleting, setDeleting] = useState(false);
    const [presetIdToDelete, setPresetIdToDelete] = useState<string | null>(
      null
    );

    useEffect(() => {
      getPresetsFromDB(PRESET_TYPE);
    }, []);

    const setPresetValuesToModalProperties = (preset) => {
      calloutStore.setValues(preset.properties);
      setIsDisabled(true);
    };

    const onDeletePreset = async () => {
      if (presetIdToDelete) {
        setDeleting(true);
        await deletePreset(presetIdToDelete);
        await getPresetsFromDB(PRESET_TYPE);
        setDeleting(false);

        // reset presetId
        setPresetId(DEFAULT_PRESET_ID);
        setIsDisabled(true);
      }
    };

    const onClickDelete = (presetId: string) => {
      setPresetIdToDelete(presetId);
    };

    return (
      <div className='preset-select-div'>
        <Row gutter={12}>
          <Col span={22}>
            <AtticusSelect
              size='large'
              className={`preset-select ${
                presetId === DEFAULT_PRESET_ID ? "preset-select-gray" : ""
              }`}
              placeholder={DEFAULT_PRESET_ID}
              value={presetId}
              onChange={(value) => {
                setPresetId(value);
                setPresetValuesToModalProperties(
                  userPresets.find((preset) => preset._id === value)
                );
                setIsDisabled(false);
              }}
              selectOptions={userPresets.map((preset) => ({
                label: preset.properties.presetName,
                value: preset._id,
              }))}
            />
          </Col>
          <Col span={2}>
            <Popconfirm
              title='Are you sure you want to delete this preset?'
              disabled={isDisabled}
              onConfirm={onDeletePreset}
              okText='Delete'
              cancelText='Cancel'
              okButtonProps={{
                danger: true,
              }}
            >
              {deleting && presetIdToDelete === presetId ? (
                <Button type='at-ghost' icon={<LoadingOutlined />} danger />
              ) : (
                <div className='delete-btn-div'>
                  <Button
                    type='at-ghost'
                    disabled={isDisabled}
                    icon={
                      isDisabled ? (
                        <DeleteIcon />
                      ) : (
                        <DeleteIconActive size={18} />
                      )
                    }
                    danger
                    onClick={() => onClickDelete(presetId)}
                  />
                </div>
              )}
            </Popconfirm>
          </Col>
        </Row>
      </div>
    );
  }
);

export default ListAllCalloutPresets;
