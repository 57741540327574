import React from "react";
import { useEventPlateId, usePlateEditorState } from "@udecode/plate-core";
import { Button, Popconfirm } from "antd";
import { PageBreakIcon } from "../../../partials/Icon";
import { removePageBreak } from "../transforms/removePageBreak";

import "./styles.scss";
import { DeleteIcon } from "../../../../../content/icons";

export const PageBreakComponent = ({ children, element, id }) => {
  const editor = usePlateEditorState(useEventPlateId(id));

  return (
    <div className="slate-page-break">
      <div></div>
      <div contentEditable={false} className="slate-page-break-icon-cover">
        <span>
          <PageBreakIcon size={20} />
        </span>
      </div>
      {children}
      <div>
        <Popconfirm
          title="Are you sure want to delete this Page Break?"
          onConfirm={() => {
            removePageBreak(element, editor);
          }}
          okButtonProps={{
            danger: true,
          }}
          okText="Delete"
          cancelText="Cancel"
        >
          <Button>
            <DeleteIcon />
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};
