import React from "react";
import { Radio, Form, Col} from "antd";
import { observer } from "mobx-react";

import { TitleSettings, ImageSettings } from "./partial";
import { getAvailableFontsForDropdown, getAvailableHeaderStylesForFont } from "../../../utils/font";
import { chapterNoSize, chapterSubtitleSize, chapterTitleSize } from "./helpers/configs";
import useRootStore from "../../../store/useRootStore";

interface ChapterHeadingProps {
  formFields: IThemeStore.ThemeFields;
  onValuesChange: (fields: Partial<IThemeStore.ThemeFields>) => void;
}

const ChapterHeading = ({
  formFields,
  onValuesChange,
}: ChapterHeadingProps): JSX.Element => {

  const handleImageUpdate = (imageUrl: string) => {
    onValuesChange({ image: imageUrl });
  };

  const { userFavouriteFonts } = useRootStore().fontStore;

  return (
    <>
      <TitleSettings
        active={Boolean(formFields.chpNumVisible)}
        title="Chapter number"
        prefix="chpNum"
        fonts={getAvailableFontsForDropdown(userFavouriteFonts)}
        availableFontStyles={getAvailableHeaderStylesForFont(
          userFavouriteFonts,
          formFields?.chpNumFont
        )}
        headingSize={chapterNoSize}
      >
        <Col span={24}>
          <Form.Item
            name="numberview"
            label="Chapter number view"
            className="a-radio-btns at-form-item"
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="number">1</Radio.Button>
              <Radio.Button value="chapter+number">Chapter 1</Radio.Button>
              <Radio.Button value="word">One</Radio.Button>
              <Radio.Button value="chapter+word">Chapter One</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </TitleSettings>
      <TitleSettings
        active={Boolean(formFields.chpTitleVisible)}
        title="Chapter title"
        prefix="chpTitle"
        fonts={getAvailableFontsForDropdown(userFavouriteFonts)}
        availableFontStyles={getAvailableHeaderStylesForFont(
          userFavouriteFonts,
          formFields?.chpTitleFont
        )}
        headingSize={chapterTitleSize}
      />
      <TitleSettings
        active={Boolean(formFields.chpSubtitleVisible)}
        title="Chapter subtitle"
        prefix="chpSubtitle"
        fonts={getAvailableFontsForDropdown(userFavouriteFonts)}
        availableFontStyles={getAvailableHeaderStylesForFont(
          userFavouriteFonts,
          formFields?.chpSubtitleFont
        )}
        headingSize={chapterSubtitleSize}
      />
      <ImageSettings 
        image={formFields.image}
        active={Boolean(formFields.imgVisible)}
        showImage={Boolean(!formFields.individualChapterImage)}
        showImageConfigs={Boolean(formFields.individualChapterImage || formFields.image)}
        showBackgroundImageConfigs={Boolean(formFields.imgPlacement === "background-image")}
        onUpdate={handleImageUpdate}
      />
    </>
  );
};

export default observer(ChapterHeading);
