import React, { FunctionComponent, useEffect, useState, useMemo } from "react";

import { AtticusClient } from "../../api/atticus.api";
import {
  ImageCategory,
  Image,
  ImageDeleteFrom,
} from "../../types/image-gallery";
import { Modal, Dialog } from "../Shared/Modal";
import { AtticusTab, TabProps } from "../Shared/Tabs";
import { Button, AtticusButtonProps, ButtonType } from "../Shared/Buttons/Button";
import { GalleryTabPane } from "./GalleryTabPane";
import { getAtticusImages } from "../../utils/ornamental-breaks";
import { EmptyShelf } from "../Shared/Empty";

interface ImageGalleryProps {
  handleImageUrl: (url: string) => void;
  ImageGalleryButtonText?: string;
  renderButtonProps?: AtticusButtonProps
}

const ImageGallery: FunctionComponent<ImageGalleryProps> = (
  props: ImageGalleryProps
) => {
  const [showImageGallery, setShowImageGallery] = useState<boolean>(false);
  const [categorizedImages, setCategorizedImages] = useState<
    Map<ImageCategory, Image[]>
  >(new Map([]));

  useEffect(() => {
    getImages();
  }, []);

  const getImages = async () => {
    const userProfile = await AtticusClient.GetProfile();
    const userImages = (await AtticusClient.GetGallery(
      userProfile._id
    )) as Image[];
    const atticusImages = getAtticusImages(36, userProfile._id);
    categorizeAndSetUserImages([...userImages, ...atticusImages]);
  };

  const categorizeAndSetUserImages = (images: Image[]): void => {
    const allImages: Image[] = [];
    const contentImages: Image[] = [];
    const themeImages: Image[] = [];
    const coverImages: Image[] = [];
    const atticusImages: Image[] = [];

    allImages.push(...images);
    images.map((image) => {
      if (
        image.folderName.includes("theme") ||
        image.folderName.includes("ornamental")
      ) {
        themeImages.push(image);
        return;
      }
      if (image.folderName.includes("cover")) {
        coverImages.push(image);
        return;
      } if (image.folderName === "atticus-images") {
        atticusImages.push(image);
        return;
      }
      contentImages.push(image);
    });
    const categorizedImageArray: [ImageCategory, Image[]][] = [
      ["all", allImages],
      ["content", contentImages],
      ["theme", themeImages],
      ["cover", coverImages],
      ["atticus-images", atticusImages]
    ];
    setCategorizedImages(new Map(categorizedImageArray));
  };

  const handleSelectImage = (imageLink: string) => {
    props.handleImageUrl(imageLink);
    setShowImageGallery(false);
  };

  const handleDeleteImage = async (
    event: any,
    image: Image,
    deleteFrom: ImageDeleteFrom
  ) => {
    event.domEvent.preventDefault();
    event.domEvent.stopPropagation();

    const deleteImage = async () => {
      const deleteImageData = {
        _imgId: image._imgId,
        folder: image.folderName,
        userId: image.userId,
      };

      try {
        await AtticusClient.DeleteImgInGallery(deleteImageData, deleteFrom);
        await getImages();
      } catch (error) {
        console.error(error);
      }
    };

    return Dialog({
      open: false,
      title: "Delete Image",
      content: `Are you sure you want to delete '${image._imgId}'?`,
      leftBtn: {
        children: "No",
      },
      rightBtn: {
        danger: true,
        onClick: async () => await deleteImage(),
        children: "Yes",
      },
    });
  };

  const renderGallery = (images: Image[]) => images.length > 0 ? (
    <GalleryTabPane
      images={images}
      handleSelectImage={handleSelectImage}
      handleDeleteImage={handleDeleteImage}
    />
  ) : <EmptyShelf title="No beautiful pictures added for your books yet!" />;

  const renderTabPanes = (images: Map<ImageCategory, Image[]>) => {
    const allImages = images.get("all") || [];
    const allImageCount = allImages.length;
    const contentImages = images.get("content") || [];
    const contentImageCount = contentImages.length;
    const themeImages = images.get("theme") || [];
    const themeImageCount = themeImages.length;
    const coverImages = images.get("cover") || [];
    const coverImageCount = coverImages.length;
    const atticusImages = images.get("atticus-images") || [];
    const atticusImageCount = atticusImages.length;

    const tabItems: TabProps[] = [
      {
        key: "all",
        label: "All",
        count: allImageCount,
        children: renderGallery(allImages),
      },
      {
        key: "content",
        label: "Content",
        count: contentImageCount,
        children: renderGallery(contentImages),
      },
      {
        key: "theme",
        label: "Themes",
        count: themeImageCount,
        children: renderGallery(themeImages),
      },
      {
        key: "cover",
        label: "Covers",
        count: coverImageCount,
        children: renderGallery(coverImages),
      },
      {
        key: "atticus-images",
        label: "Atticus images",
        count: atticusImageCount,
        children: renderGallery(atticusImages),
      },
    ];
    return tabItems;
  };

  const tabItems = useMemo(() => {
    return renderTabPanes(categorizedImages);
  }, [categorizedImages]);

  return (
    <>
      <Button
        {
        ...{
          type: ButtonType.PRIMARY,
          ...props.renderButtonProps,
          onClick: () => setShowImageGallery(true)
        }
        }
      >
        {props.ImageGalleryButtonText || "My image gallery"}
      </Button>
      <Modal
        open={showImageGallery}
        title="Image gallery"
        onCancel={() => setShowImageGallery(false)}
        centered={true}
        width={"85vw"}
        bodyStyle={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <AtticusTab
          variant="primary-blue"
          defaultActiveKey="book"
          items={tabItems}
        />
      </Modal>
    </>
  );
};

export default ImageGallery;
