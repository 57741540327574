/* eslint-disable react/display-name */

import React, { useEffect, useMemo, useState } from "react";
import { find } from "lodash";
import { observer } from "mobx-react-lite";
import parse from "html-react-parser"; 
import { getThemeThumbnailCss } from "@surge-global-engineering/css-generator";

import useRootStore from "../../store/useRootStore";
import { useSideBarActions } from "../../utils/hooks/useSideBarActions";
import { parseChapterHeader } from "../../press/exportEpub/parsers/chapterHeader";
import { SideBarChapterItem } from "../../types/sidebar";


interface ThemeThumbnailProps {
  theme: IThemeStore.Theme;
  onClick: () => void
}

const bodyContentP1 = "Once upon a time, there was a clumsy elephant named Ellie. Ellie was always tripping over her own big feet. One day, Ellie was strolling through the jungle, daydreaming about a giant carrot she had seen in her dreams. She was so lost in thought that she didn't notice the huge mud puddle in front of her. With a loud squelch, Ellie's feet got stuck in the mud, and she went tumbling down, rolling over like a big, wet ball. Her trunk flailed wildly as she tried to grab onto something to stop herself.";

const bodyContentP2 = "Finally, She came to a stop, covered head to toe in mud. She just laughed, realizing that sometimes it's better to just go with the flow of life, even if it means rolling in the mud.";

const thumbnailPrefix = "thumbnail";

const ThemeThumbnail : React.FC<ThemeThumbnailProps> = observer(({ theme, onClick, children }): JSX.Element => {
  const { chapter } = useRootStore().bookStore;
  const { sideBarItems } = useSideBarActions();
  const [chapterNumber, setChapterNumber] = useState(1);
  const { _id, configuration, title, subtitle, image} = chapter;
  const {showChapterImage = true} = configuration || {};
  const { properties: styleProps } = theme;

  const headerElementHtml = useMemo(() => {

    const shouldRenderImage = showChapterImage && styleProps?.titleCard?.image;
    const imageToRender = styleProps?.individualChapterImage ? image : styleProps?.image?.url;

    const headerElements: IChapterStore.ChapterHeaderElements = {
      title,
      subtitle,
      number: chapterNumber,
      image: shouldRenderImage ? imageToRender : ""
    };

    const isLightText = styleProps.image.placement === "background-image" && styleProps.image.headerTextColor === "light";
  
    return parseChapterHeader({headerElements, styleProps, classPrefix: thumbnailPrefix, isInverted: isLightText});
  }, [styleProps, chapterNumber, chapter]);

  const styles = useMemo(() => {
    return getThemeThumbnailCss(theme, thumbnailPrefix);
  }, [theme]);

  // change chapter number if sidebar items or chapter changes
  useEffect(() => {
    // filter sidebar items based on type 'chapter' and see the said chapter has number
    const chp =
      find(
        sideBarItems.filter(chptr => chptr.type === "chapter" && Boolean(chptr.number)),
        { id: _id }
      ) as SideBarChapterItem;

    // apply chapter number or set default number; 1
    setChapterNumber(chp?.number ? chp.number : 1);
  }, [sideBarItems, _id]);

  return (
    <>
      <div
        className="theme-thumbnail theme-card-container"
        onClick={onClick}
      >
        <style>{styles}</style>
        <div className={`thumbnail-header ${theme?._id}`}>
          <div></div>
          {parse(headerElementHtml)}
        </div>
        <div className={`thumbnail-body ${theme?._id}`}>
          <p>{bodyContentP1}</p>
          <p>{bodyContentP2}</p>
        </div>
        {children}
      </div>
    </>
  );
});

export default ThemeThumbnail;
