import { someNode, Value, TEditor, TElement } from "@udecode/plate-core";

import { unwrapNodes, wrapNodes } from "@udecode/plate";
import { ELEMENT_CALLOUTBOX } from "..";
import { CalloutBackground, CalloutBorder } from "../../../../../types/slate";

export const toggleCalloutBoxNodes = <V extends Value>(
  editor: TEditor<V>,
  background: CalloutBackground,
  border: CalloutBorder
) => {
  /**
   * if selected node's type is valid, convet the node to a callout node
   * if selected node is already having a calloutbox, toggle it back to default
   * */
  if (!someNode(editor, { match: { type: ELEMENT_CALLOUTBOX } })) {
    wrapNodes<TElement>(editor, {
      type: ELEMENT_CALLOUTBOX,
      background,
      border,
      children: [],
    });
  } else {
    unwrapNodes(editor, { match: { type: ELEMENT_CALLOUTBOX } });
  }
};
