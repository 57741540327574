import React from "react";
import { observer } from "mobx-react";
import useRootStore from "../../../../../store/useRootStore";
import { Col, Progress, Row } from "antd";
import { FireIcon, SnowFlakeIcon } from "../../../../Plate/partials";
import { HabitTools } from "../Header/Tools";

const WordCounter: React.FC = observer(() => {
  const { writingHabitState } = useRootStore().writingHabitStore;

  return (
    writingHabitState && (
      <div>
        <div className="writing-habbit-word-counter-wrapper">
          <Row>
            <Col span={23}>
              <Row gutter={8} justify="space-around" align="middle">
                <Col span={8} className="gutter-row">
                  <Progress
                    className="goals-progress"
                    format={(percent) => (
                      <div className="goals-progress-percentage">
                        {percent}%
                      </div>
                    )}
                    width={85}
                    type="dashboard"
                    strokeColor={{ "0%": "#66D8BF", "100%": "#00BE95" }}
                    gapDegree={5}
                    percent={Math.round(
                      ((writingHabitState.writtenCount +
                        writingHabitState.preBookWordCount) /
                        writingHabitState.goalCount) *
                        100
                    )}
                    strokeWidth={13}
                  />
                </Col>
                <Col span={16} className="gutter-row">
                  <Row justify="start" align="middle">
                    <div className="total-words">
                      {writingHabitState.writtenCount +
                        writingHabitState.preBookWordCount}
                    </div>

                    <div className="words-per-day">
                      /{writingHabitState.goalCount}
                    </div>

                    {writingHabitState.snowFireStreak === "snow" ? (
                      <SnowFlakeIcon />
                    ) : (
                      <FireIcon />
                    )}
                  </Row>
                  <Row>
                    <Col className="goals-word-count-text">
                      Daily writing goal
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={1}>
              <HabitTools />
            </Col>
          </Row>
        </div>
      </div>
    )
  );
});

export { WordCounter };
