
import React from "react";
import { Form, Input, Button, Row, Col, Avatar } from "antd";

const AboutAuthorEditor: React.FC = () => {
  return (
    <div className="atticus-editor-container">
      <div className="editor-area">
        <div
          style={{
            margin: "auto",
            maxWidth: 1080,
            width: "100%",
          }}
        >
          <div style={{ height: "1em" }}></div>
          <Form layout="vertical">
            <h2>About author</h2>
            <div style={{ marginTop: "1rem" }}>
              <Form.Item>
                <Avatar
                  size={160}
                  style={{ marginRight: "1rem" }}
                  shape="square"
                >
                  N
                </Avatar>
                <Button type="default">Upload image</Button>
              </Form.Item>
              <Form.Item label="Biography">
                <Input.TextArea rows={6} />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Facebook">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Twitter">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Instagram">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary">Save</Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AboutAuthorEditor;