import {
  PlateEditor,
  Value,
  WithPlatePlugin,
  ImagePlugin,
} from "@udecode/plate";

/**
 * Allows for pasting images from clipboard.
 * Not yet: dragging and dropping images, selecting them through a file system dialog.
 */
export const withImage = <
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>
>(
  editor: E,
  plugin: WithPlatePlugin<ImagePlugin, V, E>
) => {
  const { isVoid, isInline } = editor;
  editor.isVoid = (element) =>
    element.type === "image" ? true : isVoid(element);
  editor.isInline = (element) =>
    element.type === "image" ? false : isInline(element);

  return editor;
};
