import React, { FunctionComponent, useEffect, useState } from "react";
import { Formik, FormikErrors } from "formik";
import { Steps } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import { BoxsetBookSelection, BoxsetDetailsForm } from "./";
import { Modal } from "../../Shared/Modal";
import useRootStore from "../../../store/useRootStore";

interface NewBoxsetModalProps {
  showModal: boolean,
  onNewBoxset: (params: IShelfStore.BoxsetForm) => Promise<unknown>;
  onClose: () => void;
}

const { Step } = Steps;

export const NewBoxsetModal: FunctionComponent<NewBoxsetModalProps> = ({ showModal, onNewBoxset, onClose }: NewBoxsetModalProps) => {
  const [currentStep, setBookStep] = React.useState(0);
  const [selectedBookIds, setSelectedBookIds] = React.useState<Record<string, number>>({});
  const [shouldResetFields, setShouldResetFields] = useState(false);
  useEffect(() => {
		if(showModal) {
			setShouldResetFields(false);
		}
	}, [showModal]);

  const onSubmitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);
    await onNewBoxset(values);
    onClose();
    setSubmitting(false);
  };

  const onCancel = (resetForm: () => void, onClose: () => void) => {
    setShouldResetFields(true);
    setBookStep(0);
    resetForm();
    onClose();
  };

  return (
    <>
      <Formik
        initialValues={{ bookIds: [], title: "", author: [], project: "" } as IShelfStore.BoxsetForm}
        validate={({ bookIds, title }) => {
          const errors: FormikErrors<IShelfStore.BoxsetForm> = {};
          if (bookIds.length < 2) {
            errors.bookIds = "There must at least be 2 books to create a box set";
          }

          if (!title) {
            errors.title = "A book must have a title";
          }

          console.log("ERRORS", errors);
          return errors;
        }}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, dirty, handleChange, submitForm, setFieldValue, isSubmitting, resetForm }) => (
          <Modal
            maskClosable={false}
            title={"Create a new Boxset"}
            open={showModal}
            onCancel={() => onCancel(resetForm, onClose)}
            rightBtn={{
              type: "at-primary",
              backgroundColor: "green",
              block: true,
              size: "medium",
              loading: isSubmitting,
              disabled: (!dirty || !!errors.bookIds || (Boolean(currentStep) && !!errors.title)),
              onMouseDown: !currentStep ? () => setBookStep(1) : submitForm,
              children: !currentStep ? "Next" : "Create boxset"
            }}
            leftBtn={currentStep ? {
              type: "at-secondary",
              backgroundColor: "green",
              block: true,
              size: "medium",
              onMouseDown: () => setBookStep(0),
              children: "Previous"
            } : undefined}
          >
            <Steps
              type="navigation"
              size="small"
              current={currentStep}
              onChange={(value) => setBookStep(value)}
              className="boxset-step-wizard"
            >
              <Step title="1. Add books" />
              <Step title="2. Boxset information" />
            </Steps>
            {!currentStep ? (
              <BoxsetBookSelection
                selectedBookIds={selectedBookIds}
                onBookSelectionChange={(bookIds) => {
                  const bookIdsAsRecord = bookIds.reduce((acc, id, index) => {
                    acc[id] = index + 1;
                    return acc;
                  }, {} as Record<string, number>);
                  setSelectedBookIds(bookIdsAsRecord);
                  setFieldValue("bookIds", bookIds);
                }}
                resetField={shouldResetFields}
              />
            ) : (
              <BoxsetDetailsForm
                values={{ title: values.title, author: values.author, project: values.project }}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                resetField={shouldResetFields}
              />
            )}
          </Modal>
        )}
      </Formik>
    </>
  );
};
