import {
  createPluginFactory,
  onKeyDownToggleMark,
} from "@udecode/plate";

export const MARK_SMALLCAPS = "smallcaps";

export const createSmallCapsPlugin = createPluginFactory({
  key: MARK_SMALLCAPS,
  isLeaf: true,
  handlers: {
    onKeyDown: onKeyDownToggleMark,
  },
  inject: {
    props: {
      transformStyle: () => ({ fontVariant: "all-small-caps" }),
    },
  },
});
