export const parseTOC = (
  tocSummary: IChapterStore.TOCSummaryItem[],
  tocTitle: string, 
): string => {
  const html = `
    <div class="chapter">
    <div class="previewer-chapter-title-card">
      <div class="previewer-chapter-title">
        <h2>${tocTitle ? tocTitle : "Contents"}</h2>
      </div>
    </div>
      <div class="chapter-body">
        <div class="wrapper">
          <div class="toc-list">
            ${tocSummary
              .map((item) => {
                const chapterNumber = item.chapterNumber
                  ? item.chapterNumber + ". "
                  : "";
                return `
                <div style='margin-left:${2 * item.depth}em'>
                  <div class="table-of-content">
                    <span>
                      <span class="chapterNumber">${chapterNumber}</span> 
                      <span>${item.title}</span>
                      ${
                        item.subtitle
                          ? `<br/><span class="chapter-num-hidden" style="visibility: hidden">${chapterNumber}</span>
                          <span>${item.subtitle}</span>`
                          : ""
                      }
    
                    ${
                      item.subheads?.length
                        ? item.subheads
                            .map(
                              (head: any) =>
                                ` <br/> <span class="toc-subheading">${head.text}</span>`
                            )
                            .join("")
                        : ""
                    }
                    </span>
                  </div>
                </div >`;
              })
              .join("")}
          </div>
        </div>
      </div>
    `;
  return html;
};
