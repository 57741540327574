import { createPluginFactory } from "@udecode/plate-core";
import { OrnamentalBreakComponent } from "./components/ornamentalBreakComponent";

export const ELEMENT_ORNAMENTAL_BREAK = "ornamental-break";

export const createOrnamentalBreakPlugin = createPluginFactory({
    key: ELEMENT_ORNAMENTAL_BREAK,
    type: ELEMENT_ORNAMENTAL_BREAK,
    isElement: true,
    isVoid: true,
    component: OrnamentalBreakComponent
});
