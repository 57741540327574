import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { observer } from "mobx-react";

import useRootStore from "../../../store/useRootStore";

import { getWordsCount } from "../../../utils/helper";
import { AtticusDropdown } from "../../Shared/Dropdowns";
import { ArrowUp } from "../../../content/icons";


export const WordCount = observer(() => {
  const {wordCountType, setWordCountType } = useRootStore().bookStore;
  const { chapterTemplateView } = useRootStore().appStore;

  const handleSelect = (key: string) => {
    setWordCountType(key as IBookStore.wordCountType);
  };

  useEffect(() => {
    if (chapterTemplateView && wordCountType === "book") {
      setWordCountType("chapter");
    }
  }, [chapterTemplateView]);
  

  return (
    <AtticusDropdown
      trigger={["hover"]}
      placement="topLeft"
      className="word-count-dropdown"
      overlayClassName="word-count-dropdown-overlay"
      overlay={
        <Menu
          selectable
          defaultSelectedKeys={[wordCountType]}
          onSelect={(e) => handleSelect(e.key)}
          mode="inline"
          inlineIndent={0}
          className="word-count-selector"
        >
          <Menu.Item key="book" disabled={chapterTemplateView}>Book</Menu.Item>
          <Menu.Item key="chapter">Chapter</Menu.Item>
          <Menu.Item key="selection">Selection</Menu.Item>
        </Menu>
      }
    >
      <span className="word-count-type">
        {wordCountType.charAt(0).toUpperCase() + wordCountType.slice(1)}
      </span>
      <span className="word-count-divider">-</span>
      <span className="word-count-words">
      {wordCountType === "book" ? (
        <BookWC />
      ) : wordCountType === "chapter" ? (
        <ChapterWC />
      ) : wordCountType === "selection" ? (
        <SelectionWC />
      ) : null}
      </span>
      <span className="word-count-arrow">
        <ArrowUp />
      </span>
     
    </AtticusDropdown>
  );
});


const BookWC = observer(() => {
  const { getBookBodies, body, chapter, wordsCount, setWordsCount } = useRootStore().bookStore;
  const [list, setList] = useState<Array<string>>([]);

  useEffect(() => {
    getBookBodies().then((d) => {
      setWordsCount(d.words);
      setList(d.ids);
    });
  }, []);

  useEffect(() => {
    getBookBodies().then((d) => {
      setWordsCount(d.words);
    });
  }, [chapter._id]);

  const words = list.includes(chapter._id) ? getWordsCount(body) : 0;

  return (
    <>{(wordsCount + words).toLocaleString()} words</>
  );
});

const ChapterWC = observer(() => {
  const { body } = useRootStore().bookStore;

  return (
    <>{getWordsCount(body).toLocaleString()} words</>
  );
});

const SelectionWC = observer(() => {
  const { wordsCount } = useRootStore().bookStore;

  return (
    <>{wordsCount.toLocaleString()} words</>
  );
});