import { SpecialTemplate } from "./";

export const Introduction: SpecialTemplate = {
  title: "Introduction",
  children: [
    {
      type: "p",
      children: [
        {
          text: "An introduction is designed to prepare your readers for what to expect in the main body of the book.",
        },
      ],
    },
    {
      type: "p",
      children: [
        {
          text: "Introductions are most commonly used in non-fiction and are a great space to explain who you are, why you are writing the book, and what readers should expect to gain from reading the book.",
        },
      ],
    },
    {
      type: "p",
      children: [
        {
          text: "Introductions are often placed in the body of the book, before the first chapter, and are therefore not strictly considered front matter.",
        },
      ],
    },
  ],
  configuration: {
    showChapterImage: false,
    showPageNumber: true,
    showChapterHeading: true,
    showHeaderFooter: true,
    showInTableOfContents: true,
    showFirstSentenceFormatting: false,
  },
};
