import { getHtmlTagsForMarks } from "../helpers";

export const parseTextNode = (textNode: ISlateStore.SlateTextNode): string => {
  const { tagQueue, tagStack } = getHtmlTagsForMarks(textNode);
  const parsedText = textNode.text 
    ? textNode.text
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;") 
      .replace(/\n/gi, "<br>")
    : "";

  return tagQueue.join("") + parsedText + tagStack.reverse().join("");
};