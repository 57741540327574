import {
  getParentNode,
  PlateEditor,
  TNodeEntry,
  liftNodes,
  wrapNodeChildren,
} from "@udecode/plate";
import { Element, BaseElement, BaseEditor } from "slate";

import { NodeType } from "../../types";
import { aligmentElements } from "../../alignment/normalizers/withAlign";
import { ELEMENT_CALLOUTBOX } from "../createCalloutBoxPlugin";

export const withCalloutBox = <T extends PlateEditor>(
  editor: T
): PlateEditor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, childPath]: TNodeEntry) => {
    if (!Element.isElement(node)) return;

    if (
      (node as (BaseElement | BaseEditor) & NodeType).type ===
      ELEMENT_CALLOUTBOX
    ) {
      const parentEntry = getParentNode(editor, childPath);
      if (
        aligmentElements.indexOf(
          (parentEntry?.[0] as (BaseElement | BaseEditor) & NodeType).type
        ) > -1
      ) {
        wrapNodeChildren(
          editor,
          { type: parentEntry?.[0].type as string, children: [] },
          { at: childPath }
        );
        liftNodes(editor, { at: childPath });
        return;
      }
    }
    normalizeNode([node, childPath]);
  };

  return editor;
};
