import React from "react";
import { Modal as AntModal, Button as AntButton, ModalFuncProps, Row, Col } from "antd";
import { CloseIcon } from "../../../content/icons";
import { Button, AtticusButtonProps } from "../Buttons";

interface ATDialogProps extends Omit<ModalFuncProps, "closeIcon" | "footer"> {
  hideClose?: boolean;
  leftBtn?: Partial<AtticusButtonProps & { retainModalAfterCustomOnClick?: boolean }>,
  rightBtn?: Partial<AtticusButtonProps & { retainModalAfterCustomOnClick?: boolean }>,
  centered?: boolean
}

const defaultLeftBtnProps : AtticusButtonProps = {
  onClick: () => AntModal.destroyAll(),
  type: "at-secondary",
  backgroundColor: "green"
};

const defaultRightBtnProps : AtticusButtonProps = {
  onClick: () => AntModal.destroyAll(),
  type: "at-primary",
  backgroundColor: "green"
};

export const Dialog = (props: ATDialogProps) => {
  const leftBtnOnClick = async(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if(props.leftBtn?.onClick){
      await props.leftBtn?.onClick(e);
      if(!props.leftBtn.retainModalAfterCustomOnClick){
        AntModal.destroyAll();
      }
    }
    if(defaultLeftBtnProps.onClick){
      defaultLeftBtnProps.onClick(e);
    }
  };
  const rightBtnOnClick = async(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if(props.rightBtn?.onClick){
      await props.rightBtn?.onClick(e);
      if(!props.rightBtn.retainModalAfterCustomOnClick){
        AntModal.destroyAll();
      }
    }
    else if(defaultRightBtnProps.onClick){
      defaultRightBtnProps.onClick(e);
    }
  };

  const closeBtnOnClick = async(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if(props.onCancel){
      await props?.onCancel(e);
    }
    AntModal.destroyAll();
  };

  return AntModal.confirm({
    ...props,
    className: `at-dialog ${props.className || ""}`,
    centered: props.centered || true,
    title: (
      <Row className="at-modal-header" justify="space-between" align="middle">
        <Col className="at-modal-title">
          <b>{props.title}</b>
        </Col>
        {!props.hideClose ? (
          <Col className="at-modal-close">
            <AntButton icon={<CloseIcon />} onClick={(e) => closeBtnOnClick(e)} />
          </Col>
        ) : null}
      </Row>
    ),
    content: (
      <>
        <div className="at-dialog-content">
          {props.content}
        </div>
        {props.leftBtn || props.rightBtn ? (
          <Row gutter={16} className="at-modal-footer">
            <Col span={12}>
              {props.leftBtn && <Button fullWidth {...{...defaultLeftBtnProps, ...props.leftBtn, onClick: leftBtnOnClick}} />}
            </Col>
            <Col span={12}>
              {props.rightBtn && <Button fullWidth {...{...defaultRightBtnProps, ...props.rightBtn, onClick: rightBtnOnClick}} />}
            </Col>
          </Row>
        ) : null}
      </>
    )
  });
};