import React from "react";
import {
  useEventPlateId,
  usePlateEditorState,
} from "@udecode/plate-core";
import { ToolbarButton, ToolbarButtonProps } from "@udecode/plate-ui-toolbar";
import { insertOrnamentalBreak } from "../transforms/insertOrnamentalBreak";
import { allowFeatureForTextMessages } from "../../text-message/config";
import { allowFeatureForCalloutBoxes } from "../../callout-box/config";

export const OrnamentalBreakToolbarButton = ({
  id,
  ...props
}: ToolbarButtonProps):JSX.Element => {
  const editor = usePlateEditorState(useEventPlateId(id));
  const enabled = allowFeatureForTextMessages(editor) && allowFeatureForCalloutBoxes(editor);

  return (
    <div className={`${enabled ? "" : "plate-disabled"}`}>
      <ToolbarButton
        onMouseDown={(e) => {
          e.preventDefault();
          insertOrnamentalBreak(editor);
        }}
        {...props}
      />
    </div>
  );
};