import React from "react";
import { InputNumber, InputNumberProps } from "antd";

interface AtticusNumberFieldProps extends InputNumberProps {
  type?: "primary" | "secondary";
}

export const AtticusNumberField: React.FC<AtticusNumberFieldProps> = (props) => {
  return (
    <InputNumber
      {
        ...{
          ...props,
          type: "number",
          className: `at-form-input ${props.type}`
        }
      }
    />
  );
};
