import { isExpanded } from "@udecode/plate";
import { MyEditor } from "../../config/typescript";
import { MidPointCoordinates } from "./";

export const isRangeSelected = (editor: MyEditor) => {
  return editor.selection && isExpanded(editor.selection);
};

export const getEditorNodesCount = (editor: MyEditor): number => {
  return editor.getFragment().length ?? 0;
};

export const getMidPointOfSplit = (editor: MyEditor): MidPointCoordinates => {
  let anchor = editor.selection?.anchor;

  let midPoint = anchor?.path[0] as number;

  if (editor.selection && editor.selection.focus.offset > 0) {
    midPoint += 1;

    anchor = {
      offset: 0,
      path: [midPoint, 0]
    };
  }

  return {
    index: midPoint,
    anchor: anchor,
  };
};

