import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Radio, Row, Col, Tooltip } from "antd";

import useRootStore from "../../store/useRootStore";

import { ContactSupportIcon, DownloadAppIcon, HomeIcon, SaveIcon } from "../../content/icons";

import miniLogo from "../../content/images/miniLogo.png";
import wordLogo from "../../content/images/wordLogo.png";

import { InstallPWA } from "./install";
import { ProfileAvatar } from "./profile";
import { BookDetailsNav } from "./book-details";

export interface HeaderProps {
  mode: "editor" | "home" | "chapter-template"
}

const Header = observer(({ mode }: HeaderProps) => {
  const { setBookEdit, bookEdit } = useRootStore().bookStore;
  const { writing, setWriting, setThemeView } = useRootStore().appStore;
  const { isThemeBuilderActive, confirmExitEdit } = useRootStore().themeStore;
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab ] = useState("home");

  const handleEditorNavChange = (value: "writing" | "formatting" | "book-settings") => {
    if(value === "book-settings"){
      setThemeView(false);
      setBookEdit(!bookEdit);
      setWriting(true);
    }
    else if(value === "formatting"){
      setBookEdit(false);
      setWriting(false);
      setThemeView(true);
    }
    else {
      setBookEdit(false);
      setThemeView(false);
      setWriting(true);
    }
  };

  const handleHomeNavChange = (value: string) => {
    if (value === "my-books")
      push("/my-books");
    else
      push("/");
  };

  const goHome = () => {
    setThemeView(false);
    setWriting(true);
    push("/");
    //clearCache();
  };

  const goTo = (path: string) => push(path);

  const goBackup = () => {
    push("/backup");
  };


  const clearCache = () => {
    if ("serviceWorker" in navigator) {
      caches.keys().then(cacheNames => cacheNames.forEach(cacheName => caches.delete(cacheName)));
    }
    window.location.reload();
  };

  const handleExitTheme = (callback: () => void) => isThemeBuilderActive ? confirmExitEdit(callback) : callback();

  useEffect(() => {
    switch (pathname.split("/")[1]) {
      case "my-books":
        setActiveTab("my-books");
        break;

      case "books":
        setActiveTab(writing ? "writing" : "formatting");
        break;
    
      default:
        setActiveTab("home");
        break;
    }
  }, [pathname, writing]);
  

  return (
    <Row className='header' gutter={16} align='middle'>
      <Col span={8}>
        {mode === "editor" ? (
          <BookDetailsNav
            handleEditBookDetailsOnClick={() =>
              handleExitTheme(() => handleEditorNavChange("book-settings"))
            }
          >
            <Row
              className='nav-icon'
              gutter={16}
              align='middle'
              onClick={() => handleExitTheme(goHome)}
            >
              <Col>
                <img height={32} src={miniLogo} />
              </Col>
              <Col className='book-details-nav-icon'>
                <HomeIcon />
              </Col>
            </Row>
          </BookDetailsNav>
        ) : mode === "chapter-template" ? (
          <BookDetailsNav
            isChapterTemplate={true}
          >
            <Row
              className='nav-icon'
              gutter={16}
              align='middle'
              onClick={() => handleExitTheme(goHome)}
            >
              <Col>
                <img height={32} src={miniLogo} />
              </Col>
              <Col className='book-details-nav-icon'>
                <HomeIcon />
              </Col>
            </Row>
          </BookDetailsNav>
        ) : pathname === "/account/profile" || pathname === "/account/change-password"? (
          <Row
            className="nav-icon"
            gutter={16}
            align="middle"
            onClick={() => handleExitTheme(goHome)}
          >
            <Col>
              <img height={32} src={miniLogo} />
            </Col>
            <Col className="home-nav-icon">
              <HomeIcon />
            </Col>
          </Row>
        ):
        (
          <Row
            className='nav-icon'
            gutter={8}
            align='middle'
            onClick={() => handleExitTheme(goHome)}
          >
            <Col>
              <img height={32} src={miniLogo} />
            </Col>
            <Col>
              <img height={24} src={wordLogo} />
            </Col>
          </Row>
        )}
      </Col>
      <Col span={8}>
        <Row justify='center'>
          {pathname === "/account/profile" || pathname === "/account/change-password" ? <></> :
          mode === "editor" ? (
            <Radio.Group
              className='at-radio-nav'
              defaultValue='writing'
              value={activeTab}
              onChange={(e) =>
                handleExitTheme(() => handleEditorNavChange(e.target.value))
              }
            >
              <Radio.Button value='writing'>Writing</Radio.Button>
              <Radio.Button value='formatting'>Formatting</Radio.Button>
            </Radio.Group>
          ) : mode === "chapter-template" ? (
            <Radio.Group
              className='at-radio-nav'
              onChange={(e) =>
                handleExitTheme(() => handleHomeNavChange(e.target.value))
              }
            >
              <Radio.Button value='home'>Home</Radio.Button>
              <Radio.Button value='my-books'>My books</Radio.Button>
            </Radio.Group>
          ) : (
            <Radio.Group
              className='at-radio-nav'
              defaultValue='home'
              value={activeTab}
              onChange={(e) =>
                handleExitTheme(() => handleHomeNavChange(e.target.value))
              }
            >
              <Radio.Button value='home'>Home</Radio.Button>
              <Radio.Button value='my-books'>My books</Radio.Button>
            </Radio.Group>
          )}
        </Row>
      </Col>
      <Col span={8}>
        <Row gutter={24} align='middle' justify='end'>
          <Col>
            <Tooltip title="Install app">
              <InstallPWA>
                <DownloadAppIcon />
              </InstallPWA>
            </Tooltip>
          </Col>
          {pathname !== "/welcome" ? (
            <Col>
              <Tooltip title="Content backup">
                <div
                  className='nav-icon'
                  onClick={() => handleExitTheme(goBackup)}
                >
                  <SaveIcon />
                </div>
              </Tooltip>
            </Col>
          ) : null}
          <Col>
            <Tooltip title='Contact support'>
              <a
                className='nav-icon'
                target='_blank'
                href='https://atticus.io/support'
                rel='noreferrer'
              >
                <ContactSupportIcon />
              </a>
            </Tooltip>
          </Col>
          <Col>
            <ProfileAvatar
              handler={(path) => handleExitTheme(() => goTo(path))}
            />
          </Col>

          {/* <Col>
            <a onClick={() => handleExitTheme(goProfile)}>{(profile || profilePictureURL) ? <Avatar src={profile && profile.profilePictureURL ? profile.profilePictureURL : profilePictureURL} icon={<UserOutlined />} /> : <Avatar icon={<UserOutlined />} />}</a>
          </Col> */}
        </Row>
      </Col>
    </Row>
  );
});

export default Header;
