import React from "react";
import List from "./list/List";
import ActionBar from "./ActionBar";
import { FullScreen } from "../Shared/Layouts/FullScreen";
import ChapterTemplateList from "./ChapterTemplateList";

import { getEditorType } from "../../utils/helper";

const Sider: React.FC = () => {
  const { type } = getEditorType(location.pathname);
  const isChapterTemplate = type === "chapter-template";

  return (
    <FullScreen className="atticus-sidebar">
      <div className="inner-s sidebar-scroll scroller">
        {isChapterTemplate ? <ChapterTemplateList /> : <List />}
      </div>
      <div>{!isChapterTemplate ? <ActionBar /> : null}</div>
    </FullScreen>
  );
};

export default Sider;
