/**
 * for placeholders   droppableId -> {parentChapterId}-{sceneIndex}-placeholder-{index}
 * for scenes   droppableId -> {chapter Id}-{sceneIndex}
 * for chapters droppableId -> {chapter Id}
 *
 * @param droppableId
 * @summary parse the droppable id and returns
 * @returns [chapterId,sceneIndex]
 */

export const decodeDroppableId = (droppableId: string): [string, number | undefined, string | undefined] => {
  const [chapterId, sceneIndex, type] = droppableId.split("-");
  const scene = sceneIndex === undefined || sceneIndex === "" ? undefined : parseInt(sceneIndex);
  return [chapterId, scene, type];
};
