import React from "react";
import { observer } from "mobx-react";
import {
  Form,
  Row,
  Col,
  Radio,
  Slider,
  Checkbox
} from "antd";
import { AtticusSlider } from "../../Shared/Form";
//
export const footNotesScale = {
  min: 0.75,
  max: 2,
  labels: {
    0.75: {
      label: "0.75x",
    },
    1.00: {
      label: "1.00x",
    },
    1.25: {
      label: "1.25x",
    },
    1.50: {
      label: "1.50x",
    },
    1.75: {
      label: "1.75x",
    },
    2.00: {
      label: "2.00x",
    },
  },
};


const NotesSettings = ({
  formFields
}) => {

  const shouldRenderPdfEndnotesChapterOptions = formFields?.notesMode?.includes("END_OF_BOOK");
  const shouldRenderEpubEndnotesChapterOptions = formFields?.ePubNotesMode?.includes("END_OF_BOOK");

  return (
    <div className="theme-section-tab-content">
      <Row>
        <Col span={24}>
          <Form.Item
            name="notesMode"
            label="PDF"
            className="at-form-item"
          >
            <Radio.Group>
              <Radio value="FOOTNOTE">Footnotes</Radio>
              <Radio value="END_OF_CHAPTER">End of chapter</Radio>
              <Radio value="END_OF_BOOK">End of book</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row gutter={16}>
            <div style={{ display: shouldRenderPdfEndnotesChapterOptions ? "block" : "none" }}>
              <Col>
                <Form.Item 
                  className="at-form-item"
                  name="pdfEndnotesChapterSettings" 
                  label="Chapter settings"
                >
                  <Checkbox.Group>
                    <Checkbox value="showTitle">Include chapter title</Checkbox>
                    <Checkbox value="categorizeByTitle">Categorize by title</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </div>
          </Row>
        </Col>
        <Col span={24}>
          <Form.Item
            name="ePubNotesMode"
            label="ePub"
            className="at-form-item"
          >
            <Radio.Group>
              <Radio value="END_OF_CHAPTER">End of chapter</Radio>
              <Radio value="END_OF_BOOK">End of book</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row gutter={16}>
            <div style={{ display: shouldRenderEpubEndnotesChapterOptions ? "block" : "none" }}>
              <Col>
                <Form.Item 
                  className="at-form-item"
                  name="ePubEndnotesChapterSettings" 
                  label="Chapter settings"
                >
                  <Checkbox.Group>
                    <Checkbox value="showTitle">Include chapter title</Checkbox>
                    <Checkbox value="categorizeByTitle">Categorize by title</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </div>
          </Row>
        </Col>
        <Col span={24}>
          <Form.Item 
            className="at-form-item"
            name="footnoteFontSize" 
            label="Footnote font size"
          >
            <AtticusSlider 
              className="at-slider-contained"
              min={footNotesScale.min} 
              max={footNotesScale.max} 
              step={0.01} 
              marks={footNotesScale.labels} 
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default observer(NotesSettings);
