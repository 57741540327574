import React from "react";
import { ToolbarButton, ToolbarButtonProps } from "@udecode/plate";
import { observer } from "mobx-react";

import { SocialMediaLinkModal } from "./socialMediaLinkModal";
import useRootStore from "../../../../../store/useRootStore";

export const SocialMediaToolbarButton = observer(
  ({ id, ...props }: ToolbarButtonProps) => {
    const { setShowProfileModal } = useRootStore().socialProfileStore;

    return (
      <>
        <ToolbarButton
          onMouseDown={(e) => {
            e.preventDefault();
            setShowProfileModal(true);
          }}
          {...props}
        />
        <SocialMediaLinkModal id={id} />
      </>
    );
  }
);
