import { PlateEditor, Value } from "@udecode/plate";
import { ELEMENT_TEXT_MESSAGES_GROUP, ELEMENT_TEXT_MESSAGE } from "./createTextMessagePlugin";

export const allowFeatureForTextMessages = (editor: PlateEditor<Value>): boolean => {
  const focusPoint = editor.selection?.focus;

  let enabled = true;

  if (typeof focusPoint !== "undefined") {
    enabled = ![ELEMENT_TEXT_MESSAGES_GROUP, ELEMENT_TEXT_MESSAGE].includes(
      editor.children[focusPoint.path[0]].type
    );
  }
  return enabled;
};
