
import React from "react";
import { Input, InputProps } from "antd";
import { SearchIcon } from "../../../content/icons";


interface AtticusSearchFieldProps extends Omit<InputProps, "type"> {
  type?: "primary" | "secondary";
}

/* Search Field */

export const AtticusSearchField: React.FC<AtticusSearchFieldProps> = (props) => {

  return (
    <Input {
      ...{
        ...props,
        prefix: <SearchIcon />,
        className: `at-form-search ${props.type} ${props.className || ""}`
      }
    }
    />
  );
};
