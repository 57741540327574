import React from "react";
import { observer } from "mobx-react";
import { Col, Row } from "antd";

import "./index.scss";
import FavoritesListing from "./FavoriteListing";
import GoogleListing from "./GoogleListing";
import { Modal } from "../Shared/Modal";
import { AtticusTab, TabProps } from "../Shared/Tabs";
import { FontSearch } from "./partial/FontSearch";
import { FontFilter } from "./partial/FontFilter";

import useRootStore from "../../store/useRootStore";

const FontGalleryModal: React.FC = observer(() => {
  const { toggleFontGallery, showFontGallery, loading, toggleLoading, doFontSearch, favoriteFontCount, googleFontCount} = useRootStore().fontStore;

  const doFontUpdate = (query: Partial<IFontStore.FontQuery>) => {
    toggleLoading(true);
    doFontSearch(query).then(({totalCount}) => {
      toggleLoading(false);
    });
  };

  const tabItems: TabProps[] = [
    {
      key: "favorited",
      label: "Favorites",
      count: favoriteFontCount,
      children: <FavoritesListing />,
    },
    {
      key: "library",
      label: "Library",
      count: googleFontCount,
      children: <GoogleListing />
    },
  ];

  const searchAndFilterBar = (
    <Row className="font-listing-header" align="middle" justify="space-between" >
      <Col span={15}>
        <FontSearch onSearch={(search) => doFontUpdate({ page: 0, search })} />
      </Col>
      <Col span={8}>
        <FontFilter updateCategories={(arr) => doFontUpdate({ page: 0, category: arr })} />
      </Col>
    </Row>
  );

  return (
    <>
      <Modal
        open={showFontGallery}
        confirmLoading={loading}
        onCancel={() => toggleFontGallery()}
        centered={true}
        width={1200}
        title="Library"
        bodyStyle={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <AtticusTab
          className="font-tabs"
          variant="primary-blue"
          defaultActiveKey="favorited"
          items={tabItems}
          tabBarExtraContent={searchAndFilterBar}
        />
      </Modal>
    </>);
});

export default FontGalleryModal;