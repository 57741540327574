import React, { FunctionComponent } from "react";
import { Row, Col } from "antd";

import useRootStore from "../../../store/useRootStore";
import { CloseOutlinedIcon, InfoIcon } from "../../../content/icons";
import { Button, ButtonType } from "../../Shared/Buttons";
import { TooltipIcon } from "../../Plate/partials";

interface EditorRightMenuHeaderProps {
  title?: string;
  tooltipText?: string;
}

const EditorRightMenuHeader: FunctionComponent<EditorRightMenuHeaderProps> = ({
  title,
  tooltipText = "",
}) => {
  const { setSettingView, editor_setting } = useRootStore().appStore;

  const closeSettingView = () => {
    setSettingView({
      ...editor_setting,
      show: !editor_setting.show,
    });
  };
  return (
    <div className="setting-area-header">
      <Row justify="space-between" align="middle">
        <Col span={22}>
          <div className="setting-area-title">
            <h4>{title}</h4>
            <div className="info-circle">
              <TooltipIcon title={tooltipText} placement="bottom">
                <InfoIcon/>
              </TooltipIcon>
            </div>
          </div>
        </Col>
        <Col span={2}>
          <Button 
          className="close-btn"
            type={ButtonType.GHOST} 
            onClick={closeSettingView} 
            icon={<CloseOutlinedIcon/>}
            ></Button>
        </Col>
      </Row>
    </div>
  );
};

export default EditorRightMenuHeader;
