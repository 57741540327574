import React, { useState } from "react";
import {
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { observer } from "mobx-react";

import useRootStore from "../../../../../store/useRootStore";
import { Modal } from "../../../../Shared/Modal";
import { ProfileDeleteIcon, ProfileEditIcon } from "../../../../../content/icons";
import { Button } from "../../../../Shared/Buttons";

export const ListAllProfiles = observer(() => {
  const {
    userSMProfiles,
    smProfileToInsert,
    setModalState,
    deleteSMProfileFromDB,
    setSelectedSocialProfileId,
    setSMProfileToInsert,
    smProfilePathToReplace,
  } = useRootStore().socialProfileStore;

  const [deleting, setDeleting] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [profileIdToDelete, setProfileIdToDelete] = useState<string | null>(
    null
  );

  const onDeleteProfile = async () => {
    setShowConfirmDeleteModal(false);
    if (profileIdToDelete) {
      setDeleting(true);
      await deleteSMProfileFromDB(profileIdToDelete);
      setDeleting(false);
      onCloseDeleteModal();
    }
  };

  const onClickDelete = (profileId: string) => {
    setProfileIdToDelete(profileId);
    setShowConfirmDeleteModal(true);
  };

  const onEditProfile = (profileId: string) => {
    setSelectedSocialProfileId(profileId);
    setModalState("editProfile");
  };

  const onCloseDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setProfileIdToDelete(null);
  };

  const ConfirmDeleteModal = () => {
    return (
      <Modal
        visible={showConfirmDeleteModal}
        title={<b>Remove profile</b>}
        onCancel={() => setShowConfirmDeleteModal(false)}
        closable={false}
        leftBtn={{
          type: "at-secondary",
          backgroundColor: "green",
          onMouseDown: onCloseDeleteModal,
          children: "Cancel"
        }}
        rightBtn={{
          type: "at-primary",
          onMouseDown: onDeleteProfile,
          danger:true,
          children: "Remove profile"
        }}
      >
        <div className="delete-profile-modal-body">
          <p>Are you sure you want to remove this Profile from your account?</p>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      {userSMProfiles.map((profile) => {
        const isSelected = smProfileToInsert?._id === profile?._id;
        return (
          <div
            key={profile._id}
            onClick={() => {
              setSMProfileToInsert(profile);
            }}
            className={isSelected ? "all-prof selected" : "all-prof"}
          >
            <div>
              <p
                className={
                  isSelected ? "profile_name selected" : "profile_name"
                }
              >
                {profile.profileName}
              </p>
            </div>
            <div className="profile-list-action-btns">
              <Button type="at-ghost" icon={ <ProfileEditIcon color={isSelected ? "#FFFFFF" : "#171d21"} />} onClick={() => onEditProfile(profile._id)} />{" "}
              {!smProfilePathToReplace &&
                  <Button type="at-ghost" icon={<ProfileDeleteIcon color={isSelected ? "#FFFFFF" : "#171d21"} />} loading={deleting && profileIdToDelete === profile._id} onClick={() => onClickDelete(profile._id)} />
                }
            </div>
          </div>
        );
      })}
      <ConfirmDeleteModal />
    </div>
  );
});
