import React from "react";
import { Row, Col } from "antd";
import { observer } from "mobx-react";

import EditBookDetailsForm from "../Shared/Forms/EditBookDetails";
import EditPublisherDetailsForm from "../Shared/Forms/EditPublisherDetails";
import BookStats from "./BookStat";
import ExportContainer from "./ExportContainer";
import SnapshotContainer from "./SnapshotContainer";

import { AtticusTab, TabProps } from "../Shared/Tabs";

const EditBook = observer(() => {
  const tabItems: TabProps[] = [
    {
      key: "book-details",
      label: "Book details",
      children: <EditBookDetailsForm />,
    },
    {
      key: "publisher-details",
      label: "Publisher details",
      children: <EditPublisherDetailsForm />,
    },
  ];

  return (
    <div className="edit-book-wrapper">
      <div className="edit-book-scroller">
        <Row>
          <Col xs={24} md={14} lg={16} xxl={18}>
            <div className="edit-book-form-cover">
              <AtticusTab
                variant="primary-blue"
                defaultActiveKey="book-details"
                hideCountBadge={true}
                items={tabItems}
              />
            </div>
          </Col>
          <Col xs={24} md={10} lg={8} xxl={6}>
            <div className="edit-book-info-cover">
              <BookStats />
              <ExportContainer />
              <SnapshotContainer />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default EditBook;
