const getSizes = (imageNode: ISlateStore.SlateImage): string => {
  if (typeof imageNode.size === "string") {
    if (imageNode.size === "small") return "30";
    if (imageNode.size === "medium") return "45";
    if (imageNode.size === "large") return "100";
  }
  return `${imageNode.size}`;
};

const getInternalLink = (
  imageNode: ISlateStore.SlateImage,
  isExport?: boolean
): string => {
    const chapterId = imageNode.link?.internalLink?.chapterId.split("=")[1];
    if (isExport) {
      return `${chapterId}.xhtml`;
    }
    return `#chapter=${chapterId}`;
};

export const parseImage = (
  imageNode: ISlateStore.SlateImage,
  priorNode?: ISlateStore.SlateParentBlockNode,
  previousNode?: ISlateStore.SlateParentBlockNode,
  nextNode?: ISlateStore.SlateParentBlockNode,
  thenNode?: ISlateStore.SlateParentBlockNode,
  isExport?: boolean): string => {
    
  let parsedNode = "";
  let breakStyle = "page-break-before: always; page-break-after: always;";

  // handle seperate page images in exports
  if (imageNode.separatePage && isExport) {
    // separatePage image followed by another separatePage image
    const isNextNodeSeparatePageImage =
      nextNode?.type === "image" && nextNode?.separatePage;
    const isNextNodeAfterAnEmptyNodeSeparatePageImage =
      nextNode?.type === "p" &&
      (nextNode as any)?.children[0]?.text === "" &&
      thenNode?.type === "image" &&
      thenNode?.separatePage;
    if (
      isNextNodeSeparatePageImage ||
      isNextNodeAfterAnEmptyNodeSeparatePageImage
    ) {
      breakStyle = "page-break-before: always;";
    }

    // separatePage image immediately after a page-break
    const isPreviousNodePageBreak = previousNode?.type === "page-break";
    const isPreviousNodeBeforeAnEmptyNodePageBreak =
      previousNode?.type === "p" &&
      (previousNode as any).children[0].text === "" &&
      priorNode?.type === "page-break";

    if (isPreviousNodePageBreak || isPreviousNodeBeforeAnEmptyNodePageBreak) {
      breakStyle = "";
    }

    // separatePage image immediately before a page-break
    const isNextNodePageBreak = nextNode?.type === "page-break";
    const isNextNodeAfterAnEmptyNodePageBreak =
      nextNode?.type === "p" &&
      (nextNode as any).children[0].text === "" && 
      thenNode?.type === "page-break";

    if (isNextNodePageBreak || isNextNodeAfterAnEmptyNodePageBreak) {
      breakStyle = "page-break-before: always;";
    }

    // separatePage image is at the end of the chapter
    const isNextNodesUndefined =
      nextNode === undefined && thenNode === undefined;
    const isNextEmptyNodeAndUndefinedNode =
      nextNode?.type === "p" &&
      (nextNode as any)?.children[0].text === "" && 
      thenNode === undefined;
    if (isNextNodesUndefined || isNextEmptyNodeAndUndefinedNode) {
      breakStyle = "page-break-before: always;";
    }
    parsedNode += `<div style="${breakStyle}">`;
  }

  parsedNode += `<div class="image image-size-${imageNode.size} image-flow-${imageNode.flow} `;
  if (imageNode.wrap) {
    parsedNode += `image-wrap-${
      imageNode.flow
    }" style="width: ${getSizes(imageNode)}%;`;
  }
  parsedNode += "\">";

  if (imageNode.link) {
    if (imageNode.link.type === "web-link" && imageNode.link.webLink) {
      parsedNode += `<a href="${imageNode.link.webLink}" style="width: ${isExport? getSizes(imageNode): "100"}%">`;
    }
    if (
      imageNode.link.type === "internal-link" &&
      imageNode.link.internalLink
    ) {
      parsedNode += `<a href="${getInternalLink(imageNode, isExport)}" style="width: ${isExport? getSizes(imageNode): "100"}%">`;
    }
  }

  parsedNode += `<img style="width: ${
    imageNode.wrap ? "100" : getSizes(imageNode)
  }%;" src=${imageNode.url} alt="${imageNode.caption}" />`;
  if (imageNode.caption) parsedNode += `<br/><span class="caption">${imageNode.caption}</span>`;

  if (imageNode.link) parsedNode += "</a>";

  parsedNode += "</div>";
  if (imageNode.separatePage && isExport) {
    parsedNode += "</div>";
  }
  return parsedNode;
};
