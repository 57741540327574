import {
  someNode,
  Value,
  TEditor,
  TElement,
  setElements,
} from "@udecode/plate-core";

import { ELEMENT_BLOCKQUOTE, ELEMENT_DEFAULT } from "@udecode/plate";
import { ELEMENT_SUBHEADING, ELEMENT_VERSE, ELEMENT_HANGING_INDENT } from "../../";

export const toggleVerse = <V extends Value>(editor: TEditor<V>) => {
  const validNodeTypes = [
    ELEMENT_BLOCKQUOTE,
    ELEMENT_DEFAULT,
    ELEMENT_SUBHEADING,
    ELEMENT_HANGING_INDENT,
  ];

  const canConvertToVerse = (node: TElement): boolean => {
    return validNodeTypes.includes(node.type);
  };
  const isVerse = (node: TElement): boolean => {
    return node.type === ELEMENT_VERSE;
  };

  /**
   * if selected node's type is valid, convet the node to a verse node
   * if selected node is already a verse, toggle it back to default
   * */
  if (someNode(editor, { match: canConvertToVerse })) {
    setElements(editor, {
      type: ELEMENT_VERSE,
    });
  } else if (someNode(editor, { match: isVerse })) {
    setElements(editor, {
      type: ELEMENT_DEFAULT,
    });
  }
};
