import { unwrapNodes } from "@udecode/plate";
import { BaseElement, Element, Node } from "slate";

import { NodeType } from "../../types";

export const withParagraph = (editor) => {
  const { normalizeNode } = editor;
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // If the element is a paragraph, ensure its children are valid.
    if (
        Element.isElement(node) &&
        (node as BaseElement & NodeType).type === "p"
    ) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Element.isElement(child) && !editor.isInline(child)) {
          unwrapNodes(editor, { at: childPath });
          return;
        }
      } 
    } else if(!node.type) { // validate if node has type, if no type exist unwrap 
      unwrapNodes(editor, { at: path, split: false });
    }
    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };
  return editor;
};