import React from "react";
import { observer } from "mobx-react";
import { Avatar, Row, Col, Divider, Button, Space } from "antd";

import useRootStore from "../../store/useRootStore";

import { AtticusDropdown } from "../../components/Shared/Dropdowns";

interface ProfileAvatarProps {
  handler: (path: string) => void;
}

export const getInitials = (firstName, lastName) => {
  const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
  const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";

  return `${firstInitial}${lastInitial}`;
};

export const ProfileAvatar = observer(({ handler }: ProfileAvatarProps) => {
  const { user } = useRootStore().authStore;

  return (
    user && (
      <AtticusDropdown
        overlay={() => (
          <div className="profile-nav-dropdown">
            <Divider className="profile-dropdown-divider" />
            <Row align="middle">
              <Col>
                <Avatar onClick={() => handler("/account/profile")} src={user.profilePictureURL} size={"large"}><div className="profile-text">{getInitials(user?.firstName,user?.lastName)}</div></Avatar>
              </Col>
              <Col className="profile-dropdown-user">
                <b>{user.firstName} {user.lastName}</b>
                <p className="profile-dropdown-email">{user.email}</p>
              </Col>
            </Row>
            <Space
              className="profile-dropdown-link-container"
              direction="vertical"
              size={0}
            >
              <Button
                className="profile-dropdown-button"
                type="link"
                onClick={() => handler("/account/profile")}
              >
                <p className="profile-dropdown-text">My account</p>
              </Button>
              <Button
                className="logout-btn"
                type="link"
                onClick={() => handler("/auth/sign-out")}
              >
                Log out
              </Button>
            </Space>
          </div>
        )}
      >
        <Avatar src={user && user.profilePictureURL ? user.profilePictureURL : ""} >
          <div className="profile-text-h">{getInitials(user?.firstName, user?.lastName)}</div>
        </Avatar>
      </AtticusDropdown>
    )
  );
});
