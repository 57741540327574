import { CurrentChapterConfig } from "../helpers";

import { parseBaseParentBlock } from ".";

const olStyles = [
  "list-style-type: decimal;",
  "list-style-type: lower-alpha;",
  "list-style-type: lower-roman;",
]; /* 1, a, i */

export const parseList = (
  listBlock: ISlateStore.SlateListBlock,
  config: CurrentChapterConfig,
  olStyleLevel?: number,
  isExport?: boolean,
  endnotesOffset?: number,
  chapterId?: string,
  endnoteChapter?: IChapterStore.Chapter,
  themeConfig?: IThemeStore.ThemeStyleProps
): string => {
  let parsedHtml = "<div class=\"list-parent\">";
  if (listBlock.type == "ol") {
    /* Calculate the olStyleLevel */
    if (typeof olStyleLevel == "number") {
      if (olStyleLevel === olStyles.length - 1) {
        olStyleLevel = 0; /* Jump back to the first olStyleLevel */
      } else {
        olStyleLevel++; /* Increase the olStyleLevel */
      }
    } else {
      olStyleLevel = 0; /* Set olStyleLevel for the first level item */
    }

    const orderedListStyle = `margin-left:10px; ${olStyles[olStyleLevel]}`;
    parsedHtml += `<ol style="${orderedListStyle}">`;
  }

  if (listBlock.type == "ul") parsedHtml += "<ul>";

  for (const listChild of listBlock.children) {
    if (listChild.type === "li") {
      parsedHtml += "<li>";
      for (const l2Child of listChild.children) {
        if (l2Child.type === "ol") {
          parsedHtml += parseList(
            l2Child,
            config,
            olStyleLevel,
            isExport,
            endnotesOffset,
            chapterId,
            endnoteChapter,
            themeConfig
          );
        } else if (l2Child.type === "ul") {
          parsedHtml += parseList(
            l2Child,
            config,
            undefined,
            isExport,
            endnotesOffset,
            chapterId,
            endnoteChapter,
            themeConfig
          );
        } else {
          parsedHtml += parseBaseParentBlock(
            l2Child as ISlateStore.SlateBaseParentBlockNode,
            config,
            false,
            false,
            isExport,
            chapterId,
            endnoteChapter,
            endnotesOffset,
            themeConfig
          );
        }
      }
      parsedHtml += "</li>";
    }
  }

  if (listBlock.type == "ol") parsedHtml += "</ol>";
  if (listBlock.type == "ul") parsedHtml += "</ul>";
  parsedHtml += "</div>";
  return parsedHtml;
};
