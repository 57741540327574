import React, { FunctionComponent, useEffect } from "react";
import { Result } from "antd";
import { useHistory } from "react-router-dom";

import useRootStore from "../../../store/useRootStore";

const NoBooks: FunctionComponent =  () => {
	const { push } = useHistory();
	const { books } = useRootStore().shelfStore;

	useEffect(() => {
		if (books.length == 0) push("/welcome");
	}, []);

	return (
		<div>
			<Result
				status="404"
				title="You don't have any books"
				subTitle="To get started, upload an existing document or create a new project."
			/>,
		</div>
	);
};

export default NoBooks;