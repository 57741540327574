import React from "react";
import { Modal as AntModal, Button as AntButton, ModalProps, Row, Col } from "antd";
import { CloseIcon } from "../../../content/icons";
import { Button, AtticusButtonProps } from "../Buttons";

interface ATModalProps extends Omit<ModalProps, "closeIcon" | "footer"> {
  hideClose?: boolean;
  leftBtn?: AtticusButtonProps,
  rightBtn?: AtticusButtonProps
}

export const Modal: React.FC<ATModalProps> = (props) => {
  return (
    <>
      <AntModal
        {
        ...{
          ...props,
          className: `at-modal ${props.className || ""}`,
          title: (
            <Row className="at-modal-header" justify="space-between" align="middle">
              <Col className="at-modal-title" span={23}>
                {props.title}
              </Col>
              {!props.hideClose ? (
                <Col className="at-modal-close" span={1}>
                  <AntButton icon={<CloseIcon />} onClick={props.onCancel} />
                </Col>
              ) : null}
            </Row>
          ),
          closable: false,
          footer: props.leftBtn || props.rightBtn ? (
            <Row gutter={16} className="at-modal-footer">
              <Col span={12}>
                {props.leftBtn && <Button {...props.leftBtn} />}
              </Col>
              <Col span={12}>
                {props.rightBtn && <Button {...props.rightBtn} />}
              </Col>
            </Row>
          ) : null,
        }
        }
      />
    </>
  );
};