import React, { useEffect } from "react";
import {
	Row,
	Col,
	Modal
} from "antd";

import EditBookForm from "../Shared/Forms/EditBookDetails";
import BookStats from "./BookStat";
import { observer } from "mobx-react";

import useRootStore from "../../store/useRootStore";

interface EditBookPopupProps {
    id: string;
    toggle: (visible: string) => void
}

const EditBookPopup = observer((props: EditBookPopupProps) => {
	const { toggle, id } = props;
	const { getAndSetCurBook, book } = useRootStore().bookStore;

	useEffect(() => {
		getAndSetCurBook(id);
	}, [id]);
	
    return (
		<Modal
			open={Boolean(id)}
            destroyOnClose
			onCancel={() => toggle("")}
			footer={null}
			width={1080}
			title={<h2 className="section-heading">Edit book details</h2>}
			bodyStyle={{
				backgroundColor: "white"
			}}
		>
			<div style={{
				maxWidth: 1000,
				width: "100%",
				margin: "auto"
			}}>
				{book ? (
					<Row align="stretch">
						<Col flex={2}>
							<EditBookForm />
						</Col>
						<Col flex={1} className="book-stat">
							<BookStats />
						</Col>
					</Row>
				) : null}
			</div>
		</Modal>
	);
});

export default EditBookPopup;