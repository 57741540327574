import { Switch } from "antd";
import { observer } from "mobx-react";
import React from "react";
import useRootStore from "../../../../../../store/useRootStore";

const CollapseSwitch: React.FC = observer(() => {
  const { collapsed, setCollapsed } = useRootStore().writingHabitStore;

  return (
    <Switch
      checked={!collapsed}
      onChange={(isChecked: boolean) => setCollapsed(!isChecked)}
      className="collapse-switch"
    />
  );
});

export { CollapseSwitch };
