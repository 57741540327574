
import React, { useState } from "react";
import { Col, Menu, Button, Row, MenuProps } from "antd";

import { AtticusDropdown } from "../Shared/Dropdowns/Dropdown";
import { MenuIcon } from "../../content/icons";

export const BookCardDropdown = ({actions}: {actions: MenuProps["items"]}) => {
	return (
		<AtticusDropdown
			trigger={["hover"]}
			placement="topRight"
      overlayClassName="book-card-dropdown-overlay"
      className="book-card-dropdown"
			overlay={
				<Menu 
					items={actions} 
				/>
			}
		>
      <MenuIcon className="book-card-dropdown-icon"/>
		</AtticusDropdown>
	);
};
