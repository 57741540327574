import React from "react";
import { Menu, Modal, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import useRootStore from "../../../store/useRootStore";
import { Dialog } from "../../Shared/Modal";


interface DuplicateThemeProps {
  theme: IThemeStore.Theme
}

export const DuplicateTheme = observer(({ theme }: DuplicateThemeProps) => {
  const { saveNewCustomTheme } = useRootStore().themeStore;
  const { book } = useRootStore().bookStore;
  const { refreshCache } = useRootStore().pdfCacheStore;

  const onDuplicateTheme = async () => {
    try {
      const renamedTheme = {...theme, name: `Copy of ${theme.name}`};
      await saveNewCustomTheme(renamedTheme, book._id);
      refreshCache(book._id, "theme-change");
    } catch (e: any) {
      message.error("Theme could not be duplicated", 4);
    }
  };

  return (
    <Menu.Item
      key="duplicate-theme"
      onClick={() => {
        Dialog({
          title: "Duplicate Theme",
          content: "Are you sure you want to duplicate this theme?",
          rightBtn: {
            onClick: onDuplicateTheme,
            children: "Duplicate"
          },
          leftBtn: {
            children: "No"
          },
        });
      }}
      icon={<CopyOutlined />}
    >
      Duplicate
    </Menu.Item>
  );
});
