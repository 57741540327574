import React from "react";
import { Form, Select, Row, Col, Slider } from "antd";
import { observer } from "mobx-react";
import { FontVariant } from "@surge-global-engineering/css-generator";

import { headingSize } from "../helpers/configs";
import { AtticusSelect, AtticusSlider } from "../../../Shared/Form";
import { getAvailableFontsForDropdown } from "../../../../utils/font";
import useRootStore from "../../../../store/useRootStore";
import FontGalleryButton from "../../../Fonts/FontGalleryButton";
import { getFontFamilyName } from "../../../../utils/font";

interface HeadingSettingProps {
  heading: string;
  headingType: string;
}

const { Option } = Select;

const HeadingSetting = ({ heading, headingType }: HeadingSettingProps) => {
  const { userFavouriteFonts } = useRootStore().fontStore;
  const headingFonts = [...userFavouriteFonts];
  const defaultFont = {
    _id: "Default",
    family: "Default",
    source: "Default",
    createdAt: new Date(),
    lastUpdateAt: new Date(),
    variants: [],
  };
  headingFonts.unshift(defaultFont);

  return (
    <div>
      <p>{heading}</p>
      <div className="inner-xs" />
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item
            className="at-form-item"
            name={`${headingType}Font`}
            label="Font"
            tooltip="The default font is the font selected for the body of your print book and the font selected by the reader on their ebook device."
          >
            <AtticusSelect 
              placeholder="Select"
              selectOptions={getAvailableFontsForDropdown(headingFonts)
                // .filter(font => !font.availableForPreviewer) //exclude "previewer" specific fonts
                .filter(font => !font.availableForDropCap)
                .map(font => ({
                classNames: getFontFamilyName(font._id, FontVariant.regular),
                value: font._id,
                label: font.family
              }))}
              dropdownRender={(menu) => (
                <>
                    {menu}
                    <FontGalleryButton />
                </>
              )}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="at-form-item"
            name={`${headingType}Size`}
            label="Size"
          >
            <AtticusSlider
              className="at-slider-contained"
              marks={headingSize.labels}
              min={headingSize.min}
              max={headingSize.max}
              step={0.01}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default observer(HeadingSetting);
