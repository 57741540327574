import React from "react";
import {
  Radio,
  Checkbox,
  Form,
  Row,
  Col,
} from "antd";
import { observer } from "mobx-react";

import { BookViewer } from "./partial";
import { LayoutSettings } from "./partial";

const PageLayoutSettings = () => {
  return (
    <div className="theme-section-tab-content">
      <p>Layout</p>
      <Row className="hf-layout-btns scroller">
        <Form.Item className="at-form-item" name="layout">
          <Radio.Group>
            <Radio value="layout5">
              <BookViewer.E />
            </Radio>
            <Radio value="layout6">
              <BookViewer.F />
            </Radio>
            <Radio value="layout1">
              <BookViewer.A />
            </Radio>
            <Radio value="layout2">
              <BookViewer.B />
            </Radio>
            <Radio value="layout3">
              <BookViewer.C />
            </Radio>
            <Radio value="layout4">
              <BookViewer.D />
            </Radio>
            <Radio value="layout7">
              <BookViewer.G />
            </Radio>
            <Radio value="layout8">
              <BookViewer.H />
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Row>
      <div className="inner-s" />
      <LayoutSettings title="Header" prefix="header" />
      <LayoutSettings title="Footer" prefix="footer" />
    </div>
  );
};

export default observer(PageLayoutSettings);
