import React from "react";
import { observer } from "mobx-react";
import { List, Popconfirm, Tooltip, Row } from "antd";

import { Button } from "../Shared/Buttons";
import { FontItem } from "./partial";
import { FavFilledIcon } from "../../content/icons";

import useRootStore from "../../store/useRootStore";

const FavoriteListing = observer(() => {
  const { googleFavoritedFonts, removeFromFavorites, loading } = useRootStore().fontStore;

  return (
    <>
      <div className="font-gallery-tab-wrapper" >
        <div className="font-gallery-tab-inner scroller">
          <Row>
            <div className="font-tabs font-fav-tabs-inner">
              <List
                grid={{ column: 3 }}
                dataSource={googleFavoritedFonts}
                loading={loading}
                itemLayout="horizontal"
                locale={{
                  emptyText: "No Fonts to show yet"
                }}
                className="font-listing-wrapper"
                renderItem={(item) => (
                  <FontItem
                    key={item._id}
                    font={item}
                    actionItem={
                      item.source !== "atticus" ? (
                        <Tooltip title="Remove from favorites">
                          <Popconfirm
                            title="Are you sure you want to remove this from favorites ?"
                            onConfirm={() => removeFromFavorites(item._id)}
                            okText="Remove"
                            okButtonProps={{
                              style: {
                                backgroundColor: "red"
                              }
                            }}
                            cancelText="No"
                          >
                            <Button
                              type="at-ghost"
                              className="font-fav-btn"
                              icon={<FavFilledIcon />}
                            />
                          </Popconfirm>
                        </Tooltip>
                      ) : null
                    }
                  />
                )}
              />
            </div>
          </Row>
        </div>
      </div>
    </>
  );
});

export default FavoriteListing;