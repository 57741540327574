import React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { Slider, Form, FormItemProps } from "antd";
import { AtticusSlider } from "../../../../Shared/Form/Slider";
import { ImageSize } from "../../../config/typescript";

interface ImageSizeComponentProps {
    size?: ImageSize
    onSizeChange?: (size: ImageSize) => void
}

const formItemProperties: FormItemProps = {
    colon: false,
    labelCol: { span: 24 },
    style: { marginBottom: 15 },
};

const parseSizes = (size?: ImageSize) => {
    if (typeof size === "string") {
        switch (size) {
            case "small":
                return 30;
            case "medium":
                return 45;
            case "large":
                return 100;
            default:
                return 100;
        }
    }
    if (typeof size === "number") return size;
    return 100;
};

export const ImageSizeComponent: FunctionComponent<ImageSizeComponentProps> = ({ size: inputSize, onSizeChange }) => {
    const [size, setSize] = useState<number>();

    const handleSizeChange = (value: number) => {
        setSize(value);
        if (onSizeChange) onSizeChange(value);
    };

    useEffect(() => {
        setSize(parseSizes(inputSize));
    }, [inputSize]);

    return (
        <>
            <Form.Item label={<span className="edit-image-form-label">Image size</span>} {...formItemProperties}>
                <div className="edit-image-width-wrapper">
                    <AtticusSlider color="green" className="edit-image-width-slider" defaultValue={100} min={1} max={100} value={size} onChange={handleSizeChange}/>
                    <p className="edit-image-width-slider-value">{size}%</p>
                </div>
            </Form.Item>
        </>
    );
};
