import { ChapterConfiguration } from "../../../types/chapter";
import { MyRootBlock } from "../../Plate/config/typescript";

import { Blurbs } from "./Blurbs";
import { Dedication } from "./Dedication";
import { Epigraph } from "./Epigraph";
import { Foreword } from "./Foreword";
import { Preface } from "./Preface";
import { Introduction } from "./Introduction";
import { Prologue } from "./Prologue";
import { Epilogue } from "./Epilogue";
import { Afterword } from "./Afterword";
import { Acknowledgements } from "./Acknowledgements";
import { AboutTheAuthor } from "./AboutTheAuthor";
import { AlsoBy } from "./AlsoBy";

export interface SpecialTemplate {
  title: string;
  children: MyRootBlock[];
  configuration: ChapterConfiguration;
}

export const SpecialTemplates: SpecialTemplate[] = [
  Blurbs,
  Dedication,
  Epigraph,
  Foreword,
  Preface,
  Introduction,
  Prologue,
  Epilogue,
  Afterword,
  Acknowledgements,
  AboutTheAuthor,
  AlsoBy,
];
