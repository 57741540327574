import React, { FunctionComponent } from "react";
import { Row, Col, Form, Radio } from "antd";
import {
  FontVariant,
  getFontFamilyName,
} from "@surge-global-engineering/css-generator";

import {
  AtticusCheckBox,
  AtticusRadioButton,
  AtticusSelect,
  AtticusSlider,
} from "../Shared/Form";
import {
  BiggerFontIcon,
  HigherLineHeightIcon,
  LowerLineHeightIcon,
  SmallerFontIcon,
} from "../../content/icons";
import { Button, ButtonType, BackgroundColor } from "../Shared/Buttons/Button";
import useRootStore from "../../store/useRootStore";
import useLocalStorage from "../../utils/hooks/useLocalStorage";

const inits: IAppStore.EditorConfigs = {
  indentation: "spaced",
  justified: false,
  font: "",
  fontsize: 16,
  linespacing: 1.5,
};

const TextSettings: FunctionComponent = () => {
  const [form] = Form.useForm();
  const { patchEditor, editor } = useRootStore().appStore;

  const [conf, setConf] = useLocalStorage("atticusEdtrConf", "");

  const handleChange = (v: Partial<IAppStore.EditorConfigs>) => {
    const editorSettings = {
      ...editor,
      ...v,
    };
    setConf(JSON.stringify(editorSettings));
    patchEditor(editorSettings);
  };

  const reset = () => {
    patchEditor(inits);
    setConf(JSON.stringify(inits));
    form.setFieldsValue(inits);
  };

  const fontList = [
    {
      value: "",
      label: "Default",
    },
    {
      value: getFontFamilyName("ComicRelief", FontVariant.regular),
      label: "Comic Relief",
    },
    {
      value: getFontFamilyName("DPSansMono", FontVariant.regular),
      label: "DP Sans Mono",
    },
    {
      value: getFontFamilyName("EBGaramond", FontVariant.regular),
      label: "EB Garamond",
    },
    {
      value: getFontFamilyName("OpenDyslexic", FontVariant.regular),
      label: "Open Dyslexic",
    },
  ];

  return (
    <Form
      className="setting-area-form"
      form={form}
      layout="vertical"
      onValuesChange={handleChange}
      initialValues={
        conf ? JSON.parse(conf) : (editor as IAppStore.EditorConfigs)
      }
    >
      <Row>
        <h5 className="setting-form-label">Font family</h5>
      </Row>
      <Row justify="space-around" align="middle">
        <Col span={24}>
          <Form.Item name="font" shouldUpdate>
            <AtticusSelect
              type="secondary"
              defaultValue={""}
              selectOptions={fontList}
              className="editor-font-select"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <h5 className="setting-form-label">Font size</h5>
      </Row>
      <Row justify="space-around" align="middle">
        <Col span={2}>
          <SmallerFontIcon />
        </Col>
        <Col span={20}>
          <Form.Item name="fontsize" shouldUpdate>
            <AtticusSlider
              defaultValue={editor.fontsize}
              min={12}
              max={20}
              step={1}
              color="green"
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <BiggerFontIcon />
        </Col>
      </Row>
      <Row>
        <h5 className="setting-form-label">Line height</h5>
      </Row>
      <Row justify="space-around" align="middle">
        <Col span={2}>
          <LowerLineHeightIcon />
        </Col>
        <Col span={20}>
          <Form.Item name="linespacing" shouldUpdate>
            <AtticusSlider
              min={1}
              max={2}
              step={0.1}
              color="green"
              defaultValue={editor.linespacing}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <HigherLineHeightIcon />
        </Col>
      </Row>
      <Row>
        <h5 className="setting-form-label">Paragraph settings</h5>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name="indentation"
            className="goal-setting-radio-btns"
            shouldUpdate
          >
            <Radio.Group>
              <AtticusRadioButton
                value="indent"
                type="secondary"
              > Indented </AtticusRadioButton>
              <AtticusRadioButton
                value="spaced"
                type="secondary"
              > Spaced </AtticusRadioButton>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item valuePropName="checked" name="justified" shouldUpdate>
            <AtticusCheckBox
              checked={editor.justified}
              type="secondary"
              label="Justified"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            type={ButtonType.SECONDARY}
            onClick={reset}
            backgroundColor={BackgroundColor.GREEN}
          >
            Reset to default settings
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default TextSettings;
