
import React, { useState } from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";

import { Radio } from "antd";

import useRootStore from "../../store/useRootStore";

import { UnorderedListOutlined, AppstoreFilled } from "@ant-design/icons";

export const BookViewSwitcher = observer(() => {
  const { view, setView } = useRootStore().shelfStore;
  const { push } = useHistory();
  const { search } = useLocation();

  const handleSwitchView = (v) => {
    const params = new URLSearchParams(search);
    params.set("view", v);
    const queryString = params.toString();

    setView(v);
    push({
      search: queryString,
    });
  };

  return (
    <Radio.Group
      className="at-book-view-switcher"
      onChange={(e) => handleSwitchView(e.target.value)}
      value={view}
      buttonStyle="solid"
    >
      <Radio.Button value="grid"><AppstoreFilled /></Radio.Button>
      <Radio.Button value="list"><UnorderedListOutlined /></Radio.Button>
    </Radio.Group>
  );
});
