import React, { useEffect } from "react";
import { observer } from "mobx-react";

import useRootStore from "../../store/useRootStore";
import ThemeSection from "./ThemeSection";

const ThemeWrapper = observer(() => {
  const { allThemes: themes, activeTheme: theme, getAndSetTheme, synced, mountThemes } = useRootStore().themeStore;
  const { book } = useRootStore().bookStore;

  useEffect(() => {
    if (themes.length > 0) {
      getAndSetTheme(book.themeId);
    }
  }, [synced]);

  // refresh themes every time the theme wrapper is mounted
  useEffect(() => {
    mountThemes();
  }, []);

  return (
    themes.length > 0 && theme ?
      <ThemeSection />
      : null);
});

export default ThemeWrapper;
