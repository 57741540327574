import React from "react";
import { Checkbox, Radio, Switch , CheckboxProps, RadioProps, SwitchProps} from "antd";

 interface AtticusCheckBoxProps extends Omit<CheckboxProps, "type"> {
  type?: "primary" | "secondary";
  label?: string;
}
  
  export const AtticusCheckBox: React.FC<AtticusCheckBoxProps> = (props) => {

    const {type = "primary", onChange, style, label, checked = false, disabled = false} = props;
    const className = `custom-checkbox-${type}`;
    return (
      <Checkbox
        className={className}
        checked={checked}
        onChange={onChange}
        style={style}
        disabled={disabled}
      >
        {label}
      </Checkbox>
    );
  };

  interface AtticusRadioButtonProps extends Omit<RadioProps, "type"> {
    type?: "primary" | "secondary";
    label?: string;
  }
  
  
  export const AtticusRadioButton: React.FC<AtticusRadioButtonProps> = (props) => {
    const{type = "primary", label , onChange , style , checked, value} =props;
    const className = `custom-radio-${type}`;
    return (
      <Radio
        className= {className} 
        value={value}
        checked={checked}
        onChange={onChange}
        style={style}
      >
        {props.children}
      </Radio>
    );
  };

  interface AtticusSwitchProps extends Omit<SwitchProps, "type"> {
    type?: "primary" | "secondary";
  }
  export const AtticusSwitch: React.FC<AtticusSwitchProps> = (props) => {
    const{type = "primary", onChange , style , checked} =props;

    const className = `custom-switch-${type}`;
    return (
      <Switch
        className={className}
        checked={checked}
        onChange={onChange}
        style={style}
      />
    );
  };