import { PlateEditor, Value } from "@udecode/plate";
import { MyEditor, PlateNodes } from "../../../config/typescript";

export const doesEditorHaveRedos = (editor: PlateEditor<Value>) => {
  if (!editor) return;

  if (editor.history && Array.isArray(editor.history.redos)) {
    if (editor.history.redos.length > 0) {
      return true;
    }
  }

  return false;
};
