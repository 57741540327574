import { Book, ErrorBook, ErrorChapter } from "../types/book";
import Bugsnag from "@bugsnag/js";
import { Chapter, ChapterBody, ChapterMeta, IChapterTemplateBase } from "../types/chapter";
import { DeviceSpec } from "../types/theme";

//IndexedDB
import { db } from "../db/bookDb";

// Helpers
import { removeKey } from "./helper";
import { cloneDeep } from "lodash";

interface BookQueryInclude {
    chapterMeta?: boolean;
    chapterBodies?: boolean;
}

interface ThemeQueryInclude {
    themeMeta?: boolean;
    themeBase?: boolean;
    themeFields?: boolean;
}

const nullPromise = (): Promise<null> => new Promise(res => res(null));

export const GetBookFromDB = async (bookId: string, options?: BookQueryInclude): Promise<Book | undefined> => {
    const book = await db.books.get(bookId);

    if (book) {
        if (!book.frontMatterIds) book.frontMatterIds = [];

        const allPromises: Promise<ChapterMeta[] | ChapterBody[] | null>[] = [];

        if (options && options.chapterMeta) {
            allPromises.push(db.chapterMetas.where("bookId").equals(book._id).toArray());
        } else {
            allPromises.push(nullPromise());
        }

        if (options && options.chapterBodies) {
            allPromises.push(db.chapterBodies.where("bookId").equals(book._id).toArray());
        } else {
            allPromises.push(nullPromise());
        }

        const [meta, bodies] = await Promise.all(allPromises);

        // Book Bodies
        if (options?.chapterBodies || options?.chapterMeta) {
            const frontMatter: Chapter[] = [];
            const chapters: Chapter[] = [];

            // Front-matter Body
            for (const chapterId of book.frontMatterIds) {
                let tmpChapter: Chapter | null = null;

                if (meta) {
                    const curMetaIndex = (meta as ChapterMeta[]).findIndex((m) => m._id === chapterId);
                    if (curMetaIndex > -1) {
                        tmpChapter = { ...(meta as ChapterMeta[])[curMetaIndex], children: [] };
                    }
                }

                if (options.chapterBodies && bodies && tmpChapter) {
                    const curBodyIndex = (bodies as ChapterBody[]).findIndex((b) => b._id === chapterId);
                    if (curBodyIndex > -1) {
                        const tmpChapterBody = (bodies as ChapterBody[])[curBodyIndex];

                        tmpChapter = {
                            ...tmpChapter,
                            ...tmpChapterBody,
                        };
                    }
                }
                if (tmpChapter) frontMatter.push(tmpChapter);
            }

            // book body
            for (const chapterId of book.chapterIds) {
                let tmpChapter: Chapter | null = null;

                if (meta) {
                    const curMetaIndex = (meta as ChapterMeta[]).findIndex((m) => m._id === chapterId);
                    if (curMetaIndex > -1) {
                        tmpChapter = { ...(meta as ChapterMeta[])[curMetaIndex], children: [] };
                    }
                }

                if (options.chapterBodies && bodies && tmpChapter) {
                    const curBodyIndex = (bodies as ChapterBody[]).findIndex((b) => b._id === chapterId);
                    if (curBodyIndex > -1) {
                        const tmpChapterBody = (bodies as ChapterBody[])[curBodyIndex];

                        tmpChapter = {
                            ...tmpChapter,
                            ...tmpChapterBody,
                        };
                    }
                }
                if (tmpChapter) chapters.push(tmpChapter);
            }

            book.frontMatter = frontMatter;
            book.chapters = chapters;
        }
    }

    return book;
};

export const SaveBookToDB = async (book: Book): Promise<void> => {

    const chapterMetas: ChapterMeta[] = [];
    const chapterBodies: ChapterBody[] = [];

    if (book.chapters) {
        for (const chapter of book.chapters) {
            const tmpMeta: ChapterMeta = {
                _id: chapter._id,
                bookId: chapter.bookId,
                title: chapter.title,
                titleScale: chapter.titleScale,
                type: chapter.type,
                subtitle: chapter.subtitle,
                image: chapter.image,
                index: chapter.index,
                lastSuccessfulSync: chapter.lastUpdateAt,
                numbered: chapter.numbered,
                includeIn: chapter.includeIn,
                startOn: chapter.startOn,
                templateId: chapter.templateId,
                fullpageImage: chapter.fullpageImage,
                allChangesSynced: true,
                parentChapterId: chapter.parentChapterId,
                volume: chapter.volume,
                toc: chapter.toc,
                configuration: chapter.configuration,
            };

            const tmpBody: ChapterBody = {
                _id: chapter._id,
                bookId: chapter.bookId,
                children: chapter.children,
                lastSuccessfulSync: chapter.lastUpdateAt,
                allChangesSynced: true,
            };

            chapterMetas.push(tmpMeta);
            chapterBodies.push(tmpBody);
        }
    }

    const allPromises: Promise<unknown>[] = [];

    const parsedBook = removeKey(book, "chapters") as Book;

    allPromises.push(db.books.put({
        ...parsedBook,
        modifiedAt: book.lastUpdateAt,
        lastSuccessfulSync: book.lastUpdateAt,
    }));

    allPromises.push(db.chapterMetas.bulkPut(chapterMetas));
    allPromises.push(db.chapterBodies.bulkPut(chapterBodies));

    await Promise.all(allPromises);

    return;
};

// Chapter Template Library
export const SaveChapterTemplateToDB = async (template: IChapterTemplateBase): Promise<void> => {
    await db.chapterTemplates.put({
      ...template,
      modifiedAt: template.lastUpdateAt,
      lastSuccessfulSync: template.lastUpdateAt,
    });

    return;
};

// Chapter Template Library
export const GetChapterTemplates = async (templateId: string): Promise<IChapterTemplateBase | undefined> => {
    const dev = await db.chapterTemplates.get(templateId);
    return dev;
};

export const GetAllChapterTemplates = async (): Promise<IChapterTemplateBase[] | undefined> => {
    const dev = await db.chapterTemplates.toArray();
    return dev;
};

// Chapter Template Library
export const UpdateChapterTemplateMeta = async (chapterId: string, updates: Partial<ChapterMeta>): Promise<void> => {
    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.chapterTemplates.update(chapterId, updates));

    // allPromises.push(db.chapterMetas.update(chapterId, updates));
    // allPromises.push(db.chapterBodies.update(chapterId, updates));
    await Promise.all(allPromises);
};

// Chapter Template Library
export const UpdateChapterTemplateInDB = async (templateId: string, updates: Partial<IChapterTemplateBase>): Promise<void> => {
    await db.chapterTemplates.update(templateId, updates).catch (function (err) {
        Bugsnag.notify(err);
        console.log(err);
    });
};

export const GetChapterFromDB = async (chapterId: string): Promise<Chapter | null> => {
    const allPromises: Promise<ChapterMeta | ChapterBody | undefined>[] = [];

    allPromises.push(db.chapterMetas.get(chapterId));
    allPromises.push(db.chapterBodies.get(chapterId));

    const [chapterMeta, chapterBody] = await Promise.all(allPromises);

    if (chapterMeta && chapterBody) {
        const chapter: Chapter = { ...(chapterMeta as ChapterMeta), ...(chapterBody as ChapterBody) };
        return chapter;
    }
    return null;
};

export const FilterChapterMeta = async(filter: Record<string, string | number>): Promise<ChapterMeta[]> => {
    return await db.chapterMetas.where(filter).toArray();
};

export const UpdateChapterBodyInIDB = async (chapterId: string, updates: Partial<Chapter>): Promise<void> => {
    await db.chapterBodies.update(chapterId, updates).catch (function (err) {
        Bugsnag.notify(err);
        console.log(err);
    });
};

export const UpdateBookInDB = async (bookId: string, updates: Partial<Book>): Promise<void> => {
    await db.books.update(bookId, updates);
};

export const UpdateChapterMeta = async (chapterId: string, updates: Partial<ChapterMeta>): Promise<void> => {
    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.chapterMetas.update(chapterId, cloneDeep(updates)));
    allPromises.push(db.chapterBodies.update(chapterId, cloneDeep(updates)));
    await Promise.all(allPromises);
};

export const DeleteChaptersFromDB = async (chapterIds: string[]): Promise<void> => {
    const allPromises: Promise<unknown>[] = [];

    allPromises.push(db.chapterMetas.where("_id").anyOf(chapterIds).delete());
    allPromises.push(db.chapterBodies.where("_id").anyOf(chapterIds).delete());

    await Promise.all(allPromises);
};

//Themes

export const SaveThemesInIDB = async (themes: IThemeStore.ThemeResponse[]): Promise<void> => {
    const parsedThemes: IThemeStore.ThemeResponse[] = [];

    if (themes) {
        for (const theme of themes) {
            const parsedTheme: IThemeStore.ThemeResponse = {
                _id: theme._id,
                name: theme.name,
                fonts: theme.fonts,
                css: theme.css,
                createdAt: theme.createdAt,
                lastUpdateAt: theme.lastUpdateAt,
                modifiedAt: theme.modifiedAt,
                lastSuccessfulSync: theme.lastSuccessfulSync,
                allChangesSynced: true,
                properties: theme.properties,
                isPredefinedTheme: theme.isPredefinedTheme,
                isFavourite: theme.isFavourite,
            };
            parsedThemes.push(parsedTheme);
        }

        const allPromises: Promise<unknown>[] = [];
        allPromises.push(db.theme.bulkPut(parsedThemes));
    }
    return;
};

export const DeleteThemeFromIndexedDB = async (themeId: string): Promise<void> => {
  await db.theme.where("_id").equals(themeId).delete();
};

export const SavePreviewDevice = async (device: DeviceSpec[]): Promise<void> => {
    const allDevices: DeviceSpec[] = [];
    if (device) {
        for (const dv of device) {
            const allT: DeviceSpec = {
                _deviceName: dv._deviceName,
                image: dv.image,
                fonts: dv.fonts
            };
            allDevices.push(allT);
        }
    }
    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.deviceConfig.bulkPut(allDevices));
    return;
};

export const GetPreviewDeviceDB = async (_deviceName: string): Promise<DeviceSpec | undefined> => {
    const dev = await db.deviceConfig.get(_deviceName);
    return dev;
};

export const GetAllPreviewDevices = async () => {
    const bookTheme = await db.deviceConfig.toArray();

    const unique = bookTheme.map((a) => {
        return a.image;
    });
    return unique;
};

export const GetAllTheme = async (_id: string): Promise<any | undefined> => {
    const bookTheme = await db.themeBase.get(_id);
    return bookTheme;
};

export const GetThemeConfigs = async (): Promise<any[]> => {
    const bookTheme = await db.theme.toArray();
    return bookTheme || [];
};

export const GetAllThemesFromIDB = async (): Promise<IThemeStore.ThemeResponse[]> => {
    const bookTheme = await db.theme.toArray();
    return bookTheme || [];
};

export const GetThemeFromIDB = async (_id: string): Promise<IThemeStore.ThemeResponse | undefined> => await db.theme.get(_id);

export const OverwriteThemeInIDB = async (themeId: string, updates: Partial<IThemeStore.ThemeResponse>): Promise<number> => await db.theme.update(themeId, updates);

export const SaveNewThemeInIDB = async (theme: IThemeStore.ThemeResponse): Promise<string> => await db.theme.add(theme);


// Error Visualization
export const SaveErrorBook = async (error: string[]): Promise<void> => {
    const errorBooks: ErrorBook[] = [];
    if(error) {
        for(const er of error) {
            const allE = {
                _bookId: er
            };
            errorBooks.push(allE);
        }
    }
    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.failedBooks.bulkPut(errorBooks) );
    return;
};

// Error Visualization
export const GetErrorBook = async (): Promise<ErrorBook[] | undefined> => {
    try {
        const errorBook = await db.failedBooks.toArray();
        return errorBook;
    } catch (error) {
        console.log(error);
    }
};

// Error Visualization
export const SaveErrorBookChapter = async (error: ErrorChapter): Promise<void> => {
    const errorChapters: ErrorChapter[] = [];

    if(error) {
        const allPromises: Promise<unknown>[] = [];
        allPromises.push(db.failedChapters.put({
            ...error
            // lastSuccessfulSync: error.lastSuccessfulSync
         }) );
        return;
    }

};

// Error Visualization
export const GetErrorChapters = async (): Promise<ErrorChapter[] | undefined>=> {
    try {
        const errorChapter = await db.failedChapters.toArray();
        return errorChapter;
    } catch (error) {
        console.log(error);
    }
};


//Do the merge sync with archived theme config

