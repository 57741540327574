import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Row, Col, Tooltip, notification } from "antd";
import Countdown from "react-countdown";

// Icons
import { SprintPauseTimerIcon, SprintPlayTimerIcon } from "../Plate/partials/Icon";

import { db } from "../../db/bookDb";

import "./sprint.scss";

import useRootStore from "../../store/useRootStore";
import { NotificationCloseIcon } from "../../content/icons";
import { Button } from "../Shared/Buttons/Button";

interface SprintBreakProps {
    sp?: ISprintTimerStore.SprintTimer | null,
    toggle: (visible: boolean) => void
    cancel?: (visible: boolean) => void
}

const SprintBreak = observer((props: SprintBreakProps) => {
    const { getCurrentBookId } = useRootStore().bookStore;
    const bkkId = getCurrentBookId();

    const [timerPopConfirm, setTimePopConfirm] = useState(false);
    const [breakTimerPopConfirm, setBreakTimePopConfirm] = useState(false);
    const [activeTimer, setActiveTimer] = useState(false);
    const [completedSP, setCompleted] = useState(false);
    const [completedSPBreak, setCompletedSPBreak] = useState(false);

    const writingMinutes = props.sp?.writingTime ? props.sp?.writingTime : 0;
    const breakMinutes = props.sp?.breakTime ? props.sp?.breakTime : 0;
    const sprintWritingStatus = props.sp ? props.sp.status : null;
    const staticWM = props.sp?.statTime;
    const staticBM = props.sp?.statBreakTime;


    const setToggleViews = (destroyNotification?:boolean) => {
        props.toggle(true);
        if(destroyNotification) {
            notification.destroy();
        }
    };


    React.useEffect(() => {
        setCompleted(false);
    }, [writingMinutes]);

    const handleTooltips = () => {
        setTimePopConfirm(false);
        setBreakTimePopConfirm(false);
        props.cancel ? props.cancel(true) : null;
        notification.destroy();
    };

    // Renderer callback with condition
    const renderer = ({ formatted: { hours, minutes, seconds }, completed, api, total }) => {

        if (api.isPaused()) {
            const tData = {
                _bookId: bkkId,
                writingTime: total,
                breakTime: total,
                statTime: staticWM,
                statBreakTime: staticBM,
                status: "paused"
            };

            const timeData = db.sprintTimer.put(tData);

            return (
                <div className="sprint-break">
                    <Tooltip color="#3568BA" placement="bottom" title={<span>Play Timer</span>}>
                        <div className="before-sprint" onClick={api.start}>
                            <SprintPlayTimerIcon />
                            {writingMinutes > 3600000 ? (<span style={{ color: "#3568BA", paddingLeft: 2 }}>{hours}{minutes}:{seconds}</span>) : (<span style={{ color: "#3568BA" }}>{minutes}:{seconds}</span>)}
                        </div></Tooltip>
                </div>
            );
        }
        if (completed) {

            // Render a completed state
            setBreakTimePopConfirm(true);
            setActiveTimer(true);
            setCompleted(true);

            const tData = {
                _bookId: bkkId,
                writingTime: total,
                breakTime: breakMinutes,
                statTime: staticWM,
                statBreakTime: staticBM,
                status: "completed"
            };

            const timeData = db.sprintTimer.put(tData);

            return null;

        } else {
            // Render a countdown
            if (completedSP == false) {
                const tData = {
                    _bookId: bkkId,
                    writingTime: total, //hours,minutes,seconds,
                    breakTime: breakMinutes,
                    statTime: staticWM,
                    statBreakTime: staticBM,
                    status: "active"
                };

                const timeData = db.sprintTimer.update(bkkId, tData);

            }

            return (
                <>
                    {/* <div className="after-sprint">
                    <Tooltip color="#3568BA" placement="bottom" title={<span>Pause Timer</span>}>
                        <div className="before-sprint" onClick={api.pause}>
                            <SprintPauseTimerIcon />
                            </div>
                            </Tooltip>
                    {completedSP == true ? (
                        writingMinutes > 3600000 ? (<span style={{ color: "#00BE95", paddingLeft: 2 }} onClick={() => setToggleViews()}>{hours}:{minutes}:{seconds}</span>) : (<span style={{ color: "#00BE95" }} onClick={() => setToggleViews()}>{minutes}:{seconds}</span>)
                    ) : (
                        writingMinutes > 3600000 ? (<span style={{ color: "#00BE95", paddingLeft: 2 }}>{hours}:{minutes}:{seconds}</span>) : (<span style={{ color: "#00BE95" }}>{minutes}:{seconds}</span>)
                    )}
                </div> */}

                    <div className="after-sprint">
                        {completedSP == true ? (
                            <div className="before-sprint">
                                <SprintPauseTimerIcon />
                                {writingMinutes > 3600000 ? (<span style={{ color: "#00BE95", paddingLeft: 2 }} onClick={() => setToggleViews()}>{hours}:{minutes}:{seconds}</span>) : (<span style={{ color: "#00BE95" }} onClick={() => setToggleViews()}>{minutes}:{seconds}</span>)}
                            </div>
                        ) : (
                            <Tooltip color="#3568BA" placement="bottom" title={<span>Pause Timer</span>}>
                                <div className="before-sprint" onClick={api.pause}>
                                    <SprintPauseTimerIcon />
                                    {writingMinutes > 3600000 ? (<span style={{ color: "#00BE95", paddingLeft: 2 }}>{hours}:{minutes}:{seconds}</span>) : (<span style={{ color: "#00BE95" }}>{minutes}:{seconds}</span>)}
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </>
            );
        }
    };

    const rendererBreak = ({ formatted: { hours, minutes, seconds }, completed, api, total }) => {

        if (api.isPaused()) {
            const tData = {
                _bookId: bkkId,
                writingTime: writingMinutes,
                breakTime: total,
                statTime: staticWM,
                statBreakTime: staticBM,
                status: "paused"
            };

            const timeData = db.sprintTimer.put(tData);

            return (
                <div className="sprint-break">
                    <Tooltip color="#3568BA" placement="bottom" title={<span>Play Timer</span>}>
                        <div className="before-sprint" onClick={api.start}>
                            <SprintPlayTimerIcon />
                            {/* <span style={{ color: "#3568BA" }} onClick={() => setToggleViews()}>{minutes}:{seconds}</span> */}
                            {breakMinutes > 3600000 ? (<span style={{ color: "#3568BA", paddingLeft: 2 }}>{hours}:{minutes}:{seconds}</span>) : (<span style={{ color: "#3568BA" }}>{minutes}:{seconds}</span>)}
                        </div></Tooltip>
                </div>
            );
        }

        if (completed) {
            setTimePopConfirm(true);
            setCompletedSPBreak(true);
            setBreakTimePopConfirm(false);

            const tData = {
                _bookId: bkkId,
                writingTime: total,
                breakTime: total,
                statTime: staticWM,
                statBreakTime: staticBM,
                status: "completed"
            };

            const timeData = db.sprintTimer.put(tData);

            return null;
        } else {

            // Render a countdown
            // setBreakTimePopConfirm(true);

            if (completedSPBreak == false) {
                const tData = {
                    _bookId: bkkId,
                    writingTime: 0,
                    breakTime: total,
                    statTime: staticWM,
                    statBreakTime: staticBM,
                    status: "active"
                };

                const timeData = db.sprintTimer.update(bkkId, tData);

            }

            return (
                // <div className="after-sprint">
                //     <Tooltip color="#3568BA" placement="bottom" title={<span>Pause Timer</span>}><div className="before-sprint" onClick={api.pause}><SprintPauseTimerIcon /></div></Tooltip>
                //     {completedSPBreak == true ? (
                //         breakMinutes > 3600000 ? (<span style={{ color: "#3568BA", paddingLeft: 2 }} onClick={() => setToggleViews()}>{hours}:{minutes}:{seconds}</span>) : (<span style={{ color: "#3568BA" }} onClick={() => setToggleViews()}>{minutes}:{seconds}</span>)
                //     ) : (
                //         breakMinutes > 3600000 ? (<span style={{ color: "#3568BA", paddingLeft: 2 }}>{hours}:{minutes}:{seconds}</span>) : (<span style={{ color: "#3568BA" }}>{minutes}:{seconds}</span>)
                //     )}
                // </div>

                <div className="after-sprint">
                    {completedSPBreak == true ? (
                        <div className="before-sprint">
                            <SprintPauseTimerIcon />
                            {breakMinutes > 3600000 ? (<span style={{ color: "#3568BA", paddingLeft: 2 }} onClick={() => setToggleViews()}>{hours}:{minutes}:{seconds}</span>) : (<span style={{ color: "#3568BA" }} onClick={() => setToggleViews()}>{minutes}:{seconds}</span>)}                    </div>
                    ) : (
                        <Tooltip color="#3568BA" placement="bottom" title={<span>Pause Timer</span>}>
                            <div className="before-sprint" onClick={api.pause}>
                                <SprintPauseTimerIcon />
                                {breakMinutes > 3600000 ? (<span style={{ color: "#3568BA", paddingLeft: 2 }}>{hours}:{minutes}:{seconds}</span>) : (<span style={{ color: "#3568BA" }}>{minutes}:{seconds}</span>)}                        </div>
                        </Tooltip>
                    )}
                </div>

            );
        }
    };

    const triggerSprintCompletedNotification = () => {
        notification.open({
            message: null,
            duration: null,
            placement: "bottomRight",
            icon: null,
            className: "timer-notification",
            closeIcon: <></>,
            description: (
              <div>
                <Row>
                  <Col flex="auto" className="info">
                    <div className="title">Your sprint is complete!</div>
                    <div className="description">
                      Want to start another sprint?
                    </div>
                  </Col>
                  <Col className="action">
                    <Button
                      type="at-ghost"
                      className="start-button"
                      onClick={() => setToggleViews(true)}
                    >
                      Start
                    </Button>
                  </Col>
                  <Col className="destroy" onClick={() => handleTooltips()}>
                    <NotificationCloseIcon />
                  </Col>
                </Row>
              </div>
            ),
          });
    };

    const triggerSprintBreakNotification = () => {
        notification.open({
            message: null,
            duration: null,
            placement: "bottomRight",
            icon: null,
            className: "timer-notification",
            closeIcon: <></>,
            description: (
              <div>
                <Row>
                  <Col flex="auto" className="info">
                    <div className="title">Your sprint is complete!</div>
                    <div className="description">Time to take a break</div>
                  </Col>
                  <Col className="destroy" onClick={() => notification.destroy()}>
                    <NotificationCloseIcon />
                  </Col>
                </Row>
              </div>
            ),
          });
    };
    useEffect(() => {
      if(breakTimerPopConfirm) {
        if(activeTimer) {
            triggerSprintBreakNotification();
        } else {
            triggerSprintCompletedNotification();
        }
      }

      if(timerPopConfirm) {
        triggerSprintCompletedNotification(); 
      }
    }, [activeTimer, breakTimerPopConfirm, timerPopConfirm]);

    return (
        <>
            {activeTimer === false &&
                <div className="after-sprint">
                    {sprintWritingStatus === "active" ? (
                        <Countdown date={Date.now() + (writingMinutes)} renderer={renderer} />
                    ) : null}
                    {sprintWritingStatus === "paused" ? (
                        <Countdown date={Date.now() + (writingMinutes)} renderer={renderer} />
                    ) : null}
                </div>
            }

            {activeTimer === true  &&
                <div className="sprint-break">
                    {breakMinutes && sprintWritingStatus === "active" ? (
                        <Countdown date={Date.now() + (breakMinutes)} renderer={rendererBreak} />
                    ) : null}
                    {breakMinutes && sprintWritingStatus === "paused" ? (
                        <Countdown date={Date.now() + (breakMinutes)} renderer={rendererBreak} />
                    ) : null}
                </div>
            }
        </>
    );
});

export default SprintBreak;