import { PlateId } from "@udecode/plate";
import React, { ReactNode } from "react";

interface HangingIndentComponentProps {
  id: PlateId;
  children: ReactNode;
}

export const HangingIndentComponent = (props: HangingIndentComponentProps) => {
  const { id, children } = props;
  return (
    <pre className="plate-custom-hanging-indent-block" id={id}>
      <code>{children}</code>
    </pre>
  );
};
