import React, { FunctionComponent } from "react";
import { Formik, FormikErrors } from "formik";
import { Form, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { Modal } from "../Shared/Modal";
import { Button, ButtonSize, ButtonType } from "../Shared/Buttons";
import FileUpload from "../Shared/Forms/FileUpload";
import { UploadIcon } from "../../content/icons";

interface ImportChaptersModalProps {
  onUpload: (params: IShelfStore.ChapterImportForm) => Promise<unknown>;
  onClose: () => void;
  bookId: string;
}

const ImportChaptersModal: FunctionComponent<ImportChaptersModalProps> = ({
  onUpload,
  onClose,
  bookId,
}: ImportChaptersModalProps) => {
  return (
    <Modal
      open={true}
      title="Import chapters to the current book"
      onCancel={() => onClose()}
      centered={true}
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
      wrapClassName="import-chapter-modal-wrap"
    >
      <Formik
        initialValues={
          {
            bookId,
            fileURL: "",
            fileType: "",
            insertIntoVolume: false,
          } as IShelfStore.ChapterImportForm
        }
        validate={() => {
          const errors: FormikErrors<IShelfStore.ChapterImportForm> = {};
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await onUpload(values);
          setSubmitting(false);
        }}
      >
        {({
          setFieldValue,
          submitForm,
          isSubmitting,
          setSubmitting,
          values,
        }) => (
          <Form>
            <div>
              <Form.Item name="upload">
                <FileUpload
                  fileType=".docx"
                  onFileUpload={(fileUrl, fileType) => {
                    setFieldValue("fileURL", fileUrl);
                    if (fileType) setFieldValue("fileType", fileType);
                  }}
                  onFileUploadStart={() => setSubmitting(true)}
                  onFileUploadEnd={() => setSubmitting(false)}
                  styleOverrides={{
                    backgroundColor: "#E7EDF8",
                    borderRadius: 4,
                    borderWidth: 2,
                    borderStyle: "dashed",
                    borderColor: "#ADC1E3",
                  }}
                >
                  <div className="import-chapter-dnd-container">
                    {isSubmitting ? (
                      <span>
                        <Spin indicator={<LoadingOutlined />} /> Uploading
                        file
                      </span>
                    ) : (
                      <>
                        <span>
                          <UploadIcon color="#3363B9" />
                        </span>
                        <p>
                          <span className="import-chapter-dnd-text-highlight">
                            Click to upload file
                          </span>{" "}
                          or drag and drop
                        </p>
                      </>
                    )}
                  </div>
                </FileUpload>
              </Form.Item>
            </div>
            <Button
              type={ButtonType.PRIMARY}
              size={ButtonSize.LARGE}
              backgroundColor="green"
              onMouseDown={submitForm}
              loading={isSubmitting}
              disabled={!values.fileURL}
              fullWidth
            >
              Go get em Atticus!
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ImportChaptersModal;
