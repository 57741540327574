import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { UsedFont, themePropsToCss } from "@surge-global-engineering/css-generator";

import { Modal } from "antd";

import useRootStore from "../../store/useRootStore";
import { db } from "../../db/bookDb";
import Toolbar from "./Toolbar";
import { generateToc } from "../../utils/toc";
import {
  parseTitleCard,
  parseFullPageImage,
  parseTOC,
  parseChapter,
  parseEndNoteChapter,
  parseEPubNotIncluded,
} from "../../press/exportEpub/parsers";
import {
  isFrontMatterChapter,
  getAdjacentChapterIds,
  shouldApplyDropCaps,
  epubErrorModalContent,
  getPreviewerConfigByDevice,
  DeviceName,
  isIncludedInEpub,
  
} from "../../press/exportEpub/helpers";
import { PrintPreviewer } from "./print";
import { PdfChapterEndnotes, PdfEndnoteSubheading, PdfSlateEndnote } from "./print/types";
import { getChapterNumber } from "../../utils/chapter-numbering";
import { AtticusFontBaseUrl } from "../../utils/font";
import saveAs from "file-saver";

import "./index.scss";
import "./previewerStyles.scss";
import { GetBookFromDB, GetThemeFromIDB } from "../../utils/offline.book.helpers";
import { exportEpubBook } from "../../press/exportEpub";
import { generate } from "randomstring";
import { useOnlineStatus } from "../../utils/isOffline";
import { ThemeResponse } from "../../types/theme";
import { getFontsForEpub } from "../../press/exportEpub/helpers/getFontsForEpub";
import { Button } from "../Shared/Buttons/Button";
import { VerticalLeftOutlinedIcon, VerticalRightOutlinedIcon } from "../../content/icons";
import { ScrollContainer } from "../Shared/Layouts";

const previewerPrefix = "previewer";

interface PreviewerProps {
  isSidebar?: boolean;
}

const Previewer = observer((props:PreviewerProps ) => {
  const { isSidebar } = props;
  const { push } = useHistory();
  const {
    chapter,
    body,
    book,
    setErrorBook,
    getChapterBodyById,
    getAllEndNotesOfBook,
    getAllEndNotesByChapter,
  } = useRootStore().bookStore;
  const { getDevicePreview, activeTheme: theme, isThemeBuilderActive, confirmExitEdit, themeFormFields, getAndSetTheme, synced, allThemes: themes, } = useRootStore().themeStore;
  const { previewerConfigs, setPreviewerConfigs } = useRootStore().appStore;
  const { setPDFExproterOptions } = useRootStore().appStore;
  const { resetSelectedChapters } = useRootStore().sideMenuStore;
  const { refreshCache } = useRootStore().pdfCacheStore;

  const [endnotes, setEndnotes] = useState<(PdfSlateEndnote | PdfEndnoteSubheading)[]>([]);
  const [endnotesByChapterTitle, setEndnotesByChapterTitle] = useState<(PdfChapterEndnotes)[]>([]);
  const [chapters, setChapters] = useState<IChapterStore.Chapter[]>([]);
  const [previewFontSize, setPreviewFontSize] = useState(12);
  const [previewFont, setPreviewFont] = useState("Palatino");
  const [epubExporting, setEpubExporting] = useState(false);
  const isOnline = useOnlineStatus();
  const [usedFonts, setUsedFonts] = useState<UsedFont[]>([]);

  const getBookChapters = async () => {
    const chaps = await getChapterBodyById([...book.chapterIds, ...book.frontMatterIds]);
    setChapters(chaps);
  };

  useEffect(() => {
    getBookChapters();
  }, [chapter]);

  useEffect(() => {
    if (book.themeId !== theme._id && themes.length>0){
      getAndSetTheme(book.themeId);
    }
    refreshCache(book._id, "theme-change");
  }, [synced]);

  useEffect(() => {   
    let unmounted = false;
    (async () => {
      const {usedFonts} = await getFontsForEpub(theme.properties, db.userFavouriteFonts);
      if(usedFonts) setUsedFonts(usedFonts);
      if (chapter.type !== "endnotes") {
        return;
      }
      if (
        theme?.properties.notesMode !== "END_OF_BOOK" &&
        theme?.properties.ePubNotesMode !== "END_OF_BOOK"
      ) {
        return;
      }
      if (theme.properties.ePubEndnotesChapterSettings.categorizeByTitle) {
        await getAllEndNotesByChapter().then((note) => {
          if (!unmounted) setEndnotesByChapterTitle(note);
        });
      } else {
        await getAllEndNotesOfBook().then((note) => {
          if (!unmounted) setEndnotes(note);
        });
      }
    })();
    return () => {
      unmounted = true;
    };
}, [chapter, theme, themeFormFields]);

  /** export epub, pdf configs */

  const removeErrorBooks = (bookId: string) => {
    db.failedChapters.where("_bookId").anyOf(bookId).delete();
    db.failedBooks.where("_bookId").anyOf(bookId).delete();
  };

  const exportEpub = async () => {
    setEpubExporting(true);

    const theme = await GetThemeFromIDB(book.themeId);
    const {properties: themeStyleProps} = theme as ThemeResponse;
    const {fontFiles, usedFonts} = await getFontsForEpub(themeStyleProps, db.userFavouriteFonts);

    const fullBook = await GetBookFromDB(book._id, {
      chapterMeta: true,
      chapterBodies: true,
    });

    let bookEndnotes: (PdfSlateEndnote | PdfEndnoteSubheading)[] | PdfChapterEndnotes[] = [];
    if(theme?.properties.ePubNotesMode === "END_OF_BOOK"){
      bookEndnotes = theme.properties.ePubEndnotesChapterSettings.categorizeByTitle ? await getAllEndNotesByChapter(): await getAllEndNotesOfBook();
    }
    if(fullBook && theme) {
      try {
        const epub = await exportEpubBook(fullBook, theme, bookEndnotes, fontFiles, usedFonts);
        saveAs(new Blob([epub]), `${book.title}-${generate(10)}.epub`);
        removeErrorBooks(book._id);
      } catch (e) {
        console.error(e);
        setErrorBook(book._id);
        Modal.confirm(epubErrorModalContent);
      }
    }
    setEpubExporting(false);
  };

  const exportLocalPdf = () => {
    setPDFExproterOptions(book._id, book.title);
  };

  const handleExitEdit = (callback: () => void) => isThemeBuilderActive ? 
    confirmExitEdit(
      callback,
      () => undefined,
      {
        title: "You cannot export unsaved changes",
        ok: "Continue working",
        cancel: "Discard and Export"
      }
    ): 
    callback();

  /** epub previewer body and styling generators */
  const chapterNumber = useMemo(() => {
    return  getChapterNumber(chapter,book);
  }, [chapter, book]);

  const innerHtmlForChapter = useMemo(() => {
    if(theme && !isIncludedInEpub(chapter.includeIn)) {
      return parseEPubNotIncluded();
    }

    if (theme && isIncludedInEpub(chapter.includeIn)) {
      if (chapter.type === "title") return parseTitleCard(book,chapter);
      if (chapter.type === "image") {
        return parseFullPageImage({ ...chapter, children: body });
      }
      if (chapter.type === "toc") {
        return parseTOC(
          generateToc(book, chapters, "ebook", chapter),
          chapter.title,
        );
      }
      if (chapter.type === "part") {
        return parseEPubNotIncluded();
      }
      if (isFrontMatterChapter(chapter.type)) {
        // TODO - Logic needs to update with chapter level configs
        // removes chapter header images for frontmatter
        return parseChapter(
          {
            ...chapter,
            children: body,
          },
          theme?.properties,
          false,
          chapterNumber,
          previewerPrefix
        );
      }
      if(chapter.type == "volume"){
        return parseEPubNotIncluded();
      }
      if (chapter.type === "endnotes") {
        if (theme?.properties.ePubNotesMode === "END_OF_BOOK" && theme.properties.ePubEndnotesChapterSettings.categorizeByTitle ) {
          return parseEndNoteChapter(chapter, endnotesByChapterTitle, theme.properties);
        }
        if(theme?.properties.ePubNotesMode === "END_OF_BOOK" && !theme.properties.ePubEndnotesChapterSettings.categorizeByTitle){
          return parseEndNoteChapter(chapter, endnotes, theme.properties);
        }
        return parseEPubNotIncluded(); 
      }
      
      return parseChapter(
        {
          ...chapter,
          children: body,
        },
        theme.properties,
        false,
        chapterNumber,
        previewerPrefix
      );
    }
  }, [chapter, chapters, theme, book, body, chapterNumber, endnotes, endnotesByChapterTitle]);


  const epubPreviewer = useMemo(() => {
    const shouldRenderInnerLevel = previewerConfigs.l3ClassName;
    if (innerHtmlForChapter) {
      return (
        <div className="previewer">
          <div className={`p-device ${previewerConfigs.l1ClassName}`}>
            <div
              className={`${previewerConfigs.l2ClassName}`}
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
              dangerouslySetInnerHTML={
                shouldRenderInnerLevel
                  ? undefined
                  : { __html: innerHtmlForChapter }
              }
            >
              {shouldRenderInnerLevel && (
                <div
                  className={previewerConfigs.l3ClassName}
                  dangerouslySetInnerHTML={{ __html: innerHtmlForChapter }}
                ></div>
              )}
            </div>
            <img
              width={previewerConfigs.width}
              src={previewerConfigs.deviceUrl}
            />
          </div>
        </div>
      );
    }
  }, [innerHtmlForChapter, previewerConfigs, previewFont, previewFontSize]);

  const styles = useMemo(() => {
    if (theme) {
      return themePropsToCss(theme, usedFonts, AtticusFontBaseUrl, true, previewerPrefix);
    }
  }, [theme]);

  /** pdf previewer configs */

  const {  prev, next } = useMemo(() => {
    return getAdjacentChapterIds(chapter, book);
  }, [chapter, book]);

  const chapterForPreviewer = useMemo(
    () => ({ ...chapter, children: body }),
    [chapter, body]
  );

  const handlePrevChapter = () => {
    if (prev) {
      push({
        hash: `chapter=${prev}`,
      });
      resetSelectedChapters();
    }
  };

  const handleNextChapter = () => {
    if (next) {
      push({
        hash: `chapter=${next}`,
      });
      resetSelectedChapters();
    }
  };

  /** toolbar configs */

  const onChangDevice = async (deviceName: DeviceName) => {
    if (deviceName === "print") {
      setPreviewerConfigs({
        deviceName,
      });
      return;
    }
    const deviceSpec = await getDevicePreview(deviceName);
    if (deviceSpec) {
      const configs = getPreviewerConfigByDevice(
        deviceSpec._deviceName as DeviceName
      );
      setPreviewerConfigs({
        ...configs,
        deviceUrl: deviceSpec.image,
        deviceName: deviceSpec._deviceName,
      });
    }
  };

  const toolbarFontSizeCallBack = (size: number) => setPreviewFontSize(size);

  const toolbarFontCallBack = (font: string) => setPreviewFont(font);

  return (
    <ScrollContainer>
      <div
        className={`previewer-wrapper full ${
          shouldApplyDropCaps(chapter) ? "withDropcap" : ""
        } ${theme?._id}`}
      >
        <style>{styles}</style>
        <Toolbar
          device={previewerConfigs.deviceName}
          ondeviceselect={onChangDevice}
          previewFontSize={toolbarFontSizeCallBack}
          previewFont={toolbarFontCallBack}
        />
        {previewerConfigs.deviceName === "print" && theme && (
            <PrintPreviewer
              theme={theme}
              chapter={chapterForPreviewer}
              prev={prev}
              handlePrevChapter={handlePrevChapter}
              next={next}
              handleNextChapter={handleNextChapter}
              chapterNumber={chapterNumber}
            />
        )}
        {previewerConfigs.deviceName !== "print" && (
          <div className="epub-previewer-container">
            {epubPreviewer}
            <div className="previewer-actions">
              <div className="epub-chapter-navigation">
                <Button type="at-secondary" backgroundColor="green" className="previous-chapter" icon={<VerticalLeftOutlinedIcon />} onClick={handlePrevChapter} disabled={!prev}>
                  Chapter
                </Button>
                <Button type="at-secondary" backgroundColor="green" icon={<VerticalRightOutlinedIcon />} className="next-chapter reverse" onClick={handleNextChapter} disabled={!next}>
                  Chapter
                </Button>
              </div>
            </div>
          </div>
        )}

        {!isSidebar && 
          <div className="previewer-exports">
          <div className="export-buttons">
            <Button type="at-primary" backgroundColor="green" className="export-pdf" onClick={() => handleExitEdit(exportLocalPdf)}>
              Export pdf
            </Button>
            <Button type="at-primary" backgroundColor="green" className="export-epub" loading={epubExporting} onClick={() => handleExitEdit(exportEpub)} disabled={!isOnline}>
              Export ePub
            </Button>
          </div>
        </div>
        }
      </div>
    </ScrollContainer>
  );
});

export default Previewer;