import { Remote, wrap, proxy } from "comlink";
import { ChapterGeneratorApi, RequestChapterCallbackFn, CallbackFn, ChapterGeneratorWorkerStatusResponseData } from "./types";

let chapterGeneratorWorkerInstance: Worker | null = null;
let chapterGeneratorWorker: Remote<ChapterGeneratorApi<ChapterGeneratorWorkerStatusResponseData>> | null = null;

const initiateWorker = (): void => {
  chapterGeneratorWorkerInstance = new Worker("./chapter-generator.worker", {
    type: "module",
  });
  chapterGeneratorWorker = wrap<ChapterGeneratorApi<ChapterGeneratorWorkerStatusResponseData>>(chapterGeneratorWorkerInstance);
};

const terminateWorker = (): void => {
  if (chapterGeneratorWorkerInstance) {
    chapterGeneratorWorkerInstance.terminate();
  }
  chapterGeneratorWorkerInstance = null;
  chapterGeneratorWorker = null;
};

const registerCallbacks = (
  _requestChapterCallback: RequestChapterCallbackFn,
  _onStateChangeCallback: CallbackFn<ChapterGeneratorWorkerStatusResponseData>,
): void => {
  if (!chapterGeneratorWorker) {
    throw new Error("Chapter generator has not been registered! Please register it first before using");
  }

  chapterGeneratorWorker.registerCallbacks(
    proxy(_requestChapterCallback),
    proxy(_onStateChangeCallback)
  );
};

const resetWorker = (): void => {
  terminateWorker();
  initiateWorker();
};

export const register = (): void => {
  initiateWorker();
};

export const useChapterGeneratorAPI = (): {
  registerCallbacks: (_requestChapterCallback: RequestChapterCallbackFn, _onStateChangeCallback: CallbackFn<ChapterGeneratorWorkerStatusResponseData>) => void;
  resetWorker: () => void;
} => ({
  registerCallbacks,
  resetWorker,
});
