import { Moment } from "moment";

export const getAllDatesInMonth = (date: Moment): Moment[] => {
  const daysInMonth = date.daysInMonth();
  const startDate = date.startOf("month");

  return (Array(daysInMonth).fill(startDate.clone()) as Moment[]).map(
    (date: Moment, index) => {
      return date.clone().add(index, "days");
    }
  );
};

export interface DateGrid {
  startDayIndex: number;
  days: Array<Array<Moment | null>>;
}

export const buildDateGrid = (date: Moment): DateGrid => {
  const days = getAllDatesInMonth(date);

  const grid: DateGrid = {
    startDayIndex: days[0].day(),
    days: []
  };

  let weekBuffer: Array<Moment | null> = [];

  days.forEach(day => {
    if (weekBuffer.length === 7) {
      grid.days.push(weekBuffer);
      weekBuffer = [];
    }

    if (weekBuffer.length === 0 && grid.days.length === 0) {
      weekBuffer.push(
        ...(Array(grid.startDayIndex).fill(null))
      );
    }

    weekBuffer.push(day);
  });

  if (weekBuffer.length > 0) {
    grid.days.push(weekBuffer);
    weekBuffer = [];
  }

  return grid;
};
