
import React from "react";
import {
    findNodePath,
    setNodes,
    usePlateEditorState,
    PlateRenderElementProps,
} from "@udecode/plate";
import { Button, Col, Row } from "antd";

import { MyEditor } from "../../../config/typescript";
import { TextMessageContactIcon, TextMessageRecievedIcon, TextMessageSentIcon } from "../../../partials";
import "./styles.scss";
import { TextMessageNode } from "../types";

export const TextMessageComponent = ({
  element,
  children,
}: PlateRenderElementProps) => {
  const editor = usePlateEditorState() as MyEditor;
  const {
    id,
    messageType = "received",
    nextMessageType,
  } = element as TextMessageNode;
  const onClickRecieved = () => {
    const path = findNodePath(editor, element);
    setNodes(editor, { messageType: "received" }, { at: path });
  };

  const onClickSent = () => {
    const path = findNodePath(editor, element);
    setNodes(editor, { messageType: "sent" }, { at: path });
  };

  const onClickContactName = () => {
    const path = findNodePath(editor, element);
    setNodes(editor, { messageType: "contact" }, { at: path });
  };

  let textMessageClass;

  if(messageType !== "contact" || !nextMessageType) {
    textMessageClass = `message ${messageType}`;
  } else {
      textMessageClass = `message ${messageType}-${nextMessageType}`;
  }

  return (
    <Row id={id as unknown as string} className="editor-text-message">
      <Row className={textMessageClass}>
        <Col className="message-content">{children}</Col>

        <Col className="editor-text-message-action">
          <Row>
            <Button
              className="editor-text-message-action-btn"
              onClick={() => onClickRecieved()}
            >
              <TextMessageRecievedIcon active={messageType === "received"} />
            </Button>
            <Button
              className="editor-text-message-action-btn"
              onClick={() => onClickContactName()}
            >
              <TextMessageContactIcon active={messageType === "contact"} />
            </Button>
            <Button
              className="editor-text-message-action-btn"
              onClick={() => onClickSent()}
            >
              <TextMessageSentIcon active={messageType === "sent"} />
            </Button>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};