import React from "react";
import {
  ToolbarButton,
  ToolbarButtonProps,
  useEventPlateId,
  usePlateEditorState
} from "@udecode/plate";
import { doesEditorHaveUndos } from "../utils";
import { BaseOperation } from "slate";
import { UndoIcon } from "../../../../../content/icons";
import { CURRENT_COLOR } from "../../../partials";

export const UndoToolbarButton = (
  props: Omit<ToolbarButtonProps, "icon"> & { onUndo: (change: BaseOperation[]) => void }
) => {
  const editor = usePlateEditorState();

    const hasUndos = editor ? doesEditorHaveUndos(editor) : false;

    return (
      <ToolbarButton
        active={hasUndos}
        icon={
          <UndoIcon
            active={hasUndos}
            color={CURRENT_COLOR}
          />
        }
        onMouseDown={async (event) => {
          if (!editor) return;

          event.preventDefault();
          event.stopPropagation();

          if (hasUndos) {
            const change = editor.history.undos[editor.history.undos.length - 1];
            editor.undo();
            props.onUndo(change);
          }
        }}
        {...props}
      />
    );
  };
