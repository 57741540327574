import React, { FunctionComponent, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import * as querystring from "query-string";
import { observer } from "mobx-react";

// 3rd party Components
import { Formik } from "formik";
import { Form, FormItemProps, Input, message } from "antd";

// api
import { AtticusClient } from "../../api/atticus.api";

// stores
import useRootStore from "../../store/useRootStore";

import miniLogo from "../../content/images/miniLogo.png";
import {
  BackgroundColor,
  Button,
  ButtonSize,
  ButtonType,
} from "../../components/Shared/Buttons/Button";
import { AtticusTextField } from "../../components/Shared/Form/Input";
import { AtticusFullLogo } from "../../content/icons";
import { AtticusPasswordField } from "../../components/Shared/Form";

// import "./auth.scss";

const styles = {
  miniLogo: {
    height: 100,
    width: 100,
  },
};

// types
interface FormErrors {
  newPassword?: string;
  code?: string;
}

export const ResetPassword: FunctionComponent = observer(() => {
  const location = useLocation<Location>();
  const history = useHistory();
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const queryParams = querystring.parse(location.search);

  const onFinish = async (values) => {
    try {
      setIsSubmitting(true);
      const resp = await AtticusClient.ResetPassword(
        queryParams.userId,
        queryParams.code,
        values.newPassword
      );
      history.push("/auth/sign-in");
    } catch (e: any) {
      setIsSubmitting(false);
      if (e.response && e.response.status === 406) {
        const validationErrors = e.response.data.errors;
        Object.keys(validationErrors).forEach((k) => {
          form.setFields([{ name: k, errors: [validationErrors[k].message] }]);
        });
        message.error("Please fix the highlighted fields", 4);
      } else {
        message.error("Error resetting your password");
      }
    } finally {
      setIsSubmitting(false);
      form.setFieldsValue({});
    }
  };

  return (
    <div className="center-body">
      <div className="black-bar">
        <AtticusFullLogo />
      </div>
      <Form
        form={form}
        initialValues={{ newPassword: "" }}
        onFinish={onFinish}
        layout="vertical"
        validateTrigger={["onBlur", "onInput"]}
      >
        <div className="auth-card">
          <div className="text-center inner-s">
            <h1 className="inner-xs">Reset your password</h1>
            <p>Enter a new password and then repeat it.</p>
          </div>
          <div className="inner-s">
            <Form.Item
              className="at-form-item"
              label="New Password"
              name="newPassword"
              rules={[{ required: true, message: "New password required" }]}
            >
              <AtticusPasswordField />
            </Form.Item>
            <Form.Item
              className="at-form-item"
              label="Confirm Password"
              name="confirm-password"
              dependencies={["newPassword"]}
              rules={[
                { required: true, message: "Confirm password Required" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Password do not match"));
                  },
                }),
              ]}
            >
              <AtticusPasswordField />
            </Form.Item>
          </div>
          <div className="inner-xs">
            <Button
              block
              type={ButtonType.PRIMARY}
              backgroundColor={BackgroundColor.BLUE}
              size={ButtonSize.LARGE}
              htmlType="submit"
              loading={isSubmitting}
            >
              Save new password
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
});