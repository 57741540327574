import { union, isEqual, find } from "lodash";
import { notification } from "antd";

//IndexedDB
import { db } from "../db/bookDb";

// API
import { AtticusClient } from "../api/atticus.api";
import { Book } from "../types/book";
import { Chapter } from "../types/chapter";


// Offline helpers
import {
	GetBookFromDB,
	SaveBookToDB,
	UpdateBookInDB,
	DeleteChaptersFromDB,
	UpdateChapterBodyInIDB,
	SaveChapterTemplateToDB,
	GetAllThemesFromIDB,
	SaveNewThemeInIDB,
	SaveThemesInIDB,
	OverwriteThemeInIDB,
  DeleteThemeFromIndexedDB,
  GetChapterTemplates,
  FilterChapterMeta,
  UpdateChapterMeta
} from "./offline.book.helpers";

// GetErrorBook <--- error visuzalition

async function SaveBookToServer(book: Book): Promise<void> {
	try {
		const res = await AtticusClient.PutBook({
			...book,
			chapters: [
				...(book.chapters || []),
				...(book.frontMatter || []),
			]
		});
		await UpdateBookInDB(book._id, {
			lastSuccessfulSync: res.timestamp,
			allChangesSynced: true,
		});
	} catch (e: any) {
		console.log(`ERROR SAVING BOOK ${book._id} - ${book.title}`, e);
		throw e;
	}
}

async function PatchBook(bookId: string, updates: Partial<Book>): Promise<void> {
	try {
    delete updates["__v"];
    delete updates["createdAt"];
    delete updates["lastUpdateAt"];

		const res = await AtticusClient.PatchBook(bookId, updates);
		await UpdateBookInDB(bookId, {
			lastSuccessfulSync: res.timestamp,
			allChangesSynced: true,
		});
	} catch (e: any) {
		console.log(`ERROR PATCHING BOOK ${bookId}`, e);
		throw e;
	}
}

async function PatchChapter(bookId: string, chapter: Chapter): Promise<boolean> {
	try {
		const parsedUpdates: Partial<Chapter> = { ...chapter };
		delete parsedUpdates["_id"];
		delete parsedUpdates["__v"];
		delete parsedUpdates["allChangesSynced"];
		delete parsedUpdates["bookId"];
		delete parsedUpdates["lastSuccessfulSync"];

		const res = await AtticusClient.PatchChapter(bookId, chapter._id, parsedUpdates);

		// db.failedChapters.delete(bookId);

		await UpdateChapterBodyInIDB(chapter._id, {
			lastSuccessfulSync: res.timestamp,
			allChangesSynced: true,
		});
		return true;
	} catch (e: any) {
		console.log({ e });
    return false;
	}
}

async function PutChapter(chapter: Chapter): Promise<boolean> {
	try {
		const res = await AtticusClient.PutChapter(chapter);
		await UpdateChapterBodyInIDB(chapter._id, {
			lastSuccessfulSync: res.timestamp,
			allChangesSynced: true,
		});
		return true;
	} catch (e: any) {
		console.log({ e });
		throw e;
	}
}

export async function SaveOfflineBookToServer(bookId: string): Promise<boolean> {
	const fullOfflineBook = await GetBookFromDB(bookId, { chapterBodies: true, chapterMeta: true });
	// remove the failed book from the db to remove error
	// db.failedBooks.delete(bookId);

	if (!fullOfflineBook) return false;

	await SaveBookToServer(fullOfflineBook);

	return true;
}

export async function SaveServerBookToDB(bookId: string): Promise<boolean> {
	const serverBook = await AtticusClient.GetBook(bookId);

	if (!serverBook) return false;

	await SaveBookToDB(serverBook);

	return true;
}

export async function syncBook(bookId: string): Promise<boolean> {
	const allPromises: Promise<Book | undefined>[] = [];
	const serverBook = await AtticusClient.GetBook(bookId);
    const offlineBook = await GetBookFromDB(bookId, { chapterMeta: true, chapterBodies: true });
	if (!serverBook || !offlineBook) return false;

	let updateServer = false;
	let updateLocal = false;
	let newBook: Book | null = null;

	if (offlineBook.allChangesSynced === undefined) offlineBook.allChangesSynced = true;

	// Filter out deleted chapters from offlineBook chapterIds
	if (serverBook.deletedChapterIds) {
    const isValidChapter = (id) => serverBook.deletedChapterIds?.indexOf(id) === -1 && serverBook.chapters && serverBook.chapters.findIndex((chap) => chap._id === id) > -1;

		offlineBook.chapterIds = offlineBook.chapterIds.filter(isValidChapter);
		offlineBook.frontMatterIds = offlineBook.frontMatterIds.filter(isValidChapter);
	}

	if (serverBook.lastUpdateAt !== undefined && offlineBook.lastSuccessfulSync !== undefined) {

		if (serverBook.lastUpdateAt === offlineBook.lastSuccessfulSync && offlineBook.allChangesSynced) {
			// No changes
		}

		// The local copy is more recent
		if (offlineBook.lastSuccessfulSync === serverBook.lastUpdateAt) {
			updateServer = true;
			newBook = offlineBook;

			// db.failedBooks.delete(offlineBook._id);
			// db.failedChapters.clear();

		}
		// server copy is more recent
		else if (serverBook.lastUpdateAt > offlineBook.lastSuccessfulSync) {
			updateLocal = true;
			newBook = serverBook;
			if (!offlineBook.allChangesSynced) {
				updateServer = true;
			}
		}
	} else {
		// there are first-time unsynced changes on the client, have to procede to conflict resolution
		if (offlineBook.lastSuccessfulSync === undefined && !offlineBook.allChangesSynced) {
			updateServer = true;
			updateLocal = true;

			// the selection of server book for thie scenario was a bit arbitary. Thinking being the server should take precendence over client when there's ambiguity
			newBook = serverBook;
		}
	}

    //inconsistency with chapters and chaptersIds - set chapter ids from chapters to chapterIds
    offlineBook.chapterIds = offlineBook.chapters?.map(d => d._id) || [];

    const sameIds = isEqual(offlineBook.chapterIds, serverBook.chapterIds);
	let didChapterSync= false;
	if ((updateLocal && updateServer) || !sameIds) {
		didChapterSync=true;
		await syncChapters(offlineBook, serverBook, Boolean(newBook));
	} else if (updateLocal && newBook && offlineBook.allChangesSynced) {
		// Save copy of the server book to the server
		await SaveBookToDB(newBook);
	} else if (updateServer && newBook && !offlineBook.allChangesSynced) {
		// Save copy of the client book to the server
		await SaveBookToServer(newBook);
	}
	if(!didChapterSync){
		await syncChapters(offlineBook,serverBook, Boolean(newBook));
	}

	// This is a one-way sync issue since offline deletes are not supported
	// clean-up deleted chapters
	if (serverBook.deletedChapterIds) await DeleteChaptersFromDB(serverBook.deletedChapterIds);

	return true;
}

async function syncChapters(offlineBook: Book, serverBook: Book, newBook: boolean) {
    let mergedChapterIds: string[] = [];

    const offlineBookAllChapterIds = [
      ...offlineBook.frontMatterIds,
      ...offlineBook.chapterIds
    ];

    const serverBookAllChapterIds = [
      ...serverBook.frontMatterIds,
      ...serverBook.chapterIds
    ];

    const withoutDeeleteChapterIDs = offlineBookAllChapterIds.filter(id => !(serverBook.deletedChapterIds || []).includes(id));

    mergedChapterIds = union(withoutDeeleteChapterIDs, serverBookAllChapterIds);

    const waitforAllChanges: Promise<unknown>[] = [];
    const newChapterIds: string[] = [];

    if (offlineBook.chapters && serverBook.chapters) {

        for (const chapterId of mergedChapterIds) {
            const offlineChapter = offlineBook.chapters?.find(chapter => chapter._id === chapterId);
            const serverChapter = serverBook.chapters?.find(chapter => chapter._id === chapterId);

            // no offline chapter, save server chapter
            if (!offlineChapter && serverChapter) {
                newChapterIds.push(serverChapter._id);
                waitforAllChanges.push(UpdateChapterBodyInIDB(serverChapter._id, serverChapter));
                continue;
            }

            // no server chapter, sync chapter to server
            if (!serverChapter && offlineChapter) {
				newChapterIds.push(offlineChapter._id);
				waitforAllChanges.push(PutChapter(offlineChapter));
				continue;
            }

            // chapter is available on both server and offline
            if (serverChapter && offlineChapter) {
                // make sure the allChangesSynced variable has a value
                if (offlineChapter.allChangesSynced === undefined) offlineChapter.allChangesSynced = true;

                // all changes on the client are synced and the server's version is more recent
                if (offlineChapter.allChangesSynced && serverChapter.lastUpdateAt !== offlineChapter.lastSuccessfulSync) {
                    newChapterIds.push(chapterId);
                    waitforAllChanges.push(UpdateChapterBodyInIDB(chapterId, serverChapter));
                    continue;
                }

                if (!offlineChapter.allChangesSynced && serverChapter.lastUpdateAt && offlineChapter.lastSuccessfulSync) {
                    // no changes on the server beyond the last sync
                    if (serverChapter.lastUpdateAt === offlineChapter.lastSuccessfulSync) {
                        const chapterToPatch = {
                          ...offlineChapter
                        };

                        delete chapterToPatch.createdAt;
                        delete chapterToPatch.lastUpdateAt;

                        newChapterIds.push(chapterId);
                        waitforAllChanges.push(PatchChapter(offlineBook._id, chapterToPatch));
                        continue;
                    }

                    // both the server and the client has changes, the chapter has a conflict
                    // go to conflict resolution mode
                    if (serverChapter.lastUpdateAt > offlineChapter.lastSuccessfulSync) {
                        newChapterIds.push(chapterId);
						//	avoid conflict chapter creation for conflict chapters
						if(chapterId.includes("_conflict")) continue;
						//	check if a conflict chapter already exists for chapter */
						const conflictChapterId = chapterId + "_conflict";
						const conflictChapterTitle = `${offlineChapter.title} (CONFLICT)`;
						const hasConflictChapterInServer = serverBook.chapterIds.some((chapterId) => chapterId === conflictChapterId);
						const hasConflictChapterInLocal = withoutDeeleteChapterIDs.some((chapterId) => chapterId === conflictChapterId);
						if(hasConflictChapterInServer){
							//	if a conflict chapter exists in the server book, replace the content of the conflict chapter in server book with
							//	content from the original chapter from the offline book and update the server book
							waitforAllChanges.push(UpdateChapterBodyInIDB(conflictChapterId, {...offlineChapter, _id: conflictChapterId, title: conflictChapterTitle}));
							waitforAllChanges.push(PatchChapter(offlineBook._id, {...offlineChapter, _id: conflictChapterId, title: conflictChapterTitle}));
						}else if(hasConflictChapterInLocal){
							//	if a conflict chapter exists in the offline book, replace the content of the conflict chapter in the offline book
							//	with the content of the original chapter in the offline book and update the server book
							waitforAllChanges.push(UpdateChapterBodyInIDB(conflictChapterId, {...offlineChapter, _id: conflictChapterId, title: conflictChapterTitle}));
							waitforAllChanges.push(PutChapter({...offlineChapter, _id: conflictChapterId, title: conflictChapterTitle}));
						}else{
							/** temporary commented new conflict chapter creation */
							// create new conflict chapter and sync with server
							// const conflictChapter: Chapter = {
							// 	...offlineChapter,
							// 	_id: conflictChapterId,
							// 	title: `${offlineChapter.title} (CONFLICT)`
							// };
							// newChapterIds.push(conflictChapterId);
							// waitforAllChanges.push(PutChapter(conflictChapter));
						}
						// replace content of the offline chapter with content from the server -
                        waitforAllChanges.push(UpdateChapterBodyInIDB(chapterId, {...serverChapter, lastSuccessfulSync: serverChapter.lastUpdateAt, allChangesSynced: true}));
						// always save the offline chapter being replaced in the conflict collection to minimize data loss
						waitforAllChanges.push(AtticusClient.SaveConflictChapter(offlineChapter, "CONFLICT"));
                        continue;
                    }
                }
            }
            newChapterIds.push(chapterId);
        }

        // Save the new chapter structure online
        if (newBook) {
            await Promise.all(waitforAllChanges);
            if (!isEqual(newChapterIds, serverBookAllChapterIds) || (!isEqual(newChapterIds, offlineBookAllChapterIds))) {
                const chapterDeltas: Partial<Book> = {
                    frontMatterIds: [],
                    chapterIds: [],
                };

                newChapterIds.forEach((newChapterId) => {
                    if (
                      serverBook.frontMatterIds.includes(newChapterId) ||
                      offlineBook.frontMatterIds.includes(newChapterId)
                    ) {
                      if (chapterDeltas.frontMatterIds !== undefined) {
                        chapterDeltas.frontMatterIds.push(newChapterId);
                      } else {
                        chapterDeltas.frontMatterIds = [newChapterId];
                      }
                    } else {
                      if (chapterDeltas.chapterIds !== undefined) {
                        chapterDeltas.chapterIds.push(newChapterId);
                      } else {
                        chapterDeltas.chapterIds = [newChapterId];
                      }
                    }
                });

                await PatchBook(offlineBook._id, chapterDeltas);
                await SaveServerBookToDB(offlineBook._id);
            }
        }
    }
}

function allSkippingErrors(promises) {
    const handleErr = (err) => {
        if(err && err.response.status === 500){
            notification.error({
                message: "Book couldn't be loaded",
                //description: "The book you are trying to load seems to exceed the limit",
            });
        }
        console.log({err});
        return null;
    };
    return Promise.all(
        promises.map(p => p.catch(handleErr))
    );
}

export async function syncData(): Promise<void> {

	const offlineBooks = await db.books.toArray();
	const bookResponse = await AtticusClient.GetBooks();

	const serverBooks = bookResponse ? bookResponse.books : [];

	await db.books.bulkDelete(bookResponse.deletedBookIds);

	const waitForPromises: Promise<boolean>[] = [];

	// TODO: Handle Deleted Books

	// Scenario 1: Check for books present on the client is not present on the server
	for (const book of offlineBooks as Book[]) {
		if (serverBooks.findIndex((cur) => cur._id === book._id) === -1) {
			waitForPromises.push(SaveOfflineBookToServer(book._id));
		}
	}

	// Scenario 2: Check for books present on the server but not present on clients
	for (const book of serverBooks as Book[]) {
		if (offlineBooks.findIndex((cur) => cur._id === book._id) === -1) {
			waitForPromises.push(SaveServerBookToDB(book._id));
		}
	}

	// Scenario 3: The books are present on both server and client
	for (const book of serverBooks as Book[]) {
		const index = offlineBooks.findIndex((cur) => cur._id === book._id);
		if (index !== -1) {
            waitForPromises.push(syncBook(book._id));
		}
	}

	await allSkippingErrors(waitForPromises);
}

// Chapter Template Library
export async function SaveTemplateToDB(): Promise<boolean> {
	const templates = await AtticusClient.GetChapterTemplates();

	if (!templates) return false;

	for(const temp of templates) {
		await SaveChapterTemplateToDB(temp);
	}

	return true;
}

export async function syncChapterTemplateChangesWithLocalChapters(templateId: string, bookId: string, shouldUpdateAllBooks: boolean): Promise<void> {
	const template = await GetChapterTemplates(templateId);
	if(!template) return;
	const filter = {templateId};
	if(!shouldUpdateAllBooks) filter["bookId"] = bookId;
	const localChapterMetasUsingTemplate = await FilterChapterMeta(filter);
	localChapterMetasUsingTemplate.map(async chapter => {
		await UpdateChapterMeta(chapter._id, {
			type: template.type,
			title: template.title,
			titleScale: template.titleScale,
			subtitle: template.subtitle,
			image: template.image,
			numbered: template.numbered,
			fullpageImage: template.fullpageImage,
			configuration: template.configuration,
			lastUpdateAt: template.lastUpdateAt,
		});
		await UpdateChapterBodyInIDB(chapter._id, {children: template.children});
	});
}


//Theme Sync
export async function SyncThemes(onlineThemes: IThemeStore.ThemeResponse[]): Promise<void> {

	// declare themes
	const offlineThemes = await GetAllThemesFromIDB();
	const serverThemes = onlineThemes;

	// Do nothing if there's no custom themes
	if (offlineThemes.length === 0 && serverThemes.length === 0)
		return;

	// if offline themes is empty save server themes to IDB
	if (!(offlineThemes.length > 0))
		return await SaveThemesInIDB(serverThemes);


	// Do sync if both IDB and server has themes
	const allPromises: Promise<any>[] = [];

  // Check if the theme has been deleted in the server, if so, delete from IndexedDB
  const deletePromises: Promise<void>[] = [];

  offlineThemes.forEach((offlineTheme) => {
    const onlineTheme = find(onlineThemes, { _id: offlineTheme._id });

    if (!onlineTheme) {
      deletePromises.push(DeleteThemeFromIndexedDB(offlineTheme._id));
    }
  });

  await Promise.all(deletePromises);

	for (let i = 0; i < serverThemes.length; i++) {
		const offlineTheme = find(offlineThemes, { _id: serverThemes[i]._id });

		//if a new theme is available in server, save it to IDB
		if (!offlineTheme)
			allPromises.push(SaveNewThemeInIDB(serverThemes[i]));
		else {
      // check for allChangesSynced flag to save local offline custom theme changes to server
      if (!offlineTheme.allChangesSynced && !offlineTheme.isPredefinedTheme)
        allPromises.push(
          AtticusClient.SaveTheme(offlineTheme).then(
            async (resp) =>
              await OverwriteThemeInIDB(offlineTheme._id, {
                allChangesSynced: true,
              })
          )
        );

      // For pre-defined themes, the only allowed sync is favouriting
      if (!offlineTheme.allChangesSynced && offlineTheme.isPredefinedTheme) {
        const afterHook = () => {
          OverwriteThemeInIDB(offlineTheme._id, { allChangesSynced: true });
        };

        allPromises.push(
          offlineTheme.isFavourite
            ? AtticusClient.AddThemeToFavourites(offlineTheme._id).then(
                afterHook
              )
            : AtticusClient.RemoveThemeFromFavourites(offlineTheme._id).then(
                afterHook
              )
        );
      }
    }
	}

	await Promise.all(allPromises);
}
