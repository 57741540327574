import { AutoformatRule } from "@udecode/plate";

export const autoformatPunctuation: AutoformatRule[] = [
  {
    mode: "text",
    match: " - ",
    format: " – ",
  },

  /*** {autoformatPunctuation} from @udecode/plate has four punctuation formattings.
   * Removed the <> brackets related ones to fix AT-1118 and moved the other two here
  */
  {
    mode: "text",
    match: "--",
    format: "\u2014"
  }, 
  {
    mode: "text",
    match: "...",
    format: "…"
  },
];
