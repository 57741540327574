export const tocNcx = `<?xml version="1.0" encoding="UTF-8"?>
<% 
var _index = 1;  
function findBlockLink(tocItem) {
  if (!tocItem) return;

  const contentBlock = content.find((item) => item.blockId === tocItem.itemId || item.blockId === tocItem.pointToChapterId);
  if (!contentBlock) { 
    throw new Error('No content block found for ' + tocItem.itemId);
  }

  const hasTocItem = toc.map((e) => e.itemId).indexOf(contentBlock.blockId) !== -1;
  return { link: contentBlock.filename, hasTocItem };
}


function renderItem(tocItem) {
    if(!tocItem.excludeFromToc && !tocItem.beforeToc){ 
        const playOrder = _index;
        const noContentBlock = ["volume", "part"].includes(tocItem.chapterType);
        const { link, hasTocItem } = findBlockLink(tocItem);
       
        if (!(noContentBlock && hasTocItem)) _index = _index + 1;
    %>
    <navPoint id="content_<%= tocItem.itemId %>" playOrder="<%= playOrder %>" class="chapter">
        <navLabel>
            <text><%= (tocItem.chapterNumber ? tocItem.chapterNumber + ". " : "" ) + (tocItem.title || "Chapter " + tocItem.chapterNumber )%></text>
        </navLabel>
        <% if(link) { %>  <content src="<%= link %>" /> <% }%>
        <% 
                    if(tocItem.children && tocItem.children.length) {  
                        tocItem.children.forEach(renderItem);
                    }
        %>
    </navPoint>
<%      
    }
} 
%>
<ncx xmlns="http://www.daisy.org/z3986/2005/ncx/" version="2005-1">
    <head>
        <meta name="dtb:uid" content="<%= id %>" />
        <meta name="dtb:generator" content="epub-gen"/>
        <meta name="dtb:depth" content="1"/>
        <meta name="dtb:totalPageCount" content="0"/>
        <meta name="dtb:maxPageNumber" content="0"/>
    </head>
    <docTitle>
        <text><%= title %></text>
    </docTitle>
    <docAuthor>
        <text><%= author %></text>
    </docAuthor>
    <navMap>
        <% toc.forEach((tocItem, index) => !tocItem.parentItemId ? renderItem(tocItem, index) : undefined) %>
    </navMap>
</ncx>`;