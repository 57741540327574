import React, { FunctionComponent } from "react";
import { Form, Input, FormItemProps } from "antd";

import SelectAuthor from "./select-author";
import SelectProject from "./select-project";

const commonFormItemProps = {
  labelAlign: "left",
  colon: false,
} as FormItemProps;

interface NewBoxsetDetailsProps {
  values: { title: string, author: string[], project: string };
  handleChange: (obj) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  resetField?: boolean;
}

export const BoxsetDetailsForm: FunctionComponent<NewBoxsetDetailsProps> = ({ values, handleChange, setFieldValue, resetField }: NewBoxsetDetailsProps) => {
  return (
    <Form layout="vertical">
      <div className="inner-s">
        <Form.Item label="Title" {...commonFormItemProps} className="ant-input-extended">
          <Input placeholder="Title" name="title" onChange={handleChange} value={values.title} size="large" />
        </Form.Item>
        <Form.Item name="author" label="Author" {...commonFormItemProps} className="ant-input-extended ant-select-extended">
          <SelectAuthor placeholderText={"Author"} onSelect={v => setFieldValue("author", v)} value={values.author} resetField={resetField} />
        </Form.Item>
        <Form.Item name="project" label="Project" {...commonFormItemProps} className="ant-input-extended ant-select-extended">
          <SelectProject placeholderText={"Project Name"} onSelect={v => setFieldValue("project", v)} value={values.project} resetField={resetField} />
        </Form.Item>
      </div>
    </Form>
  );
};