import {
  createPluginFactory,
} from "@udecode/plate-core";
import { VerseComponent } from "./components/verseComponent";

export const ELEMENT_VERSE = "code_block";

export const createVersePlugin = createPluginFactory({
  key: ELEMENT_VERSE,
  isElement: true,
  component: VerseComponent,
  deserializeHtml: {
    rules: [
      { validNodeName: ["CODE"] },
    ]
  },
  options: {
    hotkey: ["mod+opt+8", "mod+shift+8"],
  },
});
