import React, { FunctionComponent, useMemo, useState } from "react";
import { Form, Input, List, Badge } from "antd";
import Fuse from "fuse.js";
import { FilterOutlined } from "@ant-design/icons";
import useRootStore from "../../store/useRootStore";
import { toJS } from "mobx";
import { debounce } from "lodash";

interface NewBoxSetBookSelectionProps {
  onBookSelectionChange: (selectedBookIds: Array<string>) => void;
}

const NewBoxSetBookSelection: FunctionComponent<NewBoxSetBookSelectionProps> = ({ onBookSelectionChange }) => {
  const { books } = useRootStore().shelfStore;
  // <key, value> = <bookId, order>
  const [selectedBookIds, setSelectedBookIds] = useState<Record<string, number>>({});

  const [filteredBooks, setFilteredBooks] = useState(books);

  const fuse = useMemo(() => new Fuse(toJS(books), { keys: ["title"] }), [books]);

  const filterBooks = debounce((searchTerm: string) => {
    if (!searchTerm.trim()) {
      setFilteredBooks(books);
      return;
    }

    const results = fuse.search(searchTerm);
    setFilteredBooks(results.map((result) => result.item));
  }, 200);

  const handleBookClick = (bookId: string) => {
    // sort the books
    const prioritizedBookIds = Object.keys(selectedBookIds)
      .map((bookId) => ({ bookId, priority: selectedBookIds[bookId] }))
      .sort((a, b) => (a < b ? -1 : 1))
      .map(({ bookId }) => bookId);
    const nextSelectedBookIds: typeof selectedBookIds = {};

    let removed = false;
    let index = 1;
    for (const currentBookId of prioritizedBookIds) {
      // remove the book if it was previously selected
      if (currentBookId === bookId) {
        removed = true;
        continue;
      }

      nextSelectedBookIds[currentBookId] = index;
      index += 1;
    }

    // insert the book if it was not previously selected
    if (!removed) {
      nextSelectedBookIds[bookId] = index;
      prioritizedBookIds.push(bookId);
    } else {
      prioritizedBookIds.splice(prioritizedBookIds.indexOf(bookId), 1);
    }

    // notify the parent & update the internal state
    onBookSelectionChange(prioritizedBookIds);
    setSelectedBookIds(nextSelectedBookIds);
  };

  return (
    <Form layout="vertical">
      <div className="book-selection"  >
        <Form.Item label="Search" className="ant-input-extended" style={{marginBottom:0}}>
          <Input  placeholder="Search" onChange={(event) => filterBooks(event.target.value)} />
        </Form.Item>
        <div>
          <List itemLayout="vertical">
            {filteredBooks.map(({ _id, title }) => {
              const selected = !!selectedBookIds[_id];
              const selectionOrder = selectedBookIds[_id];

              return (
                <List.Item key={_id} onClick={() => handleBookClick(_id)} style={{ ...(selected && { color: "#3568BA" }) }}>
                  <div className="book-item" style={{cursor:"pointer"}}>
                    <div>{title}</div>
                    <div>{selected && <Badge count={selectionOrder} style={{ backgroundColor: "#3568BA" }} size="small" />}</div>
                  </div>
                </List.Item>
              );
            })}
          </List>
        </div>
      </div>
    </Form>
  );
};

export default NewBoxSetBookSelection;
