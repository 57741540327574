import React, { useState } from "react";

import { Row, Col, Modal } from "antd";
import useRootStore from "../../../store/useRootStore";
import { observer } from "mobx-react";
import { ExportDocxIcon } from "../../../content/icons";
import { Button } from "../../Shared/Buttons";
import { WordCount } from "./WordCount";
import SprintTimer from "../../SprintTimer/SprintTimerToolbar";
import { Dialog } from "../../Shared/Modal";

export const BottomBar: React.FC = observer(() => {
  const { chapterTemplateView } = useRootStore().appStore;
  const { saving, book, exportBook } = useRootStore().bookStore;
  const [docxExporting, setDocxExporting] = useState(false);
  const exportDocx = async () => {
    setDocxExporting(true);
    try {
      const resp = await exportBook(book._id, "docx");
      window.open(resp.url, "_blank");
    } catch (e: any) {
      return Dialog({
        open: false,
        title: "Export failed",
        content: (
          <div>
            <p>
              Sorry about that! Your book wasn&apos;t able to be exported due to
              an error in the document. Use the link below to learn more about
              why this might happen and what you can do to resolve the error.
            </p>
            <a href="https://www.atticus.io/troubleshooting-export-errors/">
              https://www.atticus.io/troubleshooting-export-errors/
            </a>
          </div>
        ),
        rightBtn: {
          children: "Ok",
          type: "at-primary",
          danger: true,
          onClick: () => setDocxExporting(false),
        },
        onCancel() {
          setDocxExporting(false);
        },
      });
    }
    setDocxExporting(false);
  };

  return (
    <div className="bottom-bar-wrapper">
      <Row className="atticus-editor-bottom-bar">
        <Col flex="auto" className="save-status">
          <span>{saving}</span>
        </Col>
        <Col className={`export-docx divider ${chapterTemplateView ? "disabled-item" : ""}`}>
          <Button
            type="at-ghost"
            icon={<ExportDocxIcon />}
            className="export-button"
            loading={docxExporting}
            onClick={exportDocx}
          >
            Export docx
          </Button>
        </Col>
        <Col className={`timer divider ${chapterTemplateView ? "disabled-item" : ""}`}>
          <SprintTimer />
        </Col>
        <Col className="word-count divider">
          <WordCount />
        </Col>
      </Row>
    </div>
  );
});
