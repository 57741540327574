import {
    createPluginFactory,
    onKeyDownToggleMark,
  } from "@udecode/plate";
  
  export const MARK_MONOSPACE = "monospace";
  
  export const createMonospacePlugin = createPluginFactory({
    key: MARK_MONOSPACE,
    isLeaf: true,
    handlers: {
      onKeyDown: onKeyDownToggleMark,
    },
    inject:{
      props:{
        transformStyle:() => ({fontFamily: "monospace"})
      }
    }
  });
  