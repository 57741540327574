import React from "react";
import { Form, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Draggable } from "react-beautiful-dnd";
import { Button } from "../../../../Shared/Buttons";
import { ProfileDeleteIcon } from "../../../../../content/icons";

export const SocialMediaAccount = ({ account, removeItem, index }) => {
  const AddOnBefore = () => (
    <div>
      <p className="sm-item-name">{account.name}</p>
    </div>
  );

  return (
    <Draggable key={account.id} draggableId={account.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Form.Item
            name={account.id}
            rules={[
              {
                required: true,
                message: "Input something!",
              },
            ]}
            initialValue={account.username}
            className="sm-item"
          >
            <Input
              addonBefore={<AddOnBefore />}
              addonAfter={
                <Button type="at-ghost" icon={<ProfileDeleteIcon color={"#171d21"} />} onClick={() => removeItem(account.id)} />
              }
              className={`sm-input ${snapshot.isDragging ? "sm-dragged" : "sm-static"}`}
            />
          </Form.Item>
        </div>
      )}
    </Draggable>
  );
};
