import React, { FC, ReactNode } from "react";
import { Tabs, TabsProps, TabPaneProps } from "antd";

interface TabItemProps extends Omit<TabPaneProps, "tab" | "icon"> {
  key: string;
  label: React.ReactNode;
}

interface AtticusTabsProps extends Omit<TabsProps, "type" | "items"> {
  variant: "primary-blue" | "primary-mint";
  items: TabItemProps[]
}

const { TabPane } = Tabs;

export const AtticusVerticalTab: FC<AtticusTabsProps> = ({items, ...props}) => {

  const tabProps : TabsProps  = {
    ...props,
    tabPosition: "left",
    className: `at-vertical-tabs at-${props.variant}`,
  };

  return (
    <Tabs {...tabProps}>
      {items.map((item) => (
        <TabPane key={item.key} tab={item.label} forceRender>
          {item.children}
        </TabPane>
      ))}
    </Tabs>
  );
};