import React from "react";
import {
  Tooltip,
  Checkbox,
  Form,
  Row,
  Col,
  FormInstance,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { observer } from "mobx-react";
import { AtticusSelect, AtticusSlider } from "../../Shared/Form";

import { fonts, FontVariant, getFontFamilyName } from "@surge-global-engineering/css-generator";

interface TypographySettingsProps {
  formFields: IThemeStore.ThemeFields;
  onValuesChange: (fields: Partial<IThemeStore.ThemeFields>) => void;
}

const linespacingmarks = {
  1: {
    label: "Single",
  },
  1.25: {
    label: "1.25",
  },
  1.5: {
    label: "1.5",
  },
  1.75: {
    label: "1.75",
  },
  2: {
    label: "Double",
  },
};

const fontsizemarks = {
  9: {
    label: "9pt",
  },
  10: {
    label: "10pt",
  },
  11: {
    label: "11pt",
  },
  12: {
    label: "12pt",
  },
  13: {
    label: "13pt",
  },
  14: {
    label: "14pt",
  },
  15: {
    label: "15pt",
  },
  16: {
    label: "16pt",
  },
  17: {
    label: "17pt",
  },
  18: {
    label: "18pt",
  },
};

const TypographySettings = ({ formFields, onValuesChange }: TypographySettingsProps) => {

  const handleLargeTrim = (e: CheckboxChangeEvent) => {
    onValuesChange(e.target.checked ? {
      fontsize: 18,
      linespacing: 1.5,
      firstline: "spaced",
      printBaseFont: "OpenSansLT",
      isLargeTrim: true,
    } : {
      isLargeTrim: false,
    });
  };

  return (
    <div className="theme-section-tab-content">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="printBaseFont"
            label="Body font"
          >
            <AtticusSelect
              placeholder="Select font"
              className={"EBGaramond"}
              selectOptions={fonts
                .filter(font => font.availableForPrint) //filter out "print" specific fonts only
                .map(font => ({
                classNames: getFontFamilyName(font.id, FontVariant.regular),
                value: font.id,
                label: <>
                  {font.name}{" "}
                  {font.additionalInfo && (
                    <span className="font-additional-info">
                      ({font.additionalInfo})
                    </span>
                  )}
                </>
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="fontsize" label="Font size">
            <AtticusSlider
              className="at-slider-contained"
              min={9}
              max={18}
              marks={fontsizemarks}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="linespacing" label="Line spacing">
            <AtticusSlider
              className="at-slider-contained"
              min={1}
              max={2}
              step={0.05}
              marks={linespacingmarks}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className="flex center-vertical">
            <Form.Item
              name="isLargeTrim"
              className="large-print-checkbox"
              valuePropName="checked"
            >
              <Checkbox
                checked={formFields.isLargeTrim}
                onChange={handleLargeTrim}
              >
                <Tooltip
                  title={
                    "Selecting \"Large Print\" will automatically adjust your font, font size, line spacing and text alignment to meet international standards for large print books."
                  }
                >
                  <span>Large print</span>
                </Tooltip>
              </Checkbox>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default observer(TypographySettings);
