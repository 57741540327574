import { getSocialMediaIcons } from "../../../components/Previewer/print/configs/social-media-icons";

export const parseSocialProfile = (
  imageNode: ISlateStore.SlateProfileLink
): string => {
  let smIconSize: string;
  let parsedNode =
    "<div class='social-profile social-profile-flow-middle profile-links'>";

  imageNode.url.map((urlItem) => {
    const { name, username, iconSize } = urlItem;

    if (iconSize === "medium") {
      smIconSize = name + "-Medium";
    } else if (iconSize === "small") {
      smIconSize = name + "-Small";
    } else {
      smIconSize = name + "-Large";
    }

    const getSM = getSocialMediaIcons(smIconSize);
    const getSMName = getSM?.smLink;

    if (iconSize === "small") {
      parsedNode += `<a target="_blank" href="${username}"><img src=${getSMName}  class="social-profile-icon-small"/></a>`;
    } else if (iconSize === "medium") {
      parsedNode += `<a target="_blank" href="${username}"><img src=${getSMName}  class="social-profile-icon-medium" /></a>`;
    } else {
      parsedNode += `<a target="_blank" href="${username}"><img src=${getSMName}  class="social-profile-icon-large" /></a>`;
    }
  });
  parsedNode += "</div>";
  return parsedNode;
};