import React from "react";
import {
  getEditorString,
  PlateEditor,
  ToolbarButton,
  ToolbarButtonProps,
  useEventPlateId,
  usePlateEditorState,
  focusEditor,
} from "@udecode/plate";
import { Form } from "antd";
import { FormEvent, useState } from "react";
import { isUrl, validateInternalLink, validateLink } from "../helpers";
import { LinkType } from "../../../config/typescript";
import { TabbedLinkComponent } from "./TabbledLinkComponent";
import { upsertLink } from "../transforms";
import { MyEditor } from "../../../config/typescript";
import { AtticusClient } from "../../../../../api/atticus.api";
import { allowFeatureForTextMessages } from "../../text-message/config";
import { Modal } from "../../../../Shared/Modal";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const LinkToolbarButton = ({ id, ...props }: ToolbarButtonProps) => {
  const [open, setOpen] = useState(false);

  const [type, setType] = useState<LinkType>("web-link");
  const [value, setValue] = useState<string>("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState("");

  id = useEventPlateId(id);
  const editor = usePlateEditorState(id) as MyEditor;
  const enabled = allowFeatureForTextMessages(editor as PlateEditor);

  const onSubmit = async (e: FormEvent) => {
    if (!editor) return;
    const isBooklinker = type === "booklinker";
    let booklinkerLink = "";

    let valid = false;

    if (type === "web-link") {
      valid = validateLink(value);
    }

    if (type === "internal-link") {
      valid = validateInternalLink(value);
    }

    if (isBooklinker) {
      setBtnLoading(true);
      const res = await AtticusClient.CreateBooklinkerLink(value);
      if (res.success) {
        booklinkerLink = res.linkResult;
        valid = validateLink(booklinkerLink);
      } else {
        setError(res.responseStatus.message);
        valid = validateLink(booklinkerLink);
        setBtnLoading(false);
      }
    }

    if (!valid) return;

    const text = getEditorString(editor, editor.selection);

    upsertLink(editor, {
      url: isBooklinker ? booklinkerLink : value,
      text,
      linkType: type || "",
    });

    setTimeout(() => {
      focusEditor(editor, editor.selection!);
    }, 0);

    setOpen(false);
    setValue("");
    setType("web-link");
    setBtnLoading(false);
    return;
  };

  return (
    <>
      <div className={`${enabled ? "" : "plate-disabled"}`}>
        <ToolbarButton
          onMouseDown={async (event) => {
            if (!editor.selection) return;

            event.preventDefault();
            event.stopPropagation();

            setOpen(true);
          }}
          {...props}
        />
      </div>
      <Modal
        open={open}
        title='Add link'
        onCancel={() => {
          setOpen(false);
          setValue("");
          setType("web-link");
        }}
        destroyOnClose={true}
        leftBtn={{
          type: "at-secondary",
          className:"link-modal-btn",
          backgroundColor: "green",
          onMouseDown: () => setOpen(false),
          children: "Cancel",
        }}
        rightBtn={
          type !== "booklinker"
            ? {
                type: "at-primary",
                className:"link-modal-btn",
                backgroundColor: "green",
                onMouseDown: onSubmit,
                disabled: !isUrl(value),
                children: "Insert link",
              }
            : {
                type: "at-primary",
                className:"link-modal-btn",
                backgroundColor: "green",
                onMouseDown: onSubmit,
                disabled: !isUrl(value),
                loading: btnLoading,
                children: "Universal book link",
              }
        }
      >
        <Form {...layout}>
          <TabbedLinkComponent
            type={type}
            value={value}
            error={error}
            setType={setType}
            setValue={setValue}
          />
        </Form>
      </Modal>
    </>
  );
};
