import React from "react";
import { difference, intersection } from "lodash";
import defaultsDeep from "@nodeutils/defaults-deep";
import { FirstParagraph, Font, HeaderFontStyle, MarginUnits, fonts } from "@surge-global-engineering/css-generator";
import { ThemeFieldFontStyle } from "../../types/themeForm";
import { themeFormDefaults } from "../../utils/initials";
import useRootStore from "../../store/useRootStore";
import { FontItem } from "../../types/font";

interface ConvertProps {
  to: MarginUnits,
  value: number;
}

const MM_PER_INCH = 25.4;

export const MARGIN_IN_MIN = 0.125;
export const MARGIN_IN_MAX = 2.0;

export const MARGIN_MM_MIN = 3.2;
export const MARGIN_MM_MAX = 50.8;

export const HANGING_INDENT_MM_STEP = 1.27;
export const HANGING_INDENT_IN_STEP = 0.05;

export const HANGING_INDENT_IN_MIN = 0.15;
export const HANGING_INDENT_IN_MAX = 0.75;

export const HANGING_INDENT_MM_MIN = 3.81;
export const HANGING_INDENT_MM_MAX = 19.05;

export const convertMarginValue = ({ to, value }: ConvertProps): number => {
  //return values are ceiled / rounded to match the exact values mentioned in AT-1332
  if (to === "mm") {
    return Math.ceil((value * MM_PER_INCH * 10)) / 10;
  }
  return Math.round((value / MM_PER_INCH * 8)) / 8;
};

export const convertHangingIndentValue = ({ to, value }: ConvertProps): number => {
  if (to === "mm") {
    return Math.round((value * MM_PER_INCH * 100)) / 100;
  }
  return Math.round((value / MM_PER_INCH * 100)) / 100;
};

const marginThemeToForm = (unit: MarginUnits = "in", value: number) => {
  if (unit === "mm") {
    if (value < MARGIN_MM_MIN) return MARGIN_MM_MIN;
    if (value > MARGIN_MM_MAX) return MARGIN_MM_MAX;
    return value;
  }

  const convertedValue = convertMarginValue({ to: "in", value });
  if (convertedValue < MARGIN_IN_MIN) return MARGIN_IN_MIN;
  if (convertedValue > MARGIN_IN_MAX) return MARGIN_IN_MAX;
  return convertedValue;
};

const marginFormToTheme = (unit: MarginUnits = "in", value: number) => {
  if (unit === "in") {
    const convertedValue = convertMarginValue({ to: "mm", value });
    return convertedValue;
  }

  return value;
};

const hangingIndentThemeToForm = (unit: MarginUnits = "in", value: number) => {
  if (unit === "mm") {
    if (value < HANGING_INDENT_MM_MIN) return HANGING_INDENT_MM_MIN;
    if (value > HANGING_INDENT_MM_MAX) return HANGING_INDENT_MM_MAX;
    return value;
  }

  const convertedValue = convertHangingIndentValue({ to: "in", value });
  if (convertedValue < HANGING_INDENT_IN_MIN) return HANGING_INDENT_IN_MIN;
  if (convertedValue > HANGING_INDENT_IN_MAX) return HANGING_INDENT_IN_MAX;
  return convertedValue;
};

const hangingIndentFormToTheme = (unit: MarginUnits = "in", value: number) => {
  if (unit === "in") {
    const convertedValue = convertHangingIndentValue({ to: "mm", value });
    return convertedValue;
  }

  return value;
};

const transform = (obj, predicate) => {
  return Object.keys(obj).reduce((memo, key) => {
    if (predicate(obj[key], key)) {
      memo[key] = obj[key];
    }
    return memo;
  }, {});
};

const omit = (obj, items) =>
  transform(obj, (value, key) => !items.includes(key));

const pick = (obj, items) =>
  transform(obj, (value, key) => items.includes(key));

export const themeToPayload = (
  obj: IThemeStore.Theme,
  withId?: boolean
): IThemeStore.ThemePayload => {
  const l = omit(obj, [
    "_id",
    "name",
    "css",
    "isPredefinedTheme",
    "fonts",
    "isFavourite",
  ]);
  const g = pick(obj, [
    "name",
    "css",
    "isPredefinedTheme",
    "fonts",
    "isFavourite",
  ]);

  return {
    ...(withId ? { _id: obj._id } : undefined),
    ...g,
    properties: {
      ...l,
    },
  } as IThemeStore.ThemePayload;
};

const firstParagraphToDecorationsMap = new Map([
  ["uppercaseFourWords", "smallcaps"],
  ["dropcap", "dropcap"],
]);

const paragraphToParaAlignmentMap = new Map([
  ["hyphens", "hyphens"],
  ["justify", "justify"],
]);

const fontStyleStrToArr = (
  style: ThemeFieldFontStyle
): IThemeStore.HeaderFontStyle[] => {
  switch (style) {
    case ThemeFieldFontStyle.bold:
      return [HeaderFontStyle.bold];
    case ThemeFieldFontStyle.italic:
      return [HeaderFontStyle.italic];
    case ThemeFieldFontStyle.boldItalic:
      return [HeaderFontStyle.bold, HeaderFontStyle.italic];
    case ThemeFieldFontStyle.regular:
      return [HeaderFontStyle.regular];
    case ThemeFieldFontStyle.smallcaps:
      return [HeaderFontStyle.regular, HeaderFontStyle.smallcaps];
    case ThemeFieldFontStyle.underlined:
      return [HeaderFontStyle.regular, HeaderFontStyle.underlined];
    case ThemeFieldFontStyle.weight_100:
      return [HeaderFontStyle.weight_100];
    case ThemeFieldFontStyle.weight_200:
      return [HeaderFontStyle.weight_200];
    case ThemeFieldFontStyle.weight_300:
      return [HeaderFontStyle.weight_300];
    case ThemeFieldFontStyle.weight_500:
      return [HeaderFontStyle.weight_500];
    case ThemeFieldFontStyle.weight_600:
      return [HeaderFontStyle.weight_600];
    case ThemeFieldFontStyle.weight_700:
      return [HeaderFontStyle.weight_700];
    case ThemeFieldFontStyle.weight_800:
      return [HeaderFontStyle.weight_800];
    case ThemeFieldFontStyle.weight_900:
      return [HeaderFontStyle.weight_900];
    case ThemeFieldFontStyle.italic_100:
      return [HeaderFontStyle.italic_100];
    case ThemeFieldFontStyle.italic_200:
      return [HeaderFontStyle.italic_200];
    case ThemeFieldFontStyle.italic_300:
      return [HeaderFontStyle.italic_300];
    case ThemeFieldFontStyle.italic_500:
      return [HeaderFontStyle.italic_500];
    case ThemeFieldFontStyle.italic_600:
      return [HeaderFontStyle.italic_600];
    case ThemeFieldFontStyle.italic_700:
      return [HeaderFontStyle.italic_700];
    case ThemeFieldFontStyle.italic_800:
      return [HeaderFontStyle.italic_800];
    case ThemeFieldFontStyle.italic_900:
      return [HeaderFontStyle.italic_900];
  }
};

const fontStyleArrToStr = (
  styles: IThemeStore.HeaderFontStyle[]
): ThemeFieldFontStyle => {
  if (
    styles.includes(HeaderFontStyle.bold) &&
    styles.includes(HeaderFontStyle.italic)
  )
    return ThemeFieldFontStyle.boldItalic;
  if (styles.includes(HeaderFontStyle.bold)) return ThemeFieldFontStyle.bold;
  if (styles.includes(HeaderFontStyle.italic))
    return ThemeFieldFontStyle.italic;
  if (styles.includes(HeaderFontStyle.smallcaps))
    return ThemeFieldFontStyle.smallcaps;
  if (styles.includes(HeaderFontStyle.underlined))
    return ThemeFieldFontStyle.underlined;
  if (styles.includes(HeaderFontStyle.weight_100))
    return ThemeFieldFontStyle.weight_100;
  if (styles.includes(HeaderFontStyle.weight_200))
    return ThemeFieldFontStyle.weight_200;
  if (styles.includes(HeaderFontStyle.weight_300))
    return ThemeFieldFontStyle.weight_300;
  if (styles.includes(HeaderFontStyle.weight_500))
    return ThemeFieldFontStyle.weight_500;
  if (styles.includes(HeaderFontStyle.weight_600))
    return ThemeFieldFontStyle.weight_600;
  if (styles.includes(HeaderFontStyle.weight_700))
    return ThemeFieldFontStyle.weight_700;
  if (styles.includes(HeaderFontStyle.weight_800))
    return ThemeFieldFontStyle.weight_800;
  if (styles.includes(HeaderFontStyle.weight_900))
    return ThemeFieldFontStyle.weight_900;
  if (styles.includes(HeaderFontStyle.italic_100))
    return ThemeFieldFontStyle.italic_100;
  if (styles.includes(HeaderFontStyle.italic_200))
    return ThemeFieldFontStyle.italic_200;
  if (styles.includes(HeaderFontStyle.italic_300))
    return ThemeFieldFontStyle.italic_300;
  if (styles.includes(HeaderFontStyle.italic_500))
    return ThemeFieldFontStyle.italic_500;
  if (styles.includes(HeaderFontStyle.italic_600))
    return ThemeFieldFontStyle.italic_600;
  if (styles.includes(HeaderFontStyle.italic_700))
    return ThemeFieldFontStyle.italic_700;
  if (styles.includes(HeaderFontStyle.italic_800))
    return ThemeFieldFontStyle.italic_800;
  if (styles.includes(HeaderFontStyle.italic_900))
    return ThemeFieldFontStyle.italic_900;
  return ThemeFieldFontStyle.regular;
};

const firstParagraphToDecorations = (
  firstParagraph: FirstParagraph
): string[] => {
  const decorations: string[] = [];
  Object.keys(firstParagraph).forEach((key) => {
    const decorationValue = firstParagraphToDecorationsMap.get(key);

    if (firstParagraph[key] && decorationValue) {
      decorations.push(decorationValue);
    }
  });
  return decorations;
};

export const themeFormFieldsCheck = (fields: IThemeStore.ThemeFields) : string[] => {
  const keys = Object.keys(themeFormDefaults);
  const updatedKeys = Object.keys(fields);

  return difference(keys, updatedKeys);
};

export const formFieldsToStyleProps = (
  fields: IThemeStore.ThemeFields
): IThemeStore.ThemeStyleProps => {
  console.log({ fields });
  const trimsize = fields.trimsize ?
    fields.trimsize.includes("*") ? fields.trimsize.split("*") : fields.trimsize.split("x")
    : [];
  return {
    baseFontSize: fields.fontsize,
    chapterNumbering: fields.numberview,
    ornamentalBreakImage: fields.ob,
    ornamentalBreakWidth: fields.ob_width,
    sceneBreakShowSetting: fields.sceneBreakShowSetting,
    printBaseFont: fields.printBaseFont,
    layout: fields.layout,
    beginFirstSentence: fields.beginFirstSentence,
    margin: {
      unit: fields.margin_unit ? fields.margin_unit : "in",
      outer: marginFormToTheme(fields.margin_unit, fields.margin_out) / 10, // /10 => convert mm to cm
      inner: marginFormToTheme(fields.margin_unit, fields.margin_in) / 10,
      top: fields.margin_top,
      bottom: fields.margin_bottom,
    },
    hangingIndent: hangingIndentFormToTheme(fields.margin_unit, fields.hanging_indent) / 10,
    titleCard: {
      chapterNumber: fields.chpNumVisible,
      title: fields.chpTitleVisible,
      subtitle: fields.chpSubtitleVisible,
      image: fields.imgVisible,
    },
    pdfEndnotesChapterSettings: {
      showTitle: fields.pdfEndnotesChapterSettings.includes("showTitle"),
      categorizeByTitle: fields.pdfEndnotesChapterSettings.includes("categorizeByTitle"),
    },
    ePubEndnotesChapterSettings: {
      showTitle: fields.ePubEndnotesChapterSettings.includes("showTitle"),
      categorizeByTitle: fields.ePubEndnotesChapterSettings.includes("categorizeByTitle"),
    },
    paragraph: {
      indent: fields.firstline === "indented" ? 1 : 0,
      paragraphSpacing: fields.linespacing,
      hyphens: fields.paraAlignment.includes("hyphens"),
      justify: fields.paraAlignment.includes("justify"),
    },
    firstParagraph: {
      indent: fields.firstline === "indented" ? 1 : 0,
      uppercaseFourWords: fields.decorations.includes("smallcaps"),
      dropcap: fields.decorations.includes("dropcap"),
      dropcapFont: fields.dropCapfont,
    },
    trim: {
      unit: fields.trim_unit ? fields.trim_unit : "in",
      width: parseFloat(trimsize[0]),
      height: parseFloat(trimsize[1]),
      isLargeTrim: fields.isLargeTrim,
    },
    notesMode: fields.notesMode,
    ePubNotesMode: fields.ePubNotesMode,
    footnoteFontSize: fields.footnoteFontSize,
    dynamicPageBreaks: {
      breakSubheadings: fields.dynamicPageBreaks.includes("breakSubheadings"),
      breakOrnamentalBreaks: fields.dynamicPageBreaks.includes(
        "breakOrnamentalBreaks"
      ),
    },
    individualChapterImage: fields.individualChapterImage,
    image: {
      url: fields.image,
      alignment: fields.imgAlignment,
      opacity: fields.imgOpacity,
      width: fields.imgWidth,
      placement: fields.imgPlacement,
      colored: "all",
      headerTextColor: fields.textLight,
      printExtent: fields.bgPrintExtent,
    },
    chapterNo: {
      font: fields.chpNumFont,
      size: fields.chpNumSize,
      style: fontStyleStrToArr(fields.chpNumStyle),
      align: fields.chpNumAlign,
      width: fields.chpNumWidth,
      extras: {},
    },
    chapterTitle: {
      font: fields.chpTitleFont,
      size: fields.chpTitleSize,
      style: fontStyleStrToArr(fields.chpTitleStyle),
      align: fields.chpTitleAlign,
      width: fields.chpTitleWidth,
      extras: {},
    },
    chapterSubtitle: {
      font: fields.chpSubtitleFont,
      size: fields.chpSubtitleSize,
      style: fontStyleStrToArr(fields.chpSubtitleStyle),
      align: fields.chpSubtitleAlign,
      width: fields.chpSubtitleWidth,
      extras: {},
    },
    layoutPriority: fields.layoutPriority,
    header: {
      font: fields.headerFont,
      size: fields.headerSize,
    },
    footer: {
      font: fields.footerFont,
      size: fields.footerSize,
    },
    headings: {
      h2: {
        size: fields.h2Size,
        font: fields.h2Font,
      },
      h3: {
        size: fields.h3Size,
        font: fields.h3Font
      },
      h4: {
        size: fields.h4Size,
        font: fields.h4Font
      },
      h5: {
        size: fields.h5Size,
        font: fields.h5Font
      },
      h6: {
        size: fields.h6Size,
        font: fields.h6Font
      },
    }
  };
};

export const themeToFormFields = (
  theme: IThemeStore.Theme
): IThemeStore.ThemeFields => {
  const { properties: styleProps } = theme;
  return {
    coloredImg: "all", // remove this
    textLight: styleProps.image.headerTextColor,
    pdfEndnotesChapterSettings: Object.keys(styleProps.pdfEndnotesChapterSettings).filter(
      (key) => styleProps.pdfEndnotesChapterSettings[key]
    ),
    ePubEndnotesChapterSettings: Object.keys(styleProps.ePubEndnotesChapterSettings).filter(
      (key) => styleProps.ePubEndnotesChapterSettings[key]
    ),
    numberview: styleProps.chapterNumbering,
    beginFirstSentence: styleProps.beginFirstSentence,
    decorations: firstParagraphToDecorations(styleProps.firstParagraph),
    dropCapfont: styleProps.firstParagraph.dropcapFont,
    ob: styleProps.ornamentalBreakImage,
    ob_width: styleProps.ornamentalBreakWidth,
    sceneBreakShowSetting: styleProps.sceneBreakShowSetting,
    firstline: styleProps.paragraph.indent ? "indented" : "spaced",
    paraAlignment: Object.keys(styleProps.paragraph).filter(
      (key) => styleProps.paragraph[key] && paragraphToParaAlignmentMap.get(key)
    ),
    layout: styleProps.layout,
    fontsize: styleProps.baseFontSize,
    printBaseFont: styleProps.printBaseFont,
    linespacing: styleProps.paragraph.paragraphSpacing,
    trim_unit: styleProps.trim.unit ? styleProps.trim.unit : "in",
    trimsize: `${styleProps.trim.width}x${styleProps.trim.height}`,
    isLargeTrim: styleProps.trim.isLargeTrim === true,
    margin_unit: styleProps.margin.unit,
    margin_in: marginThemeToForm(styleProps.margin.unit, styleProps.margin.inner * 10), // *10 => convert cm to mm
    margin_out: marginThemeToForm(styleProps.margin.unit, styleProps.margin.outer * 10),
    margin_top: styleProps.margin.top,
    margin_bottom: styleProps.margin.bottom,
    hanging_indent: hangingIndentThemeToForm(styleProps.margin.unit, styleProps.hangingIndent * 10), // *10 => convert cm to mm
    notesMode: styleProps.notesMode,
    footnoteFontSize: styleProps.footnoteFontSize,
    ePubNotesMode: styleProps.ePubNotesMode,
    dynamicPageBreaks: Object.keys(styleProps.dynamicPageBreaks).filter(
      (key) => styleProps.dynamicPageBreaks[key]
    ),
    name: theme.name,
    image: styleProps.image.url,
    individualChapterImage: styleProps.individualChapterImage,
    bgPrintExtent: styleProps.image.printExtent,
    imgVisible: styleProps.titleCard.image,
    imgWidth: styleProps.image.width,
    imgAlignment: styleProps.image.alignment,
    imgOpacity: styleProps.image.opacity,
    imgPlacement: styleProps.image.placement,
    chpNumVisible: styleProps.titleCard.chapterNumber,
    chpNumFont: styleProps.chapterNo.font,
    chpNumStyle: fontStyleArrToStr(styleProps.chapterNo.style),
    chpNumSize: styleProps.chapterNo.size,
    chpNumAlign: styleProps.chapterNo.align,
    chpNumWidth: styleProps.chapterNo.width,
    chpTitleVisible: styleProps.titleCard.title,
    chpTitleFont: styleProps.chapterTitle.font,
    chpTitleStyle: fontStyleArrToStr(styleProps.chapterTitle.style),
    chpTitleSize: styleProps.chapterTitle.size,
    chpTitleAlign: styleProps.chapterTitle.align,
    chpTitleWidth: styleProps.chapterTitle.width,
    chpSubtitleFont: styleProps.chapterSubtitle.font,
    chpSubtitleVisible: styleProps.titleCard.title,
    chpSubtitleStyle: fontStyleArrToStr(styleProps.chapterSubtitle.style),
    chpSubtitleSize: styleProps.chapterSubtitle.size,
    chpSubtitleAlign: styleProps.chapterSubtitle.align,
    chpSubtitleWidth: styleProps.chapterSubtitle.width,
    headerFont: styleProps.header.font,
    headerSize: styleProps.header.size,
    footerFont: styleProps.footer.font,
    footerSize: styleProps.footer.size,
    layoutPriority: styleProps.layoutPriority,
    h2Size: styleProps.headings.h2.size,
    h3Size: styleProps.headings.h3.size,
    h4Size: styleProps.headings.h4.size,
    h5Size: styleProps.headings.h5.size,
    h6Size: styleProps.headings.h6.size,
    h2Font: styleProps.headings.h2.font,
    h3Font: styleProps.headings.h3.font,
    h4Font: styleProps.headings.h4.font,
    h5Font: styleProps.headings.h5.font,
    h6Font: styleProps.headings.h6.font,
  };
};

export const partialThemePropsToTheme = (
  themePartials: Partial<IThemeStore.Theme>,
  theme: IThemeStore.Theme
): IThemeStore.Theme => {
  return defaultsDeep(themePartials, theme);
};

export const getFullFormFields = (
  partialFormFields: Partial<IThemeStore.ThemeFields>,
  allFields: IThemeStore.ThemeFields
): IThemeStore.ThemeFields => {
  return defaultsDeep(partialFormFields, allFields);
};

export const themeResponseToTheme = (
  response: IThemeStore.ThemeResponse
): IThemeStore.Theme => {
  return {
    properties: response.properties,
    name: response.name,
    css: response.css,
    isFavourite: response.isFavourite,
    isPredefinedTheme: response.isPredefinedTheme,
    fonts: response.fonts,
    _id: response._id,
  };
};

/**
 * Resets theme specific meta data when duplicate / 'save as' theme
 */
export const sanitizeThemeMetaData = (
  theme: IThemeStore.Theme
): Omit<IThemeStore.Theme, "_id"> => {
  const { _id, ...themeWithoutId } = theme;
  themeWithoutId.isPredefinedTheme = false;
  themeWithoutId.isFavourite = false;

  return themeWithoutId;
};

export const sortThemes = (themes: IThemeStore.Theme[]): IThemeStore.Theme[] => {
  const sortAlphabetically = (leftTheme: IThemeStore.Theme, rightTheme: IThemeStore.Theme) =>
    leftTheme.name.localeCompare(rightTheme.name);

  const favouriteThemes = themes.filter((theme) => theme.isFavourite).sort(sortAlphabetically);
  const defaultTheme = themes.find((theme) => theme._id === "default");

  const predefinedThemes = themes.filter((theme) => theme.isPredefinedTheme && !theme.isFavourite && theme._id !== "default").sort(sortAlphabetically);
  const customThemes = themes.filter(theme => !theme.isPredefinedTheme && !theme.isFavourite && theme._id !== "default").sort(sortAlphabetically);

  return [
    ...(defaultTheme ? [defaultTheme] : []),
    ...favouriteThemes,
    ...predefinedThemes,
    ...customThemes,
  ];
};

export const hasCommonElements = (a: unknown[], b: unknown[]): boolean => {
  return intersection(a, b).length > 0;
};

export const ThemeFormFieldsToDebounce = ["name", "imgOpacity"];

export const FontNameFieldsWithStyle = ["chpNumFont", "chpTitleFont", "chpSubtitleFont"];

export const addDefaultFont = (isAvailableForDropCap?:boolean|undefined):Font[] => {
  const regFonts = [...fonts];
  const defaultFont = {
    id: "Default",
    name: "Default",
    variants: [],
    availableForDropCap: isAvailableForDropCap,
  };
  regFonts.unshift(defaultFont);

  return regFonts;
};

export const addDefaultFontToDropCap = (isAvailableForDropCap?: boolean): FontItem[] => {
  const { userFavouriteFonts } = useRootStore().fontStore;
  const regFonts = [...userFavouriteFonts];
  const defaultFont = {
    name: "Default",
    variants: [],
    _id: "Default",
    family: "Default",
    source: "atticus",
    createdAt: new Date(Date.now()),
    lastUpdateAt: new Date(Date.now()),
    availableForDropCap: isAvailableForDropCap,
  };
  regFonts.unshift(defaultFont);

  return regFonts;
};