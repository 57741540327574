import { makeAutoObservable, observable } from "mobx";

export class EditorModalStore {
  activeModal: IEditorModalStore.ModalType = null;
  nodeID: IEditorModalStore.LinkID = null;
  // -- Start: Update Endnote Modal -- //
  updateEndNote: IEditorModalStore.EndNote = {
    note: null,
  };

  newEndNote: IEditorModalStore.EndNote = {
    note: null,
  };
  // -- End: Update Endnote Modal -- //

  // -- Start: Update Link Modal -- //
  updateLinkUrl: IEditorModalStore.LinkUrl = {
    url: null,
  };

  newLinkUrl: IEditorModalStore.LinkUrlAction = {
    action: null,
    url: null,
  };
  // -- End: Update Link Modal -- //

  constructor() {
    makeAutoObservable(this, {
      activeModal: observable.ref,
    });
  }

  showModal = (activeModal: IEditorModalStore.ModalType): void => {
    this.activeModal = activeModal;
  };

  dismissAllModals = (): void => {
    // Reset all state
    this.activeModal = null;

    // Update Link Url Modal
    this.updateLinkUrl = {
      url: null,
    };

    this.newLinkUrl = {
      url: null,
      action: null
    };
  };

  // -- Start: Update Link Modal -- //
  setUpdateLinkUrl = (url: IEditorModalStore.LinkUrlType, id: IEditorModalStore.LinkID): void => {
    this.updateLinkUrl = {
      url: url,
    };
    this.nodeID = id;
  };

  setNewLinkUrl = (url: IEditorModalStore.LinkUrlType, action: IEditorModalStore.LinkUrlActionType): void => {
    this.newLinkUrl = {
      url: url,
      action: action,
    };
  };
  // -- End: Update Link Modal -- //
}

export default new EditorModalStore();
