import { observer } from "mobx-react";
import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import useRootStore from "../../../store/useRootStore";
import { useChapterGeneratorAPI } from "../../../workers";
import { CallbackFn, CallbackFnType, ChapterGeneratorWorkerStatusResponseData, PDFReadyChapterData, RequestChapterCallbackFn } from "../../../workers/types";
import { getChapterNumber } from "../../../utils/chapter-numbering";
import { getImages } from "../../Previewer/print/helpers";
import { isValidChapter } from "../../../utils/toc";

const RendererCacheInitiator: React.FC = () => {
    const params = useParams();
    const { book, getChapterBodyById } = useRootStore().bookStore;

    const { 
        // customThemeHeaders, images,
        activeTheme: theme, individualChapterImage } = useRootStore().themeStore;
    const { initializeBookCache, changeChaptersCacheStatus, getNextChapterTobeRendered, bookExist, makeChapterAsValid, setResetRendererCallback, removeBookCache, getEndNoteOffsetValue } = useRootStore().pdfCacheStore;
    const { registerCallbacks, resetWorker } = useChapterGeneratorAPI();
    const { bookId } = params as { bookId: string };

    const getBookChaptersForCache = () => {
        const chapters = [...book.frontMatter, ...book.chapters];
        const validChapters = chapters.filter(chapter => isValidChapter(chapter._id, book));
        const validFrontMatterIds = book.frontMatterIds.filter(chapterId => isValidChapter(chapterId, book));
        const chaptersWithType = validChapters.map(({ _id, type, startOn, includeIn, parentChapterId, volume }) => ({ chapterId: _id, chapterType: type, startOn, includeIn, parentChapterId, volume } as IPDFCacheStore.ChapterCacheMetaData));
        return { chaptersWithType, frontmatterIds: validFrontMatterIds };
    };

    const handleChapterRequest: RequestChapterCallbackFn = useCallback(async () => {
        const validFrontMatterIds = book.frontMatterIds.filter(chapterId => isValidChapter(chapterId, book));
        const validChapterIds = book.chapterIds.filter(chapterId => isValidChapter(chapterId, book));
        const allChapters = await getChapterBodyById([...validFrontMatterIds, ...validChapterIds]);
        const chapterCacheData = getNextChapterTobeRendered(bookId);
        const chapter = allChapters.find(chapter => chapter._id === chapterCacheData?.chapterId);
        if (chapterCacheData && chapter && theme) {
            const data: PDFReadyChapterData = {
                chapter: chapter,
                book: { ...book, chapters: allChapters },
                chapters: allChapters,
                lastPageNumber: chapterCacheData.pageNumberOffset,
                chapterNumber: getChapterNumber(chapter, {...book, chapters: allChapters}),
                theme: theme,
                endNoteOffset: theme.properties.notesMode === "END_OF_BOOK" ? getEndNoteOffsetValue(book._id, chapter._id) : 0,
                // styles: style || null,
                // images: getImages(chapter, style, images),
                // customThemeHeaders: customThemeHeaders || undefined,
                // hyphens: theme.paragraph.hyphens,
                tocSummary: chapterCacheData.tocSummary,
                previousDigest: chapterCacheData.digest
            };
            return JSON.stringify(data);
        }

        return null;
    }, [theme, book,
        // style,
        getChapterBodyById, getNextChapterTobeRendered, getChapterNumber, getImages]);

    const createWorkerResponseHandler = useCallback((bookId: string): CallbackFn<ChapterGeneratorWorkerStatusResponseData> => (type: CallbackFnType, taskId: string | null, message?: string, data?: ChapterGeneratorWorkerStatusResponseData) => {
        switch (type) {
            case "completed":
                if (!data) return;
                // eslint-disable-next-line no-case-declarations
                const { chapterId, digest, pageCount, blob } = data;
                // eslint-disable-next-line no-case-declarations
                const url = URL.createObjectURL(blob);
                makeChapterAsValid(bookId, chapterId, pageCount, url, data.endNoteCount, digest);
                break;
            case "digest-match":
                if(!message) return;
                changeChaptersCacheStatus(bookId, [message], "valid");
                break;
            default:
                break;
        }
    }, [makeChapterAsValid]);

    const reinitializeWorker = useCallback(() => {
        resetWorker();
        registerCallbacks(handleChapterRequest, createWorkerResponseHandler(book._id));
    }, [book, resetWorker, registerCallbacks, handleChapterRequest, createWorkerResponseHandler]);

    useEffect(() => {
        if (book && book._id) {
            if (!bookExist(book._id)) {
                // get book chapter data
                const { chaptersWithType, frontmatterIds } = getBookChaptersForCache();
                // initialize cache store
                initializeBookCache(bookId, chaptersWithType, frontmatterIds);
            }
            setResetRendererCallback(reinitializeWorker);
            reinitializeWorker();
        }
    }, [book, bookExist, initializeBookCache, reinitializeWorker]);

    return <div></div>;
};

export default observer(RendererCacheInitiator);
