import React, { FunctionComponent, useEffect, useState } from "react";
import { Formik, FormikErrors } from "formik";
import { Button, Form, Input, FormItemProps } from "antd";
import { v4 as uuidv4 } from "uuid";
import { generate as generateRandomString } from "randomstring";
import { UploadOutlined } from "@ant-design/icons";

import FileUpload from "../../Shared/Forms/FileUpload";
import SelectAuthor from "./select-author";
import SelectProject from "./select-project";
import { AtticusTextField } from "../../Shared/Form";
import { Modal } from "../../Shared/Modal";
import { UploadIcon } from "../../../content/icons";


const commonFormItemProps = {
  labelAlign: "left",
  colon: false,
} as FormItemProps;

interface UploadBookModalProps {
  onUpload: (params: IShelfStore.BookFormFile) => Promise<unknown>;
  showModal: boolean,
  onClose: () => void;
}

export const UploadBookForm: FunctionComponent<UploadBookModalProps> = ({ onUpload, showModal, onClose }: UploadBookModalProps) => {
  const [shouldResetFields, setShouldResetFields] = useState(false);
  const [fileUploadResetKey, setFileUploadResetKey] = useState("initKey");
  useEffect(() => {
		if(showModal) {
			setShouldResetFields(false);
		}
	}, [showModal]);

  const onCancel = (resetForm: () => void, onClose: () => void) => {
    setShouldResetFields(true);
    setFileUploadResetKey(generateRandomString(10));
    resetForm();
    onClose();
  };

  return (
    <>
      <Formik
        initialValues={{ _id: uuidv4(), chapterId: generateRandomString(16), title: "", fileURL: "", fileType: "", author: [], project: "" } as IShelfStore.BookFormFile}
        validate={() => {
          const errors: FormikErrors<IShelfStore.BookFormFile> = {};
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await onUpload(values);
          onClose();
          setSubmitting(false);
        }}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          submitForm,
          isSubmitting,
          setSubmitting,
          resetForm
        }) => (
          <Modal
            maskClosable={false}
            title={<span className="upload-book-modal-heading">Upload a book</span>}
            open={showModal}
            style={{
              borderRadius: 10,
            }}
            onCancel={() => onCancel(resetForm, onClose)}
            rightBtn={{
              type: "at-primary",
              size: "medium",
              backgroundColor: "green",
              children: "Go get em Atticus!",
              loading: isSubmitting,
              onMouseDown: submitForm,
              disabled: isSubmitting
            }}
          >
            <Form layout="vertical">
              <div className="upload-book-form">
                <Form.Item name="upload" className="form-item-upload" {...commonFormItemProps}>
                  <FileUpload
                    fileType=".docx,.epub,.rtf,.mobi"
                    onFileUpload={(fileUrl, fileType) => {
                      setFieldValue("fileURL", fileUrl);
                      if (fileType) setFieldValue("fileType", fileType);
                    }}
                    onFileUploadStart={() => setSubmitting(true)}
                    onFileUploadEnd={() => setSubmitting(false)}
                    resetKey={fileUploadResetKey}
                    styleOverrides={{
                      backgroundColor: "#E7EDF8",
                      borderRadius: 4,
                      borderWidth: 2,
                      borderStyle: "dashed",
                      borderColor: "#ADC1E3",
                      height:"160px",
                      padding: "0",
                    }}
                  >
                    <div className="upload-book-dnd-container">
                        <>
                          <span>
                            <UploadIcon color="#3363B9" />
                          </span>
                          <p className="upload-book-dnd-text">
                            <span className="upload-book-dnd-text-highlight">
                              Click to upload your book
                            </span>{" "}
                            or drag and drop
                          </p>
                        </>
                    </div>
                  </FileUpload>
                </Form.Item>
                <div className="book-upload-footer">
                  <p className="book-upload-footer-text">
                  Supported formats: .docx
                  </p>
                </div>
                <Form.Item label={<span className="upload-book-form-label">Book title</span>} {...commonFormItemProps}>
                  <AtticusTextField placeholder="Title" name="title" onChange={handleChange} value={values.title} />
                </Form.Item>
                <Form.Item name="author" label={<span className="upload-book-form-label">Author</span>} {...commonFormItemProps}>
                  <SelectAuthor placeholderText={"Author"} onSelect={v => setFieldValue("author", v)} value={values.author} resetField={shouldResetFields} />
                </Form.Item>
                <Form.Item name="project" label={<span className="upload-book-form-label">Project</span>} {...commonFormItemProps}>
                  <SelectProject placeholderText={"Project name"} onSelect={v => setFieldValue("project", v)} value={values.project} resetField={shouldResetFields} />
                </Form.Item>
              </div>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

