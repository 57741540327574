import { observer } from "mobx-react";
import React from "react";
import useRootStore from "../../../../store/useRootStore";
import { ConfigurationForm, HabitView } from "./Body";

const BodyContainer: React.FC = () => {
  const { section } = useRootStore().writingHabitStore;

  const getBody = (): React.ReactNode => {
    switch (section) {
      case "loading":
        return <p>We're loading the data for this feature. Please wait..</p>;
      case "create":
        return <ConfigurationForm isEditView={false} />;
      case "edit":
        return <ConfigurationForm isEditView={true} />;
      case "view":
        return <HabitView />;
      default:
        return <p>Something went wrong</p>;
    }
  };

  return <div>{getBody()}</div>;
};

export default observer(BodyContainer);
