import React from "react";

export const VerseComponent = (props: any) => {
  const { id, children } = props;
  return (
    <pre className="plate-custom-verse-block" id={id}>
      <code>{children}</code>
    </pre>
  );
};
