import {
  autoformatArrow,
  autoformatLegal,
  autoformatLegalHtml,
} from "@udecode/plate";
import { MyAutoformatRule } from "../typescript";
import { autoformatBlocks } from "./autoformatBlocks";
import { autoformatLists } from "./autoformatLists";
import { autoformatMarks } from "./autoformatMarks";
import { autoformatPunctuation } from "./autoformatPunctuation";

export const autoformatRules = [
  ...autoformatBlocks,
  ...autoformatLists,
  ...autoformatMarks,
  ...(autoformatPunctuation as MyAutoformatRule[]),
  ...(autoformatLegal as MyAutoformatRule[]),
  ...(autoformatLegalHtml as MyAutoformatRule[]),
  ...(autoformatArrow as MyAutoformatRule[]),
];
