import React from "react";
import {
  getPreventDefaultHandler,
  someNode,
  useEventPlateId,
  usePlateEditorState,
} from "@udecode/plate-core";
import { ToolbarButton, ToolbarButtonProps } from "@udecode/plate-ui-toolbar";
import { ELEMENT_VERSE } from "../createVersePlugin";
import { toggleVerse } from "../transforms/toggleVerseNodes";
import { allowFeatureForList } from "../../../config/lists";
import { allowFeatureForTextMessages } from "../../text-message/config";

export const VerseToolbarButton = ({
  id,
  ...props
}: ToolbarButtonProps) => {
  const editor = usePlateEditorState(useEventPlateId(id));

  const enabled = allowFeatureForList(editor) && allowFeatureForTextMessages(editor);

  return (
    <div className={`${enabled ? "" : "plate-disabled"}`}>
      <ToolbarButton
        active={
          !!editor?.selection &&
          someNode(editor, { match: { type: ELEMENT_VERSE } })
        }
        onMouseDown={
          editor ? getPreventDefaultHandler(toggleVerse, editor) : undefined
        }
        {...props}
      />
    </div>
  );
};
