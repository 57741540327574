import { shouldTransformToParagraph, simplifyTable, transformAncestors, transformDescendants, transformToParagraph } from "./transformHelpers";

export const handleTextNode = (node: ChildNode, parent: HTMLElement): void => {
    const textContent = node.textContent?.trim();
    if (textContent) {
        const p = document.createElement("p");
        p.textContent = textContent;
        parent.appendChild(p);
        node.remove();
    }
};

export const handleElementNode = (element: HTMLElement): void => {
    if (shouldTransformToParagraph(element)) {
        transformToParagraph(element);
        return;
    }
    
    transformAncestors(element);
    transformDescendants(element);

    switch (element.nodeName) {
        case "H1":
            transformToParagraph(element);
            break;
        case "LI":
            //exclude slate element nodes
            if (!element.closest("ul") && !element.closest("ol") && element.dataset.slateNode !== "element") {
                transformToParagraph(element);
            }
            break;
        case "TABLE":
        case "GOOGLE-SHEETS-HTML-ORIGIN":
            simplifyTable(element);
            break;
        default:
            break;
    }
};