import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Col, DatePicker, Row } from "antd";
import moment, { Moment } from "moment";
import { CalendarHeatmap } from "./CalendarHeatmap";
import { observer } from "mobx-react";
import useRootStore from "../../../../../store/useRootStore";
import "react-calendar-heatmap/dist/styles.css";

const MonthlyTracker: React.FC = observer(() => {
  const [selectedMonthYear, setSelectedMonthYear] = useState(moment());

  const { writingHabitState } = useRootStore().writingHabitStore;

  const onPanelChange = (value) => {
    setSelectedMonthYear(value);
  };

  return (
    writingHabitState && (
      <>
        <Row className="writing-habit-monthly-tracker">
          <Col flex="auto">
            <span className="monthly-tracker-heading">Monthly tracker</span>
          </Col>
          <Col>
            <DatePicker
              picker="month"
              bordered={false}
              onPanelChange={onPanelChange}
              value={selectedMonthYear}
              format={"MMM, YYYY"}
              suffixIcon={<DownOutlined />}
            />
          </Col>
        </Row>
        <Row>
          <Col flex="auto">
            <CalendarHeatmap
              date={selectedMonthYear.clone()}
              showDate={false}
              additionalCellClass={(day: Moment) => {
                const dayFormat = day.format("YYYY-MM-DD");
                const equivalentRecord = writingHabitState.dateRecords.find(
                  (record) => record.date === dayFormat
                );

                if (!equivalentRecord) return null;

                if (equivalentRecord.writtenCount >= equivalentRecord.goal) {
                  return "calendar-cell-green";
                }

                if (equivalentRecord.isCommittedDate) {
                  return "calendar-cell-yellow";
                }

                return null;
              }}
              tooltipForCell={(day: Moment) => {
                const dayFormat = day.format("YYYY-MM-DD");
                const equivalentRecord = writingHabitState.dateRecords.find(
                  (record) => record.date === dayFormat
                );

                if (!equivalentRecord) return null;

                return `${equivalentRecord.date} | Goal: ${equivalentRecord.goal} | Actual: ${equivalentRecord.writtenCount}`;
              }}
            />
          </Col>
        </Row>
      </>
    )
  );
});

export { MonthlyTracker };
